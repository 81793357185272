export const CHECK_ACTIVE_PAGE = 'transactions/CHECK_ACTIVE_PAGE';
export const CHECK_MODIFY_PAGE = 'transactions/CHECK_MODIFY_PAGE';
export const SET_CURRENT_TRANSACTION = 'transactions/SET_CURRENT_TRANSACTION';
export const SET_TRANSACTION_UNSUBSCRIBE =
  'transactions/SET_TRANSACTION_UNSUBSCRIBE';
export const SET_TRANSACTIONS = 'transactions/SET_TRANSACTIONS';
export const SET_APPROVED_TRANSACTIONS = 'transactions/SET_APPROVED';
export const SET_MODIFY_TRANSACTIONS = 'transactions/SET_MODIFY';
export const SET_PENDING_TRANSACTIONS = 'transactions/SET_PENDING';
export const SET_REJECTED_TRANSACTIONS = 'transactions/SET_REJECTED';
export const SET_PAYABLE_TRANSACTIONS = 'transactions/SET_PAYABLE_TRANSACTIONS';
export const SET_PAYABLE_ATH_TRANSACTIONS =
  'transactions/SET_PAYABLE_ATH_TRANSACTIONS';
export const SET_PAYABLE_BTH_TRANSACTIONS =
  'transactions/SET_PAYABLE_BTH_TRANSACTIONS';
export const SET_PAYABLE_MANUAL_TRANSACTIONS =
  'transactions/SET_PAYABLE_MANUAL_TRANSACTIONS';
export const SET_RECEIVABLE_ATH_TRANSACTIONS =
  'transactions/SET_RECEIVABLE_ATH_TRANSACTIONS';
export const SET_RECEIVABLE_BTH_TRANSACTIONS =
  'transactions/SET_RECEIVABLE_BTH_TRANSACTIONS';

const initialState = {
  isDetailedPage: false,
  isModifyPage: false,
  currentTransactionData: {},
  currentPayableAmount: '',
  currentReceivableAmount: '',
  transactions: [],
  approvedTransactions: [],
  modifyTransactions: [],
  pendingTransactions: [],
  rejectedTransactions: [],
  payableTransactions: [],
  payableAthTransactions: [],
  payableBthTransactions: [],
  payableManualTransactions: [],
  receivableAthTransactions: [],
  receivableBthTransactions: [],
  unsubscribeTransaction: () => null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CHECK_ACTIVE_PAGE:
      return {
        ...state,
        isDetailedPage: action.isDetailedPage,
      };
    case CHECK_MODIFY_PAGE:
      return {
        ...state,
        isModifyPage: action.isModifyPage,
      };
    case SET_CURRENT_TRANSACTION:
      return {
        ...state,
        currentTransactionData: action.currentTransactionData,
        currentPayableAmount: action.currentPayableAmount,
        currentReceivableAmount: action.currentReceivableAmount,
      };
    case SET_TRANSACTION_UNSUBSCRIBE:
      return {
        ...state,
        unsubscribeTransaction: action.unsubscribeTransaction,
      };
    case SET_TRANSACTIONS:
      return {
        ...state,
        transactions: action.transactions,
      };
    case SET_APPROVED_TRANSACTIONS:
      return {
        ...state,
        approvedTransactions: action.transactions,
      };
    case SET_MODIFY_TRANSACTIONS:
      return {
        ...state,
        modifyTransactions: action.transactions,
      };
    case SET_PENDING_TRANSACTIONS:
      return {
        ...state,
        pendingTransactions: action.transactions,
      };
    case SET_REJECTED_TRANSACTIONS:
      return {
        ...state,
        rejectedTransactions: action.transactions,
      };
    case SET_PAYABLE_TRANSACTIONS:
      return {
        ...state,
        payableTransactions: action.payableTransactions,
      };
    case SET_PAYABLE_ATH_TRANSACTIONS:
      return {
        ...state,
        payableAthTransactions: action.athTransactions,
      };
    case SET_PAYABLE_BTH_TRANSACTIONS:
      return {
        ...state,
        payableBthTransactions: action.bthTransactions,
      };
    case SET_PAYABLE_MANUAL_TRANSACTIONS:
      return {
        ...state,
        payableManualTransactions: action.manualTransactions,
      };
    case SET_RECEIVABLE_ATH_TRANSACTIONS:
      return {
        ...state,
        receivableAthTransactions: action.athTransactions,
      };
    case SET_RECEIVABLE_BTH_TRANSACTIONS:
      return {
        ...state,
        receivableBthTransactions: action.bthTransactions,
      };
    default:
      return state;
  }
};

export const switchActivePage = activePage => dispatch => {
  dispatch({
    type: CHECK_ACTIVE_PAGE,
    isDetailedPage: activePage,
  });
};
export const switchModifyPage = status => dispatch => {
  dispatch({
    type: CHECK_MODIFY_PAGE,
    isModifyPage: status,
  });
};
export const setCurrentTransaction = (
  transactionData,
  currentPayableAmount,
  currentReceivableAmount
) => dispatch => {
  dispatch({
    type: SET_CURRENT_TRANSACTION,
    currentTransactionData: transactionData,
    currentPayableAmount,
    currentReceivableAmount,
  });
};

//  Function for the purpose of saving the unsubscribe function of the onSnapshot listener of the selected transaction.
export const setCurrentTransactionUnsubscribe = unsubscribeTransaction => dispatch => {
  dispatch({
    type: SET_TRANSACTION_UNSUBSCRIBE,
    unsubscribeTransaction,
  });
};

export const setTransactions = transactions => dispatch => {
  dispatch({
    type: SET_TRANSACTIONS,
    transactions,
  });
};
export const setApprovedTransactions = transactions => dispatch => {
  dispatch({
    type: SET_APPROVED_TRANSACTIONS,
    transactions,
  });
};
export const setModifyTransactions = transactions => dispatch => {
  dispatch({
    type: SET_MODIFY_TRANSACTIONS,
    transactions,
  });
};
export const setPendingTransactions = transactions => dispatch => {
  dispatch({
    type: SET_PENDING_TRANSACTIONS,
    transactions,
  });
};
export const setRejectedTransactions = transactions => dispatch => {
  dispatch({
    type: SET_REJECTED_TRANSACTIONS,
    transactions,
  });
};

export const setPayableTransactions = payableTransactions => dispatch => {
  dispatch({
    type: SET_PAYABLE_TRANSACTIONS,
    payableTransactions,
  });
};

export const setPayableAthTransactions = athTransactions => dispatch => {
  dispatch({
    type: SET_PAYABLE_ATH_TRANSACTIONS,
    athTransactions,
  });
};

export const setPayableBthTransactions = bthTransactions => dispatch => {
  dispatch({
    type: SET_PAYABLE_BTH_TRANSACTIONS,
    bthTransactions,
  });
};

export const setPayableManualTransactions = manualTransactions => dispatch => {
  dispatch({
    type: SET_PAYABLE_MANUAL_TRANSACTIONS,
    manualTransactions,
  });
};

export const setReceivableAthTransactions = athTransactions => dispatch => {
  dispatch({
    type: SET_RECEIVABLE_ATH_TRANSACTIONS,
    athTransactions,
  });
};

export const setReceivableBthTransactions = bthTransactions => dispatch => {
  dispatch({
    type: SET_RECEIVABLE_BTH_TRANSACTIONS,
    bthTransactions,
  });
};
