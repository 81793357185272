import Transactions from 'views/Transactions/FinanceTransactions.jsx';
// @material-ui/icons
import DashboardIcon from '@material-ui/icons/Dashboard';

const financeRecievableDashRoutes = [
  {
    path: '/finance-recievable/transactions',
    name: 'Transactions',
    icon: DashboardIcon,
    component: Transactions,
  },
];
export default financeRecievableDashRoutes;
