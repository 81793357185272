/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-undef */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-array-index-key */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Danger from 'components/Typography/Danger.jsx';
import Snackbars from 'components/Snackbar/Snackbar.jsx';
import AddAlert from '@material-ui/icons/AddAlert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from 'components/Card/Card.jsx';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

import Button from 'components/CustomButtons/Button.jsx';
import MenuItem from '@material-ui/core/MenuItem';
import withStyles from '@material-ui/core/styles/withStyles';
import TransactionStyle from 'assets/jss/material-dashboard-pro-react/views/TransactionStyle';
import ReactSelect from 'react-select';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Close from '@material-ui/icons/Close';
import purple from '@material-ui/core/colors/purple';
import Responsive from 'react-responsive';
import apiCaller from '../../utils/apiCaller';

import firebase from '../../config/config';

const db = firebase.firestore();
const auditLog = firebase.functions().httpsCallable('utilities-auditLog');

const Desktop = props => <Responsive {...props} minWidth={701} />;
const Mobile = props => <Responsive {...props} maxWidth={700} />;
function SupplyForm({ ...props }) {
  const [inputFields, setInputFields] = useState([
    {
      trucker: '',
      trucker_error: '',
      from_city: '',
      from_city_error: '',
      to_city: '',
      to_city_error: '',
      no_of_vehicles: '',
      no_of_vehicles_error: false,
      vehicle_type: '',
      vehicle_type_error: '',
      expected_price: '',
      expected_price_error: false,
      material_type: '',
      material_type_error: '',
      loading_point: '',
      loading_point_error: '',
      unloading_point: '',
      unloading_point_error: '',
      current_city: '',
      current_city_error: '',
      isEdit: true,
      isLoading: false,
      current_location_longitude: '',
      current_location_lattitude: '',
    },
  ]);
  const [isLoading, setLoader] = useState(false);
  const [isNotification, setNotification] = useState(false);
  const [notificationText, setNotificationText] = useState('success');
  const [notificationMessage, setNotificationMessage] = useState('');

  const [truckers, setTruckers] = useState([]);

  const [truckTypes, setTruckTypes] = useState([]);

  const [cities, setCities] = useState([]);
  const [fromCitiesObject, setCityObject] = useState([]);

  /**
   * will return All the Cities Available for User
   * @function
   * @param {String} companyId
   */
  function getCities() {
    const cityRef = db
      .collection('Cities')
      .where('isActive', '==', true)
      .orderBy('name', 'asc');
    cityRef.onSnapshot(citiesSnapshot => {
      const newCities = [];
      const newFromCitiesObject = [];
      citiesSnapshot.forEach(city => {
        newCities.push(city.data().name);
        newFromCitiesObject.push({
          value: city.data().name,
          label: city.data().name,
        });
      });
      setCities(newCities);
      setCityObject(newFromCitiesObject);
    });
  }

  const getTrucks = trucker => {
    const body = {
      trucker_id: trucker.trucker_id,
      offset: 0,
      total: 5000,
    };
    apiCaller(
      'matchmaking-api/list-trucks',
      'post',
      body,
      true,
      false,
      'matchMaking'
    )
      .then(response => {
        if (response.hasOwnProperty('message')) {
          if (response.message === 'success') {
            if (response.truckers.hasOwnProperty('columnMetadata')) {
              const { columnMetadata, records } = response.truckers;
              const headers = [];
              columnMetadata.forEach(column => {
                headers.push(column.label);
              });
              const truck_types = [];
              records.forEach(record => {
                const singleRecord = '';
                record.forEach((newRecord, i) => {
                  const key = headers[i];
                  if (key === 'truck_type') {
                    truck_types.push(newRecord[Object.keys(newRecord)[0]]);
                  }
                });
              });
              setTruckTypes(truck_types);
            }
          }
        }
      })
      .catch(err => {
        console.log('Error', err);
      });
  };

  useEffect(() => {
    getCities();
  }, []);

  useEffect(() => {
    const { truckers } = props;
    const normalizedTruckers = [];
    truckers.forEach(trucker => {
      normalizedTruckers.push({
        ...trucker,
        label: trucker.name,
        value: trucker.trucker_id,
        trucker_phone_number: trucker.phone_number,
      });
    });
    setTruckers(normalizedTruckers);
  }, [props.truckers]);

  useEffect(() => {
    const { truckers } = props;
    const normalizedTruckers = [];
    truckers.forEach(trucker => {
      normalizedTruckers.push({
        ...trucker,
        label: trucker.name,
        value: trucker.trucker_id,
        trucker_phone_number: trucker.phone_number,
      });
    });
    setTruckers(normalizedTruckers);
  }, []);

  // function that verifies if a string has a given length or not
  function verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  const validateAllFields = () =>
    new Promise((resolve, reject) => {
      let error = false;
      const values = [...inputFields];
      values.forEach(row => {
        if (Object.keys(row.from_city).length === 0) {
          error = true;
          row.from_city_error = 'This Field is Required';
        } else {
          row.from_city_error = '';
        }
        if (Object.keys(row.to_city).length === 0) {
          error = true;
          row.to_city_error = 'This Field is Required';
        } else {
          row.to_city_error = '';
        }
        if (verifyLength(row.vehicle_type, 1)) {
          row.vehicle_type_error = '';
        } else {
          error = true;
          row.vehicle_type_error = 'This Field is Required';
        }
        if (Object.keys(row.trucker).length === 0) {
          error = true;
          row.trucker_error = 'This Field is Required';
        } else {
          row.trucker_error = '';
        }
        if (verifyLength(row.no_of_vehicles, 1)) {
          if (row.no_of_vehicles > 0) {
            row.no_of_vehicles_error = 'success';
          } else {
            error = true;
            row.no_of_vehicles_error = 'error';
          }
        } else {
          error = true;
          row.no_of_vehicles_error = 'error';
        }

        if (verifyLength(row.expected_price, 1)) {
          if (row.expected_price > 0) {
            row.expected_price_error = 'success';
          } else {
            error = true;
            row.expected_price_error = 'error';
          }
        } else {
          error = true;
          row.expected_price_error = 'error';
        }
      });
      if (!error) {
        resolve('Success');
      } else {
        reject('Fill all required fields');
      }
      setInputFields(values);
    });

  const handleSubmitEdit = e => {
    setLoader(true);
    validateAllFields()
      .then(() => {
        setLoader(true);
        const supplies = [];
        const { userDetails } = props;
        const createdByName = userDetails.name;
        inputFields.forEach(supply => {
          const {
            trucker_id,
            is_lead,
            name,
            trucker_phone_number,
          } = supply.trucker;
          const {
            vehicle_type,
            no_of_vehicles,
            expected_price,
            current_location_longitude,
            current_location_lattitude,
          } = supply;
          const from_location = supply.from_city.value;
          const to_location = supply.to_city.value;
          let current_location = supply.current_city;
          if (current_location === undefined) {
            current_location = '';
          }
          supplies.push({
            trucker_id: Number(trucker_id),
            is_lead,
            trucker_name: name,
            type_of_vehicle: vehicle_type,
            number_of_vehicles: Number(no_of_vehicles),
            from_location,
            to_location,
            current_location,
            price: Number(expected_price),
            trucker_phone_number,
            created_by: createdByName,
            current_location_longitude: current_location_longitude.toString(),
            current_location_lattitude: current_location_lattitude.toString(),
            from_location_longitude: '',
            from_location_lattitude: '',
            to_location_longitude: '',
            to_location_lattitude: '',
          });
        });
        const body = {
          supplies,
        };
        apiCaller(
          'matchmaking-api/add-supply',
          'post',
          body,
          true,
          false,
          'matchMaking'
        )
          .then(response => {
            if (response.hasOwnProperty('message')) {
              if (response.message === 'supply added successfully') {
                setNotificationText('success');
                setNotificationMessage('Successfully Added Demand');
                setNotification(true);
                setLoader(false);
                props.handleOnSubmit();
                handleClearFields();
                setTimeout(() => {
                  setNotification(false);
                }, 5000);
                const auditBody = {
                  data: {
                    supplies,
                  },
                  collection: 'MatchMaking',
                  updatedBy: userDetails.uid,
                  systemDetails: {},
                  type: 'MatchMaking Add Supply',
                  message: `${userDetails.email} Added Supplies`,
                };
                auditLog(auditBody);
              } else {
                setNotificationText('danger');
                setNotificationMessage(
                  'Something went wrong! Please try again'
                );
                setNotification(true);
                setLoader(false);
              }
            }
          })
          .catch(err => {
            setNotificationText('danger');
            setNotificationMessage('Something went wrong! Please try again');
            setNotification(true);
            setLoader(false);
          });
      })
      .catch(err => {
        setLoader(false);
        console.log('Error in Validation', err);
      });
    e.preventDefault();
  };

  const handleAddFields = () => {
    const values = [...inputFields];
    values.push({
      trucker: '',
      trucker_error: '',
      from_city: '',
      from_city_error: '',
      current_city: '',
      current_city_error: '',
      to_city: '',
      to_city_error: '',
      no_of_vehicles: '',
      no_of_vehicles_error: false,
      vehicle_type: '',
      vehicle_type_error: '',
      expected_price: '',
      expected_price_error: false,
      material_type: '',
      material_type_error: '',
      loading_point: '',
      loading_point_error: '',
      unloading_point: '',
      unloading_point_error: '',
      isEdit: true,
      isLoading: false,
      current_location_longitude: '',
      current_location_lattitude: '',
    });
    setInputFields(values);
  };

  const handleFromCity = (selectedCity, inputField, index) => {
    const values = [...inputFields];
    values[index].from_city = selectedCity;
    setInputFields(values);
  };

  const handleToCity = (selectedCity, inputField, index) => {
    const values = [...inputFields];
    values[index].to_city = selectedCity;
    setInputFields(values);
  };

  const handleTruckerChange = (e, inputField, index) => {
    const values = [...inputFields];
    values[index].trucker = e;
    getTrucks(e);
    setInputFields(values);
  };

  const handleTruckTypes = (vehicle_type, index) => {
    const values = [...inputFields];
    values[index].vehicle_type = vehicle_type.target.value;
    setInputFields(values);
  };

  const handlePriceChange = (e, index) => {
    const values = [...inputFields];
    values[index].expected_price = e.target.value;
    setInputFields(values);
  };

  const handleNoOfVehiclesChange = (e, index) => {
    const values = [...inputFields];
    values[index].no_of_vehicles = e.target.value;
    setInputFields(values);
  };

  const handleRemoveFields = index => {
    const values = [...inputFields];
    values[index].isLoading = true;
    setInputFields(values);
    values.splice(index, 1);
    setInputFields(values);
  };

  // Will Get select Trucks form
  function getTruckTypes(inputField, index) {
    const { classes } = props;
    return (
      <div>
        <FormControl fullWidth className={classes.selectFormControl}>
          <Select
            MenuProps={{ className: classes.selectMenu }}
            classes={{ select: classes.select }}
            value={inputField.vehicle_type}
            onChange={e => handleTruckTypes(e, index)}
            inputProps={{ name: 'vehicleType', id: 'simple-select' }}
          >
            <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
              Select Truck Type
            </MenuItem>
            {truckTypes.map((truckTypeData, i) => (
              <MenuItem
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
                value={truckTypeData}
              >
                {truckTypeData}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Danger>{inputField.vehicle_type_error}</Danger>
      </div>
    );
  }

  // Will Get select Trucks form
  function getTruckersForm(inputField, index) {
    const { classes } = props;
    // const { truckers, trucker, truckerState } = this.state;
    return (
      <div>
        <FormControl fullWidth className={classes.selectFormControl}>
          <ReactSelect
            value={inputField.trucker}
            onChange={e => handleTruckerChange(e, inputField, index)}
            options={truckers}
            placeholder="Select Trucker"
          />
        </FormControl>
        <Danger>{inputField.trucker_error}</Danger>
      </div>
    );
  }

  function selectFromCities(inputField, index) {
    return (
      <div style={{ marginTop: '5px' }}>
        <FormControl fullWidth>
          <ReactSelect
            value={inputField.from_city}
            onChange={e => handleFromCity(e, inputField, index)}
            options={fromCitiesObject}
            placeholder="Select City"
          />
        </FormControl>
        <Danger>{inputField.from_city_error}</Danger>
      </div>
    );
  }

  function selectToCities(inputField, index) {
    return (
      <div style={{ marginTop: '5px' }}>
        <FormControl fullWidth>
          <ReactSelect
            value={inputField.to_city}
            onChange={e => handleToCity(e, inputField, index)}
            options={fromCitiesObject}
            placeholder="Select City"
          />
        </FormControl>
        <Danger>{inputField.to_city_error}</Danger>
      </div>
    );
  }

  const handleClearFields = () => {
    const values = [
      {
        trucker: '',
        trucker_error: '',
        from_city: {},
        from_city_error: '',
        to_city: {},
        to_city_error: '',
        no_of_vehicles: '',
        current_city: '',
        no_of_vehicles_error: false,
        vehicle_type: '',
        vehicle_type_error: '',
        expected_price: '',
        expected_price_error: false,
        material_type: '',
        material_type_error: '',
        loading_point: '',
        loading_point_error: '',
        unloading_point: '',
        unloading_point_error: '',
        isEdit: true,
        isLoading: false,
      },
    ];
    setInputFields(values);
    setNotification(false);
  };

  const handleChange = (address, index) => {
    const values = [...inputFields];
    values[index].current_city = address;
    setInputFields(values);
  };

  const handleSelect = (address, index) => {
    const values = [...inputFields];
    values[index].current_city = address;
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        values[index].current_location_longitude = latLng.lng;
        values[index].current_location_lattitude = latLng.lat;
        setInputFields(values);
      })
      .catch(error => console.error('Error', error));
  };

  const searchOptions = {
    componentRestrictions: { country: 'in' },
  };

  return (
    <div>
      <Desktop>
        <Grid fluid style={{ justifyContent: 'center', width: '100%' }}>
          <div>
            <Snackbars
              place="tr"
              color={notificationText}
              icon={AddAlert}
              message={notificationMessage}
              open={isNotification}
              closeNotification={() => setNotification(false)}
              close
            />
            <Row style={{ width: '100%' }}>
              <Col xs={6} sm={12} md={5} lg={5}>
                <Row>
                  <Col xs={6} sm={6} md={4} lg={4}>
                    <p style={{ fontWeight: 'bold', textAlign: 'left' }}>
                      Trucker*
                    </p>
                  </Col>
                  <Col xs={6} sm={6} md={4} lg={4}>
                    <p style={{ fontWeight: 'bold', textAlign: 'left' }}>
                      From*
                    </p>
                  </Col>
                  <Col xs={6} sm={6} md={4} lg={4}>
                    <p style={{ fontWeight: 'bold', textAlign: 'left' }}>To*</p>
                  </Col>
                </Row>
              </Col>
              <Col xs={6} sm={12} md={7} lg={7}>
                <Row>
                  <Col xs={6} sm={6} md={2} lg={2}>
                    <p style={{ fontWeight: 'bold', textAlign: 'left' }}>
                      No Of Trucks*
                    </p>
                  </Col>
                  <Col xs={6} sm={6} md={2} lg={2}>
                    <p style={{ fontWeight: 'bold', textAlign: 'left' }}>
                      Truck Type*
                    </p>
                  </Col>
                  <Col xs={6} sm={12} md={3} lg={3}>
                    <p style={{ fontWeight: 'bold', textAlign: 'left' }}>
                      Expected Price*
                    </p>
                  </Col>
                  <Col xs={6} sm={6} md={4} lg={4}>
                    <p style={{ fontWeight: 'bold', textAlign: 'left' }}>
                      Current Location
                    </p>
                  </Col>
                  <Col xs={6} sm={6} md={1} lg={1}>
                    <p />
                  </Col>
                </Row>
              </Col>

              <Col xs={12} sm={12} md={12} lg={12}>
                <hr
                  style={{
                    border: '0.1px solid #E0E0E0',
                    margin: '10px -20px 10px',
                  }}
                />
              </Col>
            </Row>
            <form>
              {inputFields.map((inputField, index) => (
                <div
                  key={`${inputField}~${index}`}
                  style={{ marginBottom: '10px' }}
                >
                  <Row style={{ width: '100%' }}>
                    <Col xs={6} sm={12} md={5} lg={5}>
                      <Row>
                        <Col xs={6} sm={6} md={4} lg={4}>
                          {getTruckersForm(inputField, index)}
                        </Col>
                        <Col xs={6} sm={4} md={4} lg={4}>
                          {selectFromCities(inputField, index)}
                        </Col>
                        <Col xs={6} sm={4} md={4} lg={4}>
                          {selectToCities(inputField, index)}
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={6} sm={12} md={7} lg={7}>
                      <Row>
                        <Col xs={6} sm={6} md={2} lg={2}>
                          <CustomInput
                            labelText="No Of Trucks"
                            id="no-of-vehicles4"
                            formControlProps={{
                              fullWidth: true,
                              className: props.classes.demandsCutomForm,
                            }}
                            inputProps={{
                              onChange: event =>
                                handleNoOfVehiclesChange(event, index),
                              type: 'number',
                              value: inputField.no_of_vehicles,
                            }}
                            success={
                              inputField.no_of_vehicles_error === 'success'
                            }
                            error={inputField.no_of_vehicles_error === 'error'}
                          />
                        </Col>
                        <Col xs={6} sm={12} md={2} lg={2}>
                          {getTruckTypes(inputField, index)}
                        </Col>
                        <Col xs={6} sm={6} md={3} lg={3}>
                          <CustomInput
                            labelText="Expected Price"
                            id="no-of-vehicles5"
                            formControlProps={{
                              fullWidth: true,
                              className: props.classes.demandsCutomForm,
                            }}
                            inputProps={{
                              onChange: event =>
                                handlePriceChange(event, index),
                              type: 'number',
                              value: inputField.expected_price,
                            }}
                            success={
                              inputField.expected_price_error === 'success'
                            }
                            error={inputField.expected_price_error === 'error'}
                          />
                        </Col>
                        <Col xs={6} sm={4} md={4} lg={4}>
                          <PlacesAutocomplete
                            value={inputField.current_city}
                            onChange={e => handleChange(e, index)}
                            onSelect={e => handleSelect(e, index)}
                            searchOptions={searchOptions}
                            style={{ marginTop: '5px' }}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div>
                                <input
                                  {...getInputProps({
                                    placeholder: 'Enter loading point',
                                    className: 'location-search-input',
                                  })}
                                  style={{ marginTop: '8px' }}
                                />
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map(suggestion => {
                                    const className = suggestion.active
                                      ? 'suggestion-item--active'
                                      : 'suggestion-item';
                                    const style = suggestion.active
                                      ? {
                                          backgroundColor: '#fafafa',
                                          cursor: 'pointer',
                                        }
                                      : {
                                          backgroundColor: '#ffffff',
                                          cursor: 'pointer',
                                        };
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                          {/* {selectCurrentLocation(inputField, index)} */}
                        </Col>
                        <Col xs={6} sm={6} md={1} lg={1}>
                          <div style={{ marginTop: '8px' }}>
                            <Button
                              size="sm"
                              color="danger"
                              simple
                              justIcon
                              onClick={() => handleRemoveFields(index)}
                            >
                              <Close
                                className={props.classes.underChartIcons}
                              />
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              ))}
              <Row start="xs">
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  style={{ marginTop: '20px' }}
                >
                  <Button
                    color="info"
                    onClick={() => handleAddFields()}
                    style={{ textAlign: 'left' }}
                    simple
                  >
                    Add More
                  </Button>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  style={{ marginTop: '20px' }}
                >
                  {isLoading ? (
                    <CircularProgress
                      className={props.classes.progress}
                      style={{ color: purple[500] }}
                      thickness={7}
                    />
                  ) : (
                    <Button
                      color="success"
                      onClick={e => handleSubmitEdit(e)}
                      style={{ textAlign: 'right', float: 'right' }}
                    >
                      Submit
                    </Button>
                  )}
                </Col>
              </Row>
            </form>
          </div>
        </Grid>
      </Desktop>
      <Mobile>
        <Grid
          fluid
          style={{
            justifyContent: 'center',
            width: '100%',
            paddingRight: '0px',
            paddingLeft: '0px',
          }}
        >
          <div>
            <Snackbars
              place="tr"
              color={notificationText}
              icon={AddAlert}
              message={notificationMessage}
              open={isNotification}
              closeNotification={() => setNotification(false)}
              close
            />
            <form>
              {inputFields.map((inputField, index) => (
                <div
                  key={`${inputField}~${index}`}
                  style={{ marginBottom: '10px' }}
                >
                  <Card>
                    <Row
                      style={{
                        width: '100%',
                        marginRight: '0px',
                        marginLeft: '0px',
                      }}
                    >
                      <Col xs={12} sm={12} md={5} lg={5}>
                        <Row>
                          <Col xs={12} sm={12} md={4} lg={4}>
                            {getTruckersForm(inputField, index)}
                          </Col>
                          <Col xs={12} sm={12} md={4} lg={4}>
                            {selectFromCities(inputField, index)}
                          </Col>
                          <Col xs={12} sm={12} md={4} lg={4}>
                            {selectToCities(inputField, index)}
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} sm={12} md={7} lg={7}>
                        <Row
                          style={{
                            marginRight: '0px',
                            marginLeft: '0px',
                          }}
                        >
                          <Col xs={12} sm={12} md={2} lg={2}>
                            <CustomInput
                              labelText="No Of Trucks"
                              id="no-of-vehicles6"
                              formControlProps={{
                                fullWidth: true,
                                className: props.classes.demandsCutomForm,
                              }}
                              inputProps={{
                                onChange: event =>
                                  handleNoOfVehiclesChange(event, index),
                                type: 'number',
                                value: inputField.no_of_vehicles,
                              }}
                              success={
                                inputField.no_of_vehicles_error === 'success'
                              }
                              error={
                                inputField.no_of_vehicles_error === 'error'
                              }
                            />
                          </Col>
                          <Col xs={12} sm={12} md={2} lg={2}>
                            {getTruckTypes(inputField, index)}
                          </Col>
                          <Col xs={12} sm={12} md={2} lg={2}>
                            <CustomInput
                              labelText="Expected Price"
                              id="no-of-vehicles7"
                              formControlProps={{
                                fullWidth: true,
                                className: props.classes.demandsCutomForm,
                              }}
                              inputProps={{
                                onChange: event =>
                                  handlePriceChange(event, index),
                                type: 'number',
                                value: inputField.expected_price,
                              }}
                              success={
                                inputField.expected_price_error === 'success'
                              }
                              error={
                                inputField.expected_price_error === 'error'
                              }
                            />
                          </Col>
                          <Col xs={12} sm={12} md={3} lg={3}>
                            {/* {selectCurrentLocation(inputField, index)} */}
                            <PlacesAutocomplete
                              value={inputField.current_city}
                              onChange={e => handleChange(e, index)}
                              onSelect={e => handleSelect(e, index)}
                              searchOptions={searchOptions}
                              style={{ marginTop: '5px' }}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                              }) => (
                                <div>
                                  <input
                                    {...getInputProps({
                                      placeholder: 'Enter loading point',
                                      className: 'location-search-input',
                                    })}
                                    style={{ marginTop: '8px' }}
                                  />
                                  <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map(suggestion => {
                                      const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                      const style = suggestion.active
                                        ? {
                                            backgroundColor: '#fafafa',
                                            cursor: 'pointer',
                                          }
                                        : {
                                            backgroundColor: '#ffffff',
                                            cursor: 'pointer',
                                          };
                                      return (
                                        <div
                                          {...getSuggestionItemProps(
                                            suggestion,
                                            {
                                              className,
                                              style,
                                            }
                                          )}
                                        >
                                          <span>{suggestion.description}</span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>
                          </Col>
                          <Col xs={12} sm={12} md={1} lg={1}>
                            <div style={{ marginTop: '8px' }}>
                              <Button
                                size="sm"
                                color="danger"
                                simple
                                justIcon
                                onClick={() => handleRemoveFields(index)}
                              >
                                <Close
                                  className={props.classes.underChartIcons}
                                />
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </div>
              ))}
              <Row
                start="xs"
                style={{
                  marginRight: '0px',
                  marginLeft: '0px',
                }}
              >
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  style={{ marginTop: '20px' }}
                >
                  <Button
                    color="info"
                    onClick={() => handleAddFields()}
                    style={{ textAlign: 'left' }}
                    simple
                  >
                    Add More
                  </Button>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  style={{ marginTop: '20px' }}
                >
                  {isLoading ? (
                    <CircularProgress
                      className={props.classes.progress}
                      style={{ color: purple[500] }}
                      thickness={7}
                    />
                  ) : (
                    <Button
                      color="success"
                      onClick={e => handleSubmitEdit(e)}
                      style={{ textAlign: 'right', float: 'right' }}
                    >
                      Submit
                    </Button>
                  )}
                </Col>
              </Row>
            </form>
          </div>
        </Grid>
      </Mobile>
    </div>
  );
}

export default withStyles(TransactionStyle)(SupplyForm);
