/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import swal from 'sweetalert';
import { Rating } from 'react-simple-star-rating';
import firebase from '../../../../config/config';
import '../../../../assets/scss/RatingCardStyles.scss';

const buttonStyles = {
  background: '#3366FF',
  color: 'white',
  fontSize: '12px',
  textTransform: 'capitalize',
  marginLeft: '20px',
};
const useStyles = makeStyles({
  root: {
    // minWidth: 275,
    marginBottom: '20px',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  header: { textAlign: 'left' },
  ratingCardContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
});

export default function OnTimeDeliveryRatingsCard({
  activeRole,
  currentTransactionData,
}) {
  const db = firebase.firestore();
  const classes = useStyles();
  const [rating, setRating] = useState(
    currentTransactionData?.tripOnTimeDeliveryRating
      ? currentTransactionData?.tripOnTimeDeliveryRating * 20
      : 0
  );

  // Catch Rating value
  const handleRating = rate => {
    setRating(rate );
    submitDrivingRating(rate );

    // other logic
  };
  // Optinal callback functions
  const submitDrivingRating = rate => {
    const transactionRef = db
      .collection('Transactions')
      .doc(currentTransactionData?.transactionId);

    // Set the "capital" field of the city 'DC'
    return transactionRef
      .update({
        tripOnTimeDeliveryRating: rate,
      })
      .then(() => {
        swal(
          'Good job!',
          `You have given ${rate} star rating for on time delivery in ${currentTransactionData?.transactionSerial} Transaction!`,
          'success'
        );
      })
      .catch(
        error =>
          // The document probably doesn't exist.

          error
      );
  };

  const handleResetRating = () => {
    const transactionRef = db
      .collection('Transactions')
      .doc(currentTransactionData?.transactionId);

    // Set the "capital" field of the city 'DC'
    return transactionRef
      .update({
        tripOnTimeDeliveryRating: 0,
      })
      .then(() => {
        setRating(0);
        swal(
          'Good job!',
          `You have reset rating for onTime delivery in ${currentTransactionData?.transactionSerial} Transaction!`,
          'success'
        );
      })
      .catch(
        error =>
          // The document probably doesn't exist.

          error
      );
  };
  return (
    <div className="ratingCardContainer">
      <Typography variant="h5">On Time Delivery</Typography>
      <div>
        <Rating
          onClick={handleRating}
          readonly={
            activeRole === 'ops' || activeRole === 'finance' || rating !== 0
          }
          ratingValue={rating}
          size={40}
          /* Available Props */
        />
        {activeRole === 'ops' && rating !== 0 ? (
          <Button style={buttonStyles} onClick={handleResetRating}>
            Reset
          </Button>
        ) : null}
      </div>
    </div>
  );
}
