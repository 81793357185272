/* eslint-disable consistent-return */
/* eslint-disable no-useless-escape */
/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable no-var */
import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Card from 'components/Card/Card.jsx';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import CardBody from 'components/Card/CardBody.jsx';
import { red } from '@material-ui/core/colors';
import swal from 'sweetalert';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import ApartmentIcon from '@material-ui/icons/Apartment';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import Myfirebase from '../../config/config';
import TrafficTrackingListView from './TrafficTrackingListView';
import apiCaller from '../../utils/apiCallerOutstanding';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const TrafficTracking = () => {
  const classes = useStyles();
  const [trafficTrackingUsers, setTrafficTrackingUsers] = useState([]);
  const [token, setToken] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [adding, setAdding] = useState(false);
  const [verifying] = useState(false);
  //   const [userExists, setUserExists] = useState('');
  const [fetchingUsers, setFetchingUsers] = useState(false);

  const getExistingTrafficTracking = async token => {
    setFetchingUsers(true);
    const endPoint = 'traffic-details';
    // setFetching(true);
    const body = { traffic_uid: '' };
    try {
      await apiCaller(endPoint, body, `Bearer ${token}`).then(res => {
        const data = res.data.body;
        setTrafficTrackingUsers(data);
        setFetchingUsers(false);
      });
    } catch (error) {
      setFetchingUsers(false);
      return error;
    }
  };
  const handleOnChange = (e, type) => {
    const regexName = /^[a-zA-Z ]*$/;
    const regexPhone = /^[6-9]\d{9}$/gi;
    const regexEmail = /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/gm;
    switch (type) {
      case 'name':
        // code block

        if (regexName.test(e.target.value)) {
          setName(e.target.value);
          setError('');
          setErrorMessage('');
        } else {
          setError('name');
          setErrorMessage('enter correct name');
        }
        break;
      case 'email':
        // code block
        setEmail(e.target.value);
        if (regexEmail.test(e.target.value)) {
          setError('');
          setErrorMessage('');
        } else {
          setError('email');
          setErrorMessage('enter correct email');
        }
        break;
      case 'phone':
        // code block
        setPhone(e.target.value);
        if (regexPhone.test(e.target.value)) {
          setError('');
          setErrorMessage('');
        } else {
          setError('phone');
          setErrorMessage('enter correct phone number');
        }

        break;
      default:
      // code block
    }
  };
  const handleChange = event => {
    setRole(event.target.value);
  };

  const handleTrafficAddition = async event => {
    const endPoint = 'traffic-details';
    setAdding(true);
    const body = {
      name,
      email,
      phone_number: phone,
      role,
    };

    await apiCaller(endPoint, body, `Bearer ${token}`)
      .then(res => {
        swal(
          'Success!',
          `You have added ${name} as ${role} in Traffic Tracking!`,
          'success'
        );
        setName('');
        setEmail('');
        setRole('');
        setPhone('');
        getExistingTrafficTracking(token);
        setAdding(false);
      })
      .catch(error => {
        setAdding(false);
        swal('Error!', `please check the credentials`, 'error');
      });
  };

  useEffect(() => {
    const userDetails = Myfirebase.auth().currentUser;
    const { Aa } = userDetails;
    setToken(Aa);
    getExistingTrafficTracking(Aa);
  }, []);
  return (
    <div>
      <Card>
        <CardHeader>
          <CardIcon color="primary">
            <ApartmentIcon style={{ color: 'white' }} />
          </CardIcon>
          <h4>
            Add Traffic Tracking <small />
          </h4>
        </CardHeader>
        <CardBody>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={2} direction="row">
                  <TextField
                    id="outlined-basic"
                    label="Name"
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={error === 'name'}
                    value={name}
                    onChange={event => handleOnChange(event, 'name')}
                  />
                </Grid>
                <Grid item xs={12} md={2} direction="row">
                  <TextField
                    id="outlined-basic"
                    label="Phone"
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          {verifying === true ? (
                            <CircularProgress size={20} />
                          ) : (
                            ''
                          )}
                          {error === 'phone' &&
                          errorMessage === 'user already exists' ? (
                            <Tooltip title="user exists">
                              <CheckCircleIcon
                                style={{ color: red[500] }}
                                fontSize="small"
                              />
                            </Tooltip>
                          ) : (
                            ''
                          )}
                        </InputAdornment>
                      ),
                    }}
                    error={error === 'phone'}
                    value={phone}
                    onChange={event => handleOnChange(event, 'phone')}
                  />
                </Grid>
                <Grid item xs={12} md={3} direction="row">
                  <TextField
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={error === 'email'}
                    value={email}
                    onChange={event => handleOnChange(event, 'email')}
                  />
                </Grid>
                <Grid item xs={12} md={2} direction="row">
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    size="small"
                    fullWidth
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Role
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={role}
                      onChange={handleChange}
                    >
                      <MenuItem value="supply">Supply</MenuItem>
                      <MenuItem value="traffic">Traffic</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={1}>
                  {adding ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      variant="contained"
                      style={{ background: '#3DAC16', color: 'white' }}
                      startIcon={<AddIcon />}
                      disabled={
                        name == '' || email == '' || phone == '' || role == ''
                      }
                      onClick={handleTrafficAddition}
                    >
                      Add
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <CardIcon color="primary">
            <ApartmentIcon style={{ color: 'white' }} />
          </CardIcon>
          <h4>
            Traffic Tracking <small />
          </h4>
        </CardHeader>
        <CardBody>
          <TrafficTrackingListView
            trafficTrackingUsers={trafficTrackingUsers}
            fetchingUsers={fetchingUsers}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default TrafficTracking;
