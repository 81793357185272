import { Typography } from '@material-ui/core';
import React from 'react';

const CommonRoutes = ({ routes }) => (
  <div
    style={{
      padding: '20px 10px',
      borderBottom: '1px solid lightgray',
    }}
  >
    <Typography variant="body2" style={{ fontWeight: 'bold' }} gutterBottom>
      Common Branches
    </Typography>
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
    >
      {routes?.map(route => (
        <span
          key={route}
          style={{
            background: '#E7E4F9',
            color: '#6E62B6',
            borderRadius: '5px',
            fontWeight: 'bold',
            padding: '2px 5px',
            textTransform: 'uppercase',
            fontSize: '12px',
            marginRight: '10px',
            marginBottom: '10px',
          }}
        >
          {route}
        </span>
      ))}
    </div>
  </div>
);

export default CommonRoutes;
