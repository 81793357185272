import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import Select from 'react-select';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import swal from 'sweetalert';
import {
  Chip,
  CircularProgress,
  DialogTitle,
  Switch,
  Typography,
} from '@material-ui/core';
import apiCaller from '../../../utils/apiCallerOutstanding';
import myFirebase from '../../../config/config';

const customStyles = {
  menu: provided => ({
    ...provided,
    zIndex: 999, // Set a higher z-index value to make it appear above the dialog content
    position: 'absolute',
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  control: (provided, state) => ({
    ...provided,
    minHeight: '40px',
    width: '100%',
  }),
};

const buttonStyle = {
  backgroundColor: '#3DAC16',
  color: 'white',
  height: '32px',
  width: '178px',
  textTransform: 'capitalize',
  '&:hover': {
    backgroundColor: 'darkgreen',
  },
  marginBottom: '10px',
};

const inputStyle = {
  border: '1px solid #ccc',
  padding: '8px',
  width: '100%',
  height: '40px',
};

export default function AddManufacturer({ refetchList }) {
  const [open, setOpen] = React.useState(false);
  const [address, setAddress] = React.useState('');
  const [companyName, setCompanyName] = React.useState('');
  const [companySector, setCompanySector] = React.useState([]);
  const [companySingleSector, setCompanySingleSector] = React.useState('');
  const [companySize, setCompanySize] = React.useState('');
  const [companyLocation, setCompanyLocation] = React.useState('');
  const [companyLocationLat, setCompanyLocationLat] = React.useState('');
  const [companyLocationLng, setCompanyLocationLng] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isWarehouse, setIsWarehouse] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const searchOptions = {
    componentRestrictions: { country: 'in' },
  };

  const handleLocationChange = newAddress => {
    setCompanyLocation(address);
    setAddress(newAddress);
  };

  const handleSelect = selectedAddress => {
    geocodeByAddress(selectedAddress)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        setCompanyLocationLat(latLng?.lat);
        setCompanyLocationLng(latLng?.lng);
        setCompanyLocation(selectedAddress);
        setAddress(selectedAddress);
      })
      .catch(error => console.error('Error', error));
  };

  const handleCompanynameChange = e => {
    const { value } = e.target;
    const regex = /^[A-Za-z\s]*$/;
    if (regex.test(value)) setCompanyName(e.target.value);
  };

  const clearForm = () => {
    setCompanyName('');
    setCompanySector([]);
    setCompanySize('');
    setCompanyLocation('');
    setCompanyLocationLat('');
    setCompanyLocationLng('');
    setAddress('');
    refetchList();
    handleClose();
  };

  const handleSubmitManufacturer = async () => {
    setIsSubmitting(true);
    const currentUserDetails = myFirebase.auth().currentUser;
    const { Aa: token } = currentUserDetails;
    const body = {
      type: isWarehouse ? 'add_warehouse' : 'add_manufacturer',
      manufacturer_company_name: companyName,
      manufacturer_sector: isWarehouse
        ? companySector?.map(sector => sector?.label)
        : [companySingleSector?.value],
      manufacturer_company_size: companySize?.value,
      manufacturer_location: companyLocation,
      manufacturer_location_lng: companyLocationLat.toString(),
      manufacturer_location_lat: companyLocationLng.toString(),
    };
    const endPoint = 'pipe/trips';
    try {
      await apiCaller(endPoint, body, `Bearer ${token}`, 'pipe');
      setIsSubmitting(false);
      clearForm();
      swal(
        'Success!',
        `you have added ${
          isWarehouse ? 'warehouse' : 'manufacturer'
        } Sucessfully!`,
        'success'
      );
    } catch (e) {
      setIsSubmitting(false);
      swal('Oops!', 'something went wrong!', 'error');
    }
  };

  const handleDelete = value => {
    let selectedSector = companySector;
    selectedSector = selectedSector.filter(item => item.label !== value);
    setCompanySector(selectedSector);
  };

  const sectors = [
    'FMCG',
    'Automobile',
    'Textile',
    'Paper',
    'Electronics',
    "Industrial Equipment's",
    'Pharmaceuticals',
    'Perishables',
    'Construction Materials',
    'Home Appliances',
    'Parcel',
    'E-Commerce',
    'Agricultural',
    'Chemicals',
    'Medical Devices',
    'Defense & Sensitives',
  ];

  const sectorsOptions = sectors.map(item => ({ label: item, value: item }));
  const isDisabled = () =>
    isWarehouse
      ? address === '' ||
        companyName === '' ||
        companySector.length === 0 ||
        companySize === '' ||
        companyLocation === '' ||
        companyLocationLat === '' ||
        companyLocationLng === ''
      : address === '' ||
        companyName === '' ||
        companySingleSector === '' ||
        companySize === '' ||
        companyLocation === '' ||
        companyLocationLat === '' ||
        companyLocationLng === '';

  const handleManufacturerChange = () => {
    setIsWarehouse(prevState => !prevState);
  };

  const handleSectorChange = val => {
    if (isWarehouse) {
      setCompanySector(val);
    } else {
      setCompanySingleSector(val);
    }
  };

  return (
    <div>
      <IconButton
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={handleClickOpen}
      >
        <AddIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            transform: 'translateY(-20%)',
            minHeight: '250px',
            width: '641px',
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          Add {isWarehouse ? 'Warehouse' : 'Manufacturer'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="body2"
                  style={{ fontWeight: !isWarehouse && 'bold' }}
                >
                  Manufacturer
                </Typography>
                <Switch
                  checked={isWarehouse}
                  onChange={handleManufacturerChange}
                  value={isWarehouse ? 'warehouse' : 'manufacturer'}
                  color="primary"
                />
                <Typography
                  variant="body2"
                  style={{ fontWeight: isWarehouse && 'bold' }}
                >
                  Warehouse
                </Typography>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} style={{ flex: 12 }}>
              <TextField
                size="small"
                id="outlined-basic"
                label="Company Name"
                variant="outlined"
                fullWidth
                onChange={handleCompanynameChange}
                value={companyName}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Select
                menuPortalTarget={document.body}
                styles={customStyles}
                options={sectorsOptions}
                placeholder={isWarehouse ? 'Sector (multi)' : 'Sector'}
                value={isWarehouse ? companySector : companySingleSector}
                onChange={e => handleSectorChange(e)}
                controlShouldRenderValue={!isWarehouse}
                isMulti={isWarehouse}
              />
              {isWarehouse && (
                <div>
                  {companySector?.map(sector => (
                    <Chip
                      key={sector?.label}
                      label={sector?.label}
                      onDelete={() => handleDelete(sector?.label)}
                      color="primary"
                      size="small"
                      style={{ margin: '5px' }}
                    />
                  ))}
                </div>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <PlacesAutocomplete
                value={address}
                onChange={handleLocationChange}
                onSelect={handleSelect}
                searchOptions={searchOptions}
                style={{ border: '1px solid' }}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <input
                      {...getInputProps({
                        placeholder: 'Enter Location',
                        className: 'location-search-input',
                        style: inputStyle,
                      })}
                    />
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map(suggestion => {
                        const className = suggestion.active
                          ? 'suggestion-item--active'
                          : 'suggestion-item';
                        const style = suggestion.active
                          ? {
                              backgroundColor: '#fafafa',
                              cursor: 'pointer',
                            }
                          : {
                              backgroundColor: '#ffffff',
                              cursor: 'pointer',
                            };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Select
                menuPortalTarget={document.body}
                styles={customStyles}
                options={['Small', 'Medium', 'Large'].map(item => ({
                  label: item,
                  value: item,
                }))}
                placeholder="Company Size"
                value={companySize}
                onChange={e => setCompanySize(e)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '5px',
              }}
            >
              <Button
                variant="contained"
                style={buttonStyle}
                onClick={handleSubmitManufacturer}
                disabled={isDisabled()}
              >
                {isSubmitting ? <CircularProgress size={16} /> : 'Save'}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
