/* eslint-disable camelcase */
import React, { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import moment from 'moment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Button, CircularProgress } from '@material-ui/core';
import myFirebase from '../../../../config/config';
import {
  fileUploader,
  getSignedURL,
  getViewURL,
} from '../../../../utils/fileUploadHandler';

const db = myFirebase.firestore();

const uploaderStyles = {
  padding: '10px',
  borderRadius: '5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px dashed lightgray',
};
const viewButtonStyles = {
  backgroundColor: '#163BAC',
  borderRadius: '10px',
  padding: '5px 30px',
  width: '100%',
};
const FCUploader = ({ truckerId, rcUploaded, featureFlagFiles }) => {
  const [uploading, setUploading] = React.useState(false);
  const [url, setUrl] = React.useState('');
  const auditLog = myFirebase.functions().httpsCallable('utilities-auditLog');
  const uploadToS3 = (truckNumber, UploadedFile) => {
    const trucker_number = truckNumber.trimRight();
    const body = {
      scope: 'truck',
      type: 'signed_url',
      file_type: 'truck-fitness',
      trucker_id: truckerId,
      truck_number: trucker_number,
    };
    getSignedURL(body, UploadedFile)
      .then(response => {
        const { url: signedURL } = response?.data;
        fileUploader(signedURL, UploadedFile)
          .then(res => {
            if (res === 'success') {
              const viewURLBody = {
                scope: 'truck',
                type: 'view',
                file_type: 'truck-insurance',
                trucker_id: truckerId,
                truck_number: trucker_number,
              };

              setTimeout(() => {
                getViewURL(viewURLBody)
                  .then(result => {
                    setUrl(result?.data?.url);
                    setUploading(false);
                    rcUploaded(true);
                  })
                  .catch(e => setUploading(false));
              }, 4000);
            }
          })
          .catch(e => {
            setUploading(false);
            rcUploaded(true);
          });
      })
      .catch(e => setUploading(false));
  };

  const onDrop = useCallback(
    async acceptedFiles => {
      // Do something with the files
      const userDetails = myFirebase.auth().currentUser;

      const truckNumber = localStorage.getItem('newAddedTruck');

      const UploadedFile = acceptedFiles[0];
      setUploading(!uploading);
      if (featureFlagFiles === 2) {
        uploadToS3(truckNumber, UploadedFile);
      } else if (featureFlagFiles === 0) {
        const podRef = await db.collection('FileUploads');
        // Do something with the files
        const storageRef = myFirebase
          .storage()
          .ref(`Truck_Files/FitnessCertificate/${truckNumber}`);
        const task = storageRef.put(UploadedFile);
        task.on(
          'state_changed',
          snapshot => {
            const percentage =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            if (percentage === 100) {
              // console.log('SNAPSHOT');
            }
          },
          error => {
            console.log(error);
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            task.snapshot.ref.getDownloadURL().then(downloadURL => {
              setUrl(downloadURL);
              try {
                const metaData = {};
                metaData[truckNumber] = {
                  FitnessCertificateUpdatedByName: userDetails.displayName,
                  FitnessCertificateUpdatedByUid: userDetails.uid,
                  FitnessCertificateUpdatedAT: moment().format('llll'),
                  FitnessCertificateFileUploadURL: downloadURL,
                  Truck_Number: truckNumber,
                };

                podRef
                  .doc('Truck_Files')
                  .collection('FitnessCertificate')
                  .doc(truckerId)
                  .set(metaData, { merge: true })
                  .catch(error => {
                    console.error('Error adding document: ', error);
                  });
              } catch (error) {
                console.log(error);
              }
              const auditBody = {
                // data: { ...transaction },
                collection: 'file uploads',
                podFileUploadedBy: userDetails.uid,
                type: 'Uploaded Fitness Certificate File in Transactions',
                message: `${userDetails.email} in file uploads collection inside truckFiles doc inside Fitness Certificate collection with ${truckerId} trucker ID uploaded the file `,
                uploadedAt: moment().format('llll'),
              };
              auditLog(auditBody);
              setUploading(!uploading);
            });
          }
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    acceptedFiles: {
      'image/png': ['.png', '.jpeg', '.jpg'],
      'application/pdf': ['.pdf'],
    },
    onDrop,
  });

  return (
    <div>
      {url !== '' ? (
        <Button
          variant="contained"
          startIcon={<VisibilityIcon style={{ color: 'white' }} />}
          style={viewButtonStyles}
        >
          <a
            href={url}
            target="_blank"
            style={{ color: 'white' }}
            rel="noreferrer"
          >
            View
          </a>
        </Button>
      ) : (
        <div>
          {uploading ? (
            <CircularProgress />
          ) : (
            <div {...getRootProps()} style={uploaderStyles}>
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Drop the files here ...</p>
              ) : (
                <p>Upload Fitness Certificate</p>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FCUploader;
