/* eslint-disable react/no-array-index-key */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-shadow */
/* eslint-disable no-console */
/* eslint-disable no-prototype-builtins */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
/* eslint-disable class-methods-use-this */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// @material-ui/core components
import classNames from 'classnames';

import withStyles from '@material-ui/core/styles/withStyles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Check from '@material-ui/icons/Check';
import Snackbars from 'components/Snackbar/Snackbar.jsx';
import AddAlert from '@material-ui/icons/AddAlert';
// import { Manager, Target, Popper } from 'react-popper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Refresh from '@material-ui/icons/Refresh';

import Grow from '@material-ui/core/Grow';
import Person from '@material-ui/icons/MoreVert';
import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';
import TransactionStyle from 'assets/jss/material-dashboard-pro-react/views/TransactionStyle';
import Checkbox from '@material-ui/core/Checkbox';
import ReactSelect from 'react-select';

import Responsive from 'react-responsive';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import Close from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Accordion from 'components/AccordianCustom/Accordian.jsx';
import Danger from 'components/Typography/Danger.jsx';
import Skeleton from 'react-loading-skeleton';
import { Row, Col } from 'react-flexbox-grid';

import MatchedHistory from './matchedHistory';
import DemandsForm from './demandsForm.jsx';
import apiCaller from '../../utils/apiCaller';

import './styles.css';

import firebase from '../../config/config';
import SupplyForm from './supplyForm.jsx';

const Desktop = props => <Responsive {...props} minWidth={701} />;
const Mobile = props => <Responsive {...props} maxWidth={700} />;
const db = firebase.firestore();
const auditLog = firebase.functions().httpsCallable('utilities-auditLog');

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" {...props} ref={ref} />
));

class Demands extends React.Component {
  interval = null;

  constructor(props) {
    super(props);
    this.state = {
      demandsData: [],
      supplyData: [],
      truckTypes: [],
      truckers: [],
      trucker: '',
      accordianData: [],
      isLoading: false,
      sampleLoading: true,
      supplyDate: '',
      fromCities: [],
      cities: [],
      toCities: [],
      fromCitiesObject: [],
      selectedFromCity: [],
      truckerModal: false,
      transporterModal: false,
      truckerName: '',
      truckerPhoneNumber: '',
      transporterName: '',
      transporterPhoneNumber: '',
      notificationColor: 'success',
      isNotification: false,
      notificationMessage: '',
      isLoadingButton: false,
      open: false,
      truckerCompanyName: '',
      transporterCompanyName: '',
      truckerSelectedCity: [],
      transporterSelectedCity: [],
      truckerTruckNumberError: '',
      truckerTruckNumber: '',
      truckerTruckType: '',
      truckerTruckTypeError: '',
      transporters: [],
      truckerTruckInfo: [],
      truckerTruckInfoError: '',
      currentActiveAccordian: 0,
      isRefresh: false,
      refreshMatched: false,
      isPolling: true,
      supplyModal: false,
      demandModal: false,
      notificationText: '',
    };
    this.supplyDateChange = this.supplyDateChange.bind(this);
    this.handleFromCity = this.handleFromCity.bind(this);
    this.handleToCity = this.handleToCity.bind(this);
    this.handleCurrentCity = this.handleCurrentCity.bind(this);
    this.handleSubmitTrucker = this.handleSubmitTrucker.bind(this);
    this.handleSubmitTransporter = this.handleSubmitTransporter.bind(this);
    this.handleChanged = this.handleChanged.bind(this);
    this.refreshDemands = this.refreshDemands.bind(this);
  }

  handleClick = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  };

  componentDidMount() {
    this.getCities();
    this.getTruckers();
    this.getTransporters();
    this.getAllTruckTypes();
    this.getDemands();
    this.onFocus();
    window.addEventListener('focus', this.onFocus);
    window.addEventListener('blur', this.onBlur);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    window.removeEventListener('focus', this.onFocus);
    window.removeEventListener('blur', this.onBlur);
  }

  onFocus = () => {
    this.interval = setInterval(() => {
      this.getDemands();
    }, 120000);
  };

  onBlur = () => {
    clearInterval(this.interval);
  };

  refreshDemands() {
    this.setState({
      isRefresh: true,
    });
    this.getCities();
    this.getAllTruckTypes();
    this.getTruckers();
    this.getTransporters();
    this.getDemands();
    setTimeout(() => {
      this.setState({
        isRefresh: false,
      });
    }, 1000);
  }

  /**
   * will return All the Cities Available for User
   * @function
   * @param {String} companyId
   */
  getDemands() {
    this.setState({
      isLoading: true,
    });
    const body = {
      offset: 0,
      total: 1000,
    };
    apiCaller(
      'matchmaking-api/list-demand',
      'post',
      body,
      true,
      false,
      'matchMaking'
    )
      .then(response => {
        const demands = [];
        if (response.hasOwnProperty('message')) {
          this.setState({
            isLoading: false,
          });
          if (response.message === 'success') {
            if (response.demands.hasOwnProperty('columnMetadata')) {
              const { columnMetadata, records } = response.demands;
              const headers = [];
              columnMetadata.forEach(column => {
                headers.push(column.label);
              });
              records.forEach(record => {
                const singleRecord = {};
                record.forEach((newRecord, i) => {
                  const key = headers[i];
                  singleRecord[key] = newRecord[Object.keys(newRecord)[0]];
                  if (key === 'price' && singleRecord[key] === true) {
                    singleRecord[key] = '___';
                  }
                });
                singleRecord.limit = 2;
                demands.push(singleRecord);
              });
              this.setState(
                {
                  demandsData: demands,
                },
                () => this.setAccordianData()
              );
            }
          }
        }
      })
      .catch(err => {
        this.setState({
          isLoading: false,
        });
      });
  }

  setAccordianData() {
    const { demandsData } = this.state;
    if (demandsData.length > 0) {
      this.getProbableMatch(0);
    }
    const accordianData = [];
    demandsData.forEach((demand, i) => {
      const title = this.renderTitle(demand);
      accordianData.push({
        title: this.renderTitle(demand),
        content: this.renderDescription(demand, i),
      });
    });
    this.setState({
      accordianData,
    });
  }

  /**
   * will return All the Cities Available for User
   * @function
   * @param {String} companyId
   */
  getTruckers() {
    const body = {
      offset: 0,
      total: 5000,
    };
    apiCaller(
      'matchmaking-api/list-truckers',
      'post',
      body,
      true,
      false,
      'matchMaking'
    )
      .then(response => {
        const truckers = [];
        if (response.hasOwnProperty('message')) {
          if (response.message === 'success') {
            if (response.truckers.hasOwnProperty('columnMetadata')) {
              const { columnMetadata } = response.truckers;
              const { records } = response.truckers;
              const headers = [];
              const recordsData = [];
              columnMetadata.forEach(column => {
                headers.push(column.label);
              });
              records.forEach(record => {
                const singleRecord = {};
                record.forEach((newRecord, i) => {
                  const key = headers[i];
                  singleRecord[key] = newRecord[Object.keys(newRecord)[0]];
                });
                truckers.push(singleRecord);
              });
              this.setState({
                truckers,
              });
            }
          }
        }
      })
      .catch(err => {});
  }

  /**
   * will return All the Transporters Available for User
   * @function
   * @param {String} companyId
   */
  getTransporters() {
    const body = {
      offset: 0,
      total: 5000,
    };
    apiCaller(
      'matchmaking-api/list-transporters',
      'post',
      body,
      true,
      false,
      'matchMaking'
    )
      .then(response => {
        const transporters = [];
        if (response.hasOwnProperty('message')) {
          if (response.message === 'success') {
            if (response.transporters.hasOwnProperty('columnMetadata')) {
              const { columnMetadata, records } = response.transporters;
              const headers = [];
              columnMetadata.forEach(column => {
                headers.push(column.label);
              });
              records.forEach(record => {
                const singleRecord = {};
                record.forEach((newRecord, i) => {
                  const key = headers[i];
                  singleRecord[key] = newRecord[Object.keys(newRecord)[0]];
                });
                transporters.push(singleRecord);
              });
              this.setState({
                transporters,
              });
            }
          }
        }
      })
      .catch(err => {});
  }

  /**
   * will get all truck types from DB
   * @function
   */
  getAllTruckTypes() {
    const truckTypesRef = db.collection(`Global`).doc(`TruckTypes`);
    truckTypesRef
      .get()
      .then(
        resultData => {
          const { truckTypes } = resultData.data();
          this.setState({ truckTypes });
        },
        err => {
          console.log('err', err);
        }
      )
      .catch(err => {
        console.log('err', err);
      });
  }

  handleFromCity = selectedCity => {
    this.setState({
      selectedFromCity: selectedCity,
      fromCity: selectedCity.value,
    });
  };

  handleToCity = selectedCity => {
    this.setState({
      selectedToCity: selectedCity,
      toCity: selectedCity.value,
    });
  };

  handleCurrentCity = selectedCity => {
    this.setState({
      selectedCurrentCity: selectedCity,
    });
  };

  /**
   * will return All the Cities Available for User
   * @function
   * @param {String} companyId
   */
  getCities() {
    const cityRef = db
      .collection('Cities')
      .where('isActive', '==', true)
      .orderBy('name', 'asc');
    cityRef.onSnapshot(citiesSnapshot => {
      const cities = [];
      const fromCitiesObject = [];
      citiesSnapshot.forEach(city => {
        cities.push(city.data().name);
        fromCitiesObject.push({
          value: city.data().name,
          label: city.data().name,
        });
      });
      this.setState({
        cities,
        fromCities: cities,
        toCities: cities,
        fromCitiesObject,
      });
    });
  }

  supplyDateChange(supplyDate) {
    this.setState({ supplyDate });
  }

  handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
    });
  }

  handleMatched(demand) {
    const { userDetails, systemDetails } = this.props;
    const body = {
      probable_match_id: demand.id,
      action: 'matched',
    };
    apiCaller(
      'matchmaking-api/select-probable-match',
      'post',
      body,
      true,
      false,
      'matchMaking'
    )
      .then(response => {
        if (response.hasOwnProperty('message')) {
          if (response.message === 'successfully added') {
            const auditBody = {
              data: {
                response,
              },
              collection: 'MatchMaking',
              updatedBy: userDetails.uid,
              systemDetails,
              type: 'MatchMaking Match Demand',
              message: `${userDetails.email} Matched Demand [id: ${demand.id}]`,
            };
            auditLog(auditBody);
            this.setState({
              isLoading: true,
              refreshMatched: true,
            });
            setTimeout(() => {
              this.getDemands();
              this.setState({
                refreshMatched: false,
              });
            }, 1000);
          } else {
            let message = 'Oops Something went wrong! Please Try Again';
            if (response.hasOwnProperty('message')) {
              if (
                response.message ===
                'cannot match as probable matched were updated'
              ) {
                message =
                  'Match failed, the supply has been matched previously with another demand';
              }
              message = response.message;
            }
            this.setState({
              isLoadingButton: false,
              isNotification: true,
              notificationColor: 'danger',
              notificationText: message,
            });
            setTimeout(() => {
              this.setState({
                isNotification: false,
                notificationColor: '',
                notificationText: '',
              });
            }, 5000);
            this.getDemands();
          }
        }
      })
      .catch(err => {
        let message = 'Oops Something went wrong! Please Try Again';
        if (err.hasOwnProperty('message')) {
          message = err.message;
        }
        this.setState({
          isLoadingButton: false,
          isNotification: true,
          notificationColor: 'danger',
          notificationText: message,
        });
      });
  }

  increeseLimit = demand => {
    const { demandsData } = this.state;
    const newDemandsData = [...demandsData];
    newDemandsData.forEach((newDemand, i) => {
      if (newDemand.demand_id === demand.demand_id) {
        const { limit } = newDemand;
        newDemandsData[i].limit = limit + 2;
      }
    });
    this.setState(
      {
        demandsData: newDemandsData,
      },
      () => this.setAccordianData()
    );
  };

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'required':
        if (this.verifyRequired(event.target.value)) {
          this.setState({
            [`${stateName}Error`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}Error`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({
            [`${stateName}Error`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}Error`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'number':
        if (this.verifyNumber(event.target.value)) {
          this.setState({
            [`${stateName}Error`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}Error`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'alpha':
        if (this.verifyAlpha(event.target.value)) {
          this.setState({
            [`${stateName}Error`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}Error`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({
            [`${stateName}Error`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}Error`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'pan':
        if (this.verifyPan(event.target.value)) {
          this.setState({
            [`${stateName}Error`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}Error`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'phone':
        if (this.verifyPhone(event.target.value)) {
          this.setState({
            [`${stateName}Error`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}Error`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'ifsc':
        if (this.verifyIfsc(event.target.value)) {
          this.setState({
            [`${stateName}Error`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}Error`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'gst':
        if (this.verifyGst(event.target.value)) {
          this.setState({
            [`${stateName}Error`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}Error`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      default:
        break;
    }
  }

  validateTrucker() {
    const {
      truckerName,
      truckerPhoneNumber,
      truckerSelectedCity,
      truckerCompanyName,
      truckerTruckInfo,
    } = this.state;
    let error = false;
    return new Promise((resolve, reject) => {
      if (this.verifyLength(truckerName, 1)) {
        this.setState({
          truckerNameError: '',
        });
      } else {
        error = true;
        this.setState({
          truckerNameError: 'error',
        });
      }

      if (this.verifyPhone(truckerPhoneNumber, 1)) {
        this.setState({
          truckerPhoneNumberError: '',
        });
      } else {
        error = true;
        this.setState({
          truckerPhoneNumberError: 'error',
        });
      }

      if (this.verifyLength(truckerCompanyName, 1)) {
        this.setState({
          truckerCompanyNameError: '',
        });
      } else {
        error = true;
        this.setState({
          truckerCompanyNameError: 'error',
        });
      }

      if (truckerSelectedCity.length > 0) {
        this.setState({
          truckerSelectedCityError: '',
        });
      } else {
        error = true;
        this.setState({
          truckerSelectedCityError: 'error',
        });
      }

      if (truckerTruckInfo.length > 0) {
        truckerTruckInfo.forEach((truck, i) => {
          // if (this.verifyLength(truck.truckerTruckNumber, 1)) {
          //   truckerTruckInfo[i].truckerTruckNumberError = '';
          // } else {
          //   truckerTruckInfo[i].truckerTruckNumberError = 'error';
          //   error = true;
          // }
          if (this.verifyLength(truck.truckerTruckType, 1)) {
            truckerTruckInfo[i].truckerTruckTypeError = '';
          } else {
            truckerTruckInfo[i].truckerTruckTypeError = 'error';
            error = true;
          }
        });
        this.setState({
          truckerTruckInfo,
          truckerTruckInfoError: '',
        });
      } else {
        this.setState({
          truckerTruckInfoError: 'Please Add Trucks',
        });
      }

      if (error) {
        reject(error);
      } else {
        resolve('success');
      }
    });
  }

  validateTransporter() {
    const {
      transporterName,
      transporterPhoneNumber,
      transporterCompanyName,
    } = this.state;
    let error = false;
    return new Promise((resolve, reject) => {
      if (this.verifyLength(transporterName, 1)) {
        this.setState({
          transporterNameError: '',
        });
      } else {
        error = true;
        this.setState({
          transporterNameError: 'error',
        });
      }
      if (this.verifyLength(transporterCompanyName, 1)) {
        this.setState({
          transporterCompanyNameError: '',
        });
      } else {
        error = true;
        this.setState({
          transporterCompanyNameError: 'error',
        });
      }
      if (this.verifyPhone(transporterPhoneNumber, 1)) {
        this.setState({
          transporterPhoneNumberError: '',
        });
      } else {
        error = true;
        this.setState({
          transporterPhoneNumberError: 'error',
        });
      }

      if (error) {
        reject('Error');
      } else {
        resolve('Success');
      }
    });
  }

  handleSubmitTransporter() {
    const { userDetails, systemDetails } = this.props;
    this.setState({
      isLoadingButton: true,
    });
    this.validateTransporter()
      .then(() => {
        const {
          transporterName,
          transporterPhoneNumber,
          transporterCompanyName,
        } = this.state;
        const body = {
          name: transporterName,
          phone_number: transporterPhoneNumber,
          company_name: transporterCompanyName,
        };
        apiCaller(
          'matchmaking-api/add-transporter-lead',
          'post',
          body,
          true,
          false,
          'matchMaking'
        )
          .then(response => {
            if (response.message === 'success') {
              this.handleClose('transporterModal');
              this.setState({
                isNotification: true,
                notificationColor: 'success',
                notificationText: 'Successfully Added Transporter',
                transporterName: '',
                transporterPhoneNumber: '',
                transporterCompanyName: '',
                isLoadingButton: false,
              });
              const auditBody = {
                data: {
                  response,
                },
                collection: 'MatchMaking',
                updatedBy: userDetails.uid,
                systemDetails,
                type: 'MatchMaking Add Transporter Lead',
                message: `${userDetails.email} Added Transporter Lead ${transporterName}`,
              };
              auditLog(auditBody);
              this.getTransporters();
            } else {
              this.setState({
                isLoadingButton: false,
                isNotification: true,
                notificationColor: 'danger',
                notificationText: 'Oops Something went wrong! Please Try Again',
              });
            }
          })
          .catch(err => {
            console.log('ERROR in', err);
            this.setState({
              isNotification: true,
              notificationColor: 'danger',
              notificationText: 'Oops Something went wrong! Please Try Again',
            });
          });
      })
      .catch(err => {});
  }

  handleSubmitTrucker() {
    const { userDetails, systemDetails } = this.props;
    this.validateTrucker()
      .then(() => {
        const {
          truckerName,
          truckerPhoneNumber,
          truckerSelectedCity,
          truckerCompanyName,
          truckerTruckInfo,
        } = this.state;
        const truck_info = [];
        truckerTruckInfo.forEach(truck => {
          truck_info.push({
            truck_type: truck.truckerTruckType,
          });
        });
        const newCities = [];
        truckerSelectedCity.forEach(city => {
          newCities.push(city.value);
        });
        const body = {
          name: truckerName,
          phone_number: truckerPhoneNumber,
          company_name: truckerCompanyName,
          preferred_cities: newCities,
          truck_info,
        };
        this.setState({
          isLoadingButton: true,
        });
        apiCaller(
          'matchmaking-api/add-trucker-lead',
          'post',
          body,
          true,
          false,
          'matchMaking'
        )
          .then(response => {
            if (response.message === 'success') {
              this.handleClose('truckerModal');
              this.setState({
                isNotification: true,
                notificationColor: 'success',
                notificationText: 'Successfully Added Trucker Lead',
                truckerName: '',
                truckerNameError: '',
                truckerPhoneNumber: '',
                truckerPhoneNumberError: '',
                truckerSelectedCity: [],
                truckerSelectedCityError: '',
                truckerCompanyName: '',
                truckerTruckType: '',
                truckerTruckTypeError: '',
                truckerTruckNumberError: '',
                truckerTruckNumber: '',
                truckerTruckInfo: [],
                isLoadingButton: false,
              });
              const auditBody = {
                data: {
                  response,
                },
                collection: 'MatchMaking',
                updatedBy: userDetails.uid,
                systemDetails,
                type: 'MatchMaking Add Trucker Lead',
                message: `${userDetails.email} Added Trucker Lead ${truckerName}`,
              };
              auditLog(auditBody);
              this.getTruckers();
            } else {
              this.setState({
                isNotification: true,
                notificationColor: 'danger',
                notificationText: 'Oops Something went wrong! Please Try Again',
                isLoadingButton: false,
              });
            }
          })
          .catch(err => {
            console.log('ERROR in', err);
            this.setState({
              isNotification: true,
              notificationColor: 'danger',
              notificationText: 'Oops Something went wrong! Please Try Again',
              isLoadingButton: false,
            });
          });
      })
      .catch(err => {});
  }

  /**
   * closes the modal button
   * @param {*} modal
   */
  handleClose(modal) {
    const x = [];
    x[modal] = false;
    this.setState(x);
  }

  /**
   * closes the modal button
   * @param {*} modal
   */
  handleSubmitDemand() {
    this.handleClose('demandModal');
    this.setState({
      isNotification: true,
      notificationColor: 'success',
      notificationText: 'Successfully Added Demand',
    });
    this.getDemands();
    setTimeout(() => {
      this.setState({
        isNotification: false,
        notificationColor: 'success',
        notificationText: 'Successfully Added Demand',
      });
    }, 5000);
  }

  /**
   * closes the modal button
   * @param {*} modal
   */
  handleSubmitSupply() {
    const { userDetails, systemDetails } = this.props;
    this.handleClose('supplyModal');
    this.setState({
      isNotification: true,
      notificationColor: 'success',
      notificationText: 'Successfully Added Supply',
    });
    this.getDemands();
    setTimeout(() => {
      this.setState({
        isNotification: false,
        notificationColor: 'success',
        notificationText: 'Successfully Added Supply',
      });
    }, 5000);
  }

  hideAlert() {
    this.setState({
      alert: null,
    });
    // eslint-disable-next-line react/destructuring-assignment
    this.props.history.push('/sales/truckers/view');
  }

  // function that returns true if value is there, false otherwise
  verifyRequired(value) {
    if (value) {
      return true;
    }
    return false;
  }

  // function that returns true if value is Valid GST, false otherwise
  verifyGst(value) {
    const gstRex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    if (gstRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    const emailRex = /[^\s@]+@[^\s@]+\.[^\s@]+/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that returns true if value is email, false otherwise
  verifyAlpha(value) {
    const emailRex = /^[a-zA-Z() ]+$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if value contains only numbers
  verifyNumber(value) {
    const numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if value contains PanCard
  verifyPan(value) {
    const regpan = /(^([a-zA-Z]{5})([0-9]{4})([a-zA-Z]{1})$)/;
    if (regpan.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if value contains PanCard
  verifyPhone(value) {
    const regPhone = /^[6789]\d{9}$/;
    if (regPhone.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  verifyIfsc(value) {
    const regIfsc = /^[A-Za-z]{4}0[A-Z0-9]{6}$/;
    if (regIfsc.test(value)) {
      return true;
    }
    return false;
  }

  handleClickOpen(modal) {
    const x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleMultipleTruckerCity = event => {
    this.setState({ truckerSelectedCity: event });
  };

  handleMultipleTransporterCity = event => {
    this.setState({ transporterSelectedCity: event.target.value });
  };

  renderTableHeader() {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={4} lg={4}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4} lg={4}>
              <p className={classes.tableHeader} style={{ textAlign: 'left' }}>
                Transporter
              </p>
            </GridItem>
            <GridItem xs={12} sm={12} md={4} lg={4}>
              <p className={classes.tableHeader}>From</p>
            </GridItem>
            <GridItem xs={12} sm={12} md={4} lg={4}>
              <p className={classes.tableHeader}>To</p>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} md={4} lg={4}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4} lg={4}>
              <p className={classes.tableHeader}>Truck Type</p>
            </GridItem>
            <GridItem xs={12} sm={12} md={4} lg={4}>
              <p className={classes.tableHeader} style={{ textAlign: 'right' }}>
                Expected Price
              </p>
            </GridItem>
            <GridItem xs={12} sm={12} md={4} lg={4}>
              <p className={classes.tableHeader}>Material Type</p>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} md={4} lg={4}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={5} lg={5}>
              <p className={classes.tableHeader}>Loading Point</p>
            </GridItem>
            <GridItem xs={12} sm={12} md={5} lg={5}>
              <p className={classes.tableHeader}>Unloading Point</p>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    );
  }

  renderTitle(demand) {
    const { classes } = this.props;
    const {
      transporter_name,
      from_location,
      to_location,
      type_of_vehicle,
      price,
      loading_point,
      unloading_point,
      material_type,
    } = demand;

    return (
      <div>
        <Desktop>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4} lg={4}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4} lg={4}>
                  <p className={classes.tableRow} style={{ textAlign: 'left' }}>
                    {transporter_name}
                  </p>
                </GridItem>
                <GridItem xs={12} sm={12} md={4} lg={4}>
                  <p className={classes.tableRow}>{from_location}</p>
                </GridItem>
                <GridItem xs={12} sm={12} md={4} lg={4}>
                  <p className={classes.tableRow}>{to_location}</p>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={12} md={4} lg={4}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4} lg={4}>
                  <p className={classes.tableRow}>{type_of_vehicle}</p>
                </GridItem>
                <GridItem xs={12} sm={12} md={4} lg={4}>
                  <p
                    className={classes.tableRow}
                    style={{ textAlign: 'right' }}
                  >
                    {Intl.NumberFormat('en-IN').format(price)}
                  </p>
                </GridItem>
                <GridItem xs={12} sm={12} md={4} lg={4}>
                  <p className={classes.tableRow}>{material_type}</p>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={12} md={4} lg={4}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={5} lg={5}>
                  <p className={classes.tableRow}>{loading_point || '___'}</p>
                </GridItem>
                <GridItem xs={12} sm={12} md={5} lg={5}>
                  <p className={classes.tableRow}>{unloading_point || '___'}</p>
                </GridItem>
                <GridItem xs={12} sm={12} md={2} lg={2} />
              </GridContainer>
            </GridItem>
          </GridContainer>
        </Desktop>
        <Mobile>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card
                className={classes.card}
                style={{ marginTop: '2px', marginBottom: '5px' }}
              >
                <CardBody>
                  <GridContainer>
                    <GridItem xs={6} sm={6} md={12} lg={12}>
                      <p
                        style={{
                          fontWeight: 'bold',
                          fontSize: '12px',
                          marginBottom: '3px',
                          textAlign: 'left',
                        }}
                      >
                        {transporter_name}
                      </p>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={12} lg={12}>
                      <p
                        style={{
                          fontSize: '12px',
                          marginBottom: '3px',
                          textAlign: 'right',
                        }}
                      >
                        {type_of_vehicle}
                      </p>
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      style={{ marginTop: '10px', marginBottom: '10px' }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <p
                          style={{
                            fontWeight: 'bold',
                            fontSize: '12px',
                            marginBottom: '3px',
                            textAlign: 'left',
                          }}
                        >
                          {from_location}
                        </p>
                        <p
                          style={{
                            fontSize: '12px',
                            marginBottom: '3px',
                            textAlign: 'center',
                          }}
                        >
                          <i
                            className="fa  fa-arrow-right"
                            style={{
                              marginLeft: '2px',
                              marginRight: '2px',
                            }}
                            aria-hidden="true"
                          />
                        </p>
                        <p
                          style={{
                            fontWeight: 'bold',
                            fontSize: '12px',
                            marginBottom: '3px',
                            textAlign: 'right',
                          }}
                        >
                          {to_location}
                        </p>
                      </div>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={12} lg={12}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <p
                          style={{
                            fontWeight: 'bold',
                            fontSize: '12px',
                            marginBottom: '3px',
                            textAlign: 'left',
                          }}
                        >
                          {Intl.NumberFormat('en-IN').format(price)}
                        </p>
                      </div>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={12} lg={12}>
                      <p
                        style={{
                          fontSize: '12px',
                          marginBottom: '3px',
                          textAlign: 'right',
                        }}
                      >
                        {material_type}
                      </p>
                    </GridItem>
                  </GridContainer>
                  <GridItem />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </Mobile>
      </div>
    );
  }

  renderSupplyHeader() {
    const { classes } = this.props;
    return (
      <GridContainer style={{ display: 'flex', alignItems: 'center' }}>
        <GridItem xs={12} sm={12} md={4} lg={4}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4} lg={4}>
              <p
                className={classes.supplyTableHeader}
                style={{ textAlign: 'left' }}
              >
                Trucker
              </p>
            </GridItem>
            <GridItem xs={12} sm={12} md={4} lg={4}>
              <p className={classes.supplyTableHeader}>From</p>
            </GridItem>
            <GridItem xs={12} sm={12} md={4} lg={4}>
              <p className={classes.supplyTableHeader}>To</p>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} md={4} lg={4}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4} lg={4}>
              <p className={classes.supplyTableHeader}>Truck Type</p>
            </GridItem>
            <GridItem xs={12} sm={12} md={4} lg={4}>
              <p
                className={classes.supplyTableHeader}
                style={{ textAlign: 'right' }}
              >
                Price
              </p>
            </GridItem>
            <GridItem xs={12} sm={12} md={4} lg={4}>
              <p className={classes.supplyTableHeader}>TXN No</p>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} md={4} lg={4}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6} lg={6}>
              <p
                className={classes.supplyTableHeader}
                style={{ textAlign: 'left' }}
              >
                Current Location
              </p>
            </GridItem>
            {/* <GridItem xs={12} sm={12} md={4} lg={4}>
              <p
                className={classes.supplyTableHeader}
                style={{ textAlign: 'left' }}
              >
                Matched Cities
              </p>
            </GridItem> */}
            <GridItem xs={12} sm={12} md={6} lg={6}>
              <p className={classes.supplyTableHeader}>Matched</p>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <hr
            style={{
              marginTop: '5px',
              marginBottom: '5px',
              color: '#000',
              borderTop: '1px solid #9E9E9E',
            }}
          />
        </GridItem>
      </GridContainer>
    );
  }

  getProbableMatch(i) {
    const { demandsData } = this.state;
    const newDemands = [...demandsData];
    const row = newDemands[i];
    const { demand_id } = row;
    if (!row.hasOwnProperty('probableMatches')) {
      const body = {
        demand_id,
        offset: 0,
        total: 1000,
      };
      apiCaller(
        'matchmaking-api/list-probable-matches',
        'post',
        body,
        true,
        false,
        'matchMaking'
      )
        .then(response => {
          const probable_matches = [];
          if (response.hasOwnProperty('message')) {
            this.setState({
              isLoading: false,
            });
            if (response.message === 'success') {
              if (response.probable_matches.hasOwnProperty('columnMetadata')) {
                const { columnMetadata, records } = response.probable_matches;
                const headers = [];
                columnMetadata.forEach(column => {
                  headers.push(column.label);
                });
                records.forEach(record => {
                  const singleRecord = {};
                  record.forEach((newRecord, index) => {
                    const key = headers[index];
                    singleRecord[key] = newRecord[Object.keys(newRecord)[0]];
                  });
                  probable_matches.push(singleRecord);
                });
                row.probableMatches = probable_matches;
                newDemands[i] = row;
                this.setState(
                  {
                    demandsData: newDemands,
                  },
                  () => this.setAccordianData()
                );
              }
            }
          }
        })
        .catch(err => {
          this.setState({
            isLoading: false,
          });
        });
    }
  }

  renderDescription(demand, i) {
    const { classes } = this.props;
    let supplyData = [];
    if (demand.hasOwnProperty('probableMatches')) {
      supplyData = demand.probableMatches;
    }
    return (
      <div style={{ width: '100%' }}>
        <Desktop>
          <div
            style={{
              width: '100%',
              backgroundColor: '#cfe5ef',
              padding: '10px',
              borderRadius: '4px',
            }}
          >
            {!demand.hasOwnProperty('probableMatches') ? (
              this.skeltonViewSupply()
            ) : (
              <div>
                {supplyData.length <= 0 ? (
                  <p style={{ textAlign: 'center', fontSize: '16px' }}>
                    No Probable Matches Found
                  </p>
                ) : (
                  <div>
                    {this.renderSupplyHeader()}
                    {supplyData.map(
                      (data, i) =>
                        i <= demand.limit && (
                          <div key={i}>
                            <Desktop>
                              <GridContainer
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <GridItem xs={12} sm={12} md={4} lg={4}>
                                  <GridContainer>
                                    <GridItem xs={12} sm={12} md={4} lg={4}>
                                      <p
                                        className={classes.tableRow}
                                        style={{ textAlign: 'left' }}
                                      >
                                        {data.trucker_name}
                                      </p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4} lg={4}>
                                      <p className={classes.tableRow}>
                                        {data.from_location}
                                      </p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4} lg={4}>
                                      <p className={classes.tableRow}>
                                        {data.to_location}
                                      </p>
                                    </GridItem>
                                  </GridContainer>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4} lg={4}>
                                  <GridContainer>
                                    <GridItem xs={12} sm={12} md={4} lg={4}>
                                      <p className={classes.tableRow}>
                                        {data.type_of_vehicle}
                                      </p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4} lg={4}>
                                      <p
                                        className={classes.tableRow}
                                        style={{ textAlign: 'right' }}
                                      >
                                        {data.price === true
                                          ? '___'
                                          : Intl.NumberFormat('en-IN').format(
                                              data.price
                                            )}
                                      </p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4} lg={4}>
                                      <p
                                        className={classes.tableRow}
                                        style={{ textAlign: 'left' }}
                                      >
                                        {data.transaction_serial === true
                                          ? '___'
                                          : data.transaction_serial}
                                      </p>
                                    </GridItem>
                                  </GridContainer>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4} lg={4}>
                                  <GridContainer>
                                    <GridItem
                                      xs={12}
                                      sm={12}
                                      md={6}
                                      lg={6}
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <p className={classes.tableRow}>
                                        {data.current_location || '___'}
                                      </p>
                                    </GridItem>
                                    {/* <GridItem
                                      xs={12}
                                      sm={12}
                                      md={4}
                                      lg={4}
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <p className={classes.tableRow}>
                                        {data.matched_cities}
                                      </p>
                                    </GridItem> */}
                                    <GridItem xs={12} sm={12} md={6} lg={6}>
                                      <div
                                        className={classes.checkboxAndRadio}
                                        style={{
                                          top: '0px',
                                          marginTop: '0px',
                                          marginBottom: '0px',
                                          textAlign: 'left',
                                        }}
                                      >
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              tabIndex={-1}
                                              onClick={() =>
                                                this.handleMatched(data)
                                              }
                                              checkedIcon={
                                                <Check
                                                  className={
                                                    classes.checkedIcon
                                                  }
                                                />
                                              }
                                              icon={
                                                <Check
                                                  className={
                                                    classes.uncheckedIcon
                                                  }
                                                />
                                              }
                                              classes={{
                                                checked: classes.checked,
                                                root: classes.checkRoot,
                                              }}
                                            />
                                          }
                                          classes={{
                                            label: classes.label,
                                            root: classes.labelRoot,
                                          }}
                                          label=""
                                        />
                                      </div>
                                    </GridItem>
                                  </GridContainer>
                                </GridItem>

                                <GridItem xs={12} sm={12} md={12}>
                                  <hr
                                    style={{
                                      marginTop: '5px',
                                      marginBottom: '5px',
                                      color: '#000',
                                      borderTop: '1px solid #9E9E9E',
                                    }}
                                  />
                                </GridItem>
                              </GridContainer>
                            </Desktop>
                          </div>
                        )
                    )}
                    {demand.limit < supplyData.length && (
                      <Button
                        color="info"
                        onClick={() => this.increeseLimit(demand)}
                        style={{ textAlign: 'left' }}
                        simple
                      >
                        Load More
                      </Button>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </Desktop>
        <Mobile>
          <div
            style={{
              width: '100%',
              backgroundColor: '#cfe5ef',
              padding: '2px',
              borderRadius: '4px',
            }}
          >
            {supplyData.map((data, i) => (
              <div key={i}>
                <Mobile>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <Card
                        className={classes.card}
                        style={{
                          background: '#cfe5ef',
                          marginTop: '10px',
                          marginBottom: '10px',
                        }}
                      >
                        <CardBody>
                          <GridContainer>
                            <GridItem xs={6} sm={6} md={12} lg={12}>
                              <p
                                style={{
                                  fontWeight: 'bold',
                                  fontSize: '12px',
                                  marginBottom: '3px',
                                  textAlign: 'left',
                                }}
                              >
                                {data.trucker_name}
                              </p>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={12} lg={12}>
                              <p
                                style={{
                                  fontWeight: 'bold',
                                  fontSize: '12px',
                                  marginBottom: '3px',
                                  textAlign: 'right',
                                }}
                              >
                                {data.transaction_serial}
                              </p>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              style={{
                                marginTop: '10px',
                                marginBottom: '10px',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <p
                                  style={{
                                    fontWeight: 'bold',
                                    fontSize: '12px',
                                    marginBottom: '3px',
                                    textAlign: 'left',
                                  }}
                                >
                                  {data.from_location}
                                </p>
                                <p
                                  style={{
                                    fontSize: '12px',
                                    marginBottom: '3px',
                                    textAlign: 'center',
                                  }}
                                >
                                  <i
                                    className="fa  fa-arrow-right"
                                    style={{
                                      marginLeft: '2px',
                                      marginRight: '2px',
                                    }}
                                    aria-hidden="true"
                                  />
                                </p>
                                <p
                                  style={{
                                    fontWeight: 'bold',
                                    fontSize: '12px',
                                    marginBottom: '3px',
                                    textAlign: 'right',
                                  }}
                                >
                                  {data.to_location}
                                </p>
                              </div>
                            </GridItem>
                            <GridItem
                              xs={4}
                              sm={4}
                              md={12}
                              lg={12}
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <p
                                  style={{
                                    fontWeight: 'bold',
                                    fontSize: '12px',
                                    marginBottom: '3px',
                                    textAlign: 'left',
                                  }}
                                >
                                  {data.price === true
                                    ? '___'
                                    : Intl.NumberFormat('en-IN').format(
                                        data.price
                                      )}
                                </p>
                              </div>
                            </GridItem>
                            <GridItem
                              xs={4}
                              sm={4}
                              md={12}
                              lg={12}
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <p
                                style={{
                                  fontSize: '12px',
                                  marginBottom: '3px',
                                  textAlign: 'center',
                                }}
                              >
                                {data.material_type}
                              </p>
                            </GridItem>
                            <GridItem
                              xs={4}
                              sm={4}
                              md={6}
                              lg={6}
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                              }}
                            >
                              <div
                                className={classes.checkboxAndRadio}
                                style={{
                                  top: '0px',
                                  marginTop: '0px',
                                  marginBottom: '0px',
                                  textAlign: 'right',
                                }}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      tabIndex={-1}
                                      onClick={() => this.handleMatched(data)}
                                      checkedIcon={
                                        <Check
                                          className={classes.checkedIcon}
                                        />
                                      }
                                      icon={
                                        <Check
                                          className={classes.uncheckedIcon}
                                        />
                                      }
                                      classes={{
                                        checked: classes.checked,
                                        root: classes.checkRoot,
                                      }}
                                    />
                                  }
                                  classes={{
                                    label: classes.label,
                                    root: classes.labelRoot,
                                  }}
                                  label=""
                                />
                              </div>
                            </GridItem>
                          </GridContainer>
                          <GridItem />
                        </CardBody>
                      </Card>
                    </GridItem>
                  </GridContainer>
                </Mobile>
              </div>
            ))}
          </div>
        </Mobile>
      </div>
    );
  }

  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  // Modal Window To Add The Bank Details
  getSupplyForm() {
    const { classes } = this.props;
    const { supplyModal } = this.state;
    return (
      <Dialog
        fullWidth
        maxWidth="xl"
        classes={{ root: classes.center, paper: classes.modal }}
        style={{
          textAlign: 'center',
        }}
        open={supplyModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.handleClose('supplyModal')}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => this.handleClose('supplyModal')}
          >
            <Close className={classes.modalClose} />
          </IconButton>
          <h4 className={classes.modalTitle}>Add Supply Details</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
          style={{ minHeight: '60vh' }}
        >
          <SupplyForm
            handleOnSubmit={() => this.handleSubmitSupply('supplyModal')}
            userDetails={this.props.userDetails}
            truckers={this.state.truckers}
            truckTypes={this.state.truckTypes}
          />
        </DialogContent>
        <DialogActions className={classes.modalFooter} />
      </Dialog>
    );
  }

  // Modal Window To Add The Bank Details
  getDemandForm() {
    const { classes } = this.props;
    const { demandModal } = this.state;
    return (
      <Dialog
        fullWidth
        maxWidth="xl"
        classes={{ root: classes.center, paper: classes.modal }}
        style={{
          maxWidth: '400px !important',
          textAlign: 'center',
        }}
        // fullScreen
        open={demandModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.handleClose('supplyModal')}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => this.handleClose('demandModal')}
          >
            <Close className={classes.modalClose} />
          </IconButton>
          <h4 className={classes.modalTitle}>Add Demand Details</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
          style={{ minHeight: '60vh' }}
        >
          <DemandsForm
            handleOnSubmit={() => this.handleSubmitDemand('demandModal')}
            userDetails={this.props.userDetails}
            transporters={this.state.transporters}
            truckTypes={this.state.truckTypes}
          />
        </DialogContent>
        <DialogActions className={classes.modalFooter} />
      </Dialog>
    );
  }

  // Will Get select Trucks form
  getTruckTypes(truck, index) {
    const { classes } = this.props;
    const { truckTypes } = this.state;
    return (
      <div>
        <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
            Select Truck Type
          </InputLabel>
          <Select
            MenuProps={{ className: classes.selectMenu }}
            classes={{ select: classes.select }}
            value={truck.truckerTruckType}
            onChange={event => this.handleTruckerTruckType(event, truck, index)}
            inputProps={{ name: 'truckerTruckType', id: 'simple-select' }}
          >
            <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
              Select Truck Type
            </MenuItem>
            {truckTypes.map((truckTypeData, i) => (
              <MenuItem
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
                value={truckTypeData}
              >
                {truckTypeData}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {truck.truckerTruckTypeError === 'error' && (
          <Danger>Please select truck type</Danger>
        )}
      </div>
    );
  }

  handleAddFields() {
    const { truckerTruckInfo } = this.state;
    const newTruckerInfo = [...truckerTruckInfo];
    newTruckerInfo.push({
      truckerTruckNumber: '',
      truckerTruckNumberError: '',
      truckerTruckType: '',
      truckerTruckTypeError: '',
    });
    this.setState({
      truckerTruckInfo: newTruckerInfo,
    });
  }

  handleRemoveFields = index => {
    const { truckerTruckInfo } = this.state;
    const newTruckerInfo = [...truckerTruckInfo];
    newTruckerInfo.splice(index, 1);
    this.setState({
      truckerTruckInfo: newTruckerInfo,
    });
  };

  handleTruckNumberChange = (event, truck, index) => {
    const { truckerTruckInfo } = this.state;
    const newTruckerInfo = [...truckerTruckInfo];
    const row = newTruckerInfo[index];
    if (this.verifyLength(event.target.value, 1)) {
      row.truckerTruckNumber = event.target.value;
      row.truckerTruckNumberError = '';
    } else {
      row.truckerTruckNumber = event.target.value;
      row.truckerTruckNumberError = 'error';
    }
    newTruckerInfo[index] = row;
    this.setState({
      truckerTruckInfo: newTruckerInfo,
    });
  };

  handleTruckerTruckType = (event, truck, index) => {
    const { truckerTruckInfo } = this.state;
    const newTruckerInfo = [...truckerTruckInfo];
    const row = newTruckerInfo[index];
    row.truckerTruckType = event.target.value;
    row.truckerTruckTypeError = '';
    newTruckerInfo[index] = row;
    this.setState({
      truckerTruckInfo: newTruckerInfo,
    });
  };

  getTruckersForm() {
    const { classes } = this.props;
    const {
      truckerModal,
      truckerName,
      truckerNameError,
      truckerPhoneNumber,
      truckerPhoneNumberError,
      truckerCompanyName,
      truckerCompanyNameError,
    } = this.state;
    return (
      <Dialog
        fullWidth
        maxWidth="md"
        classes={{ root: classes.center, paper: classes.modal }}
        style={{
          maxWidth: '400px !important',
          textAlign: 'center',
        }}
        open={truckerModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.handleClose('truckerModal')}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => this.handleClose('truckerModal')}
          >
            <Close className={classes.modalClose} />
          </IconButton>
          <h4 className={classes.modalTitle}>Add Trucker Details</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
          style={{ minHeight: '30vh' }}
        >
          <GridContainer justify="flex-start">
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Trucker Name *"
                id="username"
                formControlProps={{ fullWidth: true }}
                success={truckerNameError === 'success'}
                error={truckerNameError === 'error'}
                inputProps={{
                  onChange: event =>
                    this.change(event, 'truckerName', 'length', 1),
                  type: 'length',
                  value: truckerName,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Phone Number *"
                id="phone-number"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: event =>
                    this.change(event, 'truckerPhoneNumber', 'phone'),
                  type: 'phone',
                  value: truckerPhoneNumber,
                }}
                success={truckerPhoneNumberError === 'success'}
                error={truckerPhoneNumberError === 'error'}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Company Name *"
                id="company-Name"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: event =>
                    this.change(event, 'truckerCompanyName', 'length', 1),
                  type: 'string',
                  value: truckerCompanyName,
                  style: { marginTop: '10px' },
                }}
                success={truckerCompanyNameError === 'success'}
                error={truckerCompanyNameError === 'error'}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <p
                  className={classes.labelText}
                  htmlFor="preferred-cities"
                  style={{ textAlign: 'left', marginTop: '-10px' }}
                >
                  Choose Preferred Cities
                </p>
                <ReactSelect
                  value={this.state.truckerSelectedCity}
                  onChange={this.handleMultipleTruckerCity}
                  options={this.state.fromCitiesObject}
                  placeholder="Select Cities"
                  id="prefered-cities"
                  isMulti
                  closeMenuOnSelect={false}
                />
              </FormControl>
              {this.state.truckerSelectedCityError === 'error' && (
                <p style={{ color: 'red' }}>Please Select Cities</p>
              )}
              <p />
            </GridItem>
            <GridContainer justify="flex-start">
              <GridItem xs={12} sm={12} md={12}>
                <h4
                  style={{ margin: '10px', padding: '10px', textAlign: 'left' }}
                >
                  Trucks
                </h4>
              </GridItem>
              {this.state.truckerTruckInfo.length > 0 ? (
                <GridContainer
                  style={{
                    marginLeft: '20px',
                    paddingLeft: '20px',
                    marginRight: '10px',
                    paddingRight: '10px',
                  }}
                  justify="flext-start"
                >
                  <GridItem
                    xs={12}
                    sm={12}
                    md={5}
                    style={{ textAlign: 'left' }}
                  >
                    Truck Type
                  </GridItem>
                  <GridItem xs={12} sm={12} md={7} />
                  {this.state.truckerTruckInfo.map((truck, index) => (
                    <>
                      <GridItem xs={12} sm={12} md={5}>
                        {this.getTruckTypes(truck, index)}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={2}>
                        <div style={{ marginTop: '25px' }}>
                          <Button
                            size="sm"
                            color="danger"
                            simple
                            justIcon
                            onClick={() => this.handleRemoveFields(index)}
                          >
                            <Close
                              className={this.props.classes.underChartIcons}
                            />
                          </Button>
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={5} />
                    </>
                  ))}
                  <Button
                    color="info"
                    onClick={() => this.handleAddFields()}
                    style={{ textAlign: 'left' }}
                    simple
                  >
                    <i
                      className="fa fa-plus"
                      aria-hidden="true"
                      style={{ marginRight: '2px' }}
                    />{' '}
                    Trucks
                  </Button>
                </GridContainer>
              ) : (
                <GridContainer
                  style={{
                    marginLeft: '20px',
                    paddingLeft: '20px',
                    marginRight: '10px',
                    paddingRight: '10px',
                  }}
                >
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    style={{ textAlign: 'left' }}
                  >
                    <p style={{ color: 'red' }}>
                      {' '}
                      {this.state.truckerTruckInfoError}
                    </p>
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    style={{ textAlign: 'left' }}
                  >
                    <Button
                      color="info"
                      onClick={() => this.handleAddFields()}
                      style={{ textAlign: 'left' }}
                      simple
                    >
                      <i
                        className="fa fa-plus"
                        aria-hidden="true"
                        style={{ marginRight: '2px' }}
                      />{' '}
                      Trucks
                    </Button>
                  </GridItem>
                </GridContainer>
              )}
            </GridContainer>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          {this.state.isLoading ? (
            <CircularProgress
              className={classes.progress}
              style={{ color: purple[500] }}
              thickness={4}
            />
          ) : (
            <div>
              {this.state.isLoadingButton ? (
                <CircularProgress
                  className={classes.progress}
                  style={{ color: purple[500] }}
                  thickness={4}
                />
              ) : (
                <div>
                  <Button
                    color="default"
                    onClick={() => this.handleClose('truckerModal')}
                  >
                    Close
                  </Button>
                  <Button
                    color="success"
                    onClick={() => this.handleSubmitTrucker()}
                  >
                    Submit
                  </Button>
                </div>
              )}
            </div>
          )}
        </DialogActions>
      </Dialog>
    );
  }

  getTransporterForm() {
    const { classes } = this.props;
    const {
      transporterModal,
      transporterNameError,
      transporterName,
      transporterPhoneNumber,
      transporterPhoneNumberError,
      transporterCompanyName,
      transporterCompanyNameError,
    } = this.state;
    return (
      <Dialog
        fullWidth
        maxWidth="md"
        classes={{ root: classes.center, paper: classes.modal }}
        style={{
          maxWidth: '400px !important',
          textAlign: 'center',
        }}
        open={transporterModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.handleClose('transporterModal')}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => this.handleClose('transporterModal')}
          >
            <Close className={classes.modalClose} />
          </IconButton>
          <h4 className={classes.modalTitle}>Add Transporter Details</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
          style={{ minHeight: '30vh' }}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Transporter Name *"
                id="username"
                formControlProps={{ fullWidth: true }}
                success={transporterNameError === 'success'}
                error={transporterNameError === 'error'}
                inputProps={{
                  onChange: event =>
                    this.change(event, 'transporterName', 'length', 1),
                  type: 'length',
                  value: transporterName,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Phone Number *"
                id="phone-number"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: event =>
                    this.change(event, 'transporterPhoneNumber', 'phone'),
                  type: 'phone',
                  value: transporterPhoneNumber,
                }}
                success={transporterPhoneNumberError === 'success'}
                error={transporterPhoneNumberError === 'error'}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Company Name *"
                id="companyName"
                formControlProps={{ fullWidth: true }}
                success={transporterCompanyNameError === 'success'}
                error={transporterCompanyNameError === 'error'}
                inputProps={{
                  onChange: event =>
                    this.change(event, 'transporterCompanyName', 'length', 1),
                  type: 'length',
                  value: transporterCompanyName,
                }}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          {this.state.isLoadingButton ? (
            <CircularProgress
              className={classes.progress}
              style={{ color: purple[500] }}
              thickness={4}
            />
          ) : (
            <div>
              <Button
                color="default"
                onClick={() => this.handleClose('transporterModal')}
              >
                Close
              </Button>
              <Button
                color="success"
                onClick={() => this.handleSubmitTransporter()}
              >
                Submit
              </Button>
            </div>
          )}
        </DialogActions>
      </Dialog>
    );
  }

  handleChanged(event) {
    this.setState({
      currentActiveAccordian: event,
    });
    this.getProbableMatch(event);
  }

  skeltonViewSupply() {
    const arr = [1, 2, 3];
    return (
      <div style={{ padding: '5px' }}>
        <Row start="xs">
          <br />
          {arr.map((a, i) => (
            <Row key={i}>
              <Col xs={4} sm={4} md={3}>
                <Skeleton
                  width={250}
                  style={{ marginTop: '20px', marginLeft: '10px' }}
                />
              </Col>
              <Col xs={4} sm={4} md={3}>
                <Skeleton
                  width={250}
                  style={{ marginTop: '20px', marginLeft: '10px' }}
                />
              </Col>
              <Col xs={4} sm={4} md={3}>
                <Skeleton
                  width={250}
                  style={{ marginTop: '20px', marginLeft: '10px' }}
                />
              </Col>
              <Col xs={4} sm={4} md={3}>
                <Skeleton
                  width={250}
                  style={{ marginTop: '20px', marginLeft: '10px' }}
                />
              </Col>
            </Row>
          ))}
        </Row>
      </div>
    );
  }

  skeltonViewDemand() {
    const arr = [1, 2, 3];
    return (
      <div style={{ padding: '10px' }}>
        <Row start="xs" style={{ marginTop: '20px' }}>
          <Col xs={12} sm={3} md={3}>
            <h1
              className="react-rainbow-admin-header-pages_title"
              style={{ fontSize: '26px' }}
            >
              <Skeleton width={100} />
            </h1>
          </Col>
          <Col xs={12} sm={3} md={3}>
            <h1
              className="react-rainbow-admin-header-pages_title"
              style={{ fontSize: '26px' }}
            >
              <Skeleton width={100} />
            </h1>
          </Col>
          <Col xs={12} sm={3} md={3}>
            <h1
              className="react-rainbow-admin-header-pages_title"
              style={{ fontSize: '26px' }}
            >
              <Skeleton width={100} />
            </h1>
          </Col>
          <Col xs={12} sm={3} md={3}>
            <h1
              className="react-rainbow-admin-header-pages_title"
              style={{ fontSize: '26px' }}
            >
              <Skeleton width={100} />
            </h1>
          </Col>
        </Row>
        <hr
          style={{
            marginTop: '0px',
            marginBottom: '0px',
            color: '#f5f5f5',
            borderTop: '1px solid #f5f5f5',
          }}
        />
        <Row start="xs">
          <Col xs={12} sm={3} md={3}>
            <Skeleton width={300} />
          </Col>
          <Col xs={12} sm={3} md={3}>
            <Skeleton width={300} />
          </Col>
          <Col xs={12} sm={3} md={3}>
            <Skeleton width={300} />
          </Col>
          <Col xs={12} sm={3} md={3}>
            <Skeleton width={300} />
          </Col>
        </Row>
        <Row start="xs">
          <br />
          {arr.map((a, i) => (
            <Row style={{ width: '100%', marginLeft: '5px' }} key={i}>
              <Col xs={6} sm={3} md={3}>
                <Skeleton width={200} />
              </Col>
              <Col xs={6} sm={3} md={3}>
                <Skeleton width={200} />
              </Col>
              <Col xs={6} sm={3} md={3}>
                <Skeleton width={200} />
              </Col>
              <Col xs={6} sm={3} md={3}>
                <Skeleton width={200} />
              </Col>
            </Row>
          ))}
        </Row>
        <hr
          style={{
            marginTop: '4px',
            marginBottom: '4px',
            color: '#f5f5f5',
            borderTop: '1px solid #f5f5f5',
          }}
        />
        <Row start="xs">
          <br />
          {arr.map((a, i) => (
            <Row style={{ width: '100%', marginLeft: '0px' }} key={i}>
              <Col xs={12} sm={3} md={3}>
                <Skeleton width={300} />
              </Col>
              <Col xs={12} sm={3} md={3}>
                <Skeleton width={300} />
              </Col>
              <Col xs={12} sm={3} md={3}>
                <Skeleton width={300} />
              </Col>
              <Col xs={12} sm={3} md={3}>
                <Skeleton width={300} />
              </Col>
              <Col xs={12} sm={12} md={12}>
                <hr
                  style={{
                    marginTop: '4px',
                    marginBottom: '4px',
                    color: '#f5f5f5',
                    borderTop: '1px solid #f5f5f5',
                  }}
                />
              </Col>
            </Row>
          ))}
        </Row>
      </div>
    );
  }

  render() {
    const { classes, rtlActive } = this.props;
    const {
      isLoading,
      demandsData,
      accordianData,
      notificationColor,
      notificationText,
      isNotification,
    } = this.state;
    const { open } = this.state;
    const dropdownItem = `${classes.dropdownItem} ${classNames({
      [classes.dropdownItemRTL]: rtlActive,
    })}`;
    const wrapper = classNames({
      [classes.wrapperRTL]: rtlActive,
    });
    const managerClasses = classNames({
      [classes.managerClasses]: true,
    });
    return (
      <>
        <GridContainer justify="center">
          {this.getSupplyForm()}
          {this.getDemandForm()}
          {this.getTruckersForm()}
          {this.getTransporterForm()}
          <Snackbars
            place="tr"
            color={notificationColor}
            icon={AddAlert}
            message={notificationText}
            open={isNotification}
            closeNotification={() => this.setState({ isNotification: false })}
            close
          />
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader className={`${classes.cardHeader}`} color="info">
                <GridContainer>
                  <GridItem xs={6} md={3} lg={3}>
                    <h1
                      className={`${classes.cardHeader} ${classes.rajdhaniFont} ${classes.demandHeaderText}`}
                      style={{ marginBottom: '0px', fontSize: '20px' }}
                    >
                      MatchMaking
                    </h1>
                    <Mobile>
                      {this.state.isRefresh ? (
                        <CircularProgress
                          className={classes.progress}
                          style={{ color: purple[500] }}
                          thickness={2}
                        />
                      ) : (
                        <Button
                          size="md"
                          simple
                          color="primary"
                          onClick={() => this.refreshDemands()}
                          style={{ marginLeft: '10px' }}
                        >
                          <Refresh className={classes.underChartIcons} />
                        </Button>
                      )}
                    </Mobile>

                    <Desktop>
                      <div className={classes.demandHeaderButtonStyle}>
                        <Button
                          color="primary"
                          onClick={() => this.handleClickOpen('truckerModal')}
                          simple
                        >
                          <i
                            className="fa fa-plus"
                            aria-hidden="true"
                            style={{ marginRight: '2px' }}
                          />{' '}
                          Trucker Lead
                        </Button>
                        <Button
                          color="primary"
                          onClick={() =>
                            this.handleClickOpen('transporterModal')
                          }
                          simple
                        >
                          <i
                            className="fa fa-plus"
                            aria-hidden="true"
                            style={{ marginRight: '2px' }}
                          />{' '}
                          Transporter Lead
                        </Button>
                      </div>
                    </Desktop>
                  </GridItem>
                  <GridItem xs={6} md={9} lg={9}>
                    <Mobile>
                      <Button
                        color="primary"
                        justIcon
                        aria-label=""
                        aria-owns={open ? 'menu-list' : null}
                        aria-haspopup="true"
                        onClick={this.handleClick}
                        className={
                          rtlActive ? classes.buttonLinkRTL : classes.buttonLink
                        }
                        muiClasses={{
                          label: rtlActive ? classes.labelRTL : '',
                        }}
                        buttonRef={node => {
                          this.anchorNotification = node;
                        }}
                      >
                        <Person
                          className={`${classes.headerLinksSvg} ${
                            rtlActive
                              ? `${classes.links} ${classes.linksRTL}`
                              : classes.links
                          }`}
                        />
                      </Button>
                      <Popper
                        placement="bottom-start"
                        open={open}
                        anchorEl={this.anchorNotification}
                        transition
                        disablePortal
                        className={`${classNames({
                          [classes.popperClose]: !open,
                        })} ${classes.pooperResponsive}`}
                      >
                        {({ TransitionProps }) => (
                          <Grow
                            {...TransitionProps}
                            id="notification-menu-list"
                            style={{ transformOrigin: '0 0 0' }}
                          >
                            <Paper className={classes.dropdown}>
                              <ClickAwayListener onClickAway={this.handleClose}>
                                <MenuList role="menu">
                                  <MenuItem
                                    onClick={() =>
                                      this.handleClickOpen('supplyModal')
                                    }
                                    className={dropdownItem}
                                  >
                                    <i
                                      className="fa fa-plus"
                                      aria-hidden="true"
                                      style={{ marginRight: '2px' }}
                                    />{' '}
                                    Supply
                                  </MenuItem>

                                  <MenuItem
                                    onClick={() =>
                                      this.handleClickOpen('demandModal')
                                    }
                                    className={dropdownItem}
                                  >
                                    <i
                                      className="fa fa-plus"
                                      aria-hidden="true"
                                      style={{ marginRight: '2px' }}
                                    />{' '}
                                    Demand
                                  </MenuItem>
                                  <MenuItem
                                    className={dropdownItem}
                                    onClick={() =>
                                      this.handleClickOpen('truckerModal')
                                    }
                                  >
                                    <i
                                      className="fa fa-plus"
                                      aria-hidden="true"
                                      style={{ marginRight: '2px' }}
                                    />{' '}
                                    Trucker Lead
                                  </MenuItem>
                                  <MenuItem
                                    className={dropdownItem}
                                    onClick={() =>
                                      this.handleClickOpen('transporterModal')
                                    }
                                  >
                                    <i
                                      className="fa fa-plus"
                                      aria-hidden="true"
                                      style={{ marginRight: '2px' }}
                                    />{' '}
                                    Transporter Lead
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() =>
                                      this.props.history.push(
                                        '/sales/supply-history'
                                      )
                                    }
                                    className={dropdownItem}
                                  >
                                    Supply History
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() =>
                                      this.props.history.push(
                                        '/sales/demand-history'
                                      )
                                    }
                                    className={dropdownItem}
                                  >
                                    Demand History
                                  </MenuItem>
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </Mobile>
                    <Desktop>
                      <div
                        style={{
                          float: 'right',
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                      >
                        <div>
                          <Button
                            color="success"
                            aria-label="edit"
                            style={{ marginTop: '16px' }}
                            onClick={() => this.handleClickOpen('supplyModal')}
                          >
                            <i
                              className="fa fa-plus"
                              aria-hidden="true"
                              style={{ marginRight: '2px' }}
                            />{' '}
                            Supply
                          </Button>
                          <div>
                            <Button
                              color="primary"
                              onClick={() =>
                                this.props.history.push('/sales/supply-history')
                              }
                              simple
                              style={{ marginLeft: '-20px' }}
                            >
                              Supply History
                            </Button>
                          </div>
                          <div>
                            <Mobile>
                              <div style={{ marginLeft: '-22px' }}>
                                <Button
                                  color="primary"
                                  onClick={() =>
                                    this.handleClickOpen('truckerModal')
                                  }
                                  simple
                                >
                                  <i
                                    className="fa fa-plus"
                                    aria-hidden="true"
                                    style={{ marginRight: '2px' }}
                                  />{' '}
                                  Trucker Lead
                                </Button>
                              </div>
                            </Mobile>
                          </div>
                        </div>
                        <div>
                          <Button
                            color="warning"
                            aria-label="edit"
                            style={{ marginTop: '16px' }}
                            onClick={() => this.handleClickOpen('demandModal')}
                          >
                            <i
                              className="fa fa-plus"
                              aria-hidden="true"
                              style={{ marginRight: '2px' }}
                            />{' '}
                            Demand
                          </Button>
                          <div>
                            <Button
                              color="primary"
                              onClick={() =>
                                this.props.history.push('/sales/demand-history')
                              }
                              simple
                              style={{ marginLeft: '-20px' }}
                            >
                              Demand History
                            </Button>
                            {this.state.isRefresh ? (
                              <CircularProgress
                                className={classes.progress}
                                style={{ color: purple[500] }}
                                thickness={2}
                              />
                            ) : (
                              <Button
                                size="md"
                                simple
                                color="primary"
                                onClick={() => this.refreshDemands()}
                                style={{ padding: '0px' }}
                              >
                                <Refresh className={classes.underChartIcons} />
                              </Button>
                            )}
                          </div>
                          <div>
                            <Mobile>
                              <div style={{ marginLeft: '-22px' }}>
                                <Button
                                  color="primary"
                                  onClick={() =>
                                    this.handleClickOpen('transporterModal')
                                  }
                                  simple
                                >
                                  <i
                                    className="fa fa-plus"
                                    aria-hidden="true"
                                    style={{ marginRight: '2px' }}
                                  />{' '}
                                  Transporter Lead
                                </Button>
                              </div>
                            </Mobile>
                          </div>
                        </div>
                      </div>
                    </Desktop>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} md={3} lg={3} />
                  <GridItem xs={12} md={9} lg={9} />
                </GridContainer>
              </CardHeader>
              <CardBody
                className={`${classes.cardHeader} ${classes.textCenter}`}
                style={{
                  paddingTop: '0px',
                  paddingRight: '5px',
                  paddingLeft: '5px',
                }}
              >
                {isLoading ? (
                  this.skeltonViewDemand()
                ) : (
                  <React.Fragment>
                    {demandsData.length <= 0 ? (
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        <p
                          className={classes.textCenter}
                          style={{ marginTop: '20px' }}
                        >
                          Looks like no matches found. Please Add Demands and
                          Supplies.
                        </p>
                      </GridItem>
                    ) : (
                      <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        style={{ paddingLeft: '0px', paddingRight: '0px' }}
                      >
                        <div
                          style={{ paddingTop: '5px', paddingBottom: '5px' }}
                        >
                          <Desktop>
                            <div style={{ marginTop: '5px' }}>
                              {this.renderTableHeader()}
                            </div>
                          </Desktop>
                          <Accordion
                            // active={0}
                            collapses={accordianData}
                            handleChange={event => this.handleChanged(event)}
                          />
                        </div>
                      </GridItem>
                    )}
                  </React.Fragment>
                )}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <MatchedHistory
          handleMatched={() => this.refreshDemands()}
          isRefresh={this.state.refreshMatched}
        />
      </>
    );
  }
}

Demands.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  activeRole: state.main.activeRole,
  userDetails: state.main.userDetails,
  systemDetails: state.main.systemDetails,
});

export default connect(mapStateToProps)(withStyles(TransactionStyle)(Demands));
