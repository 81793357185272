import ImagesList from 'views/TruckerLeads/imagesList.jsx';

// @material-ui/icons
import DashboardIcon from '@material-ui/icons/Dashboard';

const callCenterDashRoutes = [
  {
    path: '/call_center/trucker-leads',
    name: 'Trucker Leads',
    icon: DashboardIcon,
    component: ImagesList,
  },
  {
    redirect: true,
    path: '/call_center',
    pathTo: '/call_center/trucker-leads',
    name: 'Trucker Leads',
  },
];
export default callCenterDashRoutes;
