import Transactions from 'views/Transactions/FinanceTransactions.jsx';
// @material-ui/icons
import DashboardIcon from '@material-ui/icons/Dashboard';

const trackingRoleDashRoutes = [
  {
    path: '/tracking-role/transactions',
    name: 'Transactions',
    icon: DashboardIcon,
    component: Transactions,
  },
];
export default trackingRoleDashRoutes;
