import { Typography } from '@material-ui/core';
import React from 'react';

const CitiesPreferences = ({ cities }) => (
  <div
    style={{
      padding: '20px 10px',
      borderBottom: '1px solid lightgray',
    }}
  >
    <Typography variant="body2" style={{ fontWeight: 'bold' }} gutterBottom>
      Branches Preferences
    </Typography>
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
    >
      {cities?.map(city => (
        <span
          style={{
            background: '#D6E0FF',
            color: '#2952CC',
            borderRadius: '5px',
            fontWeight: 'bold',
            padding: '2px 5px',
            textTransform: 'uppercase',
            fontSize: '12px',
            marginRight: '10px',
            marginBottom: '10px',
          }}
        >
          {city?.name}
        </span>
      ))}
    </div>
  </div>
);

export default CitiesPreferences;
