import { Button, Typography } from '@material-ui/core';
import React from 'react';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DashboardRedirectImage from '../../assets/img/dashboard 1.svg';

const { REACT_APP_LOBB_PIPE_REDIRECTION_URL } = process.env;

const DashboardRedirect = () => (
  <div
    style={{
      background: 'white',
      marginTop: 20,
      width: '400px',
      borderRadius: '5px',
      padding: '15px',
      position: 'relative',
    }}
  >
    <div
      style={{
        width: '80px',
        height: '80px',
        backgroundColor: 'orange',
        position: 'absolute',
        top: '-23px',
        left: '4%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '5px',
      }}
    >
      <img src={DashboardRedirectImage} alt="icon" />
    </div>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
      }}
    >
      <Typography
        variant="body1"
        style={{ marginLeft: '100px', fontWeight: '600', fontSize: '16px' }}
      >
        DASHBOARD HAS BEEN MOVED TO LOBB PIPE.
      </Typography>
      <Button
        onClick={() =>
          window.open(REACT_APP_LOBB_PIPE_REDIRECTION_URL, '_blank')
        }
        variant="contained"
        color="primary"
        style={{ marginTop: 20, width: '140px', textTransform: 'none' }}
        endIcon={<ArrowForwardIcon fontSize="Small" />}
      >
        Click here
      </Button>
    </div>
  </div>
);

export default DashboardRedirect;
