const toIndianCurrency = num => {
  if (num) {
    const curr = num.toLocaleString('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    return `${curr}`;
  }
  return 0;
};

export default toIndianCurrency;
