/* eslint-disable consistent-return */
import myFirebase from '../config/config';
import apiCaller from './apiCallerOutstanding';

export default async function auditLogger(auditPayload) {
  const userDetails = myFirebase.auth().currentUser;
  const { Aa } = userDetails;
  const {
    collection,
    orgId,
    data,
    message,
    systemDetails,
    type,
    role,
    eventDateTime,
  } = auditPayload;
  const body = {
    collection,
    orgId,
    data,
    message,
    systemDetails,
    type,
    role: role ? `is${role}` : '',
    eventDateTime,
    source: 'office',
  };

  const endPoint = 'audit/audit-log';
  try {
    await apiCaller(endPoint, body, `Bearer ${Aa}`, 'pipe');
  } catch (e) {
    return e;
  }
}
