/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { CardHeader } from '@material-ui/core';
import PODAcknowledgementSubmittedFileUploader from './PODAcknowledgement/PODAcknowledgementSubmittedFileUploader';

const useStyles = makeStyles({
  root: {
    // minWidth: 275,
    marginBottom: '20px',
    // marginRight: 10,
  },

  form: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  header: { textAlign: 'left' },
});

export default function PODAcknowledgementFileUpload({
  activeRole,
  currentTransactionData,
  featureFlagFiles,
}) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader
        title="POD Acknowledgement Submitted"
        component={Typography}
        className={classes.header}
      />
      <CardContent style={{ margin: 0, padding: 0 }}>
        <div className={classes.form}>
          <PODAcknowledgementSubmittedFileUploader
            activeRole={activeRole}
            currentTransactionData={currentTransactionData}
            featureFlagFiles={featureFlagFiles}
          />
        </div>
      </CardContent>
    </Card>
  );
}
