import { cardTitle,
  tooltip } from "assets/jss/material-dashboard-pro-react.jsx";
import hoverCardStyle from "assets/jss/material-dashboard-pro-react/hoverCardStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";

const userProfileStyles = {
  ...customSelectStyle,
  ...sweetAlertStyle,
  ...regularFormsStyle,
  ...hoverCardStyle,
  tooltip,
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    "& small": {
      fontSize: "80%",
      fontWeight: "400"
    }
  },
  cardCategory: {
    marginTop: "10px",
    color: "#999999 !important",
    textAlign: "center"
  },
  description: {
    color: "#999999"
  },
  updateProfileButton: {
    float: "right"
  }
};
export default userProfileStyles;
