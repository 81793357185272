/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable no-var */
import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Card from 'components/Card/Card.jsx';
import AddIcon from '@material-ui/icons/Add';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import ApartmentIcon from '@material-ui/icons/Apartment';
import Chip from '@material-ui/core/Chip';
import { CircularProgress, Typography } from '@material-ui/core';
import firebase from 'firebase/app';
import { connect } from 'react-redux';
import UsersDataGrid from './UsersDataGrid.jsx';
import Myfirebase from '../../config/config';
import AlertMessageBar from './AlertMessageBar';
import apiCaller from '../../utils/apiCallerOutstanding.js';

const db = Myfirebase.firestore();
var users = [];
const AddBranches = ({ activeRole }) => {
  const [branchName, setBranchName] = useState('');
  const [existingBranches, setExistingBranches] = useState();
  const [error, setError] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [failedTrigger, setFailedTrigger] = useState(false);
  const [addingBranch, setAddingBranch] = useState(false);
  const [branchAdded, setBranchAdded] = useState(false);
  const [fetchedUsers, setFetchedUsers] = useState(false);
  const handleBranch = event => {
    setError(false);
    setBranchName(event.target.value);
  };
  const currentActiveRole = `is${activeRole
    ?.charAt(0)
    .toUpperCase()}${activeRole?.slice(1)}`;

  const getExistingBranches = async () => {
    const userDetails = firebase.auth().currentUser;

    if (userDetails) {
      const { Aa: token } = userDetails;
      try {
        const endPoint = `pipe/branches?role=${currentActiveRole}`;
        const response = await apiCaller(
          endPoint,
          {},
          `Bearer ${token}`,
          'nh-base',
          'get'
        );
        const data = response?.data?.Data;
        setExistingBranches(data);
        setFetchedUsers(true);
      } catch (e) {
        console.error('Error getting documents: ', e);
      }
    }
  };
  const getExistingUsers = async () => {
    setFetchedUsers(false);
    users = [];
    // setFetching(true);
    await db
      .collection('Users')
      // .where('branches', 'not-in', branchName)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          // doc.data() is never undefined for query doc snapshots
          const data = doc.data();

          users.push(data);
        });
        setFetchedUsers(true);
      })
      .catch(error => {
        setFetchedUsers(true);
        // setFetching(false);
      });
  };

  const handleBranchAddition = async () => {
    setAddingBranch(true);
    const userDetails = firebase.auth().currentUser;
    const body = {
      branch_name: branchName,
      role: currentActiveRole,
    };
    if (userDetails) {
      const { Aa: token } = userDetails;
      try {
        const endPoint = `pipe/branches`;
        await apiCaller(endPoint, body, `Bearer ${token}`, 'nh-base', 'post');
        setBranchAdded(true);
        setAddingBranch(false);
        setTrigger(true);
        getExistingUsers();
      } catch (e) {
        if (e?.response?.status === 400) {
          setError(true);
        } else {
          setFailedTrigger(true);
        }
        setAddingBranch(false);
      }
    }
  };

  useEffect(() => {
    users = [];
    getExistingBranches();
  }, []);
  return (
    <div>
      <Card>
        <CardHeader>
          <CardIcon color="primary">
            <ApartmentIcon style={{ color: 'white' }} />
          </CardIcon>
          <h4>
            Add Branches <small />
          </h4>
        </CardHeader>
        <CardBody>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4} direction="row">
                  <Grid container spacing={2}>
                    <Grid item>
                      <TextField
                        id="outlined-basic"
                        label="Add Branch"
                        variant="outlined"
                        size="small"
                        error={error}
                        onChange={handleBranch}
                        helperText={error ? 'Branch already present' : ''}
                      />
                    </Grid>
                    <Grid item>
                      {addingBranch ? (
                        <CircularProgress />
                      ) : (
                        <Button
                          variant="contained"
                          style={{ background: '#3DAC16', color: 'white' }}
                          startIcon={<AddIcon />}
                          disabled={branchName === ''}
                          onClick={handleBranchAddition}
                        >
                          Add
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" gutterBottom>
                        Current Branches
                      </Typography>
                    </Grid>
                    {existingBranches?.map(branch => (
                      <Grid item>
                        <Chip
                          variant="outlined"
                          size="small"
                          label={branch?.branch_name}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {branchAdded ? (
              <Grid item xs={12}>
                {fetchedUsers ? (
                  <UsersDataGrid
                    users={users}
                    branchName={branchName}
                    refreshUsers={() => getExistingUsers()}
                  />
                ) : (
                  ''
                )}
              </Grid>
            ) : (
              ''
            )}
          </Grid>
        </CardBody>
      </Card>
      <AlertMessageBar
        trigger={trigger}
        message="you have added a new Branch and may want to add user's to the same"
        closeBar={val => setTrigger(val)}
      />
      <AlertMessageBar
        trigger={failedTrigger}
        message="Something Went Wrong, Please Try Again"
        closeBar={val => setTrigger(val)}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  activeRole: state.main.activeRole,
});

export default connect(mapStateToProps)(AddBranches);
