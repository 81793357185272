/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Table from 'components/Table/Table.jsx';
import TransactionStyle from 'assets/jss/material-dashboard-pro-react/views/TransactionStyle';
import AddAlert from '@material-ui/icons/AddAlert';
import Slide from '@material-ui/core/Slide';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Snackbar from 'components/Snackbar/Snackbar.jsx';
// import PayoutsDataGrid from './payoutsDataGrid';

import firebase from '../../config/config';
import auditLogger from '../../utils/auditLoggerToPG';

const db = firebase.firestore();
const verifyOtp = firebase.functions().httpsCallable('payable-verifyOtp');
const reversePayout = firebase
  .functions()
  .httpsCallable('payable-reversePayout');
const verifySalesPin = firebase
  .functions()
  .httpsCallable('payable-verifySalesPin');
const auditLog = firebase.functions().httpsCallable('utilities-auditLog');

const verifySalesPendingEntry = firebase
  .functions()
  .httpsCallable('payable-verifySalesPendingEntry');

const moment = require('moment');

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" {...props} ref={ref} />
));

class PendingEntries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeButton: 'All',
      pendingSalesPaybleEntries: [],
      isLoading: false,
      view: true,
      pinModal: false,
      classicModal: false,
      otp: '',
      responseData: {},
      isOtpNotification: false,
      isRejectNotification: false,
      branches: [],
      selectedBranch: '',
      selctedPayableEntry: {},
      selectedEntry: {},
      isAcceptLoading: [],
      isRejectLoading: false,
      alert: false,
      rejectModal: false,
      transactionSerial: '',
      pin: '',
      pinState: '',
      pinError: '',
      isPinNotification: false,
      isLoadingPin: false,
      isLoadingPinCancel: false,
      isLoadingOtp: false,
      isLoadingCancel: false,
      otpError: '',
      submitted: false,
    };
    this.getTable = this.getTable.bind(this);
    this.getPendingSalesPayableEntries = this.getPendingSalesPayableEntries.bind(
      this
    );
    this.goToView = this.goToView.bind(this);
    this.submitOtp = this.submitOtp.bind(this);
    this.submitPin = this.submitPin.bind(this);
    this.onConfirmReject = this.onConfirmReject.bind(this);
    this.onCancelAlert = this.onCancelAlert.bind(this);
    this.rejectEntry = this.rejectEntry.bind(this);
  }

  componentDidMount() {
    const { userDetails } = this.props;
    this.setState({ selectedBranch: userDetails.branches[0] }, () =>
      this.getPendingSalesPayableEntries()
    );
  }

  handleBranchChange = event => {
    this.setState({ selectedBranch: event.target.value }, () =>
      this.getPendingSalesPayableEntries()
    );
  };

  onCancelAlert() {
    this.setState({
      alert: null,
    });
  }

  selectBranch() {
    const { classes, userDetails } = this.props;
    const { selectedBranch } = this.state;
    return (
      <FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
          Select Branch
        </InputLabel>
        <Select
          MenuProps={{ className: classes.selectMenu }}
          classes={{ select: classes.select }}
          value={selectedBranch}
          onChange={this.handleBranchChange}
          inputProps={{ name: 'branch', id: 'simple-select' }}
        >
          <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
            Select Branch
          </MenuItem>
          {userDetails.branches.map((branch, i) => (
            <MenuItem
              key={branch}
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value={branch}
            >
              {branch}
            </MenuItem>
          ))}
        </Select>

        <div
          style={{
            paddingTop: '20px',
            display: 'flex',
            alignItems: 'left',
          }}
        >
          <Button
            style={{
              color: this.state.activeButton === 'All' ? 'white' : '',
              backgroundColor:
                this.state.activeButton === 'All' ? '#163BAC' : '',
            }}
            onClick={() => this.getPendingSalesPayableEntries('All')}
          >
            All
          </Button>
          <Button
            style={{
              color: this.state.activeButton === 'ATH' ? 'white' : '',
              backgroundColor:
                this.state.activeButton === 'ATH' ? '#163BAC' : '',
            }}
            onClick={() => this.getPendingSalesPayableEntries('ATH')}
          >
            ATH
          </Button>
          <Button
            style={{
              color: this.state.activeButton === 'BTH' ? 'white' : '',
              backgroundColor:
                this.state.activeButton === 'BTH' ? '#163BAC' : '',
            }}
            onClick={() => this.getPendingSalesPayableEntries('BTH')}
          >
            BTH
          </Button>
        </div>
      </FormControl>
    );
  }

  getPayableDetails(data) {
    const transactionRef = db
      .collection(`Transactions`)
      .where('transactionId', '==', data.transactionId);
    const response = transactionRef
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => doc.data());
      })
      .catch(error => error);

    return response;
  }

  /**
   * Will Get ALl the Truckers For The User
   * @function
   */
  //
  getPendingSalesPayableEntries(filterType) {
    if (!filterType) {
      filterType = 'All';
    }
    this.setState({
      activeButton: filterType,
    });
    let payoutRef = db.collection(`Payouts`);
    const { userDetails, classes } = this.props;
    const colors = ['info', 'success', 'danger'];
    let { selectedBranch } = this.state;
    const { isAcceptLoading } = this.state;
    if (!selectedBranch) selectedBranch = userDetails.branches[0];
    // If user is an admin, show them all transactions.
    if (userDetails.isSalesManager) {
      payoutRef = db
        .collection(`Payouts`)
        .where('status', '==', 'pending_sales_auth')
        .where('branch', '==', selectedBranch)
        .orderBy('created_at', 'desc');
    } else if (userDetails.isSales) {
      // If user is not a sales manager, display only their created transactions
      payoutRef = db
        .collection(`Payouts`)
        .where('status', '==', 'pending_sales_auth')
        .where('agentId', '==', userDetails.uid)
        .orderBy('created_at', 'desc');
    }
    payoutRef.onSnapshot(payoutData => {
      const pendingSalesPaybleEntries = [];
      payoutData.forEach(transaction => {
        // Filter based on filterType
        if (filterType === 'All' || transaction.data().type === filterType) {
          pendingSalesPaybleEntries.push({
            color: colors[0],
            uid: transaction.data().payableId,
            data: [
              transaction.data().transactionSerial,
              transaction.data().amount,
              transaction.data().vehicleNumber,
              transaction.data().type,
              transaction.data().truckerName,
              transaction.data().transporterName || '---',
              moment(transaction.data().created_at.toDate()).format('MMM Do Y'),
              isAcceptLoading[transaction.data().payableId] ? (
                <CircularProgress
                  className={classes.progress}
                  style={{ color: purple[500] }}
                  thickness={7}
                />
              ) : (
                <Button
                  color="success"
                  className={classes.actionButton}
                  key={transaction.data().payableId}
                  onClick={() => this.verifyEntry(transaction.data())}
                  size="sm"
                >
                  Accept
                </Button>
              ),
              <Button
                color="danger"
                className={classes.actionButton}
                key={transaction.data().payableId}
                onClick={() => this.rejectEntry(transaction.data())}
                size="sm"
              >
                Reject
              </Button>,
            ],
          });
        }
      });
      this.setState({
        pendingSalesPaybleEntries,
        isAcceptLoading,
      });
    });
  }

  // function that verifies if value contains only numbers
  verifyNumber(value) {
    const numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }

  verifyOtp(value, length) {
    const numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value) && value.toString().length > length) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'number':
        if (this.verifyNumber(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'otp':
        if (this.verifyOtp(event.target.value, stateNameEqualTo)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      default:
        this.setState({
          [`${stateName}State`]: 'success',
          [stateName]: event.target.value,
        });
        break;
    }
  }

  /**
   * Will return the Table Component
   * @function
   */
  getTable(status) {
    const { classes /* userDetails submitted */ } = this.props;
    const { pendingSalesPaybleEntries } = this.state;
    // const userId = userDetails.uid;
    return (
      <Card>
        <CardBody className={classes.customCardContentClass}>
          {pendingSalesPaybleEntries.length > 0 ? (
            <Table
              hover
              tableHead={[
                'Transaction No',
                'Amount',
                'Truck No',
                'Type',
                'Trucker',
                'Transporter',
                'Date',
              ]}
              tableData={pendingSalesPaybleEntries}
            />
          ) : (
            // <PayoutsDataGrid
            //   pendingSalesPaybleEntries={pendingSalesPaybleEntries}
            //   veriySingleTransaction={payableEntryInstance =>
            //     this.verifyEntry(payableEntryInstance)
            //   }
            //   rejectEntry={payableEntryInstance =>
            //     this.rejectEntry(payableEntryInstance)
            //   }
            //   userId={userId}
            //   submitted={submitted}
            // />
            <p>No Entries Found</p>
          )}
        </CardBody>
      </Card>
    );
  }

  goToView() {
    this.setState({ view: true });
  }

  verifyEntry(payableEntry) {
    const { userDetails, classes } = this.props;
    const {
      transactionId,
      transactionSerial,
      payableId,
      amount,
    } = payableEntry;
    const { isAcceptLoading, pendingSalesPaybleEntries } = this.state;
    const payoutId = payableId;
    const userId = userDetails.uid;
    isAcceptLoading[payableId] = true;
    const body = {
      transactionId,
      transactionSerial,
      payableId,
      payoutId,
      amount,
      userId,
    };
    let prevButton = '';
    if (amount <= 99999) {
      this.handleClickOpen('pinModal');
      this.setState({
        transactionSerial,
        selectedEntry: payableEntry,
      });
    } else {
      pendingSalesPaybleEntries.forEach(entry => {
        if (entry.uid === payableId) {
          prevButton = entry.data[4];
          entry.data[4] = (
            <CircularProgress
              className={classes.progress}
              style={{ color: purple[500] }}
              thickness={7}
            />
          );
        }
      });
      this.setState({
        isAcceptLoading,
        pendingSalesPaybleEntries,
      });
      verifySalesPendingEntry(body)
        .then(receivableResult => {
          isAcceptLoading[payableId] = false;
          pendingSalesPaybleEntries.forEach(entry => {
            if (entry.uid === payableId) {
              entry.data[4] = prevButton;
            }
          });
          this.setState({
            isAcceptLoading,
            pendingSalesPaybleEntries,
          });
          const response = receivableResult.data;
          if (response.message && response.statusCode === 200) {
            this.handleClickOpen('classicModal');
            this.setState({
              transactionSerial,
              responseData: response.body,
              selectedEntry: response.body,
            });
          }
        })
        .catch(err => {
          pendingSalesPaybleEntries.forEach(entry => {
            if (entry.uid === payableId) {
              entry.data[4] = prevButton;
            }
          });
          isAcceptLoading[payableId] = false;
          this.setState({
            isAcceptLoading,
            pendingSalesPaybleEntries,
          });
        });
    }
  }

  rejectEntry(payableEntry) {
    this.setState({
      selectedEntry: payableEntry,
    });
    this.handleClickOpen('rejectModal');
  }

  showConfirmReject() {
    const { classes } = this.props;
    const { rejectModal, isRejectLoading, selectedEntry } = this.state;

    return (
      <Dialog
        classes={{
          root: `${classes.center}`,
          // paper: classes.modal,
        }}
        open={rejectModal}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}>
            Do you want to reject the entry?
          </h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        />
        <DialogActions className={classes.modalFooter}>
          {isRejectLoading ? (
            <div>
              <CircularProgress
                className={classes.progress}
                style={{ color: purple[500] }}
                thickness={7}
              />
            </div>
          ) : (
            <div>
              <Button onClick={() => this.cancelReject('rejectModal')}>
                Cancel
              </Button>
              <Button
                onClick={() => this.onConfirmReject(selectedEntry)}
                color="warning"
              >
                Yes, Reject!
              </Button>
            </div>
          )}
        </DialogActions>
      </Dialog>
    );
  }

  onConfirmReject(payableEntry) {
    const params = window?.location?.href?.split('/');
    const activeRole = params[3] === 'branch-ops' ? 'branchOps' : params[3];
    const { userDetails, classes, systemDetails } = this.props;
    const { transactionId, payableId } = payableEntry;
    const { isAcceptLoading, pendingSalesPaybleEntries } = this.state;
    const source = 'sales';
    this.setState({
      isRejectLoading: true,
    });
    payableEntry.mode = 'NEFT';
    isAcceptLoading[payableId] = true;
    const body = {
      transactionId,
      payableId,
      payableEntry,
      source,
    };
    let prevButton = '';
    pendingSalesPaybleEntries.forEach(entry => {
      if (entry.uid === payableId) {
        prevButton = entry.data[5];
        entry.data[5] = (
          <CircularProgress
            className={classes.progress}
            style={{ color: purple[500] }}
            thickness={7}
          />
        );
      }
    });
    this.setState({
      isAcceptLoading,
      pendingSalesPaybleEntries,
    });
    reversePayout(body)
      .then(receivableResult => {
        isAcceptLoading[payableId] = false;
        const auditBody = {
          data: body,
          collection: 'Payable',
          updatedBy: userDetails.uid,
          systemDetails,
          type: 'Sales Rejects Payout',
          message: `${userDetails.email} Rejected the Payout Entry Rs.${payableEntry.amount} ATH via for Transaction ${payableEntry.transactionSerial} with Payout Reference ID ${payableEntry.payableId} and Payout is REVERSED`,
        };
        auditLog(auditBody);
        const auditPayload = {
          collection: 'Payable',
          orgId: null,
          data: body,
          message: `${userDetails.email} Rejected the Payout Entry Rs.${payableEntry.amount} ATH via for Transaction ${payableEntry.transactionSerial} with Payout Reference ID ${payableEntry.payableId} and Payout is REVERSED`,
          systemDetails,
          type: 'Sales Rejects Payout',
          role: `${this.capitalizeFirstLetter(activeRole.split('is')[0])}`,
          eventDateTime: new Date().toISOString(),
          source: 'office',
        };
        auditLogger(auditPayload);
        pendingSalesPaybleEntries.forEach(entry => {
          if (entry.uid === payableId) {
            entry.data[5] = prevButton;
          }
        });
        this.showNotification('isRejectNotification');
        this.setState({
          rejectModal: false,
          isAcceptLoading,
          pendingSalesPaybleEntries,
          isRejectLoading: false,
        });
        const response = receivableResult.data;
        if (response.message && response.statusCode === 200) {
          this.handleClose('rejectModal');
          this.getPendingSalesPayableEntries();
        }
      })
      .catch(err => {
        pendingSalesPaybleEntries.forEach(entry => {
          if (entry.uid === payableId) {
            entry.data[5] = prevButton;
          }
        });
        isAcceptLoading[payableId] = false;
        this.setState({
          rejectModal: false,
          isAcceptLoading,
          pendingSalesPaybleEntries,
          isRejectLoading: false,
        });
      });
  }

  // eslint-disable-next-line react/sort-comp
  handleClickOpen(modal) {
    const x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleClose(modal) {
    const x = [];
    x[modal] = false;
    this.setState(x);
  }

  resendOtpPayable() {
    const { userDetails } = this.props;
    const { selectedEntry } = this.state;
    const {
      transactionId,
      transactionSerial,
      payableId,
      amount,
    } = selectedEntry;
    const payoutId = payableId;
    const userId = userDetails.uid;
    const body = {
      transactionId,
      transactionSerial,
      payableId,
      payoutId,
      amount,
      userId,
    };
    verifySalesPendingEntry(body).then(receivableResult => {
      const response = receivableResult.data;
      if (response.message && response.statusCode === 200) {
        this.showNotification('isOtpNotification');
        this.setState({
          responseData: response.body,
          selectedEntry: response.body,
        });
      }
    });
  }

  cancelPayableOtp(modal) {
    this.setState({
      classicModal: false,
      isLoadingCancel: false,
      isLoadingOtp: false,
    });
  }

  cancelPayablePin(modal) {
    this.setState({
      pinModal: false,
      isLoadingPinCancel: false,
      isLoadingPin: false,
    });
  }

  cancelReject() {
    this.setState({
      isRejectLoading: false,
      selectedEntry: false,
      rejectModal: false,
    });
  }

  /**
   * will return the notification snackbar
   * @param {string} place
   */
  showNotification(place) {
    // eslint-disable-next-line react/destructuring-assignment
    if (!this.state[place]) {
      const x = [];
      x[place] = true;
      this.setState(x);
      setTimeout(() => {
        x[place] = false;
        this.setState(x);
      }, 5000);
    }
  }

  capitalizeFirstLetter(str) {
    // converting first letter to uppercase
    const capitalized = str.charAt(0).toUpperCase() + str.slice(1);

    return capitalized;
  }

  submitOtp() {
    const { userDetails, systemDetails } = this.props;
    const params = window?.location?.href?.split('/');
    const activeRole = params[3] === 'branch-ops' ? 'branchOps' : params[3];

    const { otp, selectedEntry } = this.state;
    if (this.verifyOtp(otp, 5)) {
      this.setState({
        isLoadingOtp: true,
        otpError: '',
      });
      const body = {
        otpId: selectedEntry.otpId,
        otp,
        amount: selectedEntry.amount,
        payableId: selectedEntry.payableId,
        transactionId: selectedEntry.transactionId,
        transactionSerial: selectedEntry.transactionSerial,
        payoutId: selectedEntry.payoutId,
        message: selectedEntry.message,
        otpType: 'seles_verify_payable',
      };
      verifyOtp(body)
        .then(response => {
          // Success result
          this.setState({ submitted: true });
          const auditBody = {
            data: {
              amount: selectedEntry.amount,
              message: selectedEntry.message,
              transactionId: selectedEntry.transactionId,
            },
            collection: 'Payable',
            updatedBy: userDetails.uid,
            systemDetails,
            type: 'Sales Verify Payout',
            message: `${userDetails.email} authorized the OTP for Payout Entry Rs.${selectedEntry.amount} ATH via for Transaction ${selectedEntry.transactionSerial} with Payout Reference ID ${selectedEntry.payableId} and Payout is INITIATED`,
          };
          this.setState({
            otp: '',
            isLoadingOtp: false,
            classicModal: false,
          });
          this.showNotification('isAcceptNotification');
          auditLog(auditBody);

          const auditPayload = {
            collection: 'Payable',
            orgId: null,
            data: {
              amount: selectedEntry.amount,
              message: selectedEntry.message,
              transactionId: selectedEntry.transactionId,
            },
            message: `${userDetails.email} authorized the OTP for Payout Entry Rs.${selectedEntry.amount} ATH via for Transaction ${selectedEntry.transactionSerial} with Payout Reference ID ${selectedEntry.payableId} and Payout is INITIATED`,
            systemDetails,
            type: `Sales Verify Payout`,
            role: `${this.capitalizeFirstLetter(activeRole.split('is')[0])}`,
            eventDateTime: new Date().toISOString(),
            source: 'office',
          };
          auditLogger(auditPayload);
        })
        .catch(err => {
          // IF Fails
          this.setState({
            otp: '',
            isLoadingOtp: false,
            otpError: 'Incorrect OTP/OTP timed out.',
          });
          const errorBody = {
            message: `${userDetails.email} submitted the OTP for Payable Entry (Payout) Rs.${selectedEntry.amount} ATH via for Transaction ${selectedEntry.transactionSerial} with Payout Reference ID ${selectedEntry.payableId} but Payout Initiation FAILED. ${err}`,
            status: 'fail',
            error: err,
            type: 'Sales Verify Payout Failed',
            updatedBy: userDetails.uid,
            errorMessage: err.message,
            collection: 'Payable',
            systemDetails,
          };
          auditLog(errorBody);
          const auditPayload = {
            collection: 'Payable',
            orgId: null,
            data: {
              amount: selectedEntry.amount,
              message: selectedEntry.message,
              transactionId: selectedEntry.transactionId,
            },
            message: `${userDetails?.name} with email id ${userDetails?.email} verfied payout but failed ${err} `,
            systemDetails,
            type: `Sales Verify Payout`,
            role: `${this.capitalizeFirstLetter(activeRole.split('is')[0])}`,
            eventDateTime: new Date().toISOString(),
            source: 'office',
          };
          auditLogger(auditPayload);
        });
    } else {
      this.setState({
        isLoadingOtp: false,
        otpError: 'Please Enter Valid Otp',
      });
    }
  }

  submitPin() {
    const { userDetails, systemDetails } = this.props;
    const params = window?.location?.href?.split('/');
    const activeRole = params[3] === 'branch-ops' ? 'branchOps' : params[3];

    const { pin, selectedEntry } = this.state;
    if (this.verifyOtp(pin, 3)) {
      this.setState({
        isLoadingPin: true,
        pinError: '',
      });
      const body = {
        pin,
        userId: userDetails.uid,
        amount: selectedEntry.amount,
        transactionId: selectedEntry.transactionId,
        transactionSerial: selectedEntry.transactionSerial,
        payoutId: selectedEntry.payoutId,
      };
      verifySalesPin(body)
        .then(response => {
          // Success result
          const auditBody = {
            data: {
              amount: selectedEntry.amount,
              message: `Sales Verified Payout using PIN for txn #${selectedEntry.transactionSerial}`,
              transactionId: selectedEntry.transactionId,
            },
            collection: 'Payable',
            updatedBy: userDetails.uid,
            systemDetails,
            type: 'Sales Verify Payout',
            message: `${userDetails.email} authorized the OTP for Payout Entry Rs.${selectedEntry.amount} ATH via for Transaction ${selectedEntry.transactionSerial} with Payout Reference ID ${selectedEntry.payoutId} and Payout is INITIATED`,
          };
          this.setState({
            pin: '',
            isLoadingPin: false,
            pinModal: false,
          });
          this.showNotification('isAcceptNotification');
          auditLog(auditBody);
          const auditPayload = {
            collection: 'Payable',
            orgId: null,
            data: {
              amount: selectedEntry.amount,
              message: `Sales Verified Payout using PIN for txn #${selectedEntry.transactionSerial}`,
              transactionId: selectedEntry.transactionId,
            },
            message: `${userDetails.email} authorized the OTP for Payout Entry Rs.${selectedEntry.amount} ATH via for Transaction ${selectedEntry.transactionSerial} with Payout Reference ID ${selectedEntry.payoutId} and Payout is INITIATED`,
            systemDetails,
            type: `Sales Verify Payout`,
            role: `${this.capitalizeFirstLetter(activeRole.split('is')[0])}`,
            eventDateTime: new Date().toISOString(),
            source: 'office',
          };
          auditLogger(auditPayload);
        })
        .catch(err => {
          console.log('Error', err);
          // IF Fails
          this.setState({
            pin: '',
            isLoadingPin: false,
            pinError: 'Incorrect PIN.',
          });
          const errorBody = {
            message: `${userDetails.email} submitted the OTP for Payable Entry (Payout) Rs.${selectedEntry.amount} ATH via for Transaction ${selectedEntry.transactionSerial} with Payout Reference ID ${selectedEntry.payableId} but Payout Initiation FAILED. ${err}`,
            status: 'fail',
            error: err,
            type: 'Sales Verify Payout Failed',
            updatedBy: userDetails.uid,
            errorMessage: err.message,
            collection: 'Payable',
            systemDetails,
          };
          auditLog(errorBody);
          const auditPayload = {
            collection: 'Payable',
            orgId: null,
            data: {
              amount: selectedEntry.amount,
              message: `Sales Verified Payout using PIN for txn #${selectedEntry.transactionSerial}`,
              transactionId: selectedEntry.transactionId,
            },
            message: `${userDetails.email} submitted the OTP for Payable Entry (Payout) Rs.${selectedEntry.amount} ATH via for Transaction ${selectedEntry.transactionSerial} with Payout Reference ID ${selectedEntry.payableId} but Payout Initiation FAILED. ${err}`,
            systemDetails,
            type: `Sales Verify Payout`,
            role: `${this.capitalizeFirstLetter(activeRole.split('is')[0])}`,
            eventDateTime: new Date().toISOString(),
            source: 'office',
          };
          auditLogger(auditPayload);
        });
    } else {
      this.setState({
        isLoadingPin: false,
        pinError: 'Please Enter Valid Otp',
      });
    }
  }

  showDialog() {
    const { classes } = this.props;
    const {
      classicModal,
      name,
      otp,
      otpState,
      isLoadingOtp,
      isLoadingCancel,
      otpError,
      isOtpNotification,
      transactionSerial,
    } = this.state;
    return (
      <Dialog
        classes={{
          root: `${classes.center}`,
          // paper: classes.modal,
        }}
        open={classicModal}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}># {transactionSerial} </h4>
          <h4 className={classes.modalTitle}>{name} Please Enter OTP</h4>
          <Snackbar
            place="tc"
            color="info"
            icon={AddAlert}
            message="OTP sent"
            open={isOtpNotification}
            closeNotification={() =>
              this.setState({ isOtpNotification: false })
            }
            close
          />
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={10}>
              <CustomInput
                labelText="Enter Otp"
                id="truck-number"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: event => this.change(event, 'otp', 'otp', 5),
                  type: 'otp',
                  value: otp,
                }}
                success={otpState === 'success'}
                error={otpState === 'error'}
              />
              <Button
                color="info"
                simple
                onClick={() => this.resendOtpPayable()}
              >
                Resend OTP
              </Button>
            </GridItem>
            <p style={{ color: 'red' }}>{otpError}</p>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          {isLoadingOtp || isLoadingCancel ? (
            <div>
              <CircularProgress
                className={classes.progress}
                style={{ color: purple[500] }}
                thickness={7}
              />
            </div>
          ) : (
            <div>
              <Button onClick={() => this.cancelPayableOtp('classicModal')}>
                Cancel
              </Button>
              <Button onClick={() => this.submitOtp()} color="success">
                Submit OTP
              </Button>
            </div>
          )}
        </DialogActions>
      </Dialog>
    );
  }

  showPinDialog() {
    const { classes } = this.props;
    const {
      pinModal,
      name,
      pin,
      pinState,
      isLoadingPin,
      isLoadingPinCancel,
      pinError,
      transactionSerial,
    } = this.state;
    return (
      <Dialog
        classes={{
          root: `${classes.center}`,
          // paper: classes.modal,
        }}
        open={pinModal}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}># {transactionSerial} </h4>
          <h4 className={classes.modalTitle}>{name} Please Enter PIN</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={10}>
              <CustomInput
                labelText="Enter PIN"
                id="pin-number"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: event => this.change(event, 'pin', 'otp', 3),
                  type: 'otp',
                  value: pin,
                }}
                success={pinState === 'success'}
                error={pinState === 'error'}
              />
            </GridItem>
            <p style={{ color: 'red' }}>{pinError}</p>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          {isLoadingPin || isLoadingPinCancel ? (
            <div>
              <CircularProgress
                className={classes.progress}
                style={{ color: purple[500] }}
                thickness={7}
              />
            </div>
          ) : (
            <div>
              <Button onClick={() => this.cancelPayablePin('pinModal')}>
                Cancel
              </Button>
              <Button onClick={() => this.submitPin()} color="success">
                Submit PIN
              </Button>
            </div>
          )}
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    const { classes, userDetails } = this.props;
    const {
      pendingSalesPaybleEntries,
      isLoading,
      alert,
      isRejectNotification,
      isAcceptNotification,
    } = this.state;
    return (
      <div>
        <GridContainer justify="center">
          {this.showDialog()}
          {this.showPinDialog()}
          {this.showConfirmReject()}
          {alert}
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Snackbar
              place="tc"
              color="danger"
              icon={AddAlert}
              message="Entry Rejected Successfully"
              open={isRejectNotification}
              closeNotification={() =>
                this.setState({ isRejectNotification: false })
              }
              close
            />
            <Snackbar
              place="tc"
              color="success"
              icon={AddAlert}
              message="Entry Accepted Successfully"
              open={isAcceptNotification}
              closeNotification={() =>
                this.setState({ isRejectNotification: false })
              }
              close
            />
            <Card>
              <CardHeader className={`${classes.cardHeader}`} color="info">
                <p className={`${classes.cardHeader}`}>Pending Entries</p>
              </CardHeader>
              <CardBody
                className={`${classes.cardHeader} ${classes.textCenter}`}
              >
                {isLoading ? (
                  <CircularProgress
                    className={classes.progress}
                    style={{ color: purple[500] }}
                    thickness={7}
                  />
                ) : (
                  <React.Fragment>
                    {userDetails.isSalesManager && (
                      <GridItem xs={12} sm={12} md={4}>
                        {this.selectBranch()}
                      </GridItem>
                    )}
                    {pendingSalesPaybleEntries.length <= 0 ? (
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        <p className={classes.textCenter}>
                          No Pending Entries available
                        </p>
                      </GridItem>
                    ) : (
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        {this.getTable('approved')}
                      </GridItem>
                    )}
                  </React.Fragment>
                )}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

PendingEntries.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  isDetailedPage: state.transactions.isDetailedPage,
  currentTransactionData: state.transactions.currentTransactionData,
  transactions: state.transactions.transactions,
  payableTransactions: state.transactions.payableTransactions,
  athTransactions: state.transactions.payableAthTransactions,
  currentPayableAmount: state.transactions.currentPayableAmount,
  activeRole: state.main.activeRole,
  userDetails: state.main.userDetails,
  systemDetails: state.main.systemDetails,
});

export default connect(
  mapStateToProps,
  null
)(withStyles(TransactionStyle)(PendingEntries));
