import Transactions from 'views/Transactions/FinanceTransactions.jsx';
// @material-ui/icons
import DashboardIcon from '@material-ui/icons/Dashboard';

const financePayableDashRoutes = [
  {
    path: '/finance-payable/transactions',
    name: 'Transactions',
    icon: DashboardIcon,
    component: Transactions,
  },
];
export default financePayableDashRoutes;
