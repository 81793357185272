/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-prototype-builtins */
/**
 * Global API Caller function
 * @function
 * @param {RequestBody} => {formData for The APi}
 */
import Cookies from 'universal-cookie';
import firebase from '../config/config';

const cookies = new Cookies();

const db = firebase.firestore();

const ENV = process.env.REACT_APP_API_ENV;

let API_URL = 'https://api-dev.lobb.in';

// export const API_URL = 'https://api.lobb.in';
switch (ENV) {
  case 'production':
    API_URL = 'https://api.lobb.in';
    break;
  case 'staging':
    API_URL = 'https://api-dev.lobb.in';
    break;
  case 'dev':
    API_URL = 'https://api-beta.lobb.in';
    break;
  default:
    API_URL = 'https://api-dev.lobb.in';
}

function getUserDetails() {
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        db.collection(`Users`)
          .doc(user.uid)
          .onSnapshot(doc => {
            const userData = doc.data();
            const body = getBody(userData);
            resolve(body);
          });
      } else {
        reject('error');
      }
    });
  });
}

function getBody(user) {
  let activeRole = '';
  const userId = user.uid;
  if (user.isOps) {
    activeRole = 'ops';
  } else if (user.isAdmin) {
    activeRole = 'admin';
  } else if (user.isFinance) {
    activeRole = 'finance';
  } else if (user.isSales) {
    activeRole = 'sales';
  } else if (user.isBranchOps){
    activeRole = 'branch-ops';
  }
  const body = {
    user_id: userId,
    role: activeRole,
  };
  return body;
}

export default function getToken(
  endpoint,
  method = 'get',
  body,
  authorization = false,
  isForm = false
) {
  return new Promise((resolve, reject) => {
    let headers = {
      Accept: 'application/json, */*',
    };
    if (authorization) {
      headers = {
        ...headers,
        'x-api-key': process.env.REACT_APP_MATCH_MAKING_KEY,
      };
    }
    let options = {
      headers,
      method,
    };
    getUserDetails().then(newBody => {
      const data = { ...newBody };

      if (method !== 'get' && !isForm) {
        options = {
          ...options,
          body: JSON.stringify(data),
        };
      }

      return fetch(`${API_URL}/${endpoint}`, options)
        .then(response => response.json())
        .then(res => {
          
          const { token } = res;
          cookies.set('token', token);
          resolve(res);
        })
        .catch(error => {
          reject(error);
        });
    });
  });
}
