import Transactions from 'views/Transactions/FinanceTransactions.jsx';
import OutstandingReportsView from 'views/Outstanding Reports/OutstandingReportsView.jsx';
import Truckers from 'views/Truckers/Truckers.jsx';
import Transporters from 'views/Transporters/Transporters.jsx';
// @material-ui/icons
import LocalShipping from '@material-ui/icons/LocalShipping';
import TransferWithinAStation from '@material-ui/icons/TransferWithinAStation';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SwapHoriz from '@material-ui/icons/SwapHoriz';
import Tune from '@material-ui/icons/Tune';
import Queued from '../views/Transactions/Sections/Queued';
import PODAgeingView from '../views/PODAgeing/PODAgeingView';
import TransporterOutstandingReports from '../views/Outstanding Reports/TransporterOutstanding/TransporterOutstandingReports';
import TruckerReports from '../views/Outstanding Reports/TruckerReports/TruckerReports';
import DashboardRedirect from '../views/Dashboard/DashboardRedirect';

const financeDashRoutes = [
  {
    path: '/finance/dashboard',
    name: 'Dashboard',
    icon: DashboardIcon,
    component: DashboardRedirect,
  },
  {
    path: '/finance/transactions',
    name: 'Transactions',
    icon: SwapHoriz,
    component: Transactions,
  },
  {
    path: '/finance/truckers',
    name: 'View Truckers',
    icon: LocalShipping,
    component: Truckers,
  },
  {
    path: '/finance/transporters',
    name: 'View Transporters',
    icon: TransferWithinAStation,
    component: Transporters,
  },
  {
    collapse: true,
    path: '/ops',
    name: 'Reports',
    icon: AccountBalanceWalletIcon,
    // state: 'openApprove',
    views: [
      {
        path: '/finance/podAgeing',
        name: 'POD Ageing',
        mini: 'P',
        component: PODAgeingView,
      },
      {
        path: '/finance/transporterOutstanding',
        name: 'Transporter Outstanding',
        mini: 'T O',
        component: TransporterOutstandingReports,
      },
    ],
  },
  {
    path: '/finance/queued',
    name: 'Queued',
    icon: Tune,
    component: Queued,
  },
  {
    redirect: true,
    path: '/finance',
    pathTo: '/finance/dashboard',
    name: 'Dashboard',
  },
];
export default financeDashRoutes;
