import React from 'react';
import Grid from '@material-ui/core/Grid';
import CategorySelect from './CategorySelect';
import NumberOfLocationsInput from './NumberOfLocationsInput';
import AdvancePaymentTerm from './AdvancePaymentTerm';
import BalancePaymentTerm from './BalancePaymentTerm';

const TruckerNewFieldsView = ({ activeRole, selectedTruckerData }) => (
  <div style={{ marginTop: '30px', marginBottom: '30px' }}>
    <Grid
      container
      spacing={1}
      direction="row"
      justifyContent="space-between"
      flexWrap="wrap"
      alignItems="flex-end"
    >
      <Grid item xs={12} sm={6} lg={2}>
        <CategorySelect
          activeRole={activeRole}
          selectedTruckerData={selectedTruckerData}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={2}>
        <NumberOfLocationsInput
          activeRole={activeRole}
          selectedTruckerData={selectedTruckerData}
        />
      </Grid>

      <Grid item xs={12} sm={12} lg={3}>
        <AdvancePaymentTerm
          activeRole={activeRole}
          selectedTruckerData={selectedTruckerData}
        />
      </Grid>
      <Grid item xs={12} sm={12} lg={3}>
        <BalancePaymentTerm
          activeRole={activeRole}
          selectedTruckerData={selectedTruckerData}
        />
      </Grid>
    </Grid>
  </div>
);

export default TruckerNewFieldsView;
