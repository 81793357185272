import React from 'react';
import { Button, Typography } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';

const TruckFiles = ({ truckFile }) => {
  const viewFile = url => {
    window.open(url);
  };

  return (
    <div
      style={{
        padding: '10px',
        borderRadius: '5px',
        border: '1px solid lightgray',
      }}
    >
      {Object.entries(truckFile).map(([key, value]) => {
        return (
          <div
            key={key}
            style={{
              marginBottom: '20px',
            }}
          >
            <Typography variant="body2">{value?.VehicleNumber}</Typography>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: '10px',
              }}
            >
              <div>
                <p>RC</p>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  onClick={() => viewFile(value?.rcFileUploadURL)}
                  disabled={!value?.rcFileUploadURL}
                  startIcon={<VisibilityIcon />}
                  style={{ fontSize: '10px' }}
                >
                  View
                </Button>
              </div>
              <div>
                <p>Insurance</p>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  onClick={() => viewFile(value?.InsuranceFileUploadURL)}
                  disabled={!value?.InsuranceFileUploadURL}
                  startIcon={<VisibilityIcon />}
                  style={{ fontSize: '10px' }}
                >
                  View
                </Button>
              </div>
              <div>
                <p>Fitness Certificate</p>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  onClick={() =>
                    viewFile(value?.FitnessCertificateFileUploadURL)
                  }
                  disabled={!value?.FitnessCertificateFileUploadURL}
                  startIcon={<VisibilityIcon />}
                  style={{ fontSize: '10px' }}
                >
                  View
                </Button>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TruckFiles;
