/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import CardHeader from '@material-ui/core/CardHeader';
import {
  CircularProgress,
  Divider,
  TextField,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import swal from 'sweetalert';
import firebase from '../../../config/config';
import apiCaller from '../../../utils/apiCallerOutstanding';

const buttonStyles = {
  background: '#3366FF',
  color: 'white',
  fontSize: '12px',
  textTransform: 'capitalize',
  marginLeft: '20px',
};
const useStyles = makeStyles({
  root: {
    minWidth: 275,
    marginTop: '30px',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 2,
  },
  commentbox: {
    maxHeight: '300px',
    overflowY: 'scroll',
    scrollbarWidth: 'thin',
  },
});

export default function OutlinedCard({ truckerData }) {
  const [commentsData, setCommentsData] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [comment, setComments] = useState('');
  const classes = useStyles();

  const getTruckerComments = async token => {
    setFetching(true);
    const endPoint = 'add-and-get-comments';
    const body = { type: 'truckers', channel_id: truckerData.truckerId };

    await apiCaller(endPoint, body, `Bearer ${token}`)
      .then(response => {
        setCommentsData(response?.data?.body);
        setFetching(false);
      })
      .catch(error => setFetching(false));
  };

  const submitComment = async () => {
    setSubmitting(true);
    const userDetails = firebase.auth().currentUser;
    const { Aa } = userDetails;
    const endPoint = 'add-and-get-comments';
    const body = {
      type: 'truckers',
      comment,
      channel_id: truckerData.truckerId,
    };
    await apiCaller(endPoint, body, `Bearer ${Aa}`)
      .then(response => {
        setSubmitting(false);
        setComments('');
        getTruckerComments(Aa);
      })
      .catch(error => {
        swal('Error!', `Something went wrong`, 'error');
        setSubmitting(false);
      });
  };

  const handleSubmitOnPress = e => {
    if (e.key === 'Enter' && comment !== '') {
      // Cancel the default action, if needed
      e.preventDefault();
      // Trigger the button element with a click
      submitComment();
    }
  };
  const handleTextChange = e => {
    setComments(e.target.value);
    if (e.key === 'Enter') {
      // Cancel the default action, if needed
      e.preventDefault();
      // Trigger the button element with a click
      submitComment();
    }
  };
  useEffect(() => {
    const userDetails = firebase.auth().currentUser;
    const { Aa } = userDetails;
    getTruckerComments(Aa);
  }, []);
  return (
    <Card className={classes.root}>
      <CardHeader title="Comments" />
      <Divider />
      {fetching ? (
        <CircularProgress size={20} />
      ) : (
        <CardContent className={classes.commentbox}>
          {commentsData.length === 0 ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                No Comments Available
              </Typography>
            </div>
          ) : null}
          {commentsData.length > 0 ? (
            <div style={{ margin: '10px' }}>
              {commentsData.map(commentDetail => (
                <div style={{ marginBottom: '20px' }}>
                  <Typography className={classes.pos}>
                    {commentDetail?.comment}
                  </Typography>
                  <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    color="textSecondary"
                  >
                    {commentDetail?.commented_by_name}
                    &nbsp; &nbsp; &nbsp;
                    {moment(commentDetail?.created_at).format('DD-MM-YYYY')}
                    &nbsp;
                    {moment(commentDetail?.created_at).format('hh:mm A')}
                  </Typography>
                </div>
              ))}
            </div>
          ) : null}
        </CardContent>
      )}

      <CardActions>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            // marginBottom: '20px',
            margin: '20px',
          }}
        >
          <TextField
            fullWidth
            id="standard-basic"
            label="Add Comment"
            value={comment}
            onKeyDown={handleSubmitOnPress}
            onChange={handleTextChange}
          />
          {submitting ? (
            <CircularProgress size={20} />
          ) : (
            <Button
              size="small"
              variant="contained"
              style={buttonStyles}
              onClick={submitComment}
              disabled={comment === ''}
            >
              Submit
            </Button>
          )}
        </div>
      </CardActions>
    </Card>
  );
}
