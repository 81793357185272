/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Divider, Typography, Grid } from '@material-ui/core';
import FileViewer from './fileTypes/FileViewer';
import firebase from '../../config/config';
import { getViewURL } from '../../utils/fileUploadHandler';

const db = firebase.firestore();
const TransporterFiles = ({ transporterData, featureFlagFiles }) => {
  const { transporterId } = transporterData;

  const [adhaarUrl, setAdhaarUrl] = React.useState('');
  const [panUploadedBy, setPanUploadedBy] = React.useState('');
  const [adhaarBackUrl, setAdhaarBackUrl] = React.useState('');
  const [adhaarUploadedBy, setAdhaarUploadedBy] = React.useState('');
  const [lrUploadedBy, setLRUploadedBy] = React.useState('');
  const [gstUploadedBy, setGSTuploadedby] = React.useState('');
  const [panUrl, setPANUrl] = React.useState('');
  const [gstUrl, setGSTUrl] = React.useState('');
  const [lrUrl, setLRUrl] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  function getPANData() {
    const docRef = db
      .collection('FileUploads')
      .doc('TransporterFiles')
      .collection('PAN')
      .doc(transporterId);

    docRef
      .get()
      .then(doc => {
        if (doc.exists) {
          const data = doc.data();
          const { PANFileUploadURL, PANUpdatedByName } = data;
          setPANUrl(PANFileUploadURL);
          setPanUploadedBy(PANUpdatedByName);
        }
      })
      .catch(error => {
        console.log('Error getting document:', error);
      });
  }

  function getAdhaarData() {
    const docRef = db
      .collection('FileUploads')
      .doc('TransporterFiles')
      .collection('Adhaar')
      .doc(transporterId);
    // .collection('Adhaar')
    // .doc(transporterId);

    docRef
      .get()
      .then(doc => {
        if (doc.exists) {
          const data = doc.data();
          const { AdhaarFileUploadURL, AdhaarUpdatedByName } = data;
          setAdhaarUrl(AdhaarFileUploadURL);
          setAdhaarUploadedBy(AdhaarUpdatedByName);
        }
      })
      .catch(error => {
        console.log('Error getting document:', error);
      });
  }
  function getGSTData() {
    const docRef = db
      .collection('FileUploads')
      .doc('TransporterFiles')
      .collection('GST')
      .doc(transporterId);

    docRef
      .get()
      .then(doc => {
        if (doc.exists) {
          const data = doc.data();
          const { GSTFileUploadURL, GSTUpdatedByName } = data;
          setGSTUrl(GSTFileUploadURL);
          setGSTuploadedby(GSTUpdatedByName);
        }
      })
      .catch(error => {
        console.log('Error getting document:', error);
      });
  }
  function getLRData() {
    const docRef = db
      .collection('FileUploads')
      .doc('TransporterFiles')
      .collection('LR')
      .doc(transporterId);

    docRef
      .get()
      .then(doc => {
        if (doc.exists) {
          const data = doc.data();
          const { LRFileUploadURL, LRUpdatedByName } = data;
          setLRUrl(LRFileUploadURL);
          setLRUploadedBy(LRUpdatedByName);
        }
      })
      .catch(error => {
        console.log('Error getting document:', error);
      });
  }

  function getPANDataFromS3() {
    const viewURLBody = {
      scope: 'transporter',
      type: 'view',
      file_type: 'transporter-pan',
      transporter_id: transporterId,
    };
    getViewURL(viewURLBody)
      .then(result => {
        setPANUrl(result?.data?.url);
        setPanUploadedBy(result?.data?.uploaded_by);
      })
      .catch(e => console.log('error', e));
  }

  function getAdhaarDataFromS3() {
    const viewURLBody = {
      scope: 'transporter',
      type: 'view',
      file_type: 'transporter-aadhaar',
      transporter_id: transporterId,
    };

    getViewURL(viewURLBody)
      .then(result => {
        setAdhaarUrl(result?.data?.url);
        setAdhaarUploadedBy(result?.data?.uploaded_by);
      })
      .catch(e => console.log('error', e));
  }
  function getAdhaarBackDataFromS3() {
    const viewURLBody = {
      scope: 'transporter',
      type: 'view',
      file_type: 'transporter-aadhaar-back',
      transporter_id: transporterId,
    };

    getViewURL(viewURLBody)
      .then(result => {
        setAdhaarBackUrl(result?.data?.url);
        setAdhaarUploadedBy(result?.data?.uploaded_by);
      })
      .catch(e => console.log('error', e));
  }
  function getGSTDataFromS3() {
    const viewURLBody = {
      scope: 'transporter',
      type: 'view',
      file_type: 'transporter-aadhaar-back',
      transporter_id: transporterId,
    };

    getViewURL(viewURLBody)
      .then(result => {
        setGSTUrl(result?.data?.url);
        setGSTuploadedby(result?.data?.uploaded_by);
      })
      .catch(e => console.log('error', e));
  }
  function getLRDataFromS3() {
    const viewURLBody = {
      scope: 'transporter',
      type: 'view',
      file_type: 'transporter-gst',
      transporter_id: transporterId,
    };

    getViewURL(viewURLBody)
      .then(result => {
        setLRUrl(result?.data?.url);
        setLRUploadedBy(result?.data?.uploaded_by);
      })
      .catch(e => console.log('error', e));
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (featureFlagFiles === 2 && open) {
      getAdhaarDataFromS3();
      getPANDataFromS3();
      getLRDataFromS3();
      getGSTDataFromS3();
      getAdhaarBackDataFromS3();
    } else if (featureFlagFiles === 0 && open) {
      getAdhaarData();
      getPANData();
      getLRData();
      getGSTData();
    }
  }, [open]);

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={handleClickOpen}
        style={{ fontSize: '12px' }}
        disabled={featureFlagFiles === 1}
      >
        View Files
      </Button>
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div style={{ marginTop: '10px', padding: '10px' }}>
            <Divider />
            <Typography
              variant="body2"
              style={{ textAlign: 'center', marginTop: '10px' }}
            >
              Files Uploaded :
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FileViewer
                  url={adhaarUrl}
                  UploadedBy={adhaarUploadedBy}
                  type="Adhaar"
                />
              </Grid>
              {featureFlagFiles === 1 && (
                <Grid item xs={12}>
                  <FileViewer
                    url={adhaarBackUrl}
                    UploadedBy={adhaarUploadedBy}
                    type="Adhaar (Back)"
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <FileViewer
                  url={panUrl}
                  UploadedBy={panUploadedBy}
                  type="PAN"
                />
                <Grid item xs={12}>
                  <FileViewer
                    url={gstUrl}
                    type="GST"
                    UploadedBy={gstUploadedBy}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FileViewer url={lrUrl} UploadedBy={lrUploadedBy} type="LR" />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TransporterFiles;
