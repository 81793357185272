import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Switch } from 'react-router-dom';
// import * as Sentry from '@sentry/browser';
import { Provider } from 'react-redux';
import App from './app/app.jsx';

import store from './store';
import history from './history';
import 'assets/scss/material-dashboard-pro-react.css?v=1.2.0';

// if (process.env.REACT_APP_API_ENV === 'production') {
//   Sentry.init({
//     dsn: 'https://062b502dd6fa47a289e71bda9f4a7269@sentry.io/1470765',
//   });
// }
ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <App />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById('root')
);
