import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import moment from 'moment';
import apiCaller from '../../../../utils/apiCallerOutstanding';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

  accordionSummary: {
    backgroundColor: 'black', // Apply black background color to the summary
    color: 'white',
  },
  accordionDetails: { display: 'flex', flexDirection: 'column' },
  loadMoreButton: {
    marginTop: theme.spacing(2),
  },
}));

export default function StyledAccordion({ token, transporterID, activeRole }) {
  const classes = useStyles();
  const [comments, setComments] = useState([]);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const currentActiveRole = `is${activeRole
    .charAt(0)
    .toUpperCase()}${activeRole.slice(1)}`;

  async function fetchComments() {
    const endPoint = `pipe/trip-info?type=transporter&org_id=${transporterID}&detailed_type=comments&limit=20&offset=${offset}&role=${currentActiveRole}`;
    try {
      const response = await apiCaller(
        endPoint,
        {},
        `Bearer ${token}`,
        'nh-base',
        'get'
      );
      const newComments = response?.data?.Data || [];
      if (newComments.length === 0) {
        setHasMore(false);
      }
      setComments(prevComments => [...prevComments, ...newComments]);
    } catch (e) {}
  }

  useEffect(() => {
    fetchComments();
  }, [offset]);

  const fetchMoreComments = () => {
    setOffset(prevOffset => prevOffset + 20);
  };
  return (
    <div className={classes.root}>
      <Accordion className={classes.accordion}>
        <AccordionSummary
          className={classes.accordionSummary}
          expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Comments</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          {comments?.map(comment => (
            <div
              key={comment?.trip_no}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                padding: '10px 0px',
                borderBottom: '1px solid lightgray',
              }}
            >
              <div style={{ flex: 1 }}>
                <Typography>{comment?.comment}</Typography>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <span
                  style={{
                    background: '#EDEFF5',
                    color: '#474D66',
                    borderRadius: '5px',
                    fontWeight: 'bold',
                    padding: '2px 5px',
                    textTransform: 'uppercase',
                    fontSize: '12px',
                    marginRight: '10px',
                  }}
                >
                  {comment?.trip_no}
                </span>
                <span
                  style={{
                    background: '#1E7627',
                    color: '#FFFFFF',
                    borderRadius: '5px',
                    fontWeight: 'bold',
                    padding: '2px 5px',
                    textTransform: 'uppercase',
                    fontSize: '12px',
                    marginRight: '10px',
                  }}
                >
                  {comment?.commented_by}
                </span>
                <p style={{ color: '#AFB2B7', margin: 0, padding: 0 }}>
                  {comment?.commented_at
                    ? moment(comment?.commented_at).format('DD-MM-YYYY hh:mm A')
                    : null}
                </p>
              </div>
            </div>
          ))}
          <Button
            variant="outlined"
            color="primary"
            className={classes.loadMoreButton}
            onClick={fetchMoreComments}
            disabled={!hasMore}
          >
            {hasMore ? 'Load More' : 'No more comments'}
          </Button>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
