import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import CardIcon from 'components/Card/CardIcon.jsx';
import Assignment from '@material-ui/icons/Assignment';
import Edit from '@material-ui/icons/Edit';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Table from 'components/Table/Table.jsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';
import TransactionStyle from 'assets/jss/material-dashboard-pro-react/views/TransactionStyle';
import firebase from '../../config/config';
import AdminEditTransporter from './AdminEditTransporter';

const db = firebase.firestore();

class PremiumTransporters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      premiumTransportersData: [],
      isLoading: false,
      view: true,
    };
    this.getTable = this.getTable.bind(this);
    this.getPremiumTransporters = this.getPremiumTransporters.bind(this);
    this.AddUser = this.AddUser.bind(this);
    this.goToView = this.goToView.bind(this);
  }

  componentDidMount() {
    const userRef = db.collection('Users');
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.setState({ isLoading: true });
        userRef
          .doc(`${user.uid}`)
          .get()
          .then(resultData => {
            if (resultData.exists) {
              this.getPremiumTransporters();
            } else {
              this.setState({
                isLoading: false,
              });
            }
          });
      } else {
        this.setState({
          isLoading: false,
        });
      }
    });
  }

  /**
   * Will Get ALl the Truckers For The User
   * @function
   */
  getPremiumTransporters() {
    const { classes } = this.props;
    const colors = ['info', 'success'];
    const premiumTransporterAdmin = db
      .collection('Users')
      .where('isPremiumTransporterAdmin', '==', true)
      .get();
    const premiumTransporterContact = db
      .collection('Users')
      .where('isPremiumTransporterContact', '==', true)
      .get();
    Promise.all([premiumTransporterAdmin, premiumTransporterContact]).then(
      data => {
        const premiumTransporters = [];
        data.forEach(snap => {
          this.setState({ isLoading: false });
          let flag = 0;

          snap.forEach(userSnap => {
            const userResult = { ...userSnap.data(), userId: userSnap.id };
            premiumTransporters.push({
              color: colors[flag],
              data: [
                userResult.name,
                userResult.phoneNumber,
                userResult.email,
                <Button
                  color="warning"
                  className={classes.actionButton}
                  key={userResult.userId}
                  onClick={() => this.openEditForm(userResult)}
                  justIcon
                >
                  <Edit className={classes.icon} />
                </Button>,
              ],
            });
            if (flag === 0) {
              flag = 1;
            } else {
              flag = 0;
            }
          });
          this.setState({
            premiumTransportersData: premiumTransporters,
          });
        });
      }
    );
  }

  /**
   * Will return the Table Component
   * @function
   */
  getTable() {
    const { classes } = this.props;
    const { premiumTransportersData } = this.state;
    return (
      <Card>
        <CardHeader color="warning" icon>
          <CardIcon color="warning">
            <Assignment />
          </CardIcon>
        </CardHeader>
        <CardBody className={classes.customCardContentClass}>
          <Table
            hover
            tableHead={['Name', 'Phone', 'Email']}
            tableData={premiumTransportersData}
          />
        </CardBody>
      </Card>
    );
  }

  /**
   * will redirect to Add Truckers Page
   * @function
   */
  AddUser() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.history.push('/agent/premiumTransporters/add');
  }

  goToView() {
    this.setState({ view: true });
  }

  openEditForm(user) {
    this.setState({ selectedUserData: user, view: false });
  }

  render() {
    const { classes } = this.props;
    const {
      view,
      premiumTransportersData,
      isLoading,
      selectedUserData,
    } = this.state;
    return view ? (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader className={`${classes.cardHeader}`} color="warning">
                <p className={`${classes.cardHeader}`}>PremiumTransporters</p>
                <h3 className={classes.cardTitle}>
                  {premiumTransportersData.length} <small />
                </h3>
              </CardHeader>
              <CardBody
                className={`${classes.cardHeader} ${classes.textCenter}`}
              >
                {isLoading ? (
                  <CircularProgress
                    className={classes.progress}
                    style={{ color: purple[500] }}
                    thickness={7}
                  />
                ) : (
                  <React.Fragment>
                    {premiumTransportersData.length <= 0 ? (
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        <p className={classes.textCenter}>
                          No PremiumTransporters Available
                        </p>
                        <div className={classes.textCenter}>
                          <Button round color="warning" onClick={this.AddUser}>
                            Add User
                          </Button>
                        </div>
                      </GridItem>
                    ) : (
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        {this.getTable()}
                      </GridItem>
                    )}
                  </React.Fragment>
                )}
              </CardBody>
              <CardFooter stats>
                <div className={classes.stats} />
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    ) : (
      <AdminEditTransporter
        goBack={this.goToView}
        selectedUserData={selectedUserData}
      />
    );
  }
}

PremiumTransporters.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withStyles(TransactionStyle)(PremiumTransporters);
