/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import CardIcon from 'components/Card/CardIcon.jsx';
import Assignment from '@material-ui/icons/Assignment';
import Edit from '@material-ui/icons/Edit';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Table from 'components/Table/Table.jsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import TransactionStyle from 'assets/jss/material-dashboard-pro-react/views/TransactionStyle';
import firebase from '../../config/config';
import AdminEditUser from './AdminEditUser';
import FeatureOffset from './FeatureOffset';

const db = firebase.firestore();

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      companyID: '',
      uid: '',
      usersData: [],
      isLoading: false,
      view: true,
      featureFlagUsers: true,
    };
    this.getTable = this.getTable.bind(this);
    this.getUsers = this.getUsers.bind(this);
    this.AddUser = this.AddUser.bind(this);
    this.goToView = this.goToView.bind(this);
    this.getFeatureFlagStatus = this.getFeatureFlagStatus.bind(this);
  }

  componentDidMount() {
    const userRef = db.collection('Users');
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.setState({ isLoading: true });
        userRef
          .doc(`${user.uid}`)
          .get()
          .then(resultData => {
            if (resultData.exists) {
              this.getUsers(user);
            }
          });
      } else {
        this.setState({
          isLoading: false,
        });
      }
    });
    this.getFeatureFlagStatus();
  }

  openEditForm(user) {
    //  console.log("Trucker Data", trucker)
    this.setState({ selectedUserData: user, view: false });
  }

  goToView() {
    this.setState({ view: true });
  }

  getFeatureFlagStatus() {
    db.collection('FeatureFlags')
      .doc('LobbPipeWeb')
      .onSnapshot(doc => {
        if (doc.exists) {
          const data = doc.data();
          this.setState({ featureFlagUsers: data?.Manage_Users_Office });
        }
      });
  }
  /**
   * Will Get ALl the Truckers For The User
   * @function
   */
  getUsers(user) {
    const { classes } = this.props;
    const colors = ['info', 'success'];
    const userRef = db.collection('Users');
    userRef.onSnapshot(usersData => {
      this.setState({ isLoading: false });
      let flag = 0;
      const users = [];
      usersData.forEach(user => {
        const userResult = { ...user.data(), userId: user.id };
        users.push({
          color: colors[flag],
          data: [
            userResult.name,
            userResult.phoneNumber,
            userResult.email,
            <Button
              color="warning"
              className={classes.actionButton}
              key={userResult.userId}
              onClick={() => this.openEditForm(userResult)}
              justIcon
            >
              <Edit className={classes.icon} />
            </Button>,
          ],
        });
        if (flag === 0) {
          flag = 1;
        } else {
          flag = 0;
        }
      });
      this.setState({
        usersData: users,
      });
    });
  }

  /**
   * Will return the Table Component
   * @function
   */
  getTable() {
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader color="warning" icon>
          <CardIcon color="warning">
            <Assignment />
          </CardIcon>
        </CardHeader>
        <CardBody className={classes.customCardContentClass}>
          <Table
            hover
            tableHead={['Name', 'Phone', 'Email']}
            tableData={this.state.usersData}
          />
        </CardBody>
      </Card>
    );
  }

  /**
   * will redirect to Add Truckers Page
   * @function
   */
  AddUser() {
    this.props.history.push('/agent/users/add');
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  render() {
    const { classes, handleToggle } = this.props;
    const { featureFlagUsers } = this.state;
    return this.state.view ? (
      <div>
        {!featureFlagUsers ? (
          <FeatureOffset />
        ) : (
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardHeader className={`${classes.cardHeader}`} color="warning">
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'end',
                    }}
                  >
                    <div>
                      <p className={`${classes.cardHeader}`}>Users</p>
                      <h3 className={classes.cardTitle}>
                        {this.state.usersData.length} <small />
                      </h3>
                    </div>
                    {this.state.featureFlagUsers && (
                      <IconButton
                        onClick={handleToggle}
                        color="primary"
                        aria-label="add to shopping cart"
                      >
                        <AddIcon style={{ color: 'white' }} />
                      </IconButton>
                    )}
                  </div>
                </CardHeader>
                <CardBody
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                >
                  {this.state.isLoading ? (
                    <CircularProgress
                      className={classes.progress}
                      style={{ color: purple[500] }}
                      thickness={7}
                    />
                  ) : (
                    <React.Fragment>
                      {this.state.usersData.length <= 0 ? (
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                          <p className={classes.textCenter}>
                            No Users Available
                          </p>
                          <div className={classes.textCenter}>
                            <Button
                              round
                              color="warning"
                              onClick={this.AddUser}
                            >
                              Add User
                            </Button>
                          </div>
                        </GridItem>
                      ) : (
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                          {this.getTable()}
                        </GridItem>
                      )}
                    </React.Fragment>
                  )}
                </CardBody>
                <CardFooter stats>
                  <div className={classes.stats} />
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        )}
      </div>
    ) : (
      <AdminEditUser
        goBack={this.goToView}
        selectedUserData={this.state.selectedUserData}
      />
    );
  }
}

Users.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(TransactionStyle)(Users);
