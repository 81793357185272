/* eslint-disable react/no-deprecated */
/* eslint-disable react/sort-comp */
/* eslint-disable no-shadow */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-undef */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-plusplus */
import React from 'react';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import PropTypes from 'prop-types';

import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import TransactionStyle from 'assets/jss/material-dashboard-pro-react/views/TransactionStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';
import Snackbar from 'components/Snackbar/Snackbar.jsx';
import Table from 'components/Table/Table.jsx';
import { connect } from 'react-redux';
// @material-ui/icons
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Refresh from '@material-ui/icons/Refresh';

import AddAlert from '@material-ui/icons/AddAlert';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Checkbox from '@material-ui/core/Checkbox';
import firebase from '../../../config/config';
import auditLogger from '../../../utils/auditLoggerToPG';

const moment = require('moment');

const db = firebase.firestore();
const processQueuePayouts = firebase
  .functions()
  .httpsCallable('payable-processQueuePayouts');

class Queued extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      financeQueuedPayouts: [],
      selectedQueuedPayouts: [],
      finalQueuedPayouts: [],
      queuedPayoutsListData: [],
      isLoading: false,
      selctedPayableEntry: {},
      selectedEntry: {},
      selectedCheckboxes: [],
      ch: [],
      pin: '',
      pinState: '',
      pinModal: false,
      selectedModal: false,
      pinSubmitted: false,
      limit: false,
      limitMessage: 'You exceed the limit of 5',
      message: 'Payouts Queue Submited Successfully',
      errMessage: '',
      errSnack: false,
    };
    this.change = this.change.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.showConfirmModal = this.showConfirmModal.bind(this);
    this.showConfirmModalClose = this.showConfirmModalClose.bind(this);
    this.validateAllFields = this.validateAllFields.bind(this);
    this.getFinanceQueuedPayouts = this.getFinanceQueuedPayouts.bind(this);
    this.refreshFinanceQueue = this.refreshFinanceQueue.bind(this);
  }

  componentDidMount() {
    this.getFinanceQueuedPayouts();
  }

  refreshFinanceQueue() {
    this.setState(
      {
        queuedPayoutsListData: [],
        selectedQueuedPayouts: [],
        finalQueuedPayouts: [],
        errMessage: '',
        errSnack: false,
        limit: false,
        selectedModal: false,
        pinModal: false,
      },
      this.getFinanceQueuedPayouts()
    );
  }

  componentWillUnmount() {
    let id = window.setTimeout(null, 0);
    while (id--) {
      window.clearTimeout(id);
    }
  }

  /**
   * Will Get ALl the Finance Queued Payouts
   * @function
   */
  getFinanceQueuedPayouts() {
    const payoutRef = db
      .collection(`Payouts`)
      .where('status', '==', 'finance_queued')
      .orderBy('created_at', 'desc');
    payoutRef.get().then(payoutSnapData => {
      const financeQueuedPayouts = [];
      const newQueuedPayoutsListData = [];
      const colors = ['info', 'success'];
      let flag = 0;
      payoutSnapData.forEach(transaction => {
        const payoutData = transaction.data();
        newQueuedPayoutsListData.push({
          color: colors[flag],
          id: payoutData.payoutId,
          checked: false,
          data: [
            <Checkbox
              checked={false}
              className="check1"
              disabled={
                payoutData.status === 'finance_queued_processing' ||
                payoutData.void
              }
              value={payoutData.payoutId}
              onChange={e => this.handleChange(e, payoutData)}
            />,
            this.getBoldCell(`#${payoutData.transactionSerial}`),
            `${payoutData.fromCity || '__'} --> ${payoutData.toCity || '__'}`,
            payoutData.transporterName || '__',
            payoutData.truckerName || '__',
            payoutData.vehicleNumber || '__',
            this.formatMoney(payoutData.freight) || '__',
            this.formatMoney(payoutData.commission) || '__',
            this.getAmountBoldCell(payoutData.amount),
            payoutData.branch.substring(0, 3) || '__',
            moment(payoutData.created_at.toDate()).format('MMM Do Y') || '__',
            this.getStatusCell(payoutData.status),
          ],
        });

        financeQueuedPayouts.push({
          id: payoutData.payoutId,
          txn_no: payoutData.transactionSerial,
          amount: payoutData.amount,
          status: payoutData.status,
          transactionId: payoutData.transactionId,
        });
        if (flag === 0) {
          flag = 1;
        } else {
          flag = 0;
        }
      });
      this.setState({
        financeQueuedPayouts,
        queuedPayoutsListData: newQueuedPayoutsListData,
      });
    });
  }

  /**
   * Handle the changes happened in checkbox
   * @param {event} e
   */
  handleChange(e, myitem) {
    // const item = e.target.name;
    const { selectedQueuedPayouts, queuedPayoutsListData } = this.state;
    for (let j = 0; j <= queuedPayoutsListData.length - 1; j++) {
      if (queuedPayoutsListData[j].id === myitem.payoutId) {
        queuedPayoutsListData[j].data[0] = (
          <Checkbox
            checked={e.target.checked}
            className="check1"
            disabled={myitem.status === 'finance_queued_processing'}
            value={myitem.payoutId}
            onChange={e => this.handleChange(e, myitem)}
          />
        );
        queuedPayoutsListData[j].checked = e.target.checked;
      }
    }
    this.setState({
      queuedPayoutsListData,
    });
    if (selectedQueuedPayouts.indexOf(myitem) > -1) {
      // Used to pop up the checkbox from the array
      for (let i = selectedQueuedPayouts.length - 1; i >= 0; i--) {
        if (selectedQueuedPayouts[i].payoutId === myitem.payoutId) {
          selectedQueuedPayouts.splice(i, 1);
          // break;       //<-- Uncomment  if only the first term has to be removed
        }
      }
      this.setState({
        selectedQueuedPayouts,
      });
      if (selectedQueuedPayouts.length === 0) {
        this.setState({
          selectedModal: false,
        });
      } else {
        this.setState({
          selectedModal: true,
        });
      }
    } else {
      selectedQueuedPayouts.push(myitem);
      this.setState({
        selectedQueuedPayouts,
        selectedModal: true,
      });
    }
  }

  getStatusCell(status) {
    let color;
    switch (status) {
      case 'finance_queued':
        color = '#0000FF';
        break;
      case 'finance_queued_processing':
        color = '#008000';
        break;
      case 'finance_queued_rejected':
        color = '#f5709d';
        break;
      case 'reversed':
        color = '#0000FF';
        break;
      case 'cancelled':
        color = '#DC143C';
        break;
      case 'initiated':
        color = '#ff8b83';
        break;
      case 'Failure':
        color = '#DC143C';
        break;
      case 'pending_sales_auth':
        color = '#10dcc9';
        break;
      case 'sales_rejected':
        color = '#f5709d';
        break;
      case 'Sales Rejected':
        color = '#f5709d';
        break;
      default:
        color = '#000000';
    }

    return (
      <span
        style={{
          fontSize: '16px',
          fontWeight: 'bold',
          color,
        }}
      >
        {status.toUpperCase()}
      </span>
    );
  }

  getAmountBoldCell(amount) {
    return (
      <span
        style={{ fontSize: '16px', fontWeight: 'bold', marginLeft: '10px' }}
      >
        <i
          className="fa fa-inr"
          style={{ fontSize: '12px', paddingRight: '2px' }}
          aria-hidden="true"
        />
        {Intl.NumberFormat('en-IN').format(amount)}
      </span>
    );
  }

  formatMoney(amount) {
    return (
      <span style={{ fontSize: '16px' }}>
        {Intl.NumberFormat('en-IN').format(amount)}
      </span>
    );
  }

  getBoldCell(amount) {
    return (
      <span
        style={{ fontSize: '16px', fontWeight: 'bold', marginLeft: '10px' }}
      >
        {amount}
      </span>
    );
  }

  /**
   * To get the list of items with the checkbox
   */
  getList() {
    const { queuedPayoutsListData } = this.state;
    return (
      <Table
        hover
        tableHead={[
          '',
          'TXN',
          'From --> To',
          'Transporter',
          'Trucker',
          'Truck_no',
          'Freight',
          'Comm',
          'Amount',
          'Branch',
          'Date',
          'Status',
        ]}
        tableData={queuedPayoutsListData}
      />
    );
  }

  /**
   * Show the popup when the user click on any one of the checkboxes.
   */
  showPINForm = () => (
    <form onSubmit={event => this.confirmQueuePayouts(event)}>
      <GridContainer>
        <GridItem xs={4} sm={4} md={4}>
          <CustomInput
            // eslint-disable-next-line react/destructuring-assignment
            success={this.state.pinState === 'success'}
            // eslint-disable-next-line react/destructuring-assignment
            error={this.state.pinState === 'error'}
            labelText={
              <span>
                Pin <small>*</small>
              </span>
            }
            id="pin"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: this.state.pin,
              type: 'password',
              onChange: event => this.change(event, 'pin', 'otp', 4),
              endAdornment: (
                <InputAdornment position="end">
                  <VpnKeyIcon />
                </InputAdornment>
              ),
            }}
          />
        </GridItem>
        <GridItem xs={4} sm={4} md={4}>
          {this.state.isLoading ? (
            <CircularProgress style={{ color: purple[500] }} thickness={7} />
          ) : (
            <div tyle={{ paddingTop: '10px' }}>
              <Button
                type="submit"
                color="success"
                style={{ paddingTop: '10px' }}
              >
                Submit
              </Button>
            </div>
          )}
        </GridItem>
        <GridItem xs={4} sm={4} md={4} />
      </GridContainer>
    </form>
  );

  /**
   * Shows the notification when user click submit for success message.
   */
  showNotification = place => {
    if (!this.state[place]) {
      const x = [];
      x[place] = true;
      this.setState(x);
      setTimeout(() => {
        x[place] = false;
        this.setState(x);
      }, 6000);
    }
  };

  capitalizeFirstLetter(str) {
    // converting first letter to uppercase
    const capitalized = str.charAt(0).toUpperCase() + str.slice(1);

    return capitalized;
  }

  submitQueuePayouts = () => {
    const { userDetails, activeRole } = this.props;
    const { selectedQueuedPayouts } = this.state;
    const { uid } = userDetails;
    const user = userDetails;
    this.setState({
      isLoading: true,
    });
    if (selectedQueuedPayouts.length > 5 && selectedQueuedPayouts.length < 1) {
      let errMessage = '';
      if (selectedQueuedPayouts.length > 5) {
        errMessage = 'You can only select 5 Transactions at a time';
      } else {
        errMessage = 'No Queues are selected';
      }
      this.setState(
        {
          errMessage,
          isLoading: false,
        },
        this.showNotification('errSnack')
      );
    } else {
      const body = {
        queuedPayouts: selectedQueuedPayouts,
        userId: uid,
        pin: Number(this.state.pin),
      };
      processQueuePayouts(body)
        .then(response => {
          if (response.data.statusCode === 200) {
            this.showNotification('pinSubmitted');
            this.setState({
              pinModal: false,
              isLoading: false,
              pin: '',
              errMessage: '',
              selectedQueuedPayouts: [],
              selectedModal: false,
            });
            const systemDetails = JSON.parse(
              localStorage.getItem('systemDetails')
            );
            const auditPayload = {
              collection: 'Finance Queued',
              orgId: null,
              data: body,
              message: `${user?.name} with email id ${user?.email} approved payouts queued`,
              systemDetails,
              type: `approve queued`,
              role: `${this.capitalizeFirstLetter(activeRole.split('is')[0])}`,
              eventDateTime: new Date().toISOString(),
              source: 'office',
            };
            auditLogger(auditPayload);
            this.getFinanceQueuedPayouts();
          } else if (response.data.statusCode === 400) {
            this.setState(
              {
                isLoading: false,
                errMessage: 'Invalid PIN',
                pin: '',
              },
              this.showNotification('errSnack')
            );
            const systemDetails = JSON.parse(
              localStorage.getItem('systemDetails')
            );
            const auditPayload = {
              collection: 'Finance Queued',
              orgId: null,
              data: body,
              message: `${user?.name} with email id ${user?.email} approved payouts queued but failed due to wrong PIN`,
              systemDetails,
              type: `approve queued failed`,
              role: `${this.capitalizeFirstLetter(activeRole.split('is')[0])}`,
              eventDateTime: new Date().toISOString(),
              source: 'office',
            };
            auditLogger(auditPayload);
          } else {
            this.setState(
              {
                isLoading: false,
                pin: '',
                errMessage: 'Something Went Wrong, Please Try Again!',
              },
              this.showNotification('errSnack')
            );
          }
        })
        .catch(err => {
          this.setState(
            {
              isLoading: false,
              pin: '',
              errMessage: 'Something Went Wrong, Please Try Again!',
            },
            this.showNotification('errSnack')
          );
          const systemDetails = JSON.parse(
            localStorage.getItem('systemDetails')
          );
          const auditPayload = {
            collection: 'Finance Queued',
            orgId: null,
            data: body,
            message: `${user?.name} with email id ${user?.email} approved payouts queued but failed due to ${err}`,
            systemDetails,
            type: `approve queued failed`,
            role: `${this.capitalizeFirstLetter(activeRole.split('is')[0])}`,
            eventDateTime: new Date().toISOString(),
            source: 'office',
          };
          auditLogger(auditPayload);
        });
    }
  };

  /**
   * Open the pin modal to show the user to enter the input field.
   */
  showConfirmModal() {
    const { classes } = this.props;
    const { errMessage, isLoading } = this.state;
    return (
      <Dialog
        open={this.state.pinModal}
        keepMounted
        style={{ maxHeight: '80%' }}
      >
        <DialogTitle>
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            Are you sure you want to{' '}
          </span>
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            continue?
          </span>
        </DialogTitle>
        <DialogActions>
          {isLoading ? (
            <div>
              <CircularProgress
                className={classes.progress}
                style={{ color: purple[500] }}
                thickness={7}
              />
            </div>
          ) : (
            <div>
              <Button onClick={this.showConfirmModalClose} color="danger">
                No
              </Button>
              <Button onClick={this.submitQueuePayouts} color="success">
                Yes
              </Button>
              <p style={{ color: 'red' }}>{errMessage}</p>
            </div>
          )}
        </DialogActions>
      </Dialog>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  /**
   * Execute when the user enter the pin and press submit
   * @param {event} event
   */
  confirmQueuePayouts(event, props) {
    this.validateAllFields()
      .then(success => {
        if (success) {
          if (!this.state.pinModal) {
            this.setState({
              pinModal: true,
            });
          }
          this.showConfirmModal();
        }
      })
      .catch(err => {
        console.log('error');
      });
    event.preventDefault();
  }

  /**
   * To close the pin modal
   */
  showConfirmModalClose() {
    this.setState({
      pinModal: false,
    });
  }

  /**
   * To verify the number
   * @param {value} value
   * @param {stateName} stateName
   */
  // eslint-disable-next-line class-methods-use-this
  verifyNumber(value, stateName) {
    const numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value) && value.length <= 4) {
      return true;
    }
    return false;
  }

  verifyOtp(value) {
    const numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value) && value.toString().length === 4) {
      return true;
    }
    return false;
  }

  /**
   * Execute when the user click on checkbox
   * @param {event} event
   * @param {stateName} stateName
   * @param {type} type
   * @param {stateNameEqualTo} stateNameEqualTo
   */
  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'number':
        if (this.verifyNumber(event.target.value, stateName)) {
          this.setState(
            {
              [`${stateName}State`]: 'success',
              [stateName]: event.target.value,
            }
            // this.calculateFields
          );
        } else if (event.target.value === '') {
          this.setState({
            [`${stateName}State`]: 'Invalid',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'Invalid',
          });
        }
        break;
      case 'otp':
        if (this.verifyOtp(event.target.value, stateNameEqualTo)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      default:
        break;
    }
  }

  /**
   * To verify the entered length
   * @param {value} value
   * @param {length} length
   */
  // eslint-disable-next-line class-methods-use-this
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  /**
   * Used to validate the Field
   */
  validateAllFields() {
    let error = false;
    return new Promise((resolve, reject) => {
      // Verify Name
      // eslint-disable-next-line react/destructuring-assignment
      if (this.verifyOtp(this.state.pin)) {
        this.setState({
          pinState: 'success',
          errMessage: '',
        });
      } else {
        this.setState(
          {
            pinState: 'error',
            errMessage: 'Invalid Otp',
          },
          this.showNotification('errSnack')
        );
        error = true;
      }
      if (!error) {
        resolve(true);
      } else {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject('validation Failed');
      }
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <GridContainer>
        <Snackbar
          place="tc"
          color="success"
          icon={AddAlert}
          message={this.state.message}
          open={this.state.pinSubmitted}
          closeNotification={() => this.setState({ pinSubmitted: false })}
          close
        />
        <Snackbar
          place="tc"
          color="danger"
          icon={AddAlert}
          message={this.state.errMessage}
          open={this.state.errSnack}
          closeNotification={() => this.setState({ limit: false })}
          close
        />
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning" style={{ padding: '0px' }}>
              <CardIcon color="primary">
                {' '}
                {this.state.financeQueuedPayouts.length}{' '}
              </CardIcon>
              <h4>
                {' '}
                Finance Queue <small />{' '}
              </h4>
              <Button
                size="md"
                color="white"
                simple
                style={{ float: 'right', top: '0px' }}
                onClick={() => this.refreshFinanceQueue()}
              >
                <Refresh className={classes.underChartIcons} />
              </Button>
            </CardHeader>
            <CardBody>
              {this.getList()}
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  {this.state.selectedModal === false ? '' : this.showPINForm()}
                </GridItem>
                {this.state.pinModal === true ? this.showConfirmModal() : ''}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

Queued.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  activeRole: state.main.activeRole,
  userDetails: state.main.userDetails,
  systemDetails: state.main.systemDetails,
});

export default connect(
  mapStateToProps,
  null
)(withStyles(TransactionStyle)(Queued));
