import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';

const TdsPercentage = ({
  activeRole,
  truckerId,
  fetchTdsPercentage,
  tdsPercentage,
}) => {
  useEffect(() => {
    if (fetchTdsPercentage) {
      fetchTdsPercentage();
    }
  }, [fetchTdsPercentage]);

  return (
    <div>
      <Typography>TDS %</Typography>
      <Typography style={{ marginTop: '10px' }}>
        {tdsPercentage === 'INVALID PAN' ? tdsPercentage : `${tdsPercentage} %`}
      </Typography>
    </div>
  );
};

export default TdsPercentage;
