/* eslint-disable no-prototype-builtins */
/* eslint-disable no-plusplus */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-empty */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-unused-state */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';

// @material-ui/Icons

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import CustomTabs from 'components/CustomTabs/CustomTabs.jsx';
import Select from '@material-ui/core/Select';

import Search from '@material-ui/icons/Search';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import SweetAlert from 'react-bootstrap-sweetalert';
import FormControl from '@material-ui/core/FormControl';
import Snackbar from 'components/Snackbar/Snackbar.jsx';
import AddAlert from '@material-ui/icons/AddAlert';

import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Datetime from 'react-datetime';

// Component for Transactions Card
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Responsive from 'react-responsive';
import TransactionStyle from 'assets/jss/material-dashboard-pro-react/views/TransactionStyle';
// import * as Fuse from 'fuse.js';
import ReactSelect from 'react-select';
import Check from '@material-ui/icons/Check';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TransactionsCard from './Sections/TransactionsCard';

import DetailedTransactions from './Sections/DetailedTransactions';
import ModifyTransaction from './Sections/ModifyTransaction';

import {
  switchActivePage,
  setTransactions,
  setApprovedTransactions,
  setModifyTransactions,
  setPendingTransactions,
  setRejectedTransactions,
} from '../../reducers/transactions';
import firebase from '../../config/config';
import TRansactionsListDownload from './Sections/TRansactionsListDownload';

const Desktop = props => <Responsive {...props} minWidth={701} />;
const Mobile = props => <Responsive {...props} maxWidth={700} />;
const db = firebase.firestore();

class Transactions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // value: 0,
      // isDetailedForm: false,
      // transactions: [],
      pendingTransactions: [],
      approvedTransactions: [],
      modifyTransactions: [],
      rejectedTransactions: [],
      selectedBranch: '',
      approvedNextActive: false,
      approvedPrevActive: false,
      pendingNextActive: false,
      pendingPrevActive: false,
      modifyNextActive: false,
      modifyPrevActive: false,
      rejectedNextActive: false,
      rejectedPrevActive: false,
      checksAlert: false,
      searchText: '',
      searchTextState: '',
      fromDate: '',
      toDate: '',
      isFilter: false,
      truckers: [],
      selectedTrucker: null,
      truckerName: '',
      truckerId: null,
      transporterId: null,
      transporterName: null,
      transporters: [],
      selectedTransporter: null,
      isPodCollected: false,
      isPodSubmitted: false,
      riskStatusOptions: [
        {
          label: 'Green',
          value: 'green',
          name: 'Green',
        },
        {
          label: 'Amber',
          value: 'amber',
          name: 'Amber',
        },
        {
          label: 'Red',
          value: 'red',
          name: 'Red',
        },
      ],
      selectedRiskStatus: null,
    };
    this.switchTab = this.switchTab.bind(this);
    this.getTabs = this.getTabs.bind(this);
    this.unsubscribePendingPage = () => null;
    this.unsubscribeModifyPage = () => null;
    this.unsubscribeApprovedPage = () => null;
    this.unsubscribeRejectedPage = () => null;
    this.showChecksAlert = this.showChecksAlert.bind(this);
    this.closeChecksAlert = this.closeChecksAlert.bind(this);
    this.confirmDates = this.confirmDates.bind(this);
    this.fromDateChange = this.fromDateChange.bind(this);
    this.toDateChange = this.toDateChange.bind(this);
    this.searchTransaction = this.searchTransaction.bind(this);
    this.filterTransactions = this.filterTransactions.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getTruckers = this.getTruckers.bind(this);
    this.handleTrucker = this.handleTrucker.bind(this);
    this.handleTransporter = this.handleTransporter.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.getTransporters = this.getTransporters.bind(this);
  }

  componentDidMount() {
    const { userDetails } = this.props;
    // eslint-disable-next-line react/destructuring-assignment
    this.props.switchActivePage(false);
    this.getTruckers();
    this.getTransporters();
    if (!userDetails.isAdmin) {
      this.setState({ selectedBranch: userDetails.branches[0] }, () =>
        this.getTransactions()
      );
    } else {
      this.getTransactions();
    }
    this.setState({ selectedBranch: '' });
  }

  componentWillUnmount() {
    let id = window.setTimeout(null, 0);
    while (id--) {
      window.clearTimeout(id);
    }
  }

  handleToggle(value, stateName) {
    this.setState({
      [`${stateName}`]: value,
    });
  }

  /**
   * will return All the Truckers Available for User
   * @function
   * @param {String} companyId
   */
  getTruckers() {
    const TruckerRef = db.collection('Truckers');
    TruckerRef.where('status', '==', 'approved')
      .get()
      .then(
        resultData => {
          const truckers = [];
          resultData.forEach(trucker => {
            if (trucker.data().updatedByRole !== 'premiumTransporter') {
              const data = trucker.data();
              data.label = trucker.data().name;
              data.value = trucker.data().truckerId;
              truckers.push(data);
            }
          });
          this.setState({
            truckers,
          });
        },
        err => {
          console.log('err', err);
        }
      )
      .catch(err => {
        console.log('err', err);
      });
  }

  /**
   * will return All the Transporters Available for User
   * @function
   * @param {String} companyId
   */
  getTransporters() {
    const TransporterRef = db.collection(`Transporters`);
    TransporterRef.where('status', '==', 'approved')
      .get()
      .then(
        resultData => {
          const transporters = [];
          resultData.forEach(transporter => {
            //  Checks if transporter and user have any common branches
            const data = transporter.data();
            data.label = transporter.data().name;
            data.value = transporter.data().transporterId;
            transporters.push(data);
          });
          this.setState({ transporters });
        },
        err => {
          console.log('err', err);
        }
      )
      .catch(err => {
        console.log('err', err);
      });
  }

  handleTrucker = selectedTrucker => {
    this.setState({
      selectedTrucker,
      truckerName: selectedTrucker.name,
      truckerId: selectedTrucker.truckerId,
    });
  };

  handleTransporter = selectedTransporter => {
    this.setState({
      selectedTransporter,
      transporterName: selectedTransporter.name,
      transporterId: selectedTransporter.transporterId,
    });
  };

  handleRiskStatus = selectedRiskStatus => {
    this.setState({
      selectedRiskStatus,
    });
  };

  getTransactions() {
    this.getStatusTransactions('approved');
    this.getStatusTransactions('pending');
    this.getModifyStatusTransactions('modify');
    this.getStatusTransactions('rejected');
  }

  showChecksAlert() {
    this.setState({
      checksAlert: true,
      checksError: '',
    });
  }

  // eslint-disable-next-line lines-between-class-members
  closeChecksAlert() {
    this.setState({
      checksAlert: false,
      checksError: '',
    });
  }

  // eslint-disable-next-line lines-between-class-members
  resetFilter() {
    this.setState(
      {
        checksAlert: false,
        checksError: '',
        isFilter: false,
        fromDate: '',
        toDate: '',
        selectedTransporter: '',
        selectedTrucker: '',
        isPodCollected: false,
        isPodSubmitted: false,
        selectedRiskStatus: '',
      },
      () => this.getTransactions()
    );
  }

  confirmDates() {
    const { fromDate, toDate } = this.state;
    if (fromDate !== '' && toDate === '') {
      this.showNotification('tc');
    } else if (fromDate === '' && toDate !== '') {
      this.showNotification('tc');
    } else {
      this.setState(
        {
          isFilter: true,
          checksAlert: false,
          checksError: '',
          transactionId: '',
        },
        () => this.getTransactions()
      );
    }
  }

  searchBetweenDates(status) {
    const { fromDate, toDate } = this.state;
    const startDate = new Date(fromDate);
    const endDate = new Date(toDate);
    let transactionsRef;

    const { userDetails } = this.props;
    let { selectedBranch } = this.state;
    if (!selectedBranch) selectedBranch = userDetails.branches[0];
    // If user is an admin, show them all transactions.

    if (userDetails.isAdmin) {
      transactionsRef = db
        .collection(`Transactions`)
        .where('status', '==', status)
        .where('createdAt', '>', startDate)
        .where('createdAt', '<', endDate)
        .orderBy('createdAt', 'desc');
    }
    //  If user is just ops, show them only their transactions.
    else {
      transactionsRef = db
        .collection(`Transactions`)
        .where('status', '==', status)
        .where('branch', '==', selectedBranch)
        .where('createdAt', '>', startDate)
        .where('createdAt', '<', endDate)
        .orderBy('createdAt', 'desc');
    }

    if (status === 'approved')
      transactionsRef = transactionsRef.where(
        'isEditedTransaction',
        '==',
        false
      );

    this.setState({ [`${status}PrevActive`]: false });

    const unsubscribePage = transactionsRef.onSnapshot(transactionsData => {
      const transactions = [];
      //  this.setState({ isLoading: false });
      transactionsData.forEach(transaction => {
        const formattedTransaction = {
          ...transaction.data(),
        };
        transactions.push(formattedTransaction);
      });
      this.setState({
        [`${status}Transactions`]: transactions,
      });
      this.closeChecksAlert();
    });

    if (status === 'pending') this.unsubscribePendingPage = unsubscribePage;
    else if (status === 'approved')
      this.unsubscribeApprovedPage = unsubscribePage;
    else if (status === 'rejected')
      this.unsubscribeRejectedPage = unsubscribePage;
    else if (status === 'modify') this.unsubscribeModifyPage = unsubscribePage;
  }

  showNotification(place) {
    if (!this.state[place]) {
      const x = [];
      x[place] = true;
      this.setState(x);
      setTimeout(() => {
        x[place] = false;
        this.setState(x);
      }, 6000);
    }
  }

  searchTransaction(searchText) {
    if (searchText) {
      this.filterTransactions('approved', searchText);
      this.filterTransactions('pending', searchText);
      this.filterTransactions('modify', searchText);
      this.filterTransactions('rejected', searchText);
    } else {
      this.getTransactions();
    }
  }

  filterTransactions(status, searchText) {
    let transactionsRef;
    const { userDetails } = this.props;
    let { selectedBranch } = this.state;
    if (!selectedBranch) selectedBranch = userDetails.branches[0];
    // If user is an admin, show them all transactions.
    if (userDetails.isAdmin) {
      transactionsRef = db
        .collection(`Transactions`)
        .where('status', '==', status)
        .where('transactionSerial', '==', Number(searchText))
        .orderBy('createdAt', 'desc');
    }
    //  If user is just ops, show them only their transactions.
    else {
      transactionsRef = db
        .collection(`Transactions`)
        .where('status', '==', status)
        .where('branch', '==', selectedBranch)
        .where('transactionSerial', '==', Number(searchText))
        .orderBy('createdAt', 'desc');
    }

    if (status === 'approved')
      transactionsRef = transactionsRef.where(
        'isEditedTransaction',
        '==',
        false
      );

    this.setState({ [`${status}PrevActive`]: false });

    const unsubscribePage = transactionsRef.onSnapshot(transactionsData => {
      const transactions = [];
      //  this.setState({ isLoading: false });
      transactionsData.forEach(transaction => {
        const formattedTransaction = {
          ...transaction.data(),
        };
        transactions.push(formattedTransaction);
      });
      // const fuse = new Fuse(transactions, options);
      this.setState({
        [`${status}Transactions`]: transactions,
      });
    });

    if (status === 'pending') this.unsubscribePendingPage = unsubscribePage;
    else if (status === 'approved')
      this.unsubscribeApprovedPage = unsubscribePage;
    else if (status === 'rejected')
      this.unsubscribeRejectedPage = unsubscribePage;
    else if (status === 'modify') this.unsubscribeModifyPage = unsubscribePage;
  }

  fromDateChange(fromDate) {
    this.setState({ fromDate });
  }

  toDateChange(toDate) {
    this.setState({ toDate });
  }

  // function that verifies if value contains only numbers
  verifyNumber(value, stateName) {
    if (stateName === 'searchText') {
      if (value === '' || value === null) {
        this.getTransactions();
      }
    }
    const numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'number':
        if (this.verifyNumber(event.target.value, stateName)) {
          this.setState(
            {
              [`${stateName}State`]: 'success',
              [stateName]: event.target.value,
            },
            this.calculateFields
          );
        } else if (event.target.value === '') {
          this.setState({
            [`${stateName}State`]: 'Invalid',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'Invalid',
          });
        }
        break;
      default:
        break;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  filterCheck(data) {
    let action = '';
    if (data !== '' && data !== null && data !== undefined) {
      action = true;
    } else {
      action = false;
    }
    return action;
  }

  getStatusTransactions(status) {
    const {
      isFilter,
      fromDate,
      toDate,
      transporterId,
      truckerId,
      isPodCollected,
      isPodSubmitted,
      selectedRiskStatus,
    } = this.state;
    // console.log('transporterId', transporterId);
    let startDate = '';
    let endDate = '';
    // To restrict the dates to be automatically taken
    if (fromDate === '') {
      startDate = null;
    } else {
      startDate = new Date(fromDate);
    }
    if (toDate === '') {
      endDate = null;
    } else {
      endDate = new Date(toDate);
    }
    try {
      if (status === 'pending') this.unsubscribePendingPage();
      else if (status === 'approved') this.unsubscribeApprovedPage();
      else if (status === 'rejected') this.unsubscribeRejectedPage();
      else if (status === 'modify') this.unsubscribeModifyPage();
    } catch {}

    let transactionsRef;
    const { userDetails } = this.props;
    let { selectedBranch } = this.state;
    if (!selectedBranch) selectedBranch = userDetails.branches[0];

    transactionsRef = db
      .collection(`Transactions`)
      .where('status', '==', status)
      .where('isEditedTransaction', '==', false)
      .orderBy('createdAt', 'desc')
      .limit(20 + 1); // Limit to 20 + 1 to see if next should be enabled or not, displaying only 20;
    if (isPodCollected) {
      transactionsRef = db
        .collection(`Transactions`)
        .where('status', '==', status)
        .where('isCollected', '==', true)
        .where('isEditedTransaction', '==', false)
        .orderBy('createdAt', 'desc')
        .limit(20 + 1);
    }
    if (isPodSubmitted) {
      transactionsRef = db
        .collection(`Transactions`)
        .where('status', '==', status)
        .where('isSubmitted', '==', true)
        .where('isEditedTransaction', '==', false)
        .orderBy('createdAt', 'desc')
        .limit(20 + 1);
    }
    if (isFilter) {
      const fromDateAction = this.filterCheck(startDate);
      const toDateAction = this.filterCheck(endDate);
      const transporterAction = this.filterCheck(transporterId);
      const truckerAction = this.filterCheck(truckerId);
      if (fromDateAction && toDateAction) {
        transactionsRef = transactionsRef
          .where('createdAt', '>', startDate)
          .where('createdAt', '<', endDate);
      }
      if (transporterAction) {
        transactionsRef = transactionsRef.where(
          'transporterId',
          '==',
          transporterId
        );
      }
      if (truckerAction) {
        transactionsRef = transactionsRef.where('truckerId', '==', truckerId);
      }

      if (
        selectedRiskStatus !== null &&
        selectedRiskStatus !== undefined &&
        selectedRiskStatus !== ''
      ) {
        if (selectedRiskStatus.hasOwnProperty('value')) {
          if (
            selectedRiskStatus.value !== null &&
            selectedRiskStatus.value !== ''
          ) {
            transactionsRef = transactionsRef.where(
              'riskStatus',
              '==',
              selectedRiskStatus.value
            );
          }
        }
      }
    }

    if (!userDetails.isAdmin) {
      transactionsRef = transactionsRef.where('branch', '==', selectedBranch);
    }

    this.setState({ [`${status}PrevActive`]: false });

    const unsubscribePage = transactionsRef.onSnapshot(transactionsData => {
      const transactions = [];
      //  this.setState({ isLoading: false });
      transactionsData.forEach(transaction => {
        const formattedTransaction = {
          ...transaction.data(),
        };
        transactions.push(formattedTransaction);
      });

      let nextCursor;
      if (transactionsData.docs.length > 20)
        nextCursor =
          transactionsData.docs[transactionsData.docs.length - 1 - 1];
      else nextCursor = transactionsData.docs[transactionsData.docs.length - 1];

      const currentCursor = transactionsData.docs[0];

      if (transactionsData.docs.length < 20 + 1)
        this.setState({ [`${status}NextActive`]: false });
      else this.setState({ [`${status}NextActive`]: true });

      if (transactions.length > 20) transactions.pop();

      this.setState({
        [`${status}Transactions`]: transactions,
        [`${status}NextCursor`]: nextCursor,
        [`${status}CurrentCursor`]: currentCursor,
        [`${status}InitialCursor`]: currentCursor,
        checksAlert: false,
      });
    });

    if (status === 'pending') this.unsubscribePendingPage = unsubscribePage;
    else if (status === 'approved')
      this.unsubscribeApprovedPage = unsubscribePage;
    else if (status === 'rejected')
      this.unsubscribeRejectedPage = unsubscribePage;
    else if (status === 'modify') this.unsubscribeModifyPage = unsubscribePage;
  }

  getModifyStatusTransactions(status) {
    const {
      isFilter,
      fromDate,
      toDate,
      transporterId,
      truckerId,
      selectedRiskStatus,
    } = this.state;
    let startDate = '';
    let endDate = '';
    // To restrict the dates to be automatically taken
    if (fromDate === '') {
      startDate = null;
    } else {
      startDate = new Date(fromDate);
    }
    if (toDate === '') {
      endDate = null;
    } else {
      endDate = new Date(toDate);
    }
    try {
      if (status === 'pending') this.unsubscribePendingPage();
      else if (status === 'approved') this.unsubscribeApprovedPage();
      else if (status === 'rejected') this.unsubscribeRejectedPage();
      else if (status === 'modify') this.unsubscribeModifyPage();
    } catch {}

    let transactionsRef;
    const { userDetails } = this.props;
    let { selectedBranch } = this.state;
    if (!selectedBranch) selectedBranch = userDetails.branches[0];

    transactionsRef = db
      .collection(`Transactions`)
      .where('status', '==', status)
      .orderBy('createdAt', 'desc')
      .limit(20 + 1); // Limit to 20 + 1 to see if next should be enabled or not, displaying only 20;

    if (isFilter) {
      const fromDateAction = this.filterCheck(startDate);
      const toDateAction = this.filterCheck(endDate);
      const transporterAction = this.filterCheck(transporterId);
      const truckerAction = this.filterCheck(truckerId);
      if (fromDateAction && toDateAction) {
        transactionsRef = transactionsRef
          .where('createdAt', '>', startDate)
          .where('createdAt', '<', endDate);
      }
      if (transporterAction) {
        transactionsRef = transactionsRef.where(
          'transporterId',
          '==',
          transporterId
        );
      }
      if (truckerAction) {
        transactionsRef = transactionsRef.where('truckerId', '==', truckerId);
      }

      if (
        selectedRiskStatus !== null &&
        selectedRiskStatus !== undefined &&
        selectedRiskStatus !== ''
      ) {
        if (selectedRiskStatus.hasOwnProperty('value')) {
          if (
            selectedRiskStatus.value !== null &&
            selectedRiskStatus.value !== ''
          ) {
            transactionsRef = transactionsRef.where(
              'riskStatus',
              '==',
              selectedRiskStatus.value
            );
          }
        }
      }
    }

    if (!userDetails.isAdmin) {
      transactionsRef = transactionsRef.where('branch', '==', selectedBranch);
    }

    this.setState({ [`${status}PrevActive`]: false });

    const unsubscribePage = transactionsRef.onSnapshot(transactionsData => {
      const transactions = [];
      //  this.setState({ isLoading: false });
      transactionsData.forEach(transaction => {
        const formattedTransaction = {
          ...transaction.data(),
        };
        transactions.push(formattedTransaction);
      });

      let nextCursor;
      if (transactionsData.docs.length > 20)
        nextCursor =
          transactionsData.docs[transactionsData.docs.length - 1 - 1];
      else nextCursor = transactionsData.docs[transactionsData.docs.length - 1];

      const currentCursor = transactionsData.docs[0];

      if (transactionsData.docs.length < 20 + 1)
        this.setState({ [`${status}NextActive`]: false });
      else this.setState({ [`${status}NextActive`]: true });

      if (transactions.length > 20) transactions.pop();

      this.setState({
        [`${status}Transactions`]: transactions,
        [`${status}NextCursor`]: nextCursor,
        [`${status}CurrentCursor`]: currentCursor,
        [`${status}InitialCursor`]: currentCursor,
        checksAlert: false,
      });
    });

    if (status === 'pending') this.unsubscribePendingPage = unsubscribePage;
    else if (status === 'approved')
      this.unsubscribeApprovedPage = unsubscribePage;
    else if (status === 'rejected')
      this.unsubscribeRejectedPage = unsubscribePage;
    else if (status === 'modify') this.unsubscribeModifyPage = unsubscribePage;
  }

  getTabs(approved, pending, modified, rejected) {
    const {
      // approvedTransactions,
      modifyTransactions,
      pendingTransactions,
      // rejectedTransactions,
    } = this.state;
    return (
      <CustomTabs
        headerColor="#fff"
        noCards
        tabs={[
          {
            tabName: `${approved}  ${approved === 'Approved' ? '' : ''}`,
            tabContent: this.switchTab('approved'),
          },
          {
            tabName: `${modified}  ${
              modifyTransactions.length >= 20
                ? '20+'
                : modifyTransactions.length
            }`,
            tabContent: this.switchTab('modify'),
          },
          {
            tabName: `${pending} ${
              pendingTransactions.length >= 20
                ? '20+'
                : pendingTransactions.length
            }`,
            tabContent: this.switchTab('pending'),
          },
          {
            tabName: `${rejected}  ${approved === 'Approved' ? '' : ''}`,
            tabContent: this.switchTab('rejected'),
          },
        ]}
      />
    );
  }

  handleBranchChange = event => {
    this.setState({ selectedBranch: event.target.value }, () =>
      this.getTransactions()
    );
    // console.log('Selected option', event.target.value);
  };

  showNext(status) {
    const {
      isFilter,
      fromDate,
      toDate,
      transporterId,
      truckerId,
      selectedRiskStatus,
    } = this.state;
    let startDate = new Date(fromDate);
    let endDate = new Date(toDate);
    if (fromDate === '') {
      startDate = null;
    } else {
      startDate = new Date(fromDate);
    }
    if (toDate === '') {
      endDate = null;
    } else {
      endDate = new Date(toDate);
    }
    try {
      if (status === 'pending') this.unsubscribePendingPage();
      else if (status === 'approved') this.unsubscribeApprovedPage();
      else if (status === 'rejected') this.unsubscribeRejectedPage();
      else if (status === 'modify') this.unsubscribeModifyPage();
    } catch {}
    const { userDetails } = this.props;
    const {
      approvedNextCursor,
      pendingNextCursor,
      modifyNextCursor,
      rejectedNextCursor,
    } = this.state;

    let { selectedBranch } = this.state;
    if (!selectedBranch) selectedBranch = userDetails.branches[0];

    let transactionsRef;
    let nextCursor;

    if (status === 'approved') nextCursor = approvedNextCursor;
    else if (status === 'modify') nextCursor = modifyNextCursor;
    else if (status === 'pending') nextCursor = pendingNextCursor;
    else if (status === 'rejected') nextCursor = rejectedNextCursor;

    transactionsRef = db
      .collection(`Transactions`)
      .where('status', '==', status)
      .where('isEditedTransaction', '==', false)
      .limit(20 + 1)
      .orderBy('createdAt', 'desc')
      .startAfter(nextCursor);

    if (isFilter) {
      const fromDateAction = this.filterCheck(startDate);
      const toDateAction = this.filterCheck(endDate);
      const transporterAction = this.filterCheck(transporterId);
      const truckerAction = this.filterCheck(truckerId);
      if (fromDateAction && toDateAction) {
        transactionsRef = transactionsRef
          .where('createdAt', '>', startDate)
          .where('createdAt', '<', endDate);
      }
      if (transporterAction) {
        transactionsRef = transactionsRef.where(
          'transporterId',
          '==',
          transporterId
        );
      }
      if (truckerAction) {
        transactionsRef = transactionsRef.where('truckerId', '==', truckerId);
      }

      // if (selectedRiskStatus !== null && selectedRiskStatus !== '') {
      //   transactionsRef = transactionsRef.where(
      //     'riskStatus',
      //     '==',
      //     selectedRiskStatus.value
      //   );
      // } else return;

      // {--------Rectified pagination issue here------}
      if (
        selectedRiskStatus !== null &&
        selectedRiskStatus !== undefined &&
        selectedRiskStatus !== ''
      ) {
        if (selectedRiskStatus.hasOwnProperty('value')) {
          if (
            selectedRiskStatus.value !== null &&
            selectedRiskStatus.value !== ''
          ) {
            transactionsRef = transactionsRef.where(
              'riskStatus',
              '==',
              selectedRiskStatus.value
            );
          }
        }
      }
    } else {
      // null //just a try
    }
    if (!userDetails.isAdmin) {
      transactionsRef = transactionsRef.where('branch', '==', selectedBranch);
    }

    transactionsRef.get().then(transactionsData => {
      const transactions = [];
      //  this.setState({ isLoading: false });
      transactionsData.forEach(transaction => {
        const formattedTransaction = {
          ...transaction.data(),
        };
        transactions.push(formattedTransaction);
      });

      if (transactionsData.docs.length > 0) {
        let newNextCursor;
        if (transactionsData.docs.length > 20)
          newNextCursor =
            transactionsData.docs[transactionsData.docs.length - 1 - 1];
        else
          newNextCursor =
            transactionsData.docs[transactionsData.docs.length - 1];
        const newCurrentCursor = transactionsData.docs[0];

        if (transactionsData.docs.length < 20 + 1)
          this.setState({ [`${status}NextActive`]: false });
        else this.setState({ [`${status}NextActive`]: true });

        if (transactions.length > 20) transactions.pop();

        this.setState({
          [`${status}PrevActive`]: true,
          [`${status}Transactions`]: transactions,
          [`${status}NextCursor`]: newNextCursor,
          [`${status}CurrentCursor`]: newCurrentCursor,
        });
      }
    });
  }

  showPrev(status) {
    const {
      isFilter,
      fromDate,
      toDate,
      transporterId,
      truckerId,
      selectedRiskStatus,
    } = this.state;
    let startDate = new Date(fromDate);
    let endDate = new Date(toDate);
    if (fromDate === '') {
      startDate = null;
    } else {
      startDate = new Date(fromDate);
    }
    if (toDate === '') {
      endDate = null;
    } else {
      endDate = new Date(toDate);
    }
    try {
      if (status === 'pending') this.unsubscribePendingPage();
      else if (status === 'approved') this.unsubscribeApprovedPage();
      else if (status === 'rejected') this.unsubscribeRejectedPage();
      else if (status === 'modify') this.unsubscribeModifyPage();
    } catch {}

    const { userDetails } = this.props;
    const {
      approvedCurrentCursor,
      pendingCurrentCursor,
      modifyCurrentCursor,
      rejectedCurrentCursor,
      approvedInitialCursor,
      pendingInitialCursor,
      modifyInitialCursor,
      rejectedInitialCursor,
    } = this.state;

    let { selectedBranch } = this.state;
    if (!selectedBranch) selectedBranch = userDetails.branches[0];

    let transactionsRef;
    let currentCursor;
    let initialCursor;

    if (status === 'approved') currentCursor = approvedCurrentCursor;
    else if (status === 'modify') currentCursor = modifyCurrentCursor;
    else if (status === 'pending') currentCursor = pendingCurrentCursor;
    else if (status === 'rejected') currentCursor = rejectedCurrentCursor;

    if (status === 'approved') initialCursor = approvedInitialCursor;
    else if (status === 'modify') initialCursor = modifyInitialCursor;
    else if (status === 'pending') initialCursor = pendingInitialCursor;
    else if (status === 'rejected') initialCursor = rejectedInitialCursor;

    transactionsRef = db
      .collection(`Transactions`)
      .where('status', '==', status)
      .where('isEditedTransaction', '==', false)
      .limit(20)
      .orderBy('createdAt', 'asc')
      .startAfter(currentCursor);

    if (isFilter) {
      const fromDateAction = this.filterCheck(startDate);
      const toDateAction = this.filterCheck(endDate);
      const transporterAction = this.filterCheck(transporterId);
      const truckerAction = this.filterCheck(truckerId);
      if (fromDateAction && toDateAction) {
        transactionsRef = transactionsRef
          .where('createdAt', '>', startDate)
          .where('createdAt', '<', endDate);
      }
      if (transporterAction) {
        transactionsRef = transactionsRef.where(
          'transporterId',
          '==',
          transporterId
        );
      }
      if (truckerAction) {
        transactionsRef = transactionsRef.where('truckerId', '==', truckerId);
      }

      // if (
      //   selectedRiskStatus.value !== null &&
      //   selectedRiskStatus.value !== ''
      // ) {
      //   transactionsRef = transactionsRef.where(
      //     'riskStatus',
      //     '==',
      //     selectedRiskStatus.value
      //   );
      // }
      if (
        selectedRiskStatus !== null &&
        selectedRiskStatus !== undefined &&
        selectedRiskStatus !== ''
      ) {
        if (selectedRiskStatus.hasOwnProperty('value')) {
          if (
            selectedRiskStatus.value !== null &&
            selectedRiskStatus.value !== ''
          ) {
            transactionsRef = transactionsRef.where(
              'riskStatus',
              '==',
              selectedRiskStatus.value
            );
          }
        }
      }
    } else {
      // null
    }
    if (!userDetails.isAdmin) {
      transactionsRef = transactionsRef.where('branch', '==', selectedBranch);
    }

    transactionsRef.get().then(transactionsData => {
      let transactions = [];
      //  this.setState({ isLoading: false });
      transactionsData.forEach(transaction => {
        const formattedTransaction = {
          ...transaction.data(),
        };
        transactions.push(formattedTransaction);
      });

      if (transactionsData.docs.length > 0) {
        const newCurrentCursor =
          transactionsData.docs[transactionsData.docs.length - 1];
        const newNextCursor = transactionsData.docs[0];

        if (
          newCurrentCursor.data().transactionId ===
          initialCursor.data().transactionId
        ) {
          this.getStatusTransactions(status);
        } else if (transactionsData.docs.length < 20) {
          this.getStatusTransactions(status);
        } else {
          transactions = transactions.reverse();
          this.setState({
            [`${status}NextActive`]: true,
            [`${status}Transactions`]: transactions,
            [`${status}NextCursor`]: newNextCursor,
            [`${status}CurrentCursor`]: newCurrentCursor,
          });
        }
      } else {
        this.getStatusTransactions(status);
      }
    });
  }

  switchTab(status) {
    if (status === 'approved') {
      const {
        approvedTransactions,
        approvedNextActive,
        approvedPrevActive,
      } = this.state;
      this.props.setApprovedTransactions(approvedTransactions);
      return (
        <div>
          {approvedTransactions.length !== 0 ? (
            <div>
              <Button
                disabled={!approvedPrevActive}
                color={approvedPrevActive ? 'info' : 'default'}
                size="sm"
                style={{ float: 'left', marginLeft: '10px' }}
                onClick={() => this.showPrev('approved')}
              >
                Prev
              </Button>
              <Button
                disabled={!approvedNextActive}
                color={approvedNextActive ? 'info' : 'default'}
                size="sm"
                style={{ float: 'right' }}
                onClick={() => this.showNext('approved')}
              >
                Next
              </Button>
              <TransactionsCard
                sales
                approved
                transactions={this.props.approvedTransactions}
                selectedBranch={this.state.selectedBranch}
                // maintainSelectedBranch={value =>
                //   this.setState({ selectedBranch: value })
                // }
              />
            </div>
          ) : (
            <h5>
              {this.state.searchText !== '' || this.state.isFilter
                ? 'Couldn`t find the transaction you were looking for, are you sure it exists and that you have access to it?'
                : 'No Transactions Available'}
            </h5>
          )}
        </div>
      );
    }
    if (status === 'modify') {
      const {
        modifyTransactions,
        modifyPrevActive,
        modifyNextActive,
      } = this.state;
      this.props.setModifyTransactions(modifyTransactions);
      return (
        <div>
          {modifyTransactions.length !== 0 ? (
            <div>
              <Button
                disabled={!modifyPrevActive}
                color={modifyPrevActive ? 'info' : 'default'}
                size="sm"
                style={{ float: 'left', marginLeft: '10px' }}
                onClick={() => this.showPrev('modify')}
              >
                Prev
              </Button>
              <Button
                disabled={!modifyNextActive}
                color={modifyNextActive ? 'info' : 'default'}
                size="sm"
                style={{ float: 'right' }}
                onClick={() => this.showNext('modify')}
              >
                Next
              </Button>

              <TransactionsCard
                sales
                modify
                transactions={this.props.modifyTransactions}
                maintainSelectedBranch={value =>
                  this.setState({ selectedBranch: value })
                }
              />
            </div>
          ) : (
            <h5>
              {this.state.searchText !== '' || this.state.isFilter
                ? 'Couldn`t find the transaction you were looking for, are you sure it exists and that you have access to it?'
                : 'No Transactions Available'}
            </h5>
          )}
        </div>
      );
    }
    if (status === 'pending') {
      const {
        pendingTransactions,
        pendingPrevActive,
        pendingNextActive,
      } = this.state;
      this.props.setPendingTransactions(pendingTransactions);
      return (
        <div>
          {pendingTransactions.length !== 0 ? (
            <div>
              <Button
                disabled={!pendingPrevActive}
                color={pendingPrevActive ? 'info' : 'default'}
                size="sm"
                style={{ float: 'left', marginLeft: '10px' }}
                onClick={() => this.showPrev('pending')}
              >
                Prev
              </Button>
              <Button
                disabled={!pendingNextActive}
                color={pendingNextActive ? 'info' : 'default'}
                size="sm"
                style={{ float: 'right' }}
                onClick={() => this.showNext('pending')}
              >
                Next
              </Button>
              <TransactionsCard
                sales
                pending
                transactions={this.props.pendingTransactions}
              />
            </div>
          ) : (
            <h5>
              {this.state.searchText !== '' || this.state.isFilter
                ? 'Couldn`t find the transaction you were looking for, are you sure it exists and that you have access to it?'
                : 'No Transactions Available'}
            </h5>
          )}
        </div>
      );
    }
    if (status === 'rejected') {
      const {
        rejectedTransactions,
        rejectedNextActive,
        rejectedPrevActive,
      } = this.state;
      this.props.setRejectedTransactions(rejectedTransactions);
      return (
        <div>
          {rejectedTransactions.length !== 0 ? (
            <div>
              <Button
                disabled={!rejectedPrevActive}
                color={rejectedPrevActive ? 'info' : 'default'}
                size="sm"
                style={{ float: 'left', marginLeft: '10px' }}
                onClick={() => this.showPrev('rejected')}
              >
                Prev
              </Button>
              <Button
                disabled={!rejectedNextActive}
                color={rejectedNextActive ? 'info' : 'default'}
                size="sm"
                style={{ float: 'right' }}
                onClick={() => this.showNext('rejected')}
              >
                Next
              </Button>

              <TransactionsCard
                sales
                transactions={this.props.rejectedTransactions}
              />
            </div>
          ) : (
            <h5>
              {this.state.searchText !== '' || this.state.isFilter
                ? 'Couldn`t find the transaction you were looking for, are you sure it exists and that you have access to it?'
                : 'No Transactions Available'}
            </h5>
          )}
        </div>
      );
    }

    return null;
  }

  selectBranch() {
    const { classes, userDetails } = this.props;
    const { selectedBranch } = this.state;
    return (
      <FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
          Select Branch
        </InputLabel>
        <Select
          MenuProps={{ className: classes.selectMenu }}
          classes={{ select: classes.select }}
          value={selectedBranch}
          onChange={this.handleBranchChange}
          inputProps={{ name: 'branch', id: 'simple-select' }}
        >
          <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
            Select Branch
          </MenuItem>
          {userDetails.branches.map((branch, i) => (
            <MenuItem
              key={branch}
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value={branch}
            >
              {branch}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  modalWindow() {
    const { classes } = this.props;
    const {
      fromDate,
      toDate,
      selectedTransporter,
      transporters,
      truckers,
      selectedTrucker,
      selectedRiskStatus,
      riskStatusOptions,
    } = this.state;
    return (
      <SweetAlert
        showCancel
        confirmBtnText="Apply"
        title=""
        onConfirm={this.confirmDates}
        onCancel={this.closeChecksAlert}
        confirmBtnCssClass={`${classes.button} ${classes.success}`}
        cancelBtnCssClass={`${classes.button} ${classes.warning}`}
        style={{ height: '70%', width: '580px' }}
      >
        <h3 style={{ marginBottom: '0px' }}>
          <span className={classes.rajdhaniFont}>Filter Transactions</span>
        </h3>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6} style={{ marginTop: '20px' }}>
            <InputLabel className={classes.label}>From Date</InputLabel>
            <br />
            <FormControl fullWidth>
              <Datetime
                onChange={this.fromDateChange}
                value={fromDate}
                dateFormat="DD-MM-YYYY"
                timeFormat={false}
                inputProps={{ placeholder: 'Select Date' }}
              />
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} style={{ marginTop: '20px' }}>
            <InputLabel className={classes.label}>To Date</InputLabel>
            <br />
            <FormControl fullWidth>
              <Datetime
                onChange={this.toDateChange}
                value={toDate}
                dateFormat="DD-MM-YYYY"
                timeFormat={false}
                inputProps={{ placeholder: 'Select Date' }}
              />
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} style={{ marginTop: '20px' }}>
            <InputLabel className={classes.labelText}>
              Select Transporter
            </InputLabel>
            <ReactSelect
              value={selectedTransporter}
              onChange={this.handleTransporter}
              options={transporters}
              placeholder="Select Transporter"
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} style={{ marginTop: '20px' }}>
            <InputLabel className={classes.labelText}>
              Select Trucker
            </InputLabel>
            <ReactSelect
              value={selectedTrucker}
              onChange={this.handleTrucker}
              options={truckers}
              placeholder="Select Trucker"
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} style={{ marginTop: '20px' }}>
            <InputLabel className={classes.labelText}>
              Transaction Risk
            </InputLabel>
            <ReactSelect
              value={selectedRiskStatus}
              onChange={this.handleRiskStatus}
              options={riskStatusOptions}
              placeholder="Select Risk Status"
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} style={{ marginTop: '20px' }} />
          <GridItem xs={12} sm={12} md={6} style={{ marginTop: '40px' }}>
            <FormControlLabel
              classes={{
                root: classes.checkboxLabelControl,
                label: classes.checkboxLabel,
              }}
              control={
                <Checkbox
                  onChange={() =>
                    this.handleToggle(
                      !this.state.isPodCollected,
                      'isPodCollected'
                    )
                  }
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  checked={this.state.isPodCollected}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{ checked: classes.checked }}
                />
              }
              label={<span>POD Collected</span>}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} style={{ marginTop: '40px' }}>
            <FormControlLabel
              classes={{
                root: classes.checkboxLabelControl,
                label: classes.checkboxLabel,
              }}
              control={
                <Checkbox
                  onChange={() =>
                    this.handleToggle(
                      !this.state.isPodSubmitted,
                      'isPodSubmitted'
                    )
                  }
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  checked={this.state.isPodSubmitted}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{ checked: classes.checked }}
                />
              }
              label={<span>POD Submitted</span>}
            />
          </GridItem>
        </GridContainer>
        <Snackbar
          place="tc"
          color="danger"
          icon={AddAlert}
          message="Select From and To date."
          open={this.state.tc}
          closeNotification={() => this.setState({ tc: false })}
          close
        />
        {this.state.isFilter && (
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6} textCenter>
              <Button
                color="danger"
                aria-label="edit"
                size="sm"
                style={{ float: 'center' }}
                // className={}
                onClick={() => this.resetFilter()}
              >
                Reset
              </Button>
            </GridItem>
          </GridContainer>
        )}
      </SweetAlert>
    );
  }

  handleSubmit(event) {
    const { searchText } = this.state;
    // console.log('SearchText', searchText);
    if (searchText) {
      this.filterTransactions('approved', searchText);
      this.filterTransactions('pending', searchText);
      this.filterTransactions('modify', searchText);
      this.filterTransactions('rejected', searchText);
    } else {
      this.getTransactions();
    }
    event.preventDefault();
  }

  render() {
    const {
      classes,
      isModifyPage,
      // transactions,
      userDetails,
      isDetailedPage,
    } = this.props;
    const { checksAlert, searchText, searchTextState, isFilter } = this.state;
    const searchButton = `${classes.top} ${classes.searchButton} ${classNames({
      [classes.searchRTL]: '',
    })}`;

    return (
      <div>
        {!isModifyPage ? (
          <GridContainer justify="center">
            {checksAlert && this.modalWindow()}
            {!isDetailedPage ? (
              <GridItem
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ padding: '0px' }}
              >
                <Card>
                  <CardHeader className={`${classes.cardHeader}`} color="info">
                    <GridContainer>
                      <GridItem xs={12} md={3} lg={2}>
                        <p
                          className={`${classes.cardHeader} ${classes.rajdhaniFont}`}
                          style={{ marginTop: '10px', fontSize: '16px' }}
                        >
                          Transactions
                        </p>
                      </GridItem>
                      <GridItem xs={12} md={7} lg={10}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <div style={{ marginLeft: '10px' }}>
                            <form onSubmit={event => this.handleSubmit(event)}>
                              <CustomInput
                                formControlProps={{
                                  className: `${classes.top} ${classes.search}`,
                                }}
                                inputProps={{
                                  placeholder: 'Search',
                                  inputProps: {
                                    'aria-label': 'Search',
                                    value: searchText,
                                    onChange: event =>
                                      this.change(
                                        event,
                                        'searchText',
                                        'number'
                                      ),
                                    className: classes.searchInput,
                                  },
                                }}
                                success={searchTextState === 'success'}
                                error={searchTextState === 'Invalid'}
                              />
                              {window.innerWidth < 600 ? (
                                <Button
                                  color="white"
                                  aria-label="edit"
                                  justIcon
                                  round
                                  className={searchButton}
                                  onClick={() =>
                                    this.searchTransaction(searchText)
                                  }
                                  style={{ marginTop: '50px' }}
                                >
                                  <Search
                                    className={`${classes.headerLinksSvg} ${classes.searchIcon}`}
                                  />
                                </Button>
                              ) : (
                                <Button
                                  color="white"
                                  aria-label="edit"
                                  justIcon
                                  round
                                  className={searchButton}
                                  onClick={() =>
                                    this.searchTransaction(searchText)
                                  }
                                  style={{ marginTop: '16px' }}
                                >
                                  <Search
                                    className={`${classes.headerLinksSvg} ${classes.searchIcon}`}
                                  />
                                </Button>
                              )}
                            </form>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'baseline',
                              marginLeft: '10px',
                            }}
                          >
                            <Button
                              color={isFilter ? 'success' : 'white'}
                              aria-label="edit"
                              justIcon
                              round
                              className={searchButton}
                              onClick={() => this.showChecksAlert()}
                              style={{ marginTop: '16px' }}
                            >
                              <i className="fa fa-filter" aria-hidden="true" />
                            </Button>

                            <TRansactionsListDownload
                              transporters={this.state.transporters}
                              truckers={this.state.truckers}
                              activeRole={this.props.activeRole}
                              riskStatusOptions={this.state.riskStatusOptions}
                            />
                          </div>
                        </div>
                      </GridItem>
                      {/* <GridItem xs={12} md={2} lg={1}>
                       
                      </GridItem> */}
                    </GridContainer>
                  </CardHeader>
                  <CardBody
                    className={`${classes.cardHeader} ${classes.textCenter}`}
                  >
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={1} />
                      <GridItem xs={12} sm={12} md={3}>
                        {userDetails.isAdmin ? null : this.selectBranch()}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={1} />
                    </GridContainer>
                    <div>
                      <Desktop>
                        {this.getTabs(
                          'Approved',
                          'Pending',
                          'Modified',
                          'Rejected'
                        )}
                      </Desktop>
                      <Mobile>{this.getTabs('Ap', 'Pe', 'Mo', 'Re')}</Mobile>
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
            ) : (
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <DetailedTransactions />
              </GridItem>
            )}
          </GridContainer>
        ) : (
          <ModifyTransaction />
        )}
      </div>
    );
  }
}

Transactions.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  isDetailedPage: state.transactions.isDetailedPage,
  isModifyPage: state.transactions.isModifyPage,
  transactions: state.transactions.transactions,
  approvedTransactions: state.transactions.approvedTransactions,
  pendingTransactions: state.transactions.pendingTransactions,
  modifyTransactions: state.transactions.modifyTransactions,
  rejectedTransactions: state.transactions.rejectedTransactions,
  activeRole: state.main.activeRole,
  userDetails: state.main.userDetails,
  uid: state.main.uid,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      switchActivePage,
      setTransactions,
      setApprovedTransactions,
      setModifyTransactions,
      setPendingTransactions,
      setRejectedTransactions,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(TransactionStyle)(Transactions));
