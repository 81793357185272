/* eslint-disable no-prototype-builtins */
/**
 * Global API Caller function
 * @function
 * @param {RequestBody} => {formData for The APi}
 */
import Cookies from 'universal-cookie';
import getToken from './getToken';

const cookies = new Cookies();

const ENV = process.env.REACT_APP_API_ENV;

let API_URL = 'https://api-dev.lobb.in';

// export const API_URL = 'https://api.lobb.in';
switch (ENV) {
  case 'production':
    API_URL = 'https://api.lobb.in';
    break;
  case 'staging':
    API_URL = 'https://api-dev.lobb.in';
    break;
  case 'dev':
    API_URL = 'https://api-beta.lobb.in';
    break;
  default:
    API_URL = 'https://api-dev.lobb.in';
}

let count = 1;
export default function apiCaller(
  endpoint,
  method = 'get',
  body,
  authorization = false,
  isForm = false
) {
  return new Promise((resolve, reject) => {
    let headers = {
      Accept: 'application/json, */*',
    };
    if (authorization) {
      headers = {
        ...headers,
        token: `${cookies.get('token') || window.token}`,
      };
    }
    let options = {
      headers,
      method,
    };

    const data = { ...body };

    if (method !== 'get' && !isForm) {
      options = {
        ...options,
        body: JSON.stringify(data),
      };
    }
    if (isForm) {
      const form = new FormData();
      Object.keys(body).forEach(key => {
        form.append(key, body[key]);
      });
      options = {
        ...options,
        body: form,
      };
    }

    return fetch(`${API_URL}/${endpoint}`, options)
      .then(
        response => {
          if (response.status === 401) {
            getToken(
              'matchmaking-api/get-jwt-token',
              'post',
              body,
              true,
              false,
              'matchMaking'
            )
              .then(tokenResponse => {
                if (count < 3) {
                  count += 1;
                  apiCaller(endpoint, method, body, authorization, isForm);
                }
              })
              .catch(err => {
                reject(response.status);
              });
          } else {
            resolve(response.json());
          }
        },
        err => {
          reject(err);
        }
      )
      .catch(error => {
        console.log('Error 2', error);
        // if(error.message === 'The')
        reject(error);
      });
  });
}
