import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import firebase from '../../../../config/config';
import apiCaller from '../../../../utils/apiCallerOutstanding';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    marginTop: '30px',
    textAlign: 'left',
    marginBottom: 10,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 2,
  },
  cardSection: {
    marginBottom: '10px',
  },
  sectionHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 0',
  },
  sectionContent: {
    padding: '0 10px',
  },
  commentBox: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: '10px',
  },
  commentAvatar: {
    display: 'inline-block',
    width: '36px',
    height: '36px',
    borderRadius: '50%',
    backgroundColor: '#C2185B',
    textAlign: 'center',
    flexShrink: 0,
    lineHeight: '36px',
    marginBottom: '20px',
  },
  commentText: {
    marginLeft: '10px',
    backgroundColor: '#EFF6FC',
    borderRadius: '4px',
    padding: '5px',
  },
  noComments: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '10px',
  },
});

export default function OutlinedCard({ transactionId, activeRole }) {
  const [commentsData, setCommentsData] = useState({});
  const [fetching, setFetching] = useState(false);
  const classes = useStyles();

  const roleMapping = {
    'branch-ops': 'BranchOps',
    'transaction-view': 'TransactionView',
    'finance-payable': 'FinancePayable',
    'finance-recievable': 'FinanceReceivable',
  };

  const getTruckerComments = async token => {
    setFetching(true);
    const role = roleMapping[activeRole] || activeRole;
    const currentActiveRole = `is${role.charAt(0).toUpperCase()}${role.slice(
      1
    )}`;
    const endPoint = `pipe/pod-remarks?role=${currentActiveRole}&trip_id=${transactionId}`;

    await apiCaller(endPoint, {}, `Bearer ${token}`, 'nh-base', 'get')
      .then(response => {
        setCommentsData(response?.data?.Data);
        setFetching(false);
      })
      .catch(error => setFetching(false));
  };

  useEffect(() => {
    const userDetails = firebase.auth().currentUser;
    const { Aa } = userDetails;
    getTruckerComments(Aa);
  }, []);

  const hasRelevantPodComments = commentsData?.isPod?.some(
    comment => comment.general_remarks
  );

  const renderComments = (comments, commentKey) => (
    <div>
      {comments
        .filter(commentDetail => commentDetail[commentKey])
        .reverse()
        .map((commentDetail, index) => (
          <div key={index} className={classes.commentBox}>
            <div className={classes.commentAvatar}>
              <Typography style={{ color: 'white', lineHeight: '36px' }}>
                {commentDetail?.name ? commentDetail?.name.charAt(0) : ''}
              </Typography>
            </div>
            <div className={classes.commentText}>
              <Typography className={classes.pos}>
                {commentDetail[commentKey]}
              </Typography>
              <Typography
                variant="caption"
                display="block"
                gutterBottom
                color="textSecondary"
              >
                {commentDetail?.name}
                {commentDetail?.name ? <>&nbsp; &nbsp; &nbsp;</> : null}
                {moment(commentDetail?.datetime).format('DD-MM-YYYY')}
                &nbsp;
                {moment(commentDetail?.datetime).format('hh:mm A')}
              </Typography>
            </div>
          </div>
        ))}
    </div>
  );

  return (
    <Card className={classes.root}>
      <CardHeader title="General Comments" />
      <Divider />
      {fetching ? (
        <CircularProgress size={20} />
      ) : (
        <CardContent>
          {hasRelevantPodComments && (
            <div className={classes.cardSection}>
              <div className={classes.sectionHeader}>
                <Typography>Comments From POD Team</Typography>
              </div>

              <div className={classes.sectionContent}>
                {renderComments(commentsData?.isPod, 'general_remarks')}
              </div>
            </div>
          )}

          {commentsData && !hasRelevantPodComments && (
            <div className={classes.noComments}>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                No Comments Available
              </Typography>
            </div>
          )}
        </CardContent>
      )}
    </Card>
  );
}
