/* eslint-disable react/jsx-no-bind */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Box from '@material-ui/core/Box';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import myFirebase from 'firebase';
import { Button } from '@material-ui/core';
import firebase from '../../../config/config';
import {
  deleteFileHandler,
  fileUploader,
  getSignedURL,
  getViewURL,
} from '../../../utils/fileUploadHandler';
import convertTimestampToDate from '../../../utils/convertTimestampToDate';

const useStyles = makeStyles({
  box: {
    // width: 400,
    // height: 200,
    // marginTop: 30,
    borderRadius: '5px',
    textAlign: 'left',
    border: '2px dashed',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    // padding: '10px',
  },
  viewButton: {
    background: '#52BE56',
    color: 'white',
    margin: '2px 20px 0px 0px',
    padding: '5px 42px 5px 10px',
  },
  nameTabs: {
    display: 'flex',
    flexDirection: 'column',
    // marginTop: '13px',
  },
  promptBox: {
    display: 'flex',
    // flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  alert: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    border: '2px dashed lightgray',
    borderRadius: '5px',
    margin: '14px 20px',
  },
  deleteModalButton: {
    width: '45%',
    background: '#52BE56',
    borderRadius: 0,
    color: 'white',
    margin: '5px',
  },
  cancelButton: {
    width: '45%',
    background: 'lightgray',
    borderRadius: 0,
    margin: '5px',
  },
});

const contentStyle = { width: '24%' };

const inlineCheckIconStyle = {
  marginLeft: '10px',
  color: '#52BE56',
};

const nameBoxStyles = {
  lineHeight: '10px',
  margin: '10px 0px 0px 20px',
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const paperStyles = { padding: 10, marginTop: 20 };
const AdhaarFileUpload = ({
  truckerId,
  activeRole,
  multiStep,
  aadharBackUpload,
}) => {
  const [user, setUser] = React.useState();
  const [adhaarData, setAdhaarData] = React.useState({ name: '', date: '' });

  const [url, setUrl] = useState('');
  const [success, setSuccess] = useState(false);
  const [deleted, setDeleted] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  // const { truckerId } = truckerData;
  const auditLog = firebase.functions().httpsCallable('utilities-auditLog');
  const classes = useStyles();

  const onDrop = async acceptedFiles => {
    setOpen(!open);
    // Do something with the files
    const UploadedFile = acceptedFiles[0];
    const body = {
      scope: 'trucker',
      type: 'signed_url',
      file_type: 'trucker-aadhaar-back',
      trucker_id: truckerId,
    };
    getSignedURL(body, UploadedFile)
      .then(response => {
        const { url: signedURL } = response?.data;
        fileUploader(signedURL, UploadedFile)
          .then(res => {
            if (res === 'success') {
              const viewURLBody = {
                scope: 'trucker',
                type: 'view',
                file_type: 'trucker-aadhaar-back',
                trucker_id: truckerId,
              };

              setTimeout(() => {
                getViewURL(viewURLBody)
                  .then(result => {
                    setUrl(result?.data?.url);
                    const formattedDate = convertTimestampToDate(
                      result?.data?.updated_at
                    );
                    setAdhaarData({
                      name: result?.data?.uploaded_by,
                      date: formattedDate,
                    });
                    aadharBackUpload(true);
                  })
                  .catch(e => setOpen(false));
              }, 4000);
            }
          })
          .catch(e => {
            setOpen(false);
            aadharBackUpload(false);
          });
      })
      .catch(e => setOpen(false));
  };
  // ------------------------------------------//
  async function DeleteAdhaarFile() {
    setOpen(true);
    const body = {
      scope: 'trucker',
      type: 'delete',
      file_type: 'trucker-aadhaar-back',
      trucker_id: truckerId,
    };
    try {
      await deleteFileHandler(body);

      setOpen(false);
      setDeleted(true);
      setUrl('');
      const auditBody = {
        // data: { ...transaction },
        collection: 'file uploads',
        adhaarFileDeletedBy: user.uid,
        type: 'Deleted Adhaar File in Truckers',
        message: `${user.email} in file uploads collection inside Truckersfile doc inside Adhaar collection with ${truckerId} Truckers ID deleted the file `,
        deletedAt: new Date(),
      };
      auditLog(auditBody);
    } catch (e) {
      console.log(e);
    }
  }
  function handleClose() {
    setDeleted(false);
    setSuccess(false);
  }

  const { getRootProps, getInputProps } = useDropzone({
    acceptedFiles: {
      'image/png': ['.png', '.jpeg', '.jpg'],
      'application/pdf': ['.pdf'],
    },
    onDrop,
  });
  const viewLr = () => {
    window.open(url);
  };

  async function getAdhaarBackData() {
    const viewURLBody = {
      scope: 'trucker',
      type: 'view',
      file_type: 'trucker-aadhaar-back',
      trucker_id: truckerId,
    };
    getViewURL(viewURLBody)
      .then(result => {
        setUrl(result?.data?.url);
        const formattedDate = convertTimestampToDate(result?.data?.updated_at);
        setAdhaarData({
          name: result?.data?.uploaded_by,
          date: formattedDate,
        });
      })
      .catch(e => console.log('error', e));
  }

  useEffect(() => {
    const userDetails = firebase.auth().currentUser;

    getAdhaarBackData();
    setUser(userDetails);
  }, []);

  return (
    <Paper elevation={1} style={paperStyles}>
      {activeRole == 'sales' ||
      activeRole == 'branch-ops' ||
      activeRole == 'traffic' ||
      multiStep ? (
        <>
          <div className={classes.headerWrapper}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <p
                style={{
                  // float: 'left',
                  margin: '14px 20px',
                  textAlign: 'left',
                  fontSize: '26px',
                  fontWeight: 400,
                }}
              >
                Aadhaar (Back)
              </p>
              {url !== '' ? (
                <CheckCircleIcon style={inlineCheckIconStyle} />
              ) : (
                ''
              )}
            </div>
            {url !== '' ? (
              <Box>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className={classes.viewButton}
                  onClick={viewLr}
                  startIcon={<VisibilityIcon style={{ marginRight: '23px' }} />}
                >
                  View
                </Button>
              </Box>
            ) : (
              ''
            )}
          </div>
          <Box>
            {url == '' || url == undefined ? (
              <Box className={classes.container}>
                {open ? (
                  <CircularProgress
                    style={{ color: '#FCD21F', margin: '5px' }}
                    size={30}
                    thickness={8}
                  />
                ) : (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        marginTop: 5,
                      }}
                    >
                      <CloudUploadIcon />
                      <p style={{ fontSize: '10px', color: 'gray' }}>
                        Drag and Drop or Click here to upload
                      </p>
                    </div>
                  </div>
                )}
              </Box>
            ) : (
              <div style={nameBoxStyles}>
                <p style={{ fontWeight: 'bold' }}>{adhaarData.name}</p>
                <p style={{ color: '#a9a9a9', fontSize: '10px' }}>
                  {adhaarData?.date}
                </p>
              </div>
            )}
          </Box>
        </>
      ) : activeRole == 'ops' || activeRole == 'finance' ? (
        <Box>
          {url == '' || url == undefined ? (
            <div>
              <p
                style={{
                  marginTop: '14px',
                  textAlign: 'left',
                  fontSize: '26px',
                  fontWeight: 400,
                }}
              >
                Aadhaar (Back)
              </p>
              <p>No File Uploaded </p>
            </div>
          ) : (
            <>
              <div className={classes.headerWrapper}>
                <div className={classes.nameTabs}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p
                      style={{
                        // float: 'left',
                        margin: '14px 20px',
                        // marginVertical: '10px',
                        textAlign: 'left',
                        fontSize: '26px',
                        fontWeight: 400,
                      }}
                    >
                      Aadhaar (Back)
                    </p>
                    {url !== '' ? (
                      <CheckCircleIcon style={inlineCheckIconStyle} />
                    ) : (
                      ''
                    )}{' '}
                    {open ? (
                      <CircularProgress
                        style={{ color: '#FCD21F', margin: '5px' }}
                        size={30}
                        thickness={8}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                  <div style={nameBoxStyles}>
                    <p style={{ fontWeight: 'bold' }}>{adhaarData.name}</p>
                    <p style={{ color: '#a9a9a9', fontSize: '10px' }}>
                      {adhaarData.date}
                    </p>
                  </div>
                </div>
                {url !== '' ? (
                  <Box>
                    <div className={classes.promptBox}>
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        className={classes.viewButton}
                        onClick={viewLr}
                        startIcon={
                          <VisibilityIcon style={{ marginRight: '23px' }} />
                        }
                      >
                        View
                      </Button>
                      {activeRole === 'ops' && (
                        <Popup
                          trigger={
                            <IconButton
                              color="primary"
                              aria-label="upload picture"
                              component="span"
                            >
                              <DeleteOutlineIcon
                                // onClick={() => setView(true)}
                                color="error"
                              />
                            </IconButton>
                          }
                          modal
                          {...{
                            contentStyle,
                          }}
                        >
                          {close => (
                            <div
                              style={{
                                padding: '10px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                              }}
                            >
                              <HighlightOffIcon
                                fontSize="large"
                                color="error"
                              />
                              <h4 style={{ fontWeight: 'bold' }}>
                                Are You Sure?
                              </h4>
                              <p>
                                If you proceed, you will not be able to recover
                                it
                              </p>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  width: '100%',
                                  justifyContent: 'center',
                                }}
                              >
                                <Button
                                  onClick={close}
                                  className={classes.cancelButton}
                                >
                                  No
                                </Button>
                                <Button
                                  onClick={DeleteAdhaarFile}
                                  className={classes.deleteModalButton}
                                >
                                  Yes
                                </Button>
                              </div>
                            </div>
                          )}
                        </Popup>
                      )}
                    </div>
                  </Box>
                ) : (
                  ''
                )}
              </div>
            </>
          )}
        </Box>
      ) : (
        ''
      )}
      <Snackbar open={deleted} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Deleted File!
        </Alert>
      </Snackbar>
      <Snackbar open={success} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Uploaded File!
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default AdhaarFileUpload;
