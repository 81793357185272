/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDropzone } from 'react-dropzone';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Popup from 'reactjs-popup';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import moment from 'moment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import myFirebase from '../../../../../config/config';
import FeatureFlagEmptyState from '../../../../Components/FeatureFlagEmptyState';
import {
  deleteFileHandler,
  fileUploader,
  getSignedURL,
  getViewURL,
} from '../../../../../utils/fileUploadHandler';
import convertTimestampToDate from '../../../../../utils/convertTimestampToDate';

const useStyles = makeStyles({
  root: {
    marginBottom: '20px',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',

    borderRadius: '5px',
    margin: '14px 20px',
    width: '100%',
  },
  containerDashed: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    border: '2px dashed lightgray',
    borderRadius: '5px',

    width: '100%',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  form: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  input: { marginRight: '20px' },
  header: { textAlign: 'left' },
  ratingCardContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  ViewButtonBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  viewButton: {
    background: '#52BE56',
    color: 'white',
    padding: '5px 42px 5px 10px',
  },
  deleteModalButton: {
    width: '45%',
    background: '#52BE56',
    borderRadius: 0,
    color: 'white',
    margin: '5px',
  },
  cancelButton: {
    width: '45%',
    background: 'lightgray',
    borderRadius: 0,
    margin: '5px',
  },
});
const nameBoxStyles = {
  lineHeight: '10px',
  marginTop: '10px',
};
const contentStyle = { width: '300px' };
const PODAcknowledgementSubmittedFileUploader = ({
  activeRole,
  currentTransactionData,
  featureFlagFiles,
}) => {
  const classes = useStyles();
  const db = myFirebase.firestore();
  const [url, setUrl] = React.useState('');
  const [uploading, setUploading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [podMetaData, setPODMetaData] = useState({
    name: '',
    time: '',
  });
  const auditLog = myFirebase.functions().httpsCallable('utilities-auditLog');

  const handleUploadFileToS3 = file => {
    const body = {
      scope: 'trip',
      type: 'signed_url',
      file_type: 'transaction-pod-acknowledgement-sub',
      transaction_id: currentTransactionData?.transactionId,
    };
    getSignedURL(body, file)
      .then(response => {
        const { url: signedURL } = response?.data;
        fileUploader(signedURL, file)
          .then(res => {
            if (res === 'success') {
              const viewURLBody = {
                scope: 'trip',
                type: 'view',
                file_type: 'transaction-pod-acknowledgement-sub',
                transaction_id: currentTransactionData?.transactionId,
              };

              setTimeout(() => {
                getViewURL(viewURLBody)
                  .then(result => {
                    setUrl(result?.data?.url);
                    const formattedDate = convertTimestampToDate(
                      result?.data?.updated_at
                    );

                    setPODMetaData({
                      name: result?.data?.uploaded_by,
                      time: formattedDate,
                    });
                    setUploading(false);
                  })
                  .catch(e => {
                    setUploading(false);
                  });
              }, 4000);
            }
          })
          .catch(e => {
            setUploading(false);
          });
      })
      .catch(e => {
        setUploading(false);
      });
  };

  const handlePODSubmitted = async file => {
    // const UploadedFile = acceptedFiles[0];
    if (featureFlagFiles === 2) {
      handleUploadFileToS3(file);
    } else if (featureFlagFiles === 0) {
      const user = myFirebase.auth().currentUser;
      const podRef = await db.collection('Transactions');
      const storageRef = myFirebase
        .storage()
        .ref(
          `PODAcknowledgementSubmitted/${currentTransactionData?.transactionId}`
        );
      const task = storageRef.put(file);
      task.on(
        'state_changed',
        snapshot => {},
        error => {
          setUploading(false);
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          task.snapshot.ref
            .getDownloadURL()
            .then(downloadURL => {
              podRef.doc(currentTransactionData?.transactionId).set(
                {
                  podAcknowledgementSubmittedByName: user.displayName,
                  podAcknowledgementSubmittedByUId: user.uid,
                  podAcknowledgementSubmittedTimestamp: new Date(),
                  podAcknowledgementSubmittedUrl: downloadURL,
                },
                { merge: true }
              );
              setUrl(downloadURL);
              setPODMetaData({
                name: user.displayName,
                time: new Date(),
              });

              const auditBody = {
                collection: 'Transactions',
                podAcknowledgementSubmittedByName: user.uid,
                type: 'POC acknowledgement Submitted By',
                message: `POD acknowledgement Submitted  Uploaded by ${user.name} for ${currentTransactionData?.transactionSerial} transaction`,
                uploadedAt: moment().format('llll'),
              };
              auditLog(auditBody);
              setUploading(false);
            })
            .catch(error => setUploading(false));
        }
      );
    }
  };

  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    setUploading(true);
    handlePODSubmitted(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    acceptedFiles: {
      'image/png': ['.png', '.jpeg', '.jpg'],
      'application/pdf': ['.pdf'],
    },
  });

  async function deletFileFromS3() {
    const user = myFirebase.auth().currentUser;
    setDeleting(true);
    const body = {
      scope: 'trip',
      type: 'delete',
      file_type: 'transaction-pod-acknowledgement-sub',
      transaction_id: currentTransactionData?.transactionId,
    };
    try {
      await deleteFileHandler(body);

      setDeleting(false);

      setUrl('');
      const auditBody = {
        collection: 'Transactions',
        podAcknowledgementSubmittedDeletedBy: user.uid,
        type: 'Deleted POD acknowledgment Submitted',
        message: `${user.email} in ${currentTransactionData?.transactionSerial} transaction removed POD acknowledgemnet Submitted File `,
        deletedAt: moment().format('llll'),
      };
      auditLog(auditBody);
    } catch (e) {
      console.log(e);
    }
  }
  async function deleteFIleUploaded() {
    if (featureFlagFiles === 2) {
      deletFileFromS3();
    } else if (featureFlagFiles === 0) {
      const user = myFirebase.auth().currentUser;
      setDeleting(true);
      const storageRef = myFirebase
        .storage()
        .ref(`PODAcknowledgementSubmitted`)
        .child(currentTransactionData?.transactionId);
      storageRef.delete().then(() => {
        const podRef = db.collection('Transactions');
        podRef.doc(currentTransactionData?.transactionId).set(
          {
            podAcknowledgementSubmittedByName: '',
            podAcknowledgementSubmittedByUId: '',
            podAcknowledgementSubmittedTimestamp: '',
            podAcknowledgementSubmittedUrl: '',
          },
          { merge: true }
        );
        const auditBody = {
          collection: 'Transactions',
          podAcknowledgementSubmittedDeletedBy: user.uid,
          type: 'Deleted POD acknowledgment Submitted',
          message: `${user.email} in ${currentTransactionData?.transactionSerial} transaction removed POD acknowledgemnet Submitted File `,
          deletedAt: moment().format('llll'),
        };
        auditLog(auditBody);
        setDeleting(false);

        setUrl('');
      });
    }
  }
  const viewFIleUploaded = () => {
    window.open(url);
  };

  function getPODAcknowledgementURL() {
    const viewURLBody = {
      scope: 'trip',
      type: 'view',
      file_type: 'transaction-pod-acknowledgement-sub',
      transaction_id: currentTransactionData?.transactionId,
    };

    getViewURL(viewURLBody).then(result => {
      setUrl(result?.data?.url);
      const formattedDate = result?.data?.updated_at
        ? convertTimestampToDate(result?.data?.updated_at)
        : '';

      setPODMetaData({
        name: result?.data?.uploaded_by,
        time: formattedDate,
      });
    });
  }

  useEffect(() => {
    if (featureFlagFiles === 2) {
      getPODAcknowledgementURL();
    } else if (featureFlagFiles === 0) {
      setUrl(currentTransactionData?.podAcknowledgementSubmittedUrl);
      const formattedDate = convertTimestampToDate(
        currentTransactionData?.podAcknowledgementSubmittedTimestamp
      );

      setPODMetaData({
        name: currentTransactionData?.podAcknowledgementSubmittedByName || '',
        time: formattedDate || '',
      });
    }
  }, [featureFlagFiles]);
  return (
    <Box style={{ width: '100%', padding: '30px' }}>
      {featureFlagFiles === 0 || featureFlagFiles === 2 ? (
        <>
          {url ? (
            <div>
              <div className={classes.ViewButtonBox}>
                <div style={nameBoxStyles}>
                  <p style={{ fontWeight: 'bold' }}>{podMetaData?.name}</p>
                  <p style={{ color: '#a9a9a9', fontSize: '12px' }}>
                  {podMetaData.time}
                  </p>
                </div>
                <div>
                  <Button
                    variant="contained"
                    color="success"
                    size="small"
                    className={classes.viewButton}
                    onClick={viewFIleUploaded}
                    startIcon={
                      <VisibilityIcon style={{ marginRight: '23px' }} />
                    }
                  >
                    View
                  </Button>
                  {deleting ? (
                    <CircularProgress size={20} />
                  ) : activeRole === 'ops' ? (
                    <Popup
                      trigger={
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <DeleteOutlineIcon color="error" />
                        </IconButton>
                      }
                      {...{
                        contentStyle,
                      }}
                      modal
                    >
                      {close => (
                        <div
                          style={{
                            padding: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          <HighlightOffIcon fontSize="large" color="error" />
                          <h4 style={{ fontWeight: 'bold' }}>Are You Sure?</h4>
                          <p>
                            If you proceed, you will not be able to recover it
                          </p>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              width: '100%',
                            }}
                          >
                            <Button
                              onClick={close}
                              className={classes.cancelButton}
                            >
                              No
                            </Button>
                            <Button
                              onClick={deleteFIleUploaded}
                              className={classes.deleteModalButton}
                            >
                              Yes
                            </Button>
                          </div>
                        </div>
                      )}
                    </Popup>
                  ) : null}
                </div>
              </div>
            </div>
          ) : (
            <>
              {activeRole === 'ops' || activeRole === 'finance' ? (
                <div style={{ textAlign: 'left' }}>
                  <p
                    style={{
                      textAlign: 'left',
                    }}
                  >
                    No Driving License Uploaded
                  </p>
                </div>
              ) : (
                <Box className={classes.containerDashed}>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        marginTop: 5,
                      }}
                    >
                      {uploading ? (
                        <CircularProgress size={20} />
                      ) : (
                        <>
                          <CloudUploadIcon />
                          <p style={{ fontSize: '10px', color: 'gray' }}>
                            Drag and Drop or Click here to upload
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </Box>
              )}
            </>
          )}{' '}
        </>
      ) : featureFlagFiles === 1 ? (
        <FeatureFlagEmptyState color="black" />
      ) : null}
    </Box>
  );
};

export default PODAcknowledgementSubmittedFileUploader;
