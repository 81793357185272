import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Divider, Grid, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import AdhaarBackFileUploader from './AdhaarBackFileUpload';
import PANFileUpload from './PanFileUpload';
import AdhaarFileUpload from './AdhaarFileUpload';
import TruckFilesUploader from '../TruckFiles/TruckFilesUploader';
import TruckerTdsCreate from './TruckerTdsCreate';
import myFirebase from '../../../config/config';

export default function MaxWidthDialog({
  truckerid,
  created,
  trucks,
  userDetails,
  activeRole,
  clearform,
  featureFlagFiles,
}) {
  const db = myFirebase.firestore();
  const [open, setOpen] = React.useState(created);
  const [rcFileUploaded, setRCFileUploaded] = React.useState(null);
  const [panFileUploaded, setPanFileUploaded] = React.useState(null);
  const [aadharFrontUpload, setAadharFrontUpload] = React.useState(null);
  const [aadharBackUpload, setAadharBackUpload] = React.useState(null);

  const allFilesUploaded =
    rcFileUploaded && panFileUploaded && aadharFrontUpload && aadharBackUpload;
  const formDisabled = !allFilesUploaded;

  const updateTruckerLifeCycle = async id => {
    const truckerRef = await db.collection('Truckers').doc(truckerid);
    truckerRef
      .update({
        status: 'rcPending',
      })
      .catch(error => {
        // The document probably doesn't exist.
        console.error('Error updating document: ', error);
      });
  };
  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      // Handle close logic here if needed
      if (!rcFileUploaded) {
        updateTruckerLifeCycle(truckerid);
      }
      setOpen(false);
      clearform();
    }
  };
  return (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        disableEscapeKeyDown
        aria-labelledby="max-width-dialog-title"
      >
        <div
          style={{
            padding: '10px',
            display: 'flex',
            alignItems: 'center',
            gap: 10,
          }}
        >
          <DialogTitle id="max-width-dialog-title">
            <Alert variant="filled" severity="info">
              Please Note, PAN, Aadhaar (F & B) and Truck RC upload is
              mandatory.
            </Alert>
          </DialogTitle>
        </div>

        <DialogContent>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
              <PANFileUpload
                truckerId={truckerid}
                multiStep
                featureFlagFiles={featureFlagFiles}
                panFileUploaded={val => setPanFileUploaded(val)}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <AdhaarFileUpload
                truckerId={truckerid}
                multiStep
                featureFlagFiles={featureFlagFiles}
                aadharFrontUpload={val => setAadharFrontUpload(val)}
              />
            </Grid>
            {featureFlagFiles !== 1 && featureFlagFiles !== 0 && (
              <Grid item xs={12} md={6}>
                <AdhaarBackFileUploader
                  truckerId={truckerid}
                  multiStep
                  aadharBackUpload={val => setAadharBackUpload(val)}
                />
              </Grid>
            )}

            <Grid item xs={12} md={6}>
              <TruckerTdsCreate
                truckerId={truckerid}
                multiStep
                featureFlagFiles={featureFlagFiles}
                activeRole={activeRole}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>

          <Grid container spacing={5}>
            <Grid item xs={12} md={12}>
              {trucks.map(truck => (
                <Grid container spacing={5}>
                  <Grid item xs={12} md={12}>
                    <Typography variant="body1" gutterBottom>
                      {truck.truckNumber}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TruckFilesUploader
                      userDetails={userDetails}
                      type="RC"
                      truckNumber={truck?.truckNumber}
                      truckerId={truckerid}
                      rcFileUploaded={val => setRCFileUploaded(val)}
                      featureFlagFiles={featureFlagFiles}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TruckFilesUploader
                      userDetails={userDetails}
                      type="Fitness Certificate"
                      truckNumber={truck?.truckNumber}
                      truckerId={truckerid}
                      featureFlagFiles={featureFlagFiles}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TruckFilesUploader
                      userDetails={userDetails}
                      type="Insurance"
                      truckNumber={truck?.truckNumber}
                      truckerId={truckerid}
                      featureFlagFiles={featureFlagFiles}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={formDisabled} color="primary">
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
