import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';

const FeatureFlagToastAlert = () => {
  const [state, setState] = React.useState({
    open: true,
    vertical: 'top',
    horizontal: 'center',
  });
  const { vertical, horizontal, open } = state;
  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        // onClose={handleClose}
        key={vertical + horizontal}
      >
        <Alert severity="error">
          Feature Unavailable: We apologize for the inconvenience, but this
          feature is currently disabled.
          <br /> Please try again in a few hours.
        </Alert>
      </Snackbar>
    </div>
  );
};

export default FeatureFlagToastAlert;
