import { Typography } from '@material-ui/core';
import React from 'react';

const TrucksOwned = ({ trucks }) => (
  <div
    style={{
      padding: '20px 10px',
      borderBottom: '1px solid lightgray',
    }}
  >
    <Typography variant="body2" style={{ fontWeight: 'bold' }} gutterBottom>
      Trucks Owned
    </Typography>
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
    >
      {trucks?.map(truck => (
        <span
          style={{
            background: '#F8E3DA',
            color: '#85462B',
            borderRadius: '5px',
            fontWeight: 'bold',
            padding: '2px 5px',
            textTransform: 'uppercase',
            fontSize: '12px',
            marginRight: '10px',
            marginBottom: '10px',
          }}
        >
          {truck}
        </span>
      ))}
    </div>
  </div>
);

export default TrucksOwned;
