/* eslint-disable prefer-const */
/* eslint-disable vars-on-top */
/* eslint-disable no-shadow */
/* eslint-disable no-undef */
/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable no-var */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable guard-for-in */
import React, { useEffect, useState, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Typography } from '@material-ui/core';
import ReactSelect from 'react-select';
import Grid from '@material-ui/core/Grid';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Button from '@material-ui/core/Button';
import CachedIcon from '@material-ui/icons/Cached';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import moment from 'moment';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MultiSelectDropdown from './MultiSelectDropdown';
import ExcelIcon from './excelImage.svg';
import firebase from '../../../config/config';
import apiCaller from '../../../utils/apiCallerOutstanding';

const style = {
  control: base => ({
    ...base,
    border: 0,
    borderBottom: '1px solid ',
    borderRadius: 0,
    // This line disable the blue border
    boxShadow: 'none',
  }),
};
const shifter = {
  position: 'relative',
  padding: '15px',
  paddingLeft: '30px',
  paddingRight: '30px',
  border: 'solid black 2px',
  // borderRadius: '7px',
  backgroundColor: 'white',
};
const avatarContainer = {
  display: 'flex',
  alignItems: 'center',
  // background: 'white',
  padding: '2px 10px',
  // borderRadius: '30px 30px 0px 0px',
};
const avatar = {
  background: '#163BAC',
  padding: '12px',
  borderRadius: '50%',
  margin: '10px',
};

var branchesAssigned = [];
var branchesSelected = [];
// var TotalBalanceData = [];
var offset = 0;
var limit = 10;
var transporterSelected = [];
var fileName = '';
var poll = true;
const OutstandingReportsTotalBalance = ({ transporterList }) => {
  const db = firebase.firestore();
  const childRef = useRef(null);

  const [lastRefreshTime, setLastRefreshTime] = useState(
    moment().format('MMMM Do YYYY, h:mm:ss a')
  );
  const [fetching, setFetching] = useState(false);
  const [token, setToken] = useState('');
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [downloadDisabled, setDownloadDisabled] = useState(false);
  const [filterByTransporter, setFilterByTransporter] = useState([]);
  const [totalBalanceData, setTotalBalance] = useState([]);
  const [polling, setpolling] = useState(false);
  const [branchesOptions, setBranchesOptions] = useState([]);
  const [nextDisabled, setNextDisabled] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  // const [offset, setOffset] = useState(0);
  const useStyles = makeStyles({
    tableContainer: {
      minHeight: 700,
    },
    table: {
      minWidth: 700,
      width: '100%',
    },
  });
  const classes = useStyles();

  const StyledTableCell = withStyles(theme => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles(theme => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: 'white',
      },
    },
  }))(TableRow);

  function createData(
    branch,
    transporter_name,
    transaction_serial,
    truck_no,
    loading_date,
    age,
    amount_due
  ) {
    return {
      branch,
      transporter_name,
      transaction_serial,
      truck_no,
      loading_date,
      age,
      amount_due,
    };
  }
  const toIndianCurrency = num => {
    if (num) {
      const curr = num.toLocaleString('en-IN', {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
      return `₹ ${curr}`;
    }
    return 0;
  };

  const rows = totalBalanceData?.map((item, index) =>
    createData(
      // index,
      item?.branch,
      item?.transporter_name,
      item?.trip_number,
      item?.truck_number,
      item?.loading_date,
      item?.age,
      toIndianCurrency(item?.outstanding)
    )
  );
  async function getTotalBalance(branch, token) {
    setFetching(true);
    const ENDPOINT = 'total-outstanding-by-trip';
    const data = {
      transporter_id:
        transporterSelected.length == 0 ? [] : transporterSelected[0],
      filter_branch:
        branch.length > 0
          ? branch
          : branchesOptions?.map(branch => branch.value),
      limit,
      offset,
    };
    try {
      const response = await apiCaller(ENDPOINT, data, `Bearer ${token}`);

      const TotalBalance = response.data.data;
      if (TotalBalance.length < 10) {
        setNextDisabled(true);
      } else {
        setNextDisabled(false);
      }
      setTotalBalance(TotalBalance);

      setFetching(false);
      setDownloadDisabled(false);
    } catch (error) {
      setDownloadDisabled(true);
      setOpen(true);
      setFetching(false);
    }
  }
  async function getUserDetails(uid, token) {
    const podRef = await db.collection('Users').doc(uid);
    podRef
      .get()
      .then(async doc => {
        if (doc.exists) {
          const data = doc.data();
          const { branches } = data;
          for (const i in branches) {
            branchesAssigned.push({ value: branches[i], label: branches[i] });
          }

          setBranchesOptions(branchesAssigned);

          await getTotalBalance(branches, token);
        }
      })
      .catch(error => error);
  }
  const handleTransporterChange = value => {
    setTotalBalance([]);
    transporterSelected = [];
    offset = 0;
    if (value === null && branchesSelected.length == 0) {
      setFilterByTransporter(value);
      getTotalBalance(branchesAssigned, token);
    } else if (value === null && branchesSelected.length > 0) {
      setFilterByTransporter(value);
      getTotalBalance(branchesSelected, token);
    } else if (branchesSelected.length > 0) {
      setFilterByTransporter(value);
      transporterSelected.push(value.value);
      getTotalBalance(branchesSelected, token);
    } else {
      setFilterByTransporter(value);
      transporterSelected.push(value.value);
      getTotalBalance(branchesAssigned, token);
    }
  };

  const handleRefresh = () => {
    offset = 0;
    transporterSelected = [];
    const branches = branchesOptions?.map(branch => branch.value);

    setTotalBalance([]);
    childRef.current.resetOptions();
    setFilterByTransporter([]);
    setLastRefreshTime(moment().format('MMMM Do YYYY, h:mm:ss a'));

    getTotalBalance(branches, token);
  };
  const handlePrevious = () => {
    offset -= 10;
    if (branchesSelected.length == 0) {
      getTotalBalance(branchesAssigned, token);
    } else getTotalBalance(branchesSelected, token);
  };
  const handleNext = () => {
    offset += 10;
    if (branchesSelected.length == 0) {
      getTotalBalance(branchesAssigned, token);
    } else getTotalBalance(branchesSelected, token);
  };
  const TableHeaders = [
    // 'Sl. No',
    'Transaction Number',
    'Truck Number',
    'Transporter',
    'Branch',
    'Loading Date',
    'Age',
    'Amount',
  ];

  const pollExcelDownload = () => {
    setTimeout(() => {
      generateExcelFile();
    }, 3000);
  };
  const handleDownload = (url, fileName) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;

    document.body.appendChild(link);
    link.click();
    setpolling(false);
  };
  const generateExcelFile = async () => {
    setpolling(true);
    if (!fileName) {
      const newDate = moment().format();
      fileName = `Total-balance-${newDate}.xlsx`;
    }

    const ENDPOINT = 'get-outstanding-report-excel';
    const data = {
      file_name: fileName,
      is_polling: poll ? 0 : 1,
      outstanding_type: 'outstandingByTripSerial',
      transporter_id:
        transporterSelected.length == 0 ? [] : transporterSelected[0],
      filter_branch:
        branchesSelected.length > 0 ? branchesSelected : branchesAssigned,
    };
    await apiCaller(ENDPOINT, data, `Bearer ${token}`).then(response => {
      const { url } = response.data;
      if (!url) {
        poll = false;
        pollExcelDownload();
      } else {
        const { url } = response.data;
        poll = true;
        fileName = '';
        const isWindowOpenSupported = window.open('', '_blank');

        if (isWindowOpenSupported) {
          // Popup window is supported, open the URL in a new window
          window.open(url, '_blank');
          setpolling(false);
        } else {
          // Popup window is disabled, trigger download programmatically
          handleDownload(url, fileName);
        }
      }
    });
  };

  const handleOptionsSelect = value => {
    branchesSelected = [];
    offset = 0;
    for (var i in value) {
      branchesSelected.push(value[i]);
    }

    getTotalBalance(branchesSelected, token, transporterSelected);
  };

  useEffect(() => {
    const userDetails = firebase.auth().currentUser;
    const { uid, Aa } = userDetails;
    // setUid(uid);
    setToken(Aa);
    getUserDetails(uid, Aa);
  }, []);
  return (
    <div>
      <fieldset style={shifter}>
        <Accordion elevation={0} expanded={isOpen}>
          <AccordionSummary
            expandIcon={
              <IconButton
                style={{ background: '#fcd21f' }}
                onClick={() => setIsOpen(!isOpen)}
                aria-label="delete"
              >
                <ExpandMoreIcon />
              </IconButton>
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <legend>
              <div style={avatarContainer}>
                <div style={avatar}>
                  <AccountBalanceIcon style={{ color: 'white' }} />
                </div>
                <Typography variant="h6" gutterBottom>
                  Total balance
                </Typography>
              </div>
            </legend>
          </AccordionSummary>
          <AccordionDetails>
            <div className="excelBar">
              <div style={{ display: ' flex', alignItems: 'center' }}>
                <Button
                  color="primary"
                  startIcon={<CachedIcon />}
                  style={{ textTransform: 'capitalize' }}
                  onClick={handleRefresh}
                >
                  refresh
                </Button>
                <Typography
                  variant="caption"
                  display="block"
                  style={{ color: 'lightgray' }}
                >
                  Last refresh at {lastRefreshTime}
                </Typography>
              </div>
              {polling ? (
                <CircularProgress />
              ) : (
                <Button
                  color="primary"
                  startIcon={<img src={ExcelIcon} alt="icon" />}
                  disabled={downloadDisabled}
                  style={{
                    textTransform: 'capitalize',
                    textDecoration: 'underline',
                  }}
                  onClick={generateExcelFile}
                >
                  Download Excel
                </Button>
              )}
            </div>
            <div style={{ width: '100%' }}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs={4} md={6} lg={6}>
                      <div
                        style={{
                          display: ' flex',
                          alignItems: 'center',
                        }}
                      >
                        <Button
                          color="primary"
                          startIcon={<CachedIcon />}
                          style={{ textTransform: 'capitalize' }}
                          onClick={handleRefresh}
                        >
                          refresh
                        </Button>
                        <Typography
                          variant="caption"
                          display="block"
                          style={{ color: 'lightgray', marginLeft: '10px' }}
                        >
                          Last refresh at {lastRefreshTime}
                        </Typography>
                      </div>
                    </Grid>

                    <Grid item xs={4} md={6} lg={6}>
                      <div
                        style={{
                          display: ' flex',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                        }}
                      >
                        {polling ? (
                          <CircularProgress />
                        ) : (
                          <Button
                            color="primary"
                            startIcon={<img src={ExcelIcon} alt="icon" />}
                            style={{
                              textTransform: 'capitalize',
                              textDecoration: 'underline',
                            }}
                            onClick={generateExcelFile}
                            disabled={downloadDisabled}
                          >
                            Download Excel
                          </Button>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                {/* -----------------second filter--------------- */}
                <Grid item xs={12} className="paginationGrid">
                  <Grid container alignItems="center">
                    <Grid item xs={3} md={3} lg={3}>
                      <div style={{ display: ' flex', alignItems: 'center' }}>
                        <Button
                          color="primary"
                          startIcon={<ArrowBackIosIcon />}
                          disabled={offset == 0}
                          style={{ textTransform: 'capitalize' }}
                          variant
                          onClick={handlePrevious}
                        >
                          Previous
                        </Button>
                      </div>
                    </Grid>
                    <Grid item xs={6} md={6} lg={6}>
                      <div
                        style={{
                          display: ' flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: '100%',
                        }}
                      >
                        <div style={{ width: '100%', maxWidth: '260px' }}>
                          <ReactSelect
                            value={filterByTransporter}
                            onChange={handleTransporterChange}
                            options={transporterList}
                            placeholder="Select Transporter"
                            isClearable
                            styles={style}
                          />
                        </div>
                        <div style={{ width: '100%', maxWidth: '260px' }}>
                          <MultiSelectDropdown
                            ref={childRef}
                            options={branchesOptions}
                            optionsCatch={value => handleOptionsSelect(value)}
                            styles={style}
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={3} md={3} lg={3}>
                      <div
                        style={{
                          display: ' flex',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Button
                          color="primary"
                          endIcon={<ArrowForwardIosIcon />}
                          style={{
                            textTransform: 'capitalize',
                          }}
                          onClick={handleNext}
                          disabled={nextDisabled}
                        >
                          Next
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                {/* -----------------Table View--------------- */}
                <Grid item xs={12}>
                  <TableContainer className={classes.tableContainer}>
                    <Table
                      className={classes.table}
                      aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          {TableHeaders?.map(header => (
                            <StyledTableCell
                              align={
                                header == 'Transaction Number' ||
                                // header == 'Transporter' ||
                                header == 'Branch'
                                  ? 'left'
                                  : header == 'Amount' || header == 'Age'
                                  ? 'right'
                                  : 'center'
                              }
                            >
                              {header}
                            </StyledTableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      {fetching ? (
                        <CircularProgress />
                      ) : (
                        <TableBody>
                          {rows.map(row => (
                            <StyledTableRow key={row.branch}>
                              <StyledTableCell align="left">
                                {row.transaction_serial}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {row.truck_no}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {row.transporter_name}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {row.branch}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {row.loading_date}
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                {row.age}
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                {row.amount_due}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </div>
          </AccordionDetails>
        </Accordion>
      </fieldset>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="No BTH Data Found"
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
};

export default OutstandingReportsTotalBalance;
