/* eslint-disable no-plusplus */
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  CircularProgress,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import Button from 'components/CustomButtons/Button';
import { connect } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import ReactSelect from 'react-select';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import moment from 'moment';
import swal from 'sweetalert';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import firebase from '../../../config/config';
import apiCaller from '../../../utils/apiCallerOutstanding';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: 10,
  },
  paper: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
    minHeight: 600,
    maxWidth: 600,
    marginTop: 30,
  },

  header: {
    background: '#163BAC',
    borderRadius: '10px 10px 0px 0px',
    padding: '10px',
  },
  title: {
    color: 'white',
  },

  gridCell: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  select: {
    width: '100%',
  },
}));
let fileName = '';
let poll = true;
const TransporterOutstandingReports = props => {
  const { activeRole } = props;
  const classes = useStyles();
  const countRef = useRef(0);
  const [polling, setpolling] = useState(false);
  const [transporterList, setTransportersList] = useState([]);
  const [truckerList, setTruckersList] = useState([]);
  const [branchesList, setBranchesList] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectedBranch, setBranch] = useState('');
  const [fromCity, setFromCity] = useState('');
  const [toCity, setToCity] = useState('');
  const [transporter, setTransporter] = useState('');
  const [trucker, setTrucker] = useState('');
  const [truckNumber, setTruckNumber] = useState('');
  const [cities, setCities] = useState([]);
  const [trucks, setTrucks] = useState([]);
  const [trip, setTrip] = useState('');

  // const handleDateChange = date => {
  //   setSelectedDate(date);
  // };
  const db = firebase.firestore();
  const getTransporterList = () => {
    const transporters = [];
    db.collection('Transporters')

      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          // doc.data() is never undefined for query doc snapshots
          if (!doc.data().blacklist && !doc.data().isLead) {
            transporters.push({
              label: doc.data().name,
              value: doc.data().transporterId,
            });
          }
        });
        // transporters.push({ label: 'All Transporters', value: 'all' });
        setTransportersList(transporters);
      })
      .catch(error => error);
  };

  const getTruckerList = () => {
    const truckers = [];
    db.collection('Truckers')

      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          // doc.data() is never undefined for query doc snapshots
          if (!doc.data().blacklist && !doc.data().isLead) {
            truckers.push({
              label: doc.data().name,
              value: doc.data().truckerId,
            });
          }
        });
        // transporters.push({ label: 'All Transporters', value: 'all' });
        setTruckersList(truckers);
      })
      .catch(error => error);
  };
  const getBranchesList = () => {
    const docRef = db.collection('Branches').doc('Branches');

    docRef.get().then(doc => {
      if (doc.exists) {
        setBranchesList(doc?.data()?.branches);
      }
    });
  };

  function getUser(id) {
    const docRef = db.collection('Users').doc(id);

    docRef.get().then(doc => {
      if (doc.exists) {
        setBranchesList(doc?.data()?.branches);
      }
    });
  }

  function handleDateChange(date) {
    setFromDate(date);
  }
  function handleToDateChange(date) {
    setToDate(date);
  }

  function getCities() {
    const cityRef = db
      .collection('Cities')
      .where('isActive', '==', true)
      .orderBy('name', 'asc');
    cityRef.onSnapshot(citiesSnapshot => {
      //   const cities = [];
      const fromCitiesObject = [];
      citiesSnapshot.forEach(city => {
        cities.push(city.data().name);
        fromCitiesObject.push({
          value: city.data().name,
          label: city.data().name,
        });
      });
      setCities(fromCitiesObject);
    });
  }

  function getSelectedTruckerTruckNumber(id) {
    const docRef = db.collection('Truckers').doc(id);

    docRef.get().then(doc => {
      if (doc?.exists) {
        setTrucks(doc?.data()?.trucks);
      }
    });
  }

  function handleTruckerSelect(value) {
    if (value) {
      setTrucker(value);
    } else {
      setTrucker(value);
    }
    setTrucks([]);
    getSelectedTruckerTruckNumber(value?.value);
  }

  function handleTruckNumberSelect(value) {
    if (value) {
      setTruckNumber(value);
    } else {
      setTruckNumber(value);
    }
  }

  function handleBranchChange(value) {
    if (value) {
      setBranch(value);
    } else {
      setBranch(value);
    }
  }

  function handleFromCityChange(value) {
    if (value) {
      setFromCity(value);
    } else {
      setFromCity(value);
    }
  }

  function handleToCityChange(value) {
    if (value) {
      setToCity(value);
    } else {
      setToCity(value);
    }
  }

  const pollExcelDownload = () => {
    countRef.current++;
    const pollTimeOut = setTimeout(() => {
      generateExcelFile();
    }, 5000);

    if (countRef.current === 120) {
      clearTimeout(pollTimeOut);
      setpolling(false);
      swal('Oops!', 'we couldnt find the records!', 'error');
      poll = true;
    }
  };

  const handleDownload = (url, fileName) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;

    document.body.appendChild(link);
    link.click();
    setpolling(false);
  };

  const generateExcelFile = async () => {
    setpolling(true);
    const userDetails = firebase.auth().currentUser;
    const { Aa } = userDetails;
    const token = Aa;
    if (!fileName) {
      const newDate = moment().format();
      fileName = `Transporter-Outstanding-${newDate}.xlsx`;
    }
    const data = {
      file_name: fileName,
      is_polling: poll ? 0 : 1,
      outstanding_type: 'transporterOutstandingReports',
      filter_branch: selectedBranch?.value ? [selectedBranch?.value] : [],
      from_city: fromCity?.value || '',
      to_city: toCity?.value || '',
      from_date:
        fromDate !== null ? moment(fromDate)?.format('YYYY-MM-DD') : '',
      to_date: toDate !== null ? moment(toDate)?.format('YYYY-MM-DD') : '',
      gcp_transporter_id: transporter?.value || '',
      gcp_trucker_id: trucker?.value || '',
      vehicle_number: truckNumber?.value || '',
      transaction_serial: trip,
    };
    const ENDPOINT = 'get-outstanding-report-excel';

    await apiCaller(ENDPOINT, data, `Bearer ${token}`)
      .then(response => {
        const { url } = response.data;
        if (!url) {
          poll = false;
          pollExcelDownload();
        } else {
          const { url } = response.data;

          poll = true;
          fileName = '';
          const isWindowOpenSupported = window.open('', '_blank');

          if (isWindowOpenSupported) {
            // Popup window is supported, open the URL in a new window
            window.open(url, '_blank');
            setpolling(false);
          } else {
            // Popup window is disabled, trigger download programmatically
            handleDownload(url, fileName);
          }
        }
      })
      .catch(error => {
        poll = false;
        pollExcelDownload();
      });
  };

  function handleExcelDownoad() {
    generateExcelFile();
  }
  function handleTransporterChange(value) {
    if (value) {
      setTransporter(value);
    } else {
      setTransporter(value);
    }
  }

  function handleTextChange(e) {
    const reg = /^\s*\d*\s*$/;
    if (reg.test(e.target.value)) {
      setTrip(e.target.value);
    }
  }

  useEffect(() => {
    getTransporterList();
    getTruckerList();

    const userDetails = firebase.auth().currentUser;
    const { uid } = userDetails;
    if (activeRole === 'ops' || activeRole === 'finance') {
      getBranchesList();
    } else {
      getUser(uid);
    }

    getCities();
  }, []);
  return (
    <div>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Paper className={classes.paper}>
          <div className={classes.header}>
            <Typography variant="h5" className={classes.title}>
              Filter By
            </Typography>
          </div>
          <Grid container spacing={2} className={classes.root}>
            <Grid item xs={6} className={classes.gridCell}>
              <div className={classes.select}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="From date"
                    format="dd/MM/yyyy"
                    value={fromDate}
                    maxDate={new Date()}
                    onChange={date => handleDateChange(date)}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </Grid>
            <Grid item xs={6} className={classes.gridCell}>
              <div className={classes.select}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="To date"
                    format="dd/MM/yyyy"
                    value={toDate}
                    minDate={fromDate}
                    maxDate={new Date()}
                    onChange={date => handleToDateChange(date)}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </Grid>

            <Grid item xs={6} className={classes.gridCell}>
              <Typography variant="button">Branches</Typography>
              <div className={classes.select}>
                <ReactSelect
                  options={branchesList?.map(branch => ({
                    value: branch,
                    label: branch,
                  }))}
                  onChange={value => handleBranchChange(value)}
                  value={selectedBranch}
                  isClearable
                />
              </div>
            </Grid>
            <Grid item xs={6} className={classes.gridCell}>
              <Typography variant="button">Transporter</Typography>
              <div className={classes.select}>
                <ReactSelect
                  options={transporterList}
                  option={transporter}
                  onChange={value => handleTransporterChange(value)}
                  isClearable
                />
              </div>
            </Grid>
            <Grid item xs={6} className={classes.gridCell}>
              <Typography variant="button">From City</Typography>
              <div className={classes.select}>
                <ReactSelect
                  options={cities}
                  value={fromCity}
                  onChange={value => handleFromCityChange(value)}
                  isClearable
                />
              </div>
            </Grid>
            <Grid item xs={6} className={classes.gridCell}>
              <Typography variant="button">To City</Typography>
              <div className={classes.select}>
                <ReactSelect
                  options={cities}
                  value={toCity}
                  onChange={value => handleToCityChange(value)}
                  isClearable
                />
              </div>
            </Grid>

            <Grid item xs={6} className={classes.gridCell}>
              <Typography variant="button">Trucker</Typography>
              <div className={classes.select}>
                <ReactSelect
                  options={truckerList}
                  onChange={value => handleTruckerSelect(value)}
                  value={trucker}
                  isClearable
                />
              </div>
            </Grid>
            <Grid item xs={6} className={classes.gridCell}>
              <Typography variant="button">Truck Number</Typography>
              <div className={classes.select}>
                <ReactSelect
                  options={trucks?.map(truck => ({
                    value: truck.truckNumber,
                    label: truck.truckNumber,
                  }))}
                  onChange={value => handleTruckNumberSelect(value)}
                  value={truckNumber}
                  isClearable
                />
              </div>
            </Grid>
            <Grid item xs={6} className={classes.gridCell}>
              <Typography variant="button">Trip Number</Typography>
              <div className={classes.select}>
                <TextField
                  id="standard-basic"
                  fullWidth
                  onChange={e => handleTextChange(e)}
                  value={trip}
                />
              </div>
            </Grid>
          </Grid>
          <div style={{ marginTop: '30px' }}>
            {polling ? (
              <CircularProgress />
            ) : (
              <Button
                variant="contained"
                color="success"
                style={{ cursor: 'pointer' }}
                startIcon={<FileCopyIcon />}
                onClick={() => handleExcelDownoad()}
                //   disabled
              >
                Download Excel
              </Button>
            )}
          </div>
        </Paper>
      </Grid>
    </div>
  );
};

const mapStateToProps = state => ({
  isDetailedPage: state.transactions.isDetailedPage,
  isModifyPage: state.transactions.isModifyPage,
  transactions: state.transactions.transactions,
  approvedTransactions: state.transactions.approvedTransactions,
  pendingTransactions: state.transactions.pendingTransactions,
  modifyTransactions: state.transactions.modifyTransactions,
  rejectedTransactions: state.transactions.rejectedTransactions,
  activeRole: state.main.activeRole,
  userDetails: state.main.userDetails,
  uid: state.main.uid,
});

export default connect(mapStateToProps)(TransporterOutstandingReports);
