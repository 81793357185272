/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-empty */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// @material-ui/Icons

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import queryString from 'query-string';

import InputLabel from '@material-ui/core/InputLabel';
import ReactSelect from 'react-select';
import Danger from 'components/Typography/Danger.jsx';

// Component for Transactions Card
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Responsive from 'react-responsive';
import TransactionStyle from 'assets/jss/material-dashboard-pro-react/views/TransactionStyle';

import OutstandingCard from './OutstandingCard';

import {
  switchActivePage,
  setTransactions,
  setApprovedTransactions,
  setModifyTransactions,
  setPendingTransactions,
  setRejectedTransactions,
} from '../../reducers/transactions';
import firebase from '../../config/config';

const Desktop = props => <Responsive {...props} minWidth={851} />;
const Mobile = props => <Responsive {...props} maxWidth={850} />;
const db = firebase.firestore();

// let unsubscribe;

class Outstandings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      approvedTransactions: [],
      approvedNextActive: false,
      approvedPrevActive: false,
      transporterId: null,
      transporterName: null,
      transporters: [],
      selectedTransporter: null,
      transporterNameState: false,
      branches: [],
      selectedBranch: null,
    };
    this.switchTab = this.switchTab.bind(this);
    this.unsubscribePendingPage = () => null;
    this.unsubscribeModifyPage = () => null;
    this.unsubscribeApprovedPage = () => null;
    this.unsubscribeRejectedPage = () => null;
    this.handleTransporter = this.handleTransporter.bind(this);
    this.handleBranch = this.handleBranch.bind(this);

    this.getTransporters = this.getTransporters.bind(this);
  }

  componentDidMount() {
    const { userDetails } = this.props;
    this.props.switchActivePage(false);
    this.getTransporters();
    let branches = [];
    let initialBranches = userDetails.branches;
    if (initialBranches.length > 0) {
      initialBranches.forEach(branch => {
        branches.push({
          value: branch,
          name: branch,
          label: branch,
          branch,
        });
      });
      this.setState({
        branches,
      });
    }
    // this.setState({ selectedBranch: userDetails.branches[0] });
    this.setParams();
  }

  setParams() {
    let params = queryString.parse(this.props.location.search);
    let transporterId = null;
    let selectedbranch = null;

    if (params.transporterId !== null && params.transporterId !== undefined) {
      transporterId = params.transporterId;
    }
    if (params.branchId !== null && params.branchId !== undefined) {
      selectedbranch = {
        value: params.branchId,
        name: params.branchId,
        label: params.branchId,
      };
    }
    this.setState(
      {
        selectedBranch: selectedbranch,
        transporterId,
      },
      () => {
        this.getInitials(transporterId);
      }
    );
  }

  getInitials(transporterId) {
    this.getTransactions();
    this.getCurrentTransporter(transporterId);
  }

  getCurrentTransporter(transporterId) {
    const transporterRef = db
      .collection(`Transporters`)
      .where('transporterId', '==', transporterId);
    transporterRef.get().then(res => {
      res.forEach(transporter => {
        const selectedTransporter = {
          value: transporter.data().transporterId,
          label: transporter.data().name,
          name: transporter.data().name,
          ...transporter.data(),
        };
        this.setState({
          selectedTransporter,
        });
      });
    });
  }

  componentWillUnmount() {
    let id = window.setTimeout(null, 0);
    while (id--) {
      window.clearTimeout(id);
    }
  }

  /**
   * will return All the Transporters Available for User
   * @function
   * @param {String} companyId
   */
  getTransporters() {
    const TransporterRef = db.collection(`Transporters`);
    TransporterRef.where('status', '==', 'approved')
      .get()
      .then(
        resultData => {
          const transporters = [];
          resultData.forEach(transporter => {
            //  Checks if transporter and user have any common branches
            const data = transporter.data();
            data.label = transporter.data().name;
            data.value = transporter.data().transporterId;
            transporters.push(data);
          });
          this.setState({ transporters });
        },
        err => {
          console.log('err', err);
        }
      )
      .catch(err => {
        console.log('err', err);
      });
  }

  getTransactions() {
    this.getStatusTransactions('approved');
  }

  handleTransporter = selectedTransporter => {
    const { selectedBranch } = this.state;
    const { activeRole } = this.props;
    this.setState(
      {
        selectedTransporter,
        transporterName: selectedTransporter.name,
        transporterId: selectedTransporter.transporterId,
      },
      () => {
        this.getTransactions();
      }
    );
    if (selectedBranch !== null && selectedBranch !== undefined) {
      if (selectedBranch.hasOwnProperty('name')) {
        this.props.history.push(
          `/${activeRole}/outstanding/transporter?transporterId=${selectedTransporter.transporterId}&&branchId=${selectedBranch.name}`
        );
      } else {
        this.props.history.push(
          `/${activeRole}/outstanding/transporter?transporterId=${selectedTransporter.transporterId}`
        );
      }
    } else {
      this.props.history.push(
        `/${activeRole}/outstanding/transporter?transporterId=${selectedTransporter.transporterId}`
      );
    }
  };

  handleBranch = selectedBranch => {
    const { transporterId } = this.state;
    this.setState(
      {
        selectedBranch: selectedBranch,
      },
      () => {
        this.getTransactions();
      }
    );
    if (
      transporterId !== undefined ||
      transporterId !== null ||
      transporterId !== ''
    ) {
      this.props.history.push(
        `/sales/outstanding/transporter?transporterId=${transporterId}&&branchId=${selectedBranch.name}`
      );
    } else {
      this.props.history.push(
        `/sales/outstanding/transporter?transporterId=${transporterId}&&branchId=${selectedBranch.name}`
      );
    }
  };

  getStatusTransactions(status) {
    const { transporterId, selectedBranch } = this.state;

    try {
      if (status === 'approved') this.unsubscribeApprovedPage();
    } catch {}

    let transactionsRef;
    const { userDetails } = this.props;

    transactionsRef = db
      .collection(`Transactions`)
      .where('status', '==', status)
      .where('isEditedTransaction', '==', false)
      .where('transporterId', '==', transporterId)
      .where('currentReceivableAmount', '>', 0)
      .orderBy('currentReceivableAmount', 'asc')

      .orderBy('createdAt', 'desc')

      .limit(20 + 1); // Limit to 20 + 1 to see if next should be enabled or not, displaying only 20

    if (!userDetails.isSalesManager) {
      transactionsRef = transactionsRef.where('agentId', '==', userDetails.uid);
    }

    if (
      selectedBranch !== undefined &&
      selectedBranch !== null &&
      selectedBranch.hasOwnProperty('name')
    ) {
      transactionsRef = transactionsRef.where(
        'branch',
        '==',
        selectedBranch.name
      );
    }

    this.setState({ [`${status}PrevActive`]: false });
    const unsubscribePage = transactionsRef.onSnapshot(transactionsData => {
      const transactions = [];
      //  this.setState({ isLoading: false });
      transactionsData.forEach(transaction => {
        const formattedTransaction = {
          ...transaction.data(),
        };
        transactions.push(formattedTransaction);
      });

      let nextCursor;
      if (transactionsData.docs.length > 20)
        nextCursor =
          transactionsData.docs[transactionsData.docs.length - 1 - 1];
      else nextCursor = transactionsData.docs[transactionsData.docs.length - 1];

      const currentCursor = transactionsData.docs[0];

      if (transactionsData.docs.length < 20 + 1)
        this.setState({ [`${status}NextActive`]: false });
      else this.setState({ [`${status}NextActive`]: true });

      if (transactions.length > 20) transactions.pop();

      this.setState({
        [`${status}Transactions`]: transactions,
        [`${status}NextCursor`]: nextCursor,
        [`${status}CurrentCursor`]: currentCursor,
        [`${status}InitialCursor`]: currentCursor,
        checksAlert: false,
      });
    });

    if (status === 'approved') this.unsubscribeApprovedPage = unsubscribePage;
  }

  handleBranchChange = event => {
    this.setState({ selectedBranch: event.target.value }, () =>
      this.getTransactions()
    );
  };

  showNext(status) {
    const { transporterId, selectedBranch, approvedNextCursor } = this.state;
    const { userDetails } = this.props;

    try {
      if (status === 'approved') this.unsubscribeApprovedPage();
    } catch {}
    let transactionsRef;
    let nextCursor;

    if (status === 'approved') nextCursor = approvedNextCursor;

    transactionsRef = db
      .collection(`Transactions`)
      .where('status', '==', status)
      .where('transporterId', '==', transporterId)
      .where('isEditedTransaction', '==', false)
      .where('currentReceivableAmount', '>', 0)
      .orderBy('currentReceivableAmount', 'asc');

    if (!userDetails.isSalesManager || !userDetails.isAdmin) {
      transactionsRef = transactionsRef.where('agentId', '==', userDetails.uid);
    }

    if (
      selectedBranch !== undefined &&
      selectedBranch !== null &&
      selectedBranch.hasOwnProperty('name')
    ) {
      transactionsRef = transactionsRef.where(
        'branch',
        '==',
        selectedBranch.name
      );
    }

    transactionsRef = transactionsRef
      .limit(20 + 1)
      .orderBy('createdAt', 'desc')
      .startAfter(nextCursor);

    transactionsRef.get().then(transactionsData => {
      const transactions = [];
      //  this.setState({ isLoading: false });
      transactionsData.forEach(transaction => {
        const formattedTransaction = {
          ...transaction.data(),
        };
        transactions.push(formattedTransaction);
      });

      if (transactionsData.docs.length > 0) {
        let newNextCursor;
        if (transactionsData.docs.length > 20)
          newNextCursor =
            transactionsData.docs[transactionsData.docs.length - 1 - 1];
        else
          newNextCursor =
            transactionsData.docs[transactionsData.docs.length - 1];
        const newCurrentCursor = transactionsData.docs[0];

        if (transactionsData.docs.length < 20 + 1)
          this.setState({ [`${status}NextActive`]: false });
        else this.setState({ [`${status}NextActive`]: true });

        if (transactions.length > 20) transactions.pop();

        this.setState({
          [`${status}PrevActive`]: true,
          [`${status}Transactions`]: transactions,
          [`${status}NextCursor`]: newNextCursor,
          [`${status}CurrentCursor`]: newCurrentCursor,
        });
      }
    });
  }

  showPrev(status) {
    const { transporterId, selectedBranch } = this.state;
    try {
      if (status === 'approved') this.unsubscribeApprovedPage();
    } catch {}

    const { userDetails } = this.props;
    const { approvedCurrentCursor, approvedInitialCursor } = this.state;

    let transactionsRef;
    let currentCursor;
    let initialCursor;

    if (status === 'approved') currentCursor = approvedCurrentCursor;

    if (status === 'approved') initialCursor = approvedInitialCursor;

    transactionsRef = db
      .collection(`Transactions`)
      .where('status', '==', status)
      .where('transporterId', '==', transporterId)
      .where('isEditedTransaction', '==', false)
      .where('currentReceivableAmount', '>', 0)
      .orderBy('currentReceivableAmount', 'asc');

    if (!userDetails.isSalesManager || !userDetails.isAdmin) {
      transactionsRef = transactionsRef.where('agentId', '==', userDetails.uid);
    }

    if (
      selectedBranch !== undefined &&
      selectedBranch !== null &&
      selectedBranch.hasOwnProperty('name')
    ) {
      transactionsRef = transactionsRef.where(
        'branch',
        '==',
        selectedBranch.name
      );
    }

    transactionsRef = transactionsRef
      .limit(20)
      .orderBy('createdAt', 'asc')
      .startAfter(currentCursor);

    transactionsRef.get().then(transactionsData => {
      let transactions = [];
      //  this.setState({ isLoading: false });
      transactionsData.forEach(transaction => {
        const formattedTransaction = {
          ...transaction.data(),
        };
        transactions.push(formattedTransaction);
      });

      if (transactionsData.docs.length > 0) {
        const newCurrentCursor =
          transactionsData.docs[transactionsData.docs.length - 1];
        const newNextCursor = transactionsData.docs[0];

        if (
          newCurrentCursor.data().transactionId ===
          initialCursor.data().transactionId
        ) {
          this.getStatusTransactions(status);
        } else if (transactionsData.docs.length < 20) {
          this.getStatusTransactions(status);
        } else {
          transactions = transactions.reverse();
          this.setState({
            [`${status}NextActive`]: true,
            [`${status}Transactions`]: transactions,
            [`${status}NextCursor`]: newNextCursor,
            [`${status}CurrentCursor`]: newCurrentCursor,
          });
        }
      } else {
        this.getStatusTransactions(status);
      }
    });
  }

  switchTab() {
    const {
      approvedTransactions,
      approvedNextActive,
      approvedPrevActive,
    } = this.state;
    this.props.setApprovedTransactions(approvedTransactions);
    return (
      <div>
        {approvedTransactions.length !== 0 ? (
          <div>
            <Button
              disabled={!approvedPrevActive}
              color={approvedPrevActive ? 'info' : 'default'}
              size="sm"
              style={{ float: 'left', marginLeft: '10px' }}
              onClick={() => this.showPrev('approved')}
            >
              Prev
            </Button>
            <Button
              disabled={!approvedNextActive}
              color={approvedNextActive ? 'info' : 'default'}
              size="sm"
              style={{ float: 'right' }}
              onClick={() => this.showNext('approved')}
            >
              Next
            </Button>
            <OutstandingCard
              sales
              approved
              transactions={this.props.approvedTransactions}
            />
          </div>
        ) : (
          <h5 style={{ marginTop: '40px' }}>
            No Outstanding Transactions Available
          </h5>
        )}
      </div>
    );
  }

  render() {
    const {
      selectedTransporter,
      transporters,
      transporterNameState,
      selectedBranch,
      branches,
    } = this.state;
    const { classes } = this.props;
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} lg={12} style={{ padding: '0px' }}>
            <Card>
              <CardHeader className={`${classes.cardHeader}`} color="info">
                <GridContainer>
                  <GridItem xs={12} md={3} lg={3}>
                    <p
                      className={`${classes.cardHeader} ${classes.rajdhaniFont}`}
                      style={{ marginBottom: '0px', fontSize: '16px' }}
                    >
                      Customer Statement
                    </p>
                  </GridItem>
                  <GridItem xs={12} md={7} lg={8}></GridItem>
                  <GridItem xs={12} md={2} lg={1}></GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody
                className={`${classes.cardHeader} ${classes.textCenter}`}
              >
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <InputLabel
                      className={classes.labelText}
                      style={{ textAlign: 'left' }}
                    >
                      Select Transporter
                    </InputLabel>
                    <ReactSelect
                      value={selectedTransporter}
                      onChange={this.handleTransporter}
                      options={transporters}
                      placeholder="Select Transporter"
                    />
                    {transporterNameState === 'Invalid' && (
                      <Danger>{transporterNameState}</Danger>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <InputLabel
                      className={classes.labelText}
                      style={{ textAlign: 'left' }}
                    >
                      Select Branch
                    </InputLabel>
                    <ReactSelect
                      value={selectedBranch}
                      onChange={this.handleBranch}
                      options={branches}
                      placeholder="Select Branch"
                    />
                  </GridItem>
                </GridContainer>
                <div>
                  <Desktop>{this.switchTab()}</Desktop>
                  <Mobile>{this.switchTab()}</Mobile>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Outstandings.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  isDetailedPage: state.transactions.isDetailedPage,
  isModifyPage: state.transactions.isModifyPage,
  transactions: state.transactions.transactions,
  approvedTransactions: state.transactions.approvedTransactions,
  pendingTransactions: state.transactions.pendingTransactions,
  modifyTransactions: state.transactions.modifyTransactions,
  rejectedTransactions: state.transactions.rejectedTransactions,
  activeRole: state.main.activeRole,
  userDetails: state.main.userDetails,
  uid: state.main.uid,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      switchActivePage,
      setTransactions,
      setApprovedTransactions,
      setModifyTransactions,
      setPendingTransactions,
      setRejectedTransactions,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(TransactionStyle)(Outstandings));
