import React, { useEffect, useState } from 'react';
import { Toaster, toast } from 'react-hot-toast';

const InternetConnectionAlert = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const toastStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };
  useEffect(() => {
    // NOTE Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    // NOTE Listen to the online status
    window.addEventListener('online', handleStatusChange);

    // NOTE Listen to the offline status
    window.addEventListener('offline', handleStatusChange);
    if (isOnline) {
      const notify = () => toast.success('You are connected back!');
      notify();
    } else {
      const notify = () => toast.error('internet connection lost !');
      notify();
    }
    // NOTE Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };
  }, [isOnline]);
  return <Toaster position="bottom-right" reverseOrder={false} />;
};

export default InternetConnectionAlert;
