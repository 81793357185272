/* eslint-disable react/no-unused-state */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// import NotificationImportant from '@material-ui/icons/NotificationImportant';
import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

// core components
import Table from 'components/Table/Table.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
// @material-ui/icons
import Close from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TransactionStyle from 'assets/jss/material-dashboard-pro-react/views/TransactionStyle';
import Responsive from 'react-responsive';
import moment from 'moment';
import {
  switchActivePage,
  setCurrentTransaction,
  setCurrentTransactionUnsubscribe,
  setTransactions,
  switchModifyPage,
} from '../../reducers/transactions';
import firebase from '../../config/config';
import history from '../../history';

const Desktop = props => <Responsive {...props} minWidth={851} />;
const Mobile = props => <Responsive {...props} maxWidth={850} />;

// Fireabse
const db = firebase.firestore();

class OutstandingCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transaction: [],
      checksAlert: false,
      checkOne: false,
      checkTwo: false,
      checksError: '',
      checksLoading: false,
      recentTransactionsLoading: false,
      recentTransactions: [],
      truckerContactPerson: '',
      transporterContactPerson: '',
      activeBankDetails: {},
      activeBankDetailsLoading: false,
    };
    this.showDetailedTransaction = this.showDetailedTransaction.bind(this);
    this.showModifyPage = this.showModifyPage.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleCheckOne = this.handleCheckOne.bind(this);
    this.handleCheckTwo = this.handleCheckTwo.bind(this);
    this.closeChecksAlert = this.closeChecksAlert.bind(this);
    this.showChecksAlert = this.showChecksAlert.bind(this);
    this.finishChecks = this.finishChecks.bind(this);
    this.getTableHeadings = this.getTableHeadings.bind(this);
    this.showTransactions = this.showTransactions.bind(this);
  }

  componentDidMount() {}

  getMobileCard(transaction) {
    const { classes } = this.props;
    const {
      currentReceivableAmount,
      totalReceivableAmount,
      athReceivable,
    } = transaction;
    let totalReceivedAmount;
    let athReceivedAmount;
    let bthReceivedAmount = 0;
    totalReceivedAmount =
      Number(totalReceivableAmount) - Number(currentReceivableAmount);
    if (totalReceivedAmount >= Number(athReceivable)) {
      athReceivedAmount = Number(athReceivable);
    } else {
      athReceivedAmount = totalReceivedAmount;
    }

    if (totalReceivedAmount > Number(athReceivable)) {
      bthReceivedAmount = totalReceivedAmount - Number(athReceivable);
    } else {
      bthReceivedAmount = 0;
    }
    const color = '#dff0d8';
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Card
            chart
            className={classes.card}
            style={{ backgroundColor: color }}
          >
            <CardBody>
              <GridContainer>
                <GridItem
                  xs={4}
                  sm={4}
                  md={12}
                  lg={12}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <p
                    style={{
                      fontWeight: 'bold',
                      fontSize: '10px',
                      marginBottom: '3px',
                      float: 'left',
                      textAlign: 'start',
                      textTransform: 'uppercase',
                    }}
                  >
                    Loading Date
                  </p>
                  <p
                    style={{
                      fontWeight: 'bold',
                      fontSize: '8px',
                      marginBottom: '3px',
                      float: 'left',
                      textAlign: 'start',
                      textTransform: 'uppercase',
                    }}
                  >
                    {transaction.loadingDate}
                  </p>
                </GridItem>
                <GridItem
                  xs={4}
                  sm={4}
                  md={12}
                  lg={12}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <p
                    style={{
                      fontWeight: 'bold',
                      fontSize: '10px',
                      marginBottom: '3px',
                      textAlign: 'left',
                      textTransform: 'uppercase',
                    }}
                  >
                    Truck No{' '}
                  </p>
                  <p
                    style={{
                      fontWeight: 'bold',
                      fontSize: '8px',
                      marginBottom: '3px',
                      float: 'left',
                      textAlign: 'left',
                      textTransform: 'uppercase',
                    }}
                  >
                    {transaction.vehicleNumber}{' '}
                  </p>
                </GridItem>
                <GridItem
                  xs={4}
                  sm={4}
                  md={12}
                  lg={12}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <p
                    style={{
                      fontWeight: 'bold',
                      fontSize: '10px',
                      marginBottom: '3px',
                      float: 'left',
                      textAlign: 'start',
                      textTransform: 'uppercase',
                    }}
                  >
                    ATH Receivable{' '}
                  </p>
                  <p
                    style={{
                      fontWeight: 'bold',
                      fontSize: '8px',
                      marginBottom: '3px',
                      float: 'left',
                      textAlign: 'start',
                      textTransform: 'uppercase',
                    }}
                  >
                    <i
                      className="fa fa-inr"
                      aria-hidden="true"
                      style={{ fontSize: '8px', marginRight: '1px' }}
                    />
                    {Intl.NumberFormat('en-IN').format(
                      transaction.athReceivable
                    )}
                  </p>
                </GridItem>
                <GridItem
                  xs={4}
                  sm={4}
                  md={12}
                  lg={12}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <p
                    style={{
                      fontWeight: 'bold',
                      fontSize: '15px',
                      marginBottom: '3px',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <span style={{ paddingLeft: '12px' }}>
                      <Button
                        size="sm"
                        simple
                        style={{
                          color: '#000',
                          fontSize: '14px',
                          padding: '0.0625rem 0.25rem',
                          marginTop: '-2px',
                          fontWeight: 'bold',
                        }}
                        onClick={() =>
                          this.showDetailedTransaction(transaction)
                        }
                      >
                        #{transaction.transactionSerial}
                      </Button>
                    </span>
                  </p>
                </GridItem>
                <GridItem
                  xs={4}
                  sm={4}
                  md={12}
                  lg={12}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <p
                    style={{
                      fontWeight: 'bold',
                      fontSize: '10px',
                      marginBottom: '3px',
                      float: 'left',
                      textAlign: 'start',
                      textTransform: 'uppercase',
                    }}
                  >
                    BTH Receivable{' '}
                  </p>
                  <p
                    style={{
                      fontWeight: 'bold',
                      fontSize: '8px',
                      marginBottom: '3px',
                      float: 'left',
                      textAlign: 'start',
                      textTransform: 'uppercase',
                    }}
                  >
                    <i
                      className="fa fa-inr"
                      aria-hidden="true"
                      style={{ fontSize: '8px', marginRight: '1px' }}
                    />

                    {Intl.NumberFormat('en-IN').format(
                      transaction.bthReceivable
                    )}
                  </p>
                </GridItem>
                <GridItem
                  xs={4}
                  sm={4}
                  md={12}
                  lg={12}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <p
                    style={{
                      fontWeight: 'bold',
                      fontSize: '10px',
                      marginBottom: '3px',
                      float: 'left',
                      textAlign: 'start',
                      textTransform: 'uppercase',
                    }}
                  >
                    ATH Received{' '}
                  </p>
                  <p
                    style={{
                      fontWeight: 'bold',
                      fontSize: '8px',
                      marginBottom: '3px',
                      float: 'left',
                      textAlign: 'start',
                      textTransform: 'uppercase',
                    }}
                  >
                    <i
                      className="fa fa-inr"
                      aria-hidden="true"
                      style={{ fontSize: '8px', marginRight: '1px' }}
                    />
                    {Intl.NumberFormat('en-IN').format(athReceivedAmount)}
                  </p>
                </GridItem>
                <GridItem
                  xs={4}
                  sm={4}
                  md={12}
                  lg={12}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <p
                    style={{
                      fontWeight: 'bold',
                      fontSize: '10px',
                      marginBottom: '3px',
                      float: 'left',
                      textAlign: 'start',
                      textTransform: 'uppercase',
                    }}
                  >
                    Bal Pending{' '}
                  </p>
                  <p
                    style={{
                      fontWeight: 'bold',
                      fontSize: '12px',
                      marginBottom: '3px',
                      textAlign: 'start',
                      float: 'left',
                    }}
                  >
                    <span style={{ paddingLeft: '25px' }}>
                      <i
                        className="fa fa-inr"
                        aria-hidden="true"
                        style={{ fontSize: '10px' }}
                      />
                      {Intl.NumberFormat('en-IN').format(
                        transaction.currentReceivableAmount
                      )}
                    </span>
                  </p>
                </GridItem>
                <GridItem
                  xs={4}
                  sm={4}
                  md={12}
                  lg={12}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <p
                    style={{
                      fontWeight: 'bold',
                      fontSize: '10px',
                      marginBottom: '3px',
                      float: 'left',
                      textAlign: 'start',
                      textTransform: 'uppercase',
                    }}
                  >
                    BTH Received{' '}
                  </p>
                  <p
                    style={{
                      fontWeight: 'bold',
                      fontSize: '8px',
                      marginBottom: '3px',
                      float: 'left',
                      textAlign: 'start',
                      textTransform: 'uppercase',
                    }}
                  >
                    <i
                      className="fa fa-inr"
                      aria-hidden="true"
                      style={{ fontSize: '8px', marginRight: '1px' }}
                    />
                    {Intl.NumberFormat('en-IN').format(bthReceivedAmount)}
                  </p>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }

  getTableHeadings() {
    return (
      <GridContainer
      // justify="center"
      // style={{ justify: 'center', alignItems: 'center' }}
      >
        <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ padding: '0px !important' }}
        >
          <GridContainer>
            <GridItem xs={12} sm={9} md={9} lg={9}>
              <GridContainer>
                <GridItem xs={12} sm={1} md={1} lg={1}>
                  <p
                    style={{
                      fontWeight: 'bold',
                      fontSize: '12px',
                      marginBottom: '0px',
                      float: 'right',
                      color: '#0000007d',
                    }}
                  >
                    No
                  </p>
                </GridItem>
                <GridItem xs={12} sm={11} md={11} lg={11}>
                  <GridContainer>
                    <GridItem xs={12} sm={2} md={2} lg={2}>
                      <p
                        style={{
                          fontWeight: 'bold',
                          fontSize: '14px',
                          marginBottom: '0px',
                          float: 'left',
                          color: '#0000007d',
                        }}
                      >
                        {' '}
                        Loading Date{' '}
                      </p>
                    </GridItem>

                    <GridItem xs={12} sm={10} md={10} lg={10}>
                      <GridContainer>
                        <GridItem
                          xs={12}
                          sm={1}
                          md={3}
                          lg={2}
                          style={{
                            textAlign: 'left',
                          }}
                        >
                          <p
                            style={{
                              fontWeight: 'bold',
                              fontSize: '14px',
                              marginBottom: '0px',
                              float: 'left',
                              color: '#0000007d',
                            }}
                          >
                            {' '}
                            Truck No{' '}
                          </p>
                        </GridItem>
                        <GridItem
                          xs={12}
                          sm={1}
                          md={3}
                          lg={2}
                          style={{
                            textAlign: 'left',
                          }}
                        >
                          <p
                            style={{
                              fontWeight: 'bold',
                              fontSize: '14px',
                              marginBottom: '0px',
                              float: 'left',
                              color: '#0000007d',
                            }}
                          >
                            {' '}
                            From City{' '}
                          </p>
                        </GridItem>
                        <GridItem
                          xs={12}
                          sm={1}
                          md={3}
                          lg={2}
                          style={{
                            textAlign: 'left',
                          }}
                        >
                          <p
                            style={{
                              fontWeight: 'bold',
                              fontSize: '14px',
                              marginBottom: '0px',
                              float: 'left',
                              color: '#0000007d',
                            }}
                          >
                            {' '}
                            To City{' '}
                          </p>
                        </GridItem>
                        <GridItem xs={12} sm={3} md={3} lg={2}>
                          <p
                            style={{
                              fontWeight: 'bold',
                              fontSize: '14px',
                              marginBottom: '0px',
                              float: 'right',
                              color: '#0000007d',
                            }}
                          >
                            {' '}
                            ATH Receivable{' '}
                          </p>
                        </GridItem>
                        <GridItem xs={12} sm={3} md={3} lg={2}>
                          <p
                            style={{
                              fontWeight: 'bold',
                              fontSize: '14px',
                              marginBottom: '0px',
                              float: 'right',
                              color: '#0000007d',
                            }}
                          >
                            {' '}
                            BTH Receivable{' '}
                          </p>
                        </GridItem>
                        <GridItem xs={12} sm={3} md={3} lg={2}>
                          <p
                            style={{
                              fontWeight: 'bold',
                              fontSize: '14px',
                              marginBottom: '0px',
                              float: 'right',
                              color: '#0000007d',
                            }}
                          >
                            {' '}
                            ATH Received{' '}
                          </p>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={3} md={3} lg={2}>
              <GridContainer>
                <GridItem xs={12} sm={5} md={5} lg={5}>
                  <p
                    style={{
                      fontWeight: 'bold',
                      fontSize: '14px',
                      marginBottom: '0px',
                      float: 'right',
                      color: '#0000007d',
                    }}
                  >
                    {' '}
                    BTH Received{' '}
                  </p>
                </GridItem>
                <GridItem xs={12} sm={5} md={5} lg={5}>
                  <p
                    style={{
                      fontWeight: 'bold',
                      fontSize: '14px',
                      marginBottom: '0px',
                      float: 'right',
                      color: '#0000007d',
                    }}
                  >
                    {' '}
                    Bal Pending{' '}
                  </p>
                </GridItem>
                <GridItem xs={12} sm={2} md={2} lg={2} />
              </GridContainer>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    );
  }

  getDesktopCard(transaction) {
    const {
      currentReceivableAmount,
      totalReceivableAmount,
      athReceivable,
      // athPayable,
      // bthPayable,
    } = transaction;
    let totalReceivedAmount;
    let athReceivedAmount;
    let bthReceivedAmount = 0;
    totalReceivedAmount =
      Number(totalReceivableAmount) - Number(currentReceivableAmount);
    if (totalReceivedAmount >= Number(athReceivable)) {
      athReceivedAmount = Number(athReceivable);
    } else {
      athReceivedAmount = totalReceivedAmount;
    }

    if (totalReceivedAmount > Number(athReceivable)) {
      bthReceivedAmount = totalReceivedAmount - Number(athReceivable);
    } else {
      bthReceivedAmount = 0;
    }
    const { classes } = this.props;
    return (
      <GridContainer
        justify="center"
        style={{ justify: 'center', alignItems: 'center' }}
      >
        <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ padding: '0px !important' }}
        >
          <div
            style={{
              'box-shadow': '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
              backgroundColor: '#f1f1f1',
              padding: '10px 0px',
            }}
          >
            <GridContainer className={classes.gridBottom}>
              <GridItem xs={12} sm={9} md={9} lg={9}>
                <GridContainer>
                  <GridItem xs={12} sm={1} md={1} lg={1}>
                    <Button
                      size="sm"
                      simple
                      style={{
                        color: '#000',
                        fontSize: '14px',
                        padding: '0.0625rem 12px',
                        marginTop: '-2px',
                      }}
                      onClick={() => this.showDetailedTransaction(transaction)}
                    >
                      #{transaction.transactionSerial}
                    </Button>
                  </GridItem>
                  <GridItem xs={12} sm={11} md={11} lg={11}>
                    <GridContainer>
                      <GridItem xs={12} sm={2} md={2} lg={2}>
                        <p
                          style={{
                            // fontWeight: 'bold',
                            fontSize: '14px',
                            marginBottom: '0px',
                            float: 'left',
                          }}
                        >
                          <span> {transaction.loadingDate} </span>
                        </p>
                      </GridItem>
                      <GridItem xs={12} sm={10} md={10} lg={10}>
                        <GridContainer>
                          <GridItem
                            xs={4}
                            sm={3}
                            md={3}
                            lg={2}
                            style={{
                              textAlign: 'left',
                            }}
                          >
                            <p
                              style={{
                                fontSize: '14px',
                                marginBottom: '0px',
                              }}
                            >
                              {transaction.vehicleNumber}
                            </p>
                          </GridItem>
                          <GridItem
                            xs={4}
                            sm={3}
                            md={3}
                            lg={2}
                            style={{
                              textAlign: 'left',
                            }}
                          >
                            <p
                              style={{
                                fontSize: '14px',
                                marginBottom: '0px',
                              }}
                            >
                              {transaction.fromCity}
                            </p>
                          </GridItem>
                          <GridItem
                            xs={4}
                            sm={3}
                            md={3}
                            lg={2}
                            style={{
                              textAlign: 'left',
                            }}
                          >
                            <p
                              style={{
                                fontSize: '14px',
                                marginBottom: '0px',
                              }}
                            >
                              {transaction.toCity}
                            </p>
                          </GridItem>
                          <GridItem xs={4} sm={1} md={3} lg={2}>
                            <p
                              style={{
                                fontSize: '14px',
                                marginBottom: '0px',
                                float: 'right',
                              }}
                            >
                              <i
                                className="fa fa-inr"
                                aria-hidden="true"
                                style={{ fontSize: '12px', marginRight: '1px' }}
                              />
                              {Intl.NumberFormat('en-IN').format(
                                transaction.athReceivable
                              )}
                            </p>
                          </GridItem>

                          <GridItem xs={12} sm={3} md={3} lg={2}>
                            <p
                              style={{
                                fontSize: '14px',
                                marginBottom: '0px',
                                float: 'right',
                              }}
                            >
                              <i
                                className="fa fa-inr"
                                aria-hidden="true"
                                style={{ fontSize: '12px', marginRight: '1px' }}
                              />
                              {Intl.NumberFormat('en-IN').format(
                                transaction.bthReceivable
                              )}
                            </p>
                          </GridItem>
                          <GridItem xs={12} sm={3} md={3} lg={2}>
                            <p
                              style={{
                                fontSize: '14px',
                                marginBottom: '0px',
                                float: 'right',
                              }}
                            >
                              <i
                                className="fa fa-inr"
                                aria-hidden="true"
                                style={{ fontSize: '12px', marginRight: '1px' }}
                              />
                              {Intl.NumberFormat('en-IN').format(
                                athReceivedAmount || 0
                              )}
                            </p>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={3} md={3} lg={2}>
                <GridContainer>
                  <GridItem xs={12} sm={5} md={5} lg={5}>
                    <p
                      style={{
                        fontSize: '14px',
                        marginBottom: '0px',
                        float: 'right',
                      }}
                    >
                      <i
                        className="fa fa-inr"
                        aria-hidden="true"
                        style={{ fontSize: '12px', marginRight: '1px' }}
                      />
                      {Intl.NumberFormat('en-IN').format(
                        bthReceivedAmount || 0
                      )}
                    </p>
                  </GridItem>
                  <GridItem xs={12} sm={5} md={5} lg={5}>
                    <p
                      style={{
                        fontSize: '14px',
                        marginBottom: '0px',
                        float: 'right',
                      }}
                    >
                      <i
                        className="fa fa-inr"
                        aria-hidden="true"
                        style={{ fontSize: '12px', marginRight: '1px' }}
                      />
                      {Intl.NumberFormat('en-IN').format(
                        transaction.currentReceivableAmount
                      )}
                    </p>
                  </GridItem>
                  <GridItem xs={12} sm={2} md={2} lg={2} />
                </GridContainer>
              </GridItem>
            </GridContainer>
          </div>
        </GridItem>
      </GridContainer>
    );
  }

  closeChecksAlert() {
    this.setState({
      checksAlert: false,
      checksError: '',
      transaction: null,
      checkOne: false,
      checkTwo: false,
      recentTransactionsLoading: false,
      activeBankDetailsLoading: false,
      activeBankDetails: {},
      recentTransactions: [],
    });
  }

  showChecksAlert(transaction) {
    this.setState({
      transaction,
      checksAlert: true,
      checksError: '',
      recentTransactionsLoading: true,
      activeBankDetailsLoading: true,
    });
    const { truckerId, vehicleNumber, transporterId } = transaction;
    const truckerRef = db
      .collection('Truckers')
      .doc(truckerId)
      .get();
    const transporterRef = db
      .collection('Transporters')
      .doc(transporterId)
      .get();
    // Show Active Bank Details to Finance
    const bankRef = db
      .collection('Truckers')
      .doc(truckerId)
      .collection('Banks')
      .get();
    bankRef.then(banksSnap => {
      banksSnap.forEach(bank => {
        if (bank.data().bankAccountActive) {
          this.setState({
            activeBankDetails: bank.data(),
          });
        }
      });
    });
    Promise.all([truckerRef, transporterRef]).then(
      ([truckerResult, transporterResult]) => {
        this.setState({
          truckerContactPerson: truckerResult.data().contactPerson,
          transporterContactPerson: transporterResult.data().contactPerson,
        });
      }
    );
    const recentTransactionsRef = db
      .collection('Transactions')
      .where('truckerId', '==', truckerId)
      .where('vehicleNumber', '==', vehicleNumber)
      .orderBy('createdAt', 'desc')
      .limit(4);
    recentTransactionsRef
      .get()
      .then(snapshot => {
        const recentTransactions = [];
        snapshot.forEach(transactionDoc => {
          if (transaction.transactionId !== transactionDoc.data().transactionId)
            // Don't Include the same transaction in the recent list
            recentTransactions.push(transactionDoc.data());
        });
        if (recentTransactions.length > 3) recentTransactions.pop();
        this.setState({ recentTransactions, recentTransactionsLoading: false });
      })
      .catch(() => {
        this.setState({
          recentTransactions: [],
          recentTransactionsLoading: false,
        });
      });
  }

  handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
    });
  }

  // Will Show DetailedTransaction Page
  showDetailedTransaction(transaction) {
    const { activeRole } = this.props;
    // Set the CurrentTransaction in Redux Store
    // eslint-disable-next-line react/destructuring-assignment
    history.push(
      `/${activeRole}/transactions/view/${transaction.transactionId}`
    );
    this.props.setCurrentTransaction(
      transaction,
      transaction.currentPayableAmount,
      transaction.currentReceivableAmount
    );

    const unsubScribeTransaction = db
      .collection('Transactions')
      .doc(transaction.transactionId)
      .onSnapshot(transactionResult => {
        const formattedTransaction = {
          ...transactionResult.data(),
        };
        this.props.setCurrentTransaction(
          formattedTransaction,
          formattedTransaction.currentPayableAmount,
          formattedTransaction.currentReceivableAmount
        );
      });
    //  Save the unsubscribe function of the onSnapshot Listener. This listener will be unscubscribed on componentWillUnmount of the DetailedTransactions Page
    this.props.setCurrentTransactionUnsubscribe(unsubScribeTransaction);
  }

  showModifyPage(transaction) {
    this.props.setCurrentTransaction(
      transaction,
      transaction.currentPayableAmount,
      transaction.currentReceivableAmount
    );
    this.props.switchModifyPage(true);
  }

  handleCheckOne() {
    const { transactions } = this.props;
    const { transaction, checkOne } = this.state;
    const id = transaction.transactionId;
    const index = transactions.map(e => e.transactionId).indexOf(id);
    transactions[index].checkOne = !transactions[index].checkOne;
    transaction.checkOne = !transaction.checkOne;
    this.setState({ transaction, checkOne: !checkOne });
    this.props.setTransactions(transactions);
  }

  handleCheckTwo() {
    const { transactions } = this.props;
    const { transaction, checkTwo } = this.state;

    const id = transaction.transactionId;
    const index = transactions.map(e => e.transactionId).indexOf(id);
    transactions[index].checkTwo = !transactions[index].checkTwo;
    transaction.checkTwo = !transaction.checkTwo;
    this.setState({ transaction, checkTwo: !checkTwo });
    this.props.setTransactions(transactions);
  }

  finishChecks() {
    this.setState({
      checksLoading: true,
    });
    const { transaction, checkOne, checkTwo } = this.state;
    // if (transaction.tdsTransporter)
    // transporterDeductions += Number(transaction.tds);
    db.collection('Transactions')
      .doc(transaction.transactionId)
      .update({
        checkOne,
        checkTwo,
        showAthModal: true,
        modifiedAt: new Date(),
      })
      .then(() => {
        const { transactions } = this.props;
        const id = transaction.transactionId;
        const index = transactions.map(e => e.transactionId).indexOf(id);
        transactions[index].checkOne = checkOne;
        transactions[index].checkTwo = checkTwo;
        this.props.setTransactions(transactions);
        this.setState({
          checksAlert: false,
          transaction: null,
          checksLoading: false,
          recentTransactionsLoading: false,
          recentTransactions: [],
        });
      });
  }

  /**
   * Will Return Array of Transaction Components
   * @function
   */
  showTransactions() {
    const { transactions } = this.props;
    return (
      <GridContainer justify="flex-end">
        <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ padding: '0px !important' }}
        >
          <Desktop>{this.getTableHeadings()}</Desktop>
        </GridItem>

        {transactions.map(transactionResult => (
          <GridItem
            xs={12}
            sm={12}
            md={12}
            lg={12}
            key={transactionResult.transactionId}
            style={{ padding: '0px !important' }}
          >
            <Desktop>{this.getDesktopCard(transactionResult)}</Desktop>
            <Mobile>{this.getMobileCard(transactionResult)}</Mobile>
          </GridItem>
        ))}
      </GridContainer>
      // <OutstandingTableView transactionData={transactions} />
    );
  }

  render() {
    const { classes } = this.props;
    const {
      checksAlert,
      checkOne,
      checksError,
      checksLoading,
      checkTwo,
      recentTransactions,
      recentTransactionsLoading,
      activeBankDetails,
    } = this.state;
    const myActiveBankDetails = [];
    myActiveBankDetails.push(activeBankDetails);
    return (
      <GridContainer justify="center">
        {this.showTransactions()}
        {checksAlert && (
          <Dialog
            className={classes.modal}
            open={this.state.checksAlert}
            keepMounted
            onClose={() => this.closeChecksAlert()}
          >
            <DialogTitle className={classes.modalHeader}>
              <GridContainer>
                <GridItem xs={2} md={3} lg={3} />
                <GridItem xs={8} md={8} lg={8}>
                  <h3 className={classes.rajdhaniFont}>Finance Approval</h3>
                </GridItem>
                <GridItem xs={2} md={1} lg={1} style={{ color: 'red' }}>
                  <Button
                    justIcon
                    className={classes.modalCloseButton}
                    key="close"
                    aria-label="Close"
                    color="transparent"
                    onClick={() => this.closeChecksAlert()}
                  >
                    <Close className={classes.modalClose} />
                  </Button>
                </GridItem>
              </GridContainer>
            </DialogTitle>
            <DialogContent className={classes.modalBody}>
              <GridContainer>
                <GridItem xs={12}>
                  <div className={classes.checkboxAndRadio}>
                    <FormControlLabel
                      style={{ float: 'left' }}
                      control={
                        <Checkbox
                          checked={checkOne || false}
                          onChange={() => this.handleCheckOne()}
                          value="checkOne"
                        />
                      }
                      label="Has this Transporter been doing regular business with us?"
                    />
                  </div>
                </GridItem>
                <GridItem xs={12}>
                  <div className={classes.checkboxAndRadio}>
                    <FormControlLabel
                      style={{ float: 'left' }}
                      control={
                        <Checkbox
                          checked={checkTwo || false}
                          onChange={() => this.handleCheckTwo()}
                          value="checkTwo"
                        />
                      }
                      label="There are no outstanding issues currently."
                    />
                  </div>
                </GridItem>
                <GridItem xs={12}>
                  {recentTransactionsLoading ? (
                    <GridContainer>
                      <GridItem xs={6} />
                      <GridItem xs={6}>
                        <CircularProgress
                          className={classes.progress}
                          style={{ color: purple[500], justify: 'center' }}
                          thickness={3}
                        />
                      </GridItem>
                    </GridContainer>
                  ) : (
                    <React.Fragment>
                      <h4 className={classes.rajdhaniFont}>
                        Recent Transactions
                      </h4>
                      {recentTransactions.length > 0 ? (
                        <Table
                          tableHeaderColor="primary"
                          tableHead={['Date', 'Truck', 'Freight', 'To', 'From']}
                          tableData={recentTransactions.map(transaction => [
                            moment(transaction.createdAt.toDate()).format(
                              'MMM Do Y'
                            ),
                            transaction.vehicleNumber,
                            transaction.amount,
                            transaction.toCity,
                            transaction.fromCity,
                          ])}
                          coloredColls={[3]}
                          colorsColls={['primary']}
                        />
                      ) : (
                        <h5 className={classes.rajdhaniFont}>
                          No Previous Transactions with this Truck
                        </h5>
                      )}
                      <h4 className={classes.rajdhaniFont}>
                        Active Bank Account
                      </h4>

                      {Object.keys(activeBankDetails).length !== 0 &&
                      activeBankDetails.constructor === Object ? (
                        <Table
                          tableHeaderColor="primary"
                          tableHead={[
                            'Name',
                            'Account No',
                            'Bank',
                            'Ifsc',
                            'Branch',
                          ]}
                          tableData={myActiveBankDetails.map(transaction => [
                            activeBankDetails.bankHolderName,
                            activeBankDetails.accountNumber,
                            activeBankDetails.bankName,
                            activeBankDetails.ifsc,
                            activeBankDetails.bankBranchName,
                          ])}
                        />
                      ) : (
                        <h5 className={classes.rajdhaniFont}>
                          No Bank Accounts Are Active Please Check
                        </h5>
                      )}
                    </React.Fragment>
                  )}
                </GridItem>
                <GridItem xs={12}>
                  <span
                    style={{ color: '#f44336' }}
                    className={classes.rajdhaniFont}
                  >
                    {checksError}
                  </span>
                  {checksLoading ? (
                    <CircularProgress
                      className={classes.progress}
                      style={{ color: purple[500] }}
                      thickness={7}
                    />
                  ) : null}
                </GridItem>
              </GridContainer>
            </DialogContent>
            <DialogActions
              className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
            >
              {this.finishChecks}
              <Button onClick={() => this.closeChecksAlert()} color="danger">
                Cancel
              </Button>
              <Button onClick={() => this.finishChecks()} color="success">
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </GridContainer>
    );
  }
}

OutstandingCard.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  activeRole: state.main.activeRole,
  isDetailedPage: state.transactions.isDetailedPage,
  currerntTransactionData: state.transactions.currerntTransactionData,
  allTransactions: state.transactions.transactions,
  userDetails: state.main.userDetails,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      switchActivePage,
      setCurrentTransaction,
      setCurrentTransactionUnsubscribe,
      setTransactions,
      switchModifyPage,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(TransactionStyle)(OutstandingCard));
