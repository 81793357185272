import React from 'react';
import { Grid } from '@material-ui/core';
import TruckFilesUpload from './TruckFilesUpload';

const TruckDetailCard = ({
  truckersData,
  selectedTrucker,
  activeRole,
  userDetails,
  featureFlagFiles,
}) => {
  return (
    // <div style={truckDetailsCardStyle}>
    <Grid container spacing={3}>
      {truckersData.map(truck => (
        <TruckFilesUpload
          truckersData={truckersData}
          selectedTrucker={selectedTrucker}
          activeRole={activeRole}
          userDetails={userDetails}
          truck={truck}
          featureFlagFiles={featureFlagFiles}
        />
      ))}
    </Grid>
    // </div>
  );
};

export default TruckDetailCard;
