import React from 'react';
import PropTypes from 'prop-types';
// react plugin for creating charts
// react plugin for creating vector maps

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// @material-ui/icons

import Table from 'components/Table/Table.jsx';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';
import moment from 'moment';

import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import firebase from '../../config/config';

const db = firebase.firestore();

class AdminDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      logs: [],
    };
  }

  componentDidMount() {
    this.getLogs();
  }

  getLogs() {
    this.setState({ isLoading: true });
    const auditLogRef = db
      .collection('AuditLog')
      .orderBy('createdAt', 'desc')
      .limit(30);
    auditLogRef
      .get()
      .then(snapshot => {
        const logs = [];
        snapshot.forEach(doc => {
          const { message, createdAt } = doc.data();
          logs.push([
            moment(createdAt.toDate()).format('DD/MM/YY h:mm:ss a'),
            message,
          ]);
        });
        this.setState({ isLoading: false, logs });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { classes } = this.props;
    const { logs, isLoading } = this.state;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader className={`${classes.cardHeader}`} color="warning">
              <h4 style={{ color: '#ffffff' }} className={classes.cardTitle}>
                Audit Log
              </h4>
            </CardHeader>
            <CardBody className={`${classes.cardBody} ${classes.textCenter}`}>
              {isLoading ? (
                <CircularProgress
                  className={classes.progress}
                  style={{ color: purple[500] }}
                  thickness={7}
                />
              ) : (
                <Table
                  hover
                  tableHeaderColor="warning"
                  tableHead={['Date', 'Message']}
                  tableData={logs}
                />
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

AdminDashboard.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(dashboardStyle)(AdminDashboard);
