/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import AddUsers from './AddUsers';
import Users from './Users';

const UserManagement = () => {
  const [toggle, setToggle] = useState(false);
  return (
    <div>
      {!toggle ? (
        <Users handleToggle={() => setToggle(true)} />
      ) : toggle ? (
        <AddUsers />
      ) : null}
    </div>
  );
};

export default UserManagement;
