/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
/* eslint-disable prefer-const */
import React, { useEffect, useState } from 'react';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import { bindActionCreators } from 'redux';
import TransactionStyle from 'assets/jss/material-dashboard-pro-react/views/TransactionStyle';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import firebase from '../../config/config';
import PODAgeingListViewTable from './Sections/PODAgeingListView';
import {
  switchActivePage,
  setTransactions,
  setApprovedTransactions,
  setModifyTransactions,
  setPendingTransactions,
  setRejectedTransactions,
} from '../../reducers/transactions';

const PODAgeingView = props => {
  const { activeRole } = props;
  const db = firebase.firestore();
  const [transporters, setTransporters] = useState([]);
  const [truckers, setTruckers] = useState([]);
  const [branches, setBranches] = useState([]);
  const [fetching, setFetching] = useState('');

  async function getBranchesDetails(uid, token) {
    setFetching('branches');
    let branchesAssigned = [];
    const podRef = await db.collection('Users').doc(uid);
    podRef
      .get()
      .then(async doc => {
        if (doc.exists) {
          const data = doc.data();
          const { branches } = data;
          // eslint-disable-next-line guard-for-in
          for (const i in branches) {
            branchesAssigned.push({
              label: branches[i],
              value: branches[i],
            });
          }
          setBranches(branchesAssigned);
          setFetching('');
          getTransporterList();
        }
      })
      .catch(error => setFetching(''));
  }
  const getTransporterList = () => {
    setFetching('transporters');
    let transporters = [];
    db.collection('Transporters')

      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          transporters.push({
            label: doc.data().name,
            value: doc.data().transporterId,
          });
        });
        transporters.push({ label: 'All Transporters', value: 'all' });
        setTransporters(transporters);
        setFetching('');
        getTruckerList();
      })
      .catch(error => {
        setFetching('');
      });
  };
  const getTruckerList = () => {
    setFetching('truckers');
    let truckers = [];
    db.collection('Truckers')

      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          truckers.push({
            label: doc.data().name,
            value: doc.data().truckerId,
          });
        });
        truckers.push({ label: 'All Transporters', value: 'all' });
        setTruckers(truckers);
        setFetching('');
      })
      .catch(error => {
        setFetching('');
      });
  };

  useEffect(() => {
    const userDetails = firebase.auth().currentUser;

    const { uid, Aa } = userDetails;
    // setUid(uid);

    getBranchesDetails(uid, Aa);
  }, []);
  return (
    <Card>
      <CardHeader>
        <CardIcon color="primary">
          <EqualizerIcon style={{ color: 'white' }} />
        </CardIcon>
        <h4>
          POD Ageing <small />
        </h4>
      </CardHeader>
      <CardBody>
        <PODAgeingListViewTable
          transporters={transporters}
          branches={branches}
          truckers={truckers}
          fetching={fetching}
          activeRole={activeRole}
        />
      </CardBody>
    </Card>
  );
};

const mapStateToProps = state => ({
  isDetailedPage: state.transactions.isDetailedPage,
  isModifyPage: state.transactions.isModifyPage,
  transactions: state.transactions.transactions,
  approvedTransactions: state.transactions.approvedTransactions,
  pendingTransactions: state.transactions.pendingTransactions,
  modifyTransactions: state.transactions.modifyTransactions,
  rejectedTransactions: state.transactions.rejectedTransactions,
  activeRole: state.main.activeRole,
  userDetails: state.main.userDetails,
  uid: state.main.uid,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      switchActivePage,
      setTransactions,
      setApprovedTransactions,
      setModifyTransactions,
      setPendingTransactions,
      setRejectedTransactions,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(TransactionStyle)(PODAgeingView));
