/* eslint-disable no-shadow */
/* eslint-disable vars-on-top */
/* eslint-disable guard-for-in */
/* eslint-disable no-var */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import SaveIcon from '@material-ui/icons/Save';
import { Button, Chip } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import firebase from 'firebase/app';
import AlertMessageBar from './AlertMessageBar';
import Myfirebase from '../../config/config';

var selectedUsers = [];
const db = Myfirebase.firestore();
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  { id: 'branches', disablePadding: false, label: 'Branches' },
  { id: 'roles', disablePadding: false, label: 'Roles' },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.gridHead}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            color="default"
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              className={classes.gridHeadLabel}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          //   backgroundColor: '#fffde7',
        }
      : {
          color: theme.palette.text.primary,
          //   backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected, branchName, selectedUsers } = props;
  const handleBranchAddition = () => {
    const ref = db.collection('Users');
    for (var i in selectedUsers) {
      ref
        .doc(selectedUsers[i])
        .update({
          branches: firebase.firestore.FieldValue.arrayUnion(branchName),
        })
        .then(response => console.log('----'));
    }
  };

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Users
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Save">
          <Button
            variant="contained"
            style={{ background: '#163BAC', color: 'white' }}
            startIcon={<SaveIcon />}
            type="submit"
            onClick={handleBranchAddition}
          >
            Save
          </Button>
        </Tooltip>
      ) : (
        ''
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
    // color: 'white',
  },
  gridHead: {
    background: 'lightgray',
    // border: '2px solid black',
    // color: 'white',
  },
  gridHeadLabel: {
    fontSize: '16px',
  },
}));

export default function EnhancedTable({ users, branchName, refreshUsers }) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [addingBranch, setAddingBranch] = React.useState(false);
  const [trigger, setTrigger] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [selected, setSelected] = React.useState([]);
  function createData(uid, name, branches, roles) {
    return { uid, name, branches, roles };
  }

  const rows = users.map(user =>
    createData(user.uid, user.name, user.branches, [
      user.isOps ? 'OPS' : '',
      user.isAdmin ? 'Admin' : '',
      user.isSalesManager ? 'Sales Manager' : '',
      user.isSales ? 'Sales' : '',
      user.isFinance ? 'Finance' : '',
      user.isBranchOps ? 'Branch OPS' : '',
      user.isCallCenter ? 'Call Center' : '',
    ])
  );
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    selectedUsers = [];
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.name);
      rows.map(n => selectedUsers.push(n.uid));
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleRowSelected = row => {
    setChecked(!checked);
    const duplicate = selectedUsers.includes(row.uid);
    const index = selectedUsers.indexOf(row.uid);
    if (!duplicate) {
      selectedUsers.push(row.uid);
    }
    if (duplicate) {
      if (index > -1) {
        selectedUsers.splice(index, 1);
      }
    }
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const handleBranchAddition = () => {
    setAddingBranch(true);
    const ref = db.collection('Users');
    for (var i in selectedUsers) {
      ref
        .doc(selectedUsers[i])
        .update({
          branches: firebase.firestore.FieldValue.arrayUnion(branchName),
        })
        .then(() => {
          setAddingBranch(false);
          setTrigger(true);
          refreshUsers();
        });
    }
  };

  return (
    <div className={classes.root}>
      {/* <Paper className={classes.paper}> */}
      <Grid
        container
        justifyContent="space-between"
        style={{ marginBottom: 10 }}
      >
        <Grid item>
          {selected.length > 0 ? (
            <Typography
              className={classes.title}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {selected.length} selected
            </Typography>
          ) : (
            <Typography
              className={classes.title}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Select Users to Add
            </Typography>
          )}
        </Grid>
        <Grid item>
          {selected.length > 0 ? (
            <>
              {addingBranch ? (
                <CircularProgress />
              ) : (
                <Tooltip title="Save">
                  <Button
                    variant="contained"
                    style={{ background: '#163BAC', color: 'white' }}
                    startIcon={<SaveIcon />}
                    type="submit"
                    onClick={handleBranchAddition}
                  >
                    Save
                  </Button>
                </Tooltip>
              )}
            </>
          ) : (
            ''
          )}
        </Grid>
      </Grid>

      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.name);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={event => handleClick(event, row.name, row)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.name}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        color="default"
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                        onChange={() => handleRowSelected(row)}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell align="left">
                      {row?.branches?.map(item => (
                        <Chip
                          label={item}
                          style={{
                            background: '#fcd21f',
                            margin: 2,
                          }}
                          size="small"
                        />
                      ))}
                    </TableCell>
                    <TableCell align="left">
                      {row.roles.map(item =>
                        item ? (
                          <Chip
                            label={item}
                            variant="outlined"
                            size="small"
                            style={{ margin: 2 }}
                          />
                        ) : (
                          ''
                        )
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            {/* {emptyRows > 0 && (
              <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )} */}
          </TableBody>
        </Table>
      </TableContainer>
      <AlertMessageBar
        trigger={trigger}
        message={`users added to ${branchName} successfully`}
        closeBar={val => setTrigger(val)}
      />
    </div>
  );
}
