/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import logo from 'assets/img/Lobb-Logo.png';

// @material-ui/icons
import Dashboard from '@material-ui/icons/Dashboard';
import Menu from '@material-ui/icons/Menu';

// core components
import Button from 'components/CustomButtons/Button';
import Cookies from 'universal-cookie';

import pagesRoutes from 'routes/pages.jsx';

import pagesHeaderStyle from 'assets/jss/material-dashboard-pro-react/components/pagesHeaderStyle.jsx';
import firebase from '../../config/config';

const cookies = new Cookies();
const auditLog = firebase.functions().httpsCallable('utilities-auditLog');

class PagesHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isAuthenticated: false,
    };
    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    // eslint-disable-next-line no-shadow
    const db = firebase.firestore();
    const usersRef = db.collection('Users');
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        // Check if user already exists
        usersRef
          .doc(user.uid)
          .get()
          .then(userDoc => {
            if (userDoc.exists) {
              cookies.set('userName', userDoc.data().name);
              this.setState({ isAuthenticated: true });
            }
          });
      } else {
        // if not logged go to login screen
        // this.props.history.push("/pages/login-page");
      }
    });
  }

  handleDrawerToggle = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  };

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    // eslint-disable-next-line react/destructuring-assignment
    return this.props.location.pathname.indexOf(routeName) > -1;
  }

  // eslint-disable-next-line react/sort-comp
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ open: false });
    }
  }

  /**
   * Will Logout
   * @function
   */
  logout() {
    const { systemDetails } = this.props;
    const appUser = firebase.auth().currentUser;
    const auditBody = {
      data: {
        email: appUser.email,
        name: appUser.displayName,
        uid: appUser.uid,
      },
      action: 'Login',
      updatedBy: appUser.uid,
      systemDetails,
      message: `${appUser.email} logged in.`,
      collection: 'Users',
    };
    auditLog(auditBody);
    cookies.remove('token');
    firebase.auth().signOut();
    this.setState({
      isAuthenticated: false,
    });
    // eslint-disable-next-line react/destructuring-assignment
    this.props.history.push('/pages/initial-page');
  }

  render() {
    const { classes, color, rtlActive, userDetails } = this.props;
    const { isAuthenticated, open } = this.state;
    const appBarClasses = cx({
      [` ${classes[color]}`]: color,
    });
    const logoMini = `${classes.logoMini} ${cx({
      [classes.logoMiniRTL]: rtlActive,
    })}`;

    const list = (
      <List className={classes.list}>
        {isAuthenticated ? (
          <React.Fragment>
            <ListItem className={classes.listItem}>
              <NavLink to="/pages/initial-page" className={classes.navLink}>
                <ListItemIcon className={classes.listItemIcon}>
                  <Dashboard />
                </ListItemIcon>
                <ListItemText
                  primary="Dashboard"
                  disableTypography
                  className={classes.listItemText}
                />
              </NavLink>
            </ListItem>
            <ListItem className={classes.listItem}>
              <Button
                color="transparent"
                className={classes.navLink}
                onClick={() => this.logout()}
              >
                Logout
              </Button>
            </ListItem>
          </React.Fragment>
        ) : (
          <ListItem className={classes.listItem}>
            <NavLink to="/pages/login-page" className={classes.navLink}>
              <ListItemIcon className={classes.listItemIcon}>
                <Dashboard />
              </ListItemIcon>
              <ListItemText
                primary="Login"
                disableTypography
                className={classes.listItemText}
              />
            </NavLink>
          </ListItem>
        )}

        {pagesRoutes.map((prop, key) => {
          if (prop.redirect) {
            return null;
          }
          const navLink =
            classes.navLink +
            cx({
              [` ${classes.navLinkActive}`]: this.activeRoute(prop.path),
            });
          return (
            // eslint-disable-next-line react/no-array-index-key
            <ListItem key={key} className={classes.listItem}>
              <NavLink to={prop.path} className={navLink}>
                <ListItemIcon className={classes.listItemIcon}>
                  <prop.icon />
                </ListItemIcon>
                <ListItemText
                  primary={prop.short}
                  disableTypography
                  className={classes.listItemText}
                />
              </NavLink>
            </ListItem>
          );
        })}
      </List>
    );
    return (
      <AppBar position="static" className={classes.appBar + appBarClasses}>
        <Toolbar className={classes.container}>
          <Hidden smDown implementation="css">
            <div className={classes.flex}>
              <div className={classes.title}>
                <a href="https://lobb.in/" className={logoMini}>
                  <img src={logo} alt="logo" className={classes.avatarImg} />
                </a>
              </div>
            </div>
          </Hidden>
          <Hidden mdUp>
            <div className={classes.flex}>
              <div className={classes.title}>
                <a href="https://lobb.in/" className={logoMini}>
                  <img src={logo} alt="logo" className={classes.avatarImg} />
                </a>
              </div>
            </div>
          </Hidden>
          <Hidden smDown implementation="css">
            {list}
          </Hidden>
          <Hidden mdUp>
            <Button
              className={classes.sidebarButton}
              color="transparent"
              justIcon
              aria-label="open drawer"
              onClick={this.handleDrawerToggle}
            >
              <Menu />
            </Button>
          </Hidden>
          <Hidden mdUp implementation="css">
            <Hidden mdUp>
              <Drawer
                variant="temporary"
                anchor="right"
                open={open}
                classes={{
                  paper: classes.drawerPaper,
                }}
                onClose={this.handleDrawerToggle}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                {list}
              </Drawer>
            </Hidden>
          </Hidden>
        </Toolbar>
      </AppBar>
    );
  }
}

PagesHeader.defaultProps = {
  // bgColor: "",
};

PagesHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  // eslint-disable-next-line react/require-default-props
  rtlActive: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  bgColor: PropTypes.oneOf(['white', 'black', 'blue']),
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
};

const mapStateToProps = state => ({
  systemDetails: state.main.systemDetails,
});

export default connect(mapStateToProps)(
  withStyles(pagesHeaderStyle)(PagesHeader)
);
