/* eslint-disable no-useless-escape */
/* eslint class-methods-use-this: ["error", { "exceptMethods": ["verifyRequired","verifyGst","verifyEmail","verifyNumber","verifyPan","verifyPhone","verifyLength"] }] */
/* eslint-disable prefer-const */
import React from 'react';
import { connect } from 'react-redux';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import PropTypes from 'prop-types';

// @material-ui/icons
import Add from '@material-ui/icons/Add';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Edit from '@material-ui/icons/Edit';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Clearfix from 'components/Clearfix/Clearfix.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import Close from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Table from 'components/Table/Table.jsx';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Danger from 'components/Typography/Danger.jsx';

import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';

import SweetAlert from 'react-bootstrap-sweetalert';

// Fireabse
import AddTruckersStyle from 'assets/jss/material-dashboard-pro-react/views/AddTruckersStyle.jsx';
import firebase from '../../../config/config';

const db = firebase.firestore();

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" {...props} ref={ref} />
));

class AdminEditTrucker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      nameState: '',

      email: '',
      emailState: '',

      contactPerson: '',
      phoneNumber: '',
      phoneNumberState: '',

      gstNumber: '',
      gstNumberState: '',
      truckTypeState: '',
      panNumber: '',
      panNumberState: '',
      isLoading: false,

      alert: null,

      accountNumber: '',
      accountNumberState: '',

      ifsc: '',
      ifscState: '',

      bankHolderName: '',
      bankHolderNameState: '',

      branchName: '',
      branchNameState: '',

      bankName: '',
      bankNameState: '',
      truckType: '',
      truckTypes: [
        '12T- Open',
        '20FT Container',
        '22FT Closed Container',
        '22FT Jcb Carrier',
        '24FT Closed Container',
        '24FT Jcb Carrier',
        '32FT MultiAxle Container',
        '32FT Single Axle Container',
        '34FT Multi Axle Container',
        '34FT Single Axle Container',
        'FTL',
        'High Bed Trailer',
        'High Bed Trailer Double Axle',
        'High Bed Trailer Multi Axle',
        'LCV Closed Container - 14FT',
        'LCV-14FT',
        'LCV-19FT',
        'LCV-7FT',
        'LCV-17FT',
        'Low Bed Trailer',
        'Low Bed Trailer Double Axle',
        'Low Bed Trailer Multi Axle',
        'LPT-19 FT',
        'LPT-19FT Closed Container',
      ],
      trucks: [],
      truckersData: [],
      trucksState: 'Please Add Truck Details',
      bankModal: false,
      truckNumber: '',
      banks: [],
      banksData: [],
      banksState: 'Please Add Bank Details',
      editBank: false,
    };

    this.change = this.change.bind(this);
    this.submitTrucker = this.submitTrucker.bind(this);
    this.validateAllFields = this.validateAllFields.bind(this);
    this.validateBankFields = this.validateBankFields.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.hideAlert = this.hideAlert.bind(this);

    this.openEditBankForm = this.openEditBankForm.bind(this);
  }

  componentDidMount() {
    const userRef = db.collection('Users');
    //  ("ACTIVE ROLE",this.props.activeRole)
    const { classes, selectedTruckerData } = this.props;
    const truckersData = [];
    const {
      name,
      contactPerson,
      phoneNumber,
      email,
      gstNumber,
      panNumber,
      trucks,
    } = selectedTruckerData;
    if (selectedTruckerData.trucks) {
      // eslint-disable-next-line guard-for-in
      for (const i in trucks) {
        truckersData.push({
          color: 'success',
          data: [trucks[i].truckNumber, trucks[i].truckType],
        });
      }
    }
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        userRef
          .doc(`${user.uid}`)
          .get()
          .then(resultData => {
            if (resultData.exists) {
              const banks = [];
              const banksData = [];
              db.collection('Truckers')
                .doc(selectedTruckerData.truckerId)
                .collection('Banks')
                .get()
                .then(snapshot => {
                  snapshot.forEach(bank => {
                    banks.push(bank.data());
                  });
                  banks.forEach((bank, i) => {
                    banksData.push({
                      color: 'success',
                      data: [
                        bank.bankHolderName,
                        bank.accountNumber,
                        bank.bankName,
                        bank.ifsc,
                        bank.bankBranchName,
                        <Button
                          color="warning"
                          className={classes.actionButton}
                          // eslint-disable-next-line react/no-array-index-key
                          key={i}
                          onClick={() => this.openEditBankForm(bank)}
                          justIcon
                        >
                          <Edit className={classes.icon} />
                        </Button>,
                      ],
                    });
                  });

                  this.setState({ banksData, banks });
                });
              this.setState({
                truckersData,
                name,
                contactPerson,
                phoneNumber,
                email,
                gstNumber,
                panNumber,
                trucks,
              });
            }
          });
      }
    });
  }

  // Will Get select Trucks form
  getTruckTypes() {
    const { classes } = this.props;
    const { truckType, truckTypes, truckTypeState } = this.state;
    return (
      <div>
        <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
            Select Truck Type
          </InputLabel>
          <Select
            MenuProps={{ className: classes.selectMenu }}
            classes={{ select: classes.select }}
            value={truckType}
            onChange={this.handleSimple}
            inputProps={{ name: 'truckType', id: 'simple-select' }}
          >
            <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
              Select Truck Type
            </MenuItem>
            {truckTypes.map((truckTypeData, i) => (
              <MenuItem
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
                value={truckTypeData}
              >
                {truckTypeData}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {truckTypeState === 'error' && (
          <Danger>Please select truck type</Danger>
        )}
      </div>
    );
  }

  // Modal Window To Add The Trucks
  getSweetAlertForm() {
    const { classes } = this.props;
    const { classicModal, truckNumber, truckNumberState } = this.state;
    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        classes={{ root: classes.center, paper: classes.modal }}
        style={{ maxWidth: '400px !important', textAlign: 'center' }}
        open={classicModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.handleClose('classicModal')}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => this.handleClose('classicModal')}
          >
            <Close className={classes.modalClose} />
          </IconButton>
          <h4 className={classes.modalTitle}>Add Truck Details</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Truck Number *"
                id="truck-number"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: event =>
                    this.change(event, 'truckNumber', 'length', 0),
                  type: 'length',
                  value: truckNumber.replace(/\s/g, ''),
                }}
                success={truckNumberState === 'success'}
                error={truckNumberState === 'error'}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              {this.getTruckTypes()}
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button color="primary" onClick={() => this.addTrucks()}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  /**
   * Will return the Table Component
   * @function
   */
  getTable() {
    const { classes } = this.props;
    const { truckersData, trucksState } = this.state;
    return (
      <Card>
        <CardHeader style={{ marginBottom: '2px', paddingBottom: '2px' }}>
          <h4>Add Trucks</h4>
        </CardHeader>
        {truckersData.length > 0 ? (
          <CardBody className={classes.customCardContentClass}>
            <Table
              hover
              tableHead={['Truck Number', 'Type']}
              tableData={truckersData}
            />
          </CardBody>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <p
              className={`${classes.textCenter} ${classes.title}`}
              style={{ textAlign: 'center', color: 'red' }}
            >
              {trucksState}
            </p>
          </div>
        )}
        <CardFooter>
          <Button
            color="info"
            onClick={() => this.handleClickOpen('classicModal')}
            className={classes.updateProfileButton}
            justIcon
          >
            <Add />
          </Button>
        </CardFooter>
      </Card>
    );
  }

  // Modal Window To Add The Bank Details
  getBankDetailsForm() {
    const { classes } = this.props;
    const {
      bankModal,
      bankHolderName,
      bankHolderNameState,
      accountNumber,
      accountNumberState,
      ifsc,
      ifscState,
      bankName,
      bankNameState,
      branchName,
      branchNameState,
      editBank,
    } = this.state;
    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        classes={{ root: classes.center, paper: classes.modal }}
        style={{ maxWidth: '400px !important', textAlign: 'center' }}
        open={bankModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.handleClose('bankModal')}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => this.handleClose('bankModal')}
          >
            <Close className={classes.modalClose} />
          </IconButton>
          <h4 className={classes.modalTitle}>Add Bank Details</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Bank Holder Name"
                id="bank Holder Name"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: event =>
                    this.change(event, 'bankHolderName', 'length', 2),
                  type: 'length',
                  value: bankHolderName,
                }}
                success={bankHolderNameState === 'success'}
                error={bankHolderNameState === 'error'}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Account Number"
                id="account-number"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: event =>
                    this.change(event, 'accountNumber', 'length', 10),
                  type: 'length',
                  value: accountNumber,
                }}
                success={accountNumberState === 'success'}
                error={accountNumberState === 'error'}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="IFSC Code"
                id="ifsc-number"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: event => this.change(event, 'ifsc', 'ifsc'),
                  type: 'ifsc',
                  value: ifsc,
                }}
                success={ifscState === 'success'}
                error={ifscState === 'error'}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Bank name"
                id="bank-name"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: event =>
                    this.change(event, 'bankName', 'length', 2),
                  type: 'length',
                  value: bankName,
                }}
                success={bankNameState === 'success'}
                error={bankNameState === 'error'}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Enter your bank branch name"
                id="branch-name"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: event =>
                    this.change(event, 'branchName', 'length', 1),
                  type: 'length',
                  value: branchName,
                }}
                success={branchNameState === 'success'}
                error={branchNameState === 'error'}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          {editBank ? (
            <Button color="primary" onClick={() => this.editBank()}>
              Submit Changes
            </Button>
          ) : (
            <Button color="primary" onClick={() => this.addBanks()}>
              Add
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }

  /**
   * Will return the Bank Details Component
   * @function
   */
  getBanks() {
    const { classes } = this.props;
    const { banksData, banksState } = this.state;

    return (
      <Card>
        <CardHeader style={{ marginBottom: '2px', paddingBottom: '2px' }}>
          <h4>Add Banks</h4>
        </CardHeader>
        {banksData.length > 0 ? (
          <CardBody className={classes.customCardContentClass}>
            <Table
              hover
              tableHead={['Name', 'Account No', 'Bank', 'Ifsc', 'Branch']}
              tableData={banksData}
            />
          </CardBody>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <p
              className={`${classes.textCenter} ${classes.title}`}
              style={{ textAlign: 'center', color: 'red' }}
            >
              {banksState}
            </p>
          </div>
        )}
        <CardFooter>
          <Button
            color="info"
            onClick={() => this.handleClickOpen('bankModal')}
            className={classes.updateProfileButton}
            justIcon
          >
            <Add />
          </Button>
        </CardFooter>
      </Card>
    );
  }

  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  // Add Trucks
  addTrucks() {
    const { trucks, truckersData, truckType } = this.state;
    let { truckNumber } = this.state;
    if (truckNumber === '') {
      // Do nothing
    } else {
      truckNumber = truckNumber.replace(/[\.@#$%^&*()+=!~`<>{}|,:]+/g, '');
      truckNumber = truckNumber.replace(/[ -,_,-]+/g, '');
    }
    if (this.verifyLength(truckType, 1)) {
      this.setState({ truckTypeState: '' });
    } else {
      this.setState({ truckTypeState: 'error' });
    }
    if (this.verifyLength(truckNumber, 1) && this.verifyLength(truckType, 1)) {
      this.setState({
        truckNumberState: 'success',
        truckTypeState: '',
      });
      trucks.push({
        truckNumber,
        truckType,
      });
      truckersData.push({
        color: 'success',
        data: [truckNumber, truckType],
      });
      this.handleClose('classicModal');
      this.setState({
        truckersData,
        trucks,
        truckNumber: '',
        truckType: '',
      });
    } else {
      this.setState({
        truckNumberState: 'error',
      });
    }
  }

  // Will Verify Bank Details
  validateBankFields() {
    const {
      accountNumber,
      bankHolderName,
      ifsc,
      bankName,
      branchName,
    } = this.state;
    let error = false;
    return new Promise((resolve, reject) => {
      // Verify Account Number
      if (this.verifyLength(accountNumber, 5)) {
        this.setState({
          accountNumberState: 'success',
        });
      } else {
        this.setState({ accountNumberState: 'error' });
        error = true;
      }

      // Verify Account Number
      if (this.verifyLength(bankHolderName, 5)) {
        this.setState({
          bankHolderNameState: 'success',
        });
      } else {
        this.setState({ bankHolderNameState: 'error' });
        error = true;
      }

      // Verify IFSC
      if (this.verifyIfsc(ifsc)) {
        this.setState({
          ifscState: 'success',
        });
      } else {
        this.setState({ ifscState: 'error' });
        error = true;
      }

      // Verify Bank Name
      if (this.verifyLength(bankName, 2)) {
        this.setState({ bankNameState: 'success' });
      } else {
        this.setState({ bankNameState: 'error' });
        error = true;
      }

      // Verify Bank Branch
      if (this.verifyLength(branchName, 2)) {
        this.setState({ branchNameState: 'success' });
      } else {
        this.setState({ branchNameState: 'error' });
        error = true;
      }

      if (!error) {
        resolve(true);
      } else {
        reject(error);
      }
    });
  }

  // Will Add Banks
  addBanks() {
    const {
      banks,
      banksData,
      accountNumber,
      bankName,
      ifsc,
      branchName,
      bankHolderName,
    } = this.state;
    this.validateBankFields().then(
      success => {
        banks.push({
          accountNumber,
          bankName,
          ifsc,
          bankBranchName: branchName,
          bankHolderName,
        });
        banksData.push({
          color: 'success',
          data: [bankHolderName, accountNumber, bankName, ifsc, branchName, ''],
        });
        this.handleClose('bankModal');
        this.setState({
          banksData,
          banks,
          accountNumber: '',
          ifsc: '',
          bankName: '',
          branchName: '',
          bankHolderName: '',
          accountNumberState: '',
          ifscState: '',
          bankNameState: '',
          branchNameState: '',
          bankHolderNameState: '',
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  openEditBankForm(bank) {
    const {
      bankHolderName,
      accountNumber,
      bankName,
      ifsc,
      bankBranchName,
      bankId,
    } = bank;

    this.setState({
      editBank: true,
      bankHolderName,
      accountNumber,
      bankName,
      ifsc,
      branchName: bankBranchName,
      bankId,
    });
    this.handleClickOpen('bankModal');
  }

  editBank() {
    const { classes } = this.props;
    const {
      bankHolderName,
      accountNumber,
      bankName,
      ifsc,
      branchName,
      bankId,
      banks,
      banksData,
    } = this.state;

    this.validateBankFields().then(
      success => {
        let index;
        banks.forEach((bank, i) => {
          if (bank.bankId === bankId) {
            index = i;
            banks[i].accountNumber = accountNumber;
            banks[i].bankName = bankName;
            banks[i].ifsc = ifsc;
            banks[i].bankBranchName = branchName;
            banks[i].bankHolderName = bankHolderName;
          }
        });
        banksData[index].data = [
          bankHolderName,
          accountNumber,
          bankName,
          ifsc,
          branchName,
          <Button
            color="warning"
            className={classes.actionButton}
            key={index}
            onClick={() =>
              this.openEditBankForm({
                bankHolderName,
                accountNumber,
                bankName,
                ifsc,
                bankId,
                bankBranchName: branchName,
              })
            }
            justIcon
          >
            <Edit className={classes.icon} />
          </Button>,
        ];

        this.handleClose('bankModal');
        this.setState({
          editBank: false,
          banksData,
          banks,
          accountNumber: '',
          ifsc: '',
          bankName: '',
          branchName: '',
          bankHolderName: '',
          accountNumberState: '',
          ifscState: '',
          bankNameState: '',
          branchNameState: '',
          bankHolderNameState: '',
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  handleClickOpen(modal) {
    let x = [];
    x[modal] = true;
    this.setState(x);
  }

  /**
   * closes the modal button
   * @param {*} modal
   */
  handleClose(modal) {
    let x = [];
    x[modal] = false;
    this.setState(x);
    this.setState({ editBank: false });
  }

  hideAlert() {
    this.setState({
      alert: null,
    });
    // eslint-disable-next-line react/destructuring-assignment
    this.props.goBack();
  }

  // function that returns true if value is there, false otherwise
  verifyRequired(value) {
    if (value) {
      return true;
    }
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  verifyIfsc(value) {
    const regIfsc = /^[A-Za-z]{4}[a-zA-Z0-9]{7}$/;
    if (regIfsc.test(value)) {
      return true;
    }
    return false;
  }

  // function that returns true if value is Valid GST, false otherwise
  verifyGst(value) {
    let gstRex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    if (gstRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    let emailRex = /[^\s@]+@[^\s@]+\.[^\s@]+/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if value contains only numbers
  verifyNumber(value) {
    let numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if value contains PanCard
  verifyPan(value) {
    let regpan = /(^([a-zA-Z]{5})([0-9]{4})([a-zA-Z]{1})$)/;
    if (regpan.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if value contains PanCard
  verifyPhone(value) {
    // eslint-disable-next-line prefer-const
    let regPhone = /^[6789]\d{9}$/;
    if (regPhone.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'required':
        if (this.verifyRequired(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'number':
        if (this.verifyNumber(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'pan':
        if (this.verifyPan(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'phone':
        if (this.verifyPhone(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'gst':
        if (this.verifyGst(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'ifsc':
        if (this.verifyIfsc(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      default:
        break;
    }
  }

  // validates all required fields and returns a promise
  validateAllFields() {
    const { name, trucks, phoneNumber, banks } = this.state;
    let error = false;
    return new Promise((resolve, reject) => {
      // Verify Name
      if (this.verifyLength(name, 1)) {
        this.setState({
          nameState: 'success',
        });
      } else {
        this.setState({
          nameState: 'error',
        });
        error = true;
      }

      if (trucks.length > 0) {
        this.setState({ trucksState: '' });
      } else {
        this.setState({ trucksState: 'Please Add Truck Details' });

        error = true;
      }

      if (banks.length > 0) {
        this.setState({ banksState: '' });
      } else {
        this.setState({ banksState: 'Please Add Bank Details' });

        error = true;
      }

      // Verify Phone Number
      if (this.verifyPhone(phoneNumber)) {
        this.setState({
          phoneNumberState: 'success',
        });
      } else {
        this.setState({
          phoneNumberState: 'error',
        });

        error = true;
      }

      if (!error) {
        resolve(true);
      } else {
        reject(error);
      }
    });
  }

  submitPendingEdit() {
    const user = firebase.auth().currentUser;
    const { selectedTruckerData } = this.props;
    const {
      name,
      email,
      contactPerson,
      phoneNumber,
      gstNumber,
      panNumber,
      trucks,
      banks,
    } = this.state;
    const truckerEditData = {
      ...selectedTruckerData,
      name,
      email,
      contactPerson,
      phoneNumber,
      gstNumber,
      panNumber,
      trucks,
      updatedBy: user.uid,
      modifiedAt: new Date(),
      banks,
    };
    const truckerRef = db.collection('Truckers');
    truckerRef
      .doc(selectedTruckerData.truckerId)
      .update({ truckerEditData, editPending: true })
      .then(userDoc => {
        this.clearForm();
        this.setState({ isLoading: false });

        // Updated Succeessfully
      });
  }

  /**
   * Submits the Trucker details to the Database
   * @function
   */
  submitTrucker() {
    const self = this;
    this.setState({
      isLoading: true,
    });
    const { selectedTruckerData } = this.props;
    const {
      name,
      email,
      contactPerson,
      phoneNumber,
      gstNumber,
      panNumber,
      trucks,
      banks,
    } = this.state;
    this.validateAllFields()
      .then(
        success => {
          if (success) {
            const user = firebase.auth().currentUser;
            const truckerRef = db.collection('Truckers');
            truckerRef
              .doc(selectedTruckerData.truckerId)
              .update({
                name,
                email,
                contactPerson,
                phoneNumber,
                gstNumber,
                panNumber,
                trucks,
                updatedBy: user.uid,
                modifiedAt: new Date(),
              })
              .then(userDoc => {
                banks.forEach(bankDetails => {
                  // eslint-disable-next-line no-prototype-builtins
                  if (bankDetails.hasOwnProperty('bankId')) {
                    db.collection('Truckers')
                      .doc(selectedTruckerData.truckerId)
                      .collection('Banks')
                      .doc(bankDetails.bankId)
                      .set(bankDetails, { merge: true });
                  } else {
                    db.collection('Truckers')
                      .doc(selectedTruckerData.truckerId)
                      .collection('Banks')
                      .add(bankDetails)
                      .then(bankDetailsDoc => {
                        db.collection('Truckers')
                          .doc(selectedTruckerData.truckerId)
                          .collection('Banks')
                          .doc(bankDetailsDoc.id)
                          .update({
                            bankId: bankDetailsDoc.id,
                          });
                      });
                  }
                });
                this.clearForm();
                self.setState({ isLoading: false });
                // Updated Succeessfully
              });
          }
        },
        err => {
          console.log(err);
          self.setState({
            isLoading: false,
          });
        }
      )
      .catch(err => {
        console.log(err);
        self.setState({
          isLoading: false,
        });
      });
  }

  /**
   * Will Clears ALl the State values after form submision
   * @function
   */
  clearForm() {
    const { classes } = this.props;
    const self = this;

    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title="Good job!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={`${classes.button} ${classes.success}`}
        >
          Trucker Edited Succeessfully
        </SweetAlert>
      ),
    });
    self.setState({
      name: '',
      nameState: '',
      email: '',
      emailState: '',
      contactPerson: '',
      contactPersonState: '',
      phoneNumber: '',
      phoneNumberState: '',
      creditLimit: '',
      creditLimitState: '',
      gstNumber: '',
      gstNumberState: '',
      panNumber: '',
      panNumberState: '',
      city: '',
      cityState: '',
      state: '',
      stateState: '',
      postalCode: '',
      postalCodeState: '',
      bankHolderName: '',
      banks: '',
      banksData: [],
      truckersData: [],
    });
  }

  render() {
    const { classes, goBack } = this.props;
    const {
      alert,
      nameState,
      name,
      contactPerson,
      phoneNumber,
      phoneNumberState,
      email,
      emailState,
      gstNumber,
      gstNumberState,
      panNumber,
      panNumberState,
      isLoading,
    } = this.state;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            {alert}
            <Card>
              <CardHeader className={`${classes.cardHeader}`} color="warning">
                <CardIcon
                  color="primary"
                  className={`${classes.cardHeader}`}
                  onClick={goBack}
                >
                  <ArrowBack />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  Add Trucker <small />
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Trucker Name *"
                      id="username"
                      formControlProps={{ fullWidth: true }}
                      success={nameState === 'success'}
                      error={nameState === 'error'}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'name', 'length', 1),
                        type: 'length',
                        value: name,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Contact Person "
                      id="contact-person"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'contactPerson', 'length', 0),
                        type: 'length',
                        value: contactPerson,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Phone Number *"
                      id="phone-number"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'phoneNumber', 'phone'),
                        type: 'phone',
                        value: phoneNumber,
                      }}
                      success={phoneNumberState === 'success'}
                      error={phoneNumberState === 'error'}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      success={emailState === 'success'}
                      error={emailState === 'error'}
                      labelText="Email address "
                      id="email-address"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event => this.change(event, 'email', 'email'),
                        type: 'email',
                        value: email,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="GST Number "
                      id="gst-number"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'gstNumber', 'gst'),
                        type: 'gst',
                        value: gstNumber,
                      }}
                      success={gstNumberState === 'success'}
                      error={gstNumberState === 'error'}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="PAN Number"
                      id="pan-number"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'panNumber', 'pan'),
                        type: 'pan',
                        value: panNumber,
                      }}
                      success={panNumberState === 'success'}
                      error={panNumberState === 'error'}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    {this.getBanks()}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={8}>
                    {this.getTable()}
                  </GridItem>
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12}>
                      {this.getBankDetailsForm()}
                    </GridItem>
                  </GridContainer>
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12}>
                      {this.getSweetAlertForm()}
                    </GridItem>
                  </GridContainer>
                </GridContainer>
                {isLoading ? (
                  <CircularProgress
                    className={classes.progress}
                    style={{ color: purple[500] }}
                    thickness={7}
                  />
                ) : (
                  <Button
                    color="warning"
                    onClick={this.submitTrucker}
                    className={classes.updateProfileButton}
                  >
                    Submit
                  </Button>
                )}
                <Clearfix />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
AdminEditTrucker.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  activeRole: state.main.activeRole,
});

export default connect(mapStateToProps)(
  withStyles(AddTruckersStyle)(AdminEditTrucker)
);
