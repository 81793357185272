/* eslint-disable react/destructuring-assignment */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components

import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CustomTabs from 'components/CustomTabs/CustomTabs.jsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TransactionStyle from 'assets/jss/material-dashboard-pro-react/views/TransactionStyle';
import AthHistory from './ReceivableAthHistory';
import BthHistory from './ReceivableBthHistory';
import { switchActivePage } from '../../../reducers/transactions';

class ReceivableHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.hideDetailedForm = this.hideDetailedForm.bind(this);
  }

  hideDetailedForm() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.switchActivePage(false);
  }

  /**
   * Will return the Tabs ATH and BTH
   * @function
   */
  // eslint-disable-next-line class-methods-use-this
  getTabs() {
    return (
      <CustomTabs
        headerColor="#fff"
        tabs={[
          {
            tabName: 'ATH',
            tabContent: (
              <AthHistory
                currentTransactionData={this.props.currentTransactionData}
              />
            ),
          },
          {
            tabName: 'BTH',
            tabContent: (
              <BthHistory
                currentTransactionData={this.props.currentTransactionData}
              />
            ),
          },
        ]}
      />
    );
  }

  render() {
    const { classes, currentReceivableAmount } = this.props;
    return (
      <Card>
        <CardHeader
          className={`${classes.cardHeader}`}
          style={{
            marginBottom: '1px',
            paddingBottom: '1px',
            paddingTop: '1px',
          }}
        >
          <h3 className={classes.cardTitle}>
            Receivable:{' '}
            <i
              className="fa fa-inr"
              style={{ fontSize: '22px' }}
              aria-hidden="true"
            />{' '}
            {Intl.NumberFormat('en-IN').format(currentReceivableAmount)}
            <small />
          </h3>
        </CardHeader>
        <CardBody
          style={{
            marginBottom: '1px',
            paddingBottom: '1px',
            paddingTop: '1px',
          }}
        >
          {this.getTabs()}
        </CardBody>
      </Card>
    );
  }
}

ReceivableHistory.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  isDetailedPage: state.transactions.isDetailedPage,
  // currentTransactionData: state.transactions.currentTransactionData,
  transactions: state.transactions.transactions,
  payableTransactions: state.transactions.payableTransactions,
  currentReceivableAmount: state.transactions.currentReceivableAmount,
  activeRole: state.main.activeRole,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      switchActivePage,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(TransactionStyle)(ReceivableHistory));
