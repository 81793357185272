import Truckers from 'views/Truckers/Truckers.jsx';
import AddTruckers from 'views/Truckers/AddTruckers.jsx';
import Transporters from 'views/Transporters/Transporters.jsx';
import AddTransporters from 'views/Transporters/AddTransporters.jsx';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import Transactions from 'views/Transactions/SalesTransactions.jsx';
import OutstandingList from 'views/Outstandings/OutstandingList.jsx';

import pendingEntries from 'views/SalesVerifyEntries/pendingEntries.jsx';

import AddTransactions from 'views/Transactions/AddTransactions.jsx';

import TransferWithinAStation from '@material-ui/icons/TransferWithinAStation';
import LocalShipping from '@material-ui/icons/LocalShipping';
import SwapHoriz from '@material-ui/icons/SwapHoriz';

// @material-ui/icons
import DashboardIcon from '@material-ui/icons/Dashboard';
import PlaylistAddCheck from '@material-ui/icons/PlaylistAddCheck';
import PODAgeingView from '../views/PODAgeing/PODAgeingView';
import TransporterOutstandingReports from '../views/Outstanding Reports/TransporterOutstanding/TransporterOutstandingReports';
import DashboardRedirect from '../views/Dashboard/DashboardRedirect';

const salesDashRoutes = [
  {
    path: '/sales/dashboard',
    name: 'Dashboard',
    icon: DashboardIcon,
    component: DashboardRedirect,
  },
  {
    collapse: true,
    path: '/sales/transporters',
    name: 'Transporters',
    state: 'openTransporters',
    icon: TransferWithinAStation,
    views: [
      {
        path: '/sales/transporters/view',
        name: 'View',
        mini: 'V',
        component: Transporters,
      },
      {
        path: '/sales/transporters/add',
        name: 'Add',
        mini: 'A',
        component: AddTransporters,
      },
    ],
  },
  {
    collapse: true,
    path: '/sales/truckers',
    name: 'Truckers',
    icon: LocalShipping,
    state: 'openTruckers',
    views: [
      {
        path: '/sales/truckers/view',
        name: 'View',
        mini: 'V',
        component: Truckers,
      },
      {
        path: '/sales/truckers/add',
        name: 'Add',
        mini: 'A',
        component: AddTruckers,
      },
    ],
  },
  {
    collapse: true,
    path: '/sales/transactions',
    name: 'Transactions',
    icon: SwapHoriz,
    state: 'openTransactions',
    views: [
      {
        path: '/sales/transactions/view',
        name: 'View',
        mini: 'V',
        component: Transactions,
      },
      {
        path: '/sales/transactions/add',
        name: 'Add',
        mini: 'A',
        component: AddTransactions,
      },
    ],
  },
  // VERIFiCATION ABILITY FOR SALES REMOVED OFF
  // {
  //   path: '/sales/verify-entries',
  //   name: 'Verify',
  //   icon: PlaylistAddCheck,
  //   component: pendingEntries,
  // },
  // {
  //   collapse: true,
  //   path: '/sales/outstanding',
  //   name: 'Reports',
  //   icon: SwapHoriz,
  //   state: 'openOutstandings',
  //   views: [
  //     {
  //       path: '/sales/outstanding/transporter',
  //       name: 'Customer Outstanding',
  //       mini: 'C',
  //       component: OutstandingList,
  //     },
  //   ],
  // },
  {
    redirect: true,
    path: '/sales',
    pathTo: '/sales/dashboard',
    name: 'Dashboard',
  },
];

const salesManagerDashRoutes = [
  {
    path: '/sales/dashboard',
    name: 'Dashboard',
    icon: DashboardIcon,
    component: DashboardRedirect,
  },
  {
    collapse: true,
    path: '/sales/transporters',
    name: 'Transporters',
    state: 'openTransporters',
    icon: TransferWithinAStation,
    views: [
      {
        path: '/sales/transporters/view',
        name: 'View',
        mini: 'V',
        component: Transporters,
      },
      {
        path: '/sales/transporters/add',
        name: 'Add',
        mini: 'A',
        component: AddTransporters,
      },
    ],
  },
  {
    collapse: true,
    path: '/sales/truckers',
    name: 'Truckers',
    icon: LocalShipping,
    state: 'openTruckers',
    views: [
      {
        path: '/sales/truckers/view',
        name: 'View',
        mini: 'V',
        component: Truckers,
      },
      {
        path: '/sales/truckers/add',
        name: 'Add',
        mini: 'A',
        component: AddTruckers,
      },
    ],
  },
  {
    collapse: true,
    path: '/sales/transactions',
    name: 'Transactions',
    icon: SwapHoriz,
    state: 'openTransactions',
    views: [
      {
        path: '/sales/transactions/view',
        name: 'View',
        mini: 'V',
        component: Transactions,
      },
      {
        path: '/sales/transactions/add',
        name: 'Add',
        mini: 'A',
        component: AddTransactions,
      },
    ],
  },
  {
    path: '/sales/verify-entries',
    name: 'Verify',
    icon: PlaylistAddCheck,
    component: pendingEntries,
  },
  {
    collapse: true,
    path: '/sales/outstanding',
    name: 'Reports',
    icon: AccountBalanceWalletIcon,
    state: 'openOutstandings',
    views: [
      {
        path: '/sales/outstanding/transporter',
        name: 'Customer Statement',
        mini: 'C',
        component: OutstandingList,
      },

      {
        path: '/sales/podAgeing',
        name: 'POD Ageing',
        mini: 'P',
        component: PODAgeingView,
      },
      {
        path: '/sales/transporterOutstanding',
        name: 'Transporter Outstanding',
        mini: 'T O',
        component: TransporterOutstandingReports,
      },
    ],
  },
  {
    redirect: true,
    path: '/sales',
    pathTo: '/sales/dashboard',
    name: 'Dashboard',
  },
];

export default salesDashRoutes;

export { salesManagerDashRoutes };
