/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable class-methods-use-this */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
// import { Manager, Target, Popper } from 'react-popper';
import Popper from '@material-ui/core/Popper';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import { connect } from 'react-redux';
// import { createBrowserHistory } from "history";
// @material-ui/icons
import Person from '@material-ui/icons/Person';
// import Notifications from "@material-ui/icons/Notifications";

// core components
import Button from 'components/CustomButtons/Button.jsx';
import firebase from 'firebase/app';

import headerLinksStyle from 'assets/jss/material-dashboard-pro-react/components/headerLinksStyle';
import Cookies from 'universal-cookie';

import history from '../../history.js';

const cookies = new Cookies();

const auditLog = firebase.functions().httpsCallable('auditLog');

class HeaderLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.logOut = this.logOut.bind(this);
  }

  state = {
    open: false,
  };

  handleClick = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  /**
   * phone auth logout
   * @function
   */
  logOut() {
    // var _me = this
    const { systemDetails } = this.props;
    const user = firebase.auth().currentUser;
    const auditBody = {
      data: {
        email: user.email,
        name: user.displayName,
        uid: user.uid,
      },
      updatedBy: user.uid,
      action: 'Logout',
      systemDetails,
      message: `${user.email} logged out.`,
      collection: 'Users',
    };
    auditLog(auditBody);
    firebase
      .auth()
      .signOut()
      .then(result => {
        cookies.remove('token');
        // Sign-out successful.
        history.push('/pages/login-page');
      })
      .catch(error => {
        // An error happened.
      });
  }

  render() {
    const { classes, rtlActive } = this.props;
    const { open } = this.state;
    const dropdownItem = `${classes.dropdownItem} ${classNames({
      [classes.dropdownItemRTL]: rtlActive,
    })}`;
    const wrapper = classNames({
      [classes.wrapperRTL]: rtlActive,
    });
    return (
      <div className={wrapper}>
        <Button
          color="transparent"
          justIcon
          aria-label="Notifications"
          aria-owns={open ? 'notification-menu-list' : null}
          aria-haspopup="true"
          onClick={this.handleClick}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : '',
          }}
          buttonRef={node => {
            this.anchorNotification = node;
          }}
        >
          <Person />
        </Button>
        <Popper
          open={open}
          anchorEl={this.anchorNotification}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !open,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list"
              style={{ transformOrigin: '0 0 0' }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <MenuList role="menu">
                    <MenuItem onClick={this.logOut} className={dropdownItem}>
                      Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

HeaderLinks.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  // eslint-disable-next-line react/require-default-props
  rtlActive: PropTypes.bool,
};

const mapStateToProps = state => ({
  systemDetails: state.main.systemDetails,
});

export default connect(mapStateToProps)(
  withStyles(headerLinksStyle)(HeaderLinks)
);
