/* eslint-disable no-nested-ternary */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@material-ui/core';
import swal from 'sweetalert';
import firebase from '../../../../config/config';

const buttonStyles = {
  background: '#3366FF',
  color: 'white',
  fontSize: '12px',
  textTransform: 'capitalize',
};

const editButtonStyles = {
  background: '#AFB2B7',
  color: 'black',
  fontSize: '12px',
  textTransform: 'capitalize',
};
const BalancePaymentTerms = ({ activeRole, selectedTransporterData }) => {
  const db = firebase.firestore();
  const userDetails = firebase.auth().currentUser;
  const auditLog = firebase.functions().httpsCallable('utilities-auditLog');
  const [isToBeEdited, setIstoBeEdited] = React.useState(
    selectedTransporterData?.onAccount
  );
  const [onAccount, setOnAccount] = React.useState(
    selectedTransporterData?.onAccount
  );
  const [updating, setUpdating] = React.useState(false);

  const handleTextChange = e => {
    const regex = /^[0-9]*$/;
    if (regex.test(e.target.value)) {
      setOnAccount(e.target.value);
    }
  };

  const handleSubmitOnAccount = () => {
    setUpdating(true);
    const transporterRef = db
      .collection('Transporters')
      .doc(selectedTransporterData.transporterId);

    return transporterRef
      .update({
        onAccount,
        modifiedAt: new Date(),
      })
      .then(() => {
        setUpdating(false);
        swal(
          'Success!',
          `You have set ${onAccount} as On Account for  ${selectedTransporterData?.name} `,
          'success'
        );
        setIstoBeEdited(true);
        const auditBody = {
          collection: 'Transporters',
          numberOfLocationsSetBy: userDetails?.uid,
          type: 'Updated On Account in Transporters',
          message: `${userDetails?.email} in Transporters collection inside ${selectedTransporterData?.transporterId} set on Account to ${onAccount} `,
          uploadedAt: new Date(),
        };
        auditLog(auditBody);
      })
      .catch(error => {
        // The document probably doesn't exist.
        setUpdating(false);
        swal('Error!', `Something went wrong`, 'error');
      });
  };

  return (
    <div>
      <Typography
        variant="subtitle2"
        gutterBottom
        style={{ textTransform: 'capitalize', marginBottom: '10px' }}
      >
        On Account
      </Typography>
      <Grid
        container
        spacing={1}
        direction="row"
        alignItems="center"
        flexWrap="wrap"
      >
        <Grid item xs={activeRole === 'ops' ? 10 : 12}>
          {activeRole === 'sales' ||
          activeRole === 'branch-ops' ||
          activeRole === 'traffic' ? (
            <TextField
              size="small"
              id="outlined-basic"
              variant="outlined"
              fullWidth
              value={onAccount}
              disabled
            />
          ) : (
            <TextField
              size="small"
              id="outlined-basic"
              variant="outlined"
              value={onAccount}
              fullWidth
              disabled={isToBeEdited}
              onChange={handleTextChange}
            />
          )}
        </Grid>
        {activeRole === 'ops' ? (
          <Grid item xs={2}>
            {isToBeEdited ? (
              <Button
                size="small"
                variant="contained"
                style={editButtonStyles}
                onClick={() => {
                  setIstoBeEdited(false);
                }}
              >
                Edit
              </Button>
            ) : updating ? (
              <CircularProgress size={20} />
            ) : (
              <Button
                size="small"
                variant="contained"
                style={buttonStyles}
                onClick={handleSubmitOnAccount}
                disabled={!onAccount}
              >
                Submit
              </Button>
            )}
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
};

export default BalancePaymentTerms;
