import { Typography } from '@material-ui/core';
import React from 'react';

const SummaryHighlights = ({ summaryHighlights }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      padding: '20px 10px',
      borderBottom: '1px solid lightgray',
    }}
  >
    {summaryHighlights.map(highlight => (
      <div key={highlight.label} style={highlight.style}>
        <Typography variant="caption" gutterBottom>
          {highlight?.label}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {highlight?.value}
        </Typography>
      </div>
    ))}
  </div>
);

export default SummaryHighlights;
