/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import uploadIcon from 'assets/icons/upload-icon.svg';
import moment from 'moment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {
  Button,
  CircularProgress,
  IconButton,
  Popover,
  Typography,
  makeStyles,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Popup from 'reactjs-popup';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import myFirebase from '../../../config/config';
import {
  fileUploader,
  getSignedURL,
  getViewURL,
} from '../../../utils/fileUploadHandler';

const useStyles = makeStyles({
  box: {
    borderRadius: '5px',
    textAlign: 'left',
    border: '2px dashed',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    // padding: '10px',
  },
  viewButton: {
    background: '#52BE56',
    color: 'white',
    margin: '2px 20px 0px 0px',
    padding: '5px 42px 5px 10px',
  },
  nameTabs: {
    display: 'flex',
    flexDirection: 'column',
    // marginTop: '13px',
  },
  promptBox: {
    display: 'flex',
    // flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  alert: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    border: '2px dashed lightgray',
    borderRadius: '5px',
    margin: '14px 20px',
  },
  deleteModalButton: {
    width: '45%',
    background: '#52BE56',
    borderRadius: 0,
    color: 'white',
    margin: '5px',
  },
  cancelButton: {
    width: '45%',
    background: 'lightgray',
    borderRadius: 0,
    margin: '5px',
  },
});
const contentStyle = { width: '24%', zIndex: 500 };
const uploadContainerStyles = {
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
const fileUploaderStyles = {
  border: '1px dashed lightgray',
  width: '100%',
  padding: '10px',
  borderRadius: '5px',
};
const TruckFilesUploader = ({
  userDetails,
  truckNumber,
  type,
  rcFileURLState,
  truckerId,
  rcFileUploaded,
  featureFlagFiles,
  //   url,
}) => {
  const db = myFirebase.firestore();
  const [uploading, setUploading] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [url, setUrl] = React.useState('');
  const auditLog = myFirebase.functions().httpsCallable('utilities-auditLog');
  const classes = useStyles();

  const handleFilesUploadToS3 = UploadedFile => {
    const body = {
      scope: 'truck',
      type: 'signed_url',
      file_type:
        type === 'RC'
          ? 'truck-rc'
          : type === 'Fitness Certificate'
          ? 'truck-fitness'
          : type === 'Insurance'
          ? 'truck-insurance'
          : '',
      trucker_id: truckerId,
      truck_number: truckNumber,
    };
    getSignedURL(body, UploadedFile)
      .then(response => {
        const { url: signedURL } = response?.data;
        fileUploader(signedURL, UploadedFile)
          .then(res => {
            if (res === 'success') {
              const viewURLBody = {
                scope: 'truck',
                type: 'view',
                file_type:
                  type === 'RC'
                    ? 'truck-rc'
                    : type === 'Fitness Certificate'
                    ? 'truck-fitness'
                    : type === 'Insurance'
                    ? 'truck-insurance'
                    : '',
                trucker_id: truckerId,
                truck_number: truckNumber,
              };

              setTimeout(() => {
                getViewURL(viewURLBody)
                  .then(result => {
                    setUrl(result?.data?.url);
                    setUploading(false);

                    if (type === 'RC') {
                      rcFileUploaded(true);
                    }
                  })
                  .catch(e => setUploading(false));
              }, 4000);
            }
          })
          .catch(e => {
            setUploading(false);
            rcFileUploaded(false);
          });
      })
      .catch(e => setUploading(false));
  };
  const onDrop = useCallback(
    async acceptedFiles => {
      // Do something with the files
      const UploadedFile = acceptedFiles[0];
      setUploading(true);
      // console.log(truckerId);
      if (type === 'RC') {
        if (featureFlagFiles === 2) {
          handleFilesUploadToS3(UploadedFile);
        } else {
          const podRef = await db.collection('FileUploads');
          // Do something with the files
          const storageRef = myFirebase
            .storage()
            .ref(`Truck_Files/${truckerId}/${truckNumber}/RC`);

          const task = storageRef.put(UploadedFile);
          task.on(
            'state_changed',
            snapshot => {
              const percentage =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              if (percentage === 100) {
                // console.log('SNAPSHOT', snapshot.metadata());
              }
            },
            error => {
              console.log(error);
            },
            () => {
              // Handle successful uploads on complete
              // For instance, get the download URL: https://firebasestorage.googleapis.com/...
              task.snapshot.ref.getDownloadURL().then(downloadURL => {
                setUrl(downloadURL);

                //   getFileURL(downloadURL);
                try {
                  const metaData = {};
                  metaData[truckNumber] = {
                    rcUpdatedByName: userDetails.name,
                    rcUpdatedByUid: userDetails.uid,
                    rcUpdatedAT: moment().format('llll'),
                    rcFileUploadURL: downloadURL,
                    VehicleNumber: truckNumber,
                    orgId: truckerId,
                  };
                  podRef
                    .doc('Truck_Files')
                    .collection('RC')
                    .doc(truckerId)
                    .set(metaData, { merge: true })
                    .catch(error => {
                      console.error('Error adding document: ', error);
                    });
                  rcFileUploaded(true);
                } catch (error) {
                  console.log(error);
                }
                const auditBody = {
                  // data: { ...transaction },
                  collection: 'file uploads',
                  podFileUploadedBy: userDetails.uid,
                  type: 'Uploaded RC File in Transactions',
                  message: `${userDetails.email} in file uploads collection inside truckFiles doc inside RC collection uploaded the file `,
                  uploadedAt: moment().format('llll'),
                };
                auditLog(auditBody);
                setUploading(false);
              });
            }
          );
        }
      }
      if (type === 'Fitness Certificate') {
        if (featureFlagFiles === 2) {
          handleFilesUploadToS3(UploadedFile);
        } else {
          const podRef = await db.collection('FileUploads');
          // Do something with the files
          const storageRef = myFirebase
            .storage()
            .ref(`Truck_Files/${truckerId}/${truckNumber}/FitnessCertificate`);

          const task = storageRef.put(UploadedFile);
          task.on(
            'state_changed',
            snapshot => {
              const percentage =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              if (percentage === 100) {
                // console.log('SNAPSHOT', snapshot.metadata());
              }
            },
            error => {
              console.log(error);
            },
            () => {
              // Handle successful uploads on complete
              // For instance, get the download URL: https://firebasestorage.googleapis.com/...
              task.snapshot.ref.getDownloadURL().then(downloadURL => {
                setUrl(downloadURL);

                try {
                  const metaData = {};
                  metaData[truckNumber] = {
                    FitnessCertificateUpdatedByName: userDetails?.name,
                    FitnessCertificateUpdatedByUid: userDetails?.uid,
                    FitnessCertificateUpdatedAT: moment().format('llll'),
                    FitnessCertificateFileUploadURL: downloadURL,
                    VehicleNumber: truckNumber,
                    orgId: truckerId,
                  };
                  podRef
                    .doc('Truck_Files')
                    .collection('FitnessCertificate')
                    .doc(truckerId)
                    .set(metaData, { merge: true });
                } catch (error) {
                  console.log(error);
                }
                const auditBody = {
                  // data: { ...transaction },
                  collection: 'file uploads',
                  podFileUploadedBy: userDetails.uid,
                  type: 'Uploaded Fitness Certificate File in Transactions',
                  message: `${userDetails.email} in file uploads collection inside truckFiles doc inside Fitness Certificate collection uploaded the file `,
                  uploadedAt: moment().format('llll'),
                };
                auditLog(auditBody);
                setUploading(false);
              });
            }
          );
        }
      }

      if (type === 'Insurance') {
        if (featureFlagFiles === 2) {
          handleFilesUploadToS3(UploadedFile);
        } else {
          const podRef = await db.collection('FileUploads');
          // Do something with the files
          const storageRef = myFirebase
            .storage()
            .ref(`Truck_Files/${truckerId}/${truckNumber}/Insurance`);

          const task = storageRef.put(UploadedFile);
          task.on(
            'state_changed',
            snapshot => {
              const percentage =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              if (percentage === 100) {
                // console.log('SNAPSHOT', snapshot.metadata());
              }
            },
            error => {
              console.log(error);
            },
            () => {
              // Handle successful uploads on complete
              // For instance, get the download URL: https://firebasestorage.googleapis.com/...
              task.snapshot.ref.getDownloadURL().then(downloadURL => {
                setUrl(downloadURL);
                try {
                  const metaData = {};
                  metaData[truckNumber] = {
                    InsuranceUpdatedByName: userDetails.name,
                    InsuranceUpdatedByUid: userDetails.uid,
                    InsuranceUpdatedAT: moment().format('llll'),
                    InsuranceFileUploadURL: downloadURL,
                    VehicleNumber: truckNumber,
                    orgId: truckerId,
                  };
                  podRef
                    .doc('Truck_Files')
                    .collection('Insurance')
                    .doc(truckerId)
                    .set(metaData, { merge: true });
                } catch (error) {
                  console.log(error);
                }
                const auditBody = {
                  // data: { ...transaction },
                  collection: 'file uploads',
                  podFileUploadedBy: userDetails.uid,
                  type: 'Uploaded Insurance File in FileUploads',
                  message: `${userDetails.email} in file uploads collection inside Truck_Files doc inside Insurance collection  uploaded the file `,
                  uploadedAt: moment().format('llll'),
                };
                auditLog(auditBody);
                setUploading(false);
              });
            }
          );
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [truckNumber]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled: !truckNumber && true,
    acceptedFiles: {
      'image/png': ['.png', '.jpeg', '.jpg'],
      'application/pdf': ['.pdf'],
    },
  });

  async function deleteFileUploaded() {
    setDeleting(true);
    if (type === 'RC') {
      const fileRef = myFirebase
        .storage()
        .ref(`Truck_Files/${truckerId}/${truckNumber}/RC`);

      fileRef.delete().then(() => {
        const podRef = db
          .collection('FileUploads')
          .doc('Truck_Files')
          .collection('RC')
          .doc(truckerId);
        podRef
          .get()
          .then(doc => {
            if (doc.exists) {
              const files = doc.data();
              delete files[truckNumber];
              podRef.set(files);
            }
          })
          .catch(error => {
            console.log('Error getting document:', error);
          });
        const auditBody = {
          collection: 'file uploads',
          adhaarFileDeletedBy: userDetails.uid,
          type: 'Deleted Adhaar File in Truckers',
          message: `${userDetails.email} in file uploads collection inside Truckers file doc inside Adhaar collection with ${truckerId} Truckers ID deleted the file `,
          deletedAt: new Date(),
        };
        auditLog(auditBody);
        setUrl('');
        setDeleting(false);
        rcFileUploaded(null);
      });
    }
    if (type === 'Fitness Certificate') {
      const fileRef = myFirebase
        .storage()
        .ref(`Truck_Files/${truckerId}/${truckNumber}/FitnessCertificate`);

      fileRef.delete().then(() => {
        const podRef = db
          .collection('FileUploads')
          .doc('Truck_Files')
          .collection('FitnessCertificate')
          .doc(truckerId);
        podRef
          .get()
          .then(doc => {
            if (doc.exists) {
              const files = doc.data();
              delete files[truckNumber];
              podRef.set(files);
            }
          })
          .catch(error => {
            console.log('Error getting document:', error);
          });
        const auditBody = {
          collection: 'file uploads',
          adhaarFileDeletedBy: userDetails.uid,
          type: 'Deleted Adhaar File in Truckers',
          message: `${userDetails.email} in file uploads collection inside Truckers file doc inside Adhaar collection with ${truckerId} Truckers ID deleted the file `,
          deletedAt: new Date(),
        };
        auditLog(auditBody);
        setUrl('');
        setDeleting(false);
      });
    }
    if (type === 'Insurance') {
      const storageRef = myFirebase
        .storage()
        .ref(`Truck_Files/${truckerId}/${truckNumber}/Insurance`)
        .child(truckNumber);

      storageRef.delete().then(() => {
        const podRef = db
          .collection('FileUploads')
          .doc('Truck_Files')
          .collection('Insurance')
          .doc(truckerId);
        podRef
          .get()
          .then(doc => {
            if (doc.exists) {
              const files = doc.data();
              delete files[truckNumber];
              podRef.set(files);
            }
          })
          .catch(error => {
            console.log('Error getting document:', error);
          });
        const auditBody = {
          // data: { ...transaction },
          collection: 'file uploads',
          adhaarFileDeletedBy: userDetails.uid,
          type: 'Deleted Insurance File in Truckers',
          message: `${userDetails.email} in file uploads collection inside Truckfiles doc inside Insurance collection with ${truckerId} Truckers ID deleted the file `,
          deletedAt: new Date(),
        };
        auditLog(auditBody);
        setUrl('');
        setDeleting(false);
      });
    }
  }

  return (
    <div>
      {url !== '' ? (
        <div
          style={{
            display: 'flex',
            // flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
            }}
          >
            <Typography variant="body2" color="lightgray">
              {type}
            </Typography>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <a
                href={url}
                target="_blank"
                style={{ color: 'white' }}
                rel="noreferrer"
              >
                <Button variant="contained" color="primary" disableElevation>
                  <VisibilityIcon style={{ fontSize: '16px' }} />
                </Button>
              </a>
              <div>
                <IconButton
                  onClick={handleClick}
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <DeleteForeverIcon
                    // onClick={() => setView(true)}
                    color="error"
                  />
                </IconButton>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <div style={{ padding: '10px', width: '300px' }}>
                    <div
                      style={{
                        padding: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <HighlightOffIcon fontSize="large" color="error" />
                      <h4 style={{ fontWeight: 'bold' }}>Are You Sure?</h4>
                      <p>If you proceed, you will not be able to recover it</p>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '100%',
                          justifyContent: 'center',
                        }}
                      >
                        <Button
                          onClick={handleClose}
                          className={classes.cancelButton}
                        >
                          No
                        </Button>
                        <Button
                          onClick={() => deleteFileUploaded()}
                          className={classes.deleteModalButton}
                        >
                          {deleting && <CircularProgress size={20} />}
                          Yes
                        </Button>
                      </div>
                    </div>
                  </div>
                </Popover>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={uploadContainerStyles}>
          <div {...getRootProps()} style={fileUploaderStyles}>
            <input {...getInputProps()} />
            {uploading ? (
              <CircularProgress size={20} />
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: 'space-evenly',
                }}
              >
                <img src={uploadIcon} style={{ width: '30px' }} alt="icon" />
                {isDragActive ? (
                  <p style={{ margin: 0 }}>Drop the files here ...</p>
                ) : (
                  <p
                    style={
                      rcFileURLState === 'error' && type === 'RC'
                        ? { margin: 0, color: 'red' }
                        : { margin: 0 }
                    }
                  >
                    Upload {type}
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TruckFilesUploader;
