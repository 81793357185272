/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';

import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import loginPageStyle from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx';
import firebase from 'firebase';
import myFirebase from '../../config/config';
import auditLogger from '../../utils/auditLoggerToPG';

//  Initalize firestore reference
const db = myFirebase.firestore();
const usersRef = db.collection('Users');

const auditLog = firebase.functions().httpsCallable('utilities-auditLog');

class LoginPage extends React.Component {
  constructor(props) {
    const { systemDetails } = props;
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: 'cardHidden',
    };
    // firebase ui for phone authentication
    this.uiConfig = {
      // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
      signInFlow: 'popup',
      // signInSuccessUrl: '/pages/initial-page', // We will display Google as auth provider.
      signInOptions: [
        {
          provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          // signInSuccessUrl: '/initial-page',
        },
      ],
      callbacks: {
        // This is called upon successful login // 'audio' // 'invisible' or 'compact' // ' bottomright' or 'inline' applies to invisible.
        signInSuccessWithAuthResult(authResult) {
          const appUser = authResult.user;
          const auditBody = {
            data: {
              email: appUser.email,
              name: appUser.displayName,
              uid: appUser.uid,
            },
            action: 'Log In',
            updatedBy: appUser.uid,
            message: `${appUser.email} logged in.`,
            collection: 'Users',
          };

          auditLog(auditBody);

          usersRef
            .doc(authResult.user.uid)
            .get()
            .then(user => {
              if (user.exists) {
                props.history.push('/pages/initial-page');
              } else {
                props.history.push('/pages/initial-page');
              }
            });
          // Do something with the returned AuthResult.
          // const userDetails = { phoneNumber: authResult.user.phoneNumber, uid: authResult.user.uid };
          // after successfully login check if the user is present in the db or not
          // Here Check if user present in the database or not and then check the screen

          // Return type determines whether we continue the redirect automatically
          // or whether we leave that to developer to handle.
          return true;
        },
      },
    };
  }

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(() => {
      this.setState({ cardAnimaton: '' });
    }, 700);
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.content}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={6} md={4}>
              <form>
                <Card login className={classes[this.state.cardAnimaton]}>
                  <CardHeader
                    className={`${classes.cardHeader} ${classes.textCenter}`}
                    color="yellow"
                  >
                    <h4 className={classes.cardTitle}>Log in</h4>
                  </CardHeader>
                  <CardBody>
                    <StyledFirebaseAuth
                      uiConfig={this.uiConfig}
                      firebaseAuth={firebase.auth()}
                    />
                  </CardBody>
                  <CardFooter className={classes.justifyContentCenter} />
                </Card>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  systemDetails: state.main.systemDetails,
});

export default connect(mapStateToProps)(withStyles(loginPageStyle)(LoginPage));
