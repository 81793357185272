import Transactions from 'views/Transactions/FinanceTransactions.jsx';
// @material-ui/icons
import DashboardIcon from '@material-ui/icons/Dashboard';

const transactionViewDashRoutes = [
  {
    path: '/transaction-view/transactions',
    name: 'Transactions',
    icon: DashboardIcon,
    component: Transactions,
  },
];
export default transactionViewDashRoutes;
