import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Skeleton } from '@material-ui/lab';
import { Paper } from '@material-ui/core';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    cursor: 'pointer',
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({}))(TableRow);

function createData(name, first, second, third, moreThan) {
  return { name, first, second, third, moreThan };
}

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  tableContainer: {
    minHeight: '500px',
  },
});

export default function TableView({
  active,
  data,
  fetchingData,
  tableHeaders,
  toggleDrillDown,
}) {
  const classes = useStyles();
  const rows =
    active === 'days'
      ? data.map(ageing =>
          createData(
            ageing?.branch,
            ageing?.first_week || 0,
            ageing?.second_week || 0,
            ageing?.third_week || 0,
            ageing?.fourth_week || 0
          )
        )
      : data.map(ageing =>
          createData(
            ageing?.branch,
            ageing?.month_0_to_3 || 0,
            ageing?.month_3_to_6 || 0,
            ageing?.month_6_to_9 || 0,
            ageing?.month_greater_than_9 || 0
          )
        );

  return (
    <div>
      {fetchingData ? (
        <>
          <Skeleton variant="text" />
          <Skeleton variant="rect" height={118} />
        </>
      ) : (
        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                {tableHeaders.map(header => (
                  <StyledTableCell
                    align={header === 'Branch' ? 'left' : 'right'}
                  >
                    {header}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <StyledTableRow key={row.name}>
                  <StyledTableCell component="th" scope="row">
                    {row.name}
                  </StyledTableCell>
                  <StyledTableCell
                    align="right"
                    style={{ color: '#163BAC' }}
                    onClick={() => {
                      if (row.first !== 0) {
                        toggleDrillDown(
                          row,
                          active === 'days' ? 'firstWeek' : '0-3'
                        );
                      }
                    }}
                  >
                    {row.first}
                  </StyledTableCell>
                  <StyledTableCell
                    align="right"
                    style={{ color: '#670EAB' }}
                    onClick={() => {
                      if (row.second !== 0) {
                        toggleDrillDown(
                          row,
                          active === 'days' ? 'secondWeek' : '3-6'
                        );
                      }
                    }}
                  >
                    {row.second}
                  </StyledTableCell>
                  <StyledTableCell
                    align="right"
                    style={{ color: '#FF542F' }}
                    onClick={() => {
                      if (row.third !== 0) {
                        toggleDrillDown(
                          row,
                          active === 'days' ? 'thirdWeek' : '6-9'
                        );
                      }
                    }}
                  >
                    {row.third}
                  </StyledTableCell>
                  <StyledTableCell
                    align="right"
                    style={{ color: '#D30000', fontWeight: 'bold' }}
                    onClick={() => {
                      if (row.moreThan !== 0) {
                        toggleDrillDown(
                          row,
                          active === 'days' ? 'moreThanFour' : 'morethan9'
                        );
                      }
                    }}
                  >
                    {row.moreThan}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}
