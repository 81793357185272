import { Button, Typography } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';

import React, { useEffect, useState } from 'react';
import { getViewURL } from '../../../utils/fileUploadHandler';

const TruckFilesFromS3 = ({ truckNumber, truckerId }) => {
  const [rcurl, setRCURL] = useState('');
  const [fcurl, setFCURL] = useState('');
  const [icurl, setICURL] = useState('');
  const viewFile = url => {
    window.open(url);
  };
  function getRCFile() {
    const viewURLBody = {
      scope: 'truck',
      type: 'view',
      file_type: 'truck-rc',
      trucker_id: truckerId,
      truck_number: truckNumber,
    };
    getViewURL(viewURLBody)
      .then(result => {
        setRCURL(result?.data?.url);
      })
      .catch(e => console.log('error', e));
  }
  function getFCFile() {
    const viewURLBody = {
      scope: 'truck',
      type: 'view',
      file_type: 'truck-fitness',
      trucker_id: truckerId,
      truck_number: truckNumber,
    };
    getViewURL(viewURLBody)
      .then(result => {
        setFCURL(result?.data?.url);
      })
      .catch(e => console.log('error', e));
  }
  function getICFile() {
    const viewURLBody = {
      scope: 'truck',
      type: 'view',
      file_type: 'truck-insurance',
      trucker_id: truckerId,
      truck_number: truckNumber,
    };
    getViewURL(viewURLBody)
      .then(result => {
        setICURL(result?.data?.url);
      })
      .catch(e => console.log('error', e));
  }
  useEffect(() => {
    getRCFile();
    getFCFile();
    getICFile();
  }, []);
  return (
    <div
      style={{
        padding: '10px',
        borderRadius: '5px',
        border: '1px solid lightgray',
        marginBottom: '10px',
      }}
    >
      <Typography variant="body2">{truckNumber}</Typography>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: '10px',
        }}
      >
        <div>
          <p>RC</p>
          <Button
            variant="contained"
            color="success"
            size="small"
            onClick={() => viewFile(rcurl)}
            disabled={!rcurl}
            startIcon={<VisibilityIcon />}
            style={{ fontSize: '10px' }}
          >
            View
          </Button>
        </div>
        <div>
          <p>Insurance</p>
          <Button
            variant="contained"
            color="success"
            size="small"
            onClick={() => viewFile(icurl)}
            disabled={!icurl}
            startIcon={<VisibilityIcon />}
            style={{ fontSize: '10px' }}
          >
            View
          </Button>
        </div>
        <div>
          <p>Fitness Certificate</p>
          <Button
            variant="contained"
            color="success"
            size="small"
            onClick={() => viewFile(fcurl)}
            disabled={!fcurl}
            startIcon={<VisibilityIcon />}
            style={{ fontSize: '10px' }}
          >
            View
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TruckFilesFromS3;
