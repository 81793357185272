/* eslint-disable import/no-mutable-exports */
import Pages from 'layouts/Pages.jsx';
import SalesDashboard from 'layouts/SalesDashboard.jsx';
import OpsDashboard from 'layouts/OpsDashboard.jsx';
import FinanceDashboard from 'layouts/FinanceDashboard.jsx';
import TransporterDashboard from 'layouts/transporterDashboard.jsx';
import CallCenterDashboard from 'layouts/CallCenterDashboard.jsx';
import BranchOpsDashboard from 'layouts/BranchOpsDashboard.jsx';
import FinanceRecievableDashboard from '../layouts/FinanceRecievableDashboard';
import FinancePayableDashboard from '../layouts/FinancePayableDashboard';
import TransactionViewDashboard from '../layouts/TransactionViewDashboard';
import TrackingRoleDashboard from '../layouts/TrackingRoleDashboard';
import PartnerExecDashboard from '../layouts/PartnerExecDashboard';
import PartnerExecManagerDashboard from '../layouts/PartnerExecManagerDashboard';
import TrafficDashboard from '../layouts/TrafficDashboard';

const indexRoutes = [
  //  { path: "/agent", name: "Home", component: Dashboard },
  { path: '/ops', name: 'Home', component: OpsDashboard },
  { path: '/sales', name: 'Home', component: SalesDashboard },
  { path: '/finance', name: 'Home', component: FinanceDashboard },
  { path: '/transporters', name: 'Home', component: TransporterDashboard },
  { path: '/call_center', name: 'Home', component: CallCenterDashboard },
  { path: '/branch-ops', name: 'Home', component: BranchOpsDashboard },
  { path: '/partner-exec', name: 'Home', component: PartnerExecDashboard },
  { path: '/traffic', name: 'Home', component: TrafficDashboard },
  {
    path: '/partner-exec-manager',
    name: 'Home',
    component: PartnerExecManagerDashboard,
  },
  {
    path: '/finance-recievable',
    name: 'Home',
    component: FinanceRecievableDashboard,
  },
  {
    path: '/finance-payable',
    name: 'Home',
    component: FinancePayableDashboard,
  },
  {
    path: '/transaction-view',
    name: 'Home',
    component: TransactionViewDashboard,
  },
  {
    path: '/tracking-role',
    name: 'Home',
    component: TrackingRoleDashboard,
  },
  //  { path: "/admin", name: "Admin", component: AdminDashboard },
  { path: '/', name: 'Pages', component: Pages },
];

export default indexRoutes;
