/* eslint-disable no-nested-ternary */
import axios from 'axios';

const {
  REACT_APP_OFFICE_REPORTS_API,
  REACT_APP_API_BASE_URL,
  REACT_APP_API_NGROK_BASE_URL,
  REACT_APP_API_BASE_URL_NH,
} = process.env;
export default function apiCaller(endPoint, data, token, type, method) {
  const url =
    type === 'pipe'
      ? `${REACT_APP_API_BASE_URL}${endPoint}`
      : type === 'ngrok'
      ? `${REACT_APP_API_NGROK_BASE_URL}${endPoint}`
      : type === 'nh-base'
      ? `${REACT_APP_API_BASE_URL_NH}${endPoint}`
      : `${REACT_APP_OFFICE_REPORTS_API}${endPoint}`;
  return axios({
    method: method === 'get' ? 'get' : 'post',
    url,
    data,
    headers: {
      Authorization: token,
    },
  });
}
