import myFirebase from '../config/config';
import apiCaller from './apiCallerOutstanding';

async function getManufacturerList(isWarehouse) {
  const endPoint = 'pipe/trips';
  const currentUserDetails = myFirebase.auth().currentUser;
  const { Aa: token } = currentUserDetails;
  const body = {
    type: isWarehouse ? 'get_warehouse' : 'get_manufacturer',
  };
  try {
    const response = await apiCaller(
      endPoint,
      body,
      `Bearer ${token}`,
      'pipe',
      'pipe'
    );
    const result = [];
    const listData = response?.data?.Data;
    if (listData.length > 0) {
      for (const data of listData) {
        // Use "of" instead of "in" to iterate through the array
        result.push({
          label: data.company_name, // Use "data" instead of "listData" to access the properties
          value: data.manufacturer_id,
        });
      }
    }
    return result;
  } catch (e) {
    return [];
  }
}

export default getManufacturerList;
