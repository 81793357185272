/* eslint-disable camelcase */
import firebase from 'firebase/app';
import 'firebase/performance';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions';

const { GOOGLE_API_KEY } = process.env;
let config = {};
export const googleApiKey = GOOGLE_API_KEY;

if (process.env.REACT_APP_API_ENV === 'production') {
  // production credentials
  config = {
    apiKey: 'AIzaSyCW3JS-MyF9KK9gGr-qayi4sx58WT1NXJs',
    authDomain: 'lobb-office.firebaseapp.com',
    databaseURL: 'https://lobb-office.firebaseio.com',
    projectId: 'lobb-office',
    storageBucket: 'lobb-office.appspot.com',
    messagingSenderId: '110811829758',
    appId: '1:110811829758:web:88c8583ff1a13359',
  };
} else if (process.env.REACT_APP_API_ENV === 'dev') {
  // staging credentials
  config = {
    apiKey: 'AIzaSyC9oHGpxoeXjdRBarPwe-xfXrYVCHD7qRM',
    authDomain: 'lobb-office-dev.firebaseapp.com',
    databaseURL: 'https://lobb-office-dev.firebaseio.com',
    projectId: 'lobb-office-dev',
    storageBucket: 'lobb-office-dev.appspot.com',
    messagingSenderId: '544780428277',
    appId: '1:544780428277:web:e4a3c66fddcacea5a076bc',
    measurementId: 'G-LTE4X9YX1T',
  };
} else if (process.env.REACT_APP_API_ENV === 'staging') {
  config = {
    apiKey: 'AIzaSyBgNseA0EO4KS3lKMBwJJR7_4PF62Qjh4Q',
    authDomain: 'lobb-office-staging.firebaseapp.com',
    databaseURL: 'https://lobb-office-staging.firebaseio.com',
    projectId: 'lobb-office-staging',
    storageBucket: 'lobb-office-staging.appspot.com',
    messagingSenderId: '191321726097',
  };
}
const myFirebase = firebase.initializeApp(config);
const firestore = myFirebase.firestore();
firestore.settings({
  timestampsInSnapshots: true,
});
if (process.env.REACT_APP_API_ENV === 'production') {
  myFirebase.performance();
}
export default myFirebase;
