/* eslint-disable radix */
/* eslint-disable guard-for-in */
import { Checkbox, FormControl, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import Card from 'components/Card/Card.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';

const OtherExpensesModifyTransaction = ({
  classes,
  currentTransactionData,
  handleOtherExpensesByTrucker,
  handleOtherExpensesByTransporter,
  states,
}) => {
  // console.log('currentTransactionData', currentTransactionData);
  const {
    loadingChargesTransporter,
    loadingChargesPaidByTransporter,
    unloadingChargesTransporter,
    unloadingChargesPaidByTransporter,
    hamaliChargesTransporter,
    hamaliChargesPaidByTransporter,
    haltingChargesTransporter,
    haltingChargesPaidByTransporter,
    loadingChargesTrucker,
    loadingChargesPaidByTrucker,
    unloadingChargesTrucker,
    unloadingChargesPaidByTrucker,
    hamaliChargesTrucker,
    hamaliChargesPaidByTrucker,
    haltingChargesTrucker,
    haltingChargesPaidByTrucker,
  } = currentTransactionData;
  const [otherExpenseTransporter, setOtherExpenseTransporter] = useState([
    {
      loadingCharge: {
        amount: loadingChargesTransporter || '',
        checked: loadingChargesPaidByTransporter || false,
      },
    },
    {
      hamaliCharge: {
        amount: hamaliChargesTransporter || '',
        checked: hamaliChargesPaidByTransporter || false,
      },
    },
    {
      haltingCharge: {
        amount: haltingChargesTransporter || '',
        checked: haltingChargesPaidByTransporter || false,
      },
    },
    {
      unloadingCharge: {
        amount: unloadingChargesTransporter || '',
        checked: unloadingChargesPaidByTransporter || false,
      },
    },
  ]);

  const [otherExpenseTrucker, setOtherExpenseTrucker] = useState([
    {
      loadingCharge: {
        amount: loadingChargesTrucker || '',
        checked: loadingChargesPaidByTrucker || false,
      },
    },
    {
      hamaliCharge: {
        amount: hamaliChargesTrucker || '',
        checked: hamaliChargesPaidByTrucker || false,
      },
    },
    {
      haltingCharge: {
        amount: haltingChargesTrucker || '',
        checked: haltingChargesPaidByTrucker || false,
      },
    },
    {
      unloadingCharge: {
        amount: unloadingChargesTrucker || '',
        checked: unloadingChargesPaidByTrucker || false,
      },
    },
  ]);

  const {
    loadingChargesTruckerError,
    unloadingChargesTruckerError,
    hamaliChargesTruckerError,
    haltingChargesTruckerError,
    loadingChargesTransporterError,
    unloadingChargesTransporterError,
    hamaliChargesTransporterError,
    haltingChargesTransporterError,
  } = states;

  const handleAmountChange = (index, val, type) => {
    setOtherExpenseTransporter(prevArray => {
      const newArray = [...prevArray];

      const obj = newArray[index];

      for (const key in obj) {
        obj[key].amount = parseInt(val);
      }
      newArray[index] = obj; // Update the modified object in the newArray
      return newArray;
    });

    if (index === 0) {
      handleOtherExpensesByTransporter(
        'loadingChargesTransporter',
        Number(val)
      );
    } else if (index === 1) {
      handleOtherExpensesByTransporter('hamaliChargesTransporter', Number(val));
    } else if (index === 2) {
      handleOtherExpensesByTransporter(
        'haltingChargesTransporter',
        Number(val)
      );
    } else if (index === 3) {
      handleOtherExpensesByTransporter(
        'unloadingChargesTransporter',
        Number(val)
      );
    }
  };

  const handleCheckboxChange = (index, checked) => {
    if (index === 0) {
      handleOtherExpensesByTransporter(
        'loadingChargesPaidByTransporter',
        checked
      );
    } else if (index === 1) {
      handleOtherExpensesByTransporter(
        'hamaliChargesPaidByTransporter',
        checked
      );
    } else if (index === 2) {
      handleOtherExpensesByTransporter(
        'haltingChargesPaidByTransporter',
        checked
      );
    } else if (index === 3) {
      handleOtherExpensesByTransporter(
        'unloadingChargesPaidByTransporter',
        checked
      );
    }
    setOtherExpenseTransporter(prevArray => {
      const newArray = [...prevArray];

      const obj = newArray[index];

      for (const key in obj) {
        obj[key].checked = checked;
      }
      newArray[index] = obj; // Update the modified object in the newArray
      return newArray;
    });
  };

  const handleAmountTruckerChange = (index, val) => {
    if (index === 0) {
      handleOtherExpensesByTrucker('loadingChargesTrucker', Number(val));
    } else if (index === 1) {
      handleOtherExpensesByTrucker('hamaliChargesTrucker', Number(val));
    } else if (index === 2) {
      handleOtherExpensesByTrucker('haltingChargesTrucker', Number(val));
    } else if (index === 3) {
      handleOtherExpensesByTrucker('unloadingChargesTrucker', Number(val));
    }
    setOtherExpenseTrucker(prevArray => {
      const newArray = [...prevArray];

      const obj = newArray[index];

      for (const key in obj) {
        obj[key].amount = parseInt(val);
      }
      newArray[index] = obj; // Update the modified object in the newArray
      return newArray;
    });
  };

  const handleCheckboxTruckerChange = (index, checked) => {
    if (index === 0) {
      handleOtherExpensesByTrucker('loadingChargesPaidByTrucker', checked);
    } else if (index === 1) {
      handleOtherExpensesByTrucker('hamaliChargesPaidByTrucker', checked);
    } else if (index === 2) {
      handleOtherExpensesByTrucker('haltingChargesPaidByTrucker', checked);
    } else if (index === 3) {
      handleOtherExpensesByTrucker('unloadingChargesPaidByTrucker', checked);
    }

    setOtherExpenseTrucker(prevArray => {
      const newArray = [...prevArray];

      const obj = newArray[index];

      for (const key in obj) {
        obj[key].checked = checked;
      }
      newArray[index] = obj; // Update the modified object in the newArray
      return newArray;
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <h3
              style={{
                fontSize: '16px',
                color: 'black',
                fontWeight: '400',
              }}
            >
              Other Expenses{' '}
              <span style={{ fontSize: '12px', fontStyle: 'italic' }}>
                (Transporter)
              </span>
            </h3>
          </CardHeader>
          <CardBody>
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={10} />
                  <Grid item xs={2}>
                    <p style={{ fontSize: '10px', fontWeight: 'bold' }}>
                      Collected from Transporter
                    </p>
                  </Grid>
                </Grid>
                <Grid container alignItems="center">
                  <Grid item xs={10}>
                    <FormControl>
                      <CustomInput
                        labelText="Loading Charges"
                        id="loadingCharges"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          onChange: event =>
                            handleAmountChange(
                              0,
                              event.target.value,
                              'loadingCharge'
                            ),
                          type: 'number',
                          value:
                            otherExpenseTransporter[0]?.loadingCharge?.amount,
                        }}
                        success={loadingChargesTransporterError === 'success'}
                        error={loadingChargesTransporterError === 'error'}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <Checkbox
                      size="small"
                      inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
                      onChange={e =>
                        handleCheckboxChange(
                          0,
                          e.target.checked,
                          'hamaliCharge'
                        )
                      }
                      checked={
                        otherExpenseTransporter[0]?.loadingCharge?.checked
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center">
                  <Grid item xs={10}>
                    <FormControl>
                      <CustomInput
                        labelText="Unloading Charges"
                        id="unloadingCharges"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          onChange: event =>
                            handleAmountChange(
                              3,
                              event.target.value,
                              'unloadingCharge'
                            ),
                          type: 'number',
                          value:
                            otherExpenseTransporter[3]?.unloadingCharge?.amount,
                        }}
                        success={unloadingChargesTransporterError === 'success'}
                        error={unloadingChargesTransporterError === 'error'}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <Checkbox
                      size="small"
                      inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
                      onChange={e => handleCheckboxChange(3, e.target.checked)}
                      checked={
                        otherExpenseTransporter[3]?.unloadingCharge?.checked
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center">
                  <Grid item xs={10}>
                    <FormControl>
                      <CustomInput
                        labelText="Hamali Charges"
                        id="hamaliCharges"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          onChange: event =>
                            handleAmountChange(
                              1,
                              event.target.value,
                              'haltingCharge'
                            ),
                          type: 'number',
                          value:
                            otherExpenseTransporter[1]?.hamaliCharge?.amount,
                        }}
                        success={hamaliChargesTransporterError === 'success'}
                        error={hamaliChargesTransporterError === 'error'}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <Checkbox
                      size="small"
                      inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
                      onChange={e => handleCheckboxChange(1, e.target.checked)}
                      checked={
                        otherExpenseTransporter[1]?.hamaliCharge?.checked
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center">
                  <Grid item xs={10}>
                    <FormControl>
                      <CustomInput
                        labelText="Halting Charges"
                        id="loadingCharges"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          onChange: event =>
                            handleAmountChange(2, event.target.value),
                          type: 'number',
                          value:
                            otherExpenseTransporter[2]?.haltingCharge?.amount,
                        }}
                        success={haltingChargesTransporterError === 'success'}
                        error={haltingChargesTransporterError === 'error'}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <Checkbox
                      size="small"
                      inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
                      onChange={e => handleCheckboxChange(2, e.target.checked)}
                      checked={
                        otherExpenseTransporter[2]?.haltingCharge?.checked
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <h3
              style={{
                fontSize: '16px',
                color: 'black',
                fontWeight: '400',
              }}
            >
              Other Expenses{' '}
              <span style={{ fontSize: '12px', fontStyle: 'italic' }}>
                (Trucker)
              </span>
            </h3>
            <p style={{ color: 'red', fontSize: '12px' }}>
              (If the Box is not ticked, the expense is paid by Lobb.)
            </p>
          </CardHeader>
          <CardBody>
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={10} />
                  <Grid item xs={2}>
                    <p style={{ fontSize: '10px', fontWeight: 'bold' }}>
                      Paid by Trucker
                    </p>
                  </Grid>
                </Grid>
                <Grid container alignItems="center">
                  <Grid item xs={10}>
                    <FormControl>
                      <CustomInput
                        labelText="Loading Charges"
                        id="loadingCharges"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          onChange: event =>
                            handleAmountTruckerChange(0, event.target.value),
                          type: 'number',
                          value: otherExpenseTrucker[0]?.loadingCharge?.amount,
                        }}
                        success={loadingChargesTruckerError === 'success'}
                        error={loadingChargesTruckerError === 'error'}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <Checkbox
                      size="small"
                      inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
                      onChange={e =>
                        handleCheckboxTruckerChange(0, e.target.checked)
                      }
                      checked={otherExpenseTrucker[0]?.loadingCharge?.checked}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center">
                  <Grid item xs={10}>
                    <FormControl>
                      <CustomInput
                        labelText="Unloading Charges"
                        id="unloadingCharges"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          onChange: event =>
                            handleAmountTruckerChange(3, event.target.value),
                          type: 'number',
                          value: otherExpenseTrucker[3]?.unloadingCharge?.amount,
                        }}
                        success={unloadingChargesTruckerError === 'success'}
                        error={unloadingChargesTruckerError === 'error'}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <Checkbox
                      size="small"
                      inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
                      onChange={e =>
                        handleCheckboxTruckerChange(3, e.target.checked)
                      }
                      checked={otherExpenseTrucker[3]?.unloadingCharge?.checked}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center">
                  <Grid item xs={10}>
                    <FormControl>
                      <CustomInput
                        labelText="Hamali Charges"
                        id="loadingCharges"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          onChange: event =>
                            handleAmountTruckerChange(1, event.target.value),
                          type: 'number',
                          value: otherExpenseTrucker[1]?.hamaliCharge?.amount,
                        }}
                        success={hamaliChargesTruckerError === 'success'}
                        error={hamaliChargesTruckerError === 'error'}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <Checkbox
                      size="small"
                      inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
                      onChange={e =>
                        handleCheckboxTruckerChange(1, e.target.checked)
                      }
                      checked={otherExpenseTrucker[1]?.hamaliCharge?.checked}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center">
                  <Grid item xs={10}>
                    <FormControl>
                      <CustomInput
                        labelText="Halting Charges"
                        id="loadingCharges"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          onChange: event =>
                            handleAmountTruckerChange(2, event.target.value),
                          type: 'number',
                          value: otherExpenseTrucker[2]?.haltingCharge?.amount,
                        }}
                        success={haltingChargesTruckerError === 'success'}
                        error={haltingChargesTruckerError === 'error'}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <Checkbox
                      size="small"
                      inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
                      onChange={e =>
                        handleCheckboxTruckerChange(2, e.target.checked)
                      }
                      checked={otherExpenseTrucker[2]?.haltingCharge?.checked}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </Grid>
    </Grid>
  );
};

export default OtherExpensesModifyTransaction;
