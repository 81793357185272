import React from 'react';

const FeatureFlagEmptyState = ({ color }) => {
  return (
    <div>
      <p style={{ color }}>This Feature is currently unavailable</p>
    </div>
  );
};

export default FeatureFlagEmptyState;
