import axios from 'axios';
import myFirebase from '../config/config';
import getFileMimeType from './getFileMimeType';
import swal from 'sweetalert';

const { REACT_APP_API_BASE_URL } = process.env;

async function getSignedURL(body, UploadedFile) {
  const mime = await getFileMimeType(UploadedFile);

  if (mime !== 'Unknown file type') {
    const fileBody = body;
    fileBody.ext = mime;
    const userDetails = myFirebase.auth().currentUser;
    const { Aa } = userDetails;
    const token = Aa;
    const endPoint = `${REACT_APP_API_BASE_URL}pipe/files`;

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      return axios.post(endPoint, fileBody, config);
    } catch (e) {
      throw new Error(e);
    }
  } else {
    swal('Oops!', 'Unknown file type. Please upload a valid file.', 'error');
    throw new Error('Unknown file type');
  }
}

async function fileUploader(url, File) {
  try {
    await axios.put(url, File, {
      headers: {
        'content-Type': File?.type,
      },
    });

    return 'success';
  } catch (e) {
    throw new Error(e);
  }
}

function getViewURL(body) {
  const userDetails = myFirebase.auth().currentUser;
  const { Aa } = userDetails;
  const token = Aa;
  const endPoint = `${REACT_APP_API_BASE_URL}pipe/files`;

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  try {
    return axios.post(endPoint, body, config);
  } catch (e) {
    throw new Error(e);
  }
}

function deleteFileHandler(body) {
  const userDetails = myFirebase.auth().currentUser;
  const { Aa } = userDetails;
  const token = Aa;
  const endPoint = `${REACT_APP_API_BASE_URL}pipe/files`;

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  try {
    return axios.post(endPoint, body, config);
  } catch (e) {
    throw new Error(e);
  }
}

export { getSignedURL, fileUploader, getViewURL, deleteFileHandler };
