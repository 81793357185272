/* eslint-disable no-fallthrough */
/* eslint-disable no-dupe-class-members */
/* eslint-disable no-plusplus */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import PropTypes from 'prop-types';
import Snackbar from 'components/Snackbar/Snackbar.jsx';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import LocationCity from '@material-ui/icons/LocationCity';
import Add from '@material-ui/icons/Add';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  CardActions,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';

// @material-ui/icons
import AddAlert from '@material-ui/icons/AddAlert';
import notificationsStyle from 'assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx';
import firebase from '../../config/config';
import CitiesCard from './cities/CitiesCard';

const db = firebase.firestore();
// eslint-disable-next-line react/no-multi-comp
class Cities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cityname: '',
      shortName: '',
      citynameState: '',
      shortNameState: '',
      zone: '',
      addCitiesModal: false,
      isLoading: false,
      tc: false,
      duplicate: false,
      cities: [],
      errMessage: '',
      successMessage: '',
    };
    this.change = this.change.bind(this);
    this.submitCity = this.submitCity.bind(this);
    this.validateAllFields = this.validateAllFields.bind(this);
    this.getCities = this.getCities.bind(this);
  }

  componentDidMount() {
    this.getCity();
    this.getCities();
  }

  getCities() {
    const cityRef = db
      .collection('Cities')
      .where('isActive', '==', true)
      .orderBy('name', 'asc');
    cityRef.onSnapshot(citiesSnapshot => {
      const cities = [];
      citiesSnapshot.forEach(city => {
        cities.push({
          city: city.data().name,
          shortName: city.data().shortName,
          zone: city.data()?.zone,
        });
      });
      this.setState({
        cities,
      });
    });
  }

  componentWillUnmount() {
    let id = window.setTimeout(null, 0);
    while (id--) {
      window.clearTimeout(id);
    }
  }

  /**
   * Used to display the list of city in card view
   */
  getCity() {
    let columnSize = '';
    if (window.innerWidth < 600) {
      columnSize = 2;
    } else {
      columnSize = 6;
    }
    return <CitiesCard columnSize={columnSize} cities={this.state.cities} />;
  }

  isDuplicate() {
    const words = this.state.cities.map(v => v.city.toLowerCase());
    const duplicateComparison = new Set(words);
    const lower = this.state.cityname.toLowerCase();
    const myValue = duplicateComparison.has(lower);
    return myValue;
  }

  /**
   * Used to submit the city name
   */
  submitCity = event => {
    const upper =
      this.state.cityname.charAt(0).toUpperCase() +
      this.state.cityname.substring(1);
    const { shortName, zone } = this.state;
    const isDuplicate = this.isDuplicate();
    const citiesRef = db.collection('Cities');
    const date = new Date();
    this.setState({
      isLoading: true,
    });
    if (isDuplicate === false) {
      this.validateAllFields()
        .then(success => {
          if (success) {
            citiesRef
              .add({
                isActive: true,
                name: upper,
                shortName,
                createdAt: date,
                modifiedAt: date,
                zone,
              })
              .then(() => {
                this.showNotification('tc');
                // To set the first letter as upper case
                this.setState({
                  addCitiesModal: false,
                  isLoading: false,
                  cityname: '',
                  successMessage: `${upper} is successfully added to the cities list`,
                  errMessage: '',
                });
              })
              .catch(err => {
                this.showNotification('duplicate');
                this.setState({
                  addCitiesModal: false,
                  isLoading: false,
                  errMessage: 'Oops some thing went wrong',
                  successMessage: '',
                });
              });
          }
        })
        .catch(err => {
          this.showNotification('duplicate');
          this.setState({
            addCitiesModal: false,
            isLoading: false,
            errMessage: 'Oops some thing went wrong',
            successMessage: '',
          });
        });
    } else {
      this.showNotification('duplicate');
      this.setState({
        addCitiesModal: false,
        errMessage: `${upper} City is already been added.`,
        successMessage: '',
        cityname: '',
        isLoading: false,
      });
    }
  };

  showNotification(place) {
    if (!this.state[place]) {
      const x = [];
      x[place] = true;
      this.setState(x);
      setTimeout(() => {
        x[place] = false;
        this.setState(x);
      }, 6000);
    }
  }

  /**
   * Used to validate the Field
   */
  validateAllFields() {
    let error = false;
    return new Promise((resolve, reject) => {
      // Verify Name
      if (this.verifyLength(this.state.cityname, 1)) {
        this.setState({
          citynameState: 'success',
        });
      } else {
        this.setState({
          citynameState: 'error',
        });
        error = true;
      }
      if (!error) {
        resolve(true);
      } else {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject('validation Failed');
      }
    });
  }

  /**
   * Used to validation to verify error
   * @param event event name
   * @param stateName state name
   * @param type type
   * @param stateNameEqualTo state name which is equal
   */
  change(event, stateName, type, stateNameEqualTo) {
    const regex = /^[A-Z]{1,3}$/;
    switch (type) {
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'shortName':
        if (regex.test(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }

      default:
        break;
    }
  }

  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  handleAddCitiesModalOpen(modal) {
    const x = [];
    x[modal] = true;
    this.setState(x);
    this.setState({
      cityname: '',
      citynameState: '',
    });
  }

  handleAddCitiesModalClose(modal) {
    const x = [];
    x[modal] = false;
    this.setState(x);
  }

  handleChange = event => {
    // setAge(event.target.value);
    this.setState({ zone: event.target.value });
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <GridContainer>
          <GridItem>
            {this.state.duplicate === true ? (
              <Snackbar
                place="tc"
                color="danger"
                icon={AddAlert}
                message={this.state.errMessage}
                open={this.state.duplicate}
                closeNotification={() => this.setState({ duplicate: false })}
                close
              />
            ) : (
              ''
            )}
          </GridItem>
          <GridItem>
            <Snackbar
              place="tc"
              color="info"
              icon={AddAlert}
              message={this.state.successMessage}
              open={this.state.tc}
              closeNotification={() => this.setState({ tc: false })}
              close
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="warning">
                <CardIcon color="primary">
                  {' '}
                  {this.state.cities.length}{' '}
                </CardIcon>
                <h4>
                  {' '}
                  Add Cities
                  <Button
                    style={{ float: 'right' }}
                    justIcon
                    round
                    color="primary"
                    onClick={() =>
                      this.handleAddCitiesModalOpen('addCitiesModal')
                    }
                  >
                    <Add />
                  </Button>
                </h4>
              </CardHeader>
              <CardBody>
                {this.state.addCitiesModal === true ? (
                  <GridItem xs={12} sm={12} md={6} lg={6}>
                    <Card>
                      <CardHeader>
                        <h4>Add City</h4>
                      </CardHeader>
                      <form
                        onSubmit={e => {
                          this.submitCity(this);
                          e.preventDefault();
                        }}
                      >
                        <CardBody>
                          <CustomInput
                            // eslint-disable-next-line react/destructuring-assignment
                            success={this.state.citynameState === 'success'}
                            error={this.state.citynameState === 'error'}
                            labelText={
                              <span>
                                City <small>*</small>
                              </span>
                            }
                            id="cityname"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              onChange: event =>
                                this.change(event, 'cityname', 'length', 3),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <LocationCity />
                                </InputAdornment>
                              ),
                            }}
                          />
                          <CustomInput
                            // eslint-disable-next-line react/destructuring-assignment
                            success={this.state.shortNameState === 'success'}
                            error={this.state.shortNameState === 'error'}
                            labelText={
                              <span>
                                Short Name <small>*</small>
                              </span>
                            }
                            id="shortname"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              onChange: event =>
                                this.change(event, 'shortName', 'shortName', 3),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <LocationCity />
                                </InputAdornment>
                              ),
                            }}
                          />
                          <FormControl style={{ width: '100%' }}>
                            <InputLabel id="demo-simple-select-label">
                              Zone *
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={this.state.zone}
                              onChange={this.handleChange}
                            >
                              <MenuItem value="North">North</MenuItem>
                              <MenuItem value="South">South</MenuItem>
                              <MenuItem value="East">East</MenuItem>
                              <MenuItem value="West">West</MenuItem>
                            </Select>
                          </FormControl>
                        </CardBody>
                        <CardActions>
                          {this.state.isLoading ? (
                            <CircularProgress
                              className={classes.progress}
                              style={{ color: purple[500] }}
                              thickness={7}
                            />
                          ) : (
                            <div>
                              <Button
                                onClick={() =>
                                  this.handleAddCitiesModalClose(
                                    'addCitiesModal'
                                  )
                                }
                              >
                                Cancel
                              </Button>
                              <Button type="submit" color="success">
                                Submit
                              </Button>
                            </div>
                          )}
                        </CardActions>
                      </form>
                    </Card>
                  </GridItem>
                ) : (
                  ''
                )}
                {this.getCity()}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

Cities.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(notificationsStyle)(Cities);
