import React, { useState } from 'react';
import Card from 'components/Card/Card.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import MoreTruckerDeductions from './MoreTruckerDeductions';

const TripDeductionFields = ({
  classes,
  state,
  handleDeductionsTransporter,
  handleDeductionsTrucker,
  handleMoreDeductionsTrucker,
}) => {
  const [transporterDeduction, setTransporterDeduction] = useState({
    challanTransporterAmount: '',
    penaltyTransporterAmount: '',
    tdsTransporterAmount: '',
    docChargesTransporterAmount: '',
    fuelChargesTransporterAmount: '',
    maamolChargesTransporterAmount: '',
  });

  const [truckerDeduction, setTruckerDeduction] = useState({
    challanTruckerAmount: '',
    penaltyTruckerAmount: '',
  });
  const {
    challanTransporterAmountError,
    penaltyTransporterAmountError,
    tdsTransporterAmountError,
    docChargesTransporterAmountError,
    fuelChargesTransporterAmountError,
    maamolChargesTransporterAmountError,
    challanTruckerAmountError,
    penaltyTruckerAmountError,
  } = state;
  const handleInputChange = (propertyName, value) => {
    handleDeductionsTransporter(propertyName, Number(value));
    setTransporterDeduction(prevState => ({
      ...prevState,
      [propertyName]: value,
    }));
  };

  const handleTruckerInputChange = (propertyName, value) => {
    handleDeductionsTrucker(propertyName, Number(value));
    setTruckerDeduction(prevState => ({
      ...prevState,
      [propertyName]: value,
    }));
  };

  return (
    <GridContainer direction="row" spacing={1}>
      <GridItem xs={6}>
        <Card>
          <CardHeader color="primary" icon>
            <GridContainer>
              <GridItem xs={9}>
                <h3
                  style={{
                    fontSize: '16px',
                    color: 'black',
                    fontWeight: '400',
                  }}
                >
                  Transporter Deductions
                </h3>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <CustomInput
              labelText="Challan"
              id="munshiana"
              formControlProps={{ fullWidth: true }}
              inputProps={{
                onChange: event =>
                  handleInputChange(
                    'challanTransporterAmount',
                    event.target.value
                  ),
                type: 'number',
                value: transporterDeduction.challanTransporterAmount,
              }}
              success={challanTransporterAmountError === 'success'}
              error={challanTransporterAmountError === 'error'}
            />
            <CustomInput
              labelText="Penalty"
              id="munshiana"
              formControlProps={{ fullWidth: true }}
              inputProps={{
                onChange: event =>
                  handleInputChange(
                    'penaltyTransporterAmount',
                    event.target.value
                  ),
                type: 'number',
                value: transporterDeduction.penaltyTransporterAmount,
              }}
              success={penaltyTransporterAmountError === 'success'}
              error={penaltyTransporterAmountError === 'error'}
            />
            <CustomInput
              labelText="TDS"
              id="munshiana"
              formControlProps={{ fullWidth: true }}
              inputProps={{
                onChange: event =>
                  handleInputChange('tdsTransporterAmount', event.target.value),
                type: 'number',
                value: transporterDeduction.tdsTransporterAmount,
              }}
              success={tdsTransporterAmountError === 'success'}
              error={tdsTransporterAmountError === 'error'}
            />
            <CustomInput
              labelText="DOC Charges"
              id="munshiana"
              formControlProps={{ fullWidth: true }}
              inputProps={{
                onChange: event =>
                  handleInputChange(
                    'docChargesTransporterAmount',
                    event.target.value
                  ),
                type: 'number',
                value: transporterDeduction.docChargesTransporterAmount,
              }}
              success={docChargesTransporterAmountError === 'success'}
              error={docChargesTransporterAmountError === 'error'}
            />
            <CustomInput
              labelText="Fuel Charges"
              id="munshiana"
              formControlProps={{ fullWidth: true }}
              inputProps={{
                onChange: event =>
                  handleInputChange(
                    'fuelChargesTransporterAmount',
                    event.target.value
                  ),
                type: 'number',
                value: transporterDeduction.fuelChargesTransporterAmount,
              }}
              success={fuelChargesTransporterAmountError === 'success'}
              error={fuelChargesTransporterAmountError === 'error'}
            />
            <CustomInput
              labelText="Maamol Charges"
              id="munshiana"
              formControlProps={{ fullWidth: true }}
              inputProps={{
                onChange: event =>
                  handleInputChange(
                    'maamolChargesTransporterAmount',
                    event.target.value
                  ),
                type: 'number',
                value: transporterDeduction.maamolChargesTransporterAmount,
              }}
              success={maamolChargesTransporterAmountError === 'success'}
              error={maamolChargesTransporterAmountError === 'error'}
            />
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={6}>
        <GridContainer>
          <GridItem xs={12}>
            <Card style={{ marginBottom: 0 }}>
              <CardHeader color="primary" icon>
                <GridContainer>
                  <GridItem xs={9}>
                    <h3
                      style={{
                        fontSize: '16px',
                        color: 'black',
                        fontWeight: '400',
                      }}
                    >
                      Trucker Deductions
                    </h3>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText="Challan"
                  id="munshiana"
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    onChange: event =>
                      handleTruckerInputChange(
                        'challanTruckerAmount',
                        event.target.value
                      ),
                    type: 'number',
                    value: truckerDeduction.challanTruckerAmount,
                  }}
                  success={challanTruckerAmountError === 'success'}
                  error={challanTruckerAmountError === 'error'}
                />
                <CustomInput
                  labelText="Penalty"
                  id="munshiana"
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    onChange: event =>
                      handleTruckerInputChange(
                        'penaltyTruckerAmount',
                        event.target.value
                      ),
                    type: 'number',
                    value: truckerDeduction.penaltyTruckerAmount,
                  }}
                  success={penaltyTruckerAmountError === 'success'}
                  error={penaltyTruckerAmountError === 'error'}
                />
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12}>
            <MoreTruckerDeductions
              state={state}
              handleMoreDeductionsTrucker={(type, val) =>
                handleMoreDeductionsTrucker(type, val)
              }
            />
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
};

export default TripDeductionFields;
