/* eslint-disable react/no-array-index-key */
import GridListTile from '@material-ui/core/GridListTile';
import Card from 'components/Card/Card.jsx';
import GridList from '@material-ui/core/GridList';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import {
  ButtonGroup,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import swal from 'sweetalert';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import firebase from 'config/config';

const useStyles = makeStyles({
  zonebox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
  },
  activeButton: {
    background: ' #163BAC ',
    color: '#ffff',
  },
});
const db = firebase.firestore();
function CitiesCard({ cities, columnSize }) {
  const classes = useStyles();
  const [selectedCity, setSelectCity] = useState('');
  const [open, setOpen] = React.useState(false);
  const [shortName, setShortName] = React.useState('');
  const [disabledZoneButton, setDisabledZoneButton] = React.useState('');
  const [shortNameError, setShortNameError] = React.useState(false);
  const [selectedZone, setSelectedZone] = React.useState({
    city: '',
    zone: '',
  });

  const handleClickOpen = value => {
    setSelectCity(value.city);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleEditShortName = () => {
    const cityRef = db.collection('Cities').where('name', '==', selectedCity);
    cityRef
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          // doc.data() is never undefined for query doc snapshots
          const docRef = db.collection('Cities').doc(doc.id);
          return docRef
            .update({
              shortName,
            })
            .then(() => {
              setOpen(false);
              swal(
                'Good job!',
                `You added the shortName for ${selectedCity}!`,
                'success',
                {
                  buttons: false,
                  timer: 3000,
                }
              );
            })
            .catch(
              error =>
                // The document probably doesn't exist.
                error
            );
        });
      })
      .catch(error => error);
  };

  const handleShortName = value => {
    const regex = /^[A-Z]{1,3}$/;
    if (regex.test(value)) {
      setShortNameError(false);
      setShortName(value);
    } else setShortNameError(true);
  };

  const handleZone = (zone, city) => {
    setSelectedZone(() => ({
      city,
      zone,
    }));
  };

  const submitZone = () => {
    const cityRef = db
      .collection('Cities')
      .where('name', '==', selectedZone.city);
    cityRef
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          // doc.data() is never undefined for query doc snapshots
          const docRef = db.collection('Cities').doc(doc.id);
          return docRef
            .update({
              zone: selectedZone.zone,
            })
            .then(() => {
              setOpen(false);
              swal(
                'Good job!',
                `You added the zone ${selectedZone.zone} for ${selectedZone.city}!`,
                'success',
                {
                  buttons: false,
                  timer: 3000,
                }
              );
              setDisabledZoneButton(selectedZone.city);
              setSelectedZone({ city: '', zone: '' });
            })
            .catch(
              error =>
                // The document probably doesn't exist.
                error
            );
        });
      })
      .catch(error => error);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
      }}
    >
      <GridList cols={columnSize}>
        {cities.map((c, index) => (
          <GridListTile key={index} style={{ margin: '10px', width: '300px' }}>
            <Card>
              <div
                style={{
                  padding: '20px',
                  display: 'flex',
                  alignItems: 'baseline',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ width: '100%' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="body1" gutterBottom>
                      {c.city}
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{ marginLeft: '10px' }}
                      gutterBottom
                    >
                      {c.zone ? `(${c.zone})` : null}
                    </Typography>
                  </div>

                  <p> {c.shortName ? c.shortName : null}</p>
                </div>
                {c.shortName ? null : (
                  <IconButton
                    style={{ padding: 0 }}
                    onClick={() => handleClickOpen(c)}
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </div>
              {c?.zone || disabledZoneButton === c?.city ? null : (
                <div className={classes.zonebox}>
                  <ButtonGroup
                    size="small"
                    aria-label="small outlined button group"
                  >
                    <Button
                      onClick={() => handleZone('North', c.city)}
                      className={
                        selectedZone.city === c.city &&
                        selectedZone.zone === 'North'
                          ? classes.activeButton
                          : ''
                      }
                    >
                      N
                    </Button>
                    <Button
                      className={
                        selectedZone.city === c.city &&
                        selectedZone.zone === 'South'
                          ? classes.activeButton
                          : ''
                      }
                      onClick={() => handleZone('South', c.city)}
                    >
                      S
                    </Button>
                    <Button
                      className={
                        selectedZone.city === c.city &&
                        selectedZone.zone === 'East'
                          ? classes.activeButton
                          : ''
                      }
                      onClick={() => handleZone('East', c.city)}
                    >
                      E
                    </Button>
                    <Button
                      className={
                        selectedZone.city === c.city &&
                        selectedZone.zone === 'West'
                          ? classes.activeButton
                          : ''
                      }
                      onClick={() => handleZone('West', c.city)}
                    >
                      W
                    </Button>
                  </ButtonGroup>
                  {selectedZone.city === c.city && (
                    <button
                      variant="contained"
                      style={{
                        backgroundColor: '#3DAC16',
                        color: 'white',
                        marginLeft: 10,
                        border: 'none',
                        borderRadius: 5,
                      }}
                      type="submit"
                      aria-label="submit"
                      onClick={submitZone}
                    >
                      <CheckIcon
                        fontSize="small"
                        cursor="pointer"
                        onClick={submitZone}
                        color="green"
                      />
                    </button>
                  )}
                </div>
              )}
            </Card>
          </GridListTile>
        ))}
      </GridList>
      <div>
        <Dialog
          open={open}
          maxWidth="sm"
          fullWidth
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Add City</DialogTitle>
          <DialogContent
            style={{
              display: 'flex',
              padding: '10px 25px',
              margin: '10px 0px',
            }}
          >
            <TextField
              disabled
              id="standard-disabled"
              label="City Name"
              defaultValue={selectedCity}
              variant="outlined"
              size="small"
            />
            <TextField
              id="outlined-basic"
              size="small"
              label="Short Name"
              variant="outlined"
              style={{ width: '140px', marginLeft: '10px' }}
              onChange={event => handleShortName(event.target.value)}
              error={shortNameError}
              helperText={
                shortNameError
                  ? 'Short Name should be all caps and three letters'
                  : ''
              }
            />
          </DialogContent>
          <DialogActions style={{ margin: '10px 0px' }}>
            <Button
              onClick={handleClose}
              variant="contained"
              size="small"
              style={{
                backgroundColor: '#AFB2B7',
                color: 'white',
                fontSize: '14px',
              }}
              autoFocus
            >
              CANCLE
            </Button>
            <Button
              onClick={handleEditShortName}
              variant="contained"
              size="small"
              style={{
                backgroundColor: '#3DAC16',
                color: 'white',
                fontSize: '14px',
              }}
              disabled={shortName === ''}
            >
              SUBMIT
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

export default CitiesCard;
