import {
  primaryColor,
  dangerColor,
  successColor,
} from 'assets/jss/material-dashboard-pro-react.jsx';

const customInputStyle = {
  disabled: {
    '&:before': {
      borderColor: 'transparent !important',
    },
  },
  underline: {
    '&:hover:not($disabled):before,&:before': {
      borderColor: '#D2D2D2 !important',
      borderWidth: '1px !important',
    },
    '&:after': {
      borderColor: primaryColor,
    },
  },
  underlineError: {
    '&:after': {
      borderColor: dangerColor,
    },
  },
  underlineSuccess: {
    '&:after': {
      borderColor: successColor,
    },
  },
  labelRoot: {
    color: '#777575 !important',
    letterSpacing: '0.00638em',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'",
    fontWeight: '400',
    fontSize: '0.96rem !important',
    lineHeight: '1.42857',
    // marginBottom: '10px',

    // top: '10px',
    '& + $underline': {
      marginTop: '0px',
    },
  },
  labelRootError: {
    color: `${dangerColor} !important`,
  },
  labelRootSuccess: {
    color: `${successColor} !important`,
  },
  feedback: {
    position: 'absolute',
    bottom: '5px',
    right: '0',
    zIndex: '2',
    display: 'block',
    width: '1em',
    height: '1em',
    textAlign: 'center',
    pointerEvents: 'none',
  },
  feedbackRight: {
    marginRight: '22px',
  },
  formControl: {
    margin: '0 0 17px 0',
    paddingTop: '27px',
    position: 'relative',
    '& svg,& .fab,& .far,& .fal,& .fas': {
      color: '#495057',
    },
  },
  whiteUnderline: {
    '&:hover:not($disabled):before,&:before': {
      backgroundColor: '#FFFFFF',
    },
    '&:after': {
      backgroundColor: '#FFFFFF',
    },
  },
  input: {
    color: '#495057',
    marginTop: '15px',
    '&,&::placeholder': {
      fontSize: '14px',
      fontFamily: '"Rajdhani","Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: '400',
      lineHeight: '1.42857',
      opacity: '1',
    },
    '&::placeholder': {
      color: '#AAAAAA',
    },
  },
  whiteInput: {
    '&,&::placeholder': {
      color: '#FFFFFF',
      opacity: '1',
    },
  },
  fontBig: {
    color: '#000',
    fontSize: '18px !important',
    '&,&::placeholder': {
      fontSize: '18px !important',
      fontFamily: '"Rajdhani","Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: '400',
      lineHeight: '1.42857',
      opacity: '1',
    },
    '&::placeholder': {
      color: '#AAAAAA',
    },
  },
};

export default customInputStyle;
