/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable func-names */
/* eslint-disable class-methods-use-this */
/* eslint no-underscore-dangle: 0 */

import React from 'react';
import { withRouter } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Route, Switch } from 'react-router';
import indexRoutes from 'routes/index.jsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Online } from 'react-detect-offline';
import Snackbars from 'components/Snackbar/Snackbar.jsx';
import AddAlert from '@material-ui/icons/AddAlert';
import Cookies from 'universal-cookie';

import { setUserDetails, setSystemDetails } from '../reducers/main';
import firebase from '../config/config';
import getToken from '../utils/getToken';
import auditLogger from '../utils/auditLoggerToPG';
import InternetConnectionAlert from '../views/Components/InternetConnectionAlert';

const cookies = new Cookies();
require('dotenv').config();
/**
 * Represents a Root Componet.
 * @class
 */
const db = firebase.firestore();

function getIPAddress() {
  return new Promise((resolve, reject) => {
    const peerConnection = new RTCPeerConnection();
    peerConnection.createDataChannel('');
    peerConnection
      .createOffer()
      .then(sdp => peerConnection.setLocalDescription(sdp))
      .catch(error => reject(error));

    peerConnection.onicecandidate = event => {
      if (event.candidate) {
        const ipAddress = event.candidate.address;
        resolve(ipAddress);
      }
    };
  });
}

function getUserIP(onNewIP) {
  //  onNewIp - your listener function for new IPs
  // compatibility for firefox and chrome
  const myPeerConnection =
    window.RTCPeerConnection ||
    window.mozRTCPeerConnection ||
    window.webkitRTCPeerConnection;
  // eslint-disable-next-line new-cap
  const pc = new myPeerConnection({
    iceServers: [],
  });

  const noop = function() {};

  const localIPs = {};

  const ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g;

  function iterateIP(ip) {
    if (!localIPs[ip]) onNewIP(ip);
    localIPs[ip] = true;
  }

  // create a bogus data channel
  pc.createDataChannel('');

  // create offer and set local description
  pc.createOffer(sdp => {
    sdp.sdp.split('\n').forEach(line => {
      if (line.indexOf('candidate') < 0) return;
      line.match(ipRegex).forEach(iterateIP);
    });

    pc.setLocalDescription(sdp, noop, noop);
  }, noop);

  // listen for candidate events
  pc.onicecandidate = function(ice) {
    if (
      !ice ||
      !ice.candidate ||
      !ice.candidate.candidate ||
      !ice.candidate.candidate.match(ipRegex)
    )
      return;
    ice.candidate.candidate.match(ipRegex).forEach(iterateIP);
  };
}

class App extends React.Component {
  /*
   * Main app Starts
   */
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      isRefreshNotification: true,
      isAuthenticated: false,
      userId: '',
      connectionNotifText: '',
    };
    this.refreshPage = this.refreshPage.bind(this);
  }

  componentDidMount() {
    getIPAddress()
      .then(ipAddress => {
        const systemDetails = {
          ipAddress,
          platform: navigator.platform,
          vendor: navigator.vendor,
          language: navigator.language,
          userAgent: navigator.userAgent,
        };

        localStorage.setItem('systemDetails', JSON.stringify(systemDetails));
      })
      .catch(error => null);
    getUserIP(ip => {
      const systemDetails = {
        ipAddress: ip,
        platform: navigator.platform,
        vendor: navigator.vendor,
        language: navigator.language,
        userAgent: navigator.userAgent,
      };
      // eslint-disable-next-line react/destructuring-assignment
      this.props.setSystemDetails(systemDetails);
    });
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        db.collection(`Users`)
          .doc(user.uid)
          .onSnapshot(doc => {
            this.getMatchMakingToken(doc.data());
            // eslint-disable-next-line react/destructuring-assignment
            let { isRefresh } = doc.data();
            if (isRefresh === undefined) {
              isRefresh = false;
            }
            this.props.setUserDetails(doc.data(), user.uid);

            this.setState({
              loaded: true,
              isAuthenticated: true,
              userId: user.uid,
              isRefresh,
            });
          });
      } else {
        this.setState({ loaded: true });
      }
    });
  }

  capitalizeFirstLetter(str) {
    // converting first letter to uppercase
    const capitalized = str.charAt(0).toUpperCase() + str.slice(1);

    return capitalized;
  }

  getMatchMakingToken(user) {
    const systemDetails = JSON.parse(localStorage.getItem('systemDetails'));
    let activeRole = '';
    const userId = user.uid;
    if (user.isOps) {
      activeRole = 'ops';
    } else if (user.isAdmin) {
      activeRole = 'admin';
    } else if (user.isFinance) {
      activeRole = 'finance';
    } else if (user.isSales) {
      activeRole = 'sales';
    } else if (user.isBranchOps) {
      activeRole = 'branch-ops';
    } else if (user.isFinanceRecievable) {
      activeRole = 'finance-recievable';
    } else if (user.isFinancePayable) {
      activeRole = 'finance-payable';
    } else if (user.isTrackingRole) {
      activeRole = 'tracking-role';
    } else if (user.isPartnerExec) {
      activeRole = 'partner-exec';
    } else if (user.isTraffic) {
      activeRole = 'traffic';
    } else if (user.isPartnerExecManager) {
      activeRole = 'partner-exec-manager';
    }
    const body = {
      user_id: userId,
      role: activeRole,
    };
    getToken(
      'matchmaking-api/get-jwt-token',
      'post',
      body,
      true,
      false,
      'matchMaking'
    )
      .then(response => {
        const { token } = response;
        if (token) {
          cookies.set('token', token);
        }
        const auditPayload = {
          collection: 'Login',
          orgId: null,
          data: {},
          message: `${user.email} logged in`,
          systemDetails,
          type: `user login check`,
          role: `${this.capitalizeFirstLetter(activeRole.split('is')[0])}`,
          eventDateTime: new Date().toISOString(),
          source: 'office',
        };
        auditLogger(auditPayload);
      })
      .catch(err => {
        console.log('Error', err);
      });
  }

  componentDidUpdate() {
    /** all pages will start from the top */
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {}

  showNotification(place) {
    if (!this.state[place]) {
      const x = [];
      x[place] = true;
      this.setState(x);
      // use this to make the notification autoclose
      setTimeout(() => {
        x[place] = false;
        this.setState(x);
      }, 6000);
    }
  }

  /**
   * Will Refresh The Page
   * @function
   */
  refreshPage() {
    const { userId } = this.state;
    db.collection(`Users`)
      .doc(userId)
      .update({
        isRefresh: false,
        modifiedAt: new Date(),
      })
      .then(success => {
        window.location.reload();
      });
  }

  /**
   * Logs out user
   * @function
   */

  render() {
    const {
      loaded,
      connectionNotif,
      connectionNotifText,
      connectionNotifStatus,
      isRefresh,
    } = this.state;

    return (
      <>
        {loaded && (
          <Switch>
            {indexRoutes.map((prop, key) => (
              // eslint-disable-next-line react/no-array-index-key
              <Route path={prop.path} component={prop.component} key={key} />
            ))}
          </Switch>
        )}
        <InternetConnectionAlert />
        <Snackbars
          place="br"
          color={connectionNotifStatus}
          icon={AddAlert}
          message={connectionNotifText}
          open={connectionNotif}
          closeNotification={() => this.setState({ connectionNotif: false })}
          close
        />
        {isRefresh && (
          <Snackbars
            place="tc"
            color="success"
            icon={AddAlert}
            message="We’ve fine-tuned Lobb Office under the hood. Refresh this tab to experience the latest Lobb Office."
            open={isRefresh}
            actionButton
            actionButtonName="Refresh"
            onSubmit={this.refreshPage}
          />
        )}
      </>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setUserDetails,
      setSystemDetails,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(withRouter(App));
