import React from "react";
import PropTypes from "prop-types";
// react plugin for creating charts
// react plugin for creating vector maps

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

import DateRange from "@material-ui/icons/DateRange";
import LocalShipping from "@material-ui/icons/LocalShipping";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import firebase from "../../config/config";


import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

const db = firebase.firestore();

class TransporterDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: "",
      uid: "",
      truckers: [],
      isLoading: false
    };
    this.getTruckersData = this.getTruckersData.bind(this);
  }
  componentDidMount() {
    //const userRef = db.collection("Users");
    this.getCounts();
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.getTruckersData(user)
      }
    })
  }

  getCounts(){
    const countsRef = db.collection("Counters");
    countsRef.doc('Truckers').onSnapshot(doc=>{
      this.setState({truckers:doc.data().count})
    })
    
  }

  /**
   * Will Get ALl the Truckers For The User
   * @function
   */
  getTruckersData(user) {
    const TruckerRef = db
      .collection("Truckers");
    TruckerRef.where('status','==','approved').where('updatedBy','==', user.uid).get()
      .then(
        resultData => {
          let truckers = [];
          resultData.forEach(trucker => {
            //  Checks if transporter and user have any common branches
              truckers.push(trucker.data());
          });
          this.setState({
            truckers
          });
        },
        err => {
          console.log("err", err);
        }
      )
      .catch(err => {
        console.log("err", err);
      });
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };
  handleChangeIndex = index => {
    this.setState({ value: index });
  };
  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={6}>
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <LocalShipping />
                </CardIcon>
                <p className={classes.cardCategory}>Truckers</p>
                <h3 className={classes.cardTitle}>
                  {" "}
                  {this.state.truckers.length}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <DateRange />
                  Last 24 Hours
                </div>
              </CardFooter>
            
            </Card>
          </GridItem>
          {/* <GridItem xs={12} sm={12} md={12} lg={10}>
              <SalesTransaction/>
          </GridItem> */}
        </GridContainer>
      </div>
    );
  }
}

TransporterDashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(TransporterDashboard);

