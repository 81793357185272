import DashboardIcon from '@material-ui/icons/Dashboard';
import TransferWithinAStation from '@material-ui/icons/TransferWithinAStation';
import SwapHoriz from '@material-ui/icons/SwapHoriz';
import LocalShipping from '@material-ui/icons/LocalShipping';
import Transporters from 'views/Transporters/Transporters.jsx';
import AddTransporters from 'views/Transporters/AddTransporters.jsx';
import Truckers from 'views/Truckers/Truckers.jsx';
import AddTruckers from 'views/Truckers/AddTruckers.jsx';
import Transactions from 'views/Transactions/SalesTransactions.jsx';
import AddTransactions from 'views/Transactions/AddTransactions.jsx';
import DashboardRedirect from '../views/Dashboard/DashboardRedirect';

const trafficDashRoutes = [
  {
    path: '/traffic/dashboard',
    name: 'Dashboard',
    icon: DashboardIcon,
    component: DashboardRedirect,
  },
  {
    collapse: true,
    path: '/traffic/transporters',
    name: 'Transporters',
    state: 'openTransporters',
    icon: TransferWithinAStation,
    views: [
      {
        path: '/traffic/transporters/view',
        name: 'View',
        mini: 'V',
        component: Transporters,
      },
      {
        path: '/traffic/transporters/add',
        name: 'Add',
        mini: 'A',
        component: AddTransporters,
      },
    ],
  },
  {
    collapse: true,
    path: '/traffic/truckers',
    name: 'Truckers',
    icon: LocalShipping,
    state: 'openTruckers',
    views: [
      {
        path: '/traffic/truckers/view',
        name: 'View',
        mini: 'V',
        component: Truckers,
      },
      {
        path: '/traffic/truckers/add',
        name: 'Add',
        mini: 'A',
        component: AddTruckers,
      },
    ],
  },
  {
    collapse: true,
    path: '/traffic/transactions',
    name: 'Transactions',
    icon: SwapHoriz,
    state: 'openTransactions',
    views: [
      {
        path: '/traffic/transactions/view',
        name: 'View',
        mini: 'V',
        component: Transactions,
      },
      {
        path: '/traffic/transactions/add',
        name: 'Add',
        mini: 'A',
        component: AddTransactions,
      },
    ],
  },
  {
    redirect: true,
    path: '/traffic',
    pathTo: '/traffic/dashboard',
    name: 'Dashboard',
  },
];
export default trafficDashRoutes;
