import React from 'react';
import AddBranches from './AddBranches';

const BranchesManagement = () => (
  <div>
    <AddBranches />
  </div>
);

export default BranchesManagement;
