/* eslint-disable react/no-unused-state */
/* eslint-disable eqeqeq */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import CardIcon from 'components/Card/CardIcon.jsx';
import Assignment from '@material-ui/icons/Assignment';
import Edit from '@material-ui/icons/Edit';
import moment from 'moment';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Button from 'components/CustomButtons/Button.jsx';
// import Table from 'components/Table/Table.jsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';

import purple from '@material-ui/core/colors/purple';
import TransactionStyle from 'assets/jss/material-dashboard-pro-react/views/TransactionStyle';
import AppInstalledIcon from '../../assets/icons/AppDownload 2.svg';
import firebase from '../../config/config';
import OpsMaskedListViewTransporters from './Masking/OpsMaskedListViewTransporters';
import history from '../../history';

const db = firebase.firestore();
const exportExcel = firebase.functions().httpsCallable('paas-exportExcel');
const NameTableRowStyle = { display: 'flex', alignItems: 'center' };
class Transporter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transporters: [],
      isLoading: false,
      excelLoading: false,
      progressValue: 0,
      progressBuffer: 10,
      totalData: 0,
      currentPage: 1,
      maxItemsPerPage: 20,
    };
    this.getTable = this.getTable.bind(this);
    this.getTransportersData = this.getTransportersData.bind(this);
  }

  componentDidMount() {}

  /**
   * Will Get ALl the Truckers For The User
   * @function
   */

  changePage(direction) {
    if (direction !== 'next') {
      this.setState({
        currentPage: this.state.currentPage - 1,
      });
    } else if (direction == 'next') {
      this.setState({
        currentPage: this.state.currentPage + 1,
      });
    }
  }

  getTransportersData() {
    const colors = ['info', 'success', 'danger'];
    const transporterRef = db
      .collection('Transporters')
      .where('status', '==', 'approved')
      .orderBy('name', 'asc');
    const { classes } = this.props;
    this.setState({ isLoading: true });
    transporterRef.onSnapshot(transporterData => {
      const transporters = [];
      transporterData.forEach(transporter => {
        transporters.push({
          color: colors[0],
          data: [
            <div style={NameTableRowStyle}>
              {transporter.data().blacklist ? (
                <del>{transporter.data().name}</del>
              ) : (
                <p style={{ margin: 0 }}> {transporter.data().name}</p>
              )}

              {transporter.data().isAppLoggedIn ? (
                <img
                  src={AppInstalledIcon}
                  alt="icon"
                  style={{ width: '14px', margin: '10px' }}
                />
              ) : (
                ''
              )}
            </div>,

            transporter.data().blacklist ? (
              <del>{transporter.data().phoneNumber}</del>
            ) : (
              transporter.data().phoneNumber
            ),
            transporter.data().blacklist ? (
              <del>{transporter.data().email}</del>
            ) : (
              transporter.data().email
            ),
            transporter.data().blacklist ? (
              <del>{transporter.data().contactPerson}</del>
            ) : (
              transporter.data().contactPerson
            ),
            <Button
              color="warning"
              className={classes.actionButton}
              key={transporter.data().transporterId}
              onClick={() => this.openEditForm(transporter.data())}
              justIcon
            >
              <Edit className={classes.icon} />
            </Button>,
          ],
        });
        this.setState({
          transporters,
          isLoading: false,
          totalData: transporters.length,
        });
      });
    });
  }

  /**
   * Will return the Table Component
   * @function
   */

  getTable() {
    const { classes, activeRole } = this.props;
    // const { transporters } = this.state;

    return (
      <Card>
        <CardHeader color="warning" icon>
          <CardIcon color="warning">
            <Assignment />
          </CardIcon>
        </CardHeader>
        <CardBody className={classes.customCardContentClass}>
          <OpsMaskedListViewTransporters activeRole={activeRole} />
        </CardBody>
      </Card>
    );
  }

  openEditForm(transporter) {
    const { activeRole } = this.props;
    history.push({
      pathname: `/${activeRole}/truckers/view/${transporter.transporterId}`,
      state: transporter.transporterId,
    });
  }

  getStartDate(month) {
    const lastMonthFirstDate = moment()
      .subtract(month, 'months')
      .startOf('month')
      .format('YYYY-MM-DD');
    return lastMonthFirstDate;
  }

  getEndDate(month) {
    const lastMonthLastDate = moment()
      .subtract(month, 'months')
      .endOf('month')
      .format('YYYY-MM-DD');
    return lastMonthLastDate;
  }

  getMonthName(month) {
    const lastMonthName = moment()
      .subtract(month, 'months')
      .endOf('month')
      .format('MMMM-YYYY');
    return lastMonthName;
  }

  async getTruckersCount() {
    const body = [
      {
        startDate: this.getStartDate(12),
        endDate: this.getEndDate(12),
        monthName: this.getMonthName(12),
      },
      {
        startDate: this.getStartDate(11),
        endDate: this.getEndDate(11),
        monthName: this.getMonthName(11),
      },
      {
        startDate: this.getStartDate(10),
        endDate: this.getEndDate(10),
        monthName: this.getMonthName(10),
      },
      {
        startDate: this.getStartDate(9),
        endDate: this.getEndDate(9),
        monthName: this.getMonthName(9),
      },
      {
        startDate: this.getStartDate(8),
        endDate: this.getEndDate(8),
        monthName: this.getMonthName(8),
      },
      {
        startDate: this.getStartDate(7),
        endDate: this.getEndDate(7),
        monthName: this.getMonthName(7),
      },
      {
        startDate: this.getStartDate(6),
        endDate: this.getEndDate(6),
        monthName: this.getMonthName(6),
      },
      {
        startDate: this.getStartDate(5),
        endDate: this.getEndDate(5),
        monthName: this.getMonthName(5),
      },
      {
        startDate: this.getStartDate(4),
        endDate: this.getEndDate(4),
        monthName: this.getMonthName(4),
      },
      {
        startDate: this.getStartDate(3),
        endDate: this.getEndDate(3),
        monthName: this.getMonthName(3),
      },
      {
        startDate: this.getStartDate(2),
        endDate: this.getEndDate(2),
        monthName: this.getMonthName(2),
      },
      {
        startDate: this.getStartDate(1),
        endDate: this.getEndDate(1),
        monthName: this.getMonthName(1),
      },
    ];
    const apiBody = {};
    const promises = [];
    this.setState({
      excelLoading: true,
    });
    body.forEach((element, i) => {
      const startDate = new Date(element.startDate);
      const endDate = new Date(element.endDate).setHours(23, 59, 59, 999);
      const newEndData = new Date(endDate);
      const truckerRef = db
        .collection('Transporters')
        .where('createdAt', '>=', startDate)
        .where('createdAt', '<=', newEndData);
      promises.push(truckerRef.get());
      this.getTruckerCount(truckerRef).then(length => {
        body[i].length = length;
      });
    });
    Promise.all(promises).then(() => {
      setTimeout(() => {
        const rows = [{}];
        const headers = {};
        body.forEach((data, i) => {
          const month = data.monthName;
          const { length } = data;
          headers[month] = 'string';
          rows[0][month] = length;
        });
        apiBody.data = {
          headers,
          rows,
        };
        let fileName = `LO_Transporters_Report${new Date().getTime()}.xlsx`;
        fileName = fileName.replace(/ /g, '_');
        apiBody.filename = fileName;
        apiBody.format = 'xlsx';
        apiBody.is_polling = false;
        exportExcel(apiBody)
          .then(res => {
            const apiRes = res.data;
            if (apiRes.found === false) {
              this.resendRequest(apiBody);
            } else if (apiRes.found === true) {
              if (apiRes.hasOwnProperty('url')) {
                this.setState({
                  progressValue: 100,
                  progressBuffer: 100,
                  excelLoading: false,
                });
                const a = document.createElement('A');
                a.href = apiRes.url;
                a.download = apiRes.url.substr(apiRes.url.lastIndexOf('/') + 1);

                // window.location.href = apiRes.url;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                // window.location.href = apiRes.url;
              }
            }
          })
          .catch(err => {
            this.setState({
              excelLoading: false,
            });
          });
      }, 2000);
    });
  }

  resendRequest(body) {
    const { progressValue, progressBuffer } = this.state;
    const adding = 2;
    setTimeout(() => {
      this.setState({
        progressValue: Number(progressValue + adding),
        progressBuffer: Number(progressBuffer + adding),
      });
      this.resendExcel(body);
    }, 1000);
  }

  resendExcel(body) {
    body.is_polling = true;
    exportExcel(body)
      .then(res => {
        const apiRes = res.data;
        if (apiRes.found === false) {
          this.resendRequest(body);
        } else if (apiRes.found) {
          this.setState({
            progressValue: 100,
            progressBuffer: 100,
            excelLoading: false,
          });
          if (apiRes.hasOwnProperty('url')) {
            const a = document.createElement('A');
            a.href = apiRes.url;
            a.download = apiRes.url.substr(apiRes.url.lastIndexOf('/') + 1);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
        }
      })
      .catch(err => {
        this.setState({
          excelLoading: false,
        });
        console.log('Error', err);
      });
  }

  getTruckerCount(truckerRef) {
    return new Promise((resolve, reject) => {
      truckerRef.get().then(snapshot => {
        const { length } = snapshot.docs;
        resolve(length);
      });
    });
  }

  async getTruckerCountN(truckerRef) {
    try {
      const snap = await truckerRef.get();
      return snap.doc.length;
    } catch {
      console.log('Error');
      return 0;
    }
  }

  render() {
    const { classes } = this.props;
    const { excelLoading, progressBuffer, progressValue } = this.state;
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader className={`${classes.cardHeader}`} color="warning">
                <GridContainer>
                  <GridItem xs={12} md={3} lg={4}>
                    <p className={`${classes.cardHeader}`}>Transporters</p>
                  </GridItem>
                  <GridItem xs={12} md={7} lg={8}>
                    <div style={{ float: 'right' }}>
                      {excelLoading ? (
                        <CircularProgress
                          className={classes.progress}
                          style={{ color: purple[500] }}
                          thickness={7}
                        />
                      ) : (
                        <Button
                          color="info"
                          onClick={() => this.getTruckersCount()}
                          className={classes.updateProfileButton}
                        >
                          Download
                        </Button>
                      )}
                    </div>
                    {excelLoading ? (
                      <LinearProgress
                        variant="buffer"
                        value={progressValue}
                        valueBuffer={progressBuffer}
                      />
                    ) : (
                      ''
                    )}
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody
                className={`${classes.cardHeader} ${classes.textCenter}`}
              >
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  {this.getTable()}
                </GridItem>
              </CardBody>
              <CardFooter stats>
                <div className={classes.stats} />
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Transporter.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  activeRole: state.main.activeRole,
});

export default connect(mapStateToProps)(
  withStyles(TransactionStyle)(Transporter)
);
