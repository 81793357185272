import DashboardIcon from '@material-ui/icons/Dashboard';
import PartnerExecFeatureOffset from '../views/PartnerExec/PartnerExecFeatureOffset';

const partnerExecDashRoutes = [
  {
    path: '/partner-exec/dashboard',
    name: 'Dashboard',
    icon: DashboardIcon,
    component: PartnerExecFeatureOffset,
  },
];
export default partnerExecDashRoutes;
