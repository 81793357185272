import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import counter from './counter';
import transactions from './transactions';
import main from './main';

export default combineReducers({
  routing: routerReducer,
  counter,
  transactions,
  main,
});
