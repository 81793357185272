/* eslint-disable react/sort-comp */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// @material-ui/Icons
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Button from 'components/CustomButtons/Button.jsx';

import Check from '@material-ui/icons/Check';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// Component for Transactions Card
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TransactionStyle from 'assets/jss/material-dashboard-pro-react/views/TransactionStyle';
import classNames from 'classnames';
import Search from '@material-ui/icons/Search';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import SweetAlert from 'react-bootstrap-sweetalert';
import Snackbar from 'components/Snackbar/Snackbar.jsx';
import AddAlert from '@material-ui/icons/AddAlert';
import InputLabel from '@material-ui/core/InputLabel';
import Datetime from 'react-datetime';
import FormControl from '@material-ui/core/FormControl';
import ReactSelect from 'react-select';
import { MenuItem, Select } from '@material-ui/core';
import TransactionsCard from './Sections/TransactionsCard';

import DetailedTransactions from './Sections/DetailedTransactions';

import { switchActivePage, setTransactions } from '../../reducers/transactions';
import firebase from '../../config/config';

const db = firebase.firestore();

class Transactions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      financePrevActive: false,
      financeNextActive: false,
      // eslint-disable-next-line react/no-unused-state
      checksAlert: false,
      searchText: '',
      searchTextState: '',
      fromDate: '',
      toDate: '',
      isFilter: false,
      truckers: [],
      selectedTrucker: null,
      truckerName: '',
      truckerId: null,
      transporterId: null,
      transporterName: null,
      transporters: [],
      selectedTransporter: null,
      alert: null,
      tc: false,
      isPodCollected: false,
      isPodSubmitted: false,
      riskStatusOptions: [
        {
          label: 'Green',
          value: 'green',
          name: 'Green',
        },
        {
          label: 'Amber',
          value: 'amber',
          name: 'Amber',
        },
        {
          label: 'Red',
          value: 'red',
          name: 'Red',
        },
      ],
      selectedRiskStatus: null,
      selectedBranch: '',
    };
    this.addTransactions = this.addTransactions.bind(this);
    this.showNextApproved = this.showNextApproved.bind(this);
    this.showPrevApproved = this.showPrevApproved.bind(this);
    this.showInitialApproved = this.showInitialApproved.bind(this);
    this.unsubscribePage = () => null;
    this.showChecksAlert = this.showChecksAlert.bind(this);
    this.closeChecksAlert = this.closeChecksAlert.bind(this);
    this.confirmDates = this.confirmDates.bind(this);
    this.fromDateChange = this.fromDateChange.bind(this);
    this.toDateChange = this.toDateChange.bind(this);
    this.searchTransaction = this.searchTransaction.bind(this);
    this.filterTransactions = this.filterTransactions.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getTruckers = this.getTruckers.bind(this);
    this.handleTrucker = this.handleTrucker.bind(this);
    this.handleTransporter = this.handleTransporter.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.getTransporters = this.getTransporters.bind(this);
  }

  componentDidMount() {
    const { userDetails } = this.props;
    //  const { userDetails } = this.props;
    // eslint-disable-next-line react/destructuring-assignment
    this.props.switchActivePage(false);
    this.getTruckers();
    this.getTransporters();
    this.setState({ selectedBranch: userDetails.branches[0] });
    this.showInitialApproved(userDetails.branches[0]);
  }

  componentWillUnmount() {
    let id = window.setTimeout(null, 0);
    while (id--) {
      window.clearTimeout(id);
    }
  }

  handleToggle(value, stateName) {
    this.setState({
      [`${stateName}`]: value,
    });
  }

  handleRiskStatus = selectedRiskStatus => {
    this.setState({
      selectedRiskStatus,
    });
  };

  /**
   * will return All the Truckers Available for User
   * @function
   * @param {String} companyId
   */
  getTruckers() {
    const TruckerRef = db.collection('Truckers');
    TruckerRef.where('status', '==', 'approved')
      .get()
      .then(
        resultData => {
          const truckers = [];
          resultData.forEach(trucker => {
            if (trucker.data().updatedByRole !== 'premiumTransporter') {
              const data = trucker.data();
              data.label = trucker.data().name;
              data.value = trucker.data().truckerId;
              truckers.push(data);
            }
          });
          this.setState({
            truckers,
          });
        },
        err => {
          console.log('err', err);
        }
      )
      .catch(err => {
        console.log('err', err);
      });
  }

  /**
   * will return All the Transporters Available for User
   * @function
   * @param {String} companyId
   */
  getTransporters() {
    const TransporterRef = db.collection(`Transporters`);
    TransporterRef.where('status', '==', 'approved')
      .get()
      .then(
        resultData => {
          const transporters = [];
          resultData.forEach(transporter => {
            //  Checks if transporter and user have any common branches
            const data = transporter.data();
            data.label = transporter.data().name;
            data.value = transporter.data().transporterId;
            transporters.push(data);
          });
          this.setState({ transporters });
        },
        err => {
          console.log('err', err);
        }
      )
      .catch(err => {
        console.log('err', err);
      });
  }

  handleTrucker = selectedTrucker => {
    this.setState({
      selectedTrucker,
      truckerName: selectedTrucker.name,
      truckerId: selectedTrucker.truckerId,
    });
  };

  handleTransporter = selectedTransporter => {
    this.setState({
      selectedTransporter,
      transporterName: selectedTransporter.name,
      transporterId: selectedTransporter.transporterId,
    });
  };

  /**
   * will redirect to Add Transactions Page
   * @function
   */
  addTransactions() {
    const { history } = this.props;
    history.push('/sales/transactions/add');
  }

  showChecksAlert() {
    this.setState({
      checksAlert: true,
      checksError: '',
    });
  }

  // eslint-disable-next-line lines-between-class-members
  closeChecksAlert() {
    this.setState({
      checksAlert: false,
      checksError: '',
      // selectedTransporter: null,
      // selectedTrucker: null,
    });
  }

  // eslint-disable-next-line lines-between-class-members
  /**
   * Used to reset the filter in the transactions
   */
  resetFilter() {
    this.setState(
      {
        checksAlert: false,
        checksError: '',
        isFilter: false,
        fromDate: '',
        toDate: '',
        selectedTransporter: '',
        selectedTrucker: '',
        selectedRiskStatus: '',
      },
      () => this.showInitialApproved()
    );
  }

  /**
   * Calls when the submit button is trigged for the filter action
   */
  confirmDates() {
    const { fromDate, toDate } = this.state;
    if (fromDate !== '' && toDate === '') {
      this.showNotification('tc');
    } else if (fromDate === '' && toDate !== '') {
      this.showNotification('tc');
    } else {
      this.setState(
        {
          isFilter: true,
          checksAlert: false,
          checksError: '',
          transactionId: '',
        },
        () => this.showInitialApproved()
      );
    }
  }

  showNotification(place) {
    if (!this.state[place]) {
      const x = [];
      x[place] = true;
      this.setState(x);
      setTimeout(() => {
        x[place] = false;
        this.setState(x);
      }, 6000);
    }
  }

  searchTransaction(searchText) {
    if (searchText) {
      this.filterTransactions('approved', searchText);
    } else {
      this.showInitialApproved();
    }
  }

  filterTransactions(status, searchText) {
    // const options = {
    //   keys: [
    //     {
    //       name: 'transactionSerial',
    //       weight: 0.2,
    //     },
    //   ],
    // };
    let transactionsRef;

    const { userDetails, activeRole } = this.props;
    let { selectedBranch } = this.state;
    if (!selectedBranch) selectedBranch = userDetails.branches[0];
    // If user is an admin, show them all transactions.
    transactionsRef = db
      .collection(`Transactions`)
      .where('status', '==', status)
      .where('transactionSerial', '==', Number(searchText))
      .orderBy('createdAt', 'desc');
    //  If user is just ops, show them only their transactions.

    if (status === 'approved')
      transactionsRef = transactionsRef.where(
        'isEditedTransaction',
        '==',
        false
      );
    if (
      activeRole === 'transaction-view' ||
      activeRole === 'finance-payable' ||
      activeRole === 'finance-recievable' ||
      activeRole === 'tracking-role'
    ) {
      transactionsRef = transactionsRef.where(
        'branch',
        '==',
        this.state.selectedBranch
      );
    }

    this.setState({ [`${status}PrevActive`]: false });

    const unsubscribePage = transactionsRef.onSnapshot(transactionsData => {
      const transactions = [];
      //  this.setState({ isLoading: false });
      transactionsData.forEach(transaction => {
        const formattedTransaction = {
          ...transaction.data(),
        };
        transactions.push(formattedTransaction);
      });
      // const fuse = new Fuse(transactions, options);
      this.props.setTransactions(transactions);
    });
    if (status === 'pending') this.unsubscribePendingPage = unsubscribePage;
    else if (status === 'approved')
      this.unsubscribeApprovedPage = unsubscribePage;
    else if (status === 'rejected')
      this.unsubscribeRejectedPage = unsubscribePage;
    else if (status === 'modify') this.unsubscribeModifyPage = unsubscribePage;
  }

  fromDateChange(fromDate) {
    this.setState({ fromDate });
  }

  toDateChange(toDate) {
    this.setState({ toDate });
  }

  // function that verifies if value contains only numbers
  verifyNumber(value, stateName) {
    if (stateName === 'searchText') {
      if (value === '' || value === null) {
        this.showInitialApproved();
      }
    }
    const numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'number':
        if (this.verifyNumber(event.target.value, stateName)) {
          this.setState(
            {
              [`${stateName}State`]: 'success',
              [stateName]: event.target.value,
            },
            this.calculateFields
          );
        } else if (event.target.value === '') {
          this.setState({
            [`${stateName}State`]: 'Invalid',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'Invalid',
          });
        }
        break;
      default:
        break;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  filterCheck(data) {
    let action = '';
    if (data !== '' && data !== null && data !== undefined) {
      action = true;
    } else {
      action = false;
    }
    return action;
  }

  /**
   * Display the list of transactions
   */
  showInitialApproved(branch) {
    const { userDetails, activeRole } = this.props;
    const {
      isFilter,
      fromDate,
      toDate,
      transporterId,
      truckerId,
      isPodCollected,
      isPodSubmitted,
      selectedRiskStatus,
    } = this.state;
    let startDate = '';
    let endDate = '';
    // To restrict the dates to be automatically taken
    if (fromDate === '') {
      startDate = null;
    } else {
      startDate = new Date(fromDate);
    }
    if (toDate === '') {
      endDate = null;
    } else {
      endDate = new Date(toDate);
    }
    try {
      this.unsubscribePage();
      // eslint-disable-next-line no-empty
    } catch {}
    this.setState({ financePrevActive: false });
    let transactionsRef;
    transactionsRef = db
      .collection(`Transactions`)
      .where('status', '==', 'approved')
      .where('isEditedTransaction', '==', false)
      .limit(20 + 1);

    // Limit to 20 + 1 to see if next should be enabled or not, displaying only 20
    if (isPodCollected) {
      transactionsRef = db
        .collection(`Transactions`)
        .where('status', '==', 'approved')
        .where('isCollected', '==', true)
        .where('isEditedTransaction', '==', false)
        .orderBy('createdAt', 'desc')
        .limit(20 + 1);
    }
    if (isPodSubmitted) {
      transactionsRef = db
        .collection(`Transactions`)
        .where('status', '==', 'approved')
        .where('isSubmitted', '==', true)
        .where('isEditedTransaction', '==', false)
        .orderBy('createdAt', 'desc')
        .limit(20 + 1);
    }
    if (isFilter) {
      const fromDateAction = this.filterCheck(startDate);
      const toDateAction = this.filterCheck(endDate);
      const transporterAction = this.filterCheck(transporterId);
      const truckerAction = this.filterCheck(truckerId);
      if (fromDateAction && toDateAction) {
        transactionsRef = transactionsRef
          .where('createdAt', '>', startDate)
          .where('createdAt', '<', endDate);
      }
      if (transporterAction) {
        transactionsRef = transactionsRef.where(
          'transporterId',
          '==',
          transporterId
        );
      }
      if (truckerAction) {
        transactionsRef = transactionsRef.where('truckerId', '==', truckerId);
      }

      if (
        selectedRiskStatus !== null &&
        selectedRiskStatus !== undefined &&
        selectedRiskStatus !== ''
      ) {
        if (selectedRiskStatus.hasOwnProperty('value')) {
          if (
            selectedRiskStatus.value !== null &&
            selectedRiskStatus.value !== ''
          ) {
            transactionsRef = transactionsRef.where(
              'riskStatus',
              '==',
              selectedRiskStatus.value
            );
          }
        }
      }
    }
    if (
      activeRole === 'transaction-view' ||
      activeRole === 'finance-payable' ||
      activeRole === 'finance-recievable' ||
      activeRole === 'tracking-role'
    ) {
      transactionsRef = transactionsRef.where(
        'branch',
        '==',
        branch || this.state.selectedBranch
      );
    }
    this.unsubscribePage = transactionsRef
      .limit(20 + 1) // Limit to 20 + 1 to see if next should be enabled or not, displaying only 20
      .orderBy('createdAt', 'desc')
      .onSnapshot(resultData => {
        const transactions = [];
        resultData.forEach(transaction => {
          const formattedTransaction = {
            ...transaction.data(),
          };
          transactions.push(formattedTransaction);
        });
        let nextCursor;
        if (resultData.docs.length > 20)
          nextCursor = resultData.docs[resultData.docs.length - 1 - 1];
        else nextCursor = resultData.docs[resultData.docs.length - 1];
        const currentCursor = resultData.docs[0];

        if (resultData.docs.length < 20 + 1)
          this.setState({ financeNextActive: false });
        else this.setState({ financeNextActive: true });
        this.setState({
          nextCursor,
          currentCursor,
          initalCursor: currentCursor,
        });
        //  Remove the last one and show 20
        if (transactions.length > 20) transactions.pop();
        // eslint-disable-next-line react/destructuring-assignment
        this.props.setTransactions(transactions);
      });
  }

  showNextApproved() {
    const { activeRole } = this.props;
    const {
      isFilter,
      fromDate,
      toDate,
      transporterId,
      truckerId,
      selectedRiskStatus,
    } = this.state;
    let startDate = new Date(fromDate);
    let endDate = new Date(toDate);
    if (fromDate === '') {
      startDate = null;
    } else {
      startDate = new Date(fromDate);
    }
    if (toDate === '') {
      endDate = null;
    } else {
      endDate = new Date(toDate);
    }
    const { financeNextActive } = this.state;
    if (!financeNextActive) return;

    try {
      this.unsubscribePage();
      // eslint-disable-next-line no-empty
    } catch {}
    const { nextCursor } = this.state;
    let transactionsRef;
    transactionsRef = db
      .collection(`Transactions`)
      .where('status', '==', 'approved')
      .where('isEditedTransaction', '==', false);
    if (isFilter) {
      const fromDateAction = this.filterCheck(startDate);
      const toDateAction = this.filterCheck(endDate);
      const transporterAction = this.filterCheck(transporterId);
      const truckerAction = this.filterCheck(truckerId);
      if (fromDateAction && toDateAction) {
        transactionsRef = transactionsRef
          .where('createdAt', '>', startDate)
          .where('createdAt', '<', endDate);
      }
      if (transporterAction) {
        transactionsRef = transactionsRef.where(
          'transporterId',
          '==',
          transporterId
        );
      }
      if (truckerAction) {
        transactionsRef = transactionsRef.where('truckerId', '==', truckerId);
      }

      if (
        selectedRiskStatus !== null &&
        selectedRiskStatus !== undefined &&
        selectedRiskStatus !== ''
      ) {
        if (selectedRiskStatus.hasOwnProperty('value')) {
          if (
            selectedRiskStatus.value !== null &&
            selectedRiskStatus.value !== ''
          ) {
            transactionsRef = transactionsRef.where(
              'riskStatus',
              '==',
              selectedRiskStatus.value
            );
          }
        }
      }
    } else {
      // null
    }

    transactionsRef = transactionsRef
      .limit(20 + 1)
      .orderBy('createdAt', 'desc')
      .startAfter(nextCursor);
    if (
      activeRole === 'transaction-view' ||
      activeRole === 'finance-payable' ||
      activeRole === 'finance-recievable'
    ) {
      transactionsRef = transactionsRef.where(
        'branch',
        '==',
        this.state.selectedBranch
      );
    }
    transactionsRef.get().then(resultData => {
      const transactions = [];
      resultData.forEach(transaction => {
        const formattedTransaction = {
          ...transaction.data(),
        };
        transactions.push(formattedTransaction);
      });
      // const prevCursor = currentCursor;
      if (resultData.docs.length > 0) {
        const newCurrentCursor = resultData.docs[0];

        let newNextCursor;
        if (resultData.docs.length > 20)
          newNextCursor = resultData.docs[resultData.docs.length - 1 - 1];
        else newNextCursor = resultData.docs[resultData.docs.length - 1];

        if (resultData.docs.length < 20 + 1)
          this.setState({ financeNextActive: false });
        else this.setState({ financeNextActive: true });
        this.setState({
          financePrevActive: true,
          nextCursor: newNextCursor,
          currentCursor: newCurrentCursor,
        });
        if (transactions.length > 20) transactions.pop();
        // eslint-disable-next-line react/destructuring-assignment
        this.props.setTransactions(transactions);
      }
    });
  }

  showPrevApproved() {
    const { activeRole } = this.props;
    const {
      isFilter,
      fromDate,
      toDate,
      transporterId,
      truckerId,
      selectedRiskStatus,
    } = this.state;
    let startDate = new Date(fromDate);
    let endDate = new Date(toDate);
    if (fromDate === '') {
      startDate = null;
    } else {
      startDate = new Date(fromDate);
    }
    if (toDate === '') {
      endDate = null;
    } else {
      endDate = new Date(toDate);
    }
    const { financePrevActive } = this.state;
    if (!financePrevActive) return;
    try {
      this.unsubscribePage();
      // eslint-disable-next-line no-empty
    } catch {}
    const { currentCursor } = this.state;
    let transactionsRef;
    transactionsRef = db
      .collection(`Transactions`)
      .where('status', '==', 'approved')
      .where('isEditedTransaction', '==', false);
    if (isFilter) {
      const fromDateAction = this.filterCheck(startDate);
      const toDateAction = this.filterCheck(endDate);
      const transporterAction = this.filterCheck(transporterId);
      const truckerAction = this.filterCheck(truckerId);
      if (fromDateAction && toDateAction) {
        transactionsRef = transactionsRef
          .where('createdAt', '>', startDate)
          .where('createdAt', '<', endDate);
      }
      if (transporterAction) {
        transactionsRef = transactionsRef.where(
          'transporterId',
          '==',
          transporterId
        );
      }
      if (truckerAction) {
        transactionsRef = transactionsRef.where('truckerId', '==', truckerId);
      }

      if (
        selectedRiskStatus !== null &&
        selectedRiskStatus !== undefined &&
        selectedRiskStatus !== ''
      ) {
        if (selectedRiskStatus.hasOwnProperty('value')) {
          if (
            selectedRiskStatus.value !== null &&
            selectedRiskStatus.value !== ''
          ) {
            transactionsRef = transactionsRef.where(
              'riskStatus',
              '==',
              selectedRiskStatus.value
            );
          }
        }
      }
    } else {
      // null
    }
    if (
      activeRole === 'transaction-view' ||
      activeRole === 'finance-payable' ||
      activeRole === 'finance-recievable' ||
      activeRole === 'tracking-role'
    ) {
      transactionsRef = transactionsRef.where(
        'branch',
        '==',
        this.state.selectedBranch
      );
    }
    transactionsRef = transactionsRef
      .limit(20)
      .orderBy('createdAt', 'asc')
      .startAfter(currentCursor);

    transactionsRef.get().then(resultData => {
      let transactions = [];
      resultData.forEach(transaction => {
        const formattedTransaction = {
          ...transaction.data(),
        };
        transactions.push(formattedTransaction);
      });
      if (resultData.docs.length > 0) {
        // const prevCursor = currentCursor;
        const newNextCursor = resultData.docs[0];
        const newCurrentCursor = resultData.docs[resultData.docs.length - 1];

        const { initalCursor } = this.state;
        if (
          newCurrentCursor.data().transactionId ===
          initalCursor.data().transactionId
        ) {
          this.showInitialApproved();
        } else if (resultData.docs.length < 20) {
          this.showInitialApproved();
        } else {
          this.setState({
            financeNextActive: true,
            nextCursor: newNextCursor,
            currentCursor: newCurrentCursor,
          });
          transactions = transactions.reverse();
          // eslint-disable-next-line react/destructuring-assignment
          this.props.setTransactions(transactions);
        }
      } else {
        this.showInitialApproved();
      }
    });
  }

  modalWindow() {
    const { classes } = this.props;
    const {
      fromDate,
      toDate,
      selectedTransporter,
      transporters,
      truckers,
      selectedTrucker,
      selectedRiskStatus,
      riskStatusOptions,
    } = this.state;

    return (
      <SweetAlert
        showCancel
        confirmBtnText="Apply"
        title=""
        onConfirm={this.confirmDates}
        onCancel={this.closeChecksAlert}
        confirmBtnCssClass={`${classes.button} ${classes.success}`}
        cancelBtnCssClass={`${classes.button} ${classes.warning}`}
        style={{ height: '70%', width: '580px' }}
      >
        <h3 style={{ marginBottom: '0px' }}>
          <span className={classes.rajdhaniFont}>Filter Transactions</span>
        </h3>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6} style={{ marginTop: '20px' }}>
            <InputLabel className={classes.label}>From Date *</InputLabel>
            <br />
            <FormControl fullWidth>
              <Datetime
                onChange={this.fromDateChange}
                value={fromDate}
                dateFormat="DD-MM-YYYY"
                timeFormat={false}
                inputProps={{ placeholder: 'Select Date' }}
                required
              />
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} style={{ marginTop: '20px' }}>
            <InputLabel className={classes.label}>To Date</InputLabel>
            <br />
            <FormControl fullWidth>
              <Datetime
                onChange={this.toDateChange}
                value={toDate}
                dateFormat="DD-MM-YYYY"
                timeFormat={false}
                inputProps={{ placeholder: 'Select Date' }}
                required
              />
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} style={{ marginTop: '20px' }}>
            <InputLabel className={classes.labelText}>
              Select Transporter
            </InputLabel>
            <ReactSelect
              value={selectedTransporter}
              onChange={this.handleTransporter}
              options={transporters}
              placeholder="Select Transporter"
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} style={{ marginTop: '20px' }}>
            <InputLabel className={classes.labelText}>
              Select Trucker
            </InputLabel>
            <ReactSelect
              value={selectedTrucker}
              onChange={this.handleTrucker}
              options={truckers}
              placeholder="Select Trucker"
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} style={{ marginTop: '20px' }}>
            <InputLabel className={classes.labelText}>
              Transaction Risk
            </InputLabel>
            <ReactSelect
              value={selectedRiskStatus}
              onChange={this.handleRiskStatus}
              options={riskStatusOptions}
              placeholder="Select Risk Status"
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} style={{ marginTop: '20px' }} />
          <GridItem xs={12} sm={12} md={6} style={{ marginTop: '40px' }}>
            <FormControlLabel
              classes={{
                root: classes.checkboxLabelControl,
                label: classes.checkboxLabel,
              }}
              control={
                <Checkbox
                  onChange={() =>
                    this.handleToggle(
                      !this.state.isPodCollected,
                      'isPodCollected'
                    )
                  }
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  checked={this.state.isPodCollected}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{ checked: classes.checked }}
                />
              }
              label={<span>POD Collected</span>}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} style={{ marginTop: '40px' }}>
            <FormControlLabel
              classes={{
                root: classes.checkboxLabelControl,
                label: classes.checkboxLabel,
              }}
              control={
                <Checkbox
                  onChange={() =>
                    this.handleToggle(
                      !this.state.isPodSubmitted,
                      'isPodSubmitted'
                    )
                  }
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  checked={this.state.isPodSubmitted}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{ checked: classes.checked }}
                />
              }
              label={<span>POD Submitted</span>}
            />
          </GridItem>
        </GridContainer>
        <Snackbar
          place="tc"
          color="danger"
          icon={AddAlert}
          message="Select From and To date."
          open={this.state.tc}
          closeNotification={() => this.setState({ tc: false })}
          close
        />
        {this.state.isFilter && (
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6} textCenter>
              <Button
                color="danger"
                aria-label="edit"
                size="sm"
                style={{ float: 'center' }}
                // className={}
                onClick={() => this.resetFilter()}
              >
                Reset
              </Button>
            </GridItem>
          </GridContainer>
        )}
      </SweetAlert>
    );
  }

  handleSubmit(event) {
    const { searchText } = this.state;
    if (searchText) {
      this.filterTransactions('approved', searchText);
    } else {
      this.showInitialApproved();
    }
    event.preventDefault();
  }

  handleBranchChange = event => {
    this.setState({ selectedBranch: event.target.value }, () =>
      this.showInitialApproved(event.target.value)
    );
  };

  selectBranch() {
    const { classes, userDetails } = this.props;
    const { selectedBranch } = this.state;
    return (
      <div style={{ width: '200px' }}>
        <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
            Select Branch
          </InputLabel>
          <Select
            MenuProps={{ className: classes.selectMenu }}
            classes={{ select: classes.select }}
            value={selectedBranch}
            onChange={this.handleBranchChange}
            inputProps={{ name: 'branch', id: 'simple-select' }}
          >
            <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
              Select Branch
            </MenuItem>
            {userDetails.branches.map((branch, i) => (
              <MenuItem
                key={branch}
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
                value={branch}
              >
                {branch}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  }

  render() {
    const { classes, userDetails } = this.props;
    const { isDetailedPage, transactions, activeRole } = this.props;
    const {
      financePrevActive,
      financeNextActive,
      checksAlert,
      isFilter,
    } = this.state;
    const { searchText, searchTextState } = this.state;
    const searchButton = `${classes.top} ${classes.searchButton} ${classNames({
      [classes.searchRTL]: '',
    })}`;
    return (
      <div>
        <GridContainer justify="center">
          {checksAlert && this.modalWindow()}
          {!isDetailedPage ? (
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardHeader className={`${classes.cardHeader}`} color="info">
                  <GridContainer>
                    <GridItem xs={12} md={3} lg={3}>
                      <p
                        className={`${classes.cardHeader} ${classes.rajdhaniFont}`}
                        style={{ marginBottom: '0px', fontSize: '16px' }}
                      >
                        Transactions
                      </p>
                    </GridItem>
                    <GridItem xs={12} md={7} lg={8}>
                      <div style={{ float: 'right' }}>
                        <form onSubmit={event => this.handleSubmit(event)}>
                          <CustomInput
                            formControlProps={{
                              className: `${classes.top} ${classes.search}`,
                            }}
                            inputProps={{
                              placeholder: 'Search',
                              inputProps: {
                                'aria-label': 'Search',
                                value: searchText,
                                onChange: event =>
                                  this.change(event, 'searchText', 'number'),
                                className: classes.searchInput,
                              },
                            }}
                            success={searchTextState === 'success'}
                            error={searchTextState === 'Invalid'}
                          />
                          {window.innerWidth < 600 ? (
                            <Button
                              color="white"
                              aria-label="edit"
                              justIcon
                              round
                              className={searchButton}
                              onClick={() => this.searchTransaction(searchText)}
                              style={{ marginTop: '50px' }}
                            >
                              <Search
                                className={`${classes.headerLinksSvg} ${classes.searchIcon}`}
                              />
                            </Button>
                          ) : (
                            <Button
                              color="white"
                              aria-label="edit"
                              justIcon
                              round
                              className={searchButton}
                              onClick={() => this.searchTransaction(searchText)}
                              style={{ marginTop: '16px' }}
                            >
                              <Search
                                className={`${classes.headerLinksSvg} ${classes.searchIcon}`}
                              />
                            </Button>
                          )}
                        </form>
                      </div>
                    </GridItem>
                    <GridItem xs={12} md={2} lg={1}>
                      <Button
                        color={isFilter ? 'success' : 'white'}
                        aria-label="edit"
                        justIcon
                        round
                        className={searchButton}
                        onClick={() => this.showChecksAlert()}
                        style={{ marginTop: '16px' }}
                      >
                        <i className="fa fa-filter" aria-hidden="true" />
                      </Button>
                    </GridItem>
                  </GridContainer>
                </CardHeader>
                <CardBody
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                >
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={1} />
                    <GridItem xs={12} sm={12} md={3}>
                      {/* {this.selectBranch()} */}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={1} />
                  </GridContainer>
                  {transactions.length <= 0 ? (
                    <div className={classes.textCenter}>
                      <p className={`${classes.rajdhaniFont}`}>
                        {this.state.searchText !== '' || this.state.isFilter
                          ? 'Couldn`t find the transaction you were looking for, are you sure it exists and that you have access to it?'
                          : 'No Transactions Available'}
                      </p>
                      {activeRole === 'finance' ||
                      activeRole === 'transaction-view' ||
                      activeRole === 'finance-payable' ||
                      activeRole === 'finance-recievable' ||
                      activeRole === 'tracking-role' ? null : (
                        <Button
                          round
                          color="info"
                          onClick={this.addTransactions}
                        >
                          Add Transactions
                        </Button>
                      )}
                    </div>
                  ) : activeRole === 'finance' ||
                    activeRole === 'transaction-view' ||
                    activeRole === 'finance-payable' ||
                    activeRole === 'finance-recievable' ||
                    activeRole === 'tracking-role' ? (
                    <div>
                      {activeRole === 'transaction-view' ||
                      activeRole === 'finance-payable' ||
                      activeRole === 'finance-recievable' ||
                      activeRole === 'tracking-role'
                        ? this.selectBranch()
                        : null}
                      <div>
                        <Button
                          disabled={!financePrevActive}
                          color={financePrevActive ? 'info' : 'default'}
                          size="sm"
                          style={{ float: 'left', marginLeft: '10px' }}
                          onClick={this.showPrevApproved}
                        >
                          Prev
                        </Button>
                        <Button
                          disabled={!financeNextActive}
                          color={financeNextActive ? 'info' : 'default'}
                          size="sm"
                          style={{ float: 'right' }}
                          onClick={this.showNextApproved}
                        >
                          Next
                        </Button>

                        <TransactionsCard finance transactions={transactions} />
                      </div>
                    </div>
                  ) : (
                    <TransactionsCard transactions={transactions} />
                  )}
                </CardBody>
              </Card>
            </GridItem>
          ) : (
            <GridItem xs={12} sm={12} md={12} lg={12}>
              {activeRole === 'finance' ? (
                <DetailedTransactions finance />
              ) : (
                <DetailedTransactions />
              )}
            </GridItem>
          )}
        </GridContainer>
      </div>
    );
  }
}

Transactions.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  isDetailedPage: state.transactions.isDetailedPage,
  transactions: state.transactions.transactions,
  activeRole: state.main.activeRole,
  userDetails: state.main.userDetails,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      switchActivePage,
      setTransactions,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(TransactionStyle)(Transactions));
