/* eslint-disable no-await-in-loop */
/* eslint-disable consistent-return */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Divider, Typography, Grid } from '@material-ui/core';
import FileViewer from './fileTypes/FileViewer';
import firebase from '../../config/config';
import apiCaller from '../../utils/apiCallerOutstanding';
import TruckFiles from './fileTypes/TruckFiles';
import { getViewURL } from '../../utils/fileUploadHandler';
import TruckFilesFromS3 from './fileTypes/TruckFilesFromS3';

const db = firebase.firestore();
const TruckerFiles = ({ truckerId, trucker, featureFlagFiles, activeRole }) => {
  //   const { transporterId } = truckerId;
  const [truckFile, setTruckFiles] = React.useState([]);
  const [trucksData, setTrucksData] = React.useState([]);
  const [adhaarBackUrl, setAdhaarBackUrl] = React.useState('');
  const [adhaarBackUploadedBy, setAdhaarBackUploadedBy] = React.useState('');
  const [adhaarUrl, setAdhaarUrl] = React.useState('');
  const [panUrl, setPANUrl] = React.useState('');
  const [tdsUrl, setTdsUrl] = React.useState('');
  const [panUploadedBy, setPanUploadedBy] = React.useState('');
  const [adhaarUploadedBy, setAdhaarUploadedBy] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function getAdhaarData() {
    const docRef = await db
      .collection('FileUploads')
      .doc('TruckerFiles')
      .collection('Adhaar')
      .doc(truckerId);
    // .collection('Adhaar')
    // .doc(truckerId);

    docRef
      .get()
      .then(doc => {
        if (doc.exists) {
          const data = doc.data();
          const { AdhaarFileUploadURL, AdhaarUpdatedByName } = data;
          setAdhaarUrl(AdhaarFileUploadURL);
          setAdhaarUploadedBy(AdhaarUpdatedByName);
        }
      })
      .catch(error => {
        console.log('Error getting document:', error);
      });
  }
  function getPanData() {
    const docRef = db
      .collection('FileUploads')
      .doc('TruckerFiles')
      .collection('PAN')
      .doc(truckerId);
    // .collection('PAN')
    // .doc(truckerId);

    docRef
      .get()
      .then(doc => {
        if (doc.exists) {
          const data = doc.data();
          const { PANFileUploadURL, PANUpdatedByName } = data;
          setPANUrl(PANFileUploadURL);
          setPanUploadedBy(PANUpdatedByName);
        }
      })
      .catch(error => {
        console.log('Error getting document:', error);
      });
  }

  async function getTruckFiles(documentId, collectionPaths) {
    try {
      const results = [];

      for (const collectionPath of collectionPaths) {
        const documentRef = db
          .collection('FileUploads')
          .doc('Truck_Files')
          .collection(collectionPath)
          .doc(documentId);
        const documentSnapshot = await documentRef.get();
        if (documentSnapshot.exists) {
          const documentData = documentSnapshot.data();
          results.push(documentData);
        }
      }

      return results;
    } catch (error) {
      console.log(error);
    }
  }

  function reduceTruckFIleData(data) {
    const mergedObj = data.reduce((acc, curr) => {
      const vehicleNumber = Object.values(curr)[0].VehicleNumber;
      acc[vehicleNumber] = Object.assign(
        {},
        acc[vehicleNumber],
        curr[vehicleNumber]
      );
      return acc;
    }, {});
    setTruckFiles(mergedObj);
  }

  async function getAdhaarDataFromS3() {
    const viewURLBody = {
      scope: 'trucker',
      type: 'view',
      file_type: 'trucker-aadhaar',
      trucker_id: truckerId,
    };
    getViewURL(viewURLBody)
      .then(result => {
        setAdhaarUrl(result?.data?.url);
        setAdhaarUploadedBy(result?.data?.uploaded_by);
      })
      .catch(e => console.log('error', e));
  }

  async function getAdhaarBackDataFromS3() {
    const viewURLBody = {
      scope: 'trucker',
      type: 'view',
      file_type: 'trucker-aadhaar-back',
      trucker_id: truckerId,
    };
    getViewURL(viewURLBody)
      .then(result => {
        setAdhaarBackUrl(result?.data?.url);
        setAdhaarBackUploadedBy(result?.data?.uploaded_by);
      })
      .catch(e => console.log('error', e));
  }
  function getPanDataFromS3() {
    const viewURLBody = {
      scope: 'trucker',
      type: 'view',
      file_type: 'trucker-pan',
      trucker_id: truckerId,
    };
    getViewURL(viewURLBody)
      .then(result => {
        setPANUrl(result?.data?.url);
        setPanUploadedBy(result?.data?.uploaded_by);
      })
      .catch(e => console.log('error', e));
  }

  function reduceTruckFIleDataFromS3(data) {
    const trucksArray = data?.trucks?.map(truck => ({
      truckNumber: truck.truckNumber,
      truckerId: data.truckerId,
    }));
    setTrucksData(trucksArray);
  }
  const currentActiveRole = `is${activeRole
    ?.charAt(0)
    .toUpperCase()}${activeRole?.slice(1)}`;

  async function getTdsData() {
    const currentUserDetails = firebase.auth().currentUser;
    const { Aa } = currentUserDetails;
    const userToken = Aa;
    const endPoint = `pipe/trucker-tds/signed-url?trucker_id=${truckerId}&role=${currentActiveRole}&file_type=${truckerTds}`;
    try {
      const response = await apiCaller(
        endPoint,
        {},
        `Bearer ${userToken}`,
        'nh-base',
        'get'
      );
      const fileUrl = response?.data?.file_url;
      setTdsUrl(fileUrl);
    } catch (error) {}
  }

  async function getFilesData() {
    try {
      await getAdhaarDataFromS3();
    } catch (error) {
      console.error('Error in getAdhaarData:', error);
    }

    try {
      await getPanDataFromS3();
    } catch (error) {
      console.error('Error in getPanData:', error);
    }

    try {
      await getAdhaarBackDataFromS3();
    } catch (error) {
      console.error('Error in getAdhaarBackData:', error);
    }

    try {
      await reduceTruckFIleDataFromS3(trucker);
    } catch (error) {
      console.error('Error in reduceTruckFIleData:', error);
    }
  }
  function getFinancialYearAndTruckerTds() {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();

    const startYear = month >= 3 ? year : year - 1;
    const endYear = startYear + 1;

    const currentFinancialYear = `${startYear}-${endYear.toString().slice(-2)}`;
    const formattedYear = `${startYear}${endYear.toString().slice(-2)}`;
    const truckerTds = `trucker_tds_${formattedYear}`;

    return { currentFinancialYear, truckerTds };
  }

  const { currentFinancialYear, truckerTds } = getFinancialYearAndTruckerTds();

  useEffect(() => {
    if (open && featureFlagFiles === 2) {
      getFilesData();
      getTdsData();
    } else if (open && featureFlagFiles === 0) {
      getAdhaarData();
      getPanData();
      getTruckFiles(truckerId, [
        'RC',
        'Insurance',
        'FitnessCertificate',
      ]).then(data => reduceTruckFIleData(data));
    }
  }, [open]);

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={handleClickOpen}
        style={{ fontSize: '12px' }}
        disabled={featureFlagFiles === 1}
      >
        View Files
      </Button>
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div style={{ marginTop: '10px', padding: '10px' }}>
            <Divider />
            <Typography
              variant="body2"
              style={{ textAlign: 'center', marginTop: '10px' }}
            >
              Files Uploaded :
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FileViewer
                  url={adhaarUrl}
                  UploadedBy={adhaarUploadedBy}
                  type="Adhaar"
                  featureFlagFiles={featureFlagFiles}
                />
              </Grid>
              {featureFlagFiles === 2 && (
                <Grid item xs={12}>
                  <FileViewer
                    url={adhaarBackUrl}
                    UploadedBy={adhaarBackUploadedBy}
                    type="Adhaar Back"
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <FileViewer
                  url={panUrl}
                  UploadedBy={panUploadedBy}
                  type="PAN"
                  featureFlagFiles={featureFlagFiles}
                />
              </Grid>

              <Grid item xs={12}>
                <FileViewer
                  url={tdsUrl}
                  UploadedBy={tdsUrl ? panUploadedBy : ''}
                  type={`TDS (FY ${currentFinancialYear})`}
                  featureFlagFiles={featureFlagFiles}
                />
              </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div style={{ textAlign: 'center' }}>
                  <Typography variant="body1">Truck Files</Typography>
                </div>
              </Grid>
              <Grid item xs={12}>
                {featureFlagFiles === 0 && <TruckFiles truckFile={truckFile} />}
                {featureFlagFiles === 2 &&
                  trucksData?.map(truck => (
                    <TruckFilesFromS3
                      truckNumber={truck?.truckNumber}
                      truckerId={truck?.truckerId}
                    />
                  ))}
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TruckerFiles;
