import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 220,
  },
}));

const MultiSelect = forwardRef(({ optionsCatch, options }, ref) => {
  const classes = useStyles();
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleChange = event => {
    const { value } = event.target;

    if (value.includes('select-all')) {
      if (value.length === options.length + 1) {
        setSelectedOptions([]);
        optionsCatch([]);
      } else {
        setSelectedOptions(value.filter(val => val !== 'select-all'));
        optionsCatch(value.filter(val => val !== 'select-all'));
      }
    } else {
      setSelectedOptions(value);
      optionsCatch(value);
    }
  };

  const handleSelectAll = event => {
    if (event.target.checked) {
      setSelectedOptions(options.map(option => option.value));
      optionsCatch(options.map(option => option.value));
    } else {
      setSelectedOptions([]);
      optionsCatch([]);
    }
  };

  useImperativeHandle(ref, () => ({
    resetOptions() {
      setSelectedOptions([]);
      optionsCatch([]);
    },
  }));

  const renderOption = option => (
    <MenuItem key={option.value} value={option.value}>
      <Checkbox checked={selectedOptions.includes(option.value)} />
      {option.label}
    </MenuItem>
  );
  const renderValue = selected => {
    if (selected.length === 0) {
      return 'Select Options';
    }
    if (selected.length === options.length) {
      return 'All Selected';
    }
    return `${selected.length} selected`;
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel>Select Branches</InputLabel>
      <Select
        multiple
        value={selectedOptions}
        onChange={handleChange}
        renderValue={renderValue}
      >
        <MenuItem key="select-all" value="select-all">
          <Checkbox
            checked={selectedOptions?.length === options?.length}
            onChange={handleSelectAll}
          />
          Select All
        </MenuItem>
        {options?.map(renderOption)}
      </Select>
    </FormControl>
  );
});

export default MultiSelect;
