/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-param-reassign */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Done from '@material-ui/icons/Done';
import infoIcon from 'assets/img/infoIcon.svg';
import greenTick from 'assets/img/greenCheck.svg';
// import Close from '@material-ui/icons/Close';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import purple from '@material-ui/core/colors/purple';
import AddAlert from '@material-ui/icons/AddAlert';
import Snackbar from 'components/Snackbar/Snackbar.jsx';
import Table from 'components/Table/Table.jsx';
import SweetAlert from 'react-bootstrap-sweetalert';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Close from '@material-ui/icons/Close';

import CircularProgress from '@material-ui/core/CircularProgress';

import AdminApprovePageStyle from 'assets/jss/material-dashboard-pro-react/views/AdminApprovePageStyle';
import Slide from '@material-ui/core/Slide';
import uuid from 'react-uuid';
import { Box } from '@material-ui/core';
import apiCaller from '../../utils/apiCallerOutstanding';
import TruckerFiles from './TruckerFiles';
import firebase from '../../config/config';
import TruckFiles from './fileTypes/TruckFiles';
import FeatureFlagToastAlert from '../Components/FeatureFlagToastAlert';

const db = firebase.firestore();
const auditLog = firebase.functions().httpsCallable('utilities-auditLog');
const approveTrucker = firebase
  .functions()
  .httpsCallable('truckers-approveTrucker');
const createContactandFundAccountPaas = firebase
  .functions()
  .httpsCallable('paas-createContactandFundAccountPaas');
const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" {...props} ref={ref} />
));

class AdminApproveTrucker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      company: '',
      companyList: {},
      pendingTruckers: [],
      isLoading: false,
      isButtonLoading: [],
      tc: false,
      agentName: '',
      color: 'info',
      alert: false,
      banksData: [],
      banksArray: [],
      loadingBanks: [],
      tl: false,
      tr: false,
      bl: false,
      bc: false,
      br: false,
      classicModal: false,
      panModal: false,
      panDetailData: {},
      showLoader: false,
      name: '',
      errorAlert: false,
      approveAlert: false,
      fundAccountLoading: false,
      contactLoading: false,
      fundAccountSuccess: false,
      contactSuccess: false,
      razorpayError: '',
      featureFlagFiles: '',
    };
    this.handleSimpleSelect = this.handleSimpleSelect.bind(this);
    this.approveUser = this.approveUser.bind(this);
    this.rejectUser = this.rejectUser.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
    this.closeErrorAlert = this.closeErrorAlert.bind(this);
    this.closeChecksAlert = this.closeChecksAlert.bind(this);
    this.getPendingTruckers = this.getPendingTruckers.bind(this);
    this.showTruckerDetails = this.showTruckerDetails.bind(this);
    this.tdsDeductedFlagHandler = this.tdsDeductedFlagHandler.bind(this);
  }

  componentDidMount() {
    this.getPendingTruckers();
    this.getFeatureFlagStatus();
  }

  getFeatureFlagStatus() {
    db.collection('FeatureFlags')
      .doc('FileUploadsFeature')
      .onSnapshot(doc => {
        if (doc.exists) {
          const data = doc.data();
          this.setState({ featureFlagFiles: data?.truckerFiles });
        }
      });
  }

  closeChecksAlert() {
    this.setState({
      checksAlert: false,
      checksError: '',
      recentTransactionsLoading: false,
      recentTransactions: [],
    });
  }

  showTruckerDetails(trucker, id) {
    const { loadingBanks } = this.state;
    loadingBanks[id] = true;
    this.setState({ loadingBanks });
    if (trucker.editPending) {
      const banksData = [];
      trucker.banks.forEach(bank => {
        banksData.push({
          color: 'success',
          data: [
            bank.bankHolderName,
            bank.accountNumber,
            bank.bankName,
            bank.ifsc,
            bank.bankBranchName,
            bank.bankAccountActive && (
              <Done style={{ color: '#43a047 ', alignItems: 'center' }} />
            ),
          ],
        });
      });
      loadingBanks[id] = false;
      this.handleClickOpen('classicModal');
      this.setState({ banksData, loadingBanks });
      // this.setState({ banksData, alert: true, loadingBanks });
    } else {
      const bankRef = db
        .collection('Truckers')
        .doc(trucker.truckerId)
        .collection('Banks');
      bankRef
        .get()
        .then(
          banks => {
            const banksData = [];
            const banksArray = [];
            if (!banks.empty) {
              banks.forEach(bankDetailsResult => {
                const bankDetails = bankDetailsResult.data();
                banksArray.push(bankDetails);
                banksData.push({
                  color: 'success',
                  data: [
                    bankDetails.bankHolderName,
                    bankDetails.accountNumber,
                    bankDetails.bankName,
                    bankDetails.ifsc,
                    bankDetails.bankBranchName,
                    bankDetails.bankAccountActive && (
                      <Done
                        style={{ color: '#43a047 ', alignItems: 'center' }}
                      />
                    ),
                  ],
                });
              });
              loadingBanks[id] = false;
              this.handleClickOpen('classicModal');
              this.setState({ banksData, loadingBanks, banksArray });
              // this.setState({ banksData, alert: true, loadingBanks });
            }
          },
          err => {
            loadingBanks[id] = false;
            this.setState({ banksData: [], alert: false, loadingBanks });
          }
        )
        .catch(err => {
          loadingBanks[id] = false;
          this.setState({ banksData: [], alert: false, loadingBanks });
        });
    }
  }

  closeAlert() {
    this.setState({ alert: false });
  }

  closeErrorAlert() {
    this.setState({ errorAlert: false });
  }

  handleClickOpen(modal) {
    const x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleClose(modal) {
    const x = [];
    x[modal] = false;
    this.setState(x);
  }

  /**
   * will return the notification snackbar
   * @param {string} place
   */
  showNotification(place, name) {
    this.setState({
      agentName: name,
    });
    if (!this.state[place]) {
      const x = [];
      x[place] = true;
      this.setState(x);
      setTimeout(() => {
        x[place] = false;
        this.setState(x);
      }, 6000);
    }
  }

  /**
   * will return All the Pending Users
   * @function
   */
  getPendingTruckers() {
    //  let pendingTruckers = [];
    const pendingTruckersQuery = db
      .collection('Truckers')
      .where('status', '==', 'pending')
      .get();
    const pendingEditTruckersQuery = db
      .collection('Truckers')
      .where('editPending', '==', true)
      .get();
    Promise.all([pendingTruckersQuery, pendingEditTruckersQuery]).then(
      pending => {
        const pendingTruckers = [];
        pending[0].forEach(pendingTrucker => {
          pendingTruckers.push(pendingTrucker.data());
        });
        pending[1].forEach(pendingTrucker => {
          if (pendingTrucker.data().truckerEditData)
            pendingTruckers.push(pendingTrucker.data());
        });
        this.setState({ pendingTruckers });
      }
    );
  }

  /**
   * returns the Ui for Collapsable Card Component Details
   * @function
   */
  getContent = trucker => (
    <GridContainer>
      <GridItem xs={12} sm={12} md={8}>
        <p>
          <span>Email :</span> {trucker.email}
        </p>
      </GridItem>
      <GridItem xs={12} sm={12} md={8}>
        <p>
          <span>Contact :</span>
          {trucker.phoneNumber}
        </p>
      </GridItem>
    </GridContainer>
  );

  // Will Handle The Select Button Element
  handleSimpleSelect = (event, trucker) => {
    const { companyName } = event.target.value;
    const { companyId } = event.target.value;
    const { pendingTruckers } = this.state;
    for (const i in pendingTruckers) {
      if (pendingTruckers[i].uid === trucker.uid) {
        pendingTruckers[i].companyName = companyName;
        pendingTruckers[i].companyId = companyId;
      }
    }
    this.setState({ [event.target.name]: pendingTruckers });
  };

  /**
   * Will return the Bank Details Component
   * @function
   */
  getBanks() {
    const { classes } = this.props;
    return (
      <Card>
        {this.state.banksData.length > 0 ? (
          <CardBody className={classes.customCardContentClass}>
            <Table
              hover
              tableHead={[
                'Name',
                'Account No',
                'Bank',
                'Ifsc',
                'Branch',
                'Active',
              ]}
              tableData={this.state.banksData}
            />
          </CardBody>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <p
              className={`${classes.textCenter} ${classes.title}`}
              style={{ textAlign: 'center', color: 'red' }}
            >
              {this.state.banksState}
            </p>
          </div>
        )}
      </Card>
    );
  }

  confirmBankDetails(transaction) {
    this.handleClickOpen('classicModal');
  }

  async tdsDeductedFlagHandler(trucker, id) {
    this.setState({
      showLoader: true,
    });
    this.handleClickOpen('panModal');
    const userDetails = firebase.auth().currentUser;

    const { Aa: token } = userDetails;

    const endPoint = `pipe/trucker-pan-details?trucker_id=${id}`;

    try {
      const response = await apiCaller(
        endPoint,
        {},
        `Bearer ${token}`,
        'nh-base',
        'get'
      );
      const value = response?.data?.Data;
      this.setState({
        showLoader: false,
        panDetailData: value,
      });
    } catch (e) {
      console.error(e);
    }
  }

  showDialog() {
    const { classes } = this.props;
    const { classicModal } = this.state;
    return (
      <Dialog
        classes={{
          root: `${classes.center}`,
          // paper: classes.modal,
        }}
        fullWidth
        maxWidth="md"
        open={classicModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.handleClose('classicModal')}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => this.handleClose('classicModal')}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Please Check the Bank Details</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {this.getBanks()}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={() => this.handleClose('classicModal')}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  showPanDialog() {
    const { classes } = this.props;
    const { panModal, panDetailData, showLoader } = this.state;
    const isPanDetailDataEmpty =
      panDetailData?.pan_status === '' ||
      panDetailData?.pan_status === 'INVALID';

    return (
      <Dialog
        classes={{
          root: `${classes.center}`,
        }}
        fullWidth
        maxWidth="sm"
        open={panModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.handleClose('panModal')}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div className={classes.modalHeaderContent}>
            <h4 className={classes.modalTitle}>PAN Validation Details</h4>
          </div>
        </DialogTitle>

        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {showLoader === true ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <CircularProgress />
            </div>
          ) : isPanDetailDataEmpty ? (
            <div
              style={{
                backgroundColor: '#FDE8E8',
                border: '1px solid #d32f2f',
                color: 'black',
                padding: '10px',
                borderRadius: '5px',
                paddingLeft: '110px',
              }}
            >
              <img
                style={{
                  paddingRight: '10px',
                }}
                src={infoIcon}
                alt=""
              />
              Invalid! PAN number or details does not exist
            </div>
          ) : (
            <div>
              {/* Render the PAN detail data */}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                }}
              >
                <p>Verified Name</p>
                <p style={{ fontWeight: 'bold' }}>
                  {panDetailData?.verifed_name}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                }}
              >
                <p>Name on PAN</p>
                <p style={{ fontWeight: 'bold' }}>
                  {panDetailData?.name_pan_card}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                }}
              >
                <p>DOB</p>
                <p style={{ fontWeight: 'bold' }}>
                  {panDetailData?.pan_date_of_birth}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                }}
              >
                <p>PAN Type</p>
                <p style={{ fontWeight: 'bold' }}>{panDetailData?.pan_type}</p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                }}
              >
                <p>PAN Status</p>
                <p style={{ fontWeight: 'bold' }}>
                  {panDetailData?.pan_status}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                }}
              >
                <p>Aadhaar Linked</p>
                <p style={{ fontWeight: 'bold', color: 'green' }}>
                  {panDetailData.pan_aadhaar_linked && (
                    <img src={greenTick} alt="icon" />
                  )}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                }}
              >
                <p>Gender</p>
                <p style={{ fontWeight: 'bold' }}>
                  {panDetailData?.pan_gender}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                }}
              >
                <p>PAN Email</p>
                <p style={{ fontWeight: 'bold' }}>{panDetailData?.pan_email}</p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                }}
              >
                <p>Address State</p>
                <p style={{ fontWeight: 'bold' }}>
                  {panDetailData?.pan_address?.city}
                  {panDetailData?.pan_address?.state}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                }}
              >
                <p>Address Pincode</p>
                <p style={{ fontWeight: 'bold' }}>
                  {panDetailData?.pan_address?.pincode}
                </p>
              </div>
            </div>
          )}
        </DialogContent>

        <DialogActions className={classes.modalFooter}>
          <Button onClick={() => this.handleClose('panModal')}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  }

  /**
   * will return the Card
   * @function
   */
  getCards() {
    const { classes, activeRole } = this.props;
    const { featureFlagFiles } = this.state;
    let author = '';
    return (
      <React.Fragment>
        {this.state.pendingTruckers.map(trucker => {
          if (trucker.editPending) {
            //  Passing the Edited Details of the trucker to the render and onClick functions.
            trucker = trucker.truckerEditData;
            if (trucker.editedByName) {
              author = trucker.editedByName;
            }
            trucker.isEdit = true;
            trucker.editPending = true;
          } else {
            trucker.isEdit = false;
            author = trucker.createdByName;
          }
          return (
            <GridItem xs={12} sm={12} md={4} lg={4} key={trucker.truckerId}>
              <Card>
                <CardHeader>
                  <h4 className={classes.cardTitle}>
                    {trucker.name} {trucker.isEdit && '(Edited)'}
                  </h4>
                </CardHeader>
                <CardBody>
                  <GridContainer direction="column" justify="center">
                    <GridItem xs={12} sm={12} md={12}>
                      <p>
                        <span>Email : </span> {trucker.email}
                      </p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <p>
                        <span>Contact Person : </span>
                        {trucker.contactPerson}
                      </p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <p>
                        <span>Contact : </span>
                        {trucker.phoneNumber}
                      </p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <p>
                        <span>GST : </span>
                        {trucker.gstNumber || 'None'}
                      </p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <p>
                        <span>PAN : </span>
                        {trucker.panNumber || 'None'}
                      </p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <p>
                        <span>Author : </span>
                        {author || 'None'}
                      </p>
                    </GridItem>

                    <GridItem
                      style={{ textAlign: 'center' }}
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      {this.state.loadingBanks[trucker.truckerId] ? (
                        <CircularProgress
                          className={classes.progress}
                          style={{
                            color: 'secondary',
                            marginLeft: '50px',
                          }}
                          thickness={7}
                        />
                      ) : (
                        <Box>
                          <Button
                            color="success"
                            onClick={() =>
                              this.showTruckerDetails(
                                trucker,
                                trucker.truckerId
                              )
                            }
                            className={classes.updateProfileButton}
                            simple
                            disabled={featureFlagFiles === 1}
                          >
                            View Bank Details
                          </Button>

                          <Button
                            color="success"
                            onClick={() =>
                              this.tdsDeductedFlagHandler(
                                trucker,
                                trucker.truckerId
                              )
                            }
                            className={classes.updateProfileButton}
                            simple
                            disabled={featureFlagFiles === 1}
                          >
                            VIEW PAN DETAILS
                          </Button>
                        </Box>
                      )}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <TruckerFiles
                        truckerId={trucker.truckerId}
                        trucker={trucker}
                        featureFlagFiles={featureFlagFiles}
                        activeRole={activeRole}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter>
                  {this.state.isButtonLoading[trucker.truckerId] ? (
                    <CircularProgress
                      className={classes.progress}
                      style={{ color: purple[500] }}
                      thickness={7}
                    />
                  ) : (
                    <React.Fragment>
                      <Button
                        onClick={() =>
                          this.rejectUser(trucker, trucker.truckerId)
                        }
                        className={classes.updateProfileButton}
                        disabled={featureFlagFiles === 1}
                      >
                        Reject
                      </Button>
                      <Button
                        color="success"
                        onClick={() =>
                          this.approveUser(trucker, trucker.truckerId)
                        }
                        className={classes.updateProfileButton}
                        disabled={featureFlagFiles === 1}
                      >
                        Approve
                      </Button>
                    </React.Fragment>
                  )}
                </CardFooter>
              </Card>
            </GridItem>
          );
        })}
      </React.Fragment>
    );
  }

  /**
   * Approve User
   * @function
   */
  approveUser(trucker, id) {
    const { isButtonLoading, banksArray } = this.state;
    const { userDetails, systemDetails } = this.props;
    isButtonLoading[id] = true;
    this.setState({
      isButtonLoading,
    });
    approveTrucker(trucker)
      .then(truckerRef => {
        if (!trucker.isEdit) {
          const data = {
            orgId: trucker.truckerId,
            name: trucker.contactPerson,
            phoneNumber: trucker.phoneNumber,
            role: 'org',
            userType: 'trucker',
            userActive: true,
            customerId: uuid(),
            createdAt: new Date(),
            modifiedAt: new Date(),
          };
          db.collection('Customers')
            .doc(data.customerId)
            .set(data);
        }

        this.setState({
          approveAlert: true,
          contactLoading: true,
          fundAccountLoading: true,
          razorpayError: '',
        });

        createContactandFundAccountPaas(trucker)
          .then(res => {
            this.setState({
              contactLoading: false,
              fundAccountLoading: false,
              contactSuccess: true,
              fundAccountSuccess: true,
            });
          })
          .catch(err => {
            this.setState({
              contactLoading: false,
              fundAccountLoading: false,
              contactSuccess: false,
              fundAccountSuccess: false,
              razorpayError: err.message,
            });
            // console.log('Error');
            // console.log(typeof(err))
            // console.log(err.message)
            // console.log(err.code)
          });

        const { pendingTruckers } = this.state;
        for (const i in pendingTruckers) {
          if (pendingTruckers[i].truckerId === trucker.truckerId) {
            pendingTruckers.splice(i, 1);
          }
        }
        // const NotificationMessage = `${trucker.name} Trucker Approved `;
        // this.showNotification('tc', NotificationMessage);
        isButtonLoading[id] = false;
        this.setState({
          pendingTruckers,
          isButtonLoading,
          color: 'info',
        });
        const auditBody = {
          data: {
            ...trucker,
            banksArray,
            status: 'approved',
          },
          collection: 'Truckers',
          updatedBy: userDetails.uid,
          type: 'Approve Trucker',
          systemDetails,
          message: `${userDetails.email} approved Trucker EDIT with name ${trucker.name}`,
        };
        const auditBody2 = {
          data: {
            banksArray,
          },
          collection: 'Truckers',
          updatedBy: userDetails.uid,
          systemDetails,
          type: 'Bank',
          message: `${userDetails.email} approved Trucker EDIT with ${
            trucker.name
          } and banks ${banksArray.map(bank => JSON.stringify(bank)).join()}`,
        };

        auditLog(auditBody);
        // console.log('AUDIT',auditBody2)
        auditLog(auditBody2);
      })
      .catch(err => {
        isButtonLoading[id] = false;
        this.setState({
          isButtonLoading,
        });
        const errorBody = {
          message: `${userDetails.email} tried to approve Trucker EDIT with name ${trucker.name} but FAILED`,
          status: 'fail',
          error: err,
          updatedBy: userDetails.uid,
          errorMessage: err.message,
          collection: 'Truckers',
          systemDetails,
        };
        auditLog(errorBody);
        this.setState({
          errorAlert: true,
        });
      });

    // if (trucker.isEdit) {
    //   const userRef = db.collection(`Truckers`);
    //   userRef
    //     .doc(`${trucker.truckerId}`)
    //     .update({
    //       ...trucker,
    //       editPending: false,
    //       modifiedAt: new Date(),
    //       truckerEditData: null,
    //     })
    //     .then(() => {
    //       userRef
    //         .doc(`${trucker.truckerId}`)
    //         .collection('EditLog')
    //         .add({ ...trucker, status: 'approved' });
    //       // eslint-disable-next-line prefer-destructuring
    //       const pendingTruckers = this.state.pendingTruckers;
    //       for (const i in pendingTruckers) {
    //         if (pendingTruckers[i].truckerId === trucker.truckerId) {
    //           pendingTruckers.splice(i, 1);
    //         }
    //       }
    //       trucker.banks.forEach(bankDetails => {
    //         if (bankDetails.hasOwnProperty('bankId')) {
    //           db.collection('Truckers')
    //             .doc(trucker.truckerId)
    //             .collection('Banks')
    //             .doc(bankDetails.bankId)
    //             .set(bankDetails, { merge: true });
    //         } else {
    //           db.collection('Truckers')
    //             .doc(trucker.truckerId)
    //             .collection('Banks')
    //             .add(bankDetails)
    //             .then(bankDetailsDoc => {
    //               db.collection('Truckers')
    //                 .doc(trucker.truckerId)
    //                 .collection('Banks')
    //                 .doc(bankDetailsDoc.id)
    //                 .update({ bankId: bankDetailsDoc.id });
    //             });
    //         }
    //       });

    //       const auditBody = {
    //         data: {
    //           ...trucker,
    //           status: 'approved',
    //         },
    //         collection: 'Truckers',
    //         updatedBy: userDetails.uid,
    //         type: 'Approve Trucker',
    //         systemDetails,
    //         message: `${userDetails.email} approved Trucker EDIT with name ${
    //           trucker.name
    //         }`,
    //       };
    //       auditLog(auditBody);

    //       const NotificationMessage = `${trucker.name} Trucker Approved `;
    //       this.showNotification('tc', NotificationMessage);
    //       isButtonLoading[id] = false;
    //       this.setState({
    //         pendingTruckers,
    //         isButtonLoading,
    //         color: 'info',
    //       });
    //     })
    //     .catch(err => {
    //       const errorBody = {
    //         message: `${
    //           userDetails.email
    //         } tried to approve Trucker EDIT with name ${
    //           trucker.name
    //         } but FAILED`,
    //         status: 'fail',
    //         error: err,
    //         updatedBy: userDetails.uid,
    //         errorMessage: err.message,
    //         collection: 'Truckers',
    //         systemDetails,
    //       };
    //       auditLog(errorBody);
    //     });
    // } else {
    //   const userRef = db.collection(`Truckers`);
    //   userRef
    //     .doc(`${trucker.truckerId}`)
    //     .update({ status: 'approved', modifiedAt: new Date() })
    //     .then(() => {
    //       userRef
    //         .doc(`${trucker.truckerId}`)
    //         .collection('EditLog')
    //         .add({ ...trucker, status: 'approved' });
    //       const { pendingTruckers } = this.state;
    //       for (const i in pendingTruckers) {
    //         if (pendingTruckers[i].truckerId === trucker.truckerId) {
    //           pendingTruckers.splice(i, 1);
    //         }
    //       }
    //       const auditBody = {
    //         data: {
    //           ...trucker,
    //           status: 'approved',
    //         },
    //         collection: 'Truckers',
    //         updatedBy: userDetails.uid,
    //         type: 'Approve Trucker',
    //         systemDetails,
    //         message: `${userDetails.email} approved Trucker with name ${
    //           trucker.name
    //         }`,
    //       };
    //       auditLog(auditBody);
    //       const NotificationMessage = `${trucker.name} Trucker Approved `;
    //       this.showNotification('tc', NotificationMessage);
    //       isButtonLoading[id] = false;
    //       this.setState({ pendingTruckers, isButtonLoading, color: 'info' });
    //     })
    //     .catch(err => {
    //       const errorBody = {
    //         message: `${userDetails.email} tried to approve Trucker with name ${
    //           trucker.name
    //         } but FAILED`,
    //         status: 'fail',
    //         error: err,
    //         updatedBy: userDetails.uid,
    //         errorMessage: err.message,
    //         collection: 'Truckers',
    //         systemDetails,
    //       };
    //       auditLog(errorBody);
    //     });
    // }
  }

  /**
   * Reject User
   * @function
   */
  rejectUser(trucker, id) {
    const { isButtonLoading } = this.state;
    const { userDetails, systemDetails } = this.props;
    isButtonLoading[id] = true;
    this.setState({
      isButtonLoading,
    });

    if (trucker.isEdit) {
      const userRef = db.collection(`Truckers`);
      userRef
        .doc(trucker.truckerId)
        .update({
          editPending: false,
        })
        .then(() => {
          userRef
            .doc(`${trucker.truckerId}`)
            .collection('EditLog')
            .add({ ...trucker, status: 'rejected' });
          const { pendingTruckers } = this.state;
          for (const i in pendingTruckers) {
            if (pendingTruckers[i].truckerId === trucker.truckerId) {
              pendingTruckers.splice(i, 1);
            }
          }

          const auditBody = {
            data: {
              ...trucker,
              status: 'rejected',
            },
            collection: 'Truckers',
            updatedBy: userDetails.uid,
            type: 'Reject Trucker',
            systemDetails,
            message: `${userDetails.email} rejected Trucker EDIT with name ${trucker.name}`,
          };
          auditLog(auditBody);

          const NotificationMessage = `${trucker.name} Trucker Rejected `;
          this.showNotification('tc', NotificationMessage);
          isButtonLoading[id] = false;
          this.setState({ pendingTruckers, isButtonLoading, color: 'danger' });
        })
        .catch(err => {
          const errorBody = {
            message: `${userDetails.email} tried to reject Trucker EDIT with name ${trucker.name} but FAILED`,
            status: 'fail',
            error: err,
            updatedBy: userDetails.uid,
            errorMessage: err.message,
            collection: 'Truckers',
            systemDetails,
          };
          auditLog(errorBody);

          isButtonLoading[id] = false;
          this.setState({ isButtonLoading });
        });
    } else {
      const userRef = db.collection(`Truckers`);
      userRef
        .doc(`${trucker.truckerId}`)
        .update({ status: 'rejected', modifiedAt: new Date() })
        .then(() => {
          const { pendingTruckers } = this.state;
          for (const i in pendingTruckers) {
            if (pendingTruckers[i].truckerId === trucker.truckerId) {
              pendingTruckers.splice(i, 1);
            }
          }

          const auditBody = {
            data: {
              ...trucker,
              status: 'rejected',
            },
            collection: 'Truckers',
            updatedBy: userDetails.uid,
            type: 'Reject Trucker',
            systemDetails,
            message: `${userDetails.email} rejected Trucker with name ${trucker.name}`,
          };
          auditLog(auditBody);

          const NotificationMessage = `${trucker.name} Trucker Rejected `;
          this.showNotification('tc', NotificationMessage);
          isButtonLoading[id] = false;
          this.setState({
            pendingTruckers,
            isButtonLoading,
            color: 'danger',
          });
        })
        .catch(err => {
          const errorBody = {
            message: `${userDetails.email} tried to reject Trucker with name ${trucker.name} but FAILED`,
            status: 'fail',
            error: err,
            updatedBy: userDetails.uid,
            errorMessage: err.message,
            collection: 'Truckers',
            systemDetails,
          };
          auditLog(errorBody);
          isButtonLoading[id] = false;
          this.setState({ isButtonLoading });
        });
    }
  }

  render() {
    const { classes } = this.props;
    const {
      approveAlert,
      contactSuccess,
      contactLoading,
      razorpayError,
      featureFlagFiles,
    } = this.state;
    return (
      <div>
        {featureFlagFiles === 1 && <FeatureFlagToastAlert />}
        <GridContainer justify="center">
          {this.showDialog()}
          {this.showPanDialog()}
          <GridContainer justify="center">
            {this.state.alert && (
              <SweetAlert
                confirmBtnText="Close"
                title=""
                onConfirm={this.closeAlert}
                onCancel={this.closeAlert}
                confirmBtnCssClass={`${this.props.classes.button} ${this.props.classes.success}`}
                cancelBtnCssClass={`${this.props.classes.button} ${this.props.classes.danger}`}
              >
                <h3>
                  <span className={classes.rajdhaniFont}>
                    {this.state.name} Bank Details
                  </span>
                </h3>
                {this.getBanks()}
              </SweetAlert>
            )}
            {this.state.errorAlert && (
              <SweetAlert
                error
                confirmBtnText="Close"
                title=""
                onConfirm={this.closeErrorAlert}
                onCancel={this.closeErrorAlert}
                confirmBtnCssClass={`${this.props.classes.button} ${this.props.classes.success}`}
                cancelBtnCssClass={`${this.props.classes.button} ${this.props.classes.danger}`}
              >
                Error in Approving Trucker Try again.
              </SweetAlert>
            )}

            {approveAlert && (
              <SweetAlert
                confirmBtnText="Done"
                title=""
                onConfirm={() => {
                  this.setState({ approveAlert: false });
                }}
                onCancel={() => {
                  this.setState({ approveAlert: false });
                }}
                confirmBtnCssClass={`${this.props.classes.button} ${this.props.classes.success}`}
              >
                <h2>
                  <span className={this.props.classes.rajdhaniFont}>
                    PaaS Status
                  </span>
                </h2>
                <GridContainer>
                  <GridItem xs={12}>
                    <span style={{ fontSize: 25, textAlign: 'center' }}>
                      {' '}
                      Account is being verified.
                    </span>
                    {contactLoading && (
                      <CircularProgress
                        className={classes.progress}
                        style={{ color: purple[500] }}
                        size={20}
                        thickness={3}
                      />
                    )}
                  </GridItem>

                  <GridItem xs={12}>
                    {contactSuccess && (
                      <p style={{ textAlign: 'center', color: '#009688' }}>
                        Will be available after verification.
                      </p>
                    )}
                  </GridItem>
                  <GridItem xs={12}>
                    <span
                      style={{
                        fontSize: 15,
                        float: 'left',
                        color: 'red',
                      }}
                    >
                      {razorpayError}
                    </span>
                  </GridItem>
                  <GridItem xs={12}>
                    <span
                      style={{
                        fontSize: 15,
                        float: 'left',
                        color: 'red',
                      }}
                    >
                      {razorpayError && 'Retry in Trucker Edit.'}
                    </span>
                  </GridItem>
                </GridContainer>
              </SweetAlert>
            )}
          </GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader className={`${classes.cardHeader}`} color="warning">
                <p className={`${classes.cardHeader}`}>Approve Truckers</p>
                <h3 className={classes.cardTitle}>
                  {this.state.pendingTruckers.length} <small />
                </h3>
              </CardHeader>
              <CardBody className={`${classes.cardHeader}`}>
                {this.state.pendingTruckers.length <= 0 && (
                  <p className={classes.textCenter}>No Pending Truckers</p>
                )}
                <GridContainer style={{ padding: '12px' }}>
                  {this.getCards()}
                </GridContainer>
              </CardBody>
              <CardFooter stats>
                <div className={classes.stats} />
              </CardFooter>
            </Card>
            <Snackbar
              place="tc"
              color={this.state.color}
              icon={AddAlert}
              message={this.state.agentName}
              open={this.state.tc}
              closeNotification={() => this.setState({ tc: false })}
              close
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

AdminApproveTrucker.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  userDetails: state.main.userDetails,
  systemDetails: state.main.systemDetails,
  activeRole: state.main.activeRole,
});

export default connect(mapStateToProps)(
  withStyles(AdminApprovePageStyle)(AdminApproveTrucker)
);
