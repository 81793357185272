import Datetime from 'react-datetime';
import {
  Button,
  IconButton,
  Typography,
  CircularProgress,
  Snackbar,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { Alert } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import firebase from '../../../../config/config';
import apiCaller from '../../../../utils/apiCallerOutstanding';

const InsuranceExpiry = ({ truckNumber, activeRole }) => {
  const role = activeRole === 'branch-ops' ? 'BranchOps' : activeRole;
  const [expiryData, setExpiryData] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [EditExpiry, setEditExpiry] = useState(false);
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  let textColor;
  if (expiryData?.insurance_active_flag === 0) {
    textColor = '#0A0B09';
  } else if (expiryData?.insurance_active_flag === 1) {
    textColor = '#FF7E03';
  } else {
    textColor = '#D32F2F';
  }
  const getExpiry = async () => {
    setLoading(true);
    const currentActiveRole = `is${role?.charAt(0).toUpperCase()}${role.slice(
      1
    )}`; // Capitalize first letter
    const currentUserDetails = firebase.auth().currentUser;
    const { Aa: token } = currentUserDetails;
    const endPoint = `pipe/truck-management/documents?role=${currentActiveRole}&vehicle_number=${truckNumber}`;
    await apiCaller(endPoint, {}, `Bearer ${token}`, 'nh-base', 'get')
      .then(response => {
        const result = response?.data?.Data[0];
        setExpiryData(result);
      })
      .catch(e => {})
      .finally(() => {
        setLoading(false);
      });
  };
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const updateExpiry = async () => {
    const formattedDate = moment(selectedDate).format('YYYY-MM-DD');
    const currentActiveRole = `is${role.charAt(0).toUpperCase()}${role.slice(
      1
    )}`; // Capitalize first letter
    const currentUserDetails = firebase.auth().currentUser;
    const { Aa: token } = currentUserDetails;
    const body = {
      truck_id: expiryData.truck_id,
      role: currentActiveRole,
      vehicle_number: truckNumber,
      insurance_expiry_date: formattedDate,
    };
    const endPoint = 'pipe/truck-management/documents';
    try {
      const response = await apiCaller(
        endPoint,
        body,
        `Bearer ${token}`,
        'nh-base',
        'post'
      );
      if (response && response.data && response.data.message) {
        setSnackbarMessage(response?.data?.message);
      } else {
        setSnackbarMessage(response?.data?.Error);
      }
      setSelectedDate('');
      getExpiry();
    } catch (e) {
      return e;
    }
  };

  const dayDiff = () => {
    const today = moment();
    const expiryDate = moment(expiryData?.insurance_expiry_date, 'DD/MM/YYYY');
    return today.diff(expiryDate, 'days');
  };
  const daysDifference = dayDiff();

  const handleEditExpiry = () => {
    setEditExpiry(true);
  };

  useEffect(() => {
    getExpiry();
  }, []);
  return (
    <div>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {expiryData?.insurance_expiry_date ? (
            <div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Typography fontWeight={500}>Insurance Expiry Date</Typography>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Typography style={{ color: textColor }}>
                    {expiryData?.insurance_expiry_date}
                  </Typography>
                  <IconButton
                    aria-label="delete"
                    marginLeft="5px"
                    onClick={handleEditExpiry}
                  >
                    <EditIcon />
                  </IconButton>
                </div>
              </div>
              <div>
                {EditExpiry ? (
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div style={{ width: selectedDate ? '60%' : '100%' }}>
                      <Datetime
                        value={selectedDate}
                        inputProps={{
                          placeholder: 'Add Insurance Expiry Date',
                        }}
                        dateFormat="DD-MM-YYYY"
                        timeFormat={false}
                        onChange={date => {
                          if (date !== null) {
                            setSelectedDate(date);
                          }
                        }}
                      />
                    </div>
                    <div>
                      {selectedDate && (
                        <Button
                          style={{ width: '120px' }}
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            updateExpiry();
                            setEditExpiry(false);
                            setSnackbarOpen(true);
                          }}
                        >
                          Save
                        </Button>
                      )}
                    </div>
                  </div>
                ) : (
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Typography
                      style={{
                        fontSize: '10px',
                      }}
                    >
                      {expiryData?.insurance_added_by},{' '}
                      {moment(expiryData?.insurance_added_datetime).format(
                        'MMM DD YYYY, h:mma'
                      )}
                    </Typography>
                    {expiryData?.insurance_active_flag === 2 && (
                      <Typography
                        style={{ color: '#D32F2F', fontSize: '10px' }}
                      >
                        {daysDifference} Days Since Expiry
                      </Typography>
                    )}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div>
              <div>
                <Typography fontWeight={500}>
                  Add Insurance Expiry Date
                </Typography>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: selectedDate ? '60%' : '100%' }}>
                  <Datetime
                    value={selectedDate}
                    inputProps={{ placeholder: 'Add Insurance Expiry Date' }}
                    dateFormat="DD-MM-YYYY"
                    timeFormat={false}
                    onChange={date => {
                      if (date !== null) {
                        setSelectedDate(date);
                      }
                    }}
                  />
                </div>
                <div>
                  {selectedDate && (
                    <>
                      <Button
                        style={{ width: '120px' }}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          updateExpiry();
                          setSnackbarOpen(true);
                        }}
                      >
                        Save
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={
            snackbarMessage.includes('Successfully updated the Exipry date')
              ? 'error'
              : 'success'
          }
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default InsuranceExpiry;
