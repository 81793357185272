/* eslint-disable no-prototype-builtins */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-undef */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Check from '@material-ui/icons/Check';
import Checkbox from '@material-ui/core/Checkbox';
import Skeleton from 'react-loading-skeleton';
import { Row, Col } from 'react-flexbox-grid';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';
import TransactionStyle from 'assets/jss/material-dashboard-pro-react/views/TransactionStyle';
import Responsive from 'react-responsive';
import Refresh from '@material-ui/icons/Refresh';

import firebase from '../../config/config';
import apiCaller from '../../utils/apiCaller';

const Desktop = props => <Responsive {...props} minWidth={701} />;
const Mobile = props => <Responsive {...props} maxWidth={700} />;
const db = firebase.firestore();
const auditLog = firebase.functions().httpsCallable('utilities-auditLog');

class DemandHistory extends React.Component {
  interval = null;

  constructor(props) {
    super(props);
    this.state = {
      isRefresh: false,
      isLoading: false,
      demandsData: [],
    };
  }

  componentDidMount() {
    this.getDemands();
    this.onFocus();
    window.addEventListener('focus', this.onFocus);
    window.addEventListener('blur', this.onBlur);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    window.removeEventListener('focus', this.onFocus);
    window.removeEventListener('blur', this.onBlur);
  }

  onFocus = () => {
    this.interval = setInterval(() => {
      this.getDemands();
    }, 120000);
  };

  onBlur = () => {
    clearInterval(this.interval);
  };

  refresh() {
    this.setState({
      isRefresh: true,
    });
    this.getDemands();
    setTimeout(() => {
      this.setState({
        isRefresh: false,
      });
    }, 500);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isRefresh !== this.props.isRefresh || this.state.refresh) {
      if (this.props.isRefresh || this.state.isRefresh) {
        this.refresh();
      }
    }
  }

  skeltonViewDemand() {
    const arr = [1, 2, 3];
    return (
      <div style={{ padding: '10px' }}>
        <Row start="xs" style={{ marginTop: '20px' }}>
          <Col xs={12} sm={3} md={3}>
            <h1
              className="react-rainbow-admin-header-pages_title"
              style={{ fontSize: '26px' }}
            >
              <Skeleton width={100} />
            </h1>
          </Col>
          <Col xs={12} sm={3} md={3}>
            <h1
              className="react-rainbow-admin-header-pages_title"
              style={{ fontSize: '26px' }}
            >
              <Skeleton width={100} />
            </h1>
          </Col>
          <Col xs={12} sm={3} md={3}>
            <h1
              className="react-rainbow-admin-header-pages_title"
              style={{ fontSize: '26px' }}
            >
              <Skeleton width={100} />
            </h1>
          </Col>
          <Col xs={12} sm={3} md={3}>
            <h1
              className="react-rainbow-admin-header-pages_title"
              style={{ fontSize: '26px' }}
            >
              <Skeleton width={100} />
            </h1>
          </Col>
        </Row>
        <hr
          style={{
            marginTop: '0px',
            marginBottom: '0px',
            color: '#f5f5f5',
            borderTop: '1px solid #f5f5f5',
          }}
        />
        <Row start="xs">
          <Col xs={12} sm={3} md={3}>
            <Skeleton width={300} />
          </Col>
          <Col xs={12} sm={3} md={3}>
            <Skeleton width={300} />
          </Col>
          <Col xs={12} sm={3} md={3}>
            <Skeleton width={300} />
          </Col>
          <Col xs={12} sm={3} md={3}>
            <Skeleton width={300} />
          </Col>
        </Row>
        <Row start="xs">
          <br />
          {arr.map((a, i) => (
            <Row style={{ width: '100%', marginLeft: '5px' }} key={i}>
              <Col xs={6} sm={3} md={3}>
                <Skeleton width={200} />
              </Col>
              <Col xs={6} sm={3} md={3}>
                <Skeleton width={200} />
              </Col>
              <Col xs={6} sm={3} md={3}>
                <Skeleton width={200} />
              </Col>
              <Col xs={6} sm={3} md={3}>
                <Skeleton width={200} />
              </Col>
            </Row>
          ))}
        </Row>
        <hr
          style={{
            marginTop: '4px',
            marginBottom: '4px',
            color: '#f5f5f5',
            borderTop: '1px solid #f5f5f5',
          }}
        />
        <Row start="xs">
          <br />
          {arr.map((a, i) => (
            <Row style={{ width: '100%', marginLeft: '0px' }} key={i}>
              <Col xs={12} sm={3} md={3}>
                <Skeleton width={300} />
              </Col>
              <Col xs={12} sm={3} md={3}>
                <Skeleton width={300} />
              </Col>
              <Col xs={12} sm={3} md={3}>
                <Skeleton width={300} />
              </Col>
              <Col xs={12} sm={3} md={3}>
                <Skeleton width={300} />
              </Col>
              <Col xs={12} sm={12} md={12}>
                <hr
                  style={{
                    marginTop: '4px',
                    marginBottom: '4px',
                    color: '#f5f5f5',
                    borderTop: '1px solid #f5f5f5',
                  }}
                />
              </Col>
            </Row>
          ))}
        </Row>
      </div>
    );
  }

  handleMatched(demand) {
    const { userDetails, systemDetails } = this.props;
    const body = {
      probable_match_id: demand.id,
      action: 'unmatched',
    };
    apiCaller(
      'matchmaking-api/select-probable-match',
      'post',
      body,
      true,
      false,
      'matchMaking'
    )
      .then(response => {
        const auditBody = {
          data: {
            demand,
          },
          collection: 'MatchMaking',
          updatedBy: userDetails.uid,
          systemDetails,
          type: 'MatchMaking Unmatched Demand',
          message: `${userDetails.email} Unmatched Demand [id: ${demand.id}]`,
        };
        auditLog(auditBody);
        this.setState({
          isLoading: true,
        });
        this.props.handleMatched();
        setTimeout(() => {
          this.getDemands();
        }, 1000);
      })
      .catch(err => {});
  }

  /**
   * will return All the Cities Available for User
   * @function
   * @param {String} companyId
   */
  getDemands() {
    this.setState({
      isLoading: true,
    });
    const body = {
      offset: 0,
      total: 1000,
    };
    apiCaller(
      'matchmaking-api/list-matches',
      'post',
      body,
      true,
      false,
      'matchMaking'
    )
      .then(response => {
        const demands = [];
        if (response.hasOwnProperty('message')) {
          this.setState({
            isLoading: false,
          });
          if (response.message === 'success') {
            if (response.matches.hasOwnProperty('columnMetadata')) {
              const { columnMetadata, records } = response.matches;
              const headers = [];
              columnMetadata.forEach(column => {
                headers.push(column.label);
              });
              records.forEach(record => {
                const singleRecord = {};
                record.forEach((newRecord, i) => {
                  const key = headers[i];
                  if (!singleRecord.hasOwnProperty(key)) {
                    singleRecord[key] = newRecord[Object.keys(newRecord)[0]];
                  }
                });
                demands.push(singleRecord);
              });
              this.setState({
                demandsData: demands,
              });
            }
          }
        }
      })
      .catch(err => {
        this.setState({
          isLoading: false,
        });
      });
  }

  handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
    });
  }

  renderTableHeader() {
    const { classes } = this.props;
    return (
      <GridContainer >
        <GridItem xs={12} sm={12} md={4} lg={4}>
          <GridContainer >
            <GridItem xs={12} sm={12} md={4} lg={4}>
              <p className={classes.tableHeader} style={{ textAlign: 'left' }}>
                Transporter
              </p>
            </GridItem>
            <GridItem xs={12} sm={12} md={4} lg={4}>
              <p className={classes.tableHeader}>From</p>
            </GridItem>
            <GridItem xs={12} sm={12} md={4} lg={4}>
              <p className={classes.tableHeader}>To</p>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} md={4} lg={4}>
          <GridContainer >
            <GridItem xs={12} sm={12} md={4} lg={4}>
              <p className={classes.tableHeader}>Truck Type</p>
            </GridItem>
            <GridItem xs={12} sm={12} md={4} lg={4}>
              <p className={classes.tableHeader} style={{ textAlign: 'right' }}>
                Expected Price
              </p>
            </GridItem>
            <GridItem xs={12} sm={12} md={4} lg={4}>
              <p className={classes.tableHeader}>Material Type</p>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} md={2} lg={2}>
          <p className={classes.tableHeader}>Loading Point</p>
        </GridItem>
        <GridItem xs={12} sm={12} md={2} lg={2}>
          <p className={classes.tableHeader}>Unloading Point</p>
        </GridItem>
      </GridContainer>
    );
  }

  renderSupplyHeader() {
    const { classes } = this.props;
    return (
      <GridContainer
        
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <GridItem xs={12} sm={12} md={4} lg={4}>
          <GridContainer >
            <GridItem xs={12} sm={12} md={4} lg={4}>
              <p
                className={classes.supplyTableHeader}
                style={{ textAlign: 'left' }}
              >
                Trucker
              </p>
            </GridItem>
            <GridItem xs={12} sm={12} md={4} lg={4}>
              <p className={classes.supplyTableHeader}>From</p>
            </GridItem>
            <GridItem xs={12} sm={12} md={4} lg={4}>
              <p className={classes.supplyTableHeader}>To</p>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} md={4} lg={4}>
          <GridContainer >
            <GridItem xs={12} sm={12} md={4} lg={4}>
              <p className={classes.supplyTableHeader}>Truck Type</p>
            </GridItem>
            <GridItem xs={12} sm={12} md={4} lg={4}>
              <p
                className={classes.supplyTableHeader}
                style={{ textAlign: 'right' }}
              >
                Price
              </p>
            </GridItem>
            <GridItem xs={12} sm={12} md={4} lg={4}>
              <p className={classes.supplyTableHeader}>TXN No</p>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} md={4} lg={4}>
          <GridContainer >
            <GridItem xs={12} sm={12} md={6} lg={6}>
              <p
                className={classes.supplyTableHeader}
                style={{ textAlign: 'left' }}
              >
                Current Location
              </p>
            </GridItem>
            {/* <GridItem xs={12} sm={12} md={4} lg={4}>
              <p
                className={classes.supplyTableHeader}
                style={{ textAlign: 'left' }}
              >
                Matched Cities
              </p>
            </GridItem> */}
            <GridItem xs={12} sm={12} md={6} lg={6}>
              <p className={classes.supplyTableHeader}>Matched</p>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <hr
            style={{
              marginTop: '5px',
              marginBottom: '5px',
              color: '#000',
              borderTop: '1px solid #9E9E9E',
            }}
          />
        </GridItem>
      </GridContainer>
    );
  }

  renderTitle(demand) {
    const { classes } = this.props;
    const {
      transporter_name,
      trucker_name,
      transaction_serial,
      from_location,
      to_location,
      type_of_vehicle,
      demand_price,
      price,
      loading_point,
      unloading_point,
      material_type,
      current_location,
      matched_cities,
    } = demand;

    return (
      <div>
        <Desktop>
          <GridContainer >
            <GridItem xs={12} sm={12} md={4} lg={4}>
              <GridContainer >
                <GridItem xs={12} sm={12} md={4} lg={4}>
                  <p className={classes.tableRow} style={{ textAlign: 'left' }}>
                    {transporter_name}
                  </p>
                </GridItem>
                <GridItem xs={12} sm={12} md={4} lg={4}>
                  <p className={classes.tableRow}>{from_location}</p>
                </GridItem>
                <GridItem xs={12} sm={12} md={4} lg={4}>
                  <p className={classes.tableRow}>{to_location}</p>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={12} md={4} lg={4}>
              <GridContainer >
                <GridItem xs={12} sm={12} md={4} lg={4}>
                  <p className={classes.tableRow}>{type_of_vehicle}</p>
                </GridItem>
                <GridItem xs={12} sm={12} md={4} lg={4}>
                  <p
                    className={classes.tableRow}
                    style={{ textAlign: 'right' }}
                  >
                    {Intl.NumberFormat('en-IN').format(demand_price)}
                  </p>
                </GridItem>
                <GridItem xs={12} sm={12} md={4} lg={4}>
                  <p className={classes.tableRow}>{material_type}</p>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={12} md={2} lg={2}>
              <p className={classes.tableRow}>{loading_point}</p>
            </GridItem>
            <GridItem xs={12} sm={12} md={2} lg={2}>
              <p className={classes.tableRow}>{unloading_point}</p>
            </GridItem>
          </GridContainer>
          <div
            style={{
              width: '100%',
              backgroundColor: '#cfe5ef',
              padding: '10px',
              borderRadius: '4px',
            }}
          >
            {this.renderSupplyHeader()}
            <GridContainer
              
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <GridItem xs={12} sm={12} md={4} lg={4}>
                <GridContainer >
                  <GridItem xs={12} sm={12} md={4} lg={4}>
                    <p
                      className={classes.tableRow}
                      style={{ textAlign: 'left' }}
                    >
                      {trucker_name}
                    </p>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4} lg={4}>
                    <p className={classes.tableRow}>{from_location}</p>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4} lg={4}>
                    <p className={classes.tableRow}>{to_location}</p>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={12} md={4} lg={4}>
                <GridContainer >
                  <GridItem xs={12} sm={12} md={4} lg={4}>
                    <p className={classes.tableRow}>{type_of_vehicle}</p>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4} lg={4}>
                    <p
                      className={classes.tableRow}
                      style={{ textAlign: 'right' }}
                    >
                      {price === true
                        ? '___'
                        : Intl.NumberFormat('en-IN').format(price)}
                    </p>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4} lg={4}>
                    <p
                      className={classes.tableRow}
                      style={{ textAlign: 'left' }}
                    >
                      {transaction_serial || '___'}
                    </p>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={12} md={4} lg={4}>
                <GridContainer >
                  <GridItem
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    <p className={classes.tableRow}>{current_location}</p>
                  </GridItem>
                  {/* <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    <p className={classes.tableRow}>{matched_cities}</p>
                  </GridItem> */}
                  <GridItem xs={12} sm={12} md={6} lg={6}>
                    <div
                      className={classes.checkboxAndRadio}
                      style={{
                        top: '0px',
                        marginTop: '0px',
                        marginBottom: '0px',
                        textAlign: 'left',
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            tabIndex={-1}
                            checked
                            onClick={() => this.handleMatched(demand)}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot,
                        }}
                        label=""
                      />
                    </div>
                  </GridItem>
                </GridContainer>
              </GridItem>

              <GridItem xs={12} sm={12} md={12}>
                <hr
                  style={{
                    marginTop: '5px',
                    marginBottom: '5px',
                    color: '#000',
                    borderTop: '1px solid #9E9E9E',
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={12} />
            </GridContainer>
          </div>
        </Desktop>
        <Mobile>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card
                className={classes.card}
                style={{
                  marginTop: '2px',
                  marginBottom: '5px',
                  padding: '10px',
                }}
              >
                <CardBody>
                  <GridContainer>
                    <GridItem xs={6} sm={6} md={12} lg={12}>
                      <p
                        style={{
                          fontWeight: 'bold',
                          fontSize: '12px',
                          marginBottom: '3px',
                          textAlign: 'left',
                        }}
                      >
                        {transporter_name}
                      </p>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={12} lg={12}>
                      <p
                        style={{
                          fontSize: '12px',
                          marginBottom: '3px',
                          textAlign: 'right',
                        }}
                      >
                        {type_of_vehicle}
                      </p>
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      style={{ marginTop: '10px', marginBottom: '10px' }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <p
                          style={{
                            fontWeight: 'bold',
                            fontSize: '12px',
                            marginBottom: '3px',
                            textAlign: 'left',
                          }}
                        >
                          {from_location}
                        </p>
                        <p
                          style={{
                            fontSize: '12px',
                            marginBottom: '3px',
                            textAlign: 'center',
                          }}
                        >
                          <i
                            className="fa  fa-arrow-right"
                            style={{
                              marginLeft: '2px',
                              marginRight: '2px',
                            }}
                            aria-hidden="true"
                          />
                        </p>
                        <p
                          style={{
                            fontWeight: 'bold',
                            fontSize: '12px',
                            marginBottom: '3px',
                            textAlign: 'right',
                          }}
                        >
                          {to_location}
                        </p>
                      </div>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={12} lg={12}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <p
                          style={{
                            fontWeight: 'bold',
                            fontSize: '12px',
                            marginBottom: '3px',
                            textAlign: 'left',
                          }}
                        >
                          {demand_price}
                        </p>
                      </div>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={12} lg={12}>
                      <p
                        style={{
                          fontSize: '12px',
                          marginBottom: '3px',
                          textAlign: 'right',
                        }}
                      >
                        {material_type}
                      </p>
                    </GridItem>
                  </GridContainer>
                  <GridItem />
                </CardBody>
                <div
                  style={{
                    width: '100%',
                    //   backgroundColor: '#cfe5ef',
                    padding: '2px',
                    borderRadius: '4px',
                  }}
                >
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <Card
                        className={classes.card}
                        style={{
                          background: '#cfe5ef',
                          marginTop: '10px',
                          marginBottom: '10px',
                        }}
                      >
                        <CardBody>
                          <GridContainer>
                            <GridItem xs={6} sm={6} md={12} lg={12}>
                              <p
                                style={{
                                  fontWeight: 'bold',
                                  fontSize: '12px',
                                  marginBottom: '3px',
                                  textAlign: 'left',
                                }}
                              >
                                {trucker_name}
                              </p>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={12} lg={12}>
                              <p
                                style={{
                                  fontWeight: 'bold',
                                  fontSize: '12px',
                                  marginBottom: '3px',
                                  textAlign: 'right',
                                }}
                              >
                                {transaction_serial || '___'}
                              </p>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              style={{
                                marginTop: '10px',
                                marginBottom: '10px',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <p
                                  style={{
                                    fontWeight: 'bold',
                                    fontSize: '12px',
                                    marginBottom: '3px',
                                    textAlign: 'left',
                                  }}
                                >
                                  {from_location}
                                </p>
                                <p
                                  style={{
                                    fontSize: '12px',
                                    marginBottom: '3px',
                                    textAlign: 'center',
                                  }}
                                >
                                  <i
                                    className="fa  fa-arrow-right"
                                    style={{
                                      marginLeft: '2px',
                                      marginRight: '2px',
                                    }}
                                    aria-hidden="true"
                                  />
                                </p>
                                <p
                                  style={{
                                    fontWeight: 'bold',
                                    fontSize: '12px',
                                    marginBottom: '3px',
                                    textAlign: 'right',
                                  }}
                                >
                                  {to_location}
                                </p>
                              </div>
                            </GridItem>
                            <GridItem
                              xs={4}
                              sm={4}
                              md={12}
                              lg={12}
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <p
                                  style={{
                                    fontWeight: 'bold',
                                    fontSize: '12px',
                                    marginBottom: '3px',
                                    textAlign: 'left',
                                  }}
                                >
                                  {price === true
                                    ? '___'
                                    : Intl.NumberFormat('en-IN').format(price)}
                                </p>
                              </div>
                            </GridItem>
                            <GridItem
                              xs={4}
                              sm={4}
                              md={12}
                              lg={12}
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <p
                                style={{
                                  fontSize: '12px',
                                  marginBottom: '3px',
                                  textAlign: 'center',
                                }}
                              >
                                {material_type}
                              </p>
                            </GridItem>
                            <GridItem
                              xs={4}
                              sm={4}
                              md={6}
                              lg={6}
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                              }}
                            >
                              <div
                                className={classes.checkboxAndRadio}
                                style={{
                                  top: '0px',
                                  marginTop: '0px',
                                  marginBottom: '0px',
                                  textAlign: 'right',
                                }}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      tabIndex={-1}
                                      checked
                                      onClick={() => this.handleMatched(demand)}
                                      checkedIcon={
                                        <Check
                                          className={classes.checkedIcon}
                                        />
                                      }
                                      icon={
                                        <Check
                                          className={classes.uncheckedIcon}
                                        />
                                      }
                                      classes={{
                                        checked: classes.checked,
                                        root: classes.checkRoot,
                                      }}
                                    />
                                  }
                                  classes={{
                                    label: classes.label,
                                    root: classes.labelRoot,
                                  }}
                                  label=""
                                />
                              </div>
                            </GridItem>
                          </GridContainer>
                          <GridItem />
                        </CardBody>
                      </Card>
                    </GridItem>
                  </GridContainer>
                </div>
              </Card>
            </GridItem>
          </GridContainer>
        </Mobile>
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    const { isLoading, demandsData } = this.state;
    return (
      <>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader className={`${classes.cardHeader}`} color="info">
                <GridContainer>
                  <GridItem xs={12} md={4} lg={4}>
                    <h1
                      className={`${classes.cardHeader} ${classes.rajdhaniFont} ${classes.demandHeaderText}`}
                      style={{ marginBottom: '0px', fontSize: '20px' }}
                    >
                      Matched
                    </h1>
                  </GridItem>
                  <GridItem xs={12} md={8} lg={8}>
                    {this.state.isRefresh ? (
                      <CircularProgress
                        className={classes.progress}
                        style={{ color: purple[500] }}
                        thickness={2}
                      />
                    ) : (
                      <Button
                        size="md"
                        simple
                        color="primary"
                        onClick={() => this.refresh()}
                        style={{
                          marginLeft: '10px',
                          float: 'right',
                          textAlign: 'right',
                        }}
                      >
                        <Refresh className={classes.underChartIcons} />
                      </Button>
                    )}
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} md={3} lg={3} />
                  <GridItem xs={12} md={9} lg={9} />
                </GridContainer>
              </CardHeader>
              <CardBody
                className={`${classes.cardHeader} ${classes.textCenter}`}
                style={{
                  paddingTop: '0px',
                  paddingRight: '5px',
                  paddingLeft: '5px',
                }}
              >
                {isLoading ? (
                  this.skeltonViewDemand()
                ) : (
                  <React.Fragment>
                    {demandsData.length <= 0 ? (
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        <p
                          className={classes.textCenter}
                          style={{ marginTop: '20px' }}
                        >
                          No Matches found.
                        </p>
                      </GridItem>
                    ) : (
                      <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        style={{ paddingLeft: '0px', paddingRight: '0px' }}
                      >
                        <div
                          style={{ paddingTop: '5px', paddingBottom: '5px' }}
                        >
                          <Desktop>
                            <div style={{ marginTop: '20px' }}>
                              {this.renderTableHeader()}
                            </div>
                            <div>
                              {demandsData.map((demand, i) => (
                                <div key={i}>
                                  {this.renderTitle(demand)}
                                  <hr
                                    style={{
                                      marginTop: '10px',
                                      marginBottom: '10px',
                                      color: '#000',
                                      borderTop: '1px solid #9E9E9E',
                                    }}
                                  />
                                </div>
                              ))}
                            </div>
                          </Desktop>
                          <Mobile>
                            {demandsData.map((demand, i) => (
                              <div key={i}>
                                {this.renderTitle(demand)}
                                <hr
                                  style={{
                                    marginTop: '5px',
                                    marginBottom: '5px',
                                    color: '#000',
                                    borderTop: '1px solid #9E9E9E',
                                  }}
                                />
                              </div>
                            ))}
                          </Mobile>
                        </div>
                      </GridItem>
                    )}
                  </React.Fragment>
                )}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

DemandHistory.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  activeRole: state.main.activeRole,
  userDetails: state.main.userDetails,
  systemDetails: state.main.systemDetails,
});

export default connect(mapStateToProps)(
  withStyles(TransactionStyle)(DemandHistory)
);
