/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import {
  Button,
  CircularProgress,
  IconButton,
  Paper,
  makeStyles,
} from '@material-ui/core';
import Popup from 'reactjs-popup';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import React, { useState, useEffect } from 'react';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import VisibilityIcon from '@material-ui/icons/Visibility';
import moment from 'moment';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import myFirebase from '../../../../config/config';
import apiCaller from '../../../../utils/apiCallerOutstanding';
import FeatureFlagEmptyState from '../../../Components/FeatureFlagEmptyState';
import swal from 'sweetalert';
import getFileMimeType from '../../../../utils/getFileMimeType';

const contentStyle = { width: '300px' };
const useStyles = makeStyles(theme => ({
  paper: { padding: 10, marginTop: 20 },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    border: '2px dashed lightgray',
    borderRadius: '5px',
    margin: '14px 20px',
  },
  viewButton: {
    background: '#52BE56',
    color: 'white',
    marginRight: '20px',
    padding: '5px 42px 5px 10px',
  },
  viewCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '14px 20px',
  },
  viewCardMeta: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  deleteModalButton: {
    width: '45%',
    background: '#52BE56',
    borderRadius: 0,
    color: 'white',
    margin: '5px',
  },
  cancelButton: {
    width: '45%',
    background: 'lightgray',
    borderRadius: 0,
    margin: '5px',
  },
}));
const EwayBillUpload = ({
  currentTransactionData,
  activeRole,
  stylesClass,
  featureFlagFiles,
}) => {
  const classes = useStyles();
  const [uploading, setUploading] = useState(false);
  const [uploadedURL, setUploadedUrl] = useState('');
  const [uploadedBy, setUploadedBy] = useState('');
  const [uploadedAt, setUploadedAt] = useState('');
  const [token, setToken] = useState('');
  const [isDeleting, setIsDeleting] = React.useState(false);
  const { transactionId } = currentTransactionData;
  const fetchEwayUrl = async userToken => {
    setUploading(true);
    const body = {
      scope: 'trip',
      type: 'view',
      file_type: 'eway-bill',
      transaction_id: transactionId,
    };
    const endPoint = `pipe/files`;
    try {
      const response = await apiCaller(
        endPoint,
        body,
        `Bearer ${userToken}`,
        'pipe'
      );

      const fileUrl = response?.data?.url;
      const fileUploadedBy = response?.data?.uploaded_by;
      const fileUploadedAt = response?.data?.updated_at;
      setUploadedBy(fileUploadedBy);
      setUploadedAt(fileUploadedAt);
      setUploadedUrl(fileUrl);
      setUploading(false);
    } catch (e) {
      setUploading(false);
      return null;
    }
  };
  const fetchS3URl = async UploadedFile => {
    const mime = await getFileMimeType(UploadedFile);
    if (mime !== 'Unknown file type') {
      let s3Url = '';
      const body = {
        scope: 'trip',
        type: 'signed_url',
        file_type: 'eway-bill',
        transaction_id: transactionId,
        ext: mime,
      };
      const endPoint = `pipe/files`;

      try {
        const response = await apiCaller(
          endPoint,
          body,
          `Bearer ${token}`,
          'pipe'
        );
        const fileUrl = response.data.url;
        s3Url = fileUrl;
      } catch (e) {
        return e;
      }
      return s3Url;
    }
    swal('Oops!', 'Unknown file type. Please upload a valid file.', 'error');
    setUploading(false);
  };
  const onDrop = async acceptedFiles => {
    // Do something with the files
    setUploading(true);
    const url = await fetchS3URl(acceptedFiles[0]);
    try {
      await axios.put(url, acceptedFiles[0], {
        headers: {
          'content-Type': acceptedFiles[0]?.type,
        },
      });
      setTimeout(() => {
        fetchEwayUrl(token);
        setUploading(false);
      }, 5000);
    } catch (e) {
      return null;
    }
  };
  const { getRootProps, getInputProps } = useDropzone({
    acceptedFiles: {
      'image/png': ['.png', '.jpeg', '.jpg'],
      'application/pdf': ['.pdf'],
    },
    onDrop,

    maxFiles: 1,
  });

  function viewFIleUploaded(podUrl) {
    window.open(podUrl);
  }

  async function deleteFIleUploaded() {
    setIsDeleting(true);
    const currentUserDetails = myFirebase.auth().currentUser;
    const { Aa } = currentUserDetails;
    const userToken = Aa;
    const body = {
      scope: 'trip',
      type: 'delete',
      file_type: 'eway-bill',
      transaction_id: transactionId,
    };

    const endPoint = 'pipe/files';
    try {
      await apiCaller(endPoint, body, `Bearer ${userToken}`, 'pipe');

      setUploadedUrl('');
      setIsDeleting(false);
    } catch (e) {
      setIsDeleting(false);
    }
  }
  useEffect(() => {
    const currentUserDetails = myFirebase.auth().currentUser;
    const { Aa } = currentUserDetails;
    const userToken = Aa;
    setToken(userToken);
    fetchEwayUrl(userToken);
  }, []);

  return (
    <>
      {currentTransactionData?.checkOne && currentTransactionData?.checkTwo ? (
        <Paper className={classes.paper}>
          <div style={{ textAlign: 'left', marginLeft: 20 }}>
            <h3 className={stylesClass.classes.cardTitle}>E-Way Bill</h3>
          </div>
          {featureFlagFiles === 0 || featureFlagFiles === 2 ? (
            <>
              {uploadedURL === '' ? (
                <div>
                  {activeRole === 'sales' ||
                  activeRole === 'branch-ops' ||
                  activeRole === 'traffic' ? (
                    <div {...getRootProps()} className={classes.container}>
                      <input {...getInputProps()} />
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'column',
                          marginTop: 5,
                        }}
                      >
                        {uploading ? (
                          <CircularProgress />
                        ) : (
                          <>
                            <CloudUploadIcon />
                            <p style={{ fontSize: '10px', color: 'gray' }}>
                              Drag and Drop or Click here to upload
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className={classes.viewCard}>
                      <p>No file uploaded</p>
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <div className={classes.viewCard}>
                    <div className={classes.viewCardMeta}>
                      <p style={{ fontWeight: 'bold' }}>{uploadedBy}</p>
                      <p style={{ color: '#a9a9a9', fontSize: '12px' }}>
                        {moment(uploadedAt).format('LLL')}
                      </p>
                    </div>
                    <div>
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        className={classes.viewButton}
                        onClick={() => viewFIleUploaded(uploadedURL)}
                        startIcon={
                          <VisibilityIcon style={{ marginRight: '23px' }} />
                        }
                        // loading={fetching}
                      >
                        View
                      </Button>
                      {activeRole === 'ops' && (
                        <Popup
                          trigger={
                            isDeleting ? (
                              <CircularProgress
                                style={{ color: '#FCD21F', margin: '5px' }}
                                size={30}
                                thickness={8}
                              />
                            ) : (
                              <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                              >
                                <DeleteOutlineIcon color="error" />
                              </IconButton>
                            )
                          }
                          {...{
                            contentStyle,
                          }}
                          modal
                        >
                          {close => (
                            <div
                              style={{
                                padding: '10px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                              }}
                            >
                              <HighlightOffIcon
                                fontSize="large"
                                color="error"
                              />

                              <h4 style={{ fontWeight: 'bold' }}>
                                Are You Sure?
                              </h4>
                              <p>
                                If you proceed, you will not be able to recover
                                it
                              </p>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  width: '100%',
                                }}
                              >
                                <Button
                                  onClick={close}
                                  className={classes.cancelButton}
                                >
                                  No
                                </Button>

                                <Button
                                  onClick={() => {
                                    close();
                                    deleteFIleUploaded();
                                  }}
                                  className={classes.deleteModalButton}
                                >
                                  Yes
                                </Button>
                              </div>
                            </div>
                          )}
                        </Popup>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : featureFlagFiles === 1 ? (
            <FeatureFlagEmptyState color="black" />
          ) : null}
        </Paper>
      ) : null}
    </>
  );
};

export default EwayBillUpload;
