/* eslint class-methods-use-this: ["error", { "exceptMethods": ["verifyRequired","verifyGst","verifyEmail","verifyNumber","verifyPan","verifyPhone","verifyLength"] }] */
import React from 'react';
import { connect } from 'react-redux';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import PropTypes from 'prop-types';

// @material-ui/icons
import GroupAdd from '@material-ui/icons/GroupAdd';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Clearfix from 'components/Clearfix/Clearfix.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';

import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';

import SweetAlert from 'react-bootstrap-sweetalert';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import Danger from 'components/Typography/Danger.jsx';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// Fireabse
import userProfileStyles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx';
import firebase from '../../config/config';

const db = firebase.firestore();

class AddPremiumTransporters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      nameState: '',

      email: '',
      emailState: '',
      phoneNumber: '',
      phoneNumberState: '',
      gstNumber: '',
      gstNumberState: '',

      panNumber: '',
      panNumberState: '',

      companyName: '',
      isLoading: false,

      alert: null,
      cities: [],
      citiesState: '',
      selectedValue: 'male',
      roles: [],
      companyId: '',
      companyNameState: '',
      rolesState: '',
      companies: [],
      allBranches: [],
    };
    this.change = this.change.bind(this);
    this.submitUser = this.submitUser.bind(this);
    this.validateAllFields = this.validateAllFields.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.handleCities = this.handleCities.bind(this);
    this.handleRoles = this.handleRoles.bind(this);
    this.handleCompanies = this.handleCompanies.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getRadioForm = this.getRadioForm.bind(this);
    this.getBranches = this.getBranches.bind(this);
    this.verifyRequired = this.verifyRequired.bind(this);
  }

  componentDidMount() {
    this.getBranches();
    this.getCompanies();
  }

  // Get Branches
  getBranches() {
    firebase.auth().onAuthStateChanged(user => {
      const branches = db.collection(`Branches`).doc('Branches');
      branches.get().then(myBranches => {
        if (myBranches.exists) {
          this.setState({
            allBranches: myBranches.data().branches,
          });
        }
      });
    });
  }

  getCompanies() {
    const companies = [];
    const companyRef = db.collection('Companies');
    companyRef.get().then(companiesSnap => {
      if (!companiesSnap.empty) {
        companiesSnap.forEach(company => {
          companies.push(company.data());
        });
        this.setState({ companies });
      }
    });
  }

  getRadioForm() {
    const { classes } = this.props;
    const { selectedValue } = this.state;

    return (
      <div className={classes.inlineChecks} style={{ marginTop: '5px' }}>
        <InputLabel htmlFor="multiple-select" className={classes.selectLabel}>
          Gender
        </InputLabel>
        <br />
        <FormControlLabel
          control={
            <Radio
              checked={selectedValue === 'male'}
              onChange={this.handleChange}
              value="male"
              name="radio button demo"
              aria-label="Male"
              icon={<FiberManualRecord className={classes.radioUnchecked} />}
              checkedIcon={
                <FiberManualRecord className={classes.radioChecked} />
              }
              classes={{
                checked: classes.radio,
              }}
            />
          }
          classes={{
            label: classes.label,
          }}
          label="Male"
        />

        <FormControlLabel
          control={
            <Radio
              checked={selectedValue === 'female'}
              onChange={this.handleChange}
              value="female"
              name="radio button demo"
              aria-label="Female"
              icon={<FiberManualRecord className={classes.radioUnchecked} />}
              checkedIcon={
                <FiberManualRecord className={classes.radioChecked} />
              }
              classes={{
                checked: classes.radio,
              }}
            />
          }
          classes={{
            label: classes.label,
          }}
          label="Female"
        />
      </div>
    );
  }

  getMultiselectForm() {
    const { classes } = this.props;
    const { cities, allBranches, citiesState } = this.state;
    return (
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel htmlFor="multiple-select" className={classes.selectLabel}>
            Select Branch
          </InputLabel>
          <Select
            multiple
            value={cities}
            onChange={this.handleCities}
            MenuProps={{ className: classes.selectMenu }}
            classes={{ select: classes.select }}
            inputProps={{ name: 'multipleSelect2', id: 'multiple-select2' }}
          >
            <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
              Choose City
            </MenuItem>
            {allBranches.map((branch, i) => (
              <MenuItem
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
                value={branch}
              >
                {branch}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Danger>{citiesState}</Danger>
      </GridItem>
    );
  }

  getMultiselectFormRole() {
    const { classes } = this.props;
    const { roles, rolesState } = this.state;
    return (
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel htmlFor="multiple-select" className={classes.selectLabel}>
            Select Role
          </InputLabel>
          <Select
            value={roles}
            onChange={this.handleRoles}
            MenuProps={{ className: classes.selectMenu }}
            classes={{ select: classes.select }}
            inputProps={{ name: 'multipleSelect', id: 'multiple-select' }}
          >
            <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
              Choose Role
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value="premiumTransporterAdmin"
            >
              Transporter Admin
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value="premiumTransporterContact"
            >
              Transporter Contact
            </MenuItem>
          </Select>
        </FormControl>
        <Danger>{rolesState}</Danger>
      </GridItem>
    );
  }

  getMultiselectFormCompany() {
    const { classes } = this.props;
    const { companies, rolesState, companyId } = this.state;
    return (
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel htmlFor="multiple-select" className={classes.selectLabel}>
            Select Companies
          </InputLabel>
          <Select
            value={companyId}
            onChange={this.handleCompanies}
            MenuProps={{ className: classes.selectMenu }}
            classes={{ select: classes.select }}
            inputProps={{ name: 'multipleSelect1', id: 'multiple-select1' }}
          >
            <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
              Choose Role
            </MenuItem>
            {companies.map((company, i) => (
              <MenuItem
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
                value={company.companyId}
              >
                {company.companyName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Danger>{rolesState}</Danger>
      </GridItem>
    );
  }

  handleCities = event => {
    this.setState({ cities: event.target.value });
  };

  handleRoles = event => {
    this.setState({
      roles: event.target.value,
      companyId: '',
      companyName: '',
    });
  };

  handleCompanies = event => {
    const { companies } = this.state;
    for (const i in companies) {
      if (companies[i].companyId === event.target.value) {
        this.setState({
          companyId: companies[i].companyId,
          companyName: companies[i].companyName,
        });
      }
    }
    this.setState({ companyId: event.target.value });
  };

  handleChange(event) {
    this.setState({ selectedValue: event.target.value });
  }

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  // function that returns true if value is there, false otherwise
  verifyRequired(value) {
    if (value) {
      return true;
    }
    return false;
  }

  // function that returns true if value is Valid GST, false otherwise
  verifyGst(value) {
    const gstRex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    if (gstRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    const emailRex = /[^\s@]+@[^\s@]+\.[^\s@]+/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if value contains only numbers
  verifyNumber(value) {
    const numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if value contains PanCard
  verifyPan(value) {
    const regpan = /(^([a-zA-Z]{5})([0-9]{4})([a-zA-Z]{1})$)/;
    if (regpan.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if value contains PanCard
  verifyPhone(value) {
    const regPhone = /^[6789]\d{9}$/;
    if (regPhone.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'required':
        if (this.verifyRequired(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'number':
        if (this.verifyNumber(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'pan':
        if (this.verifyPan(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'phone':
        if (this.verifyPhone(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'gst':
        if (this.verifyGst(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      default:
        break;
    }
  }

  // validates all required fields and returns a promise
  validateAllFields() {
    const { name, companyName, email, roles } = this.state;
    let error = false;
    return new Promise((resolve, reject) => {
      // Verify Name
      if (this.verifyLength(name, 1)) {
        this.setState({
          nameState: 'success',
        });
      } else {
        this.setState({
          nameState: 'error',
        });
        error = true;
      }
      if (this.verifyLength(companyName, 1)) {
        this.setState({ companyNameState: 'success' });
      } else {
        this.setState({ companyNameState: 'error' });
        error = true;
      }
      // Verify Email
      if (this.verifyEmail(email)) {
        this.setState({
          emailState: 'success',
        });
      } else {
        this.setState({
          emailState: 'error',
        });
        error = true;
      }
      if (roles.length > 0) {
        this.setState({ rolesState: '' });
      } else {
        this.setState({ rolesState: 'Please Select Role' });
        error = true;
      }
      if (!error) {
        resolve(true);
      } else {
        reject(error);
      }
    });
  }

  /**
   * Submits the user details to the Database
   * @function
   */
  submitUser() {
    const self = this;
    const { roles } = this.state;
    const { classes, systemDetails, userDetails } = this.props;
    this.setState({
      isLoading: true,
    });
    this.validateAllFields()
      .then(
        success => {
          //   let _me = this;
          const {
            name,
            phoneNumber,
            email,
            gstNumber,
            panNumber,
            selectedValue,
            companyName,
            companyId,
          } = this.state;
          let isPremiumTransporterAdmin = false;
          let isPremiumTransporterContact = true;
          if (success) {
            if (roles.includes('premiumTransporterAdmin')) {
              isPremiumTransporterAdmin = true;
              isPremiumTransporterContact = false;
            } else if (roles.includes('premiumTransporterContact')) {
              isPremiumTransporterContact = true;
            }
            const body = {
              name,
              phoneNumber: `+91${phoneNumber}`,
              email,
              gstNumber,
              panNumber,
              gender: selectedValue,
              isPremiumTransporterAdmin,
              isPremiumTransporterContact,
              companyName,
              companyId,
            };
            const addPremiumTransporter = firebase
              .functions()
              .httpsCallable('transporters-addPremiumTransporter');
            addPremiumTransporter(body)
              .then(result => {
                const user = firebase.auth().currentUser;
                const auditLog = firebase
                  .functions()
                  .httpsCallable('utilities-auditLog');
                const auditBody = {
                  data: {
                    name,
                    phoneNumber: `+91${phoneNumber}`,
                    email,
                    gstNumber,
                    panNumber,
                    gender: selectedValue,
                    isPremiumTransporterAdmin,
                    isPremiumTransporterContact,
                    companyName,
                    companyId,
                    status: 'success',
                  },
                  updatedBy: user.uid,
                  collection: 'Companies And Users',
                  type: 'Add Premium Transporter',
                  systemDetails,
                  message: `${userDetails.email} added Premium Transporter with email ${email}`,
                };
                auditLog(auditBody).then(response => {
                  console.log('success');
                });
                // Read result of the Cloud Function.
                self.clearForm();
                self.setState({
                  isLoading: false,
                });
              })
              .catch(err => {
                const { message } = err;
                const userData = firebase.auth().currentUser;
                const errorBody = {
                  message: `${userDetails.email} tried to add a Premium Transporter with email ${email} but FAILED.`,
                  status: 'Fail',
                  error: err,
                  updatedBy: userData.uid,
                  errorMessage: message,
                  collection: 'Users',
                  systemDetails,
                };
                const auditLogError = firebase
                  .functions()
                  .httpsCallable('utilities-auditLog');
                auditLogError(errorBody).then(response => {
                  console.log('success');
                });
                self.setState({
                  isLoading: false,
                  alert: (
                    <SweetAlert
                      error
                      style={{
                        display: 'block',
                        marginTop: '-100px',
                      }}
                      title="Error"
                      onConfirm={() => this.hideAlert()}
                      onCancel={() => this.hideAlert()}
                      confirmBtnCssClass={`${classes.button} ${classes.success}`}
                    >
                      {message}
                    </SweetAlert>
                  ),
                });
              });
          }
        },
        err => {
          console.log(err);
          self.setState({
            isLoading: false,
          });
        }
      )
      .catch(err => {
        console.log(err);
        self.setState({
          isLoading: false,
        });
      });
  }

  /**
   * Will Clears ALl the State values after form submision
   * @function
   */
  clearForm() {
    const { classes } = this.props;
    const self = this;
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title="Good job!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={`${classes.button} ${classes.success}`}
        >
          Transporter Added Successfully
        </SweetAlert>
      ),
    });
    self.setState({
      name: '',
      nameState: '',
      email: '',
      emailState: '',
      contactPerson: '',
      contactPersonState: '',
      phoneNumber: '',
      phoneNumberState: '',
      creditLimit: '',
      creditLimitState: '',
      gstNumber: '',
      gstNumberState: '',
      panNumber: '',
      panNumberState: '',
      city: '',
      cityState: '',
      state: '',
      stateState: '',
      postalCode: '',
      postalCodeState: '',
      cities: [],
      citiesState: '',
    });
  }

  render() {
    const { classes } = this.props;
    const { roles } = this.state;
    let isPremiumTransporterAdmin = false;
    if (roles.includes('premiumTransporterAdmin')) {
      isPremiumTransporterAdmin = true;
    } else if (roles.includes('premiumTransporterContact')) {
      // isPremiumTransporterContact = true;
      // Do nothing
    }
    const {
      alert,
      companyNameState,
      companyName,
      nameState,
      name,
      phoneNumber,
      phoneNumberState,
      emailState,
      email,
      gstNumber,
      gstNumberState,
      panNumber,
      panNumberState,
      isLoading,
    } = this.state;

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            {alert}
            <Card>
              <CardHeader className={`${classes.cardHeader}`} color="warning">
                <CardIcon color="primary" className={`${classes.cardHeader}`}>
                  <GroupAdd />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  Add Transporter <small />
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    {this.getMultiselectFormRole()}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    {isPremiumTransporterAdmin ? (
                      <CustomInput
                        labelText="Company Name *"
                        id="comanyName"
                        formControlProps={{ fullWidth: true }}
                        success={companyNameState === 'success'}
                        error={companyNameState === 'error'}
                        inputProps={{
                          onChange: event =>
                            this.change(event, 'companyName', 'length', 1),
                          type: 'length',
                          value: companyName,
                        }}
                      />
                    ) : (
                      this.getMultiselectFormCompany()
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText=" Transporter Name *"
                      id="username"
                      formControlProps={{ fullWidth: true }}
                      success={nameState === 'success'}
                      error={nameState === 'error'}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'name', 'length', 1),
                        type: 'length',
                        value: name,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Phone Number *"
                      id="phone-number"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'phoneNumber', 'phone'),
                        type: 'phone',
                        value: phoneNumber,
                      }}
                      success={phoneNumberState === 'success'}
                      error={phoneNumberState === 'error'}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      success={emailState === 'success'}
                      error={emailState === 'error'}
                      labelText="Email address *"
                      id="email-address"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event => this.change(event, 'email', 'email'),
                        type: 'email',
                        value: email,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="GST Number "
                      id="gst-number"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'gstNumber', 'gst'),
                        type: 'gst',
                        value: gstNumber,
                      }}
                      success={gstNumberState === 'success'}
                      error={gstNumberState === 'error'}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="PAN Number"
                      id="pan-number"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'panNumber', 'pan'),
                        type: 'pan',
                        value: panNumber,
                      }}
                      success={panNumberState === 'success'}
                      error={panNumberState === 'error'}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    {this.getRadioForm()}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4} />
                </GridContainer>
                {isLoading ? (
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={4}>
                      <CircularProgress
                        className={classes.progress}
                        style={{ color: purple[500] }}
                        thickness={7}
                      />
                    </GridItem>
                  </GridContainer>
                ) : (
                  <Button
                    color="warning"
                    onClick={this.submitUser}
                    className={classes.updateProfileButton}
                  >
                    Submit
                  </Button>
                )}
                <Clearfix />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

AddPremiumTransporters.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  userDetails: state.main.userDetails,
  systemDetails: state.main.systemDetails,
});

export default connect(mapStateToProps)(
  withStyles(userProfileStyles)(AddPremiumTransporters)
);
