/* eslint-disable no-useless-concat */
/* eslint-disable vars-on-top */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable guard-for-in */
/* eslint-disable no-var */
/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  CircularProgress,
  // Snackbar,
  Typography,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CachedIcon from '@material-ui/icons/Cached';
import TableRow from '@material-ui/core/TableRow';
// import IconButton from '@material-ui/core/IconButton';
// import CloseIcon from '@material-ui/icons/Close';

import MultiSelectDropdown from './MultiSelectDropdown';
import ExcelIcon from './excelImage.svg';
import firebase from '../../../config/config';
import apiCaller from '../../../utils/apiCallerOutstanding';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  tableContainer: {
    minHeight: 700,
  },
  table: {
    minWidth: 700,
  },
}));
const shifter = {
  position: 'relative',
  padding: '15px',
  paddingLeft: '30px',
  paddingRight: '30px',
  border: 'solid black 2px',
  // borderRadius: '7px',
  backgroundColor: 'white',
};
const avatarContainer = {
  display: 'flex',
  alignItems: 'center',
  // background: 'white',
  padding: '2px 10px',
  // borderRadius: '30px 30px 0px 0px',
};
const avatar = {
  background: '#163BAC',
  padding: '12px',
  borderRadius: '50%',
  margin: '10px',
};
var branchesAssigned = [];
var branchesSelected = [];
var OutstandingData = [];
var offset = 0;
var limit = 10;
var fileName = '';
var poll = true;
const TotalOutstandingByBranch = () => {
  const childRef = useRef(null);
  const [fetching, setFetching] = useState(false);
  const [token, setToken] = useState('');
  const [polling, setpolling] = useState(false);
  const [downloadDisabled, setDownloadDisabled] = useState(false);
  const [open, setOpen] = React.useState(true);
  const [nextDisabled, setNextDisabled] = useState(false);
  const [branchesOptions, setBranchesOptions] = useState([]);
  const [lastRefreshTime, setLastRefreshTime] = useState(
    moment().format('MMMM Do YYYY, h:mm:ss a')
  );
  const db = firebase.firestore();
  const StyledTableCell = withStyles(theme => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  const StyledTableRow = withStyles(theme => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: 'white',
      },
    },
  }))(TableRow);
  const classes = useStyles();

  const TableHeaders = [
    // 'Sl. No',
    'Transporter Name',
    'Branch',
    // 'Trucker Name',
    'Amount Due',
  ];
  const toIndianCurrency = num => {
    if (num) {
      const curr = num.toLocaleString('en-IN', {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
      return `₹ ${curr}`;
    }
    return 0;
  };
  function createData(
    index,
    transporter_name,
    branch,
    trucker_name,
    amount_due
  ) {
    return {
      index,
      transporter_name,
      branch,
      trucker_name,
      amount_due,
    };
  }

  const rows = OutstandingData.map((item, index) =>
    createData(
      index,
      item.transporter_name,
      item.branch,
      item.trucker_name,
      toIndianCurrency(item.amount_due)
    )
  );
  async function getOutStandingByBranchDATA(branch, token) {
    OutstandingData = [];
    // transporters = [];
    setFetching(true);
    const ENDPOINT = 'get-receivable-outstanding-by-branch';
    const data = {
      type: 'ATH',
      filter_branch:
        branch.length > 0
          ? branch
          : branchesOptions?.map(branch => branch.value),
      limit,
      offset,
    };
    try {
      const response = await await apiCaller(ENDPOINT, data, `Bearer ${token}`);
      const outstanding = response.data.data;
      if (outstanding.length < 10) {
        setNextDisabled(true);
      }
      OutstandingData = [...OutstandingData, ...outstanding];
      setFetching(false);
      setDownloadDisabled(false);
    } catch (error) {
      // setOpen(true);
      setDownloadDisabled(true);
      setFetching(false);
    }
  }
  async function getUserDetails(uid, token) {
    const podRef = await db.collection('Users').doc(uid);
    podRef
      .get()
      .then(async doc => {
        if (doc.exists) {
          const data = doc.data();
          const { branches } = data;
          for (const i in branches) {
            branchesAssigned.push({ value: branches[i], label: branches[i] });
          }
          setBranchesOptions(branchesAssigned);
          await getOutStandingByBranchDATA(branches, token);
        }
      })
      .catch(error => error);
  }
  const handlePrevious = () => {
    offset -= 10;
    if (branchesSelected.length == 0) {
      getOutStandingByBranchDATA(branchesAssigned, token);
    } else getOutStandingByBranchDATA(branchesSelected, token);
  };
  const handleNext = () => {
    offset += 10;
    if (branchesSelected.length == 0) {
      getOutStandingByBranchDATA(branchesAssigned, token);
    } else getOutStandingByBranchDATA(branchesSelected, token);
  };
  const pollExcelDownload = () => {
    setTimeout(() => {
      generateExcelFile();
    }, 3000);
  };
  const handleDownload = (url, fileName) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;

    document.body.appendChild(link);
    link.click();
    setpolling(false);
  };
  const generateExcelFile = async () => {
    setpolling(true);
    if (!fileName) {
      const newDate = moment().format();
      fileName = `Outstanding-By-Branch-${
        branchesSelected.length > 0 ? branchesSelected : 'All'
      }-${newDate}.xlsx`;
    }
    const ENDPOINT = 'get-outstanding-report-excel';
    const data = {
      file_name: fileName,
      is_polling: poll ? 0 : 1,
      outstanding_type: 'receivableOutstandingByBranch',
      type: 'ATH',
      filter_branch:
        branchesSelected.length > 0 ? branchesSelected : branchesAssigned,
    };
    try {
      await await apiCaller(ENDPOINT, data, `Bearer ${token}`).then(
        response => {
          const { url } = response.data;
          if (!url) {
            poll = false;
            pollExcelDownload();
          } else {
            poll = true;
            fileName = '';
            const isWindowOpenSupported = window.open('', '_blank');

            if (isWindowOpenSupported) {
              // Popup window is supported, open the URL in a new window
              window.open(url, '_blank');
              setpolling(false);
            } else {
              // Popup window is disabled, trigger download programmatically
              handleDownload(url, fileName);
            }
          }
        }
      );
    } catch (error) {
      setpolling(false);
    }
  };
  const handleOptionsSelect = value => {
    offset = 0;
    branchesSelected = [];
    for (var i in value) {
      branchesSelected.push(value[i]);
    }

    getOutStandingByBranchDATA(branchesSelected, token);
  };
  const handleRefresh = () => {
    offset = 0;
    childRef.current.resetOptions();
    setLastRefreshTime(moment().format('MMMM Do YYYY, h:mm:ss a'));
    const branches = branchesOptions?.map(branch => branch.value);
    getOutStandingByBranchDATA(branches, token);
  };
  useEffect(() => {
    const userDetails = firebase.auth().currentUser;
    const { uid, Aa } = userDetails;
    setToken(Aa);
    getUserDetails(uid, Aa);
  }, []);
  return (
    <div>
      <fieldset style={shifter}>
        <Accordion elevation={0} expanded={open}>
          <AccordionSummary
            expandIcon={
              <IconButton
                style={{ background: '#fcd21f' }}
                onClick={() => setOpen(!open)}
                aria-label="delete"
              >
                <ExpandMoreIcon />
              </IconButton>
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <legend>
              <div style={avatarContainer}>
                <div style={avatar}>
                  <AccountBalanceIcon style={{ color: 'white' }} />
                </div>
                <Typography variant="h6" gutterBottom>
                  Total Outstanding by Branch
                </Typography>
              </div>
            </legend>{' '}
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ width: '100%' }}>
              <div className="refreshBar">
                <div style={{ display: ' flex', alignItems: 'center' }}>
                  <Button
                    color="primary"
                    startIcon={<CachedIcon />}
                    style={{ textTransform: 'capitalize' }}
                    onClick={handleRefresh}
                  >
                    refresh
                  </Button>
                  <Typography
                    variant="caption"
                    display="block"
                    style={{ color: 'lightgray' }}
                  >
                    Last refresh at {lastRefreshTime}
                  </Typography>
                </div>
                <div style={{ padding: '10px 0px' }}>
                  <MultiSelectDropdown
                    ref={childRef}
                    options={branchesOptions}
                    optionsCatch={value => handleOptionsSelect(value)}
                  />
                </div>
                {polling ? (
                  <CircularProgress />
                ) : (
                  <Button
                    color="primary"
                    startIcon={<img src={ExcelIcon} alt="icon" />}
                    style={{
                      textTransform: 'capitalize',
                      textDecoration: 'underline',
                    }}
                    disabled={downloadDisabled}
                    onClick={generateExcelFile}
                  >
                    Download Excel
                  </Button>
                )}
              </div>
              <div className="table-layout">
                <Grid container spacing={4}>
                  {/* -----------------Table View--------------- */}
                  <Grid container alignItems="center">
                    <Grid item xs={12}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Button
                          color="primary"
                          startIcon={<ArrowBackIosIcon />}
                          style={{ textTransform: 'capitalize' }}
                          variant
                          disabled={offset == 0}
                          onClick={handlePrevious}
                        >
                          Previous
                        </Button>

                        <Button
                          color="primary"
                          endIcon={<ArrowForwardIosIcon />}
                          style={{
                            textTransform: 'capitalize',
                          }}
                          onClick={handleNext}
                          disabled={nextDisabled}
                        >
                          Next
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <TableContainer className={classes.tableContainer}>
                      <Table
                        className={classes.table}
                        aria-label="customized table"
                      >
                        <TableHead>
                          <TableRow>
                            {TableHeaders?.map(header => (
                              <StyledTableCell
                                align={
                                  header == 'Sl. No' ||
                                  header == 'Transporter Name'
                                    ? 'left'
                                    : header == 'Amount Due'
                                    ? 'right'
                                    : 'center'
                                }
                              >
                                {header}
                              </StyledTableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        {fetching ? (
                          <CircularProgress />
                        ) : (
                          <TableBody>
                            {rows.map(row => (
                              <StyledTableRow key={row.name}>
                                {/* <StyledTableCell component="th" scope="row">
                            {row.index}
                          </StyledTableCell> */}
                                <StyledTableCell align="left">
                                  {row.transporter_name}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row.branch}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  {row.amount_due}
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        )}
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </fieldset>
    </div>
  );
};

export default TotalOutstandingByBranch;
