/* eslint-disable react/forbid-prop-types */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/sort-comp */
import React from 'react';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// @material-ui/icons
import { connect } from 'react-redux';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import firebase from '../../config/config';

const db = firebase.firestore();

class ImagesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imagesData: [],
    };
    this.getThumbNailView = this.getThumbNailView.bind(this);
    this.getAllImages = this.getAllImages.bind(this);
  }

  componentDidMount() {
    // const userRef = db.collection("Users")
    this.getAllImages();
  }

  getAllImages() {
    const imagesRef = db.collection('TruckerLeads');

    imagesRef.onSnapshot(snapshot => {
      const imagesData = [];
      snapshot.forEach(docs => {
        const { description } = docs.data();
        // console.log('2 ', description.split('\\n'));
        imagesData.push({
          imageUrl: docs.data().imageUrl,
          isAnalysing: docs.data().isAnalysing,
          description: description.split('\\n'),
          // description: docs.data().description,
          lat: docs.data().lat,
          long: docs.data().long,
        });
      });
      this.setState({
        imagesData,
      });
    });
  }

  getThumbNailView() {
    const { imagesData } = this.state;
    return (
      <GridContainer>
        {imagesData.map(image => (
          <GridItem xs={12} sm={12} md={3}>
            <div
              className="fileinput text-center"
              style={{ marginLeft: '12px' }}
            >
              <div className="thumbnail">
                <img src={image.imageUrl} alt="..." />
                <div>
                  <h6 style={{ marginTop: '1px' }}>
                    {image.lat}, {image.long}
                  </h6>
                  <h5 style={{ marginTop: '1px' }}>Description: </h5>
                  {image.isAnalysing === 1 ? (
                    <p>Analysing</p>
                  ) : (
                    <p
                      style={{
                        textAlign: 'left',
                        paddingLeft: '4px',
                        paddingRight: '4px',
                        marginBottom: '1px',
                      }}
                    >
                      {image.description.map(line => (
                        <div>{line}</div>
                      ))}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </GridItem>
        ))}
      </GridContainer>
    );
  }

  render() {
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <legend>Trucker Leads</legend>
            {this.getThumbNailView()}
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  activeRole: state.main.activeRole,
  userDetails: state.main.userDetails,
});

export default connect(
  mapStateToProps,
  null
)(withStyles(dashboardStyle)(ImagesList));
