/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-const */
/* eslint-disable camelcase */
/* eslint-disable no-var */
/* eslint-disable guard-for-in */
import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import OutstandingReportsTotalBalance from './Sections/OustandingReportsTotalBalance';
import TotalOutstandingByBranch from './Sections/TotalOutstandingByBranch';
import './Sections/styles/OutstandingReportsStyles.scss';
import firebase from '../../config/config';

const OutstandingReportsView = () => {
  const [transporterList, setTransportersList] = useState([]);
  const db = firebase.firestore();
  const getTransporterList = () => {
    let transporters = [];
    db.collection('Transporters')

      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          // doc.data() is never undefined for query doc snapshots

          transporters.push({
            label: doc.data().name,
            value: doc.data().transporterId,
          });
        });
        transporters.push({ label: 'All Transporters', value: 'all' });
        setTransportersList(transporters);
      })
      .catch(error => error);
  };
  useEffect(() => {
    getTransporterList();
  }, []);
  return (
    <div>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <OutstandingReportsTotalBalance transporterList={transporterList} />
        </Grid>
        <Grid item xs={12}>
          <TotalOutstandingByBranch />
        </Grid>
      </Grid>
    </div>
  );
};

export default OutstandingReportsView;
