/* eslint-disable array-callback-return */
/* eslint-disable no-shadow */
/* eslint-disable no-var */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  Snackbar,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ReactSelect from 'react-select';
import { makeStyles } from '@material-ui/core/styles';
import apiCaller from '../../../utils/apiCallerOutstanding';
import myFirebase from '../../../config/config';
import TableView from './TableView';
import DrillDownTable from './DrillDownTable';

var offset = 0;
const style = {
  control: base => ({
    ...base,
    border: '1px solid lightgray',
    borderRadius: 5,
    // This line disable the blue border
    boxShadow: 'none',
  }),
};
const useStyles = makeStyles({
  toggleButton: {
    height: '32px',
    width: '90px',
    borderColor: '#163bac',
    background: '#163bac',
    color: 'white',
  },
  toggleButtonActive: {
    height: '32px',
    width: '90px',
    background: 'white',
  },
  searchButton: {
    background: '#3366FF',
    color: 'white',
    width: '110px',
    height: '36px',
    textTransform: 'capitalize',
  },
});
const PODAgeingListView = ({
  transporters,
  fetching,
  branches,
  truckers,
  activeRole,
}) => {
  const [podType, setPodType] = useState('');
  const [drillDownQuery, setDrillDownQuery] = useState([]);
  const [active, setActive] = useState('days');
  const [open, setOpen] = useState(false);
  const [transporterSelected, setTransporterSelected] = useState([]);
  const [truckerSelected, setTruckerSelected] = useState([]);
  const [branchSelected, setBranchSelected] = useState([]);
  const [drillDownData, setDrillDown] = useState([]);
  const [podAgeingCollectedData, setPODAgeingCollectedData] = useState([]);
  const [podAgeingSubmittedData, setPODAgeingSubmittedData] = useState([]);
  const [fetchingData, setFetchingData] = useState(false);
  const [toggleDrillDown, setToggleDrillDown] = useState(false);
  const [disabledNext, setDisableNext] = useState(false);
  const [podDrillDownQuery, setPodDrllDownQuery] = useState({
    branch: '',
    range: '',
    type: '',
    filterBy: '',
    filterByValue: '',
  });

  const tableHeaders =
    active === 'days'
      ? ['Branch', '0-7 Days', '8-14 Days', '15-21 Days', '> 21 Days']
      : ['Branch', '0-3 Months', '3-6 Months', '6-9 Months', '> 9 Months'];

  const drillDownHeaders =
    podType === 'Pod_Collected'
      ? [
          'Txn',
          'Loading Date',
          'From',
          'To',
          'Trucker',
          'Phone Number',
          'Truck #',
          'Delivery Date',
          'Ageing',
        ]
      : [
          'Txn',
          'Loading Date',
          'From',
          'To',
          'Transporter',
          'Phone Number',
          'Truck #',
          'Delivery Date',
          'Ageing',
        ];
  const [filterParams, setFilterParams] = useState({
    type: '',
    value: '',
    id: '',
  });
  const classes = useStyles();

  const handleFilterQuery = (type, value) => {
    switch (type) {
      case 'transporters':
        // code block
        setTransporterSelected(value);
        setTruckerSelected('');
        setBranchSelected('');
        setFilterParams({ type, value: value?.label, id: value?.value });
        getPODAgeingData(active, type, value?.value);
        break;
      case 'truckers':
        // code block
        setTruckerSelected(value);
        setBranchSelected('');
        setTransporterSelected('');
        setFilterParams({ type, value: value?.label, id: value?.value });
        getPODAgeingData(active, type, value?.value);
        break;
      case 'branch':
        // code block
        setBranchSelected(value);
        setTruckerSelected('');
        setTransporterSelected('');
        setFilterParams({ type, value: value?.label, id: value?.value });
        getPODAgeingData(active, type, value?.value);
        break;
      // case 'branch':
      default:
      // code block
    }
  };

  const handleDataPODAgeing = data => {
    const response = new Map();

    data.map(x => {
      response[`${x.branch}`] = [];
    });

    data.map(x => {
      response[`${x.branch}`] = [...response[`${x.branch}`], x];
    });

    const values = Object.values(response);

    const ans = values.reduce((acc, v) => {
      const temp = v.reduce((obj, x) => ({ ...obj, ...x }), {});
      return [...acc, temp];
    }, []);

    return ans;
  };
  const getPODAgeingData = async (currentActive, type, id) => {
    setFetchingData(true);
    const userDetails = myFirebase.auth().currentUser;
    const { Aa } = userDetails;
    // eslint-disable-next-line prefer-const
    let token = Aa;
    const endPoint = 'pipe/get-pod-ageing';

    const body = id
      ? {
          type: currentActive || active,
          range: '',
          filter_by: type ? `${type}` : '',
          value: id,
        }
      : {
          type: currentActive || active,
          range: '',
        };
    await apiCaller(endPoint, body, `Bearer ${token}`, 'nh-base', 'post')
      .then(response => {
        const dataRecieved = response?.data?.body;
        const mappedDataRecievded = handleDataPODAgeing(
          dataRecieved?.pod_collected_ageing
        );
        const mappedDataSubmmitted = handleDataPODAgeing(
          dataRecieved?.pod_submitted_ageing
        );
        setPODAgeingCollectedData(mappedDataRecievded);
        setPODAgeingSubmittedData(mappedDataSubmmitted);
        setFetchingData(false);
      })
      .catch(error => {
        setOpen(true);
        setFetchingData(false);
      });
  };
  const handleActive = value => {
    setBranchSelected('');
    setTruckerSelected('');
    setTransporterSelected('');
    setActive(value);
    getPODAgeingData(value);
  };

  const getPodDrillDownData = async (
    range,
    branch,
    type,
    offset,
    filterBy,
    filterByValue
  ) => {
    setFetchingData(true);
    const userDetails = myFirebase.auth().currentUser;
    const { Aa } = userDetails;
    // eslint-disable-next-line prefer-const
    let token = Aa;
    const body = {
      type: active,
      range,
      branch,
      pod_ageing_type: type,
      is_download: false,
      limit: 10,
      offset: offset || 0,
      filter_by: filterBy ? `${filterBy}` : '',
      value: filterByValue,
    };
    setDrillDownQuery(body);

    const endPoint = 'pipe/get-pod-ageing';

    await apiCaller(endPoint, body, `Bearer ${token}`, 'nh-base', 'post')
      .then(response => {
        const data = response.data.body;
        if (data.length < 10) {
          setDisableNext(true);
        } else {
          setDisableNext(false);
        }

        setDrillDown(response.data.body);
        setFetchingData(false);
      })
      .catch(error => {
        setDisableNext(true);
        setOpen(true);
        setFetchingData(false);
      });
  };

  const handleDrillDown = (rowSelected, selectedRange, type) => {
    setPodType(type);
    let range = '';
    if (active === 'days') {
      range =
        selectedRange === 'firstWeek'
          ? '0-7'
          : selectedRange === 'secondWeek'
          ? '7-14'
          : selectedRange === 'thirdWeek'
          ? '14-21'
          : selectedRange === 'moreThanFour'
          ? '21- '
          : '';
    } else {
      range =
        selectedRange === '0-3'
          ? '0-3'
          : selectedRange === '3-6'
          ? '3-6'
          : selectedRange === '6-9'
          ? '6-9'
          : selectedRange === 'morethan9'
          ? '9- '
          : '';
    }

    setPodDrllDownQuery({
      branch: rowSelected.name,
      range,
      type,
      filterBy: filterParams.type,
      filterByValue: filterParams.id,
    });
    setToggleDrillDown(true);
    getPodDrillDownData(
      range,
      rowSelected.name,
      type,
      offset,
      filterParams.type,
      filterParams.id
    );
  };

  const handlePagination = offset => {
    const { range, branch, type, filterBy, filterByValue } = podDrillDownQuery;

    getPodDrillDownData(range, branch, type, offset, filterBy, filterByValue);
  };
  const handleNext = () => {
    offset += 10;
    handlePagination(offset);
  };

  const handlePrevious = () => {
    if (offset !== 0) {
      offset -= 10;
      handlePagination(offset);
    }
    handlePagination(offset);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  useEffect(() => {
    getPODAgeingData();
  }, []);
  return (
    <Grid container spacing={5}>
      {toggleDrillDown ? (
        <Grid item xs={12}>
          <DrillDownTable
            drillDownData={drillDownData}
            fetchingData={fetchingData}
            drillDownHeaders={drillDownHeaders}
            toggleDrillDown={() => {
              offset = 0;
              setToggleDrillDown(false);
            }}
            handleNext={handleNext}
            offset={offset}
            handlePrevious={handlePrevious}
            disabledNext={disabledNext}
            type={podType}
            podDrillDownQuery={drillDownQuery}
            activeRole={activeRole}
          />
        </Grid>
      ) : (
        <>
          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs={4}>
                <ButtonGroup
                  //   size="large"

                  aria-label="small outlined button group"
                >
                  <Button
                    variant={active === 'days' ? '' : 'contained'}
                    className={
                      active === 'days'
                        ? classes.toggleButton
                        : classes.toggleButtonActive
                    }
                    onClick={() => handleActive('days')}
                  >
                    Days
                  </Button>
                  <Button
                    // variant={active === 'months' ? '' : 'contained'}
                    className={
                      active === 'months'
                        ? classes.toggleButton
                        : classes.toggleButtonActive
                    }
                    onClick={() => handleActive('months')}
                  >
                    Months
                  </Button>
                </ButtonGroup>
              </Grid>
              <Grid item xs={8}>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item xs={3}>
                    <ReactSelect
                      value={transporterSelected}
                      onChange={event =>
                        handleFilterQuery('transporters', event)
                      }
                      options={transporters}
                      placeholder="Select Transporter"
                      isClearable
                      styles={style}
                      isLoading={fetching === 'transporters'}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <ReactSelect
                      value={truckerSelected}
                      onChange={event => handleFilterQuery('truckers', event)}
                      options={truckers}
                      placeholder="Select Trucker"
                      isClearable
                      styles={style}
                      isLoading={fetching === 'truckers'}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <ReactSelect
                      value={branchSelected}
                      onChange={event => handleFilterQuery('branch', event)}
                      options={branches}
                      placeholder="Select Branch"
                      isClearable
                      styles={style}
                      isLoading={fetching === 'branches'}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h5"
              gutterBottom
              style={{ fontWeight: 'bold' }}
            >
              POD Not Collected
            </Typography>
            <TableView
              active={active}
              data={podAgeingCollectedData}
              fetchingData={fetchingData}
              tableHeaders={tableHeaders}
              toggleDrillDown={(value, key) =>
                handleDrillDown(value, key, 'Pod_Collected')
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              gutterBottom
              style={{ fontWeight: 'bold' }}
            >
              POD Not Submitted
            </Typography>
            <TableView
              active={active}
              data={podAgeingSubmittedData}
              fetchingData={fetchingData}
              tableHeaders={tableHeaders}
              toggleDrillDown={(value, key) =>
                handleDrillDown(value, key, 'Pod_Submitted')
              }
            />
          </Grid>
        </>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="No Data Found"
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </Grid>
  );
};

export default PODAgeingListView;
