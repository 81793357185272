/* eslint-disable no-plusplus */
function compareMagicNumber(bytes, magicNumber) {
  for (let i = 0; i < magicNumber.length; i++) {
    if (bytes[i] !== magicNumber[i]) {
      return false;
    }
  }
  return true;
}
async function getFileMimeType(file) {
  const blob = file.slice(0, 4); // You can change the number of bytes to read

  // Convert the Blob to an ArrayBuffer
  const arrayBuffer = await blob.arrayBuffer();

  const bytes = new Uint8Array(arrayBuffer);

  if (compareMagicNumber(bytes, [0x25, 0x50, 0x44, 0x46])) {
    return 'pdf';
  }
  if (compareMagicNumber(bytes, [0xff, 0xd8, 0xff])) {
    return 'jpeg';
  }
  if (compareMagicNumber(bytes, [0x89, 0x50, 0x4e, 0x47])) {
    return 'png';
  }
  return 'Unknown file type'; // Throw an error for unknown types
}

export default getFileMimeType;
