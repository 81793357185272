/* eslint-disable no-await-in-loop */
/* eslint-disable no-shadow */
/* eslint-disable no-loop-func */
/* eslint-disable prefer-const */
/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Paper, Button, Box } from '@material-ui/core';
import Popup from 'reactjs-popup';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CircularProgress from '@material-ui/core/CircularProgress';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CardHeader from 'components/Card/CardHeader.jsx';
import '../../../assets/scss/ReactPOPStyles.scss';
import 'reactjs-popup/dist/index.css';
import myFirebase from 'firebase';
import firebase from '../../../config/config';
import FeatureFlagEmptyState from '../../Components/FeatureFlagEmptyState';
import convertTimestampToDate from '../../../utils/convertTimestampToDate';
import apiCaller from '../../../utils/apiCallerOutstanding';
import {
  deleteFileHandler,
  fileUploader,
  getSignedURL,
  getViewURL,
} from '../../../utils/fileUploadHandler';

const useStyles = makeStyles(theme => ({
  paper: { padding: 10 },
  box: {
    borderRadius: '5px',
    textAlign: 'left',
    // border: '2px dashed',
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    // alignItems: 'flex-start',
    flexDirection: 'column',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    border: '2px dashed lightgray',
    borderRadius: '5px',
    margin: '14px 20px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  prompt: {
    marginTop: 20,
  },
  button: {
    fontSize: '10px',
    margin: 10,
  },
  viewButton: {
    background: '#52BE56',
    color: 'white',
    padding: '5px 42px 5px 10px',
  },
  nameTabs: {
    display: 'flex',
    // flexDirection: 'column',
    textAlign: 'left',
    justifyContent: 'space-between',
    marginBottom: '10px',
    alignItems: 'center',
  },
  promptBox: {
    display: 'flex',
    // flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  alert: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  popover: {
    padding: '10px',
  },
  deleteModalButton: {
    width: '45%',
    background: '#52BE56',
    borderRadius: 0,
    color: 'white',
    margin: '5px',
  },
  cancelButton: {
    width: '45%',
    background: 'lightgray',
    borderRadius: 0,
    margin: '5px',
  },
  progress: {
    borderColor: 'yellow',
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  showMoreButton: {
    fontSize: '12px',
    color: '#645D6A',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
}));
const inlineCheckIconStyle = {
  marginLeft: '10px',
  color: '#52BE56',
};

const contentStyle = { width: '300px' };
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const PODFileUpload = ({
  currentTransactionData,
  activeRole,
  stylesClass,
  featureFlagFiles,
}) => {
  const classes = useStyles();
  const [user, setUser] = React.useState();
  const [podData, setPodData] = React.useState([]);
  const [deleted, setDeleted] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [isUploading, setIsUploading] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState('');
  const [fetching, setFetching] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [viewDropZone, setViewDropZone] = React.useState(false);
  const [showMore, setShowMore] = React.useState(false);
  const [podStatusData, setPodStatusData] = React.useState('');
  const [podFetching, setPodFetching] = React.useState(false);

  const db = firebase.firestore();
  const { transactionId, transactionSerial } = currentTransactionData;

  const uploadHandlerToS3 = async (file, filename) => {
    const body = {
      scope: 'trip',
      type: 'signed_url',
      file_type: 'transaction-pod',
      transaction_id: filename,
    };

    try {
      const response = await getSignedURL(body, file);

      const { url: signedURL } = response?.data;

      const res = await fileUploader(signedURL, file);
      if (res === 'success') {
        const viewURLBody = {
          scope: 'trip',
          type: 'view',
          file_type: 'transaction-pod',
          transaction_id: filename,
        };

        await new Promise(resolve => setTimeout(resolve, 4000));

        const result = await getViewURL(viewURLBody);
        const formattedDateUpdated = convertTimestampToDate(
          result?.data?.updated_at
        );
        const formattedDateApproved = convertTimestampToDate(
          result?.data?.approved_at
        );
        const data = {
          podUpdatedByName: result?.data?.uploaded_by,
          podUpdatedAT: formattedDateUpdated,
          podApprovedAt: formattedDateApproved,
          podApprovedByName: result?.data?.approved_by,
          podFileUploadURL: result?.data?.url,
          fileName: filename,
        };

        // Update state with the data for each iteration
        setPodData(prevState => [...prevState, data]);
        setIsUploading(false);
      }
    } catch (error) {
      setIsUploading(false);
    }
  };
  const uploadHandler = async (file, filename) => {
    let POD_DATA = [];
    const podRef = await db.collection('FileUploads').doc('TransactionFiles');
    const storageRef = myFirebase.storage().ref(`Transaction_POD/${filename}`);
    const task = storageRef.put(file);
    return task.on(
      'state_changed',
      snapshot => {
        const percentage =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        if (percentage === 100) {
          // setOpen(false);
        }
      },
      error => error,
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        task.snapshot.ref.getDownloadURL().then(downloadURL => {
          podRef
            .collection('POD')
            .doc(`${filename}`)
            .set(
              {
                podUpdatedByName: user.displayName,
                podUpdatedByUid: user.uid,
                podUpdatedAT: moment().format('llll'),
                podFileUploadURL: downloadURL,
                transactionSerial,
              }
              // { merge: true }
            );

          POD_DATA.push({
            podUpdatedByName: user.displayName,
            podUpdatedByUid: user.uid,
            podUpdatedAT: moment().format('llll'),
            podFileUploadURL: downloadURL,
          });
          setPodData(podData => [
            ...podData,
            {
              podUpdatedByName: user.displayName,
              podUpdatedByUid: user.uid,
              podUpdatedAT: moment().format('llll'),
              podFileUploadURL: downloadURL,
            },
          ]);

          const auditBody = {
            // data: { ...transaction },
            collection: 'file uploads',
            podFileUploadedBy: user.uid,
            type: 'Uploaded POD File in Transactions',
            message: `${user.email} in file uploads collection inside transactionFiles doc inside POD collection with ${transactionId} transporter ID uploaded the file `,
            uploadedAt: moment().format('llll'),
          };
          auditLog(auditBody);
          // setIsUploading(false);
          setSuccess(true);
          setViewDropZone(false);
          return 'success';
        });
      }
    );
  };
  const handleMultipleFilesUpload = async (file, id) => {
    let currentFileCount = podData.length;
    if (featureFlagFiles === 0) {
      if (file.length > 1) {
        for (let i = 0; i < file.length; i++) {
          setIsUploading(true);
          if (currentFileCount == 0) {
            currentFileCount++;
            uploadHandler(file[i], id);
            // .then(res => getDataPODDATA());
          } else {
            currentFileCount++;
            uploadHandler(file[i], `${id}_${currentFileCount}`);
          }
        }
      } else if (podData.length == 1) {
        setIsUploading(true);

        uploadHandler(file[0], `${id}_1`).then(res => getDataPODDATA());
      } else if (podData.length == 2) {
        setIsUploading(true);

        uploadHandler(file[0], `${id}_2`).then(res => getDataPODDATA());
      } else if (podData.length == 3) {
        setIsUploading(true);

        uploadHandler(file[0], `${id}_3`).then(res => getDataPODDATA());
      } else if (podData.length == 4) {
        setIsUploading(true);

        uploadHandler(file[0], `${id}_4`).then(res => getDataPODDATA());
      } else {
        setIsUploading(true);

        uploadHandler(file[0], id).then(res => getDataPODDATA());
      }
      await getDataPODDATA();
    } else if (featureFlagFiles === 2) {
      const startIndex = podData?.length;
      const endIndex = podData.length + file.length;
      for (let i = startIndex; i < endIndex; i++) {
        setIsUploading(true);
        if (i === 0) {
          uploadHandlerToS3(file[i - startIndex], id);
        } else {
          uploadHandlerToS3(file[i - startIndex], `${id}_${i}`);
        }
        //
      }
    }
  };
  const onDrop = async (acceptedFiles, fileRejections) => {
    // Do something with the files
    const podDataLength = podData.length;
    const fileslength = acceptedFiles.length;
    const fileConditionCheck = podDataLength + fileslength;
    if (fileConditionCheck > 5) {
      setError(true);
    } else {
      setError(false);
      handleMultipleFilesUpload(acceptedFiles, transactionId);
    }
  };
  const { getRootProps, getInputProps } = useDropzone({
    acceptedFiles: {
      'image/png': ['.png', '.jpeg', '.jpg'],
      'application/pdf': ['.pdf'],
    },
    onDrop,

    maxFiles: 5,
    disabled: currentTransactionData.void,
  });

  function handleClose() {
    setDeleted(false);
    setSuccess(false);
  }
  function viewFIleUploaded(podUrl) {
    window.open(podUrl);
  }
  const auditLog = firebase.functions().httpsCallable('utilities-auditLog');

  async function handlePODFileDelete(fileName) {
    setIsDeleting(fileName);
    const body = {
      scope: 'trip',
      type: 'delete',
      file_type: 'transaction-pod',
      transaction_id: fileName,
    };
    try {
      await deleteFileHandler(body);

      const updatedFiles = podData.filter(file => file.fileName !== fileName);
      setPodData(updatedFiles);
      const auditBody = {
        // data: { ...transaction },
        collection: 'Transactions',
        podFileDeletedBy: user.uid,
        type: 'Delete POD File in Transactions',
        message: `${user.email} in Transactions with ${transactionId} transaction ID deleted the file `,
        deletedAt: new Date(),
      };
      auditLog(auditBody);
      setIsDeleting('');
      setDeleted(true);
    } catch (e) {
      setIsDeleting('');
    }
  }

  function handleMultiplePODdelete(pod) {
    setIsDeleting(true);
    const { transactionSerial } = currentTransactionData;
    const podRef = db.collection('FileUploads');

    return podRef
      .doc('TransactionFiles')
      .collection('POD')
      .where('transactionSerial', '==', transactionSerial)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          podRef
            .doc('TransactionFiles')
            .collection('POD')
            .doc(doc.id)
            .delete();
        });
      })
      .then(() => {
        for (let i = 0; i < podData.length; i++) {
          const storageRef = myFirebase
            .storage()
            .ref('Transaction_POD')
            .child(podData[i].docId);
          storageRef.delete();
        }

        return 'success';
      });
  }

  async function deleteFIleUploaded(id) {
    setIsDeleting(true);
    handleMultiplePODdelete().then(res => getDataPODDATA());
  }

  async function handleGetPODDataFromS3() {
    setFetching(true);
    setPodData([]);
    for (let i = 0; i < 20; i++) {
      const viewURLBody = {
        scope: 'trip',
        type: 'view',
        file_type: 'transaction-pod',
        transaction_id: i !== 0 ? `${transactionId}_${i}` : `${transactionId}`,
      };
      try {
        const result = await getViewURL(viewURLBody);
        const data = {
          podUpdatedByName: result?.data?.uploaded_by,
          podUpdatedAT: result?.data?.updated_at,
          podApprovedAt: result?.data?.approved_at,
          podApprovedByName: result?.data?.approved_by,
          podFileUploadURL: result?.data?.url,
          fileName: i !== 0 ? `${transactionId}_${i}` : `${transactionId}`,
        };

        // Update state with the data for each iteration
        setPodData(prevState => [...prevState, data]);

        setFetching(false);
      } catch (e) {
        setFetching(false);
      }
    }
  }

  async function getDataPODDATA() {
    if (featureFlagFiles === 2) {
      handleGetPODDataFromS3();
    } else if (featureFlagFiles === 0) {
      setFetching(true);
      const podRef = await db
        .collection('FileUploads')
        .doc('TransactionFiles')
        .collection('POD');

      podRef
        .where('transactionSerial', '==', transactionSerial)
        .get()
        .then(querySnapshot => {
          let data = [];
          querySnapshot.forEach(doc => {
            const {
              podFileUploadURL,
              podUpdatedAT,
              podUpdatedByName,
              transactionSerial,
              podUpdatedByUid,
            } = doc.data();
            const formattedDate = convertTimestampToDate(podUpdatedAT);
            data.push({
              podFileUploadURL,
              podUpdatedAT: formattedDate,
              podUpdatedByName,
              transactionSerial,
              podUpdatedByUid,
              docId: doc.id,
            });
          });

          setPodData(data);

          setFetching(false);
          setIsUploading(false);
        })
        .catch(error => {
          setPodData([]);
          setFetching(false);
          setIsDeleting(false);
          setIsUploading(false);
        });
    }
  }

  const roleMapping = {
    'branch-ops': 'BranchOps',
    'transaction-view': 'TransactionView',
    'finance-payable': 'FinancePayable',
  };

  const getPodStatus = async token => {
    setPodFetching(true);
    const role = roleMapping[activeRole] || activeRole;
    const currentActiveRole = `is${role.charAt(0).toUpperCase()}${role.slice(
      1
    )}`;
    const endPoint = `pipe/pod-status?role=${currentActiveRole}&trip_id=${transactionId}`;

    await apiCaller(endPoint, {}, `Bearer ${token}`, 'nh-base', 'get')
      .then(response => {
        setPodStatusData(response?.data?.status);
        setPodFetching(false);
      })
      .catch(error => setPodFetching(false));
  };

  const podStatusFilter = {
    traffic_queue: 'Traffic Queue',
    pod_pending: 'Pod Pending',
    inbox_hard_copy: 'Inbox Hard Copy',
    inbox_soft_copy: 'Inbox Soft Copy',
    finance_queue: 'Finance Queue',
  };

  const podStatus = podStatusFilter[podStatusData];

  const statusStyles = {
    traffic_queue: {
      color: '#8D7302',
      backgroundColor: '#FFF9E4',
    },
    pod_pending: {
      color: '#363636',
      backgroundColor: '#F1F2F4',
    },
    inbox_hard_copy: {
      color: '#0C60BC',
      backgroundColor: '#F3F9FF',
    },
    inbox_soft_copy: {
      color: '#0C60BC',
      backgroundColor: '#F3F9FF',
    },
    finance_queue: {
      color: '#112D83',
      backgroundColor: '#EFF2FC',
    },
  };

  const statusStyle = statusStyles[podStatusData];

  useEffect(() => {
    // setPodData([]);
    getDataPODDATA();
    const userDetails = firebase.auth().currentUser;
    setUser(userDetails);
    getPodStatus(userDetails?.Aa);
  }, [featureFlagFiles]);

  return (
    <Paper className={classes.paper}>
      {featureFlagFiles === 1 && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h3 className={stylesClass.classes.cardTitle}>POD File</h3>
        </div>
      )}
      {featureFlagFiles === 0 || featureFlagFiles === 2 ? (
        <>
          {/* Uplaod Feature Disbaled */}
          {activeRole == 'null' ? (
            <>
              <CardHeader
                className={`${stylesClass.classes.cardHeader}`}
                style={{
                  marginBottom: '1px',
                  paddingBottom: '1px',
                  paddingTop: '5px',
                }}
              >
                <div className={classes.headerWrapper}>
                  <div className={classes.flexCenter}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <h3 className={stylesClass.classes.cardTitle}>
                        POD File
                      </h3>
                      {podData.length > 0 ? (
                        <CheckCircleIcon style={inlineCheckIconStyle} />
                      ) : (
                        ''
                      )}
                    </div>
                    {podFetching ? (
                      <CircularProgress size={15} />
                    ) : (
                      <p
                        style={{
                          color: statusStyle?.color,
                          backgroundColor: statusStyle?.backgroundColor,
                          padding: '5px',
                          borderRadius: '4px',
                        }}
                      >
                        {podStatus}
                      </p>
                    )}
                  </div>
                  {podData.length > 0
                    ? podData
                        ?.slice(0, showMore ? podData.length : 3)
                        ?.map(pod => (
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'flex-start',
                              justifyContent: 'space-between',
                              width: '100%',
                            }}
                          >
                            <div>
                              <p style={{ fontWeight: 'bold' }}>
                                {pod?.podUpdatedByName}
                              </p>
                              <p style={{ color: '#a9a9a9', fontSize: '12px' }}>
                                {pod.podUpdatedAT}
                              </p>
                            </div>
                            <div>
                              <Button
                                variant="contained"
                                color="success"
                                size="small"
                                className={classes.viewButton}
                                onClick={() =>
                                  viewFIleUploaded(pod.podFileUploadURL)
                                }
                                startIcon={
                                  <VisibilityIcon
                                    style={{ marginRight: '23px' }}
                                  />
                                }
                                loading={fetching}
                              >
                                View
                              </Button>
                            </div>
                          </div>
                        ))
                    : null}
                  {podData.length === 0 ? (
                    <Box>
                      <Box className={classes.container}>
                        {isUploading ? (
                          <CircularProgress
                            style={{ color: '#FCD21F', margin: '5px' }}
                            size={30}
                            thickness={8}
                          />
                        ) : (
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                marginTop: 5,
                              }}
                            >
                              <CloudUploadIcon />
                              <p style={{ fontSize: '10px', color: 'gray' }}>
                                Drag and Drop or Click here to upload
                              </p>
                              {error ? (
                                <em style={{ color: 'red' }}>
                                  Only Five Files allowed
                                </em>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        )}
                      </Box>
                    </Box>
                  ) : null}
                  {podData.length > 4 ? null : viewDropZone ? (
                    <Box>
                      <Box className={classes.container}>
                        {isUploading ? (
                          <CircularProgress
                            style={{ color: '#FCD21F', margin: '5px' }}
                            size={30}
                            thickness={8}
                          />
                        ) : (
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                marginTop: 5,
                              }}
                            >
                              <CloudUploadIcon />
                              <p style={{ fontSize: '10px', color: 'gray' }}>
                                Drag and Drop or Click here to upload
                              </p>
                              {error ? (
                                <em style={{ color: 'red' }}>
                                  Only Five Files allowed
                                </em>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        )}
                      </Box>
                    </Box>
                  ) : podData.length !== 0 ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => setViewDropZone(true)}
                      >
                        + Add More
                      </Button>
                    </div>
                  ) : null}

                  {podData?.length > 3 && (
                    <div>
                      <Button
                        size="small"
                        disableRipple
                        className={classes.showMoreButton}
                        onClick={() => setShowMore(!showMore)}
                      >
                        {showMore ? 'Show Less' : 'Show More'}
                        {showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </Button>
                    </div>
                  )}
                </div>
              </CardHeader>
            </>
          ) : (
            ''
          )}
          {activeRole == 'ops' ||
          activeRole == 'finance' ||
          activeRole === 'finance-payable' ||
          activeRole == 'sales' ||
          activeRole == 'traffic' ||
          activeRole == 'branch-ops' ||
          activeRole === 'transaction-view' ? (
            <Box>
              {podData.length > 0 ? (
                <CardHeader
                  className={`${stylesClass.classes.cardHeader}`}
                  style={{
                    marginBottom: '1px',
                    paddingBottom: '1px',
                    paddingTop: '5px',
                  }}
                >
                  <div className={classes.headerWrapper}>
                    <div className={classes.nameTabs}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h3
                          className={stylesClass.classes.cardTitle}
                          style={{ textAlign: 'left' }}
                        >
                          POD File
                        </h3>

                        {podData.length > 0 ? (
                          <CheckCircleIcon style={inlineCheckIconStyle} />
                        ) : (
                          ''
                        )}
                      </div>
                      {podFetching ? (
                        <CircularProgress size={15} />
                      ) : (
                        <p
                          style={{
                            color: statusStyle?.color,
                            backgroundColor: statusStyle?.backgroundColor,
                            padding: '5px',
                            borderRadius: '4px',
                          }}
                        >
                          {podStatus}
                        </p>
                      )}
                      {!currentTransactionData.void &&
                      activeRole == 'ops' &&
                      featureFlagFiles === 0 ? (
                        <Popup
                          trigger={
                            isDeleting ? (
                              <CircularProgress
                                style={{ color: '#FCD21F', margin: '5px' }}
                                size={30}
                                thickness={8}
                              />
                            ) : (
                              <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                              >
                                <DeleteOutlineIcon color="error" />
                              </IconButton>
                            )
                          }
                          {...{
                            contentStyle,
                          }}
                          modal
                        >
                          {close => (
                            <div
                              style={{
                                padding: '10px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                              }}
                            >
                              <HighlightOffIcon
                                fontSize="large"
                                color="error"
                              />

                              <h4 style={{ fontWeight: 'bold' }}>
                                Are You Sure?
                              </h4>
                              <p>
                                If you proceed, you will not be able to recover
                                it
                              </p>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  width: '100%',
                                }}
                              >
                                <Button
                                  onClick={close}
                                  className={classes.cancelButton}
                                >
                                  No
                                </Button>

                                <Button
                                  onClick={() => {
                                    close();
                                    deleteFIleUploaded();
                                  }}
                                  className={classes.deleteModalButton}
                                >
                                  Yes
                                </Button>
                              </div>
                            </div>
                          )}
                        </Popup>
                      ) : null}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                      }}
                    >
                      {podData
                        ?.slice(0, showMore ? podData.length : 3)
                        ?.map(pod => (
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              width: '100%',
                            }}
                          >
                            <div>
                              {pod?.podUpdatedByName && (
                                <div style={{ display: 'flex', gap: '5px' }}>
                                  <p>
                                    {moment(pod?.podUpdatedAT).format(
                                      'DD MMM YYYY'
                                    )}
                                  </p>
                                  <span style={{ fontSize: '25px' }}>•</span>
                                  <p>
                                    {moment(pod?.podUpdatedAT).format('hh:mma')}
                                  </p>
                                  <span style={{ fontSize: '25px' }}>•</span>
                                  <p>Uploaded By {pod?.podUpdatedByName}</p>
                                </div>
                              )}
                              {pod?.podApprovedAt && (
                                <div style={{ display: 'flex', gap: '5px' }}>
                                  <p>
                                    {moment(pod?.podApprovedAt).format(
                                      'DD MMM YYYY'
                                    )}
                                  </p>
                                  <span style={{ fontSize: '25px' }}>•</span>
                                  <p>
                                    {moment(pod?.podApprovedAt).format(
                                      'hh:mma'
                                    )}
                                  </p>
                                  <span style={{ fontSize: '25px' }}>•</span>
                                  <p>Approved By {pod?.podApprovedByName}</p>
                                </div>
                              )}
                            </div>

                            <div>
                              <Button
                                variant="contained"
                                color="success"
                                size="small"
                                className={classes.viewButton}
                                onClick={() =>
                                  viewFIleUploaded(pod.podFileUploadURL)
                                }
                                startIcon={
                                  <VisibilityIcon
                                    style={{ marginRight: '23px' }}
                                  />
                                }
                                loading={fetching}
                              >
                                View
                              </Button>
                              {featureFlagFiles === 2 ? (
                                <>
                                  {!currentTransactionData.void &&
                                  activeRole == 'ops' &&
                                  featureFlagFiles === 2 ? (
                                    <Popup
                                      trigger={
                                        isDeleting === pod?.fileName ? (
                                          <CircularProgress
                                            style={{
                                              color: '#FCD21F',
                                              margin: '5px',
                                            }}
                                            size={30}
                                            thickness={8}
                                          />
                                        ) : (
                                          <IconButton
                                            color="primary"
                                            aria-label="upload picture"
                                            component="span"
                                            style={{ marginLeft: '20px' }}
                                          >
                                            <DeleteOutlineIcon color="error" />
                                          </IconButton>
                                        )
                                      }
                                      {...{
                                        contentStyle,
                                      }}
                                      modal
                                    >
                                      {close => (
                                        <div
                                          style={{
                                            padding: '10px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                          }}
                                        >
                                          <HighlightOffIcon
                                            fontSize="large"
                                            color="error"
                                          />

                                          <h4 style={{ fontWeight: 'bold' }}>
                                            Are You Sure?
                                          </h4>
                                          <p>
                                            If you proceed, you will not be able
                                            to recover it
                                          </p>
                                          <div
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              width: '100%',
                                            }}
                                          >
                                            <Button
                                              onClick={close}
                                              className={classes.cancelButton}
                                            >
                                              No
                                            </Button>

                                            <Button
                                              onClick={() => {
                                                close();
                                                handlePODFileDelete(
                                                  pod?.fileName
                                                );
                                              }}
                                              className={
                                                classes.deleteModalButton
                                              }
                                            >
                                              Yes
                                            </Button>
                                          </div>
                                        </div>
                                      )}
                                    </Popup>
                                  ) : null}
                                </>
                              ) : null}
                            </div>
                          </div>
                        ))}
                    </div>
                    {podData?.length > 3 && (
                      <div>
                        <Button
                          size="small"
                          disableRipple
                          className={classes.showMoreButton}
                          onClick={() => setShowMore(!showMore)}
                        >
                          {showMore ? 'Show Less' : 'Show More'}
                          {showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </Button>
                      </div>
                    )}
                  </div>
                </CardHeader>
              ) : podData.length == 0 ? (
                <>
                  <div className={classes.nameTabs}>
                    <div>
                      <h3
                        className={stylesClass.classes.cardTitle}
                        style={{ textAlign: 'left' }}
                      >
                        POD File
                      </h3>
                    </div>
                    {podFetching ? (
                      <CircularProgress size={15} />
                    ) : (
                      <p
                        style={{
                          color: statusStyle?.color,
                          backgroundColor: statusStyle?.backgroundColor,
                          padding: '5px',
                          borderRadius: '4px',
                          margin: 0,
                        }}
                      >
                        {podStatus}
                      </p>
                    )}
                  </div>
                </>
              ) : (
                ''
              )}
            </Box>
          ) : (
            ''
          )}
          <Snackbar
            open={deleted}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="error">
              Deleted File!
            </Alert>
          </Snackbar>
          <Snackbar
            open={success}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="success">
              File Uploaded!
            </Alert>
          </Snackbar>
        </>
      ) : featureFlagFiles === 1 ? (
        <FeatureFlagEmptyState color="black" />
      ) : null}
    </Paper>
  );
};

export default PODFileUpload;
