/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// Core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Table from 'components/Table/Table.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TransactionStyle from 'assets/jss/material-dashboard-pro-react/views/TransactionStyle';
import history from '../../../history';
import {
  switchActivePage,
  setTransactions,
  setApprovedTransactions,
  setModifyTransactions,
  setPendingTransactions,
  setRejectedTransactions,
} from '../../../reducers/transactions';
import firebase from '../../../config/config';

const db = firebase.firestore();
const moment = require('moment');

class PayoutsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payoutsData: [],
      manualPayoutsData: [],
    };
    this.addTransactions = this.addTransactions.bind(this);
    this.getPayoutsTable = this.getPayoutsTable.bind(this);
    this.goToTransactionsPage = this.goToTransactionsPage.bind(this);
    // this.unsubscribe = null;
  }

  getStatusCell(status) {
    let color;
    switch (status) {
      case 'queued':
        color = '#FF8C00';
        break;
      case 'processing':
        color = '#0000FF';
        break;
      case 'processed':
        color = '#008000';
        break;
      case 'reversed':
        color = '#981cad';
        break;
      case 'cancelled':
        color = '#DC143C';
        break;
      case 'initiated':
        color = '#ff8b83';
        break;
      case 'Failure':
        color = '#DC143C';
        break;
      case 'pending_sales_auth':
        color = '#10dcc9';
        break;
      case 'sales_rejected':
        color = '#f5709d';
        break;
      case 'Sales Rejected':
        color = '#f5709d';
        break;
      default:
        color = '#000000';
    }

    return (
      <span style={{ fontSize: '16px', fontWeight: 1000, color }}>
        {status.toUpperCase()}
      </span>
    );
  }

  getBoldCell(amount) {
    return (
      <span
        style={{ fontSize: '16px', fontWeight: 'bold', marginLeft: '10px' }}
      >
        <i
          className="fa fa-inr"
          style={{ fontSize: '12px', paddingRight: '2px' }}
          aria-hidden="true"
        />
        {Intl.NumberFormat('en-IN').format(amount)}
      </span>
    );
  }

  componentDidMount() {
    const { currentTransactionData, singleTransaction } = this.props;
    const payouts = [];
    let payoutsData = [];
    let manualPayoutsData = [];
    let payoutRef = db.collection('Payouts');
    if (singleTransaction) {
      payoutRef = payoutRef.where(
        'transactionId',
        '==',
        currentTransactionData.transactionId
      );
    }
    if (!singleTransaction) {
      const startDate = moment()
        .subtract(2, 'days')
        .format('YYYY-MM-DD');
      payoutRef = payoutRef
        .where('created_at', '>', new Date(startDate))
        .orderBy('created_at', 'desc');
    }
    payoutRef.onSnapshot(payoutSnap => {
      payoutsData = [];
      manualPayoutsData = [];
      payoutSnap.forEach(payoutInfo => {
        payouts.push(payoutInfo.data());
        if (singleTransaction) {
          const { provider } = payoutInfo.data();
          if (provider === 'manual') {
            manualPayoutsData.push({
              color: 'info',
              data: [
                typeof payoutInfo.data().modifiedAt !== 'object'
                  ? moment(payoutInfo.data().modifiedA, 'MMM Do YY').format(
                      'MMM Do Y h:mm A'
                    )
                  : moment(
                      new Date(payoutInfo.data().modifiedAt.toDate())
                    ).format('MMM Do Y h:mm A'),
                this.getBoldCell(payoutInfo.data().amount), //  Convert from Paise to RS
                payoutInfo.data().createdByName || '---',
                payoutInfo.data().mode,
                // payoutInfo.data().truckerName,
                payoutInfo.data().accountNumber || '---',
                this.getStatusCell(payoutInfo.data().status),

                payoutInfo.data().utr || '---',
                payoutInfo.data().vehicleNumber || '---',
                payoutInfo.data().providerPayoutId || '---',
              ],
            });
          } else {
            payoutsData.push({
              color: 'info',
              data: [
                typeof payoutInfo.data().modifiedAt !== 'object'
                  ? moment(payoutInfo.data().modifiedAt, 'MMM Do YY').format(
                      'MMM Do Y h:mm A'
                    )
                  : moment(
                      new Date(payoutInfo.data().modifiedAt.toDate())
                    ).format('MMM Do Y h:mm A'),
                this.getBoldCell(payoutInfo.data().amount), //  Convert from Paise to RS
                payoutInfo.data().createdByName || '---',
                payoutInfo.data().mode,
                // payoutInfo.data().truckerName,
                payoutInfo.data().accountNumber || '---',
                this.getStatusCell(payoutInfo.data().status),

                payoutInfo.data().utr || '---',
                payoutInfo.data().vehicleNumber || '---',
                payoutInfo.data().providerPayoutId || '---',
              ],
            });
          }
        } else {
          const { provider } = payoutInfo.data();
          if (provider === 'manual') {
            manualPayoutsData.push({
              color: 'info',
              data: [
                typeof payoutInfo.data().modifiedAt !== 'object'
                  ? moment(payoutInfo.data().modifiedAt, 'MMM Do YY').format(
                      'MMM Do Y h:mm A'
                    )
                  : moment(
                      new Date(payoutInfo.data().modifiedAt.toDate())
                    ).format('MMM Do Y h:mm A'),
                <Button
                  size="sm"
                  simple
                  style={{
                    color: '#000',
                    fontSize: '14px',
                    padding: '0.0625rem 0.25rem',
                    marginTop: '-2px',
                    fontWeight: 'bold',
                  }}
                  onClick={() => this.goToTransactionsPage()}
                >
                  {payoutInfo.data().transactionSerial}
                </Button>,
                this.getBoldCell(payoutInfo.data().amount), //  Convert from Paise to RS
                payoutInfo.data().createdByName || '---',
                payoutInfo.data().mode,
                // payoutInfo.data().truckerName,
                payoutInfo.data().accountNumber || '---',
                this.getStatusCell(payoutInfo.data().status),

                payoutInfo.data().utr || '---',
                payoutInfo.data().vehicleNumber || '---',
                payoutInfo.data().providerPayoutId || '---',
              ],
            });
          } else {
            payoutsData.push({
              color: 'info',
              data: [
                typeof payoutInfo.data().modifiedAt !== 'object'
                  ? moment(payoutInfo.data().modifiedAt, 'MMM Do YY').format(
                      'MMM Do Y h:mm A'
                    )
                  : moment(
                      new Date(payoutInfo.data().modifiedAt.toDate())
                    ).format('MMM Do Y h:mm A'),
                <Button
                  size="sm"
                  simple
                  style={{
                    color: '#000',
                    fontSize: '14px',
                    padding: '0.0625rem 0.25rem',
                    marginTop: '-2px',
                    fontWeight: 'bold',
                  }}
                  onClick={() => this.goToTransactionsPage()}
                >
                  {payoutInfo.data().transactionSerial}
                </Button>,
                this.getBoldCell(payoutInfo.data().amount), //  Convert from Paise to RS
                payoutInfo.data().createdByName || '---',
                payoutInfo.data().mode,
                // payoutInfo.data().truckerName,
                payoutInfo.data().accountNumber || '---',
                this.getStatusCell(payoutInfo.data().status),

                payoutInfo.data().utr || '---',
                payoutInfo.data().vehicleNumber || '---',
                payoutInfo.data().providerPayoutId || '---',
              ],
            });
          }
        }
      });
      this.setState({
        payoutsData,
        manualPayoutsData,
      });
    });
  }

  goToTransactionsPage() {
    const { history } = this.props;
    history.push('/sales/transactions/view');
  }

  getTable(transaction) {
    transaction = transaction.map(t => t.data);
    const { classes, singleTransaction } = this.props;
    return (
      <div>
        {transaction.length > 0 ? (
          <Table
            tableHead={
              singleTransaction
                ? [
                    'Date',
                    'Amount',
                    'Initiated By',
                    'Type',
                    // 'Trucker',
                    'Account No',
                    'Status',
                    'UTR',
                    'Truck_No',
                    'Payout ID',
                  ]
                : [
                    'Date',
                    '#',
                    'Amount',
                    'Initiated By',
                    'Type',
                    // 'Trucker',
                    'Account No',
                    'Status',
                    'UTR',
                    'Truck_No',
                    'Payout ID',
                  ]
            }
            tableData={transaction}
            customCellClasses={[classes.center]}
            customClassesForCells={[6, 5, 5]}
            customHeadCellClasses={[classes.center]}
            customHeadClassesForCells={[6, 5, 5]}
          />
        ) : (
          <div className={classes.textCenter}>
            <p className={`${classes.rajdhaniFont}`}>
              No Transactions Available
            </p>
          </div>
        )}
      </div>
    );
  }

  componentWillUnmount() {
    // this.unsubscribe();
  }

  getPayoutsTable(type) {
    const { payoutsData, manualPayoutsData } = this.state;
    if (type === 'manual') {
      return this.getTable(manualPayoutsData);
    }
    return this.getTable(payoutsData);
  }

  /**
   * will redirect to Add Transactions Page
   * @function
   */
  addTransactions() {
    history.push('/sales/transactions/add');
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                className={`${classes.cardHeader}`}
                color="info"
                style={{
                  marginBottom: '1px',
                  marginTop: '1px',
                  paddingTop: '0px',
                  paddingBottom: '0px',
                }}
              >
                <h3 className={`${classes.cardHeader} ${classes.rajdhaniFont}`}>
                  Payouts Summary <small />
                </h3>
              </CardHeader>
              <CardBody
                style={{ marginBottom: '1px', marginTop: '1px' }}
                className={`${classes.cardHeader} ${classes.textCenter}`}
              >
                {this.state.payoutsData.length <= 0 ? (
                  <div className={classes.textCenter}>
                    <p className={`${classes.rajdhaniFont}`}>
                      No Payouts Summary
                    </p>
                  </div>
                ) : (
                  this.getPayoutsTable()
                )}
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                className={`${classes.cardHeader}`}
                color="info"
                style={{
                  marginBottom: '1px',
                  marginTop: '1px',
                  paddingTop: '0px',
                  paddingBottom: '0px',
                }}
              >
                <h3 className={`${classes.cardHeader} ${classes.rajdhaniFont}`}>
                  Manual Payouts Summary <small />
                </h3>
              </CardHeader>
              <CardBody
                style={{ marginBottom: '1px', marginTop: '1px' }}
                className={`${classes.cardHeader} ${classes.textCenter}`}
              >
                {this.state.manualPayoutsData.length <= 0 ? (
                  <div className={classes.textCenter}>
                    <p className={`${classes.rajdhaniFont}`}>
                      No Payouts Summary
                    </p>
                  </div>
                ) : (
                  this.getPayoutsTable('manual')
                )}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

PayoutsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  singleTransaction: PropTypes.bool, // Filter payouts for the single transaction or display all?
};

const mapStateToProps = state => ({
  isDetailedPage: state.transactions.isDetailedPage,
  isModifyPage: state.transactions.isModifyPage,
  transactions: state.transactions.transactions,
  approvedTransactions: state.transactions.approvedTransactions,
  pendingTransactions: state.transactions.pendingTransactions,
  modifyTransactions: state.transactions.modifyTransactions,
  rejectedTransactions: state.transactions.rejectedTransactions,
  activeRole: state.main.activeRole,
  uid: state.main.uid,
  userDetails: state.main.userDetails,
  // currentTransactionData: state.transactions.currentTransactionData,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      switchActivePage,
      setTransactions,
      setApprovedTransactions,
      setModifyTransactions,
      setPendingTransactions,
      setRejectedTransactions,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(TransactionStyle)(PayoutsTable));
