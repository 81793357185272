/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FeatureFlagEmptyState from '../../Components/FeatureFlagEmptyState';

const AdhaarFile = ({ url, type, UploadedBy, featureFlagFiles }) => {
  const viewFile = () => {
    window.open(url);
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      {featureFlagFiles === 1 ? (
        <FeatureFlagEmptyState color="black" />
      ) : (
        <>
          <div>
            <Typography variant="body1">{type}</Typography>
            <Typography variant="caption">
              Uploaded By : {UploadedBy}
            </Typography>
          </div>

          <Button
            variant="contained"
            color="success"
            size="small"
            onClick={viewFile}
            disabled={url === ''}
            startIcon={<VisibilityIcon />}
            style={{ fontSize: '10px' }}
          >
            View
          </Button>
        </>
      )}
    </div>
  );
};

export default AdhaarFile;
