/* eslint-disable react/no-unused-state */
/* eslint-disable eqeqeq */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import CardIcon from 'components/Card/CardIcon.jsx';
import Assignment from '@material-ui/icons/Assignment';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Button from 'components/CustomButtons/Button.jsx';
// import Table from 'components/Table/Table.jsx';
// import CircularProgress from '@material-ui/core/CircularProgress';
// import purple from '@material-ui/core/colors/purple';
import CustomTabs from 'components/CustomTabs/CustomTabs.jsx';
import Edit from '@material-ui/icons/Edit';
import TransactionStyle from 'assets/jss/material-dashboard-pro-react/views/TransactionStyle';
import AppInstalledIcon from '../../assets/icons/AppDownload 2.svg';
import firebase from '../../config/config';
import MaskedtransporterListView from './Masking/MaskedtransporterListView';

const db = firebase.firestore();
const NameTableRowStyle = { display: 'flex', alignItems: 'center' };
class Transporters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transportersData: [],
      pendingTransporters: [],
      rejectedTransporters: [],
      approvedTransporters: [],
      isLoading: false,
      totalData: 0,
      currentPage: 1,
      maxItemsPerPage: 20,
    };
    this.getTable = this.getTable.bind(this);
    this.getTransportersData = this.getTransportersData.bind(this);
    this.AddTransporter = this.AddTransporter.bind(this);
  }

  componentDidMount() {
    const userRef = db.collection('Users');
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        // this.setState({ isLoading: true });
        userRef
          .doc(`${user.uid}`)
          .get()
          .then(resultData => {
            if (resultData.exists) {
              this.setState({
                branches: resultData.data().branches,
              });
              // this.getTransportersData(resultData.data().branches);
            }
          });
      } else {
        this.setState({
          isLoading: false,
        });
      }
    });
  }

  /**
   * Will return the Tabs ATH and BTH
   * @function
   */
  getTabs() {
    return (
      <CustomTabs
        headerColor="#fff"
        tabs={[
          {
            tabName: `Approved`,
            tabContent: this.getTable('approved'),
          },
        ]}
      />
    );
  }

  /**
   * Will Get ALl the Truckers For The User
   * @function
   */
  getTransportersData(branches) {
    const { transportersData } = this.state;
    const { classes } = this.props;
    const colors = ['info', 'success', 'danger'];
    const maskedNumber = number => `******${String(number).slice(-4)}`;
    const transporterRef = db;
    const transporters = transportersData;

    this.setState({ isLoading: false });
    transporterRef.onSnapshot(transporterData => {
      const pendingTransporters = [];
      const approvedTransporters = [];
      const rejectedTransporters = [];
      this.setState({ isLoading: false });
      let flag = 0;
      transporterData.forEach(transporter => {
        if (transporter.data().status === 'pending') {
          pendingTransporters.push({
            color: colors[0],
            data: [
              transporter.data().name,
              transporter.data().phoneNumber,
              transporter.data().email,
              transporter.data().contactPerson,
            ],
          });
        }
        if (transporter.data().status === 'approved') {
          approvedTransporters.push({
            color: colors[1],
            data: [
              <div style={NameTableRowStyle}>
                {transporter.data().blacklist ? (
                  <del>{transporter.data().name}</del>
                ) : (
                  <p style={{ margin: 0 }}> {transporter.data().name}</p>
                )}

                {transporter.data().isAppLoggedIn ? (
                  <img
                    src={AppInstalledIcon}
                    alt="icon"
                    style={{ width: '14px', margin: '10px' }}
                  />
                ) : (
                  ''
                )}
              </div>,
              transporter.data().blacklist ? (
                <del>{maskedNumber(transporter.data().phoneNumber)}</del>
              ) : (
                maskedNumber(transporter.data().phoneNumber)
              ),
              transporter.data().blacklist ? (
                <del>{transporter.data().email}</del>
              ) : (
                transporter.data().email
              ),
              transporter.data().blacklist ? (
                <del>{transporter.data().contactPerson}</del>
              ) : (
                transporter.data().contactPerson
              ),

              <Button
                color="warning"
                className={classes.actionButton}
                key={transporter.data().transporterId}
                onClick={() => this.openEditForm(transporter.data())}
                justIcon
              >
                <Edit className={classes.icon} />
              </Button>,
            ],
          });
        }
        if (transporter.data().status === 'rejected') {
          rejectedTransporters.push({
            color: colors[2],
            data: [
              transporter.data().name,
              transporter.data().phoneNumber,
              transporter.data().email,
              transporter.data().contactPerson,
            ],
          });
        }
        const transporterResult = transporter.data();
        transporters.push({
          color: colors[flag],
          data: [
            transporterResult.name,
            transporterResult.phoneNumber,
            transporterResult.email,
            transporterResult.contactPerson,
          ],
        });
        if (flag === 0) {
          flag = 1;
        } else {
          flag = 0;
        }

        this.setState({
          transportersData: transporters,
          approvedTransporters,
          pendingTransporters,
          rejectedTransporters,
          totalData: transporters.length,
        });
      });
    });
  }

  /**
   * Will return the Table Component
   * @function
   */
  getTable(status) {
    const { classes } = this.props;
    // const activeRole = localStorage.getItem('activeRole');
    const {
      pendingTransporters,
      rejectedTransporters,
      approvedTransporters,
    } = this.state;

    let transportersData = [];
    if (status === 'pending') {
      transportersData = pendingTransporters;
    } else if (status === 'rejected') {
      transportersData = rejectedTransporters;
    } else if (status === 'approved') {
      transportersData = approvedTransporters;
    }
    return (
      <Card>
        <CardHeader color="warning" icon>
          <CardIcon color="warning">
            <Assignment />
          </CardIcon>
        </CardHeader>

        <CardBody className={classes.customCardContentClass}>
          <MaskedtransporterListView transportersData={transportersData} />
        </CardBody>
      </Card>
    );
  }

  changePage(direction) {
    if (direction !== 'next') {
      this.setState({
        currentPage: this.state.currentPage - 1,
      });
    } else if (direction == 'next') {
      this.setState({
        currentPage: this.state.currentPage + 1,
      });
    }
  }

  /**
   * will redirect to Add Truckers Page
   * @function
   */
  AddTransporter() {
    const { history } = this.props;
    history.push('/sales/transporters/add');
  }

  openEditForm(transporter) {
    const { history } = this.props;
    const { activeRole } = this.props;
    history.push({
      pathname: `/${activeRole}/transporters/view/${transporter.transporterId}`,
      state: transporter.transporterId,
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader className={`${classes.cardHeader}`} color="warning">
                <p className={`${classes.cardHeader}`}>Transporters</p>
              </CardHeader>
              <CardBody
                className={`${classes.cardHeader} ${classes.textCenter}`}
              >
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  {this.getTabs()}
                </GridItem>
              </CardBody>
              <CardFooter stats>
                <div className={classes.stats} />
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Transporters.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(TransactionStyle)(Transporters);
