/* eslint-disable radix */
/* eslint-disable guard-for-in */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import { Checkbox, Grid } from '@material-ui/core';

export default function MoreTruckerDeductions({
  state,
  currentTransactionData,
  setState,
  handleMoreDeductionsTrucker,
}) {
  const {
    tdsTruckerAmount,
    tdsTruckerShowinloadingslip,
    docChargesTruckerAmount,
    docChargesTruckerShowinloadingslip,
    fuelChargesTruckerAmount,
    fuelChargesTruckerShowinloadingslip,
    maamolChargesTruckerAmount,
    maamolChargesTruckerShowinloadingslip,
    shortageChargesTruckerAmount,
    shortageChargesTruckerShowinloadingslip,
    lateDeliveryTruckerAmount,
    lateDeliveryTruckerShowinloadingslip,
    latePodTruckerAmount,
    latePodTruckerShowinloadingslip,
    earlySettlementTruckerAmount,
    earlySettlementTruckerShowinloadingslip,
  } = currentTransactionData;

  const {
    tdsTruckerAmountError,
    fuelChargesTruckerAmountError,
    docChargesTruckerAmountError,
    maamolChargesTruckerAmountError,
    shortageChargesTruckerAmountError,
    lateDeliveryTruckerAmountError,
    latePodTruckerAmountError,
    earlySettlementTruckerAmountError,
    showTds,
    hardCodeTransactionNumber,
    latestTransactionNumber,
  } = state;

  const sendValueTotransporterTdsDeducted = () => {
    return latestTransactionNumber < hardCodeTransactionNumber || showTds;
  };

  const [moreTruckerDeduction, setMoreTruckerDeduction] = useState([
    {
      tds: {
        amount: tdsTruckerAmount || '',
        checked: tdsTruckerShowinloadingslip || false,
      },
    },
    {
      docCharges: {
        amount: docChargesTruckerAmount || '',
        checked: docChargesTruckerShowinloadingslip || false,
      },
    },
    {
      fuelCharges: {
        amount: fuelChargesTruckerAmount || '',
        checked: fuelChargesTruckerShowinloadingslip || false,
      },
    },
    {
      maamolCharges: {
        amount: maamolChargesTruckerAmount || '',
        checked: maamolChargesTruckerShowinloadingslip || false,
      },
    },
    {
      shortageCharges: {
        amount: shortageChargesTruckerAmount || '',
        checked: shortageChargesTruckerShowinloadingslip || false,
      },
    },
    {
      deliveryCharges: {
        amount: lateDeliveryTruckerAmount || '',
        checked: lateDeliveryTruckerShowinloadingslip || false,
      },
    },
    {
      podCharges: {
        amount: latePodTruckerAmount || '',
        checked: latePodTruckerShowinloadingslip || false,
      },
    },
    {
      settlementCharges: {
        amount: earlySettlementTruckerAmount || '',
        checked: earlySettlementTruckerShowinloadingslip || false,
      },
    },
  ]);
  const handleAmountChange = (index, val) => {
    switch (index) {
      case 0:
        handleMoreDeductionsTrucker('tdsTruckerAmount', Number(val));
        break;
      case 1:
        handleMoreDeductionsTrucker('docChargesTruckerAmount', Number(val));
        break;
      case 2:
        handleMoreDeductionsTrucker('fuelChargesTruckerAmount', Number(val));
        break;
      case 3:
        handleMoreDeductionsTrucker('maamolChargesTruckerAmount', Number(val));
        break;
      case 4:
        handleMoreDeductionsTrucker(
          'shortageChargesTruckerAmount',
          Number(val)
        );
        break;
      case 5:
        handleMoreDeductionsTrucker('lateDeliveryTruckerAmount', Number(val));
        break;
      case 6:
        handleMoreDeductionsTrucker('latePodTruckerAmount', Number(val));
        break;
      case 7:
        handleMoreDeductionsTrucker(
          'earlySettlementTruckerAmount',
          Number(val)
        );
        break;

      default:
        // Default case when index does not match any specific cases
        // Handle the default behavior here
        break;
    }
    setMoreTruckerDeduction(prevArray => {
      const newArray = [...prevArray];

      const obj = newArray[index];

      for (const key in obj) {
        obj[key].amount = parseInt(val);
      }
      newArray[index] = obj; // Update the modified object in the newArray
      return newArray;
    });
  };

  const handleCheckboxChange = (index, checked) => {
    switch (index) {
      case 0:
        handleMoreDeductionsTrucker('tdsTruckerShowinloadingslip', checked);
        break;
      case 1:
        handleMoreDeductionsTrucker(
          'docChargesTruckerShowinloadingslip',
          checked
        );
        break;
      case 2:
        handleMoreDeductionsTrucker(
          'fuelChargesTruckerShowinloadingslip',
          checked
        );
        break;
      case 3:
        handleMoreDeductionsTrucker(
          'maamolChargesTruckerShowinloadingslip',
          checked
        );
        break;
      case 4:
        handleMoreDeductionsTrucker(
          'shortageChargesTruckerShowinloadingslip',
          checked
        );
        break;
      case 5:
        handleMoreDeductionsTrucker(
          'lateDeliveryTruckerShowinloadingslip',
          checked
        );
        break;
      case 6:
        handleMoreDeductionsTrucker('latePodTruckerShowinloadingslip', checked);
        break;

      case 7:
        handleMoreDeductionsTrucker(
          'earlySettlementTruckerShowinloadingslip',
          checked
        );
        break;
      default:
        break;
    }

    setMoreTruckerDeduction(prevArray => {
      const newArray = [...prevArray];

      const obj = newArray[index];

      for (const key in obj) {
        obj[key].checked = checked;
      }
      newArray[index] = obj; // Update the modified object in the newArray
      return newArray;
    });
  };
  return (
    <Card>
      <CardHeader color="primary" icon>
        <Grid container>
          <Grid item xs={9}>
            <h3
              style={{
                fontSize: '16px',
                color: 'black',
                fontWeight: '400',
              }}
            >
              More Trucker Deductions
            </h3>
            <p style={{ color: 'red', fontSize: '12px' }}>
              (If checkbox is ticked, these values will be shown in Loading
              Slip)
            </p>
          </Grid>
        </Grid>
      </CardHeader>
      <CardBody>
        {sendValueTotransporterTdsDeducted() && (
          <Grid container alignItems="center">
            <Grid item xs={2}>
              <Checkbox
                onChange={e => handleCheckboxChange(0, e.target.checked)}
                checked={moreTruckerDeduction[0]?.tds?.checked}
                color="primary"
                size="small"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Grid>
            <Grid item xs={10}>
              <CustomInput
                labelText="TDS"
                id="munshiana"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: event => handleAmountChange(0, event.target.value),
                  type: 'number',
                  value: moreTruckerDeduction[0]?.tds?.amount,
                }}
                success={tdsTruckerAmountError === 'success'}
                error={tdsTruckerAmountError === 'error'}
              />
            </Grid>
          </Grid>
        )}
        <Grid container alignItems="center">
          <Grid item xs={2}>
            <Checkbox
              onChange={e => handleCheckboxChange(1, e.target.checked)}
              checked={moreTruckerDeduction[1]?.docCharges?.checked}
              color="primary"
              size="small"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </Grid>
          <Grid item xs={10}>
            <CustomInput
              labelText="Doc Charges"
              id="munshiana"
              formControlProps={{ fullWidth: true }}
              inputProps={{
                onChange: event => handleAmountChange(1, event.target.value),
                type: 'number',
                value: moreTruckerDeduction[1]?.docCharges?.amount,
              }}
              success={docChargesTruckerAmountError === 'success'}
              error={docChargesTruckerAmountError === 'error'}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={2}>
            <Checkbox
              onChange={e => handleCheckboxChange(2, e.target.checked)}
              checked={moreTruckerDeduction[2]?.fuelCharges?.checked}
              color="primary"
              size="small"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </Grid>
          <Grid item xs={10}>
            <CustomInput
              labelText="Fuel Charges"
              id="munshiana"
              formControlProps={{ fullWidth: true }}
              inputProps={{
                onChange: event => handleAmountChange(2, event.target.value),
                type: 'number',
                value: moreTruckerDeduction[2]?.fuelCharges?.amount,
              }}
              success={fuelChargesTruckerAmountError === 'success'}
              error={fuelChargesTruckerAmountError === 'error'}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={2}>
            <Checkbox
              onChange={e => handleCheckboxChange(3, e.target.checked)}
              checked={moreTruckerDeduction[3]?.maamolCharges?.checked}
              color="primary"
              size="small"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </Grid>
          <Grid item xs={10}>
            <CustomInput
              labelText="Maamol Charges"
              id="munshiana"
              formControlProps={{ fullWidth: true }}
              inputProps={{
                onChange: event => handleAmountChange(3, event.target.value),
                type: 'number',
                value: moreTruckerDeduction[3]?.maamolCharges?.amount,
              }}
              success={maamolChargesTruckerAmountError === 'success'}
              error={maamolChargesTruckerAmountError === 'error'}
            />
          </Grid>
        </Grid>

        <Grid container alignItems="center">
          <Grid item xs={2}>
            <Checkbox
              onChange={e => handleCheckboxChange(4, e.target.checked)}
              checked={moreTruckerDeduction[4]?.shortageCharges?.checked}
              color="primary"
              size="small"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </Grid>
          <Grid item xs={10}>
            <CustomInput
              labelText="Shortage / Damage"
              id="munshiana"
              formControlProps={{ fullWidth: true }}
              inputProps={{
                onChange: event => handleAmountChange(4, event.target.value),
                type: 'number',
                value: moreTruckerDeduction[4]?.shortageCharges?.amount,
              }}
              success={shortageChargesTruckerAmountError === 'success'}
              error={shortageChargesTruckerAmountError === 'error'}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={2}>
            <Checkbox
              onChange={e => handleCheckboxChange(5, e.target.checked)}
              checked={moreTruckerDeduction[5]?.deliveryCharges?.checked}
              color="primary"
              size="small"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </Grid>
          <Grid item xs={10}>
            <CustomInput
              labelText="Late Delivery"
              id="munshiana"
              formControlProps={{ fullWidth: true }}
              inputProps={{
                onChange: event => handleAmountChange(5, event.target.value),
                type: 'number',
                value: moreTruckerDeduction[5]?.deliveryCharges?.amount,
              }}
              success={lateDeliveryTruckerAmountError === 'success'}
              error={lateDeliveryTruckerAmountError === 'error'}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={2}>
            <Checkbox
              onChange={e => handleCheckboxChange(6, e.target.checked)}
              checked={moreTruckerDeduction[6]?.podCharges?.checked}
              color="primary"
              size="small"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </Grid>
          <Grid item xs={10}>
            <CustomInput
              labelText="Late POD"
              id="munshiana"
              formControlProps={{ fullWidth: true }}
              inputProps={{
                onChange: event => handleAmountChange(6, event.target.value),
                type: 'number',
                value: moreTruckerDeduction[6]?.podCharges?.amount,
              }}
              success={latePodTruckerAmountError === 'success'}
              error={latePodTruckerAmountError === 'error'}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={2}>
            <Checkbox
              onChange={e => handleCheckboxChange(7, e.target.checked)}
              checked={moreTruckerDeduction[7]?.settlementCharges?.checked}
              color="primary"
              size="small"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </Grid>
          <Grid item xs={10}>
            <CustomInput
              labelText="Early Settlement Charges"
              id="munshiana"
              formControlProps={{ fullWidth: true }}
              inputProps={{
                onChange: event => handleAmountChange(7, event.target.value),
                type: 'number',
                value: moreTruckerDeduction[7]?.settlementCharges?.amount,
              }}
              success={earlySettlementTruckerAmountError === 'success'}
              error={earlySettlementTruckerAmountError === 'error'}
            />
          </Grid>
        </Grid>
      </CardBody>
    </Card>
  );
}
