import Truckers from 'views/Truckers/Truckers.jsx';
import AddTruckers from 'views/Truckers/AddTruckers.jsx';

import Transporters from 'views/Transporters/Transporters.jsx';
import AddTransporters from 'views/Transporters/AddTransporters.jsx';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import OutstandingReportsView from 'views/Outstanding Reports/OutstandingReportsView.jsx';
import Transactions from 'views/Transactions/BranchOpsTransactions.jsx';
import Demands from 'views/Demands/demands.jsx';
import AddTransactions from 'views/Transactions/AddTransactions.jsx';

import TransferWithinAStation from '@material-ui/icons/TransferWithinAStation';
import LocalShipping from '@material-ui/icons/LocalShipping';
import SwapHoriz from '@material-ui/icons/SwapHoriz';

// @material-ui/icons
import DashboardIcon from '@material-ui/icons/Dashboard';
import Timeline from '@material-ui/icons/Timeline';
import PODAgeingView from '../views/PODAgeing/PODAgeingView';
import TransporterOutstandingReports from '../views/Outstanding Reports/TransporterOutstanding/TransporterOutstandingReports';
import TruckerReports from '../views/Outstanding Reports/TruckerReports/TruckerReports';
import DashboardRedirect from '../views/Dashboard/DashboardRedirect';

const branchOpsRoutes = [
  {
    path: '/branch-ops/dashboard',
    name: 'Dashboard',
    icon: DashboardIcon,
    component: DashboardRedirect,
  },
  {
    collapse: true,
    path: '/branch-ops/transporters',
    name: 'Transporters',
    state: 'openTransporters',
    icon: TransferWithinAStation,
    views: [
      {
        path: '/branch-ops/transporters/view',
        name: 'View',
        mini: 'V',
        component: Transporters,
      },
      {
        path: '/branch-ops/transporters/add',
        name: 'Add',
        mini: 'A',
        component: AddTransporters,
      },
    ],
  },
  {
    collapse: true,
    path: '/branch-ops/truckers',
    name: 'Truckers',
    icon: LocalShipping,
    state: 'openTruckers',
    views: [
      {
        path: '/branch-ops/truckers/view',
        name: 'View',
        mini: 'V',
        component: Truckers,
      },
      {
        path: '/branch-ops/truckers/add',
        name: 'Add',
        mini: 'A',
        component: AddTruckers,
      },
    ],
  },
  {
    collapse: true,
    path: '/branch-ops/transactions',
    name: 'Transactions',
    icon: SwapHoriz,
    state: 'openTransactions',
    views: [
      {
        path: '/branch-ops/transactions/view',
        name: 'View',
        mini: 'V',
        component: Transactions,
      },
      {
        path: '/branch-ops/transactions/add',
        name: 'Add',
        mini: 'A',
        component: AddTransactions,
      },
    ],
  },

  {
    collapse: true,
    path: '/branch-ops/outstanding',
    name: 'Reports',
    icon: AccountBalanceWalletIcon,
    state: 'openOutstandings',
    views: [
      {
        path: '/branch-ops/outStandingReports',
        name: 'Outstanding Reports',
        mini: 'O',
        component: OutstandingReportsView,
      },
      {
        path: '/branch-ops/podAgeing',
        name: 'POD Ageing',
        mini: 'P',
        component: PODAgeingView,
      },
      {
        path: '/branch-ops/transporterOutstanding',
        name: 'Transporter Outstanding',
        mini: 'T O',
        component: TransporterOutstandingReports,
      },
    ],
  },
  {
    redirect: true,
    path: '/branch-ops',
    pathTo: '/branch-ops/dashboard',
    name: 'Dashboard',
  },
];
export default branchOpsRoutes;
