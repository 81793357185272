/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import CardIcon from 'components/Card/CardIcon.jsx';
import Assignment from '@material-ui/icons/Assignment';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Table from 'components/Table/Table.jsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';

import AdminEditTrucker from 'views/TransporterPages/Truckers/AdminEditTruckers.jsx';
import TransactionStyle from 'assets/jss/material-dashboard-pro-react/views/TransactionStyle';
import firebase from '../../../config/config';

const db = firebase.firestore();

class Trucker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      truckersData: [],
      approvedTruckers: [],
      isLoading: false,
      view: true,
    };
    this.getTable = this.getTable.bind(this);
    this.getTruckersData = this.getTruckersData.bind(this);
    this.AddTrucker = this.AddTrucker.bind(this);
    this.goToView = this.goToView.bind(this);
  }

  componentDidMount() {
    //  console.log("ACTIVE ROLE",this.props.activeRole)
    const userRef = db.collection('Users');
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.setState({ isLoading: true });
        userRef
          .doc(`${user.uid}`)
          .get()
          .then(resultData => {
            if (resultData.exists) {
              this.getTruckersData(resultData.data().companyId, user.uid);
            }
          });
      } else {
        this.setState({
          isLoading: false,
        });
      }
    });
  }

  /**
   * Will Get ALl the Truckers For The User
   * @function
   */
  getTruckersData(companyId, uid) {
    // const { classes } = this.props;
    // const { truckersData } = this.state;
    // const colors = ['info', 'success', 'danger'];
    // const truckerRef = db
    //   .collection('Truckers')
    //   .where('companyId', '==', companyId)
    //   .where('updatedByRole', '==', 'premiumTransporter')
    //   .orderBy('name', 'asc');
    // const truckers = truckersData;
    // this.setState({ isLoading: false });
    // truckerRef.onSnapshot(truckerData => {
    //   const approvedTruckers = [];
    //   this.setState({ isLoading: false });
    //   let flag = 0;
    //   truckerData.forEach(trucker => {
    //     if (trucker.data().status === 'approved') {
    //       approvedTruckers.push({
    //         color: colors[1],
    //         data: [
    //           trucker.data().name,
    //           trucker.data().phoneNumber,
    //           trucker.data().email,
    //           trucker.data().contactPerson,
    //           <Button
    //             color="warning"
    //             className={classes.actionButton}
    //             key={trucker.data().truckerId}
    //             onClick={() => this.openEditForm(trucker.data())}
    //             justIcon
    //           >
    //             <Edit className={classes.icon} />
    //           </Button>,
    //         ],
    //       });
    //     }
    //     const truckerResult = trucker.data();
    //     truckers.push({
    //       color: colors[flag],
    //       data: [
    //         truckerResult.name,
    //         truckerResult.phoneNumber,
    //         truckerResult.email,
    //         truckerResult.contactPerson,
    //       ],
    //     });
    //     if (flag === 0) {
    //       flag = 1;
    //     } else {
    //       flag = 0;
    //     }
    //     this.setState({ truckersData: truckers, approvedTruckers });
    //   });
    // });
  }

  /**
   * Will return the Table Component
   * @function
   */
  getTable() {
    const { classes } = this.props;
    const { approvedTruckers } = this.state;
    let truckersData = [];
    truckersData = approvedTruckers;
    return (
      <Card>
        <CardHeader color="warning" icon>
          <CardIcon color="warning">
            <Assignment />
          </CardIcon>
        </CardHeader>
        <CardBody className={classes.customCardContentClass}>
          {truckersData.length > 0 ? (
            <Table
              hover
              tableHead={['Name', 'Phone', 'Email', 'Contact Person']}
              tableData={truckersData}
            />
          ) : (
            <p>No Truckers Found</p>
          )}
        </CardBody>
      </Card>
    );
  }

  /**
   * will redirect to Add Truckers Page
   * @function
   */
  AddTrucker() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.history.push('/transporters/truckers/add');
  }

  goToView() {
    this.setState({ view: true });
  }

  openEditForm(trucker) {
    this.setState({ selectedTruckerData: trucker, view: false });
  }

  render() {
    const { classes } = this.props;
    const { view, truckersData, isLoading, selectedTruckerData } = this.state;
    return (
      <div>
        {view ? (
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardHeader className={`${classes.cardHeader}`} color="warning">
                  <p className={`${classes.cardHeader}`}>Truckers</p>
                  <h3 className={classes.cardTitle}>
                    {truckersData.length} <small />
                  </h3>
                </CardHeader>
                <CardBody
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                >
                  {isLoading ? (
                    <CircularProgress
                      className={classes.progress}
                      style={{ color: purple[500] }}
                      thickness={7}
                    />
                  ) : (
                    <React.Fragment>
                      {truckersData.length <= 0 ? (
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                          <p className={classes.textCenter}>
                            No Truckers Available
                          </p>
                          <div className={classes.textCenter}>
                            <Button
                              round
                              color="warning"
                              onClick={this.AddTrucker}
                            >
                              Add Trucker
                            </Button>
                          </div>
                        </GridItem>
                      ) : (
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                          {this.getTable()}
                        </GridItem>
                      )}
                    </React.Fragment>
                  )}
                </CardBody>
                <CardFooter stats>
                  <div className={classes.stats} />
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        ) : (
          <AdminEditTrucker
            goBack={this.goToView}
            selectedTruckerData={selectedTruckerData}
          />
        )}
      </div>
    );
  }
}

Trucker.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  activeRole: state.main.activeRole,
});

export default connect(mapStateToProps)(withStyles(TransactionStyle)(Trucker));
