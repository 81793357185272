import moment from 'moment';

export default function convertTimestampToDate(timestamp) {
  if (typeof timestamp === 'object' && timestamp.seconds) {
    try {
      const date = new Date(timestamp.seconds * 1000); // Convert seconds to milliseconds
      const options = {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true,
      };
      const formattedDate = date.toLocaleString('en-US', options); // Convert date to a string representation
      return formattedDate;
    } catch (error) {
      console.error('Error converting timestamp to date:', error);
    }
  } else if (typeof timestamp === 'string') {
    return moment(timestamp).format('MMMM Do YYYY, h:mm:ss a'); // Return the original string value
  }

  return null; // Invalid format, return null as a default value
}
