/* eslint-disable no-nested-ternary */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import firebase from 'config/config';
import swal from 'sweetalert';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  formControl: {
    // margin: theme.spacing(1),
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const buttonStyles = {
  background: '#3366FF',
  color: 'white',
  fontSize: '12px',
  textTransform: 'capitalize',
};
const editButtonStyles = {
  background: '#AFB2B7',
  color: 'black',
  fontSize: '12px',
  textTransform: 'capitalize',
};
const CategorySelect = ({ activeRole, selectedTruckerData }) => {
  const db = firebase.firestore();
  const classes = useStyles();
  const [category, setCategory] = React.useState(selectedTruckerData?.category);
  const [updating, setUpdating] = React.useState(false);
  const [isToBeEdited, setIstoBeEdited] = React.useState(
    selectedTruckerData?.category
  );

  const handleChange = event => {
    setCategory(event.target.value);
  };

  const handleSubmitCategory = () => {
    setUpdating(true);
    const truckerRef = db
      .collection('Truckers')
      .doc(selectedTruckerData.truckerId);

    // Set the "capital" field of the city 'DC'
    return truckerRef
      .update({
        category,
        modifiedAt: new Date(),
      })
      .then(() => {
        setUpdating(false);
        swal(
          'Success!',
          `You have set ${selectedTruckerData.name} to category ${category}!`,
          'success'
        );
        setIstoBeEdited(true);
      })
      .catch(error => {
        // The document probably doesn't exist.
        setUpdating(false);
        swal('Error!', `Something went wrong`, 'error');
      });
  };

  return (
    <Grid
      container
      spacing={1}
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      {activeRole === 'ops' || activeRole === 'finance' ? (
        <>
          <Grid item xs={8}>
            <FormControl
              variant="outlined"
              size="small"
              className={classes.formControl}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Category
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={category}
                onChange={handleChange}
                label="Category"
                disabled={isToBeEdited}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="Very Large">Very Large</MenuItem>
                <MenuItem value="Large">Large</MenuItem>
                <MenuItem value="Very Small">Very Small</MenuItem>
                <MenuItem value="Small">Small</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {isToBeEdited ? (
            <Grid item xs={4}>
              <Button
                size="small"
                variant="contained"
                style={editButtonStyles}
                onClick={() => {
                  setIstoBeEdited(false);
                }}
              >
                Edit
              </Button>
            </Grid>
          ) : (
            <Grid item xs={4}>
              {updating ? (
                <CircularProgress size={20} />
              ) : (
                <Button
                  size="small"
                  variant="contained"
                  style={buttonStyles}
                  onClick={handleSubmitCategory}
                >
                  Submit
                </Button>
              )}
            </Grid>
          )}
        </>
      ) : null}
      {activeRole === 'branch-ops' ||
      activeRole === 'sales' ||
      activeRole === 'traffic' ? (
        <>
          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              size="small"
              className={classes.formControl}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Category
              </InputLabel>
              <Select
                disabled
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={category}
                onChange={handleChange}
                label="Category"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="Very Large">Very Large</MenuItem>
                <MenuItem value="Large">Large</MenuItem>
                <MenuItem value="Very Small">Very Small</MenuItem>
                <MenuItem value="Small">Small</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </>
      ) : null}
    </Grid>
  );
};

export default CategorySelect;
