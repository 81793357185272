import { primaryColor } from 'assets/jss/material-dashboard-pro-react.jsx';

const accordionStyle = theme => ({
  root: {
    flexGrow: 1,
    marginBottom: '20px',
  },
  expansionPanel: {
    boxShadow: 'none',
    '&:before': {
      display: 'none !important',
    },
  },
  expansionPanelExpanded: {
    margin: '0',
  },
  expansionPanelSummary: {
    minHeight: 'auto !important',
    backgroundColor: 'transparent',
    borderBottom: '1px solid #ddd',
    padding: '25px 0px 5px 0px',
    [theme.breakpoints.down('sm')]: {
      padding: '5px 0px 5px 0px',
    },
    borderTopLeftRadius: '3px',
    borderTopRightRadius: '3px',
    color: '#3C4858',
    '&:hover': {
      color: primaryColor,
    },
  },
  expansionPanelSummaryExpaned: {
    color: primaryColor,
    '& $expansionPanelSummaryExpandIcon': {
      [theme.breakpoints.up('md')]: {
        top: 'auto !important',
      },
      transform: 'rotate(180deg)',
      [theme.breakpoints.down('sm')]: {
        bottom: '10px !important',
        top: '65% !important',
      },
    },
  },
  expansionPanelSummaryContent: {
    margin: '0 !important',
    paddingRight: '0px !important',
  },
  expansionPanelSummaryExpandIcon: {
    [theme.breakpoints.up('md')]: {
      top: 'auto !important',
    },
    transform: 'rotate(0deg)',
    color: 'inherit',
    [theme.breakpoints.down('sm')]: {
      bottom: '10px !important',
      top: '65% !important',
      right: '45%',
    },
  },
  expansionPanelSummaryExpandIconExpanded: {},
  title: {
    fontSize: '15px',
    fontWeight: 'bolder',
    marginTop: '0',
    marginBottom: '0',
    color: 'inherit',
  },
  expansionPanelDetails: {
    padding: '15px 0px 5px',
  },
});

export default accordionStyle;
