import TransporterDashboard from 'views/TransporterPages/TransporterDashboard.jsx';

import Truckers from 'views/TransporterPages/Truckers/Truckers.jsx';
import AddTruckers from 'views/TransporterPages/Truckers/AddTruckers.jsx';

import Transactions from 'views/Transactions/SalesTransactions.jsx';
import AddTransactions from 'views/TransporterPages/AddTransactionsTransporter.jsx';

import LocalShipping from '@material-ui/icons/LocalShipping';
import SwapHoriz from '@material-ui/icons/SwapHoriz';

// @material-ui/icons
import DashboardIcon from '@material-ui/icons/Dashboard';

const transportersDashRoutes = [
  {
    path: '/transporters/dashboard',
    name: 'Dashboard',
    icon: DashboardIcon,
    component: TransporterDashboard,
  },
  {
    collapse: true,
    path: '/transporters/truckers',
    name: 'Truckers',
    icon: LocalShipping,
    state: 'openTruckers',
    views: [
      {
        path: '/transporters/truckers/view',
        name: 'View',
        mini: 'V',
        component: Truckers,
      },
      {
        path: '/transporters/truckers/add',
        name: 'Add',
        mini: 'A',
        component: AddTruckers,
      },
    ],
  },
  {
    collapse: true,
    path: '/transporters/transactions',
    name: 'Transactions',
    icon: SwapHoriz,
    state: 'openTransactions',
    views: [
      {
        path: '/transporters/transactions/view',
        name: 'View',
        mini: 'V',
        component: Transactions,
      },
      {
        path: '/transporters/transactions/add',
        name: 'Add',
        mini: 'A',
        component: AddTransactions,
      },
    ],
  },
  {
    redirect: true,
    path: '/transporters',
    pathTo: '/transporters/dashboard',
    name: 'Dashboard',
  },
];
export default transportersDashRoutes;
