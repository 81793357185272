/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import moment from 'moment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Button, CircularProgress } from '@material-ui/core';
import uploadIcon from 'assets/icons/upload-icon.svg';
import myFirebase from '../../../../config/config';
import {
  fileUploader,
  getSignedURL,
  getViewURL,
} from '../../../../utils/fileUploadHandler';

const db = myFirebase.firestore();
let userDetails;

const uploaderStyles = {
  padding: '10px',
  borderRadius: '5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px dashed lightgray',
};
const fileUploaderStyles = {
  width: '200px',
  marginLeft: '5px',
  padding: '10px',
  borderRadius: '5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px dashed lightgray',
};
const viewButtonStyles = {
  backgroundColor: '#163BAC',
  borderRadius: '10px',
  padding: '5px 30px',
  width: '100%',
  margin: '5px',
};
const FileUploader = ({
  truckNumber,
  truckerId,
  rcUploaded,
  rcFileForExisting,
  featureFlagFiles,
}) => {
  const [uploading, setUploading] = React.useState(false);
  const [url, setUrl] = React.useState('');
  const auditLog = myFirebase.functions().httpsCallable('utilities-auditLog');
  const handleUploadeToS3 = UploadedFile => {
    const trucker_number = truckNumber.trimRight();
    setUploading(true);
    const body = {
      scope: 'truck',
      type: 'signed_url',
      file_type: 'truck-rc',
      trucker_id: truckerId,
      truck_number: trucker_number,
    };
    getSignedURL(body, UploadedFile)
      .then(response => {
        const { url: signedURL } = response?.data;
        fileUploader(signedURL, UploadedFile)
          .then(res => {
            if (res === 'success') {
              const viewURLBody = {
                scope: 'truck',
                type: 'view',
                file_type: 'truck-rc',
                trucker_id: truckerId,
                truck_number: trucker_number,
              };

              setTimeout(() => {
                getViewURL(viewURLBody)
                  .then(result => {
                    setUrl(result?.data?.url);
                    setUploading(false);
                    rcUploaded(true);
                  })
                  .catch(e => setUploading(false));
              }, 4000);
            }
          })
          .catch(e => {
            setUploading(false);
            rcUploaded(true);
          });
      })
      .catch(e => setUploading(false));
  };

  const onDrop = useCallback(
    async acceptedFiles => {
      // Do something with the files
      const UploadedFile = acceptedFiles[0];
      if (featureFlagFiles === 2) {
        handleUploadeToS3(UploadedFile);
      } else if (featureFlagFiles === 0) {
        setUploading(!uploading);
        const podRef = await db.collection('FileUploads');
        // Do something with the files
        const storageRef = myFirebase
          .storage()
          .ref(`Truck_Files/${truckerId}/${truckNumber}/RC`);
        const task = storageRef.put(UploadedFile);
        task.on(
          'state_changed',
          snapshot => {
            const percentage =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            if (percentage === 100) {
              // console.log('SNAPSHOT');
            }
          },
          error => {
            console.log(error);
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            task.snapshot.ref.getDownloadURL().then(downloadURL => {
              setUrl(downloadURL);
              try {
                const metaData = {};
                metaData[truckNumber] = {
                  rcUpdatedByName: userDetails.displayName,
                  rcUpdatedByUid: userDetails.uid,
                  rcUpdatedAT: moment().format('llll'),
                  rcFileUploadURL: downloadURL,
                  VehicleNumber: truckNumber,
                  orgId: truckerId,
                };
                podRef
                  .doc('Truck_Files')
                  .collection('RC')
                  .doc(truckerId)
                  .set(metaData, { merge: true })
                  .catch(error => {
                    console.error('Error adding document: ', error);
                  });
                rcUploaded(true);
              } catch (error) {
                console.log(error);
              }

              const auditBody = {
                // data: { ...transaction },
                collection: 'file uploads',
                podFileUploadedBy: userDetails.uid,
                type: 'Uploaded RC File in Transactions',
                message: `${userDetails.email} in file uploads collection inside truckFiles doc inside RC collection with ${truckerId} trucker ID uploaded the file `,
                uploadedAt: moment().format('llll'),
              };
              auditLog(auditBody);
              setUploading(false);
            });
          }
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    acceptedFiles: {
      'image/png': ['.png', '.jpeg', '.jpg'],
      'application/pdf': ['.pdf'],
    },
    onDrop,
  });

  useEffect(() => {
    userDetails = myFirebase.auth().currentUser;
    setUrl('');
    rcUploaded(false);
  }, []);

  useEffect(() => {
    setUrl('');
  }, [truckerId]);
  return (
    <div>
      {rcFileForExisting ? (
        <div>
          {url !== '' ? (
            <Button
              variant="contained"
              startIcon={<VisibilityIcon style={{ color: 'white' }} />}
              style={viewButtonStyles}
            >
              <a
                href={url}
                target="_blank"
                style={{ color: 'white' }}
                rel="noreferrer"
              >
                View
              </a>
            </Button>
          ) : (
            <div>
              {uploading ? (
                <CircularProgress />
              ) : (
                <div {...getRootProps()} style={fileUploaderStyles}>
                  <input {...getInputProps()} />
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                      justifyContent: 'space-evenly',
                    }}
                  >
                    <img
                      src={uploadIcon}
                      style={{ width: '25px' }}
                      alt="icon"
                    />
                    {isDragActive ? (
                      <p style={{ margin: 0 }}>Drop the files here ...</p>
                    ) : (
                      <p style={{ margin: 0 }}>
                        Upload RC <span style={{ color: 'red' }}>*</span>
                      </p>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div>
          {url !== '' ? (
            <Button
              variant="contained"
              startIcon={<VisibilityIcon style={{ color: 'white' }} />}
              style={viewButtonStyles}
            >
              <a
                href={url}
                target="_blank"
                style={{ color: 'white' }}
                rel="noreferrer"
              >
                View
              </a>
            </Button>
          ) : (
            <div>
              {uploading ? (
                <CircularProgress />
              ) : (
                <div {...getRootProps()} style={uploaderStyles}>
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p>Drop the files here ...</p>
                  ) : (
                    <p>
                      Upload RC Card <span style={{ color: 'red' }}>*</span>
                    </p>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FileUploader;
