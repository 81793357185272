/* eslint-disable no-nested-ternary */
import { Checkbox, Box, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import toIndianCurrency from 'utils/ToIndianCurrencyFormat.js';
import checkboxImage from 'assets/icons/Checkbox.svg';
import checkedImage from 'assets/icons/checkedicon.svg';
import apiCaller from '../../../../utils/apiCallerOutstanding';
import myFirebase from '../../../../config/config';

const TripDeductionsView = ({
  classes,
  transaction,
  edittedDeductions,
  isOldTransaction,
  role,
}) => {
  const [tdsPercentage, setTdsPercentage] = React.useState('');
  const {
    challanTransporterAmount,
    penaltyTransporterAmount,
    hamaliChargesTransporter,
    tdsTransporterAmount,
    docChargesTransporterAmount,
    fuelChargesTransporterAmount,
    maamolChargesTransporterAmount,
    challanTruckerAmount,
    penaltyTruckerAmount,
    hamaliChargesPaidByTransporter,
    unloadingChargesTransporter,
    loadingChargesTransporter,
    loadingChargesPaidByTransporter,
    unloadingChargesPaidByTransporter,
    haltingChargesTransporter,
    haltingChargesPaidByTransporter,
    loadingChargesTrucker,
    unloadingChargesTrucker,
    loadingChargesPaidByTrucker,
    unloadingChargesPaidByTrucker,
    hamaliChargesTrucker,
    hamaliChargesPaidByTrucker,
    haltingChargesTrucker,
    haltingChargesPaidByTrucker,
    docChargesTruckerAmount,
    fuelChargesTruckerAmount,
    maamolChargesTruckerAmount,
    shortageChargesTruckerAmount,
    lateDeliveryTruckerAmount,
    tdsTruckerAmount,
    earlySettlementTruckerAmount,
    latePodTruckerAmount,
    tdsTruckerShowinloadingslip,
    docChargesTruckerShowinloadingslip,
    fuelChargesTruckerShowinloadingslip,
    maamolChargesTruckerShowinloadingslip,
    shortageChargesTruckerShowinloadingslip,
    lateDeliveryTruckerShowinloadingslip,
    earlySettlementTruckerShowinloadingslip,
    latePodTruckerShowinloadingslip,
    truckerId,
  } = transaction;

  const {
    unloadingChargesPaidTransporterEdited,
    loadingChargesPaidTransporterEdited,
    hamaliChargesPaidTransporterEdited,
    haultingChargesPaidTransporterEdited,
    loadingChargesPaidByTruckerEdited,
    unloadingChargesPaidByTruckerEdited,
    hamaliChargesPaidByTruckerEdited,
    haltingChargesPaidByTruckerEdited,
    munshianaTransporterEdited,
    penaltyTransporterEdited,
    tdsTransporterEdited,
    docChargesTransporterEdited,
    fuelChargesTransporterEdited,
    maamolChargesTransporterEdited,
    munshianaTruckerEdited,
    penaltyTruckerEdited,
    maamolChargesTruckerEdited,
    fuelChargesTruckerEdited,
    docChargesTruckerEdited,
    shortageChargesTruckerEditted,
    lateDeliveryTruckerEditted,
    tdsTruckerEditted,
    earlySettlementTruckerEditted,
    latePodTruckerEditted,
    loadingChargesPaidByTransporterCheckedEditted,
    unloadingChargesPaidByTransporterCheckedEditted,
    hamaliChargesPaidByTransporterCheckedEditted,
    haltingChargesPaidByTransporterCheckedEditted,
    loadingChargesPaidByTruckerCheckedEditted,
    unloadingChargesPaidByTruckerCheckedEditted,
    hamaliChargesPaidByTruckerCheckedEditted,
    haltingChargesPaidByTruckerCheckedEditted,
    earlySettlementTruckerShowinloadingslipEditted,
    tdsTruckerShowinloadingslipEditted,
    lateDeliveryTruckerShowinloadingslipEditted,
    shortageChargesTruckerShowinloadingslipEditted,
    maamolChargesTruckerShowinloadingslipEditted,
    fuelChargesTruckerShowinloadingslipEditted,
    docChargesTruckerShowinloadingslipEditted,
    latePodTruckerShowinloadingslipEditted,
    showTds,
    hardCodeTransactionNumber,
    latestTransactionNumber,
  } = edittedDeductions;

  const blueRow = {
    background: '#D9EDF7',
    display: 'flex',
    alignItems: 'center',
    padding: '5px',
    borderRadius: '5px',
    justifyContent: 'space-between',
    marginBottom: '10px',
  };

  const blueRowWithoutCheckbox = {
    background: '#D9EDF7',
    display: 'flex',
    alignItems: 'center',
    padding: '5px 20px',
    borderRadius: '5px',
    justifyContent: 'space-between',
    marginBottom: '10px',
  };

  const greenRow = {
    background: '#DFF0D8',
    display: 'flex',
    alignItems: 'center',
    padding: '5px',
    borderRadius: '5px',
    justifyContent: 'space-between',
    marginBottom: '10px',
  };
  const greenRowWithoutCheckbox = {
    background: '#DFF0D8',
    display: 'flex',
    alignItems: 'center',
    padding: '5px 20px',
    borderRadius: '5px',
    justifyContent: 'space-between',
    marginBottom: '10px',
  };

  const purpleRow = {
    background: '#F6E4FF',
    display: 'flex',
    alignItems: 'center',
    padding: '5px',
    borderRadius: '5px',
    justifyContent: 'space-between',
    marginBottom: '10px',
  };

  const purpleRowWithCheckbox = {
    background: '#F6E4FF',
    display: 'flex',
    alignItems: 'center',
    padding: '5px 20px',
    borderRadius: '5px',
    justifyContent: 'space-between',
    marginBottom: '10px',
  };
  const brownRow = {
    background: '#E1D8AB',
    display: 'flex',
    alignItems: 'center',
    padding: '5px',
    borderRadius: '5px',
    justifyContent: 'space-between',
    marginBottom: '10px',
  };

  const brownRowWithoutCheckbox = {
    background: '#E1D8AB',
    display: 'flex',
    alignItems: 'center',
    padding: '5px 20px',
    borderRadius: '5px',
    justifyContent: 'space-between',
    marginBottom: '10px',
  };
  const lavenderRow = {
    background: '#D7E0FF',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    padding: '5px',
    borderRadius: '5px',
    justifyContent: 'flex-start',
    marginBottom: '10px',
  };

  const limeRow = {
    background: '#FFF3BE',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    padding: '5px',
    borderRadius: '5px',
    justifyContent: 'flex-start',
    marginBottom: '10px',
  };

  const orangeRow = {
    background: '#FFD8B3',
    display: 'flex',
    alignItems: 'center',
    padding: '5px',
    borderRadius: '5px',
    justifyContent: 'space-between',
    marginBottom: '10px',
  };

  const orangeRowWithoutcheckbox = {
    background: '#FFD8B3',
    display: 'flex',
    alignItems: 'center',
    padding: '5px 20px',
    borderRadius: '5px',
    justifyContent: 'space-between',
    marginBottom: '10px',
  };

  const greyRow = {
    background: '#D3D3D3',
    display: 'flex',
    alignItems: 'center',
    padding: '5px',
    borderRadius: '5px',
    justifyContent: 'space-between',
    marginBottom: '10px',
  };

  const greyRowWithoutCheckbox = {
    background: '#D3D3D3',
    display: 'flex',
    alignItems: 'center',
    padding: '5px 20px',
    borderRadius: '5px',
    justifyContent: 'space-between',
    marginBottom: '10px',
  };

  const rowInlineStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    paddingLeft: '10px',
    paddingRight: '10px',
  };
  const displayFlex = { display: 'flex', alignItems: 'center' };

  const roleMapping = {
    'branch-ops': 'BranchOps',
    'transaction-view': 'TransactionView',
    'finance-payable': 'FinancePayable',
    'finance-recievable': 'FinanceReceivable',
  };

  async function fetchTdsPercentage() {
    const currentRole = roleMapping[role] || role;
    const currentActiveRole = `is${currentRole
      .charAt(0)
      .toUpperCase()}${currentRole.slice(1)}`;
    const userDetails = myFirebase.auth().currentUser;
    const { Aa: userToken } = userDetails;
    const endPoint = `pipe/trucker-tds-percentage?role=${currentActiveRole}&trucker_id=${truckerId}`;
    try {
      const response = await apiCaller(
        endPoint,
        {},
        `Bearer ${userToken}`,
        'nh-base',
        'get'
      );
      setTdsPercentage(response?.data?.tds_percentage);
    } catch (e) {}
  }

  useEffect(() => {
    fetchTdsPercentage();
  });

  return (
    <Box>
      <form>
        <GridContainer className={classes.customMargin}>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer className={classes.customMargin}>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                className={classes.noPadding}
                style={{
                  float: 'left',
                  textAlign: 'left',
                  paddingLeft: '0px !important',
                }}
              >
                <h5 className={classes.customCardIconTitle}>
                  Other Expenses
                  <span style={{ fontSize: '12px', fontStyle: 'italic' }}>
                    (transporter)
                  </span>
                </h5>
              </GridItem>
              <GridItem className={classes.noPadding} xs={12} sm={12} md={12}>
                <GridContainer spacing={2}>
                  <GridItem xs={6} md={4}>
                    <div style={limeRow}>
                      <div style={rowInlineStyle}>
                        <Typography variant="body1">Loading Charges</Typography>
                        <Typography
                          variant="body1"
                          style={{
                            color:
                              isOldTransaction &&
                              loadingChargesPaidTransporterEdited
                                ? 'red'
                                : !isOldTransaction &&
                                  loadingChargesPaidTransporterEdited
                                ? 'green'
                                : 'black',
                            fontWeight: loadingChargesPaidTransporterEdited
                              ? 'bold'
                              : '',
                          }}
                        >
                          {toIndianCurrency(loadingChargesTransporter)}
                        </Typography>
                      </div>
                      <div style={displayFlex}>
                        {loadingChargesPaidByTransporter ? (
                          <img
                            src={checkedImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',

                              width: '16px',
                            }}
                          />
                        ) : (
                          <img
                            src={checkboxImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',

                              width: '16px',
                            }}
                          />
                        )}

                        <p
                          style={{
                            fontSize: '12px',
                            marginLeft: '10px',
                            marginBottom: '0px',
                            color:
                              isOldTransaction &&
                              loadingChargesPaidByTransporterCheckedEditted
                                ? 'red'
                                : !isOldTransaction &&
                                  loadingChargesPaidByTransporterCheckedEditted
                                ? 'green'
                                : 'black',
                            fontWeight: loadingChargesPaidByTransporterCheckedEditted
                              ? 'bold'
                              : '',
                          }}
                        >
                          Paid By Transporter
                        </p>
                      </div>
                    </div>
                  </GridItem>
                  <GridItem xs={6} md={4}>
                    <div style={limeRow}>
                      <div style={rowInlineStyle}>
                        <Typography variant="body1">Hamali Charges</Typography>
                        <Typography
                          variant="body1"
                          style={{
                            color:
                              isOldTransaction &&
                              hamaliChargesPaidTransporterEdited
                                ? 'red'
                                : !isOldTransaction &&
                                  hamaliChargesPaidTransporterEdited
                                ? 'green'
                                : 'black',
                            fontWeight: hamaliChargesPaidTransporterEdited
                              ? 'bold'
                              : '',
                          }}
                        >
                          {toIndianCurrency(hamaliChargesTransporter)}
                        </Typography>
                      </div>
                      <div style={displayFlex}>
                        {hamaliChargesPaidByTransporter ? (
                          <img
                            src={checkedImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',

                              width: '16px',
                            }}
                          />
                        ) : (
                          <img
                            src={checkboxImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',

                              width: '16px',
                            }}
                          />
                        )}

                        <p
                          style={{
                            fontSize: '12px',
                            marginLeft: '10px',
                            marginBottom: '0px',
                            color:
                              isOldTransaction &&
                              hamaliChargesPaidByTransporterCheckedEditted
                                ? 'red'
                                : !isOldTransaction &&
                                  hamaliChargesPaidByTransporterCheckedEditted
                                ? 'green'
                                : 'black',
                            fontWeight: hamaliChargesPaidByTransporterCheckedEditted
                              ? 'bold'
                              : '',
                          }}
                        >
                          Paid By Transporter
                        </p>
                      </div>
                    </div>
                  </GridItem>
                  <GridItem xs={6} md={4}>
                    <div style={limeRow}>
                      <div style={rowInlineStyle}>
                        <Typography variant="body1">Halting Charges</Typography>
                        <Typography
                          variant="body1"
                          style={{
                            color:
                              isOldTransaction &&
                              haultingChargesPaidTransporterEdited
                                ? 'red'
                                : !isOldTransaction &&
                                  haultingChargesPaidTransporterEdited
                                ? 'green'
                                : 'black',
                            fontWeight: haultingChargesPaidTransporterEdited
                              ? 'bold'
                              : '',
                          }}
                        >
                          {toIndianCurrency(haltingChargesTransporter)}
                        </Typography>
                      </div>
                      <div style={displayFlex}>
                        {haltingChargesPaidByTransporter ? (
                          <img
                            src={checkedImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',

                              width: '16px',
                            }}
                          />
                        ) : (
                          <img
                            src={checkboxImage}
                            alt="icon"
                            style={{ marginLeft: '10px', width: '16px' }}
                          />
                        )}

                        <p
                          style={{
                            fontSize: '12px',
                            marginLeft: '10px',
                            marginBottom: '0px',
                            color:
                              isOldTransaction &&
                              haltingChargesPaidByTransporterCheckedEditted
                                ? 'red'
                                : !isOldTransaction &&
                                  haltingChargesPaidByTransporterCheckedEditted
                                ? 'green'
                                : 'black',
                            fontWeight: haltingChargesPaidByTransporterCheckedEditted
                              ? 'bold'
                              : '',
                          }}
                        >
                          Paid By Transporter
                        </p>
                      </div>
                    </div>
                  </GridItem>
                  <GridItem xs={6} md={4}>
                    <div style={limeRow}>
                      <div style={rowInlineStyle}>
                        <Typography variant="body1">
                          Unloading Charges
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{
                            color:
                              isOldTransaction &&
                              unloadingChargesPaidTransporterEdited
                                ? 'red'
                                : !isOldTransaction &&
                                  unloadingChargesPaidTransporterEdited
                                ? 'green'
                                : 'black',
                            fontWeight: unloadingChargesPaidTransporterEdited
                              ? 'bold'
                              : '',
                          }}
                        >
                          {toIndianCurrency(unloadingChargesTransporter)}
                        </Typography>
                      </div>
                      <div style={displayFlex}>
                        {unloadingChargesPaidByTransporter ? (
                          <img
                            src={checkedImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',

                              width: '16px',
                            }}
                          />
                        ) : (
                          <img
                            src={checkboxImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',

                              width: '16px',
                            }}
                          />
                        )}

                        <p
                          style={{
                            fontSize: '12px',
                            marginLeft: '10px',
                            marginBottom: '0px',
                            color:
                              isOldTransaction &&
                              unloadingChargesPaidByTransporterCheckedEditted
                                ? 'red'
                                : !isOldTransaction &&
                                  unloadingChargesPaidByTransporterCheckedEditted
                                ? 'green'
                                : 'black',
                            fontWeight: unloadingChargesPaidByTransporterCheckedEditted
                              ? 'bold'
                              : '',
                          }}
                        >
                          Paid By Transporter
                        </p>
                      </div>
                    </div>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer className={classes.customMargin}>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                className={classes.noPadding}
                style={{
                  float: 'left',
                  textAlign: 'left',
                  paddingLeft: '0px !important',
                }}
              >
                <h5 className={classes.customCardIconTitle}>
                  Other Expenses
                  <span style={{ fontSize: '12px', fontStyle: 'italic' }}>
                    (trucker)
                  </span>
                </h5>
                <p style={{ color: 'red', fontSize: '12px' }}>
                  (If the Box is not ticked, the expense is paid by Lobb.)
                </p>
              </GridItem>
              <GridItem className={classes.noPadding} xs={12} sm={12} md={12}>
                <GridContainer spacing={2}>
                  <GridItem xs={6} md={4}>
                    <div style={lavenderRow}>
                      <div style={rowInlineStyle}>
                        <Typography variant="body1">Loading Charges</Typography>
                        <Typography
                          variant="body1"
                          style={{
                            color:
                              isOldTransaction &&
                              loadingChargesPaidByTruckerEdited
                                ? 'red'
                                : !isOldTransaction &&
                                  loadingChargesPaidByTruckerEdited
                                ? 'green'
                                : 'black',
                            fontWeight: loadingChargesPaidByTruckerEdited
                              ? 'bold'
                              : '',
                          }}
                        >
                          {toIndianCurrency(loadingChargesTrucker)}
                        </Typography>
                      </div>
                      <div style={displayFlex}>
                        {loadingChargesPaidByTrucker ? (
                          <img
                            src={checkedImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',

                              width: '16px',
                            }}
                          />
                        ) : (
                          <img
                            src={checkboxImage}
                            alt="icon"
                            style={{ marginLeft: '10px', width: '16px' }}
                          />
                        )}

                        <p
                          style={{
                            fontSize: '12px',
                            marginLeft: '10px',
                            marginBottom: '0px',
                            color:
                              isOldTransaction &&
                              loadingChargesPaidByTruckerCheckedEditted
                                ? 'red'
                                : !isOldTransaction &&
                                  loadingChargesPaidByTruckerCheckedEditted
                                ? 'green'
                                : 'black',
                            fontWeight: loadingChargesPaidByTruckerCheckedEditted
                              ? 'bold'
                              : '',
                          }}
                        >
                          Paid By Trucker
                        </p>
                      </div>
                    </div>
                  </GridItem>
                  <GridItem xs={6} md={4}>
                    <div style={lavenderRow}>
                      <div style={rowInlineStyle}>
                        <Typography variant="body1">Hamali Charges</Typography>
                        <Typography
                          variant="body1"
                          style={{
                            color:
                              isOldTransaction &&
                              hamaliChargesPaidByTruckerEdited
                                ? 'red'
                                : !isOldTransaction &&
                                  hamaliChargesPaidByTruckerEdited
                                ? 'green'
                                : 'black',
                            fontWeight: hamaliChargesPaidByTruckerEdited
                              ? 'bold'
                              : '',
                          }}
                        >
                          {toIndianCurrency(hamaliChargesTrucker)}
                        </Typography>
                      </div>
                      <div style={displayFlex}>
                        {hamaliChargesPaidByTrucker ? (
                          <img
                            src={checkedImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',

                              width: '16px',
                            }}
                          />
                        ) : (
                          <img
                            src={checkboxImage}
                            alt="icon"
                            style={{ marginLeft: '10px', width: '16px' }}
                          />
                        )}

                        <p
                          style={{
                            fontSize: '12px',
                            marginLeft: '10px',
                            marginBottom: '0px',
                            color:
                              isOldTransaction &&
                              hamaliChargesPaidByTruckerCheckedEditted
                                ? 'red'
                                : !isOldTransaction &&
                                  hamaliChargesPaidByTruckerCheckedEditted
                                ? 'green'
                                : 'black',
                            fontWeight: hamaliChargesPaidByTruckerCheckedEditted
                              ? 'bold'
                              : '',
                          }}
                        >
                          Paid By Trucker
                        </p>
                      </div>
                    </div>
                  </GridItem>
                  <GridItem xs={6} md={4}>
                    <div style={lavenderRow}>
                      <div style={rowInlineStyle}>
                        <Typography variant="body1">Halting Charges</Typography>
                        <Typography
                          variant="body1"
                          style={{
                            color:
                              isOldTransaction &&
                              haltingChargesPaidByTruckerEdited
                                ? 'red'
                                : !isOldTransaction &&
                                  haltingChargesPaidByTruckerEdited
                                ? 'green'
                                : 'black',
                            fontWeight: haltingChargesPaidByTruckerEdited
                              ? 'bold'
                              : '',
                          }}
                        >
                          {toIndianCurrency(haltingChargesTrucker)}
                        </Typography>
                      </div>
                      <div style={displayFlex}>
                        {haltingChargesPaidByTrucker ? (
                          <img
                            src={checkedImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',
                              marginRight: '5px',
                              width: '16px',
                            }}
                          />
                        ) : (
                          <img
                            src={checkboxImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',
                              marginRight: '5px',
                              width: '16px',
                            }}
                          />
                        )}

                        <p
                          style={{
                            fontSize: '12px',
                            marginLeft: '10px',
                            marginBottom: '0px',
                            color:
                              isOldTransaction &&
                              haltingChargesPaidByTruckerCheckedEditted
                                ? 'red'
                                : !isOldTransaction &&
                                  haltingChargesPaidByTruckerCheckedEditted
                                ? 'green'
                                : 'black',
                            fontWeight: haltingChargesPaidByTruckerCheckedEditted
                              ? 'bold'
                              : '',
                          }}
                        >
                          Paid By Trucker
                        </p>
                      </div>
                    </div>
                  </GridItem>
                  <GridItem xs={6} md={4}>
                    <div style={lavenderRow}>
                      <div style={rowInlineStyle}>
                        <Typography variant="body1">
                          Unloading Charges
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{
                            color:
                              isOldTransaction &&
                              unloadingChargesPaidByTruckerEdited
                                ? 'red'
                                : !isOldTransaction &&
                                  unloadingChargesPaidByTruckerEdited
                                ? 'green'
                                : 'black',
                            fontWeight: unloadingChargesPaidByTruckerEdited
                              ? 'bold'
                              : '',
                          }}
                        >
                          {toIndianCurrency(unloadingChargesTrucker)}
                        </Typography>
                      </div>
                      <div style={displayFlex}>
                        {unloadingChargesPaidByTrucker ? (
                          <img
                            src={checkedImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',

                              width: '16px',
                            }}
                          />
                        ) : (
                          <img
                            src={checkboxImage}
                            alt="icon"
                            style={{ marginLeft: '10px', width: '16px' }}
                          />
                        )}

                        <p
                          style={{
                            fontSize: '12px',
                            marginLeft: '10px',
                            marginBottom: '0px',
                            color:
                              isOldTransaction &&
                              unloadingChargesPaidByTruckerCheckedEditted
                                ? 'red'
                                : !isOldTransaction &&
                                  unloadingChargesPaidByTruckerCheckedEditted
                                ? 'green'
                                : 'black',
                            fontWeight: unloadingChargesPaidByTruckerCheckedEditted
                              ? 'bold'
                              : '',
                          }}
                        >
                          Paid By Trucker
                        </p>
                      </div>
                    </div>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer className={classes.customMargin}>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                className={classes.noPadding}
                style={{
                  float: 'left',
                  textAlign: 'left',
                  paddingLeft: '0px !important',
                }}
              >
                <h5 className={classes.customCardIconTitle}>
                  Transporter Deductions
                </h5>
              </GridItem>
              <GridItem className={classes.noPadding} xs={12} sm={12} md={12}>
                <GridContainer spacing={2}>
                  <GridItem xs={6} md={4}>
                    <div style={greyRowWithoutCheckbox}>
                      <Typography variant="body1">CHALLAN</Typography>
                      <Typography
                        variant="body1"
                        style={{
                          color:
                            isOldTransaction && munshianaTransporterEdited
                              ? 'red'
                              : !isOldTransaction && munshianaTransporterEdited
                              ? 'green'
                              : 'black',
                          fontWeight: munshianaTransporterEdited ? 'bold' : '',
                        }}
                      >
                        {toIndianCurrency(challanTransporterAmount)}
                      </Typography>
                    </div>
                  </GridItem>
                  <GridItem xs={6} md={4}>
                    <div style={greyRowWithoutCheckbox}>
                      <Typography variant="body1">PENALTY</Typography>
                      <Typography
                        variant="body1"
                        style={{
                          color:
                            isOldTransaction && penaltyTransporterEdited
                              ? 'red'
                              : !isOldTransaction && penaltyTransporterEdited
                              ? 'green'
                              : 'black',
                          fontWeight: penaltyTransporterEdited ? 'bold' : '',
                        }}
                      >
                        {toIndianCurrency(penaltyTransporterAmount)}
                      </Typography>
                    </div>
                  </GridItem>
                  <GridItem xs={6} md={4}>
                    <div style={greyRowWithoutCheckbox}>
                      <Typography variant="body1">TDS</Typography>
                      <Typography
                        variant="body1"
                        style={{
                          color:
                            isOldTransaction && tdsTransporterEdited
                              ? 'red'
                              : !isOldTransaction && tdsTransporterEdited
                              ? 'green'
                              : 'black',
                          fontWeight: tdsTransporterEdited ? 'bold' : '',
                        }}
                      >
                        {toIndianCurrency(tdsTransporterAmount)}
                      </Typography>
                    </div>
                  </GridItem>
                  <GridItem xs={6} md={4}>
                    <div style={greyRowWithoutCheckbox}>
                      <Typography variant="body1">DOC CHARGES</Typography>
                      <Typography
                        variant="body1"
                        style={{
                          color:
                            isOldTransaction && docChargesTransporterEdited
                              ? 'red'
                              : !isOldTransaction && docChargesTransporterEdited
                              ? 'green'
                              : 'black',
                          fontWeight: docChargesTransporterEdited ? 'bold' : '',
                        }}
                      >
                        {toIndianCurrency(docChargesTransporterAmount)}
                      </Typography>
                    </div>
                  </GridItem>
                  <GridItem xs={6} md={4}>
                    <div style={greyRowWithoutCheckbox}>
                      <Typography variant="body1">FUEL CHARGES</Typography>
                      <Typography
                        variant="body1"
                        style={{
                          color:
                            isOldTransaction && fuelChargesTransporterEdited
                              ? 'red'
                              : !isOldTransaction &&
                                fuelChargesTransporterEdited
                              ? 'green'
                              : 'black',
                          fontWeight: fuelChargesTransporterEdited
                            ? 'bold'
                            : '',
                        }}
                      >
                        {toIndianCurrency(fuelChargesTransporterAmount)}
                      </Typography>
                    </div>
                  </GridItem>
                  <GridItem xs={6} md={4}>
                    <div style={greyRowWithoutCheckbox}>
                      <Typography variant="body1">MAAMOL CHARGES</Typography>
                      <Typography
                        variant="body1"
                        style={{
                          color:
                            isOldTransaction && maamolChargesTransporterEdited
                              ? 'red'
                              : !isOldTransaction &&
                                maamolChargesTransporterEdited
                              ? 'green'
                              : 'black',
                          fontWeight: maamolChargesTransporterEdited
                            ? 'bold'
                            : '',
                        }}
                      >
                        {toIndianCurrency(maamolChargesTransporterAmount)}
                      </Typography>
                    </div>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer className={classes.customMargin}>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                className={classes.noPadding}
                style={{
                  float: 'left',
                  textAlign: 'left',
                  paddingLeft: '0px !important',
                }}
              >
                <h5 className={classes.customCardIconTitle}>
                  Trucker Deductions
                </h5>
              </GridItem>
              <GridItem className={classes.noPadding} xs={12} sm={12} md={12}>
                <GridContainer spacing={2}>
                  <GridItem xs={6} md={4}>
                    <div style={orangeRowWithoutcheckbox}>
                      <Typography variant="body1">CHALLAN</Typography>
                      <Typography
                        variant="body1"
                        style={{
                          color:
                            isOldTransaction && munshianaTruckerEdited
                              ? 'red'
                              : !isOldTransaction && munshianaTruckerEdited
                              ? 'green'
                              : 'black',
                          fontWeight: munshianaTruckerEdited ? 'bold' : '',
                        }}
                      >
                        {' '}
                        {toIndianCurrency(challanTruckerAmount)}
                      </Typography>
                    </div>
                  </GridItem>
                  <GridItem xs={6} md={4}>
                    <div style={orangeRowWithoutcheckbox}>
                      <Typography variant="body1">PENALTY</Typography>
                      <Typography
                        variant="body1"
                        style={{
                          color:
                            isOldTransaction && penaltyTruckerEdited
                              ? 'red'
                              : !isOldTransaction && penaltyTruckerEdited
                              ? 'green'
                              : 'black',
                          fontWeight: penaltyTruckerEdited ? 'bold' : '',
                        }}
                      >
                        {toIndianCurrency(penaltyTruckerAmount)}
                      </Typography>
                    </div>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer className={classes.customMargin}>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                className={classes.noPadding}
                style={{
                  float: 'left',
                  textAlign: 'left',
                  paddingLeft: '0px !important',
                }}
              >
                <h5 className={classes.customCardIconTitle}>
                  More Trucker Deductions
                </h5>
                <p style={{ color: 'red', fontSize: '12px' }}>
                  (If checkbox is ticked, these values will be shown in Loading
                  Slip)
                </p>
              </GridItem>
              <GridItem className={classes.noPadding} xs={12} sm={12} md={12}>
                <GridContainer spacing={2}>
                  <GridItem xs={6} md={4}>
                    <div style={blueRow}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {docChargesTruckerShowinloadingslip ? (
                          <img
                            src={checkedImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',
                              marginRight: '5px',
                              width: '16px',
                            }}
                          />
                        ) : (
                          <img
                            src={checkboxImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',
                              marginRight: '5px',
                              width: '16px',
                            }}
                          />
                        )}

                        <Typography
                          variant="body1"
                          style={{
                            color:
                              isOldTransaction &&
                              docChargesTruckerShowinloadingslipEditted
                                ? 'red'
                                : !isOldTransaction &&
                                  docChargesTruckerShowinloadingslipEditted
                                ? 'green'
                                : 'black',
                            fontWeight: docChargesTruckerShowinloadingslipEditted
                              ? 'bold'
                              : '',
                          }}
                        >
                          DOC CHARGES
                        </Typography>
                      </div>

                      <Typography
                        variant="body1"
                        style={{
                          color:
                            isOldTransaction && docChargesTruckerEdited
                              ? 'red'
                              : !isOldTransaction && docChargesTruckerEdited
                              ? 'green'
                              : 'black',
                          fontWeight: docChargesTruckerEdited ? 'bold' : '',
                        }}
                      >
                        {toIndianCurrency(docChargesTruckerAmount)}
                      </Typography>
                    </div>
                  </GridItem>
                  <GridItem xs={6} md={4}>
                    <div style={blueRow}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {fuelChargesTruckerShowinloadingslip ? (
                          <img
                            src={checkedImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',
                              marginRight: '5px',
                              width: '16px',
                            }}
                          />
                        ) : (
                          <img
                            src={checkboxImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',
                              marginRight: '5px',
                              width: '16px',
                            }}
                          />
                        )}

                        <Typography
                          variant="body1"
                          style={{
                            color:
                              isOldTransaction &&
                              fuelChargesTruckerShowinloadingslipEditted
                                ? 'red'
                                : !isOldTransaction &&
                                  fuelChargesTruckerShowinloadingslipEditted
                                ? 'green'
                                : 'black',
                            fontWeight: fuelChargesTruckerShowinloadingslipEditted
                              ? 'bold'
                              : '',
                          }}
                        >
                          FUEL CHARGES
                        </Typography>
                      </div>
                      <Typography
                        variant="body1"
                        style={{
                          color:
                            isOldTransaction && fuelChargesTruckerEdited
                              ? 'red'
                              : !isOldTransaction && fuelChargesTruckerEdited
                              ? 'green'
                              : 'black',
                          fontWeight: fuelChargesTruckerEdited ? 'bold' : '',
                        }}
                      >
                        {toIndianCurrency(fuelChargesTruckerAmount)}
                      </Typography>
                    </div>
                  </GridItem>
                  <GridItem xs={6} md={4}>
                    <div style={blueRow}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {maamolChargesTruckerShowinloadingslip ? (
                          <img
                            src={checkedImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',
                              marginRight: '5px',
                              width: '16px',
                            }}
                          />
                        ) : (
                          <img
                            src={checkboxImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',
                              marginRight: '5px',
                              width: '16px',
                            }}
                          />
                        )}

                        <Typography
                          variant="body1"
                          style={{
                            color:
                              isOldTransaction &&
                              maamolChargesTruckerShowinloadingslipEditted
                                ? 'red'
                                : !isOldTransaction &&
                                  maamolChargesTruckerShowinloadingslipEditted
                                ? 'green'
                                : 'black',
                            fontWeight: maamolChargesTruckerShowinloadingslipEditted
                              ? 'bold'
                              : '',
                          }}
                        >
                          MAAMOL CHARGES
                        </Typography>
                      </div>
                      <Typography
                        variant="body1"
                        style={{
                          color:
                            isOldTransaction && maamolChargesTruckerEdited
                              ? 'red'
                              : !isOldTransaction && maamolChargesTruckerEdited
                              ? 'green'
                              : 'black',
                          fontWeight: maamolChargesTruckerEdited ? 'bold' : '',
                        }}
                      >
                        {toIndianCurrency(maamolChargesTruckerAmount)}
                      </Typography>
                    </div>
                  </GridItem>

                  <GridItem xs={6} md={4}>
                    <div style={blueRow}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {shortageChargesTruckerShowinloadingslip ? (
                          <img
                            src={checkedImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',
                              marginRight: '5px',
                              width: '16px',
                            }}
                          />
                        ) : (
                          <img
                            src={checkboxImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',
                              marginRight: '5px',
                              width: '16px',
                            }}
                          />
                        )}

                        <Typography
                          variant="body1"
                          style={{
                            color:
                              isOldTransaction &&
                              shortageChargesTruckerShowinloadingslipEditted
                                ? 'red'
                                : !isOldTransaction &&
                                  shortageChargesTruckerShowinloadingslipEditted
                                ? 'green'
                                : 'black',
                            fontWeight: shortageChargesTruckerShowinloadingslipEditted
                              ? 'bold'
                              : '',
                          }}
                        >
                          SHORTAGE / DAMAGE
                        </Typography>
                      </div>
                      <Typography
                        variant="body1"
                        style={{
                          color:
                            isOldTransaction && shortageChargesTruckerEditted
                              ? 'red'
                              : !isOldTransaction &&
                                shortageChargesTruckerEditted
                              ? 'green'
                              : 'black',
                          fontWeight: shortageChargesTruckerEditted
                            ? 'bold'
                            : '',
                        }}
                      >
                        {toIndianCurrency(shortageChargesTruckerAmount)}
                      </Typography>
                    </div>
                  </GridItem>
                  <GridItem xs={6} md={4}>
                    <div style={blueRow}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {lateDeliveryTruckerShowinloadingslip ? (
                          <img
                            src={checkedImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',
                              marginRight: '5px',
                              width: '16px',
                            }}
                          />
                        ) : (
                          <img
                            src={checkboxImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',
                              marginRight: '5px',
                              width: '16px',
                            }}
                          />
                        )}

                        <Typography
                          variant="body1"
                          style={{
                            color:
                              isOldTransaction &&
                              lateDeliveryTruckerShowinloadingslipEditted
                                ? 'red'
                                : !isOldTransaction &&
                                  lateDeliveryTruckerShowinloadingslipEditted
                                ? 'green'
                                : 'black',
                            fontWeight: lateDeliveryTruckerShowinloadingslipEditted
                              ? 'bold'
                              : '',
                          }}
                        >
                          LATE DELIVERY
                        </Typography>
                      </div>
                      <Typography
                        variant="body1"
                        style={{
                          color:
                            isOldTransaction && lateDeliveryTruckerEditted
                              ? 'red'
                              : !isOldTransaction && lateDeliveryTruckerEditted
                              ? 'green'
                              : 'black',
                          fontWeight: lateDeliveryTruckerEditted ? 'bold' : '',
                        }}
                      >
                        {toIndianCurrency(lateDeliveryTruckerAmount)}
                      </Typography>
                    </div>
                  </GridItem>
                  <GridItem xs={6} md={4}>
                    {(latestTransactionNumber < hardCodeTransactionNumber ||
                      showTds) && (
                      <div style={blueRow}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {tdsTruckerShowinloadingslip ? (
                            <img
                              src={checkedImage}
                              alt="icon"
                              style={{
                                marginLeft: '10px',
                                marginRight: '5px',
                                width: '16px',
                              }}
                            />
                          ) : (
                            <img
                              src={checkboxImage}
                              alt="icon"
                              style={{
                                marginLeft: '10px',
                                marginRight: '5px',
                                width: '16px',
                              }}
                            />
                          )}

                          <Typography
                            variant="body1"
                            style={{
                              color:
                                isOldTransaction &&
                                tdsTruckerShowinloadingslipEditted
                                  ? 'red'
                                  : !isOldTransaction &&
                                    tdsTruckerShowinloadingslipEditted
                                  ? 'green'
                                  : 'black',
                              fontWeight: tdsTruckerShowinloadingslipEditted
                                ? 'bold'
                                : '',
                            }}
                          >
                            TDS
                          </Typography>
                        </div>
                        <Typography
                          style={{ marginLeft: '5px', color: '#D14343' }}
                        >
                          {tdsPercentage === 'INVALID PAN'
                            ? ''
                            : `(${tdsPercentage} %)`}
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{
                            color:
                              isOldTransaction && tdsTruckerEditted
                                ? 'red'
                                : !isOldTransaction && tdsTruckerEditted
                                ? 'green'
                                : 'black',
                            fontWeight: tdsTruckerEditted ? 'bold' : '',
                          }}
                        >
                          {toIndianCurrency(tdsTruckerAmount)}
                        </Typography>
                      </div>
                    )}
                  </GridItem>
                  <GridItem xs={6} md={4}>
                    <div style={blueRow}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {earlySettlementTruckerShowinloadingslip ? (
                          <img
                            src={checkedImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',
                              marginRight: '5px',
                              width: '16px',
                            }}
                          />
                        ) : (
                          <img
                            src={checkboxImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',
                              marginRight: '5px',
                              width: '16px',
                            }}
                          />
                        )}

                        <Typography
                          variant="body1"
                          style={{
                            color:
                              isOldTransaction &&
                              earlySettlementTruckerShowinloadingslipEditted
                                ? 'red'
                                : !isOldTransaction &&
                                  earlySettlementTruckerShowinloadingslipEditted
                                ? 'green'
                                : 'black',
                            fontWeight: earlySettlementTruckerShowinloadingslipEditted
                              ? 'bold'
                              : '',
                          }}
                        >
                          EARLY SETTLEMENT CHARGES
                        </Typography>
                      </div>
                      <Typography
                        variant="body1"
                        style={{
                          color:
                            isOldTransaction && earlySettlementTruckerEditted
                              ? 'red'
                              : !isOldTransaction &&
                                earlySettlementTruckerEditted
                              ? 'green'
                              : 'black',
                          fontWeight: earlySettlementTruckerEditted
                            ? 'bold'
                            : '',
                        }}
                      >
                        {toIndianCurrency(earlySettlementTruckerAmount)}
                      </Typography>
                    </div>
                  </GridItem>
                  <GridItem xs={6} md={4}>
                    <div style={blueRow}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {latePodTruckerShowinloadingslip ? (
                          <img
                            src={checkedImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',
                              marginRight: '5px',
                              width: '16px',
                            }}
                          />
                        ) : (
                          <img
                            src={checkboxImage}
                            alt="icon"
                            style={{
                              marginLeft: '10px',
                              width: '16px',
                              marginRight: '5px',
                            }}
                          />
                        )}

                        <Typography
                          variant="body1"
                          style={{
                            color:
                              isOldTransaction &&
                              latePodTruckerShowinloadingslipEditted
                                ? 'red'
                                : !isOldTransaction &&
                                  latePodTruckerShowinloadingslipEditted
                                ? 'green'
                                : 'black',
                            fontWeight: latePodTruckerShowinloadingslipEditted
                              ? 'bold'
                              : '',
                          }}
                        >
                          LATE POD
                        </Typography>
                      </div>
                      <Typography
                        variant="body1"
                        style={{
                          color:
                            isOldTransaction && latePodTruckerEditted
                              ? 'red'
                              : !isOldTransaction && latePodTruckerEditted
                              ? 'green'
                              : 'black',
                          fontWeight: latePodTruckerEditted ? 'bold' : '',
                        }}
                      >
                        {toIndianCurrency(latePodTruckerAmount)}
                      </Typography>
                    </div>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </form>
    </Box>
  );
};

export default TripDeductionsView;
