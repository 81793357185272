/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable class-methods-use-this */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
import React from 'react';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// @material-ui/icons
import SwapHoriz from '@material-ui/icons/SwapHoriz';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Clearfix from 'components/Clearfix/Clearfix.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import Datetime from 'react-datetime';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';
import Danger from 'components/Typography/Danger.jsx';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import SweetAlert from 'react-bootstrap-sweetalert';
import moment from 'moment';

// Fireabse
import AddTruckersStyle from 'assets/jss/material-dashboard-pro-react/views/AddTruckersStyle.jsx';
import firebase from '../../config/config';

const db = firebase.firestore();
const addTransaction = firebase
  .functions()
  .httpsCallable('transactions-addTransaction');
const auditLog = firebase.functions().httpsCallable('utilities-auditLog');

class AddTransactions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transporterName: '',
      transporterNameState: '',
      truckerNameState: '',
      transporterId: '',
      truckerName: '',
      truckerId: '',
      fromCity: [],
      fromCityState: '',
      toCity: [],
      toCityState: '',
      vehicleNumber: '',
      vehicleNumberState: '',
      amount: '0',
      amountState: '',
      commission: '0',
      commissionState: '',
      loadingCharges: '0',
      loadingChargesState: '',
      bthReceivable: '0',
      bthReceivableState: '',
      bthPayable: '0',
      bthPayableState: '',
      athReceivable: '0',
      athReceivableState: '',
      athPayable: '0',
      athPayableState: '',
      truckType: '',
      truckTypeState: '',
      truckNumber: '',
      truckNumberState: '',
      companyName: '',
      isLoading: false,
      alert: null,
      companyId: '',
      trucks: [],
      truckTypes: [
        '12T- Open',
        '20FT Container',
        '22FT Closed Container',
        '22FT Jcb Carrier',
        '24FT Closed Container',
        '24FT Jcb Carrier',
        '32FT MultiAxle Container',
        '32FT Single Axle Container',
        '34FT Multi Axle Container',
        '34FT Single Axle Container',
        'FTL',
        'High Bed Trailer',
        'High Bed Trailer Double Axle',
        'High Bed Trailer Multi Axle',
        'LCV Closed Container - 14FT',
        'LCV-14FT',
        'LCV-19FT',
        'LCV-7FT',
        'LCV-17FT',
        'Low Bed Trailer',
        'Low Bed Trailer Double Axle',
        'Low Bed Trailer Multi Axle',
        'LPT-19 FT',
        'LPT-19FT Closed Container',
      ],
      transporters: [],
      truckers: [],
      fromCities: [],
      toCities: [],
      totalPayablePendingAmount: 0,
      totalReceivablePendingAmount: 0,
      totalCommission: 0,
      loadingDate: moment(),
      // athReceivableDate: moment(),
      athReceivableDate: 'None',
      haultingCharges: '0',
      haultingChargesState: '',
      hamaliCharges: '0',
      hamaliChargesState: '',
      loadingChargesPaidTransporter: false,
      hamaliChargesPaidTransporter: false,
      haultingChargesPaidTransporter: false,
      munshiana: '0',
      munshianaState: '',
      penalty: '0',
      penaltyState: '',
      munshianaTrucker: false,
      munshianaTransporter: false,
      penaltyTrucker: false,
      penaltyTransporter: false,
      branches: [],
      branch: '',
      comapnyId: '',
      myBranches: '',
      tds: '0',
      tdsState: '',
      tdsTrucker: false,
      tdsTransporter: false,
      docCharges: '0',
      docChargesState: '',
      docChargesTrucker: false,
      docChargesTransporter: false,
    };
    this.change = this.change.bind(this);
    this.submitTransaction = this.submitTransaction.bind(this);
    this.validateAllFields = this.validateAllFields.bind(this);
    this.getTruckTypes = this.getTruckTypes.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.getTruckers = this.getTruckers.bind(this);
    this.getCities = this.getCities.bind(this);
    this.handleTrucker = this.handleTrucker.bind(this);
    this.handleTrucks = this.handleTrucks.bind(this);
    this.loadingDateChange = this.loadingDateChange.bind(this);
    this.athReceivableDateChange = this.athReceivableDateChange.bind(this);
    this.otherExpenses = this.otherExpenses.bind(this);
    this.deductions = this.deductions.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.confirmAlert = this.confirmAlert.bind(this);
    this.getUserCompanyName = this.getUserCompanyName.bind(this);
  }

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  confirmAlert() {
    this.setState({
      alert: null,
    });
    this.props.history.push('/transporters/transactions/view');
  }

  componentDidMount() {
    if (this.props.userDetails.branches.length > 1)
      this.setState({ branches: this.props.userDetails.branches });
    else {
      this.setState({ branch: this.props.userDetails.branches[0] });
    }
    this.getCities();
    this.getTruckers();
    this.getUserCompanyName();
  }

  /**
   * Get the Users Company Name from the Database
   * @function
   */
  getUserCompanyName() {
    firebase.auth().onAuthStateChanged(user => {
      const userRef = db.collection(`Users`).doc(`${user.uid}`);
      userRef
        .get()
        .then(
          resultData => {
            if (resultData.exists) {
              if (resultData.data().companyId) {
                this.setState({
                  companyName: resultData.data().companyName,
                  companyId: resultData.data().companyId,
                  myBranches: resultData.data().branches,
                  transporterName: resultData.data().name,
                  transporterId: resultData.data().uid,
                });
              }
            }
          },
          err => {
            console.log('err', err);
          }
        )
        .catch(err => {
          console.log('err', err);
        });
    });
  }

  /**
   * will return All the Truckers Available for User
   * @function
   * @param {String} companyId
   */
  getTruckers() {
    const TruckerRef = db.collection('Truckers');
    TruckerRef.where('status', '==', 'approved')
      .where('updatedBy', '==', this.props.userDetails.uid)
      .get()
      .then(
        resultData => {
          const truckers = [];
          resultData.forEach(trucker => {
            truckers.push(trucker.data());
          });
          this.setState({
            truckers,
          });
        },
        err => {
          console.log('err', err);
        }
      )
      .catch(err => {
        console.log('err', err);
      });
  }

  /**
   * will return All the Cities Available for User
   * @function
   * @param {String} companyId
   */
  getCities() {
    const citiesRef = db.collection(`Global`).doc(`Cities`);
    citiesRef
      .get()
      .then(
        resultData => {
          const cities = resultData.data().cities;
          this.setState({ fromCities: cities, toCities: cities });
        },
        err => {
          console.log('err', err);
        }
      )
      .catch(err => {
        console.log('err', err);
      });
  }

  // function that verifies if value contains only numbers
  verifyNumber(value) {
    const numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }

  verifyNumberAndGreater(value) {
    const numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value) && value > 4999) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  // Will Verify cities
  verifyCities(value) {
    if (value.length < 2) {
      return false;
    }
    return true;
  }

  calculateFields() {
    const {
      amount,
      commission,
      athReceivable,
      // athPayable,
      // bthPayable,
    } = this.state;

    const bthReceivable = amount - athReceivable;
    const athPayable = athReceivable - commission;
    const bthPayable = amount - athPayable - commission;

    this.setState({ bthReceivable, athPayable, bthPayable });

    if (this.verifyNumber(bthReceivable))
      this.setState({
        bthReceivableState: 'success',
      });
    else
      this.setState({
        bthReceivableState: 'Invalid',
      });

    if (this.verifyNumber(athPayable))
      this.setState({
        athPayableState: 'success',
      });
    else
      this.setState({
        athPayableState: 'Invalid',
      });

    if (this.verifyNumber(bthPayable))
      this.setState({
        bthPayableState: 'success',
      });
    else
      this.setState({
        bthPayableState: 'Invalid',
      });
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'number':
        if (this.verifyNumber(event.target.value)) {
          this.setState(
            {
              [`${stateName}State`]: 'success',
              [stateName]: event.target.value,
            },
            this.calculateFields
          );
        } else {
          this.setState({
            [`${stateName}State`]: 'Invalid',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'Invalid',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'greater':
        if (this.verifyNumberAndGreater(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'Invalid',
            [stateName]: event.target.value,
          });
        }
        break;
      default:
        break;
    }
  }

  // validates all required fields and returns a promise
  validateAllFields() {
    let error = false;
    return new Promise((resolve, reject) => {
      // Verify  Transporter Name

      if (this.verifyLength(this.state.transporterName, 1)) {
        this.setState({
          transporterNameState: 'success',
        });
      } else {
        this.setState({ transporterNameState: 'Invalid' });
        error = true;
      }

      // Verify  Trucker Name
      if (this.verifyLength(this.state.truckerName, 1)) {
        this.setState({
          truckerNameState: 'success',
        });
      } else {
        this.setState({ truckerNameState: 'Invalid' });
        error = true;
      }
      // Verify  From City
      if (this.verifyLength(this.state.fromCity, 1)) {
        this.setState({
          fromCityState: 'success',
        });
      } else {
        this.setState({ fromCityState: 'Invalid' });
        error = true;
      }
      // Verify  To City
      if (this.verifyLength(this.state.toCity, 1)) {
        this.setState({
          toCityState: 'success',
        });
      } else {
        this.setState({ toCityState: 'Invalid' });
        error = true;
      }

      // Verify  Amount
      if (this.verifyNumberAndGreater(this.state.amount)) {
        this.setState({
          amountState: 'success',
        });
      } else {
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: 'block', marginTop: '-100px' }}
              title="Error"
              onConfirm={() => this.hideAlert()}
              confirmBtnCssClass={`${this.props.classes.button} ${this.props.classes.success}`}
            >
              The Freight value must be minimum 5,000.
            </SweetAlert>
          ),
          amountState: 'Invalid',
        });
        error = true;
      }

      // Verify  Commission
      if (this.verifyNumber(this.state.commission)) {
        this.setState({
          commissionState: 'success',
        });
      } else {
        this.setState({ commissionState: 'Invalid' });
        error = true;
      }

      // Verify  Commission
      if (this.verifyNumber(this.state.loadingCharges)) {
        this.setState({
          loadingChargesState: 'success',
        });
      } else {
        this.setState({ loadingChargesState: 'Invalid' });
        error = true;
      }

      // Verify  Commission
      if (this.verifyNumber(this.state.hamaliCharges)) {
        this.setState({
          hamaliChargesState: 'success',
        });
      } else {
        this.setState({ hamaliChargesState: 'Invalid' });
        error = true;
      }

      // Verify  Commission
      if (this.verifyNumber(this.state.haultingCharges)) {
        this.setState({
          haultingChargesState: 'success',
        });
      } else {
        this.setState({ haultingChargesState: 'Invalid' });
        error = true;
      }

      // Verify  Commission
      if (this.verifyNumber(this.state.munshiana)) {
        this.setState({
          munshianaState: 'success',
        });
        if (
          Number(this.state.munshiana) > 0 &&
          !this.state.munshianaTransporter &&
          !this.state.munshianaTrucker
        ) {
          error = true;
          this.setState({
            munshianaState: 'Invalid',
          });
        }
      } else {
        this.setState({ munshianaState: 'Invalid' });
        error = true;
      }

      // Verify  Commission
      if (this.verifyNumber(this.state.penalty)) {
        this.setState({
          penaltyState: 'success',
        });
        if (
          Number(this.state.penalty) > 0 &&
          !this.state.penaltyTransporter &&
          !this.state.penaltyTrucker
        ) {
          error = true;
          this.setState({
            penaltyState: 'Invalid',
          });
        }
      } else {
        this.setState({ penaltyState: 'Invalid' });
        error = true;
      }

      // Verify  Commission
      if (this.verifyNumber(this.state.bthPayable)) {
        this.setState({
          bthPayableState: 'success',
        });
      } else {
        this.setState({ bthPayableState: 'Invalid' });
        error = true;
      }

      // Verify  Commission
      if (this.verifyNumber(this.state.bthReceivable)) {
        this.setState({
          bthReceivableState: 'success',
        });
      } else {
        this.setState({ bthReceivableState: 'Invalid' });
        error = true;
      }

      // Verify  Commission
      if (this.verifyNumber(this.state.athReceivable)) {
        this.setState({
          athReceivableState: 'success',
        });
      } else {
        this.setState({ athReceivableState: 'Invalid' });
        error = true;
      }

      // Verify  Commission
      if (this.verifyNumber(this.state.athPayable)) {
        this.setState({
          athPayableState: 'success',
        });
      } else {
        this.setState({ athPayableState: 'Invalid' });
        error = true;
      }

      // Verify  Truck Number
      if (this.verifyLength(this.state.truckNumber, 1)) {
        this.setState({ truckNumberState: 'success' });
      } else {
        this.setState({ truckNumberState: 'Invalid' });
        error = true;
      }

      // Verify whether the amount is less the commission
      if (
        parseInt(this.state.amount, 10) < parseInt(this.state.commission, 10)
      ) {
        this.setState({
          commissionState: 'Invalid',
        });
        error = true;
      }

      if (!error) {
        if (this.checkValues()) {
          resolve(true);
        } else {
          this.setState({
            alert: (
              <SweetAlert
                warning
                style={{ display: 'block', marginTop: '-100px' }}
                title="Warning"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnCssClass={`${this.props.classes.button} ${this.props.classes.success}`}
              >
                Please verify your calculations.
              </SweetAlert>
            ),
          });
          reject('Calculations do not match.');
        }
      } else {
        reject('validation Failed');
      }
    });
  }

  checkValues() {
    const {
      amount,
      commission,
      munshianaTrucker,
      munshianaTransporter,
      athPayable,
      bthPayable,
      athReceivable,
      bthReceivable,
      munshiana,
      penalty,
      penaltyTransporter,
      penaltyTrucker,
      loadingCharges,
      loadingChargesPaidTransporter,
      hamaliCharges,
      hamaliChargesPaidTransporter,
      haultingCharges,
      haultingChargesPaidTransporter,
      tds,
      tdsTrucker,
      tdsTransporter,
      docCharges,
      docChargesTrucker,
      docChargesTransporter,
    } = this.state;
    //  Total Payable Calculation
    let payableDeductions = 0;
    if (munshianaTrucker) payableDeductions += Number(munshiana);
    if (penaltyTrucker) payableDeductions += Number(penalty);
    if (tdsTrucker) payableDeductions += Number(tds);
    if (docChargesTrucker) payableDeductions += Number(docCharges);
    //  Total Receivable Calculation
    let receivableDeductions = 0;
    if (penaltyTransporter) receivableDeductions += Number(penalty);
    if (munshianaTransporter) receivableDeductions += Number(munshiana);
    if (tdsTransporter) receivableDeductions += Number(tds);
    if (docChargesTransporter) receivableDeductions += Number(docCharges);

    let otherExpensesPayable = 0;
    let otherExpensesReceivable = 0;
    if (loadingChargesPaidTransporter) {
      otherExpensesReceivable += Number(loadingCharges);
      otherExpensesPayable += Number(loadingCharges);
    } else otherExpensesPayable += Number(loadingCharges);

    if (hamaliChargesPaidTransporter) {
      otherExpensesReceivable += Number(hamaliCharges);
      otherExpensesPayable += Number(hamaliCharges);
    } else otherExpensesPayable += Number(hamaliCharges);

    if (haultingChargesPaidTransporter) {
      otherExpensesReceivable += Number(haultingCharges);
      otherExpensesPayable += Number(haultingCharges);
    } else otherExpensesPayable += Number(haultingCharges);

    const totalPayableAmount =
      Number(athPayable) + Number(bthPayable) - payableDeductions;
    // + otherExpensesPayable;
    const totalReceivableAmount =
      Number(athReceivable) +
      Number(bthReceivable) -
      receivableDeductions +
      otherExpensesReceivable;

    const calculatedCommision = totalReceivableAmount - totalPayableAmount;
    this.setState({
      totalPayableAmount,
      totalReceivableAmount,
      otherExpensesPayable,
      otherExpensesReceivable,
      payableDeductions,
      receivableDeductions,
      netCommission: calculatedCommision,
    });

    // Check Frieght = Receivable ATH + Receivable BTH
    // Check Freight = Payable ATH + Payable BTH + Commission
    if (
      Number(amount) !== Number(athReceivable) + Number(bthReceivable) ||
      Number(amount) !==
        Number(athPayable) + Number(bthPayable) + Number(commission)
    )
      return false;
    return true;
  }

  /**
   * Submits the transporter details to the Database
   * @function
   */
  submitTransaction() {
    this.setState({
      isLoading: true,
    });
    console.log('submit transactions');
    this.validateAllFields()
      .then(
        success => {
          if (success) {
            const { userDetails, systemDetails } = this.props;
            const {
              transporterName,
              truckerName,
              fromCity,
              toCity,
              amount,
              commission,
              netCommission,
              athPayable,
              bthPayable,
              athReceivable,
              bthReceivable,
              munshiana,
              munshianaTrucker,
              munshianaTransporter,
              penalty,
              penaltyTransporter,
              penaltyTrucker,
              tds,
              tdsTransporter,
              tdsTrucker,
              docCharges,
              docChargesTrucker,
              docChargesTransporter,
              loadingCharges,
              loadingChargesPaidTransporter,
              hamaliCharges,
              hamaliChargesPaidTransporter,
              haultingCharges,
              haultingChargesPaidTransporter,
              totalPayableAmount,
              totalReceivableAmount,
              loadingDate,
              truckerId,
              transporterId,
            } = this.state;
            const user = firebase.auth().currentUser;
            //  const truckerRef = db.collection(`Transactions`);

            const body = {
              isEditedTransaction: false,
              agentId: user.uid,
              vehicleNumber: this.state.truckNumber,
              truckType: this.state.truckType,
              transporterName,
              truckerName,
              fromCity,
              toCity,
              amount,
              commission,
              netCommission,
              athPayable,
              bthPayable,
              athReceivable,
              bthReceivable,
              munshiana,
              munshianaTrucker,
              munshianaTransporter,
              penalty,
              penaltyTransporter,
              penaltyTrucker,
              loadingCharges,
              loadingChargesPaidTransporter,
              hamaliCharges,
              hamaliChargesPaidTransporter,
              haultingCharges,
              haultingChargesPaidTransporter,
              totalPayableAmount,
              totalReceivableAmount,
              truckerId,
              transporterId,
              tds,
              tdsTransporter,
              tdsTrucker,
              docCharges,
              docChargesTrucker,
              docChargesTransporter,
              branch: 'Premium',
              loadingDate: loadingDate.format('MMM Do YY'),
              athReceivableDate: 'None',
              updatedByRole: 'premiumTransporterAdmin',
            };
            addTransaction(body)
              .then(result => {
                const auditBody = {
                  data: {
                    ...body,
                  },
                  collection: 'Transactions',
                  updatedBy: userDetails.uid,
                  systemDetails,
                  type: 'Create Transaction',
                  message: `${userDetails.email} created a new Transaction with GFV ${amount}, Commission ${commission}, From ${fromCity} To ${toCity}`,
                };
                auditLog(auditBody);
                this.clearForm();
                this.setState({ isLoading: false });
              })
              .catch(err => {
                const errorBody = {
                  message: `${userDetails.email} tried to create a new Transaction but FAILED`,
                  status: 'fail',
                  error: err,
                  updatedBy: userDetails.uid,
                  errorMessage: err.message,
                  collection: 'Transactions',
                  systemDetails,
                };
                auditLog(errorBody);
                console.log('Error in adding transaction', err);
                this.setState({
                  alert: (
                    <SweetAlert
                      error
                      style={{ display: 'block', marginTop: '-100px' }}
                      title="Error"
                      onConfirm={() => this.hideAlert()}
                      confirmBtnCssClass={`${this.props.classes.button} ${this.props.classes.success}`}
                    >
                      Oops!, Something Went Wrong.
                    </SweetAlert>
                  ),
                });
                this.setState({ isLoading: false });
              });
          }
        },
        err => {
          console.log(err);
          this.setState({
            isLoading: false,
          });
        }
      )
      .catch(err => {
        console.log(err);
        this.setState({
          isLoading: false,
        });
      });
  }

  /**
   * Will Clears ALl the State values after form submision
   * @function
   */
  clearForm() {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title="Good job!"
          onConfirm={() => this.confirmAlert()}
          onCancel={() => this.confirmAlert()}
          confirmBtnCssClass={`${this.props.classes.button} ${this.props.classes.success}`}
        >
          Transaction Added Succeessfully
        </SweetAlert>
      ),
    });
    this.setState({
      transporterName: '',
      transporterId: '',
      truckerName: '',
      truckerId: '',
      fromCity: '',
      fromCityState: '',
      toCity: '',
      toCityState: '',
      vehicleNumber: '',
      vehicleNumberState: '',
      amount: '0',
      amountState: '',
      commission: '0',
      commissionState: '',
      truckType: '',
      truckTypeState: '',
      truckNumber: '',
      truckNumberState: '',
      isLoading: false,
      loadingCharges: '0',
      loadingChargesState: '',
      bthReceivable: '0',
      bthReceivableState: '',
      bthPayable: '0',
      bthPayableState: '',
      athReceivable: '0',
      athReceivableState: '',
      athPayable: '0',
      athPayableState: '',
      haultingCharges: '0',
      haultingChargesState: '',
      hamaliCharges: '0',
      hamaliChargesState: '0',
      loadingChargesPaidTransporter: false,
      hamaliChargesPaidTransporter: false,
      haultingChargesPaidTransporter: false,
      munshiana: '0',
      munshianaState: '',
      penalty: '0',
      penaltyState: '',
      munshianaTrucker: false,
      munshianaTransporter: false,
      penaltyTrucker: false,
      penaltyTransporter: false,
      tds: '0',
      tdsTransporter: false,
      tdsTrucker: false,
      docCharges: '0',
      docChargesTrucker: false,
      docChargesTransporter: false,
    });
  }

  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleTrucker = event => {
    const truckers = this.state.truckers;
    for (const i in truckers) {
      if (truckers[i].truckerId === event.target.value) {
        if (truckers[i].trucks) {
          this.setState({
            trucks: truckers[i].trucks,
            truckType: '',
            truckNumber: '',
          });
        }
        this.setState({
          truckerName: truckers[i].name,
        });
      }
    }
    this.setState({ truckerId: event.target.value });
  };

  handleTrucks = event => {
    for (const i in this.state.trucks) {
      if (this.state.trucks[i].truckNumber === event.target.value) {
        this.setState({ truckType: this.state.trucks[i].truckType });
      }
    }
    this.setState({ truckNumber: event.target.value });
  };

  // Will Return select From cities component
  selectFromCities() {
    const { classes } = this.props;
    return (
      <FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
          Select From City
        </InputLabel>
        <Select
          MenuProps={{ className: classes.selectMenu }}
          classes={{ select: classes.select }}
          value={this.state.fromCity}
          onChange={this.handleSimple}
          inputProps={{ name: 'fromCity', id: 'simple-select' }}
        >
          <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
            Select City
          </MenuItem>
          {this.state.fromCities.map((city, i) => (
            <MenuItem
              key={i}
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value={city}
            >
              {city}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  // Will Return select From cities component
  selectToCities() {
    const { classes } = this.props;
    return (
      <FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
          Select To City
        </InputLabel>
        <Select
          MenuProps={{ className: classes.selectMenu }}
          classes={{ select: classes.select }}
          value={this.state.toCity}
          onChange={this.handleSimple}
          inputProps={{ name: 'toCity', id: 'simple-select' }}
        >
          <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
            Select City
          </MenuItem>
          {this.state.toCities.map((city, i) => (
            <MenuItem
              key={i}
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value={city}
            >
              {city}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  getTruckerNames() {
    const { classes } = this.props;
    return (
      <FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
          Select Trucker
        </InputLabel>
        <Select
          MenuProps={{ className: classes.selectMenu }}
          classes={{ select: classes.select }}
          value={this.state.truckerId}
          onChange={this.handleTrucker}
          inputProps={{ name: 'truckerName', id: 'simple-select' }}
        >
          <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
            Select Trucker
          </MenuItem>
          {this.state.truckers.map((trucker, i) => (
            <MenuItem
              key={i}
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value={trucker.truckerId}
            >
              {trucker.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  handleCheckbox(stateName) {
    let current = this.state[stateName];
    current = !current;

    this.setState({ [stateName]: current });
  }

  deductions() {
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader color="primary" icon>
          <h3 className={classes.cardIconTitle}>Deductions</h3>
        </CardHeader>
        <CardBody>
          <form>
            <GridContainer>
              <GridItem xs={12} sm={4} md={4}>
                <FormControl>
                  <CustomInput
                    labelText="Challan"
                    id="munshiana"
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      onChange: event =>
                        this.change(event, 'munshiana', 'number'),
                      type: 'number',
                      value: this.state.munshiana,
                    }}
                    success={this.state.munshianaState === 'success'}
                    error={this.state.munshianaState === 'Invalid'}
                  />
                </FormControl>
              </GridItem>

              <GridItem xs={12} sm={4} md={4}>
                <div
                  style={{ marginTop: '15px', marginLeft: '15px' }}
                  className={classes.checkboxAndRadio}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.munshianaTrucker}
                        onChange={() => this.handleCheckbox('munshianaTrucker')}
                        value="munshianaTrucker"
                      />
                    }
                    label="To Trucker"
                  />
                </div>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <div
                  style={{ marginTop: '15px' }}
                  className={classes.checkboxAndRadio}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.munshianaTransporter}
                        onChange={() =>
                          this.handleCheckbox('munshianaTransporter')
                        }
                        value="munshianaTransporter"
                      />
                    }
                    label="From Transporter"
                  />
                </div>
              </GridItem>

              <GridItem xs={12} sm={4} md={4}>
                <FormControl>
                  <CustomInput
                    labelText="Penalty"
                    id="penalty"
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      onChange: event =>
                        this.change(event, 'penalty', 'number'),
                      type: 'number',
                      value: this.state.penalty,
                    }}
                    success={this.state.penaltyState === 'success'}
                    error={this.state.penaltyState === 'Invalid'}
                  />
                </FormControl>
              </GridItem>

              <GridItem xs={12} sm={4} md={4}>
                <div
                  style={{ marginTop: '15px', marginLeft: '15px' }}
                  className={classes.checkboxAndRadio}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.penaltyTrucker}
                        onChange={() => this.handleCheckbox('penaltyTrucker')}
                        value="penaltyTrucker"
                      />
                    }
                    label="To Trucker"
                  />
                </div>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <div
                  style={{ marginTop: '15px' }}
                  className={classes.checkboxAndRadio}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.penaltyTransporter}
                        onChange={() =>
                          this.handleCheckbox('penaltyTransporter')
                        }
                        value="penaltyTransporter"
                      />
                    }
                    label="From Transporter"
                  />
                </div>
              </GridItem>
              {/* New Modifications */}
              <GridItem xs={12} sm={4} md={4}>
                <FormControl>
                  <CustomInput
                    labelText="Tds deduction"
                    id="tds"
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      onChange: event => this.change(event, 'tds', 'number'),
                      type: 'number',
                      value: this.state.tds,
                    }}
                    success={this.state.tdsState === 'success'}
                    error={this.state.tdsState === 'Invalid'}
                  />
                </FormControl>
              </GridItem>

              <GridItem xs={12} sm={4} md={4}>
                <div
                  style={{ marginTop: '15px', marginLeft: '15px' }}
                  className={classes.checkboxAndRadio}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.tdsTrucker}
                        onChange={() => this.handleCheckbox('tdsTrucker')}
                        value="tdsTrucker"
                      />
                    }
                    label="To Trucker"
                  />
                </div>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <div
                  style={{ marginTop: '15px' }}
                  className={classes.checkboxAndRadio}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.tdsTransporter}
                        onChange={() => this.handleCheckbox('tdsTransporter')}
                        value="tdsTransporter"
                      />
                    }
                    label="From Transporter"
                  />
                </div>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <FormControl>
                  <CustomInput
                    labelText="Doc deduction"
                    id="doc"
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      onChange: event =>
                        this.change(event, 'docCharges', 'number'),
                      type: 'number',
                      value: this.state.docCharges,
                    }}
                    success={this.state.docChargesState === 'success'}
                    error={this.state.docChargesState === 'Invalid'}
                  />
                </FormControl>
              </GridItem>

              <GridItem xs={12} sm={4} md={4}>
                <div
                  style={{ marginTop: '15px', marginLeft: '15px' }}
                  className={classes.checkboxAndRadio}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.docChargesTrucker}
                        onChange={() =>
                          this.handleCheckbox('docChargesTrucker')
                        }
                        value="docChargesTrucker"
                      />
                    }
                    label="To Trucker"
                  />
                </div>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <div
                  style={{ marginTop: '15px' }}
                  className={classes.checkboxAndRadio}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.docChargesTransporter}
                        onChange={() =>
                          this.handleCheckbox('docChargesTransporter')
                        }
                        value="docChargesTransporter"
                      />
                    }
                    label="From Transporter"
                  />
                </div>
              </GridItem>
            </GridContainer>
          </form>
        </CardBody>
      </Card>
    );
  }

  otherExpenses() {
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader color="primary" icon>
          <h3 className={classes.cardIconTitle}>Other Expenses</h3>
        </CardHeader>
        <CardBody>
          <form>
            <GridContainer>
              <GridItem xs={12} sm={4} md={4}>
                <FormControl>
                  <CustomInput
                    labelText="Loading Charges"
                    id="loadingCharges"
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      onChange: event =>
                        this.change(event, 'loadingCharges', 'number'),
                      type: 'number',
                      value: this.state.loadingCharges,
                    }}
                    success={this.state.loadingChargesState === 'success'}
                    error={this.state.loadingChargesState === 'Invalid'}
                  />
                </FormControl>
              </GridItem>

              <GridItem xs={12} sm={8} md={8}>
                <div
                  style={{ marginTop: '15px', marginLeft: '15px' }}
                  className={classes.checkboxAndRadio}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.loadingChargesPaidTransporter}
                        onChange={() =>
                          this.handleCheckbox('loadingChargesPaidTransporter')
                        }
                        value="loadingChargesPaidTransporter"
                      />
                    }
                    label="Collected From Transporter?"
                  />
                </div>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <FormControl>
                  <CustomInput
                    labelText="Hamali Charges"
                    id="hamaliCharges"
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      onChange: event =>
                        this.change(event, 'hamaliCharges', 'number'),
                      type: 'number',
                      value: this.state.hamaliCharges,
                    }}
                    success={this.state.hamaliChargesState === 'success'}
                    error={this.state.hamaliChargesState === 'Invalid'}
                  />
                </FormControl>
              </GridItem>

              <GridItem xs={12} sm={8} md={8}>
                <div
                  style={{ marginTop: '15px', marginLeft: '15px' }}
                  className={classes.checkboxAndRadio}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.hamaliChargesPaidTransporter}
                        onChange={() =>
                          this.handleCheckbox('hamaliChargesPaidTransporter')
                        }
                        value="hamaliChargesPaidTransporter"
                      />
                    }
                    label="Collected From Transporter?"
                  />
                </div>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <FormControl>
                  <CustomInput
                    labelText="Halting Charges"
                    id="haultingCharges"
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      onChange: event =>
                        this.change(event, 'haultingCharges', 'number'),
                      type: 'number',
                      value: this.state.haultingCharges,
                    }}
                    success={this.state.haultingChargesState === 'success'}
                    error={this.state.haultingChargesState === 'Invalid'}
                  />
                </FormControl>
              </GridItem>

              <GridItem xs={12} sm={8} md={8}>
                <div
                  style={{ marginTop: '15px', marginLeft: '15px' }}
                  className={classes.checkboxAndRadio}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.haultingChargesPaidTransporter}
                        onChange={() =>
                          this.handleCheckbox('haultingChargesPaidTransporter')
                        }
                        value="haultingChargesPaidTransporter"
                      />
                    }
                    label="Collected From Transporter?"
                  />
                </div>
              </GridItem>
            </GridContainer>
          </form>
        </CardBody>
      </Card>
    );
  }

  getTruckTypes() {
    const { classes } = this.props;
    return (
      <FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
          Select Truck
        </InputLabel>
        <Select
          MenuProps={{ className: classes.selectMenu }}
          classes={{ select: classes.select }}
          value={this.state.truckNumber}
          onChange={this.handleTrucks}
          inputProps={{ name: 'truckNumber', id: 'simple-select' }}
        >
          <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
            Select Truck
          </MenuItem>
          {this.state.trucks.map((truck, i) => (
            <MenuItem
              key={i}
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value={truck.truckNumber}
            >
              {truck.truckNumber}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  loadingDateChange(loadingDate) {
    this.setState({ loadingDate });
  }

  athReceivableDateChange(athReceivableDate) {
    this.setState({ athReceivableDate });
  }

  render() {
    //  console.log(this.state);
    const { classes } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            {this.state.alert}
            <Card>
              <CardHeader className={`${classes.cardHeader}`} color="info">
                <CardIcon color="primary" className={`${classes.cardHeader}`}>
                  <SwapHoriz />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  Add transaction <small />
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    {this.getTruckerNames()}
                    {this.state.truckerNameState === 'Invalid' && (
                      <Danger>{this.state.truckerNameState}</Danger>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    {this.getTruckTypes()}
                    {this.state.truckNumberState === 'Invalid' && (
                      <Danger>{this.state.truckNumberState}</Danger>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    {this.selectFromCities()}
                    {this.state.fromCityState === 'Invalid' && (
                      <Danger>{this.state.fromCityState}</Danger>
                    )}
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    {this.selectToCities()}
                    {this.state.toCityState === 'Invalid' && (
                      <Danger>{this.state.toCityState}</Danger>
                    )}
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Truck Type"
                      id="credit-limit"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        disabled: true,
                        value: this.state.truckType,
                      }}
                      success={this.state.truckTypeState === 'success'}
                      error={this.state.truckTypeState === 'Invalid'}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Freight Value"
                      id="amountr"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'amount', 'greater'),
                        type: 'greater',
                        value: this.state.amount,
                      }}
                      success={this.state.amountState === 'success'}
                      error={this.state.amountState === 'Invalid'}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Commission"
                      id="commission"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'commission', 'number'),
                        type: 'number',
                        value: this.state.commission,
                      }}
                      success={this.state.commissionState === 'success'}
                      error={this.state.commissionState === 'Invalid'}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="ATH Receivable"
                      id="athReceivable"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'athReceivable', 'number'),
                        type: 'number',
                        value: this.state.athReceivable,
                      }}
                      success={this.state.athReceivableState === 'success'}
                      error={this.state.athReceivableState === 'Invalid'}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="BTH Receivable"
                      id="bthReceivable"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        disabled: true,
                        type: 'number',
                        value: this.state.bthReceivable,
                      }}
                      success={this.state.bthReceivableState === 'success'}
                      error={this.state.bthReceivableState === 'Invalid'}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="ATH Payable"
                      id="athPayable"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'athPayable', 'number'),
                        disabled: true,
                        type: 'number',
                        value: this.state.athPayable,
                      }}
                      success={this.state.athPayableState === 'success'}
                      error={this.state.athPayableState === 'Invalid'}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="BTH Payable"
                      id="bthPayable"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'bthPayable', 'number'),
                        type: 'number',
                        disabled: true,
                        value: this.state.bthPayable,
                      }}
                      success={this.state.bthPayableState === 'success'}
                      error={this.state.bthPayableState === 'Invalid'}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <InputLabel className={classes.label}>
                      Loading Date
                    </InputLabel>
                    <br />
                    <FormControl fullWidth>
                      <Datetime
                        onChange={this.loadingDateChange}
                        value={this.state.loadingDate}
                        timeFormat={false}
                        inputProps={{ placeholder: 'Select Date' }}
                      />
                    </FormControl>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6} />
                </GridContainer>
                {this.state.isLoading ? (
                  <CircularProgress
                    className={classes.progress}
                    style={{ color: purple[500] }}
                    thickness={7}
                  />
                ) : (
                  <Button
                    color="info"
                    onClick={this.submitTransaction}
                    className={classes.updateProfileButton}
                  >
                    Submit
                  </Button>
                )}
                <Clearfix />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
AddTransactions.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  userDetails: state.main.userDetails,
  isDetailedPage: state.transactions.isDetailedPage,
  transactions: state.transactions.transactions,
  systemDetails: state.main.systemDetails,
});

export default connect(mapStateToProps)(
  withStyles(AddTruckersStyle)(AddTransactions)
);
