/* eslint-disable react/destructuring-assignment */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { connect } from 'react-redux';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import PropTypes from 'prop-types';

// @material-ui/icons
import TransferWithinAStation from '@material-ui/icons/TransferWithinAStation';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Clearfix from 'components/Clearfix/Clearfix.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';

import SweetAlert from 'react-bootstrap-sweetalert';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import infoIcon from 'assets/img/infoIcon.svg';
import greenTick from 'assets/img/greenCheck.svg';

// Fireabse
import userProfileStyles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx';
import axios from 'axios';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import MultiStepCapture from './Section/MultiStepCapture';
import firebase from '../../config/config';
import FeatureFlagToastAlert from '../Components/FeatureFlagToastAlert';
import apiCaller from '../../utils/apiCallerOutstanding';
import Checkbox from '@material-ui/core/Checkbox';


const { REACT_APP_API_BASE_URL, REACT_APP_OFFICE_REPORTS_API } = process.env;

const db = firebase.firestore();
const auditLog = firebase.functions().httpsCallable('utilities-auditLog');

const panButton = {
  marginTop: '20px',
  marginLeft: '10px',
  height: '35px',
  width: '150px',
  borderRadius: '4px',
  border: '1px solid #8F95B2',
  background: 'transparent',
  color: '#474D66',
  fontWeight: '500',
};

const submitButton = {
  background: '#163BAC',
  color: 'white',
};

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" {...props} ref={ref} />
));

class AddTransporters extends React.Component {
  // function that returns true if value is there, false otherwise
  static verifyRequired(value) {
    if (value) {
      return true;
    }
    return false;
  }

  // function that returns true if value is Valid GST, false otherwise
  static verifyGst(value) {
    const gstRex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    if (gstRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that returns true if value is email, false otherwise
  static verifyEmail(value) {
    const emailRex = /[^\s@]+@[^\s@]+\.[^\s@]+/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if value contains only numbers
  static verifyNumber(value) {
    const numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if value contains PanCard
  static verifyPan(value) {
    const regpan = /(^([a-zA-Z]{5})([0-9]{4})([a-zA-Z]{1})$)/;
    if (regpan.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if value contains Tan
  static verifyTan(value) {
    const regtan = /(^([a-zA-Z]{4})([0-9]{5})([a-zA-Z]{1})$)/;
    if (regtan.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if value contains PanCard
  static verifyPhone(value) {
    const regPhone = /^[6789]\d{9}$/;
    if (regPhone.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  static verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      nameState: '',
      email: '',
      emailState: '',
      contactPerson: '',
      contactPersonState: '',
      phoneNumber: '',
      phoneNumberState: '',
      gstNumber: '',
      gstNumberState: '',
      panNumber: '',
      tanNumber: '',
      panNumberState: '',
      tanNumberState: '',
      isLoading: false,
      created: false,
      transporterId: '',
      errorMessage: '',
      validatingPan: false,
      duplicateTransporter: false,
      presentOrgs: [],
      disableSubmit: false,
      transporterTDSChecked: '',
      transporterTDSCheckedState: '',
      featureFlagFiles: '',
      panModal: false,
      panDetailData: {},
      showLoader: false,
      isChecked: false,
    };

    this.change = this.change.bind(this);
    this.submitTransporter = this.submitTransporter.bind(this);
    this.validateAllFields = this.validateAllFields.bind(this);
    this.validatePanNumber = this.validatePanNumber.bind(this);
    this.checkIfMultipleOrgs = this.checkIfMultipleOrgs.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.validPanDetails = this.validPanDetails.bind(this);
  }

  componentDidMount() {
    this.getFeatureFlagStatus();
  }

  getFeatureFlagStatus() {
    db.collection('FeatureFlags')
      .doc('FileUploadsFeature')
      .onSnapshot(doc => {
        if (doc.exists) {
          const data = doc.data();
          this.setState({ featureFlagFiles: data?.transporterFiles });
        }
      });
  }

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  async validatePanNumber(pan) {
    const { activeRole } = this.props;
    const role = activeRole === 'branch-ops' ? 'BranchOps' : activeRole;

    const currentActiveRole = `is${role.charAt(0).toUpperCase()}${role.slice(
      1
    )}`; // Capitalize first letter
    // Append 'is'
    this.setState({ validatingPan: true });
    const endPoint = `transporter/verify/pan?role=${currentActiveRole}&pan=${pan}`;
    const userDetails = firebase.auth().currentUser;
    const { Aa } = userDetails;
    const token = Aa;
    try {
      const response = await apiCaller(
        endPoint,
        {},
        `Bearer ${token}`,
        'nh-base',
        'get'
      );
      const isPanUnique = response?.data?.unique_pan_number;
      this.setState({ validatingPan: false });
      return !isPanUnique;
    } catch (error) {
      this.setState({ validatingPan: false });
      return false;
    }
  }

  async checkIfMultipleOrgs(number) {
    this.setState({ validatingPhone: true });
    const { activeRole } = this.props;
    const role = activeRole === 'branch-ops' ? 'BranchOps' : activeRole;
    const currentActiveRole = `is${role.charAt(0).toUpperCase()}${role.slice(
      1
    )}`;
    let status = false;
    const userDetails = firebase.auth().currentUser;
    const { Aa } = userDetails;
    const token = Aa;
    try {
      const endPoint = `transporter/verify/phone?role=${currentActiveRole}&phone=${number}`;

      const response = await apiCaller(
        endPoint,
        {},
        `Bearer ${token}`,
        'nh-base',
        'get'
      );

      const data = response?.data?.other_users;

      if (data.length > 0) {
        this.setState({ duplicateTransporter: true, presentOrgs: data });
      } else {
        this.setState({ duplicateTransporter: false });
        status = true;
      }
      this.setState({ validatingPhone: false });
    } catch (e) {
      this.setState({ duplicateTransporter: false });
      status = true;
      this.setState({ validatingPhone: false });
    }
    return status;
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'required':
        if (AddTransporters.verifyRequired(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'email':
        if (AddTransporters.verifyEmail(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'number':
        if (AddTransporters.verifyNumber(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'length':
        if (
          AddTransporters.verifyLength(event.target.value, stateNameEqualTo)
        ) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
        case 'nameViaPan':
          if (
            AddTransporters.verifyLength(event, stateNameEqualTo)
          ) {
            this.setState({
              [`${stateName}State`]: 'success',
              [stateName]: event,
            });
          } else {
            this.setState({
              [`${stateName}State`]: 'error',
              [stateName]: event,
            });
          }
          break
      case 'pan':
        if (AddTransporters.verifyPan(event.target.value)) {
          this.validatePanNumber(event.target.value).then(status => {
            if (status) {
              this.setState({
                [`${stateName}State`]: 'error',
                [stateName]: event.target.value,
                errorMessage: 'PAN already exists',
                disableSubmit: true,
              });
            } else {
              this.setState({
                [`${stateName}State`]: 'success',
                [stateName]: event.target.value,
                disableSubmit: false,
              });
            }
          });
          this.setState({
            [stateName]: event.target.value,
            disableSubmit: false,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
            errorMessage: '',
          });
        }
        break;
      case 'phone':
        if (AddTransporters.verifyPhone(event.target.value)) {
          this.checkIfMultipleOrgs(event.target.value);
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'gst':
        if (AddTransporters.verifyGst(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'tan':
        if (AddTransporters.verifyTan(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      default:
        break;
    }
  }

  // validates all required fields and returns a promise
  validateAllFields() {
    const {
      name,
      email,
      phoneNumber,
      contactPerson,
      panNumberState,
      panNumber,
      tanNumber,
    } = this.state;
    let error = false;
    return new Promise((resolve, reject) => {
      // Verify Name
      if (AddTransporters.verifyLength(name, 1)) {
        this.setState({
          nameState: 'success',
        });
      } else {
        this.setState({
          nameState: 'error',
        });
        error = true;
      }
      if (AddTransporters.verifyLength(contactPerson, 1)) {
        this.setState({
          contactPersonState: 'success',
        });
      } else {
        this.setState({
          contactPersonState: 'error',
        });
        error = true;
      }

      if (AddTransporters.verifyLength(email, 1)) {
        this.setState({
          emailState: 'success',
        });
      } else {
        this.setState({
          emailState: 'error',
        });
        // error = true;
      }
      if (AddTransporters.verifyLength(panNumber, 1)) {
        this.setState({
          panNumberState: 'success',
        });
      } else {
        this.setState({
          panNumberState: 'error',
          errorMessage: 'Please check PAN number',
        });
        error = true;
        // error = true;
      }

      if (this.state.transporterTDSChecked !== 'none') {
        if (AddTransporters.verifyTan(tanNumber)) {
          this.setState({
            tanNumberState: 'success',
          });
        } else {
          this.setState({
            tanNumberState: 'error',
            errorMessage: 'Please check TAN number',
          });
          error = true;
        }
      }

      if (!this.state.transporterTDSChecked) {
        this.setState({ transporterTDSCheckedState: 'error' });
        error = true;
      }

      // Verify Phone Number
      if (AddTransporters.verifyPhone(phoneNumber)) {
        this.setState({
          phoneNumberState: 'success',
        });
      } else {
        this.setState({
          phoneNumberState: 'error',
        });
        error = true;
      }
      if (panNumberState === 'error') {
        this.setState({
          errorMessage: 'Please check PAN number',
        });
        error = true;
      }
      if (!error) {
        resolve(true);
      } else {
        reject(new Error('validation Failed'));
      }
    });
  }

  checkIfTransporterExists() {
    return new Promise((resolve, reject) => {
      const { email, phoneNumber } = this.state;
      const emailQuery = db
        .collection('Transporters')
        .where('email', '==', email)
        .get();
      const phoneNumberQuery = db
        .collection('Transporters')
        .where('phoneNumber', '==', phoneNumber)
        .get();

      if (email)
        return Promise.all([emailQuery, phoneNumberQuery])
          .then(results => {
            if (results[0].empty && results[1].empty) {
              resolve(true);
            }
            reject(
              new Error('Transporter already exists with PhoneNumber or Email')
            );
          })
          .catch(() => {
            reject(new Error('Could not add Transporter. Internal Error.'));
          });
      //  Else check only phone number if email is empty
      return phoneNumberQuery
        .then(result => {
          if (result.empty) {
            resolve(true);
          }
          reject(new Error('Transporter already exists.'));
        })
        .catch(() => {
          reject(new Error('Could not add Transporter. Internal Error.'));
        });
    });
  }

  handleCheck = event => {
    this.setState({ transporterTDSChecked: event.target.value });
  };

  /**
   * Submits the transporter details to the Database
   * @function
   */
  submitTransporter() {
    const { classes, userDetails, systemDetails, activeRole } = this.props;
    const role = activeRole === 'branch-ops' ? 'BranchOps' : activeRole;
    const currentActiveRole = `is${role.charAt(0).toUpperCase()}${role.slice(
      1
    )}`;

    this.setState({
      isLoading: true,
    });
    this.validateAllFields()
      .then(success => {
        if (success) {
          const {
            name,
            email,
            contactPerson,
            phoneNumber,
            gstNumber,
            panNumber,
            tanNumber,
            transporterTDSChecked,
          } = this.state;

          const user = firebase.auth().currentUser;

          const { Aa } = user;
          const token = Aa;
          const body = {
            name: contactPerson,
            company_name: name,
            phone: phoneNumber,
            email,
            gst_number: gstNumber,
            pan_number: panNumber,
            tan_number: tanNumber,
            tds_deduction: transporterTDSChecked,
            role: currentActiveRole,
          };
          const endPoint = 'transporter/add-transporter';
          apiCaller(endPoint, body, `Bearer ${token}`, 'nh-base')
            .then(response => {
              const transporterId = response?.data?.transporter_id;
              localStorage.setItem('response', JSON.stringify(response));
              this.setState({ transporterId });
              const auditBody = {
                data: {
                  name,
                  email,
                  contactPerson,
                  phoneNumber,
                  gstNumber,
                  panNumber,
                  tanNumber,
                  status: 'pending',
                },
                collection: 'Transporters',
                updatedBy: userDetails.uid,
                systemDetails,
                type: 'Add Transporter',
                message: `${userDetails.email} created Transporter with name ${name}`,
              };
              auditLog(auditBody);
              this.setState({ isLoading: false });
              this.setState({ created: true });
            })
            .catch(err => {
              const errorBody = {
                message: `${userDetails.email} tried to create Transporter with name ${name} but FAILED`,
                status: 'fail',
                error: err,
                updatedBy: userDetails.uid,
                errorMessage: err.message,
                collection: 'Transporters',
                systemDetails,
              };
              auditLog(errorBody);
              this.setState({ isLoading: false });
            });
        }
      })
      .catch(err => {
        // console.log(err);
        this.setState({
          isLoading: false,
        });
      });
  }

  /**
   * Will Clears ALl the State values after form submision
   * @function
   */
  clearForm() {
    const { classes } = this.props;
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title="Good job!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={`${classes.button} ${classes.success}`}
        >
          Transporter Added Succeessfully
        </SweetAlert>
      ),
    });
    this.setState({
      name: '',
      nameState: '',
      email: '',
      emailState: '',
      contactPerson: '',
      phoneNumber: '',
      phoneNumberState: '',
      gstNumber: '',
      gstNumberState: '',
      panNumber: '',
      panNumberState: '',
      tanNumber: '',
      tanNumberState: '',
      presentOrgs: [],
    });
  }

  handleClickOpenModal(modal) {
    const x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleCloseModal(modal) {
    const x = [];
    x[modal] = false;
    this.setState(x);
  }

  handleCheckboxChange = (event) => {
    this.setState({ isChecked: event.target.checked });
  };

  handleNameChange = (event) => {
    this.change(event, 'name', 'nameViaPan', 1)
    this.handleCloseModal('panModal')
  };

  async validPanDetails(num) {
    this.setState({
      showLoader: true,
    });
    this.handleClickOpenModal('panModal');
    const userDetails = firebase.auth().currentUser;
    const { Aa: token } = userDetails;

    const endPoint = `pipe/pan-details?pan_number=${num}`;

    try {
      const response = await apiCaller(
        endPoint,
        {},
        `Bearer ${token}`,
        'nh-base',
        'get'
      );
      const value = response?.data;
      this.setState({
        showLoader: false,
        panDetailData: value,
      });
    } catch (e) {
      console.log('Error', e);
    }
  }

  showPanDialog() {
    const { classes } = this.props;
    const { panModal, panDetailData, showLoader, isChecked } = this.state;
    const isPanDetailDataEmpty =
      panDetailData?.pan_status === '' ||
      panDetailData?.pan_status === 'INVALID';

    return (
      <Dialog
        classes={{
          root: `${classes.center}`,
        }}
        fullWidth
        maxWidth="sm"
        open={panModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.handleCloseModal('panModal')}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div className={classes.modalHeaderContent}>
            <h4 className={classes.modalTitle}>PAN Validation Details</h4>
          </div>
        </DialogTitle>

        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {showLoader ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <CircularProgress />
            </div>
          ) : isPanDetailDataEmpty ? (
            <div
              style={{
                backgroundColor: '#FDE8E8',
                border: '1px solid #d32f2f',
                color: 'black',
                padding: '10px',
                borderRadius: '5px',
              }}
            >
              <img
                style={{
                  paddingRight: '10px',
                }}
                src={infoIcon}
                alt=""
              />
              Invalid! PAN number or details does not exist
            </div>
          ) : (
            <div>
              {/* Render the PAN detail data */}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                }}
              >
                <p>Verified Name</p>
                <p style={{ fontWeight: 'bold' }}>
                  {panDetailData?.verified_name}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                }}
              >
                <p>Name on PAN</p>
                <p style={{ fontWeight: 'bold' }}>
                  {panDetailData?.name_pan_card}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                }}
              >
                <p>DOB</p>
                <p style={{ fontWeight: 'bold' }}>
                  {panDetailData?.pan_date_of_birth}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                }}
              >
                <p>PAN Type</p>
                <p style={{ fontWeight: 'bold' }}>{panDetailData?.pan_type}</p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                }}
              >
                <p>PAN Status</p>
                <p style={{ fontWeight: 'bold' }}>
                  {panDetailData?.pan_status}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                }}
              >
                <p>Aadhaar Linked</p>
                <p style={{ fontWeight: 'bold', color: 'green' }}>
                  {panDetailData?.pan_aadhaar_linked && (
                    <img src={greenTick} alt="icon" />
                  )}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                }}
              >
                <p>Gender</p>
                <p style={{ fontWeight: 'bold' }}>
                  {panDetailData?.pan_gender}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                }}
              >
                <p>PAN Email</p>
                <p style={{ fontWeight: 'bold' }}>{panDetailData?.pan_email}</p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                }}
              >
                <p>Address State</p>
                <p style={{ fontWeight: 'bold' }}>
                  {panDetailData?.pan_address?.city}
                  {panDetailData?.pan_address?.state}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                }}
              >
                <p>Address Pincode</p>
                <p style={{ fontWeight: 'bold' }}>
                  {panDetailData?.pan_address?.pincode}
                </p>
              </div>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Checkbox checked={isChecked} onChange={this.handleCheckboxChange}/>
                <p style={{ margin: 0 }}>Update Transporter Name as per PAN</p>
              </div>
            </div>
          )}
        </DialogContent>

        <DialogActions className={classes.modalFooter}>
          <Button onClick={() => this.handleCloseModal('panModal')}>
            Close
          </Button>
          <Button style={submitButton} disabled={!isChecked}  onClick={() => this.handleNameChange(panDetailData?.name_pan_card)}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    const { classes, activeRole } = this.props;
    const {
      isLoading,
      alert,
      name,
      nameState,
      contactPerson,
      contactPersonState,
      phoneNumber,
      phoneNumberState,
      email,
      emailState,
      gstNumber,
      gstNumberState,
      panNumber,
      panNumberState,
      tanNumber,
      tanNumberState,
      created,
      transporterId,
      errorMessage,
      presentOrgs,
      validatingPhone,
      validatingPan,
      disableSubmit,
      featureFlagFiles,
      transporterTDSCheckedState,
    } = this.state;
    return (
      <div>
        {featureFlagFiles === 1 && <FeatureFlagToastAlert />}
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            {alert}
            <Card>
              <CardHeader className={`${classes.cardHeader}`} color="warning">
                <CardIcon color="primary" className={`${classes.cardHeader}`}>
                  <TransferWithinAStation />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  Add Transporter
                  <small />
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Transporter Name *"
                      id="username"
                      formControlProps={{ fullWidth: true }}
                      success={nameState === 'success'}
                      error={nameState === 'error'}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'name', 'length', 1),
                        type: 'length',
                        value: name,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Contact Person *"
                      id="contact-person"
                      formControlProps={{ fullWidth: true }}
                      success={contactPersonState === 'success'}
                      error={contactPersonState === 'error'}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'contactPerson', 'length', 0),
                        type: 'length',
                        value: contactPerson,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <CustomInput
                        labelText="Phone Number *"
                        id="phone-number"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          onChange: event =>
                            this.change(event, 'phoneNumber', 'phone'),
                          type: 'phone',
                          value: phoneNumber,
                        }}
                        success={phoneNumberState === 'success'}
                        error={phoneNumberState === 'error'}
                      />
                      {validatingPhone ? <CircularProgress /> : null}
                    </div>
                    <div>
                      {presentOrgs.length > 0 ? (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',

                            flexWrap: 'wrap',
                          }}
                        >
                          <Typography
                            variant="caption"
                            gutterBottom
                            color="primary"
                          >
                            Org already exists with Phone Number &nbsp;
                          </Typography>
                          <Typography
                            variant="caption"
                            gutterBottom
                            color="primary"
                          >
                            (
                          </Typography>
                          {presentOrgs?.map((org, index) => (
                            <Typography
                              variant="caption"
                              gutterBottom
                              color="primary"
                              style={{
                                marginRight: '5px',
                                textDecoration: org?.is_blacklisted
                                  ? 'line-through'
                                  : 'none',
                              }}
                            >
                              {org?.name},
                            </Typography>
                          ))}
                          <Typography
                            variant="caption"
                            gutterBottom
                            color="primary"
                          >
                            )
                          </Typography>
                        </div>
                      ) : null}
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      success={emailState === 'success'}
                      error={emailState === 'error'}
                      labelText="Email address "
                      id="email-address"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event => this.change(event, 'email', 'email'),
                        type: 'email',
                        value: email,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="GST Number "
                      id="gst-number"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'gstNumber', 'gst'),
                        type: 'gst',
                        value: gstNumber,
                      }}
                      success={gstNumberState === 'success'}
                      error={gstNumberState === 'error'}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <CustomInput
                        labelText="PAN Number *"
                        id="pan-number"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          onChange: event =>
                            this.change(event, 'panNumber', 'pan'),
                          type: 'pan',
                          value: panNumber,
                        }}
                        success={panNumberState === 'success'}
                        error={panNumberState === 'error'}
                      />

                      {validatingPan ? <CircularProgress /> : null}
                      <Button
                        style={panButton}
                        disabled={!panNumber}
                        onClick={() =>
                          this.validPanDetails(
                            panNumber
                          )
                        }
                      >
                        Pan Valid Details
                      </Button>
                    </div>
                    {panNumberState === 'error' ? (
                      <p style={{ color: 'red' }}>{errorMessage}</p>
                    ) : (
                      ''
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <FormControl
                      component="fieldset"
                      error={transporterTDSCheckedState === 'error'}
                    >
                      <FormLabel component="legend">TDS Deduction</FormLabel>
                      <RadioGroup
                        aria-label="TDS Deduction"
                        name="TDS Deduction"
                        value={this.state.transporterTDSChecked}
                        onChange={this.handleCheck}
                        row
                      >
                        <FormControlLabel
                          value="lobb"
                          control={<Radio size="small" />}
                          label={
                            <Typography variant="caption">
                              Deducted to Lobb
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value="trucker"
                          control={<Radio size="small" />}
                          label={
                            <Typography variant="caption">
                              Deducted to Trucker
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value="none"
                          control={<Radio size="small" />}
                          label={
                            <Typography variant="caption">
                              Deducted to None
                            </Typography>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={`TAN Number${
                        this.state.transporterTDSChecked !== 'none' ? ' *' : ''
                      }`}
                      id="tan-number"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'tanNumber', 'tan'),
                        type: 'tan',
                        value: tanNumber,
                      }}
                      success={tanNumberState === 'success'}
                      error={tanNumberState === 'error'}
                    />
                  </GridItem>
                </GridContainer>
                {isLoading ? (
                  <CircularProgress
                    className={classes.progress}
                    style={{ color: purple[500] }}
                    thickness={7}
                  />
                ) : (
                  <Button
                    color="warning"
                    onClick={this.submitTransporter}
                    className={classes.updateProfileButton}
                    disabled={disableSubmit || featureFlagFiles === 1}
                  >
                    Next
                  </Button>
                )}
                <Clearfix />
              </CardBody>
              {created ? (
                <MultiStepCapture
                  transporterId={transporterId}
                  created={created}
                  activeRole={activeRole}
                  clearform={() => {
                    this.setState({ created: false });
                    this.clearForm();
                  }}
                  featureFlagFiles={featureFlagFiles}
                />
              ) : (
                ''
              )}
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            {this.showPanDialog()}
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
AddTransporters.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  userDetails: state.main.userDetails,
  systemDetails: state.main.systemDetails,
  activeRole: state.main.activeRole,
});

export default connect(mapStateToProps)(
  withStyles(userProfileStyles)(AddTransporters)
);
