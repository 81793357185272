import { cardTitle } from 'assets/jss/material-dashboard-pro-react.jsx';
import customSelectStyle from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle';
import purple from '@material-ui/core/colors/purple';

const AddTruckersStyle = theme => ({
  ...customSelectStyle,
  ...sweetAlertStyle,
  cardTitle: {
    fontFamily: 'Rajdhani',
    ...cardTitle,
  },
  cardIconTitle: {
    ...cardTitle,
    fontFamily: 'Rajdhani',
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400',
    },
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center',
  },
  description: {
    fontFamily: 'Rajdhani',
    color: '#999999',
  },
  updateProfileButton: {
    float: 'right',
  },
  rajdhaniFont: {
    fontFamily: 'Rajdhani',
  },
  labelText: {
    color: '#777575 !important',
    fontSize: '0.86rem',
  },
  selectLabel: {
    cursor: 'pointer',
    paddingLeft: '0',
    color: 'rgba(0, 0, 0, 0.56)',
    fontSize: '14px',
    lineHeight: '1.428571429',
    fontWeight: '400',
    display: 'inline-flex',
  },
  selectLabelSearch: {
    color: 'rgba(0, 0, 0, 0.4)',
    fontSize: '0.7rem',
    lineHeight: '1.428571429',
    display: 'inline-flex',
  },
  colorSwitchBase: {
    color: purple[300],
    '&$colorChecked': {
      color: purple[500],
      '& + $colorBar': {
        backgroundColor: purple[500],
      },
    },
  },
  colorBar: {},
  colorChecked: {},
  iOSSwitchBase: {
    '&$iOSChecked': {
      color: theme.palette.common.white,
      '& + $iOSBar': {
        backgroundColor: '#52d869',
      },
    },
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.sharp,
    }),
  },
  iOSChecked: {
    transform: 'translateX(15px)',
    '& + $iOSBar': {
      opacity: 1,
      border: 'none',
    },
  },
  iOSBar: {
    borderRadius: 13,
    width: 42,
    height: 26,
    marginTop: -13,
    marginLeft: -21,
    border: 'solid 1px',
    borderColor: theme.palette.grey[400],
    backgroundColor: '#FCD21F',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  iOSIcon: {
    width: 24,
    height: 24,
  },
  iOSIconChecked: {
    boxShadow: theme.shadows[1],
  },
});
export default AddTruckersStyle;
