/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Snackbar from '@material-ui/core/Snackbar';
import axios from 'axios';
import swal from 'sweetalert';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import moment from 'moment';
import ReactSelect from 'react-select';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Tooltip } from '@material-ui/core';
import firebase from '../../../config/config';

const { REACT_APP_OFFICE_REPORTS_API } = process.env;
const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const useStyles = makeStyles(theme => ({
  downloadButton: {
    backgroundColor: '#3366FF',
    color: 'white',
    fontSize: '12px',
    textTransform: 'capitalize',
    marginLeft: '15px',
  },
  dialogModal: { height: '400px', margin: '10px 30px', padding: '10px' },
  filters: { marginBottom: '10px' },
}));

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function CustomizedDialogs({
  transporters,
  truckers,
  riskStatusOptions,
  activeRole,
}) {
  const [open, setOpen] = React.useState(false);
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [fetching, setFetching] = React.useState(false);
  const [token, setToken] = React.useState('');
  const [startDate, setStartDate] = React.useState('');
  const [endDate, setEndDate] = React.useState('');
  const [selectedDates, setSelectedDates] = React.useState({
    startDate: new Date(''),
    endDate: '',
  });
  const [selectedStartDate, setSelectedStartDate] = React.useState(null);
  const [maxDateRange, setMaxDateRange] = React.useState();
  const [selectedEndDate, setSelectedEndDate] = React.useState(null);
  const [selectedTrucker, setSelectedTrucker] = React.useState();
  const [selectedTransporter, setSelectedTransporter] = React.useState();
  const [riskStatus, setRiskStatus] = React.useState();

  function formatDate(date) {
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [year, month, day].join('-');
  }
  const handleStartDateChange = date => {
    setSelectedEndDate('');
    setEndDate('');

    let maxRange = '';
    if (activeRole === 'sales' || activeRole === 'traffic') {
      const c = new Date(date);
      const today = new Date();
      maxRange = c.setDate(c.getDate() + 30);
      const setRange = new Date(maxRange);
      if (setRange > today) {
        setMaxDateRange(today);
      } else {
        setMaxDateRange(maxRange);
      }
    } else {
      const today = new Date();
      const c = new Date(date);
      maxRange = c.setDate(c.getDate() + 60);
      const setRange = new Date(maxRange);
      if (setRange > today) {
        setMaxDateRange(today);
      } else {
        setMaxDateRange(maxRange);
      }
    }
    setSelectedStartDate(formatDate(date));
    const formattedDate = moment(date).format('DD/MM/YYYY');

    setSelectedDates({ ...selectedDates, startDate: formattedDate });
    setStartDate(date);
  };

  const handleEndDateChange = date => {
    setSelectedEndDate(formatDate(date));
    const formattedDate = moment(date).format('DD/MM/YYYY');
    setEndDate(date);
    setSelectedDates({ ...selectedDates, endDate: formattedDate });
  };
  const classes = useStyles();

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBar(false);
  };
  const handleTrucker = value => {
    setSelectedTrucker(value);
  };
  const handleTransporter = value => {
    setSelectedTransporter(value);
  };
  const handleRiskStatus = value => {
    setRiskStatus(value);
  };
  const getDownloadFile = async () => {
    setFetching(true);
    const endPoint = '/get-transaction-download';
    const body = {
      loadingStartDate: selectedStartDate,
      loadingEndDate: selectedEndDate,
      transporterName: selectedTransporter?.name || '',
      truckerName: selectedTrucker?.name || '',
      riskStatus: riskStatus?.name.toLowerCase() || '',
      userType: activeRole === 'sales' ? 'salesManager' : 'ops',
    };
    try {
      const response = await axios.post(
        `${REACT_APP_OFFICE_REPORTS_API}${endPoint}`,
        body,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const Url = response.data;
      window.open(Url, '_blank');
      setSelectedDates({ startDate: '', endDate: '' });
      setStartDate('');
      setSelectedStartDate('');
      setSelectedEndDate('');
      setEndDate('');
      swal('Success!', 'Your file has been downloaded!', 'success');
      setFetching(false);
    } catch (e) {
      setOpen(true);
      swal('Oops!', 'No Data Found !', 'error');
      setFetching(false);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    const userDetails = firebase.auth().currentUser;
    const { Aa } = userDetails;
    setToken(Aa);
  }, []);
  return (
    <div>
      <Tooltip title="Download Transaction Excel">
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={handleClickOpen}
          className={classes.downloadButton}
        >
          Download
        </Button>
      </Tooltip>

      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose} />
        <DialogContent
          style={{ padding: '10px' }}
          className={classes.dialogModal}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ textAlign: 'center', margin: '30px 0px' }}>
              <Typography
                variant="h6"
                style={{ textTransform: 'capitalize', fontSize: '24px' }}
              >
                Statement Download
              </Typography>
            </div>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6} className={classes.filters}>
                    <KeyboardDatePicker
                      inputVariant="outlined"
                      disableToolbar
                      error={false}
                      format="dd/MM/yyyy"
                      helperText={null}
                      margin="normal"
                      id="date-picker-inline"
                      size="small"
                      placeholder="Select Start Date"
                      value={startDate}
                      onChange={handleStartDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      maxDate={new Date()}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6} className={classes.filters}>
                    <KeyboardDatePicker
                      inputVariant="outlined"
                      disableToolbar
                      error={false}
                      format="dd/MM/yyyy"
                      helperText={null}
                      margin="normal"
                      id="date-picker-inline"
                      size="small"
                      placeholder="Select End Date"
                      value={endDate}
                      onChange={handleEndDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      maxDate={maxDateRange}
                      minDate={selectedStartDate}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6} className={classes.filters}>
                    <ReactSelect
                      value={selectedTransporter}
                      onChange={handleTransporter}
                      options={transporters}
                      placeholder="Select Transporter"
                      isClearable
                    />
                  </Grid>
                  <Grid item xs={12} lg={6} className={classes.filters}>
                    <ReactSelect
                      value={selectedTrucker}
                      onChange={handleTrucker}
                      options={truckers}
                      placeholder="Select Trucker"
                      isClearable
                    />
                  </Grid>
                  <Grid item xs={12} lg={6} className={classes.filters}>
                    <ReactSelect
                      value={riskStatus}
                      onChange={handleRiskStatus}
                      options={riskStatusOptions}
                      placeholder="Select Risk Status"
                    />
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '30px',
            }}
          >
            {fetching ? (
              <CircularProgress />
            ) : (
              <Button
                className={classes.downloadButton}
                onClick={getDownloadFile}
                disabled={startDate === '' || endDate === ''}
              >
                Download Statement
              </Button>
            )}
          </div>
        </DialogContent>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={openSnackBar}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        message="No Data Found For selected dates"
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseSnackbar}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}
