/* eslint-disable react/jsx-no-bind */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import moment from 'moment';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import myFirebase from 'firebase';
import Paper from '@material-ui/core/Paper';
import { Button } from '@material-ui/core';
import firebase from '../../../config/config';
import {
  deleteFileHandler,
  fileUploader,
  getSignedURL,
  getViewURL,
} from '../../../utils/fileUploadHandler';
import convertTimestampToDate from '../../../utils/convertTimestampToDate';
import FeatureFlagEmptyState from '../../Components/FeatureFlagEmptyState';

const useStyles = makeStyles({
  box: {
    // width: 400,
    // height: 200,
    // marginTop: 30,
    borderRadius: '5px',
    textAlign: 'left',
    border: '2px dashed',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    border: '2px dashed lightgray',
    borderRadius: '5px',
    margin: '14px 20px',
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    // padding: '10px',
  },
  viewButton: {
    background: '#52BE56',
    color: 'white',
    margin: '2px 20px 0px 0px',
    padding: '5px 42px 5px 10px',
  },
  nameTabs: {
    display: 'flex',
    flexDirection: 'column',
  },
  promptBox: {
    display: 'flex',
    // flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  alert: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  deleteModalButton: {
    width: '45%',
    background: '#52BE56',
    borderRadius: 0,
    color: 'white',
    margin: '5px',
  },
  cancelButton: {
    width: '45%',
    background: 'lightgray',
    borderRadius: 0,
    margin: '5px',
  },
});
const contentStyle = { width: '24%' };

const inlineCheckIconStyle = {
  marginLeft: '10px',
  color: '#52BE56',
};

const nameBoxStyles = {
  lineHeight: '10px',
  margin: '10px 0px 0px 20px',
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const paperStyles = { marginTop: '20px', padding: '10px' };
const GSTFileUpload = ({
  activeRole,
  transporterId,
  multiStep,
  featureFlagFiles,
}) => {
  const [user, setUser] = React.useState();
  const [viewData, setViewData] = useState({ name: '', date: '' });
  const [url, setUrl] = useState('');
  const [success, setSuccess] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [deleted, setDeleted] = React.useState(false);
  // const { transporterId } = transporterData;
  const db = firebase.firestore();
  const auditLog = firebase.functions().httpsCallable('utilities-auditLog');
  const classes = useStyles();

  const handleFileUploadToS3 = UploadedFile => {
    const body = {
      scope: 'transporter',
      type: 'signed_url',
      file_type: 'transporter-gst',
      transporter_id: transporterId,
    };
    // signedURL
    getSignedURL(body, UploadedFile)
      .then(response => {
        const { url: signedURL } = response?.data;
        fileUploader(signedURL, UploadedFile)
          .then(res => {
            if (res === 'success') {
              const viewURLBody = {
                scope: 'transporter',
                type: 'view',
                file_type: 'transporter-gst',
                transporter_id: transporterId,
              };
              setTimeout(() => {
                getViewURL(viewURLBody)
                  .then(result => {
                    setUrl(result?.data?.url);
                    const formattedDate = convertTimestampToDate(
                      result?.data?.updated_at
                    );
                    setViewData({
                      name: result?.data?.uploaded_by,
                      date: formattedDate,
                    });
                  })
                  .catch(e => setOpen(false));
              }, 4000);
            }
          })
          .catch(e => setOpen(false));
      })
      .catch(e => setOpen(false));
  };
  const onDrop = async acceptedFiles => {
    setOpen(!open);
    // Do something with the files
    const UploadedFile = acceptedFiles[0];
    if (featureFlagFiles === 2) {
      handleFileUploadToS3(UploadedFile);
    } else if (featureFlagFiles === 0) {
      const podRef = await db.collection('FileUploads');
      const storageRef = myFirebase
        .storage()
        .ref(`Transporter_File/GST/${transporterId}`);
      const task = storageRef.put(UploadedFile);
      task.on(
        'state_changed',
        snapshot => {
          const percentage =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          if (percentage === 100) {
            // console.log('SNAPSHOT', snapshot.metadata());
            setOpen(false);
          }
        },
        error => {
          console.log(error);
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          task.snapshot.ref.getDownloadURL().then(downloadURL => {
            podRef
              .doc('TransporterFiles')
              .collection('GST')
              .doc(transporterId)
              .set(
                {
                  GSTUpdatedByName: user.displayName,
                  GSTUpdatedByUid: user.uid,
                  GSTUpdatedAT: moment().format('llll'),
                  GSTFileUploadURL: downloadURL,
                },
                { merge: true }
              );
            setUrl(downloadURL);

            setViewData({
              name: user.displayName,
              date: moment().format('llll'),
            });
            const auditBody = {
              // data: { ...transaction },
              collection: 'file uploads',
              gstFileUploadedBy: user.uid,
              type: 'Uploaded PAN File in Transporters',
              message: `${user.email} in file uploads collection inside transporterfile doc inside GST collection with ${transporterId} transporter ID uploaded the file `,
              uploadedAt: moment().format('llll'),
            };
            auditLog(auditBody);
            setSuccess(true);
          });
        }
      );
    }
  };

  async function DeleteGSTFileFromS3() {
    setOpen(true);
    const body = {
      scope: 'transporter',
      type: 'delete',
      file_type: 'transporter-gst',
      transporter_id: transporterId,
    };
    try {
      await deleteFileHandler(body);

      setOpen(false);
      setDeleted(true);
      setUrl('');
      const auditBody = {
        // data: { ...transaction },
        collection: 'file uploads',
        lrFileDeletedBy: user.uid,
        type: 'Deleted LR File in Transporters',
        message: `${user.email} in file uploads collection inside transporterfile doc inside LR collection with ${transporterId} transporter ID deleted the file `,
        deletedAt: new Date(),
      };
      auditLog(auditBody);
    } catch (e) {
      console.log(e);
    }
  }
  async function DeleteLRFile() {
    if (featureFlagFiles === 2) {
      DeleteGSTFileFromS3();
    } else if (featureFlagFiles === 0) {
      setOpen(true);
      const storageRef = myFirebase
        .storage()
        .ref('Transporter_File/GST')
        .child(transporterId);
      storageRef.delete().then(() => {
        const podRef = db.collection('FileUploads');
        podRef
          .doc('TransporterFiles')
          .collection('GST')
          .doc(transporterId)
          .delete();

        const auditBody = {
          // data: { ...transaction },
          collection: 'file uploads',
          gstFileDeletedBy: user.uid,
          type: 'Deleted GST File in Transporters',
          message: `${user.email} in file uploads collection inside transporterfile doc inside GST collection with ${transporterId} transporter ID deleted the file `,
          deletedAt: new Date(),
        };
        auditLog(auditBody);
        setOpen(false);
        setDeleted(true);
        setUrl('');
        //   window.location.reload(false);
      });
    }
  }

  function handleClose() {
    setDeleted(false);
    setSuccess(false);
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    acceptedFiles: {
      'image/png': ['.png', '.jpeg', '.jpg'],
      'application/pdf': ['.pdf'],
    },
  });
  const viewGST = () => {
    window.open(url);
  };

  function getGSTDataFromS3() {
    const viewURLBody = {
      scope: 'transporter',
      type: 'view',
      file_type: 'transporter-gst',
      transporter_id: transporterId,
    };
    getViewURL(viewURLBody)
      .then(result => {
        setUrl(result?.data?.url);
        const formattedDate = convertTimestampToDate(result?.data?.updated_at);
        setViewData({
          name: result?.data?.uploaded_by,
          date: formattedDate,
        });
      })
      .catch(e => console.log('error', e));
  }
  function getGSTData() {
    if (featureFlagFiles === 2) {
      getGSTDataFromS3();
    } else if (featureFlagFiles === 0) {
      const docRef = db
        .collection('FileUploads')
        .doc('TransporterFiles')
        .collection('GST')
        .doc(transporterId);

      docRef
        .get()
        .then(doc => {
          if (doc.exists) {
            const data = doc.data();
            const { GSTFileUploadURL, GSTUpdatedAT, GSTUpdatedByName } = data;
            setUrl(GSTFileUploadURL);
            const formattedDate = convertTimestampToDate(GSTUpdatedAT);
            setViewData({
              name: GSTUpdatedByName,
              date: formattedDate,
            });
          }
        })
        .catch(error => {
          console.log('Error getting document:', error);
        });
    }
  }
  useEffect(() => {
    const userDetails = firebase.auth().currentUser;
    getGSTData();
    setUser(userDetails);
  }, [featureFlagFiles]);

  return (
    <Paper elevation={1} style={paperStyles}>
      {featureFlagFiles === 0 || featureFlagFiles === 2 ? (
        <>
          {activeRole == 'sales' ||
          activeRole == 'branch-ops' ||
          activeRole == 'traffic' ||
          multiStep ? (
            <>
              <div className={classes.headerWrapper}>
                <div className={classes.nameTabs}>
                  {' '}
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p
                      style={{
                        margin: '14px 20px',
                        textAlign: 'left',
                        fontSize: '26px',
                        fontWeight: 400,
                      }}
                    >
                      GST
                    </p>
                    {url !== '' ? (
                      <CheckCircleIcon style={inlineCheckIconStyle} />
                    ) : (
                      ''
                    )}
                  </div>
                  {url !== '' ? (
                    <div style={nameBoxStyles}>
                      <p style={{ fontWeight: 'bold' }}>{viewData.name}</p>
                      <p style={{ color: '#a9a9a9', fontSize: '10px' }}>
                        {viewData.date}
                      </p>
                    </div>
                  ) : (
                    ''
                  )}
                </div>

                {url !== '' ? (
                  <Box>
                    <Button
                      variant="contained"
                      color="success"
                      size="small"
                      className={classes.viewButton}
                      onClick={viewGST}
                      startIcon={
                        <VisibilityIcon style={{ marginRight: '23px' }} />
                      }
                    >
                      View
                    </Button>
                  </Box>
                ) : (
                  ''
                )}
              </div>

              <Box>
                {url == '' || url == undefined ? (
                  <Box className={classes.container}>
                    {open ? (
                      <CircularProgress
                        style={{ color: '#FCD21F', margin: '5px' }}
                        size={30}
                        thickness={8}
                      />
                    ) : (
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            marginTop: 5,
                          }}
                        >
                          <CloudUploadIcon />
                          <p style={{ fontSize: '10px', color: 'gray' }}>
                            Drag and Drop or Click here to upload
                          </p>
                        </div>
                      </div>
                    )}
                  </Box>
                ) : (
                  ''
                )}
              </Box>
            </>
          ) : activeRole == 'ops' || activeRole == 'finance' ? (
            <Box>
              {url == '' || url == undefined ? (
                <div>
                  <p
                    style={{
                      marginTop: '14px',
                      textAlign: 'left',
                      fontSize: '26px',
                      fontWeight: 400,
                    }}
                  >
                    GST
                  </p>
                  <p>No File Uploaded </p>
                </div>
              ) : (
                <>
                  <div className={classes.headerWrapper}>
                    <div className={classes.nameTabs}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p
                          style={{
                            margin: '14px 20px',
                            textAlign: 'left',
                            fontSize: '26px',
                            fontWeight: 400,
                          }}
                        >
                          GST
                        </p>
                        {url !== '' ? (
                          <CheckCircleIcon style={inlineCheckIconStyle} />
                        ) : (
                          ''
                        )}
                        {open ? (
                          <CircularProgress
                            style={{ color: '#FCD21F', margin: '5px' }}
                            size={30}
                            thickness={8}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                      {url !== '' ? (
                        <div style={nameBoxStyles}>
                          <p style={{ fontWeight: 'bold' }}>{viewData.name}</p>
                          <p style={{ color: '#a9a9a9', fontSize: '10px' }}>
                            {viewData.date}
                          </p>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                    {url !== '' ? (
                      <Box>
                        <div className={classes.promptBox}>
                          <Button
                            variant="contained"
                            color="success"
                            size="small"
                            className={classes.viewButton}
                            onClick={viewGST}
                            startIcon={
                              <VisibilityIcon style={{ marginRight: '23px' }} />
                            }
                          >
                            View
                          </Button>
                          {activeRole === 'ops' && (
                            <Popup
                              trigger={
                                <IconButton
                                  color="primary"
                                  aria-label="upload picture"
                                  component="span"
                                >
                                  <DeleteOutlineIcon
                                    // onClick={() => setView(true)}
                                    color="error"
                                  />
                                </IconButton>
                              }
                              modal
                              {...{
                                contentStyle,
                              }}
                            >
                              {close => (
                                <div
                                  style={{
                                    padding: '10px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                  }}
                                >
                                  <HighlightOffIcon
                                    fontSize="large"
                                    color="error"
                                  />
                                  <h4 style={{ fontWeight: 'bold' }}>
                                    Are You Sure?
                                  </h4>
                                  <p>
                                    If you proceed, you will not be able to
                                    recover it
                                  </p>
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      width: '100%',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <Button
                                      onClick={close}
                                      className={classes.cancelButton}
                                    >
                                      No
                                    </Button>
                                    <Button
                                      onClick={DeleteLRFile}
                                      className={classes.deleteModalButton}
                                    >
                                      Yes
                                    </Button>
                                  </div>
                                </div>
                              )}
                            </Popup>
                          )}
                        </div>
                      </Box>
                    ) : (
                      ''
                    )}
                  </div>
                </>
              )}
            </Box>
          ) : (
            ''
          )}
          <Snackbar
            open={deleted}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="error">
              Deleted File!
            </Alert>
          </Snackbar>
          <Snackbar
            open={success}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="success">
              Uploaded File!
            </Alert>
          </Snackbar>
        </>
      ) : featureFlagFiles === 1 ? (
        <FeatureFlagEmptyState color="black" />
      ) : null}
      {/* <div className={classes.box}> */}

      {/* </div> */}
    </Paper>
  );
};

export default GSTFileUpload;
