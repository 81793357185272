import React, { useState, useEffect } from 'react';
import Card from 'components/Card/Card.jsx';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import FitnessUploader from './FileTypes/FitnessUploader';
import InsuranceUploader from './FileTypes/InsuranceFileUploader';
import RCUploader from './FileTypes/RCUploader';
import InsuranceExpiry from './FileTypes/InsuranceExpiry';
import myFirebase from '../../../config/config';
import TruckImage from '../../../assets/icons/Truck.svg';

const headerTextTitleStyles = {
  margin: 10,
  fontSize: '16px',
  fontWeight: 'bold',
  color: 'white',
};

const db = myFirebase.firestore();
const TruckFilesUpload = ({
  truckersData,
  selectedTrucker,
  activeRole,
  userDetails,
  truck,
  featureFlagFiles,
}) => {
  const [rcFileUploaded, setRCFileUploaded] = useState(false);
  const { truckerId } = selectedTrucker;
  const headerStyles = {
    background: rcFileUploaded ? '#3DAC16' : '#FF7E03',
    borderRadius: '5px 5px 0px 0px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  };

  return (
    <Grid item xs={12} lg={4}>
      <Card
        style={{
          borderRadius: '5px',
          border: rcFileUploaded ? '2px solid #3DAC16' : '2px solid #FF7E03',
        }}
      >
        <div style={headerStyles}>
          <h3 style={headerTextTitleStyles}>{truck.data[0]}</h3>
        </div>
        <div style={{ padding: '10px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2} style={{ display: 'flow' }}>
                <Grid item>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={TruckImage}
                      alt="icon"
                      style={{ marginRight: '10px' }}
                    />
                    <Typography variant="body1">{truck.data[1]}</Typography>
                  </div>
                </Grid>
                <Grid item>
                  <RCUploader
                    selectedTrucker={selectedTrucker}
                    truckNumber={truck.data[0]}
                    activeRole={activeRole}
                    userDetails={userDetails}
                    rcFileUploaded={val => setRCFileUploaded(val)}
                    featureFlagFiles={featureFlagFiles}
                  />
                </Grid>

                <Grid item>
                  <FitnessUploader
                    selectedTrucker={selectedTrucker}
                    truckNumber={truck.data[0]}
                    activeRole={activeRole}
                    userDetails={userDetails}
                    featureFlagFiles={featureFlagFiles}
                  />
                </Grid>
                <Grid item>
                  <InsuranceUploader
                    selectedTrucker={selectedTrucker}
                    truckNumber={truck.data[0]}
                    activeRole={activeRole}
                    userDetails={userDetails}
                    featureFlagFiles={featureFlagFiles}
                  />
                </Grid>
                <Grid item>
                  <InsuranceExpiry
                    truckNumber={truck.data[0]}
                    activeRole={activeRole}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Card>
    </Grid>
  );
};

export default TruckFilesUpload;
