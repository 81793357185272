import { Card, Grid, CardContent } from '@material-ui/core';
import React from 'react';
import DriverRatingsCard from './DriverRatingsCard';
import MaterialHandlingRatingsCard from './MaterialHandlingRating';
import OnTimeDeliveryRatingsCard from './OnTimeDeliveryRating';
import PODAcknowledgementFileUpload from './PODAcknowledgementFileUpload';
import PODDocketNumber from './PODDocketNumber';

const NewFieldsContainer = ({
  activeRole,
  currentTransactionData,
  featureFlagFiles,
}) => (
  <Grid container spacing={2}>
    <Grid item xs={12} lg={12}>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={12}>
              <DriverRatingsCard
                activeRole={activeRole}
                currentTransactionData={currentTransactionData}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <OnTimeDeliveryRatingsCard
                activeRole={activeRole}
                currentTransactionData={currentTransactionData}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <MaterialHandlingRatingsCard
                activeRole={activeRole}
                currentTransactionData={currentTransactionData}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>

    <Grid item xs={12} lg={12}>
      <PODDocketNumber
        activeRole={activeRole}
        currentTransactionData={currentTransactionData}
      />
    </Grid>
    <Grid item xs={12} lg={12}>
      <PODAcknowledgementFileUpload
        activeRole={activeRole}
        currentTransactionData={currentTransactionData}
        featureFlagFiles={featureFlagFiles}
      />
    </Grid>
  </Grid>
);

export default NewFieldsContainer;
