/* eslint-disable radix */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-useless-return */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@material-ui/core';
import firebase from 'config/config';
import swal from 'sweetalert';

const buttonStyles = {
  background: '#3366FF',
  color: 'white',
  fontSize: '12px',
  textTransform: 'capitalize',
};
const editButtonStyles = {
  background: '#AFB2B7',
  color: 'black',
  fontSize: '12px',
  textTransform: 'capitalize',
};
const NumberOfLocationsInput = ({ activeRole, selectedTruckerData }) => {
  const db = firebase.firestore();
  const userDetails = firebase.auth().currentUser;
  const auditLog = firebase.functions().httpsCallable('utilities-auditLog');
  const [numberOfLocations, setNumberofLocations] = React.useState(
    selectedTruckerData?.numberOfLocations
  );
  const [isToBeEdited, setIstoBeEdited] = React.useState(
    selectedTruckerData?.numberOfLocations
  );
  const [updating, setUpdating] = React.useState(false);
  const handleTextChange = e => {
    const regex = /^[0-9]*$/;
    if (regex.test(e.target.value) && e.target.value.length <= 4) {
      setNumberofLocations(e.target.value);
    } else return;
  };
  const handleSubmitNumberOfLocations = () => {
    setUpdating(true);
    const truckerRef = db
      .collection('Truckers')
      .doc(selectedTruckerData.truckerId);

    // Set the "capital" field of the city 'DC'
    return truckerRef
      .update({
        numberOfLocations: parseInt(numberOfLocations),
        modifiedAt: new Date(),
      })
      .then(() => {
        setUpdating(false);
        swal(
          'Success!',
          `You have set ${numberOfLocations} as Number Of locations for  ${selectedTruckerData?.name} `,
          'success'
        );
        setIstoBeEdited(true);
        const auditBody = {
          collection: 'Truckers',
          numberOfLocationsSetBy: userDetails?.uid,
          type: 'Updated Number Of Locations in Truckers',
          message: `${userDetails?.email} in Truckers collection inside ${selectedTruckerData?.truckerId} set number of locations to ${numberOfLocations} `,
          uploadedAt: new Date(),
        };
        auditLog(auditBody);
      })
      .catch(error => {
        // The document probably doesn't exist.
        setUpdating(false);
        swal('Error!', `Something went wrong`, 'error');
      });
  };
  return (
    <div>
      <Typography
        variant="subtitle2"
        gutterBottom
        style={{ textTransform: 'capitalize', marginBottom: '10px' }}
      >
        Number of Locations
      </Typography>
      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          xs={activeRole === 'ops' || activeRole === 'finance' ? 10 : 12}
        >
          {activeRole === 'sales' ||
          activeRole === 'branch-ops' ||
          activeRole === 'traffic' ? (
            <TextField
              size="small"
              id="outlined-basic"
              variant="outlined"
              fullWidth
              value={numberOfLocations}
              disabled
            />
          ) : (
            <TextField
              size="small"
              id="outlined-basic"
              variant="outlined"
              value={numberOfLocations}
              fullWidth
              onChange={handleTextChange}
              disabled={isToBeEdited}
            />
          )}
        </Grid>
        {activeRole === 'ops' || activeRole === 'finance' ? (
          <>
            {isToBeEdited ? (
              <Grid item xs={2}>
                <Button
                  size="small"
                  variant="contained"
                  style={editButtonStyles}
                  onClick={() => {
                    setIstoBeEdited(false);
                  }}
                >
                  Edit
                </Button>
              </Grid>
            ) : (
              <Grid item xs={2}>
                {updating ? (
                  <CircularProgress size={20} />
                ) : (
                  <Button
                    size="small"
                    variant="contained"
                    style={buttonStyles}
                    onClick={handleSubmitNumberOfLocations}
                  >
                    Submit
                  </Button>
                )}
              </Grid>
            )}
          </>
        ) : null}
      </Grid>
    </div>
  );
};

export default NumberOfLocationsInput;
