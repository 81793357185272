/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const AdhaarFile = ({ url, type, UploadedBy }) => {
  const viewFile = () => {
    window.open(url);
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <div>
        <Typography variant="body1">{type}</Typography>
        <Typography variant="caption">Uploaded By : {UploadedBy}</Typography>
      </div>
      <Button
        variant="contained"
        color="success"
        size="small"
        disabled={url === ''}
        onClick={viewFile}
        startIcon={<VisibilityIcon />}
        style={{ fontSize: '10px' }}
      >
        View
      </Button>
    </div>
  );
};

export default AdhaarFile;
