/* eslint-disable react/jsx-no-bind */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Paper, Button, Box, CardContent } from '@material-ui/core';
import Popup from 'reactjs-popup';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CircularProgress from '@material-ui/core/CircularProgress';
import VisibilityIcon from '@material-ui/icons/Visibility';
import '../../../assets/scss/ReactPOPStyles.scss';
import 'reactjs-popup/dist/index.css';
import myFirebase from 'firebase';
import firebase from '../../../config/config';
import FeatureFlagEmptyState from '../../Components/FeatureFlagEmptyState';
import {
  deleteFileHandler,
  fileUploader,
  getSignedURL,
  getViewURL,
} from '../../../utils/fileUploadHandler';
import convertTimestampToDate from '../../../utils/convertTimestampToDate';

const useStyles = makeStyles(theme => ({
  paper: { padding: 15 },

  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    border: '2px dashed lightgray',
    borderRadius: '5px',
    margin: '30px 20px 14px',
  },

  viewButton: {
    background: '#52BE56',
    color: 'white',
    padding: '5px 42px 5px 10px',
  },

  deleteModalButton: {
    width: '45%',
    background: '#52BE56',
    borderRadius: 0,
    color: 'white',
    margin: '5px',
  },
  cancelButton: {
    width: '45%',
    background: 'lightgray',
    borderRadius: 0,
    margin: '5px',
  },

  viewContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 10,
  },
}));
const inlineCheckIconStyle = {
  marginLeft: '10px',
  color: '#52BE56',
};

const nameBoxStyles = {
  lineHeight: '10px',
  marginTop: '10px',
};
const contentStyle = { width: '30%' };
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const DrivingLicenseUploader = ({
  currentTransactionData,
  activeRole,
  stylesClass,
  featureFlagFiles,
}) => {
  const classes = useStyles();
  const [user, setUser] = React.useState();
  const [podData, setPodData] = React.useState({ name: '', date: '' });
  const [deleted, setDeleted] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [url, setUrl] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const db = firebase.firestore();
  const { transactionId, transactionSerial } = currentTransactionData;

  const handleFileUploadToS3 = UploadedFile => {
    const body = {
      scope: 'trip',
      type: 'signed_url',
      file_type: 'transaction-driving-license',
      transaction_id: currentTransactionData?.transactionId,
    };
    getSignedURL(body, UploadedFile)
      .then(response => {
        const { url: signedURL } = response?.data;
        fileUploader(signedURL, UploadedFile)
          .then(res => {
            if (res === 'success') {
              const viewURLBody = {
                scope: 'trip',
                type: 'view',
                file_type: 'transaction-driving-license',
                transaction_id: currentTransactionData?.transactionId,
              };

              setTimeout(() => {
                getViewURL(viewURLBody)
                  .then(result => {
                    setUrl(result?.data?.url);
                    const formattedDate = convertTimestampToDate(
                      result?.data?.updated_at
                    );
                    setPodData({
                      name: result?.data?.uploaded_by,
                      date: formattedDate,
                    });
                    setOpen(false);
                  })
                  .catch(e => {
                    setOpen(false);
                  });
              }, 4000);
            }
          })
          .catch(e => {
            setOpen(false);
          });
      })
      .catch(e => {
        setOpen(false);
      });
  };
  const onDrop = async acceptedFiles => {
    setOpen(!open);
    // Do something with the files
    const UploadedFile = acceptedFiles[0];
    if (featureFlagFiles === 2) {
      handleFileUploadToS3(UploadedFile);
    } else {
      const podRef = await db.collection('FileUploads');
      const storageRef = myFirebase
        .storage()
        .ref(`DrivingLicense/${transactionId}`);
      const task = storageRef.put(UploadedFile);
      task.on(
        'state_changed',
        snapshot => {
          const percentage =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          if (percentage === 100) {
            // console.log('SNAPSHOT', snapshot.metadata());
            setOpen(false);
          }
        },
        error => error,
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          task.snapshot.ref.getDownloadURL().then(downloadURL => {
            podRef
              .doc('TransactionFiles')
              .collection('DrivingLicense')
              .doc(transactionId)
              .set(
                {
                  DLUpdatedByName: user.displayName,
                  DLUpdatedByUid: user.uid,
                  DLUpdatedAT: moment().format('llll'),
                  DLFileUploadURL: downloadURL,
                  transactionSerial,
                },
                { merge: true }
              );
            setUrl(downloadURL);

            setPodData({
              name: user.displayName,
              date: moment().format('llll'),
            });
            const auditBody = {
              collection: 'file uploads',
              podFileUploadedBy: user.uid,
              type: 'Uploaded DrivingLicense File in Transactions',
              message: `${user.email} in file uploads collection inside transactionFiles doc inside DrivingLicense collection with ${transactionId} transporter ID uploaded the file `,
              uploadedAt: moment().format('llll'),
            };
            auditLog(auditBody);
            setSuccess(true);
          });
        }
      );
    }
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    disabled: currentTransactionData.void,
    acceptedFiles: {
      'image/png': ['.png', '.jpeg', '.jpg'],
      'application/pdf': ['.pdf'],
    },
  });
  function handleClose() {
    setDeleted(false);
    setSuccess(false);
  }
  function viewFIleUploaded() {
    window.open(url);
  }
  const auditLog = firebase.functions().httpsCallable('utilities-auditLog');

  async function deletFileFromS3() {
    setOpen(true);
    const body = {
      scope: 'trip',
      type: 'delete',
      file_type: 'transaction-driving-license',
      transaction_id: currentTransactionData?.transactionId,
    };
    try {
      await deleteFileHandler(body);

      setOpen(false);
      setDeleted(true);
      setUrl('');
      const auditBody = {
        collection: 'file uploads',
        podFileDeletedBy: user.uid,
        type: 'Deleted DrivingLicense File in Transactions',
        message: `${user.email} in file uploads collection inside transactionFiles doc inside DrivingLicense collection with ${transactionId} transporter ID deleted the file `,
        deletedAt: moment().format('llll'),
      };
      auditLog(auditBody);
    } catch (e) {
      console.log(e);
    }
  }
  async function deleteFIleUploaded() {
    if (featureFlagFiles === 2) {
      deletFileFromS3();
    } else {
      setOpen(true);
      const storageRef = myFirebase
        .storage()
        .ref('DrivingLicense')
        .child(transactionId);
      storageRef.delete().then(() => {
        const podRef = db.collection('FileUploads');
        podRef
          .doc('TransactionFiles')
          .collection('DrivingLicense')
          .doc(transactionId)
          .delete();
        const auditBody = {
          collection: 'file uploads',
          podFileDeletedBy: user.uid,
          type: 'Deleted DrivingLicense File in Transactions',
          message: `${user.email} in file uploads collection inside transactionFiles doc inside DrivingLicense collection with ${transactionId} transporter ID deleted the file `,
          deletedAt: moment().format('llll'),
        };
        auditLog(auditBody);
        setOpen(false);
        setDeleted(true);
        setUrl('');
      });
    }
  }

  function getFileDataFromS3() {
    const viewURLBody = {
      scope: 'trip',
      type: 'view',
      file_type: 'transaction-driving-license',
      transaction_id: currentTransactionData?.transactionId,
    };
    getViewURL(viewURLBody)
      .then(result => {
        setUrl(result?.data?.url);
        const formattedDate = convertTimestampToDate(result?.data?.updated_at);

        setPodData({
          name: result?.data?.uploaded_by,
          date: formattedDate,
        });
      })
      .catch(e => console.log('error', e));
  }

  async function getDataPODDATA() {
    if (featureFlagFiles === 2) {
      getFileDataFromS3();
    } else if (featureFlagFiles === 0) {
      const podRef = await db
        .collection('FileUploads')
        .doc('TransactionFiles')
        .collection('DrivingLicense')
        .doc(transactionId);
      podRef
        .get()
        .then(doc => {
          if (doc.exists) {
            const data = doc.data();
            const { DLFileUploadURL, DLUpdatedAT, DLUpdatedByName } = data;
            setUrl(DLFileUploadURL);
            const formattedDate = convertTimestampToDate(DLUpdatedAT);

            setPodData({
              name: DLUpdatedByName,
              date: formattedDate,
            });
          }
        })
        .catch(error => error);
    }
  }

  useEffect(() => {
    getDataPODDATA();
    const userDetails = firebase.auth().currentUser;
    setUser(userDetails);
  }, [featureFlagFiles]);

  return (
    <Paper className={classes.paper}>
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h3 className={stylesClass.classes.cardTitle}>Driving License</h3>
          {url !== '' ? <CheckCircleIcon style={inlineCheckIconStyle} /> : ''}
        </div>
      </div>
      {featureFlagFiles === 0 || featureFlagFiles === 2 ? (
        <>
          {activeRole == 'sales' ||
          activeRole == 'branch-ops' ||
          activeRole == 'traffic' ? (
            <>
              <CardContent style={{ margin: 0, padding: 0 }}>
                <div>
                  <Box>
                    {url == '' ? (
                      <Box className={classes.container}>
                        {open ? (
                          <CircularProgress
                            style={{ color: '#FCD21F', margin: '5px' }}
                            size={30}
                            thickness={8}
                          />
                        ) : (
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                marginTop: 5,
                              }}
                            >
                              <CloudUploadIcon />
                              <p style={{ fontSize: '10px', color: 'gray' }}>
                                Drag and Drop or Click here to upload
                              </p>
                            </div>
                          </div>
                        )}
                      </Box>
                    ) : (
                      <div className={classes.viewContainer}>
                        <div style={nameBoxStyles}>
                          <p style={{ fontWeight: 'bold' }}>{podData.name}</p>
                          <p style={{ color: '#a9a9a9', fontSize: '12px' }}>
                            {podData.date}
                          </p>
                        </div>

                        <Box>
                          <Button
                            variant="contained"
                            color="success"
                            size="small"
                            className={classes.viewButton}
                            onClick={viewFIleUploaded}
                            startIcon={
                              <VisibilityIcon style={{ marginRight: '23px' }} />
                            }
                          >
                            View
                          </Button>
                        </Box>
                      </div>
                    )}
                  </Box>
                </div>
              </CardContent>
            </>
          ) : (
            ''
          )}
          {activeRole == 'ops' ? (
            <Box>
              {url !== '' ? (
                <div className={classes.viewContainer}>
                  <div style={nameBoxStyles}>
                    <p style={{ fontWeight: 'bold' }}>{podData.name}</p>
                    <p style={{ color: '#a9a9a9', fontSize: '12px' }}>
                      {podData.date}
                    </p>
                  </div>

                  <Box>
                    <Button
                      variant="contained"
                      color="success"
                      size="small"
                      className={classes.viewButton}
                      onClick={viewFIleUploaded}
                      startIcon={
                        <VisibilityIcon style={{ marginRight: '23px' }} />
                      }
                    >
                      View
                    </Button>

                    <Popup
                      trigger={
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <DeleteOutlineIcon color="error" />
                        </IconButton>
                      }
                      {...{
                        contentStyle,
                      }}
                      modal
                    >
                      {close => (
                        <div
                          style={{
                            padding: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          <HighlightOffIcon fontSize="large" color="error" />
                          <h4 style={{ fontWeight: 'bold' }}>Are You Sure?</h4>
                          <p>
                            If you proceed, you will not be able to recover it
                          </p>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              width: '100%',
                            }}
                          >
                            <Button
                              onClick={close}
                              className={classes.cancelButton}
                            >
                              No
                            </Button>
                            <Button
                              onClick={deleteFIleUploaded}
                              className={classes.deleteModalButton}
                            >
                              Yes
                            </Button>
                          </div>
                        </div>
                      )}
                    </Popup>
                  </Box>
                </div>
              ) : (
                <div styles={{ marginTop: '20px' }}>
                  <p
                    style={{
                      textAlign: 'left',
                    }}
                  >
                    No Driving License Uploaded
                  </p>
                </div>
              )}
            </Box>
          ) : (
            ''
          )}
          <Snackbar
            open={deleted}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="error">
              Deleted File!
            </Alert>
          </Snackbar>
          <Snackbar
            open={success}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="success">
              File Uploaded!
            </Alert>
          </Snackbar>
        </>
      ) : featureFlagFiles === 1 ? (
        <FeatureFlagEmptyState color="black" />
      ) : null}
    </Paper>
  );
};

export default DrivingLicenseUploader;
