/* eslint-disable camelcase */
/* eslint-disable no-var */
import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Skeleton } from '@material-ui/lab';
import moment from 'moment';
import { Button, CircularProgress } from '@material-ui/core';
import apiCaller from '../../../utils/apiCallerOutstanding';
import firebase from '../../../config/config';
import history from '../../../history';

var fileName = '';
var poll = true;
const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    cursor: 'pointer',
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({}))(TableRow);

function createData(
  serial,
  loadingDate,
  from,
  to,
  trucker,
  phoneNumber,
  truckNo,
  deliveryDate,
  ageing,
  tripId
) {
  return {
    serial,
    loadingDate,
    from,
    to,
    trucker,
    phoneNumber,
    truckNo,
    deliveryDate,
    ageing,
    tripId,
  };
}

function createDataPodSubmitted(
  serial,
  loadingDate,
  from,
  to,
  transporter,
  phoneNumber,
  truckNo,
  deliveryDate,
  ageing,
  tripId
) {
  return {
    serial,
    loadingDate,
    from,
    to,
    transporter,
    phoneNumber,
    truckNo,
    deliveryDate,
    ageing,
    tripId,
  };
}

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  tableContainer: {
    minHeight: '500px',
    marginTop: '20px',
  },
  pagination: {
    width: '100%',
    marginTop: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

export default function CustomizedTables({
  drillDownData,
  fetchingData,
  drillDownHeaders,
  toggleDrillDown,
  disabledNext,
  handlePrevious,
  handleNext,
  offset,
  type,
  podDrillDownQuery,
  activeRole,
}) {
  const classes = useStyles();
  const [polling, setpolling] = useState(false);
  const functionConvertDate = value => {
    let formattedDate = '';
    if (value) {
      formattedDate = `${moment(value).format('DD-MM-YYYY')}`;
    }

    return formattedDate;
  };
  const rows =
    type === 'Pod_Collected'
      ? drillDownData?.map(data =>
          createData(
            data?.transaction_serial,
            functionConvertDate(data?.loading_date),
            data?.from_city,
            data?.to_city,
            data?.trucker_name,
            data?.phone_number,
            data?.vehicle_number,
            functionConvertDate(data?.delivery_date),
            data?.pod_ageing,
            data?.trip_id
          )
        )
      : drillDownData?.map(data =>
          createDataPodSubmitted(
            data?.transaction_serial,
            functionConvertDate(data?.loading_date),
            data?.from_city,
            data?.to_city,
            data?.transporter_name,
            data?.phone_number,
            data?.vehicle_number,
            functionConvertDate(data?.delivery_date),
            data?.pod_ageing,
            data?.trip_id
          )
        );
  const pollExcelDownload = () => {
    setTimeout(() => {
      generateExcelFile();
    }, 3000);
  };

  const generateExcelFile = async () => {
    setpolling(true);
    const userDetails = firebase.auth().currentUser;
    const { Aa } = userDetails;
    const token = Aa;
    if (!fileName) {
      const newDate = moment().format();
      fileName = `POD-ageing${newDate}.xlsx`;
    }

    const ENDPOINT = 'pipe/get-pod-ageing';
    const {
      branch,
      filter_by,
      limit,

      pod_ageing_type,
      range,
      type: rangeType,
    } = podDrillDownQuery;
    const data = {
      file_name: fileName,
      is_polling: poll ? 0 : 1,
      branch,
      filter_by,
      limit,
      offset,
      pod_ageing_type,
      range,
      type: rangeType,
      is_download: true,
    };
    await apiCaller(ENDPOINT, data, `Bearer ${token}`, 'nh-base', 'post').then(
      response => {
        const { url } = response.data;
        if (!url) {
          poll = false;
          pollExcelDownload();
        } else {
          const { url } = response.data;
          poll = true;
          fileName = '';
          window.open(url, '_blank');
          setpolling(false);
        }
      }
    );
  };

  const redirectToDetailedTrsnactions = transactionId => {
    const url = `/${activeRole}/transactions/view/${transactionId}`;

    // Open the URL in a new tab
    window.open(url, '_blank');
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Button
          startIcon={<ArrowBackIcon />}
          variant="contained"
          onClick={toggleDrillDown}
        >
          Back
        </Button>
        {polling ? (
          <CircularProgress />
        ) : (
          <Button
            color="primary"
            variant="contained"
            onClick={generateExcelFile}
          >
            Download Excel
          </Button>
        )}
      </div>

      {fetchingData ? (
        <>
          <Skeleton variant="text" />
          <Skeleton variant="rect" height={118} />
        </>
      ) : (
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                {drillDownHeaders.map(header => (
                  <StyledTableCell
                    align={header === 'Ageing' ? 'right' : 'left'}
                  >
                    {header}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {type === 'Pod_Collected' ? (
                <>
                  {rows?.map(row => (
                    <StyledTableRow key={row.name}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        onClick={() =>
                          redirectToDetailedTrsnactions(row?.tripId)
                        }
                      >
                        {row?.serial}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row?.loadingDate}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row?.from}
                      </StyledTableCell>
                      <StyledTableCell align="left">{row?.to}</StyledTableCell>
                      <StyledTableCell align="left">
                        {row?.trucker}
                      </StyledTableCell>{' '}
                      <StyledTableCell align="left">
                        {row?.phoneNumber}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row?.truckNo}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row?.deliveryDate}
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                        style={{ color: '#163BAC', fontWeight: 'bold' }}
                      >
                        {row?.ageing}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </>
              ) : (
                <>
                  {rows?.map(row => (
                    <StyledTableRow key={row.name}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        onClick={() =>
                          redirectToDetailedTrsnactions(row?.tripId)
                        }
                      >
                        {row?.serial}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row?.loadingDate}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row?.from}
                      </StyledTableCell>
                      <StyledTableCell align="left">{row?.to}</StyledTableCell>
                      <StyledTableCell align="left">
                        {row?.transporter}
                      </StyledTableCell>{' '}
                      <StyledTableCell align="left">
                        {row?.phoneNumber}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row?.truckNo}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row?.deliveryDate}
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                        style={{ color: '#163BAC', fontWeight: 'bold' }}
                      >
                        {row?.ageing}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </>
              )}
            </TableBody>
          </Table>
          <div className={classes.pagination}>
            <Button
              startIcon={<ArrowBackIcon />}
              onClick={() => handlePrevious()}
              disabled={offset === 0}
            >
              Previous
            </Button>
            <Button
              endIcon={<ArrowForwardIcon />}
              disabled={disabledNext}
              onClick={() => handleNext()}
            >
              Next
            </Button>
          </div>
        </TableContainer>
      )}
    </div>
  );
}
